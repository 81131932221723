// ** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { axiosInstance } from '@src/network/AxiosInstance'
// import { getSellerList } from '../sellers/store'
import CustomToast from '@src/@core/components/ui/custom-toast/CustomToast'
export const getShippingChannel = createAsyncThunk(
  '/shipping',
  async ({params: param}) => {
    const params = param?.shippingSearchedText 
      ? param?.seller?.value?.id
        ? {name_filter: param?.shippingSearchedText, seller_id: param?.seller?.value?.id, is_integrated: true} 
        : {name_filter: param?.shippingSearchedText}
      : param?.seller?.value?.id
        ? {seller_id: param?.seller?.value?.id, is_integrated: true}
        : param
    const response = await axiosInstance.get('/api/v1/shipping/shipping_partners', {params})
    return response.data.data
  } 
)
export const integrateShippingChannel = createAsyncThunk(
  '/shipping/integrate_shipping_channel',
  async ({body}) => {
    const response = await axiosInstance.post('/api/v1/shipping/partner_shipping_methods', body)
    return response?.data
  } 
)
export const shippingPartnerMethods = createAsyncThunk(
  '/shipping/shippingPartnerMethods',
  async ({shippingPartnerTag}) => {
    const response = await axiosInstance.get('/api/v1/shipping/partner_shipping_methods', { params: {ids: shippingPartnerTag} })
    return response.data
  } 
)

export const editShippingPartnerPriority = createAsyncThunk(
  '/shipping/edit_shipping_partners_priority',
  async ({body}) => {
    console.log({body})
    const response = await axiosInstance.put('/api/v1/shipping/shipping_partners', body)
    return response
  } 
)

export const editIntegrationShippingChannel = createAsyncThunk(
  '/shipping/edit_integration_shipping_channel',
  async ({body}, state) => {
    const allState = state.getState()
    const integrationId = allState.shipping.activeIntegration.id 
    const res = await axiosInstance.put(`/api/v1/shipping/partner_shipping_methods/${integrationId}`, body)
    return res.data 
  }
)

export const getSingleShippingChannel = createAsyncThunk(
  '/shipping/get_single_shipping_channel',
  async (_, state) => {
    const allState = state.getState()
    const integrationId = allState.shipping.activeIntegration.id 
    const params = {id: integrationId}
    const res = await axiosInstance.get('/api/v1/shipping/partner_shipping_methods', {params})
    return res.data.data
  } 
)
export const getCountriesCode = async (search = '', loadedOptions, {page = 1 })  => {
  const params = search ? {search_column: 'name', search_query: search} : {page}
  try {
    const res = await axiosInstance.get('/api/v1/tenant/countries', {params})
    return {
      options: res.data.data.map((country) => ({
        value: {id:country.id, countryCode: country.iso2, currencyCode: country.currency.code, name: country.name},
        label: `${country.name} (${country.iso2})`
      })),
      hasMore: res?.data?.meta?.current_page < res?.data?.meta?.last_page,
      additional: {
        page: res?.data?.meta?.current_page + 1
      }
    }
  } catch (error) {
    // Handle the 403 error or other errors that may occur during the API request
    return {
      options: [],
      hasMore: false,
      additional: {
        page
      }
    }
  }
}

export const getChannelIntegrationDetails = createAsyncThunk('/shipping/get_channel_integration_details', 

  async (params, state) => {
    const allState = state.getState()
    const shippingPartnerId = allState.shipping.activeShippingChannel.id   
    const res = await axiosInstance.get(`/api/v1/shipping/shipping_partners/${shippingPartnerId}`, {params})
    return res.data
  })

export const ignoreWebHookMsg = createAsyncThunk(
  '/shipping/ignore_webhook_msg',
  async ({body}, state) => {
    const allState = state.getState()
    const integrationId = allState.shipping.activeIntegration.id 
    const res = await axiosInstance.put(`/api/v1/shipping/partner_shipping_methods/${integrationId}`, body)
    return res.data 
  }
)

export const shippingSlice = createSlice({
  name: 'shipping',
  initialState: {
    loading: {},
    error: {},
    success: {},
    shippingPartners: [],
    activeShippingChannel: null,
    activeChannelIntegrationDetails: null,
    activeChannelIntegratedSellers: null,
    singleShippingChannelDetail: null,
    activeIntegration: null,
    params: {},
    changeShippingPriority: { loading: false, is_success: false, data: null },
    shippingPartnerMethods: { loading: false, is_success: false, data: null },
    integrateShippingChannelResponse: {},
    editShippingPartnerResponse: {},
    isGetShippingChannelReloadRequired:false
  },
  reducers: {
    cleanupChangeShippingPartnerPriority: (state) => {
      state.changeShippingPriority = { loading: false, is_success: false, data: null }
    },
    setActiveShippingChannel: (state, action) => {
      state.activeShippingChannel = action.payload
    },
    setActiveIntegration: (state, action) => {
      state.activeIntegration = action.payload
    },
    setActiveChannelIntegratedSellers: (state, action) => {
      state.activeChannelIntegratedSellers = action.payload
    },
    shippingCleanup: (state) => {
      // state.loading = {}
      state.error = {}
      state.success = {}
      state.activeShippingChannel = null
      state.activeChannelIntegrationDetails = null
      state.activeChannelIntegratedSellers = null
      state.singleShippingChannelDetail = null
      state.activeIntegration = null
      state.isGetShippingChannelReloadRequired = false
    },
    setParams : (state, action) => {
      state.params = {...state.params, ...action.payload}
    },
    clearIntegrateShippingChannelResponse: (state) => { 
      state.integrateShippingChannelResponse = {}
    },
    clearEditShippingPartnerResponse: (state) => { 
      state.editShippingPartnerResponse = {}
    },
    resetSuccess: (state) => { 
      state.success = {}
    }
  },
  extraReducers: builder => {
    builder
    // .addCase(getSellerList.fulfilled, (state, action) => {
    //   if (!state.activeShippingSeller) {
    //     state.activeShippingSeller = action.payload.data[0]
    //   }
    // })
      .addCase(getShippingChannel.pending, (state) => {
        state.loading = {...state.loading, getShippingChannel:true}
      })
      .addCase(getShippingChannel.fulfilled, (state, action) => {
        state.loading = {...state.loading, getShippingChannel:false}
        state.error = {...state.error, getShippingChannel:false}
        state.shippingPartners = action.payload
      })
      .addCase(getShippingChannel.rejected, (state) => {
        state.loading = {...state.loading, getShippingChannel:false}
        state.error = {...state.error, getShippingChannel:true}
        state.shippingPartners = []
      })
      .addCase(integrateShippingChannel.pending, (state) => {
        state.loading = {...state.loading, integrateShippingChannel:true}
      })
      .addCase(integrateShippingChannel.fulfilled, (state, action) => {
        state.loading = {...state.loading, integrateShippingChannel:false}
        state.success = {...state.success, integrateShippingChannel: true }
        state.error = {...state.error, integrateShippingChannel: false}
        state.integrateShippingChannelResponse = action.payload.data
        CustomToast('Shipping partner integrated successfully', {my_type:'success'})
      })
      .addCase(integrateShippingChannel.rejected, (state) => {
        state.loading = {...state.loading, integrateShippingChannel:false}
        state.error = {...state.error, integrateShippingChannel: true}
      })
      .addCase(editIntegrationShippingChannel.pending, (state) => {
        state.loading = {...state.loading, editIntegrationShippingChannel:true}
      })
      .addCase(editIntegrationShippingChannel.fulfilled, (state, action) => {
        state.loading = {...state.loading, editIntegrationShippingChannel:false}
        state.success = {...state.success, editIntegrationShippingChannel: true }
        state.error = { ...state.error, editIntegrationShippingChannel: false }
        state.editShippingPartnerResponse = action.payload.data
        CustomToast('Shipping partner edited successfully', {my_type:'success'})
      })
      .addCase(editIntegrationShippingChannel.rejected, (state) => {
        state.loading = {...state.loading, editIntegrationShippingChannel:false}
        state.error = {...state.error, editIntegrationShippingChannel: true}
      })
      .addCase(getSingleShippingChannel.pending, (state) => {
        state.loading = {...state.loading, getSingleShippingChannel:true}
      })
      .addCase(getSingleShippingChannel.fulfilled, (state, action) => {
        state.loading = {...state.loading, getSingleShippingChannel:false}
        state.singleShippingChannelDetail = action.payload
        state.error = {...state.error, getSingleShippingChannel: false}
      })
      .addCase(getSingleShippingChannel.rejected, (state) => {
        state.error = {...state.error, getSingleShippingChannel: false}
      })
      .addCase(getChannelIntegrationDetails.pending, (state) => {
        state.loading = {...state.loading, getChannelIntegrationDetails: true}
      })
      .addCase(getChannelIntegrationDetails.fulfilled, (state, action) => {
        state.loading = {...state.loading, getChannelIntegrationDetails: false}
        state.activeChannelIntegrationDetails = action.payload 
      })
      .addCase(getChannelIntegrationDetails.rejected, (state) => {
        state.loading = {...state.loading, getChannelIntegrationDetails: false}
        state.error = {...state.error, getChannelIntegrationDetails: true}
      })
      .addCase(editShippingPartnerPriority.pending, (state) => {
        state.changeShippingPriority = { data: null, loading: true, is_success: true}
      })
      .addCase(editShippingPartnerPriority.fulfilled, (state, action) => {
        state.changeShippingPriority = {loading: false, data: action.payload, is_success: true}
      })
      .addCase(editShippingPartnerPriority.rejected, (state) => {
        state.changeShippingPriority = {data: null, loading: false, is_success: false}
      })
      .addCase(shippingPartnerMethods.pending, (state) => {
        state.shippingPartnerMethods = {data: null, loading: true, is_success: false}
      })
      .addCase(shippingPartnerMethods.fulfilled, (state, { payload }) => {
        state.shippingPartnerMethods = {...payload, loading: false}
      })
      .addCase(shippingPartnerMethods.rejected, (state) => {
        state.shippingPartnerMethods = {data: null, loading: false, is_success: false}
      })
      .addCase(ignoreWebHookMsg.pending, (state) => {
        state.loading = {...state.loading, ignoreWebHookMsg:true}
      })
      .addCase(ignoreWebHookMsg.fulfilled, (state) => {
        state.loading = {...state.loading, ignoreWebHookMsg:false}
        state.success = {...state.success, ignoreWebHookMsg: true }
        state.error = { ...state.error, ignoreWebHookMsg: false }
        state.isGetShippingChannelReloadRequired = true
      })
      .addCase(ignoreWebHookMsg.rejected, (state) => {
        state.loading = {...state.loading, ignoreWebHookMsg:false}
        state.error = {...state.error, ignoreWebHookMsg: true}
      })
  }
})

export const { setActiveShippingChannel, setActiveChannelIntegratedSellers, setActiveIntegration, shippingCleanup, setParams, cleanupChangeShippingPartnerPriority, clearIntegrateShippingChannelResponse, resetSuccess, editShippingPartnerResponse, clearEditShippingPartnerResponse} = shippingSlice.actions
export default shippingSlice.reducer
