import classNames from 'classnames'
import { forwardRef } from 'react'
import { X } from 'react-feather'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Input, InputGroup, InputGroupText, Spinner } from 'reactstrap'
import './style.scss'

const ErrorIcon = (
  <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.333496 13.3333L7.66683 0.666626L15.0002 13.3333H0.333496ZM7.73641 11.3833C7.87891 11.3833 7.99739 11.3351 8.09183 11.2387C8.18627 11.1423 8.2335 11.0229 8.2335 10.8804C8.2335 10.7379 8.1853 10.6194 8.08891 10.525C7.99251 10.4305 7.87307 10.3833 7.73058 10.3833C7.58808 10.3833 7.46961 10.4315 7.37516 10.5279C7.28072 10.6243 7.2335 10.7437 7.2335 10.8862C7.2335 11.0287 7.28169 11.1472 7.37808 11.2416C7.47448 11.3361 7.59392 11.3833 7.73641 11.3833ZM7.2335 9.53329H8.2335V5.79996H7.2335V9.53329Z" fill="#C21808" />
  </svg>
)

const BarcodeScanner = forwardRef(({startIcon,  placeholder, autoFocus, control, name, hasValue = false, persistPlaceholder = '', disabled, onKeyDown, handleClear = false, loading, errors = {}}, ref) => {

  const {t} = useTranslation()
    

  return (
    <div className={classNames('position-relative d-flex flex-column align-items-start flex-grow-1 scanBarcodeHeaderMain')}>
      <InputGroup className={classNames('scanBarcodeHeaderInputGroup py-10px px-12px align-items-center', {
        'border border-danger':errors[name]?.message,
        'bg-light-3':  disabled || loading
      })}>
        <InputGroupText className={classNames('border-0 txt-sub-rg', {
          'bg-light-3':  disabled || loading
        })}>
          <div className="pe-12px">{startIcon}</div>
          {(hasValue && persistPlaceholder) && <span className="text-dark pe-4px">{`${t(persistPlaceholder)} - `}</span>}
          {loading && <Spinner className="position-absolute barcode-loading-spinner" size={8}/>}
        </InputGroupText>
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <Input
              className={classNames('border-0 p-0 txt-sub-rg', {
                'text-dark': hasValue,
                'bg-light-3':  disabled || loading
              })}
              innerRef={ref}
              placeholder={t(placeholder)}
              autoFocus={autoFocus}
              onKeyDown={(e) => { if (onKeyDown) onKeyDown(e) }}
              {...field}
              disabled={disabled || loading}
            />
          )}
        />
        {(handleClear && hasValue && !(disabled || loading)) && <X onClick={handleClear} className="me-10px text-dark cursor-pointer" size={16}/>}
      </InputGroup>
      {errors[name] && <div className="text-danger txt-sub-rg mt-2px"> {ErrorIcon} {t(errors[name].message)}</div>}
    </div>
  )
})
export default BarcodeScanner

// const handleBarcodeScan = (e, current) => {
//     e.preventDefault()
//    console.log({e, current})
//   }
/* <BarcodeScanner handleBarcodeScan={handleBarcodeScan} startIcon={<img src="https://cdn-icons-png.flaticon.com/128/1550/1550324.png" alt="barcode Code" width="22px" height="18px" />} placeholder='Scan Existing Pass Bin Barcode'/> */