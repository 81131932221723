// ** Redux Imports
import { configureStore } from '@reduxjs/toolkit';
import { ENVIRONMENT } from '@src/App.constants';
import { createLogger } from 'redux-logger';
import rootReducer from './rootReducer';

const logger = createLogger({
  collapsed: true,
  diff: true
});

const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware => {
    return getDefaultMiddleware({
      serializableCheck: false
      //@ts-expect-error FIXME
    }).concat(import.meta.env.VITE_ENVIRONMENT === ENVIRONMENT.PRODUCTION ? [] : logger);
  }
});
export { store };

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch