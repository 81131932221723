import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import CustomToast from '@src/@core/components/ui/custom-toast/CustomToast'
import { axiosInstance } from '@src/network/AxiosInstance'
import { paramsSerializer } from '@src/utility/Utils'

export const getSellerList = createAsyncThunk('seller/getSellers', async (params = {}) => {
  const res = await axiosInstance.get('/api/v1/tenant/sellers', {params})
  return res.data
})
export const getAllSuppliers = createAsyncThunk('all/suppliers', async () => {
  const params = {sort: 'name_asc'}
  const res = await axiosInstance.get('/api/v1/wms/suppliers', {params})
  return res.data.data
})
export const getSupplierSellers = createAsyncThunk('all/supplierSellers', async ({searchSellerValue = '', page = 1, take_previous_data = false, seller_ids}) => {
  const params = searchSellerValue ? {search_column:'name', search_query: searchSellerValue, page, per_page:20, not_seller_ids: seller_ids} : {page, per_page:20, not_seller_ids: seller_ids}
  const res = await axiosInstance.get(`/api/v1/tenant/sellers?${paramsSerializer(params)}`)
  return {data: res?.data, take_previous_data}
})
export const mapSupplierSeller = createAsyncThunk('seller/mapSupplierToSeller', async ({supplier_id, body}) => {
  const res = await axiosInstance.put(`/api/v1/wms/suppliers/${supplier_id}`, body)
  return res
})
export const createSeller = createAsyncThunk('seller/createSeller', async ({body, t}) => {
  const res = await axiosInstance.post('/api/v1/tenant/sellers', body)
  if (res?.data?.is_success) {
    CustomToast(t('Seller created successfully'), { my_type: 'success' })
    return res.data.data
  }
  
})

export const updateSeller = createAsyncThunk('seller/upateSeller', async ({body, sellerId, t}) => {
  const res = await axiosInstance.put(`/api/v1/tenant/sellers/${sellerId}`, body)
  if (res?.data?.is_success) {
    CustomToast(t('Seller updated successfully'), { my_type: 'success' })
    return res
  }
})

export const getSingleSeller = createAsyncThunk('seller/getSingleSeller', async ({sellerId}) => {
  const res = await axiosInstance.get(`/api/v1/tenant/sellers/${sellerId}`)
  return res.data.data
})
export const getSellerCountries = async (search = '', loadedOptions, {country_id, page})  => {
  const params = search ? {search_column: 'name', search_query: search} : {page}
  const res = await axiosInstance.get('/api/v1/tenant/countries', {params})
  return {
    options: res.data.data.map((country) => ({
      value: country.id, 
      label: country.name
    })),
    hasMore: res?.data?.meta?.current_page < res?.data?.meta?.last_page,
    additional: {
      page: res?.data?.meta?.current_page + 1,
      country_id
    }
  }
}
export const getSellerStates = async (search, loadedOptions, {country_id, page })  => {
  const params = search ? {search_column: 'name', search_query: search, page} : {page}
  const res = await axiosInstance.get(`/api/v1/tenant/countries/${country_id}/states`, {params})
  return {
    options: res.data.data.map((state) => ({
      value: state.id,
      label: state.name
    })),
    hasMore: res?.data?.meta?.current_page < res?.data?.meta?.last_page,
    additional: {
      page: res?.data?.meta?.current_page + 1,
      country_id
    }
  }
}

export const getSellerCities = async (search = '', loadedOptions, { country_id, page})  => {
  const params = search ? {search_column: 'name', search_query: search} : {page}
  const res = await axiosInstance.get(`/api/v1/tenant/states/${country_id}/cities`, {params})
  return {
    options: res.data.data.map((city) => ({
      value: city.id,
      label: city.name
    })),
    hasMore: res?.data?.meta?.current_page < res?.data?.meta?.last_page,
    additional: {
      page: res?.data?.meta?.current_page + 1,
      country_id
    }
  }
}
export const getSuppliersAsyncDataPurchase = async (search, loadedOptions, { page }) => {
  try {
    const response = await axiosInstance.get('/api/v1/wms/suppliers', {
      params: { search_column: 'name', search_query: search, page, sort: 'name_asc' }
    })
    const data = response?.data
    return {
      options: data?.data?.map(ele => ({
        value: ele,
        label: ele?.name
      })),
      hasMore: page < data?.meta?.last_page,
      additional: {
        page: page + 1
      }
    }
  } catch (err) {
    console.warn(err)
  }
}
const initialState = {
  loading: {},
  error: {},
  success:{},
  allSellers: [],
  singleSeller : null,
  meta: null,
  countries: [],
  states: [],
  cities: [],
  selectedSupplier: null,
  supplierSellerMetaData: null,
  supllierSellerData: [],
  createSellerMode: false,
  updateSellerData:{}
}

const sellerSlice = createSlice({
  name: 'seller',
  initialState,
  reducers: {
    sellerCleanup: (state) => {
      state.error = {}
      state.loading = {}
      state.success = {}
      state.singleSeller = null 
      state.selectedSupplier = null
    },
    setCreateSellerMode: (state, action) => {
      state.createSellerMode = action.payload
    },
    setSelectedSupplier : (state, action) => {
      state.selectedSupplier = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(createSeller.pending, (state) => {
        state.loading = {...state.loading, createSeller: true}
      })
      .addCase(createSeller.fulfilled, (state, action) => {
        state.loading = {...state.loading, createSeller: false}
        state.error = {...state.error, createSeller: false}
        state.allSellers = action.payload
        state.success = {...state.success, createSeller: true}
      // CustomToast("Seller created Successfully!", {my_type: 'success'})
      })
      .addCase(createSeller.rejected, (state) => {
        state.loading = {...state.loading, createSeller: false}
        state.error = {...state.error, createSeller: true}
        state.success = false
      })
      .addCase(updateSeller.pending, (state) => {
        state.loading = {...state.loading, updateSeller: true}
      })
      .addCase(updateSeller.fulfilled, (state, action) => {
        state.loading = {...state.loading, updateSeller: false}
        state.error = {...state.error, updateSeller: false}
        state.success = {...state.success, updateSeller: true}
        state.updateSellerData = action.payload?.data
      // CustomToast("Seller Updated Successfully!", {my_type: 'success'})
      })
      .addCase(updateSeller.rejected, (state) => {
        state.loading = {...state.loading, updateSeller: false}
        state.error = {...state.error, updateSeller: true}
        state.success = false
      })
      .addCase(getSellerList.pending, (state) => {
        state.loading = {...state.loading, getSellerList: true}      
      })
      .addCase(getSellerList.fulfilled, (state, action) => {
        state.loading = {...state.loading, getSellerList: false}
        state.success = { ...state.success, getSellerList: true }
        state.error = {...state.error, getSellerList: false}
        state.allSellers = action.payload
        state.meta = action.payload.meta
      })
      .addCase(getSellerList.rejected, (state) => {
        state.loading = {...state.loading, getSellerList: false}
        state.success = { ...state.success, getSellerList: false }
        state.error = {...state.error, getSellerList: true}
      })
    // all suppliers
      .addCase(getAllSuppliers.pending, (state) => {
        state.loading = {...state.loading, getAllSuppliers: true}
      })
      .addCase(getAllSuppliers.fulfilled, (state, action) => {
        state.loading = {...state.loading, getAllSuppliers: false}
        state.success = {...state.success, getAllSuppliers: true}
        state.error = {...state.error, getAllSuppliers: false}
        if (action.payload?.length) {
          state.selectedSupplier = {label: action.payload[0].name, value: action.payload[0]}
        } else {
          state.selectedSupplier = null
        }
      })
      .addCase(getAllSuppliers.rejected, (state) => {
        state.loading = {...state.loading, getAllSuppliers: false}
        state.error = {...state.error, getAllSuppliers: true}
        state.success = {...state.success, getAllSuppliers: false}
        state.selectedSupplier = null 
      })
    // all sellers suppliers
      .addCase(getSupplierSellers.pending, (state) => {
        state.loading = {...state.loading, getSupplierSellers: true}
      })
      .addCase(getSupplierSellers.fulfilled, (state, action) => {
        state.loading = {...state.loading, getSupplierSellers: false}
        state.error = {...state.error, getSupplierSellers: false}
        if (action.payload?.take_previous_data) {
          state.supllierSellerData = [...state.supllierSellerData, ...action.payload.data.data]
        } else {
          state.supllierSellerData = action.payload.data.data
        }
        state.supplierSellerMetaData = action.payload.data.meta
      })
      .addCase(getSupplierSellers.rejected, (state) => {
        state.loading = {...state.loading, getSupplierSellers: false}
        state.error = {...state.error, getSupplierSellers: true}
      })
    //
    // map supplier seller
      .addCase(mapSupplierSeller.pending, (state) => {
        state.loading = {...state.loading, mapSupplierSeller: true}
      })
      .addCase(mapSupplierSeller.fulfilled, (state) => {
        state.loading = {...state.loading, mapSupplierSeller: false}
        state.error = {...state.error, mapSupplierSeller: false}
        state.success = {...state.success, mapSupplierSeller: true}
      })
      .addCase(mapSupplierSeller.rejected, (state) => {
        state.loading = {...state.loading, mapSupplierSeller: false}
        state.error = {...state.error, mapSupplierSeller: true}
        state.success = {...state.success, mapSupplierSeller: false}
      })
    // 
      .addCase(getSingleSeller.pending, (state) => {
        state.loading = {...state.loading, getSingleSeller: true}
      })
      .addCase(getSingleSeller.fulfilled, (state, action) => {
        state.loading = {...state.loading, getSingleSeller: false}
        state.singleSeller = action.payload
      })
      .addCase(getSingleSeller.rejected, (state) => {
        state.loading = {...state.loading, getSingleSeller: false}
        state.error = {...state.error, getSingleSeller: true}
      })
  }
})
export const { sellerCleanup, setSelectedSupplier, setCreateSellerMode} = sellerSlice.actions
export default sellerSlice.reducer
