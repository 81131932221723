const SVG = ({ height, fillColor, width,  iswhite }) => {
  const color = iswhite ? 'white' : fillColor
  return <svg width={width} height={height} viewBox="0 0 77 77" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="path-1-inside-1_925_17244" fill="white">
      <rect x="46.5669" y="49.079" width="20.9211" height="20.9211" rx="1"/>
    </mask>
    <rect x="46.5669" y="49.079" width="20.9211" height="20.9211" rx="1" stroke={color} strokeWidth="4.8" mask="url(#path-1-inside-1_925_17244)"/>
    <mask id="path-2-inside-2_925_17244" fill="white">
      <rect x="53.5405" y="49.079" width="6.97369" height="8.96617" rx="1"/>
    </mask>
    <rect x="53.5405" y="49.079" width="6.97369" height="8.96617" rx="1" stroke={color} strokeWidth="4.8" mask="url(#path-2-inside-2_925_17244)"/>
    <mask id="path-3-inside-3_925_17244" fill="white">
      <rect x="27.6384" y="49.079" width="20.9211" height="20.9211" rx="1"/>
    </mask>
    <rect x="27.6384" y="49.079" width="20.9211" height="20.9211" rx="1" stroke={color} strokeWidth="4.8" mask="url(#path-3-inside-3_925_17244)"/>
    <mask id="path-4-inside-4_925_17244" fill="white">
      <rect x="34.6121" y="49.079" width="6.97369" height="8.96617" rx="1"/>
    </mask>
    <rect x="34.6121" y="49.079" width="6.97369" height="8.96617" rx="1" stroke={color} strokeWidth="4.8" mask="url(#path-4-inside-4_925_17244)"/>
    <mask id="path-5-inside-5_925_17244" fill="white">
      <rect x="44.5742" y="30.1504" width="20.9211" height="20.9211" rx="1"/>
    </mask>
    <rect x="44.5742" y="30.1504" width="20.9211" height="20.9211" rx="1" stroke={color} strokeWidth="4.8" mask="url(#path-5-inside-5_925_17244)"/>
    <mask id="path-6-inside-6_925_17244" fill="white">
      <rect x="51.5481" y="30.1504" width="6.97369" height="8.96617" rx="1"/>
    </mask>
    <rect x="51.5481" y="30.1504" width="6.97369" height="8.96617" rx="1" stroke={color} strokeWidth="4.8" mask="url(#path-6-inside-6_925_17244)"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M20.1261 19.2818C20.7079 19.2818 21.1795 18.8102 21.1795 18.2283C21.1795 17.6465 20.7079 17.1749 20.1261 17.1749C19.5442 17.1749 19.0726 17.6465 19.0726 18.2283C19.0726 18.8102 19.5442 19.2818 20.1261 19.2818ZM20.1261 21.7399C22.0654 21.7399 23.6376 20.1677 23.6376 18.2283C23.6376 16.289 22.0654 14.7168 20.1261 14.7168C18.1867 14.7168 16.6145 16.289 16.6145 18.2283C16.6145 20.1677 18.1867 21.7399 20.1261 21.7399Z" fill={color}/>
    <path fillRule="evenodd" clipRule="evenodd" d="M20.1261 29.8165C20.7079 29.8165 21.1795 29.3448 21.1795 28.763C21.1795 28.1812 20.7079 27.7096 20.1261 27.7096C19.5442 27.7096 19.0726 28.1812 19.0726 28.763C19.0726 29.3448 19.5442 29.8165 20.1261 29.8165ZM20.1261 32.2746C22.0654 32.2746 23.6376 30.7024 23.6376 28.763C23.6376 26.8236 22.0654 25.2515 20.1261 25.2515C18.1867 25.2515 16.6145 26.8236 16.6145 28.763C16.6145 30.7024 18.1867 32.2746 20.1261 32.2746Z" fill={color}/>
    <path fillRule="evenodd" clipRule="evenodd" d="M20.1261 40.3512C20.7079 40.3512 21.1795 39.8795 21.1795 39.2977C21.1795 38.7159 20.7079 38.2443 20.1261 38.2443C19.5442 38.2443 19.0726 38.7159 19.0726 39.2977C19.0726 39.8795 19.5442 40.3512 20.1261 40.3512ZM20.1261 42.8093C22.0654 42.8093 23.6376 41.2371 23.6376 39.2977C23.6376 37.3583 22.0654 35.7862 20.1261 35.7862C18.1867 35.7862 16.6145 37.3583 16.6145 39.2977C16.6145 41.2371 18.1867 42.8093 20.1261 42.8093Z" fill={color}/>
    <path fillRule="evenodd" clipRule="evenodd" d="M25.2175 17.8772C25.2175 17.1984 25.7678 16.6481 26.4466 16.6481H44.0044C44.6831 16.6481 45.2334 17.1984 45.2334 17.8772C45.2334 18.556 44.6831 19.1062 44.0044 19.1062H26.4466C25.7678 19.1062 25.2175 18.556 25.2175 17.8772Z" fill={color}/>
    <path fillRule="evenodd" clipRule="evenodd" d="M25.2175 28.7629C25.2175 28.0841 25.7024 27.5339 26.3006 27.5339H41.7728C42.371 27.5339 42.8559 28.0841 42.8559 28.7629C42.8559 29.4417 42.371 29.992 41.7728 29.992H26.3006C25.7024 29.992 25.2175 29.4417 25.2175 28.7629Z" fill={color}/>
    <path fillRule="evenodd" clipRule="evenodd" d="M25.2175 39.2976C25.2175 38.6188 25.6115 38.0686 26.0975 38.0686H38.6687C39.1547 38.0686 39.5487 38.6188 39.5487 39.2976C39.5487 39.9764 39.1547 40.5267 38.6687 40.5267H26.0975C25.6115 40.5267 25.2175 39.9764 25.2175 39.2976Z" fill={color}/>
    <path d="M52.9935 30.7016V9C52.9935 7.89543 52.0981 7 50.9935 7H12C10.8954 7 10 7.89543 10 9V61.2223C10 62.3269 10.8954 63.2223 12 63.2223H29.292" stroke={color} strokeWidth="2.4"/>
  </svg>
}
export default SVG