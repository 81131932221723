import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { useState } from 'react'

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 0,
      refetchOnMount: 'always',
      retry: false
    }
  }
})

const ReactQueryProvider = ({ children }) => {
  const [queryClientState] = useState(() => queryClient)
  return (
    <QueryClientProvider client={queryClientState}>
      {children}
    </QueryClientProvider>
  )
}

export default ReactQueryProvider