const SVG = ({ height, fillColor, width, iswhite }) => {
  const color = iswhite ? 'white' : fillColor
  return <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M10.1469 12.0498C10.1469 11.8088 10.3422 11.6134 10.5832 11.6134H18.099C18.34 11.6134 18.5354 11.8088 18.5354 12.0498V13.2388H20.2772C20.4106 13.2388 20.5367 13.2999 20.6195 13.4045L22.2851 15.511C22.346 15.588 22.3792 15.6834 22.3792 15.7816V18.3837C22.3792 18.6247 22.1838 18.8201 21.9428 18.8201H21.5688C21.3798 19.4552 20.7868 19.9156 20.0955 19.9156C19.4042 19.9156 18.8112 19.4552 18.6223 18.8201H14.4178C14.2289 19.4552 13.6359 19.9156 12.9446 19.9156C12.2533 19.9156 11.6603 19.4552 11.4714 18.8201H10.5832C10.3422 18.8201 10.1469 18.6247 10.1469 18.3837V12.0498ZM11.4732 17.9474C11.664 17.3153 12.2555 16.8578 12.9446 16.8578C13.6337 16.8578 14.2252 17.3153 14.416 17.9474H17.6627V12.4861H11.0196V17.9474H11.4732ZM18.5354 17.9474H18.6241C18.815 17.3153 19.4064 16.8578 20.0955 16.8578C20.7231 16.8578 21.2697 17.2373 21.5065 17.7824V16.218H18.5354V17.9474ZM18.5354 15.3453H21.0415L20.0659 14.1115H18.5354V15.3453ZM12.9446 17.7306C12.5785 17.7306 12.2813 18.028 12.2813 18.3867C12.2813 18.7454 12.5785 19.0429 12.9446 19.0429C13.3107 19.0429 13.6079 18.7454 13.6079 18.3867C13.6079 18.028 13.3107 17.7306 12.9446 17.7306ZM20.0955 17.7306C19.7295 17.7306 19.4323 18.028 19.4323 18.3867C19.4323 18.7454 19.7295 19.0429 20.0955 19.0429C20.4616 19.0429 20.7588 18.7454 20.7588 18.3867C20.7588 18.028 20.4616 17.7306 20.0955 17.7306Z" fill={color}/>
    <path fillRule="evenodd" clipRule="evenodd" d="M10.1035 4.09315C9.98617 4.0382 9.85052 4.0382 9.73323 4.09315L3.06795 7.21563C2.91463 7.28745 2.8167 7.44147 2.8167 7.61078V14.8897C2.8167 15.1307 3.01207 15.3261 3.25306 15.3261C3.49406 15.3261 3.68943 15.1307 3.68943 14.8897V7.88823L9.91834 4.97018L16.1473 7.88823V12.0498C16.1473 12.2908 16.3426 12.4862 16.5836 12.4862C16.8246 12.4862 17.02 12.2908 17.02 12.0498V7.61078C17.02 7.44147 16.9221 7.28745 16.7687 7.21563L10.1035 4.09315ZM14.7382 9.29211C14.7382 9.05111 14.5428 8.85575 14.3018 8.85575H5.53487C5.29387 8.85575 5.09851 9.05111 5.09851 9.29211V12.0498C5.09851 12.2908 5.29387 12.4862 5.53487 12.4862C5.77587 12.4862 5.97124 12.2908 5.97124 12.0498V9.72847H13.8655V12.0498C13.8655 12.2908 14.0608 12.4862 14.3018 12.4862C14.5428 12.4862 14.7382 12.2908 14.7382 12.0498V9.29211Z" fill={color}/>
    <path fillRule="evenodd" clipRule="evenodd" d="M5.58273 14.8897C5.58273 14.6487 5.38737 14.4533 5.14637 14.4533H2.30647C2.06548 14.4533 1.87011 14.6487 1.87011 14.8897V17.7296C1.87011 17.9706 2.06548 18.1659 2.30647 18.1659H5.14637C5.38737 18.1659 5.58273 17.9706 5.58273 17.7296V14.8897ZM4.71001 15.326V17.2932H2.74284V15.326H4.71001Z" fill={color}/>
    <path fillRule="evenodd" clipRule="evenodd" d="M8.42264 14.8897C8.42264 14.6487 8.22727 14.4533 7.98627 14.4533H5.14638C4.90538 14.4533 4.71001 14.6487 4.71001 14.8897V17.7296C4.71001 17.9706 4.90538 18.1659 5.14638 18.1659H7.98627C8.22727 18.1659 8.42264 17.9706 8.42264 17.7296V14.8897ZM7.54991 15.326V17.2932H5.58274V15.326H7.54991Z" fill={color}/>
    <path fillRule="evenodd" clipRule="evenodd" d="M8.02698 12.0498C8.02698 11.8088 7.83161 11.6134 7.59061 11.6134H4.75072C4.50972 11.6134 4.31435 11.8088 4.31435 12.0498V14.8897C4.31435 15.1307 4.50972 15.326 4.75072 15.326H7.59061C7.83161 15.326 8.02698 15.1307 8.02698 14.8897V12.0498ZM7.15425 12.4861V14.4533H5.18708V12.4861H7.15425Z" fill={color}/>
  </svg>    
}
export default SVG