import { ATTACHMENTS_SIDESHEET_VIEW } from '@src/views/sales/sales.constant'
import ExistingAttachments from './existing-attachments'
import UploadAttachments from './upload-attachment'

const AddEditAttachments = (props) => {
  const { handleUploadFiles, addedAttachments, mode, isUploading, formObject, currentView, setCurrentView, uploadDetails } = props

  const handleChangeCurrentView = (view) => {
    setCurrentView(view)
  }

  return (
    <>
      {currentView === ATTACHMENTS_SIDESHEET_VIEW.EXISTING && <ExistingAttachments handleUploadFiles={handleUploadFiles} isUploading={isUploading} addedAttachments={addedAttachments} mode={mode} formObject={formObject} handleChangeCurrentView={handleChangeCurrentView} />}
      {currentView === ATTACHMENTS_SIDESHEET_VIEW.UPLOAD && <UploadAttachments handleUploadFiles={handleUploadFiles} isUploading={isUploading} addedAttachments={addedAttachments} mode={mode} formObject={formObject} handleChangeCurrentView={handleChangeCurrentView} uploadDetails={uploadDetails} />}
    </>
  )
}

export default AddEditAttachments