import Button from '@src/@core/components/ui/button'
import { useRef } from 'react'
import { AlertTriangle, X } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Modal, ModalBody, Spinner } from 'reactstrap'
import { SERIALISATION_OPTION } from '../../constant'

const OverReceiveItemModal = ({isOpen, toggle, handleSaveAnyway, handleChangeQuantity, loading}) => {
  const loadingState = useSelector(store => store.inventory.loadingState)
  const skuDetails = useSelector(store => store.inventory.grn.sku_details?.data)
  const displayGrnDetails = useSelector(store => store.inventory.grn.displayGrnDetails)
  const {t} = useTranslation()

  const primaryCtaRef = useRef()

  const onOpened = () => {
    if (primaryCtaRef.current) primaryCtaRef.current?.focus()
  }

  return (
    <Modal
      isOpen={isOpen}
      centered
      toggle={toggle}
      onOpened={onOpened}
      contentClassName='rounded-12px'
    >
      <ModalBody className="d-flex flex-column py-16px px-20px ">
        <div className="d-flex justify-content-end align-items-center">
          <X onClick={toggle} size={16} className="text-dark" />
        </div>
        <div className="d-flex flex-column gap-8px mb-32px px-36px align-items-center">
          <AlertTriangle style={{ strokeWidth: 1.5, color: '#FF991F' }} size={80} fill="currentColor" strokeColor="white" stroke="white" />
          <div className='txt-h3-sb text-dark text-center'>
            {t(`GRN includes more items than specified in the ${displayGrnDetails?.is_purchase_order ? 'Purchase Order' : 'Stock Transfer Order'}.`)}
          </div>
        </div>
        <div className='d-flex gap-20px'>
          <Button
            ofStyle="outlined"
            className='w-100 justify-content-center'
            onClick={handleChangeQuantity}
            disabled={loading}
          >
            {skuDetails?.serialisation_status === SERIALISATION_OPTION.serialised.id ? t('Go Back') : t('Change Quantity')}
          </Button>
          <Button
            className='w-100 justify-content-center'
            onClick={handleSaveAnyway}
            ref={primaryCtaRef}
            disabled={loadingState.addSkuInGrnBin || loading}
          >
            {(loadingState.addSkuInGrnBin || loading) && <Spinner size="sm" />}
            <span className="align-middle ms-25">{t('Save anyway')}</span>
        
          </Button>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default OverReceiveItemModal