import Button from '@src/@core/components/ui/button'
import CustomToast from '@src/@core/components/ui/custom-toast/CustomToast'
import FloatingDropDown from '@src/@core/components/ui/floating-dropdown'
import { useApi } from '@src/configs/react-query/useApi'
import { axiosInstance } from '@src/network/AxiosInstance'
import { uploadMultipleFilesOnS3 } from '@src/redux/authentication'
import { getQcFailReasons, setBinDisabledGrnTableData, setScannedSkuDetails } from '@src/views/inventory/store'
import { useEffect, useState } from 'react'
import { X } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Modal, Spinner } from 'reactstrap'
import { BATCH_STATUS } from '../../constant'
import OverReceiveItemModal from '../qc-component/overReceiveItemModal'
import ImageUploaderBinDisabled from './imageUploader'
import QrCodeScannerBinDisabled from './qrCodeScanner'
import './styles.scss'

const SerializedMarkAsFail = (props) => {
  const { isSerializedMarkAsFail, toggle, skuDetails, grnResponse, selectedGlobalHubId } = props
  const { t } = useTranslation()
  const [scannedBarcode, setScannedBarcode] = useState(null)
  const [reason, setReason] = useState(null)
  const [images, setImages] = useState([])
  const [imageUploadLoading, setImageUploadLoading] = useState(false)
  const dispatch = useDispatch()
  const tableData = useSelector(store => store.inventory.binDisabledGrn.tableData)
  const [requestBody, setRequestBody] = useState({})
  const [isOverReceiveModalOpen, setIsOverReceiveModalOpen] = useState(false)
  const returnGrnResponse = useSelector((state) => state.returns.createReturnGrnResponse?.data)

  const { mutate: markAsFailSerialisedSku, isPending:loadingState } = useApi({
    isMutation: true,
    apiKey: ['mark-as-fail-serialised-sku-bin-disabled'],
    apiFn: (body) => {

      const skuInTable = tableData.find(item => item.seller_sku_id === skuDetails?.seller_sku_id)

      const bodyToSend = {
        ...body,
        over_receive: !!skuInTable?.allow_over_receive,
        return_order_id: returnGrnResponse?.return_order_detail?.id || null
      }
      const grn_id = grnResponse?.id || returnGrnResponse?.id
      return axiosInstance.post(`/api/v1/wms/hubs/${selectedGlobalHubId}/grns/${grn_id}`, bodyToSend)
    },
    onSuccess: (data) => {
      if (data?.data?.is_over_receive) {
        setIsOverReceiveModalOpen(true)
      } else {
        const failedBarcodes = requestBody?.reasons?.map((item) => item?.barcodes)?.flatMap(el => el)
        const failQty = requestBody?.reasons?.reduce((acc, el) => acc + el?.quantity, 0)
        const reasonsToSet = requestBody?.reasons?.map((item) => {
          return {
            ...item,
            upload_ids: []
          }
        })
  
        dispatch(setScannedSkuDetails({
          data: {
            ...skuDetails,
            pass_qty: requestBody?.pass_quantity || 0,
            fail_qty: failQty,
            reasons: reasonsToSet,
            pass_barcodes: requestBody?.sku_barcodes || [],
            fail_barcodes: failedBarcodes
          }
        }))
  
        if (skuDetails?.batch_status === BATCH_STATUS.BATCHED) {
          const updateTableData = tableData.map((item) => {
            return item.seller_sku_id === skuDetails.seller_sku_id && item.batch?.id === skuDetails?.batch?.id ? {
              ...item,
              pass_qty: requestBody?.pass_quantity || 0,
              fail_qty: failQty,
              reasons: reasonsToSet,
              pass_barcodes: requestBody?.sku_barcodes || [],
              fail_barcodes: failedBarcodes
            } : item
          })
          dispatch(setBinDisabledGrnTableData(updateTableData))
        } else {
          const updateTableData = tableData.map((item) => {
            return item.seller_sku_id === skuDetails.seller_sku_id ? {
              ...item,
              pass_qty: requestBody?.pass_quantity || 0,
              fail_qty: failQty,
              reasons: reasonsToSet,
              pass_barcodes: requestBody?.sku_barcodes || [],
              fail_barcodes: failedBarcodes
            } : item
          })
          dispatch(setBinDisabledGrnTableData(updateTableData))
        }
        CustomToast('1 Item failed successfully', { my_type: 'success', duration: 2000 })
        setRequestBody({})
        setScannedBarcode(null)
        setReason(null)
        setImages([])
        toggle()
      }
    }
  })

  const { mutate: markAsFailSerialisedSkuFromModal, isPending:loadingStateModal } = useApi({
    isMutation: true,
    apiKey: ['mark-as-fail-serialised-sku-bin-disabled-from-modal'],
    apiFn: () => {
      const body = {
        ...requestBody,
        over_receive: isOverReceiveModalOpen,
        return_order_id: returnGrnResponse?.return_order_detail?.id || null
      }
      const grn_id = grnResponse?.id || returnGrnResponse?.id
      return axiosInstance.post(`/api/v1/wms/hubs/${selectedGlobalHubId}/grns/${grn_id}`, body)
    },
    onSuccess: () => {
      const failedBarcodes = requestBody?.reasons?.map((item) => item?.barcodes)?.flatMap(el => el)
      const failQty = requestBody?.reasons?.reduce((acc, el) => acc + el?.quantity, 0)
      const reasonsToSet = requestBody?.reasons?.map((item) => {
        return {
          ...item,
          upload_ids: []
        }
      })
  
      dispatch(setScannedSkuDetails({
        data: {
          ...skuDetails,
          pass_qty: requestBody?.pass_quantity || 0,
          fail_qty: failQty,
          reasons: reasonsToSet,
          pass_barcodes: requestBody?.sku_barcodes || [],
          fail_barcodes: failedBarcodes
        }
      }))
  
      if (skuDetails?.batch_status === BATCH_STATUS.BATCHED) {
        const updateTableData = tableData.map((item) => {
          return item.seller_sku_id === skuDetails.seller_sku_id && item.batch?.id === skuDetails?.batch?.id ? {
            ...item,
            pass_qty: requestBody?.pass_quantity || 0,
            fail_qty: failQty,
            reasons: reasonsToSet,
            pass_barcodes: requestBody?.sku_barcodes || [],
            fail_barcodes: failedBarcodes,
            allow_over_receive: true
          } : item
        })
        dispatch(setBinDisabledGrnTableData(updateTableData))
      } else {
        const updateTableData = tableData.map((item) => {
          return item.seller_sku_id === skuDetails.seller_sku_id ? {
            ...item,
            pass_qty: requestBody?.pass_quantity || 0,
            fail_qty: failQty,
            reasons: reasonsToSet,
            pass_barcodes: requestBody?.sku_barcodes || [],
            fail_barcodes: failedBarcodes,
            allow_over_receive: true
          } : item
        })
        dispatch(setBinDisabledGrnTableData(updateTableData))
      }
        
      CustomToast('1 Item failed successfully', { my_type: 'success', duration: 2000 })
      setIsOverReceiveModalOpen(false)
      setRequestBody({})
      setScannedBarcode(null)
      setReason(null)
      setImages([])
      toggle()
    }
  })

  const handleOnClosed = () => {

  }

  const handleBarcodeScan = (barcode) => {
    setScannedBarcode(barcode)
  }

  const handleReasonChange = (reason) => {
    setReason(reason)
  }

  const handleSave = async () => {

    let uploadIds
    if (images?.length) {
      setImageUploadLoading(true)
      const imagesToUpload = images.map(item => ({
        service: 'serialised_qc_fail_reason_images',
        usecase: 'reason_images',
        file: item
      }))

      uploadIds = await uploadMultipleFilesOnS3(imagesToUpload)
    }

    if (images?.length && !uploadIds) {
      setImageUploadLoading(false)
      return
    }

    let currentSelectedSku

    if (skuDetails?.batch_status === BATCH_STATUS.BATCHED) {
      currentSelectedSku = tableData?.find((item) => item.seller_sku_id === skuDetails?.seller_sku_id && item?.batch?.id === skuDetails?.batch?.id)
    } else {
      currentSelectedSku = tableData?.find((item) => item.seller_sku_id === skuDetails?.seller_sku_id)
    }

    const previousReasons = currentSelectedSku?.reasons || []
    const passedBarcodes = currentSelectedSku?.pass_barcodes || []
    const failedBarcodes = currentSelectedSku?.fail_barcodes || []

    if (!failedBarcodes?.includes(scannedBarcode)) {
      const isReasonPresentInPreviousReasons = previousReasons?.find(item => item.id === reason?.value)
      let reasonsToSend

      if (isReasonPresentInPreviousReasons) {
        reasonsToSend = previousReasons?.map((item) => (item.id === reason?.value ?
          {
            id: reason?.value,
            quantity: item.quantity + 1,
            upload_ids: uploadIds?.length ? uploadIds : [],
            barcodes: Array.isArray(item.barcodes) && item.barcodes?.length ? [...item.barcodes, scannedBarcode] : [scannedBarcode]
          }
          : item)
        )
      } else {
        if (previousReasons?.length) {
          reasonsToSend = [
            ...previousReasons, {
              id: reason?.value,
              quantity: 1,
              upload_ids: uploadIds?.length ? uploadIds : [],
              barcodes: [scannedBarcode]
            }
          ]
        } else {
          reasonsToSend = [
            {
              id: reason?.value,
              quantity: 1,
              upload_ids: uploadIds?.length ? uploadIds : [],
              barcodes: [scannedBarcode]
            }
          ]
        }
      }

      const passQty = passedBarcodes?.includes(scannedBarcode) ? (parseInt(skuDetails?.pass_qty) - 1) : skuDetails?.pass_qty
      const filteredPassBarcodes = passedBarcodes?.includes(scannedBarcode) ? passedBarcodes?.filter(item => item !== scannedBarcode) : passedBarcodes
      
      const body = {
        sku_barcodes: filteredPassBarcodes,
        reasons: reasonsToSend,
        batch_id: skuDetails?.batch_status === BATCH_STATUS.BATCHED ? skuDetails?.batch?.id : null,
        pass_quantity: passQty,
        seller_sku_code: skuDetails?.seller_sku_code
      }
      setRequestBody(body)

      markAsFailSerialisedSku(body)

    } else {
      CustomToast('The scanned Barcode is already in use. Please add a different Barcode.', { my_type: 'error', audioRequired: true })
    }
    setImageUploadLoading(false)
  }

  useEffect(() => {
  }, [isSerializedMarkAsFail])

  return (<>
    <Modal
      isOpen={isSerializedMarkAsFail}
      toggle={() => {
        setScannedBarcode(null)
        setReason(null)
        setImages([])
        toggle()
      }}
      className='serialise-qc-fail-bin-disabled-popup-wrapper'
      onClosed={handleOnClosed}
      centered
    >
      <div className='flex-center-between w-100 mb-20px'>
        <span className='text-dark txt-body-md'>{t('Mark as fail')}</span>
        <X className='cursor-pointer' size={16} color='var(--bs-dark)' onClick={toggle} />
      </div>
      <div className='w-100 mb-20px'>
        <QrCodeScannerBinDisabled
          placeholder='Scan Barcode'
          handleSkuBarcodeScan={handleBarcodeScan}
          doNotClear={true}
          setReason={setReason}
        />
      </div>
      <div className='w-100 mb-20px'>
        <FloatingDropDown
          isAsync
          loadOptions={getQcFailReasons}
          additional={{ page: 1 }}
          placeholder='Select Reason'
          isClearable
          value={reason}
          onChangeFunc={handleReasonChange}
          disabled={!scannedBarcode}
        />
      </div>
      <div className='mb-20px'>
        <ImageUploaderBinDisabled
          imageSize={60}
          customizedUploadIcon={true}
          customRadius={true}
          data={images}
          setData={setImages}
        />
      </div>
      <Button className='w-100 justify-content-center' onClick={handleSave} disabled={!scannedBarcode || !reason || imageUploadLoading || loadingState} >
        {(imageUploadLoading || loadingState) && <Spinner size='sm' />}
        {t('Done')}
      </Button>

    </Modal>

    <OverReceiveItemModal
      isOpen={isOverReceiveModalOpen}
      toggle={() => setIsOverReceiveModalOpen(p => !p)}
      handleChangeQuantity={() => setIsOverReceiveModalOpen(p => !p)}
      handleSaveAnyway={() => markAsFailSerialisedSkuFromModal()}
      loading={loadingStateModal}
    />
  </>
  )
}

export default SerializedMarkAsFail