import * as PusherPushNotifications from '@pusher/push-notifications-web'
import { setPusherLoading } from '@src/redux/authentication'
import { store } from '@src/redux/store'
import { pusherCallback } from '@src/utility/Utils'
import Pusher from 'pusher-js'

const channelNamePrefix = import.meta.env.VITE_PUSHER_CHANNEL_NAME_PREFIX

let pusher

let channel

let timeoutId = null

export const startInactivityCheck = () => {
  timeoutId = window.setTimeout(() => {
    if (pusher) {
      pusher.disconnect()
    }
  }, 15 * 60 * 1000) // called after 15 minutes
}

// called by something that detects user activity
export const userActivityDetected = () => {
  if (timeoutId !== null) {
    window.clearTimeout(timeoutId)
  }
  // startInactivityCheck()
}

export const bindToChannelEvent = (eventName, customCallback) => {
  const callback = typeof customCallback === 'function' ? customCallback : pusherCallback
  if (channel) {
    channel.bind(eventName, callback)
  }
}

export const unbindFromChannelEvent = (eventName) => {
  if (channel) {
    channel.unbind(eventName)
  }
}

export const unsubscribeChannel = (userId) => {
  if (pusher) {
    pusher.unsubscribe(`${channelNamePrefix}-${userId}`)
  }
}

export const disconnectFromPusher = () => {
  if (pusher) {
    pusher.disconnect()
    pusher = null
    channel = null
  }

  // clearing the idle state timeout
  userActivityDetected()
}

export const initRealTime = (dependentFunc = () => { }) => {

  const userId = store.getState().auth.userData.id
  store.dispatch(setPusherLoading(true))
  // creating pusher instance
  if (!pusher) {
    pusher = new Pusher(import.meta.env.VITE_PUSHER_APP_KEY, {
      cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER,
      encrypted: true,
      authEndpoint: '/api/v1/pusher/authenticate',
      channelAuthorization: {
        endpoint: '/api/v1/pusher/authenticate',
        headers: {
          'x-omniful-platform': 'web',
          Authorization: `Bearer ${JSON.parse(localStorage.getItem('auth')).access_token}`
        }
      }
    })

    // subscribing to the channel
    channel = pusher.subscribe(`${channelNamePrefix}-${userId}`)

    // starting the inactivity timer
    startInactivityCheck()

    channel.bind('pusher:subscription_succeeded', () => {
      store.dispatch(setPusherLoading(false))
      dependentFunc()
    })
  
    pusher.connection.bind('message', (msg) => {
      if (!msg.channel && msg.event !== 'pusher:pong') {
        store.dispatch(setPusherLoading(false))
        dependentFunc()
        disconnectFromPusher()
      }
    })
  } else {
    store.dispatch(setPusherLoading(false))
    dependentFunc()
  }
}

export class BeamManager {
  constructor() {
    this.beamsClient = null
    this.isBeamStarted = false
    this.registration = null
  }

  // Ensure proper sequencing of methods by calling initBeam() before using beamsClient
  async registerServiceWorker({ tenant_id, isEnabled }) {
    if (this.registration) {
      console.log('service worker already register')
    } else {
      if ('serviceWorker' in navigator) {
        navigator.serviceWorker.register('/serviceWorker.js')
          .then(registration => {
            this.registration = registration
            if (isEnabled) {
              this.initBeam({ tenant_id })
            } else {
              console.log('notification not enabled')
            }
          })
          .catch(error => {
            console.error('Service Worker registration failed:', error)
          })
      } else {
        console.log('Service Worker not supported')
      }
    }
  }

  async initBeam({ tenant_id }) {
    try {
      if (this.beamsClient) {
        console.log('Beam is already initialized')
      } else {
        this.beamsClient = new PusherPushNotifications.Client({
          instanceId: import.meta.env.VITE_BEAM_APP_KEY,
          serviceWorkerRegistration: this.registration
        })
      }
      await this.startBeam({ tenant_id })
    } catch (error) {
      console.error('Error occurred:', error)
    }
  }

  async startBeam({ tenant_id }) {
    if (this.isBeamStarted) {
      console.log('Beam is already started')
    } else {
      try {
        await this.beamsClient.start()
        this.isBeamStarted = true
        const interests = await this.beamsClient.getDeviceInterests()
        const isSubscribedTenantInterest = interests.includes(`new-order-notification-${tenant_id}`)
        if (!isSubscribedTenantInterest) {
          await this.beamsClient.addDeviceInterest(`new-order-notification-${tenant_id}`)
          await this.beamsClient.getDeviceInterests()
            .then(interests => {
              console.log('New Interests Subscribed', interests)
            })
        } else {
          console.log('No new interests Subscribe', interests)
        }
      } catch (error) {
        console.warn('Could not Start Beam', error)
      }
    }
  }

  async stopBeam() {
    if (this.beamsClient) {
      try {
        await this.beamsClient.stop()
        this.isBeamStarted = false
        console.log('Stop Beam')
      } catch (error) {
        console.error('Could not stop Beams state', error)
      }
    } else {
      console.log('Beams client is not initialized')
    }
  }

  async stopAndLogoutBeam() {
    if (this.beamsClient) {
      try {
        await this.stopBeam()
        this.beamsClient = null
        this.isBeamStarted = false
        this.registration = null
        await this.unRegisterServiceWorker()
        console.log('Beam is stopped and service worker unregistered')
      } catch (error) {
        console.error('Error stopping beam or unregistering service worker:', error)
      }
    } else {
      console.log('Beams client is not initialized')
    }
  }

  async unRegisterServiceWorker() {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.getRegistrations().then(registrations => {
        for (const registration of registrations) {
          registration.unregister()
            .catch(error => {
              console.error('Error while unregistering service worker:', error)
            })
        }
      })
    }
  }
}

