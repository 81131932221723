import CustomTag from '@src/@core/components/ui/badge/CustomTag'
import CopyOnClick from '@src/@core/components/ui/click-and-copy'
import AttachmentButtonBadge from '../attachmentButtonBadge'
import './TableColumnOrderId.scss'

const TableColumnOrderId = ({ omsOrderId, idToShowOnScreen, handleOrderDetails, handleGetParentData, showOrderStatusBadge = false, orderStatus, columnKey, attachments, hasAttachmentButton = false }) => {

  return (
    idToShowOnScreen ? <>
      <CopyOnClick
        id={omsOrderId}
        handleDetails={handleOrderDetails}
        columnKey={columnKey} 
      >{idToShowOnScreen}</CopyOnClick> 
      {(showOrderStatusBadge || attachments?.length > 0) && <div className='flex-fill flex-center-end ms-6px gap-6px'>
        {attachments?.length > 0 && <AttachmentButtonBadge attachments={attachments} omsOrderId={omsOrderId} handleGetParentData={handleGetParentData} hasAttachmentButton={hasAttachmentButton}/>}
        {showOrderStatusBadge && <CustomTag title={orderStatus} className='text-success bg-success-light'/>}
      </div>}
    </> : '-'
  )
}

export default TableColumnOrderId