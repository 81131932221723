import { ExpandableCustomTable } from '@src/@core/components/ui/globalTable/ExpandableCustomTable'
import SideSheet from '@src/@core/components/ui/sideSheet'
import { formattedLongDate } from '@src/utility/Utils'
import { useTranslation } from 'react-i18next'
import PerfectScrollbar from 'react-perfect-scrollbar'

const SerialisedBarcodes = ({ serialisedData }) => {
  const { data, is_batched } = serialisedData

  const { t } = useTranslation()

  const columns = [
    {
      id: 1,
      name: t('S.No.'),
      key: 'batched_serial_number',
      width: '60px',
      cell: (_, index) => <div className='text-center w-100'>{index + 1}</div>
    },
    {
      id: 2,
      name: t('Serialised Barcode'),
      key: 'batched_serialised_barcodes',
      minWidth: '220px',
      cell: (row) => <div className='txt-sub-rg'>
        {row.barcode || '-'}
      </div>
    },
    {
      id: 3,
      name: t('Batch Number'),
      key: 'batched_batch_number',
      minWidth: '220px',
      omit: !is_batched,
      cell: (row) => <div className='txt-sub-rg'>
        <p className='m-0'>{row.batch?.external_batch_id || '-'}</p>
        <p className='m-0 text-dark-6 txt-asst-rg'>{t('Expiry')}:{formattedLongDate(row.batch?.expiry_date)}</p>
      </div>
    }
  ]

  return <section className='p-24px'>
    <ExpandableCustomTable
      columns={columns}
      data={data || []}
      useReactPaginate={false}
      showPagination={false}
      showColumnsTableHeader={false}
    />
  </section>
}

const SerialisedBarcodeSidesheet = (props) => {
  const { serialisedSidesheet, setSerialiseSidesheet, serialisedData, setSerialisedData } = props

  return (
    <SideSheet
      isOpen={serialisedSidesheet}
      toggle={() => setSerialiseSidesheet(false)}
      onClosed={() => setSerialisedData({})}
      unmountOnClose={true}
      modalClassName="modal-slide-in sidebar-todo-modal"
      contentClassName="p-0 bg-white"
      size="sm"
      title='Serialised Barcodes'
    >
      <PerfectScrollbar>
        <SerialisedBarcodes serialisedData={serialisedData} />
      </PerfectScrollbar>
    </SideSheet>
  )
}

export default SerialisedBarcodeSidesheet