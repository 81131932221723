import CheckBox from '@src/@core/components/ui/new-checkbox'
import dndIcon from '@src/assets/images/icons/dndIcon.svg'
import RefreshTableIcon from '@src/assets/images/svg/table/refreshTableIcon'
import { useOnClickOutside } from '@src/utility/hooks/useOnClickOutside'
import classNames from 'classnames'
import { useEffect, useRef, useState } from 'react'
import { ChevronDown, ChevronUp } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { ReactSortable } from 'react-sortablejs'
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle
} from 'reactstrap'
import Button from '../../button'
import TableSearch from './TableSearch'
import './styles.scss'

const showingColumnsCount = (columnsList) => {
  let count = 0
  columnsList.forEach((column) => {
    if (!column.omit) {
      count++
    }
  })
  return count
}

const TableHeader = ({
  loading,
  columns,
  columnsList,
  setColumnsList,
  showColumnsDropdown,
  showColumnsTableHeader,
  searchcolumnsList,
  handleQueryParams,
  search_column_query,
  meta,
  TableHeaderComponent,
  handleRefreshTable,
  columnName,
  setSortedColumn,
  tableTitle,
  hideTableSearch
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const { t } = useTranslation()
  const { current_page = 0, total = 0, per_page = 0 } = meta
  const startItemNumber = (current_page > 0 && per_page > 0) ? ((current_page - 1) * per_page) + (per_page ? 1 : 0) : 0
  const endItemNumber = Math.min(current_page * per_page, total)
  const [currentColumnsArray, setCurrentColumnsArray] = useState(() => columnsList)
  const [activeCount, setActiveCount] = useState(
    showingColumnsCount(columnsList)
  )
  const tableHeaderOptionsRef = useRef(null)
  useOnClickOutside(tableHeaderOptionsRef, () => {
    setIsOpen(false)
    setCurrentColumnsArray(columnsList)
    setActiveCount(showingColumnsCount(columnsList))
  })
  const handleColumnShowToggle = (id) => {
    setCurrentColumnsArray((prev) => {
      return prev.map((ele) => {
        if (ele.id === id) {
          return {...ele, omit: !ele.omit}
        }
        return ele
      })
    })
  }

  const handleApplyChanges = () => {
    setColumnsList(currentColumnsArray)
    setSortedColumn(columnName, currentColumnsArray)
    setActiveCount(showingColumnsCount(currentColumnsArray))
    setIsOpen(false)
  }

  const handleReset = () => {
    setIsOpen(false)
    setCurrentColumnsArray(columns)
    setActiveCount(showingColumnsCount(columns))
    setSortedColumn(columnName, columns)
    setColumnsList(columns)
  }
  const handleToggleDropdown = () => {
    setIsOpen(prev => !prev)
  }
  const handleRestrictedSwap = (e) => {
    const { oldIndex, newIndex } = e
    if (
      !currentColumnsArray[oldIndex].reorder ||
      !currentColumnsArray[newIndex].reorder
    ) {
      const resetSwap = [...currentColumnsArray]
      resetSwap[oldIndex].order = newIndex + 1
      resetSwap[newIndex].order = oldIndex + 1
      setCurrentColumnsArray(resetSwap)
    }
  }
  useEffect(() => {
    setCurrentColumnsArray(columnsList)
  }, [columnsList])

  return (
    <>
      {showColumnsTableHeader ? (
        <div className="table-header text-dark d-flex flex-column gap-1">
          <div className="d-flex justify-content-between w-100">
            {hideTableSearch ? null : <div className="table-search-container">
              {
                searchcolumnsList && 
                  <TableSearch
                    searchcolumnsList={searchcolumnsList}
                    search_column_query={search_column_query}
                    handleQueryParams={handleQueryParams}
                  />
              }
            </div>}
            {tableTitle && <div className="table-title">{tableTitle}</div>}
            <div className={classNames('d-flex justify-content-end action-buttons-container', {'gap-1': TableHeaderComponent})}>
              {handleRefreshTable && <button className="flex-center-center table-header-button h-40px" onClick={handleRefreshTable} disabled={loading}>
                <RefreshTableIcon className={classNames({'rotate-icon': loading})}/>
              </button>}
              {TableHeaderComponent}          
            </div>
          </div>
          {showColumnsDropdown && <div className="w-100 d-flex align-items-center justify-content-between txt-sub-rg table-column-info-container">
            <span className="table-data-quantity-info">
              {
                total > 0 && (
                  <>
                    {t('Showing')} {startItemNumber}-{endItemNumber} {t('of')} {total || 0} {total > 1 ? t('Items') : t('Item')}
                  </>
                )
              }
            </span>
            <div className="position-relative showing-column-dropdown-container" ref={tableHeaderOptionsRef}>
              <Dropdown isOpen={isOpen} toggle={() => {}}>
                <DropdownToggle
                  onClick={handleToggleDropdown}
                  className="btn-sm txt-sub-rg w-100 toggle-col-btn p-0 d-flex gap-50 align-items-center showing-column-title"
                  color="white"
                >
                  <span>
                    {t('Showing')} {activeCount} {t('of')} {columnsList.length} {t('columns')}
                  </span>
                  {isOpen ? <ChevronUp size={15} /> : <ChevronDown size={15} />}
                </DropdownToggle>
                {isOpen && (
                  <DropdownMenu className="d-flex flex-column p-0 justify-content-between">
                    <ReactSortable
                      className="dropdown-options"
                      // ghostClass="sortable-ghost"
                      list={currentColumnsArray}
                      setList={setCurrentColumnsArray}
                      onEnd={(e) => handleRestrictedSwap(e)}
                    >
                      {currentColumnsArray.map((col, index) => (
                        <DropdownItem
                          key={index}
                          disabled={!col.reorder}
                          className={classNames('p-0 w-100', {'column-toggle-disabled' : !col.reorder})}
                        >
                          <div className="column-option">
                            <div className="dnd-icon-container">
                              <img className="icon" src={dndIcon} width={16} height={16}/>
                            </div>
                            <CheckBox
                              name="showing-column-dropdown-item"
                              checked={!col.omit}
                              id={col.id}
                              className="input-radio-overrides cursor-pointer max-width-100 text-truncate"
                              disabled={!col.reorder}
                              onChange={() => handleColumnShowToggle(parseInt(col.id))}
                              size='sm'
                              label={col.name}
                            />
                          </div>
                        </DropdownItem>
                      ))}
                    </ReactSortable>
                    <div
                      className="drag-columns-buttons"
                    >
                      <Button
                        type="button"
                        ofStyle="outlined"
                        ofType="compressed"
                        className="w-50 flex-center-center"
                        onClick={handleReset}
                      >
                        {t('Reset')}
                      </Button>
                      <Button
                        type="button"
                        ofType="compressed"
                        className="w-50 flex-center-center"
                        onClick={handleApplyChanges}
                      >
                        {t('Apply')}
                      </Button>
                    </div>
                  </DropdownMenu>
                )}
              </Dropdown>
            </div>
          </div>}
        </div>
      ) : null}
    </>
  )
}
export default TableHeader
