export const customStyles = (isMenuScroll = true) => ({
  container: (provided) => ({
    ...provided,
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  }),
  control: (provided) => ({
    ...provided,
    color: 'var(--bs-dark)',
    minHeight: 'auto',
    maxHeight: 'auto',
    border: 'none',
    filter: isMenuScroll ? 'drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.15))' : undefined,
    boxShadow: 'none'
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: 0,
    width: '100%'
  }),
  placeholder: provided => ({
    ...provided,
    display: 'none'
  }),
  singleValue: provided => ({
    ...provided,
    display: 'none'
  }),
  multiValue: provided => ({
    ...provided,
    display: 'none'
  }),
  indicatorsContainer: provided => ({
    ...provided,
    display: 'none'
  }),
  menu: (provided) => ({
    ...provided,
    margin: 0,
    position: 'static',
    display: 'block',
    backgroundColor: 'none',
    boxShadow: 'none',
    flexGrow: 1,
    overflowY: 'auto'
  }),
  menuList: (provided) => ({
    ...provided,
    height: '100%',
    maxHeight: '100%',
    position: 'static',
    padding: '8px 16px',
    paddingBottom: 0
  }),
  option: (provided) => ({
    ...provided,
    padding: 0,
    margin: '0 0 16px 0',
    backgroundColor: 'transparent !important',
    color: 'var(--bs-dark)',
    display: 'flex',
    alignItems: 'center',
    fontSize: '12px',
    lineHeight: '20px',
    gap: '8px'
  })
})
export const DROPDOWN_TYPES = {
  APPLIED_DROPDOWN: 'APPLIED_DROPDOWN',
  DROPDOWN: 'DROPDOWN',
  ASYNC_DROPDOWN: 'ASYNC_DROPDOWN',
  MULTI_DROPDOWN: 'MULTI_DROPDOWN',
  MULTI_ASYNC_DROPDOWN: 'MULTI_ASYNC_DROPDOWN',
  DATE_DROPDOWN: 'DATE_DROPDOWN',
  TIME_DROPDOWN: 'TIME_DROPDOWN'
}
export const IS_OPTION_VALUE_ALL = (value) => value === 'all'