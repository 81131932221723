import FailBin1 from '../../../../../assets/images/inventory/icons/FailBin1.svg'
import FailBin2 from '../../../../../assets/images/inventory/icons/FailBin2.svg'
import FailBinPlus from '../../../../../assets/images/inventory/icons/FailBinPlus.svg'
import PassBin1 from '../../../../../assets/images/inventory/icons/PassBin1.svg'
import PassBin2 from '../../../../../assets/images/inventory/icons/PassBin2.svg'
import PassBinPlus from '../../../../../assets/images/inventory/icons/PassBinPlus.svg'
export const GATE_ENTRY_OPTIONS = {
  gate_entry: { id: 'gate_entry_id', name: 'Gate Entry' },
  carton_barcode: { id: 'carton_barcode', name: 'Carton Barcode' },
  off_system_returns: { id: 'off_system_return', name: 'Return Orders' }
}
export const SEARCH_SKU_TYPES = {
  sku_barcode: { id: 'sku_barcode', name: 'SKU Barcode' },
  search_sku: { id: 'search_sku', name: 'Search SKU' }
}

export const SELLER_SUPPLIER_OPTIONS = {
  seller_supplier: {id: 'seller_supplier', name: 'Seller & Supplier'},
  off_system_returns: { id: 'off_system_returns', name: 'Return Orders' }
}

export const PO_AND_STO_ENABLED_OPTIONS = {
  purchase_order_id : { id: 'purchase_order_id', name: 'Purchase Order'},
  stock_transfer_order_id : { id: 'stock_transfer_order_id', name: 'Stock Transfer Order'},
  off_system_returns: { id: 'off_system_returns', name: 'Return Orders' }
}

export const PO_ENABLED_OPTIONS = {
  purchase_order_id : { id: 'purchase_order_id', name: 'Purchase Order'},
  off_system_returns: { id: 'off_system_returns', name: 'Return Orders' }
}

export const STO_ENABLED_OPTIONS = {
  stock_transfer_order_id : { id: 'stock_transfer_order_id', name: 'Stock Transfer Order'},
  seller_supplier: {id: 'seller_supplier', name: 'Seller & Supplier'},
  off_system_returns: { id: 'off_system_returns', name: 'Return Orders' }
}

export const SERIALISATION_OPTION = {
  non_serialised: {id:'non_serialised', name :'Non-Serialised'},
  serialised:{id:'serialised', name : 'Serialised'}
}

export const BIN_BARCODE_STATUS = {
  pass : 'pass',
  fail: 'fail'
}
export const PO_AND_STO_ENTITY_TYPE = {
  PO : 'po',
  STO: 'sto'
}
export const GRN_STEPS = {
  GRN_AND_BIN_DETAILS: 1,
  GRN_QC: 2
}
export const GRN_SOURCE_TYPE = ['gate_entry', 'purchase_order', 'stock_transfer_order']

export const UNDEFINED = 'undefined'

export const BIN_TYPE = {
  pass_bin: { id: 'pass_bin', status: 'Pass', name: 'Pass Bin', bin_icon: PassBin1, plus_icon: PassBinPlus, bin_border_icon: PassBin2 },
  fail_bin: {id: 'fail_bin', status: 'Fail', name: 'Fail Bin', bin_icon: FailBin1, plus_icon: FailBinPlus, bin_border_icon: FailBin2}
}

export const BATCH_STATUS = {
  BATCHED: 'batched',
  NOT_BATCHED: 'not_batched'
}
export const BIN_STATUS = {
  FAIL: 'Fail',
  PASS: 'Pass'
}

export const BULK_UPLOAD_GRN_SAMPLE_FILE = 'https://omniful-staging-uploads.s3.eu-central-1.amazonaws.com/wms/grn/Sample+Bulk+Grn+Serialisation+-+Sheet1.csv'

export const BIN_DISABLED_GRN_SAMPLE_FILE = 'https://omniful-staging-uploads.s3.eu-central-1.amazonaws.com/wms/grns/bulk/serialised/Bulk+Serialised+GRN+-+Bulk%2BSerialize%2BGRN+(2).csv'

export const BULK_UPLOAD_PUSHER_RES = 'grn_serialisation_barcode_upload'