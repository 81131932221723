import { components } from 'react-select'
import classNames from 'classnames'

const CustomDropdownOption = (props) => {
  const {data: {label, color, logo, value}} = props
  const labelStyle = {color: color || 'text-dark', background: color ? `${color}1A` : 'transparent'}
  const { Option } = components
  return (
    <Option key={value} {...props}>
      <div className="flex-center-between" title={label}>
        <div className={classNames('cursor-pointer text-truncate', {'rounded px-4px py-2px' : color})} style={labelStyle}>{label}</div>
        {logo && <div className='cursor-pointer bg-white rounded shadow-1 p-4px border'><img className='object-fit-contain' src={logo} width={32} height={16} alt={label}/></div>}
      </div>
    </Option>
  )
}
export default CustomDropdownOption