export const ASSEMBLE_STATUS_OPTIONS = {
  PENDING: 'Pending',
  COLLECTING: 'Collecting',
  READY_TO_ASSEMBLE: 'Ready To Assemble',
  ASSEMBLING: 'Assembling',
  COMPLETED: 'Completed'
}

export const getBadgeClassNamesForProgress = (progress) => {
  switch (progress) {
  case ASSEMBLE_STATUS_OPTIONS.PENDING: return 'bg-danger-light text-danger'
  case ASSEMBLE_STATUS_OPTIONS.COLLECTING: return 'bg-brown-light text-brown'
  case ASSEMBLE_STATUS_OPTIONS.READY_TO_ASSEMBLE: return 'bg-success-light text-success'
  case ASSEMBLE_STATUS_OPTIONS.ASSEMBLING: return 'bg-warning-light text-warning'
  case ASSEMBLE_STATUS_OPTIONS.COMPLETED: return 'bg-dark-1 text-dark'
  }
}

export const MAX_BARCODE_HEIGHT = 28

export const initial_config_state = { width: 10, height: 8 }

export const ASSEMBLY_BARCODES_BATCH_SIZE = 100

export const SIDESHEET_VIEWS = {
  SET_SERIALISATION: 'set_serialisation',
  PRINT_BARCODES: 'print_barcodes'
}

export const BARCODE_SETTING = {
  SERIALISED: 'serialised',
  NON_SERIALISED: 'non-serialised'
}