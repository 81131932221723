import { ExpandableCustomTable } from '@src/@core/components/ui/globalTable/ExpandableCustomTable'
import SKUDetailsCol from '@src/@core/components/ui/sku-details'
import abilityMap from '@src/assets/data/abilityMapping/abilityMapping'
import navigateIcon from '@src/assets/images/orders/trackingOrder.svg'
import { AbilityContext } from '@src/utility/context/Can'
import { formattedLongDate } from '@src/utility/Utils'
import AssembledKitsSidesheet from '@src/views/inventory/components/inventoryOperations/assembly/AssembledKitsSidesheet'
import { SKU_TYPES } from '@src/views/sales/constant/orders.constants'
import SidebarContainer from '@src/views/sales/picking-wave/pages/sidebarContainer/SidebarContainer'
import { SetcompletedWaveState, getPicklistsOfStatus, getWmsOrderDetails } from '@src/views/sales/store/store'
import { memo, useContext, useEffect, useState } from 'react'
import { Info, Plus } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap'
import OrderItemsChildTable from '../../../ordersForApproval/OrderItemsChildTable/OrderItemsChildTable'
import BatchesSidesheet from './components/BatchesSidesheet'
import FailedSkuDetailsSidesheet from './components/FailedSkuDetailsSidesheet'
import NonSerialisedBarcodeSidesheet from './components/NonSerialisedBarcodeSidesheet'
import SerialisedBarcodeSidesheet from './components/SerialisedBarcodeSidesheet'
import WmsDetailsTableColumns from './components/wmsDetailsColumns'

const SEARCH_LIST = [
  { id: 'name', name: 'SKU' },
  { id: 'seller_sku_code', name: 'SKU Code' }
]

const CommonDropdown = ({ items, label }) => {
  const [isOpen, setIsOpen] = useState(false)

  const toggleDropdown = () => { setIsOpen(!isOpen) }

  return <Dropdown isOpen={isOpen} toggle={toggleDropdown}>
    <DropdownToggle tag="span">+{label}</DropdownToggle>
    <DropdownMenu className='cursor-default'>
      {items.map((item, index) => <DropdownItem className='w-100 txt-sub-rg text-dark cursor-default'
        text key={index}> {item} </DropdownItem>)}
    </DropdownMenu>
  </Dropdown>

}

const WmsOrderDetails = (props) => {
  const { sellerId, hubId } = props
  const { wmsOrderDetailsData, loading, error, success } = useSelector(store => ({
    wmsOrderDetailsData: store.sales.wmsOrderDetailsData,
    loading: store.sales.loading,
    error: store.sales.error,
    success: store.sales.success
  }))
  const selectedPicklistStatus = useSelector(store => store.sales.selectedPicklistStatus)
  const orderDetailsData = wmsOrderDetailsData.data || {}
  const [searchQuery, setSearchQuery] = useState({ column: SEARCH_LIST[0] })
  const [pagination, setPagination] = useState()
  const [showAssembledKitsSidesheet, setShowAssembledKitsSidesheet] = useState(false)
  const [currentTask, setCurrentTask] = useState(null)
  const [serialisedSidesheet, setSerialiseSidesheet] = useState(false)
  const [serialisedData, setSerialisedData] = useState({})
  const [failedSkuSidesheet, setFailedSkuSidesheet] = useState(false)
  const [failedSkuData, setFailedSkuData] = useState({})
  const [batchesSidesheet, setBatchesSidesheet] = useState(false)
  const [batchData, setBatchData] = useState({})
  const [nonSerialisedSidesheet, setNonSerialiseSidesheet] = useState(false)
  const [nonSerialisedData, setNonSerialisedData] = useState({})
  const [isSidebarOpen, setIsSidebarOpen] = useState(false)
  const [waveDetails, setWaveDetails] = useState(null)
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const ability = useContext(AbilityContext)

  const handleSearchItem = ({ searchVal, selectedColumn = null }) => {
    const searchQuery = searchVal ? { column: selectedColumn, query: searchVal } : { column: selectedColumn }
    setSearchQuery(searchQuery)
  }

  const handlePagination = page => {
    const pagination = { page: page.selected, per_page: page.per_page ? page.per_page : 10 }
    setPagination(pagination)
  }

  const handleShowAssembledKits = () => {
    const taskData = {
      id: orderDetailsData.assembly_task_details?.id,
      name: orderDetailsData.assembly_task_details?.name,
      created_at: orderDetailsData.assembly_task_details?.created_at,
      status: orderDetailsData.assembly_task_details?.status,
      order_details: { seller_sales_channel_order_id: orderDetailsData.seller_sales_channel_order_id }
    }
    setCurrentTask(taskData)
    setShowAssembledKitsSidesheet(true)
  }

  const handleBarcodesSidesheet = (data) => {
    setSerialisedData(data)
    setSerialiseSidesheet(true)
  }

  const handleBatchesSidesheet = (row) => {
    const data = row.batch
    setBatchData({ data })
    setBatchesSidesheet(true)
  }

  const handleFailedSkuSidesheet = (row) => {
    setFailedSkuData({ data: row.fail_details, is_serialised: row.sku?.is_serialised, is_batched: row.sku?.is_batched })
    setFailedSkuSidesheet(true)
  }

  const handleNonSerialisedSidesheet = (row) => {
    setNonSerialisedData({ data: row.barcodes })
    setNonSerialiseSidesheet(true)
  }

  const handleWaveDetails = () => {
    setWaveDetails({
      wave: {
        waveId: orderDetailsData.wave_details?.id,
        name: orderDetailsData.wave_details?.name,
        time: orderDetailsData.wave_details?.created_at
      }
    })
    setIsSidebarOpen(true)
    dispatch(getPicklistsOfStatus({ hub_id:hubId, waveId: orderDetailsData.wave_details?.id }))
    dispatch(SetcompletedWaveState(false))
  }

  const TableColumns = WmsDetailsTableColumns(
    { handleBarcodesSidesheet, handleBatchesSidesheet, handleFailedSkuSidesheet, handleNonSerialisedSidesheet })
  
  const childTableColumns = [
    {
      id: 1,
      name: t('SKU Details'),
      minWidth: '340px',
      maxWidth: '400px',
      cell: (row) => { 
        return (
          <SKUDetailsCol
            skuDetailData={{
              isValidSku: true,
              id: row.sku.seller_sku_id,
              image: {
                src: row.sku.images?.[0]?.url,
                alt: row.sku.name
              },
              details: {
                name: row.sku.name,
                skuCode: row.sku.seller_sku_code,
                columnKey: 'hub_operations_sku_details'
              }
            }}
          />
        )
      }
    },
    {
      id: 2,
      name: <div className="w-100 text-end text-truncate" title={t('Picked Qty')}>{t('Picked Qty')}</div>,
      minWidth: '140px',
      cell: (row) => <div className="w-100 text-end">
        {row.picked_quantity}
      </div>
    },
    {
      id: 3,
      name: t('Barcode'),
      minWidth: '200px',
      cell: (row) => {
        return (
          <>
            {row.sku.is_serialised ?
              row.serialised_barcodes?.[0]?.barcode ? <div className="flex-center-start w-100 gap-8px">
                <span className='txt-sub-rg text-dark'>{row.serialised_barcodes[0].barcode}</span>
                {row.serialised_barcodes.length > 1 ?
                  <p className="cursor-pointer flex-center-center gap-2px m-0 ps-6px pe-8px"
                    style={{
                      color: 'var(--bs-primary)',
                      border: '1px solid var(--bs-primary)',
                      borderRadius: '12px'
                    }}
                    onClick={() => handleBarcodesSidesheet({ data: row.serialised_barcodes, is_batched: row.sku?.is_batched })}>
                    <Plus size={12} color="var(--bs-primary)" />
                    <span className="txt-asst-rg pt-2px">{row.serialised_barcodes.length - 1}</span>
                  </p>
                  : null}
              </div> : '-'
              :
              row.barcodes?.length ? <div className="flex-center-start w-100 gap-8px">
                <span className='txt-sub-rg text-dark'>{row.barcodes[0]}</span>
                {row.barcodes.length > 1 ?
                  <p className="cursor-pointer flex-center-center gap-2px m-0 ps-6px pe-8px"
                    style={{
                      color: 'var(--bs-primary)',
                      border: '1px solid var(--bs-primary)',
                      borderRadius: '12px'
                    }}
                    onClick={() => handleNonSerialisedSidesheet(row)}>
                    <Plus size={12} color="var(--bs-primary)" />
                    <span className="txt-asst-rg pt-2px">{row.barcodes.length - 1}</span>
                  </p>
                  : null}
              </div> : '-'
            }
          </>
        )
      }
    },
    {
      id: 4,
      name: t('Batch Number'),
      minWidth: '240px',
      cell: (row) => <div className="w-100 d-flex justify-content-between">
        {
          row.batch?.[0]?.batch?.external_batch_id ?
            <div className="w-100 flex-center-start gap-8px">
              <div>
                <p className='txt-sub-rg text-dark m-0'>{row.batch[0].batch.external_batch_id}</p>
                <p className="m-0 text-dark-6 txt-asst-rg">{t('Expiry')}: {formattedLongDate(row.batch[0].batch.expiry_date)}</p>
              </div>
              {row.batch.length > 1 ?
                <p className="cursor-pointer flex-center-center gap-2px m-0 ps-6px pe-8px rounded-12px text-primary-global border border-primary-global"
                  onClick={() => handleBatchesSidesheet(row)}>
                  <Plus size={12} color="var(--bs-primary)" />
                  <span className="txt-asst-rg pt-2px">{row.batch.length - 1}</span>
                </p>
                : null}
            </div>
            : '-'
        }
      </div>
    },
    {
      id: 5,
      name: <div className="w-100 text-end text-truncate" title={t('Packed Qty')}>{t('Packed Qty')}</div>,
      minWidth: '140px',
      cell: (row) => <div className="w-100 text-end text-success">
        {row.packed_quantity}
      </div>
    },
    {
      id: 6,
      name: <div className="w-100 text-end">{t('Failed Qty')}</div>,
      minWidth: '150px',
      cell: (row) => <div className="w-100 text-end">
        <div className="flex-center-end gap-8px">
          <span className='text-danger'>{row.failed_quantity}</span>
          {row.failed_quantity ?
            <Info size={16} color='var(--bs-primary)' className='cursor-pointer'
              strokeWidth={2} onClick={() => handleFailedSkuSidesheet(row)} /> : null}
        </div>
      </div>
    },
    {
      id: 7,
      name: <div className="w-100 text-end text-truncate" title={t('Putaway Qty')}>{t('Putaway Qty')}</div>,
      minWidth: '150px',
      cell: (row) => <div className="w-100 text-end">{row.pending_put_away_quantity}</div>
    }
  ]

  useEffect(() => {
    const params = {
      oms_order_id: sellerId,
      search_column: searchQuery?.query ? searchQuery?.column?.id : undefined,
      search_query: searchQuery?.query || undefined,
      ...pagination
    }
    dispatch(getWmsOrderDetails({params, hubId}))
  }, [searchQuery, pagination])

  return (
    <>
      <>
        <section className='d-flex flex-column gap-12px'>

          {orderDetailsData.picked_by?.length ? <div className='d-flex'>
            <span className='txt-body-rg text-dark-5 d-inline-block width-200'>
              {orderDetailsData.assembly_task_details?.id ? t('Collected By:') : t('Picked By:')}
            </span>
            <div className='d-flex'>
              <span className='txt-body-rg text-dark'>{orderDetailsData.picked_by.slice(0, 3)?.join(', ')}</span>
              {orderDetailsData.picked_by.slice(3)?.length ?
                <div className='flex-center-start'>
                  <span className='text-dark'>&nbsp;,</span>
                  <span className='txt-body-rg text-primary cursor-pointer'>
                    <CommonDropdown items={orderDetailsData.picked_by.slice(3)} label={orderDetailsData.picked_by.slice(3).length} />
                  </span>
                </div> : null}
            </div>
          </div> : null}

          {!orderDetailsData.wave_details?.name && !orderDetailsData.assembly_task_details?.id ? <div>
            <span className='txt-body-rg d-inline-block width-200'>{t('Picking Method:')}</span>
            <span className='txt-body-rg text-dark'>{orderDetailsData.picking_method}</span>
          </div> : null}

          {orderDetailsData.wave_details?.name ? <div className='flex-center-start'>
            <span className='txt-body-rg d-inline-block width-200'>{t('Picking Wave:')}</span>
            <span className='txt-body-rg text-dark pe-8px'>{orderDetailsData.wave_details.name}</span>
            {ability.can(abilityMap.picking.view_wave.action, abilityMap.picking.view_wave.resource) && <img src={navigateIcon} width={18} height={18} alt='navigate-to-wave-page'
              onClick={handleWaveDetails} className='cursor-pointer'
            />}
            <span className='ps-8px'>{orderDetailsData.wave_details?.created_at}</span>
          </div> : null}

          {orderDetailsData.assembly_task_details?.id ? <div className='d-flex'>
            <span className='txt-body-rg d-inline-block width-200'>{t('Assembled By:')}</span>
            {orderDetailsData.assembly_task_details?.assembled_by?.length ? <div className='d-flex'>
              <span className='txt-body-rg text-dark'>{orderDetailsData.assembly_task_details.assembled_by.slice(0, 3)?.join(', ')}</span>
              {orderDetailsData.assembly_task_details.assembled_by.slice(3)?.length ? <div className='flex-center-start'>
                <span className='text-dark'>&nbsp;,</span>
                <span className='txt-body-rg text-primary cursor-pointer'>
                  <CommonDropdown items={orderDetailsData.assembly_task_details.assembled_by.slice(3)} label={orderDetailsData.assembly_task_details.assembled_by.slice(3).length} />
                </span></div> : null}
            </div> : '-'}
          </div> : null}

          {orderDetailsData.assembly_task_details?.name ? <div>
            <span className='txt-body-rg d-inline-block width-200'>{t('Assembly Task:')}</span>
            <span className='txt-body-rg text-primary cursor-pointer' onClick={handleShowAssembledKits}>{orderDetailsData.assembly_task_details.name}</span>
          </div> : null}

          {orderDetailsData.packed_by?.length > 0 && <div className='d-flex'>
            <span className='txt-body-rg d-inline-block width-200'>{t('Packed By:')}</span>
            {orderDetailsData.packed_by?.length ? <div className='d-flex'>
              <span className='txt-body-rg text-dark'>{orderDetailsData.packed_by.slice(0, 3)?.join(', ')}</span>
              {orderDetailsData.packed_by.slice(3)?.length ? <div className='flex-center-start'>
                <span className='text-dark'>&nbsp;,</span>
                <span className='txt-body-rg text-primary cursor-pointer'>
                  <CommonDropdown items={orderDetailsData.packed_by.slice(3)} label={orderDetailsData.packed_by.slice(3).length} />
                </span>
              </div> : null}
            </div> : '-'}
          </div>}

        </section>

        <div className='py-16px'>
          <ExpandableCustomTable
            loading={loading.getWmsOrderDetails}
            error={error.getWmsOrderDetails}
            success={success.getWmsOrderDetails}
            columns={TableColumns}
            data={orderDetailsData.warehouse_order_items || []}
            meta={wmsOrderDetailsData.meta}
            searchcolumnsList={SEARCH_LIST}
            search_column_query={searchQuery}
            handleQueryParams={handleSearchItem}
            showColumnsDropdown={false}
            useReactPaginate={false}
            handlePagination={handlePagination}
            expandableRows
            expandableRowsComponent={OrderItemsChildTable}
            expandableRowsComponentProps={{ childTableColumns, childDataKey: 'child_warehouse_order_items'}}
            expandableRowDisabled={row => row.type !== SKU_TYPES.BUNDLE.key}
            expandableRowExpanded={row => row.type === SKU_TYPES.BUNDLE.key}
          />
        </div>
      </>

      <AssembledKitsSidesheet
        showAssembledKitsSidesheet={showAssembledKitsSidesheet}
        setShowAssembledKitsSidesheet={setShowAssembledKitsSidesheet}
        currentTask={currentTask}
        hub_id={hubId}
      />

      <SerialisedBarcodeSidesheet
        serialisedSidesheet={serialisedSidesheet}
        setSerialiseSidesheet={setSerialiseSidesheet}
        serialisedData={serialisedData}
        setSerialisedData={setSerialisedData}
      />

      <FailedSkuDetailsSidesheet
        failedSkuSidesheet={failedSkuSidesheet}
        setFailedSkuSidesheet={setFailedSkuSidesheet}
        failedSkuData={failedSkuData}
        setFailedSkuData={setFailedSkuData}
      />

      <BatchesSidesheet
        batchesSidesheet={batchesSidesheet}
        setBatchesSidesheet={setBatchesSidesheet}
        batchData={batchData}
        setBatchData={setBatchData}
      />

      <NonSerialisedBarcodeSidesheet
        nonSerialisedSidesheet={nonSerialisedSidesheet}
        setNonSerialiseSidesheet={setNonSerialiseSidesheet}
        nonSerialisedData={nonSerialisedData}
        setNonSerialisedData={setNonSerialisedData}
      />

      <SidebarContainer
        is_sidebar_open={isSidebarOpen}
        set_is_sidebar_open={setIsSidebarOpen}
        waveDetails={waveDetails}
        status={selectedPicklistStatus?.value}
        hub_id={hubId}
      />
    </>
  )
}

export default memo(WmsOrderDetails)