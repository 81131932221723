import './style.scss'
const ProgressBar = ({ progress=0 }) => {
  return (
    <div className="progress-bar-layout-wrapper">
      <div className="progress-bar-layout">
        <div
          className='inner-content-progress-bar'
          style={{
            transform: `scaleX(${progress / 100})`
          }}
        ></div>
      </div>
    </div>
  )
}

export default ProgressBar