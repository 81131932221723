import Button from '@src/@core/components/ui/button'
import { ExpandableCustomTable } from '@src/@core/components/ui/globalTable/ExpandableCustomTable'
import { ALL_COLUMN_NAME_MAPPING } from '@src/@core/components/ui/globalTable/globalTable.constant'
import trackingImage from '@src/assets/images/orders/trackingOrder.svg'
import { initRealTime } from '@src/network/RealTime'
import { printMultipleAwb } from '@src/utility/Utils'
import Invoice from '@src/views/sales/components/invoice/invoice'
import CancelShipmentPopUp from '@src/views/sales/create/CancelShipmentPopUp'
import { ShippingAccountColumn } from '@src/views/sales/sales.utils'
import { getAWBDetail, resetSuccess } from '@src/views/sales/store/store'
import { useQueryClient } from '@tanstack/react-query'
import classNames from 'classnames'
import { useEffect, useRef, useState } from 'react'
import { Info, MoreVertical } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { DropdownItem, DropdownMenu, DropdownToggle, Spinner, UncontrolledDropdown, UncontrolledTooltip } from 'reactstrap'
import CustomTag from '../../../@core/components/ui/badge/CustomTag'
import CopyOnClick from '../../../@core/components/ui/click-and-copy'
import TableColumnOrderId from '../../sales/components/TableColumnOrderId/TableColumnOrderId'
import { API_KEYS, BULK_SHIPMENT_STATUSES } from '../bulk-ship.constants'

const ShipmentDetails = (props) => {
  const {
    tableData,
    isGetOrdersFetching,
    isGetOrdersError,
    isGetOrdersSuccess,
    fetchOrdersResponse,
    searchQuery,
    setSearchQuery,
    handlePagination,
    pagination,
    handleOrderDetails,
    cancelShipment,
    cancelShipmentPending,
    shipmentDetailsSearchCol,
    selectedData,
    setSelectedData
  } = props
  const { t } = useTranslation()
  const printInvoiceRef = useRef(null)
  const dispatch = useDispatch()
  const queryClient = useQueryClient()
  const pusherLoading = useSelector(store => store.auth.pusherLoading)
  const success = useSelector((store) => store.sales.success)

  const [selectedOrder, setSelectedOrder] = useState({})
  const [orderForPrintInvoice, setOrderForPrintInvoicer] = useState({}) // state exclusive for print invoice
  const [cancelShipmentModal, setCancelShipmentModal] = useState(false)

  const handleSelectedData = (data) => {
    setSelectedData(data?.selectedRows)
  }

  const handleBulkCancelShipment = () => {
    // if (selectedData.length === 0) {
    //   CustomToast("Please select one or more orders", { my_type: "info" })
    //   return
    // }
    initRealTime(() => {
      cancelShipment({ selectedData })
    })
  }

  const handleCancelShipment = (row) => {
    setSelectedOrder(row)
    setCancelShipmentModal(true)
  }

  const handlePrintInvoice = (row) => {
    setOrderForPrintInvoicer(row)
  }

  const handleSearchItem = ({ searchVal, selectedColumn = null }) => {
    const searchQuery = searchVal ? { column: selectedColumn, query: searchVal } : {}
    setSearchQuery(searchQuery)
  }


  const handlePrintAwb = async (row) => {
    if (row.shipment.awb_label) {
      printMultipleAwb(row.shipment.awb_label)
    } else {
      const res = await getAWBDetail(row.id)
      if (res?.is_success && res?.data?.awb_label) {
        printMultipleAwb(res.data.awb_label)
      }
    }
  }

  const tableColumns = [
    {
      id: 1,
      name: t('S. No.'),
      key: 'serail_number',
      width: '64px',
      selector: row => row,
      reorder: false,
      cell: (row) => (
        <div className="w-100 text-center">{row.serialNumber}</div>
      )
    },
    {
      id: 2,
      name: t('Order ID'),
      key: 'seller_sales_channel_order_id',
      minWidth: '280px',
      maxWidth: '300px',
      reorder: false,
      cell: row => <>
        <CopyOnClick
          id={row.id}
          handleDetails={() => handleOrderDetails(row)}
          columnKey='seller_sales_channel_order_id'
        >{row.orderID}</CopyOnClick>
        {/* {showOrderStatusBadge && <CustomTag title={orderStatus} className='text-success bg-success-light ms-6px' />} */}
      </>
    },
    {
      id: 3,
      name: t('Reference Order ID'),
      key: 'reference_order_id',
      width: '180px',
      reorder: false,
      cell: (row) => <TableColumnOrderId key={row.referenceOrderID} omsOrderId={row.id} idToShowOnScreen={row.referenceOrderID} columnKey="reference_order_id" />
    },
    {
      id: 4,
      name: t('Shipment Status'),
      key: 'shipment_status',
      minWidth: '200px',
      maxWidth: '18.75%',
      reorder: true,
      cell: (row) => (
        <div className="w-100 flex-center-between">
          <span
            className={classNames({
              'text-danger': row.shipment?.creation_status === 'failed'
            })}
          >
            {row.shipment?.display_creation_status}
          </span>
          {row.shipment?.creation_status === 'failed' && <span>
            <Info size={16} strokeWidth={1.5} color="var(--bs-danger)" id={`tooltip-${row.id}`} />
            <UncontrolledTooltip target={`tooltip-${row.id}`} placement="top" offset={[0, 5]} >
              <span>{row.shipment?.creation_error}</span>
            </UncontrolledTooltip>
          </span>}
        </div>
      )
    },
    {
      id: 5,
      name: t('Order Tags'),
      key: 'tags',
      minWidth: '250px',
      maxWidth: '300px',
      reorder: true,
      cell: (row) => {
        return (
          <div className="d-flex flex-wrap gap-6px">
            {
              row.orderTags?.length > 0 ? row.orderTags.map((tag, index) => {
                return (
                  <CustomTag key={index} style={{ color: tag.color, backgroundColor: `${tag.color}1A` }} title={tag.name} />
                )
              }) : <div className="w-100 text-center">-</div>
            }
          </div>
        )
      }
    },
    {
      id: 6,
      name: t('Order Source'),
      key: 'order_source',
      minWidth: '170px',
      reorder: true,
      selector: (row) => row.displaySource
    },
    {
      id: 7,
      name: t('Shipping Account'),
      key: 'order_created_at',
      minWidth: '210px',
      maxWidth: '210px',
      reorder: true,
      cell: (row) => ShippingAccountColumn(row.shipment)
    },
    {
      id: 8,
      name: t('AWB Number'),
      key: 'awb_number',
      minWidth: '200px',
      maxWidth: '18.75%',
      reorder: true,
      cell: row => (
        <div className="w-100 h-100 d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center gap-50">
            {row.tracking_url && <a href={row.tracking_url} target="_blank" rel="noreferrer"><img src={trackingImage} alt="tracking-image" /></a>}
            <span>{row.awbNumber || '-'}</span>
          </div>
        </div>
      )
    },
    {
      id: 9,
      name: '',
      key: 'action',
      width: '60px',
      reorder: false,
      cell: (row) => {
        return (
          <div className='flex-center-center w-100'>
            <UncontrolledDropdown>
              <DropdownToggle className='pe-1 cursor-pointer more_vert rounded-4px' tag='span'>
                <div className='border p-4px rounded-4px border-dark-2 overflow-hidden'>
                  <MoreVertical size={16} />
                </div>
              </DropdownToggle>
              <DropdownMenu container='body' className='py-0'>
                <>
                  <DropdownItem
                    onClick={(e) => {
                      e.preventDefault()
                      handlePrintAwb(row)
                    }}
                    className='txt-sub-rg rounded-4px w-100 text-dark'
                  >
                    <span className='align-middle'>{t('Print AWB')}</span>
                  </DropdownItem>
                  <DropdownItem
                    onClick={(e) => {
                      e.preventDefault()
                      handlePrintInvoice(row)
                    }}
                    className='txt-sub-rg rounded-4px w-100 text-dark'
                  >
                    <span className='align-middle'>{t('Print Invoice')}</span>
                  </DropdownItem>
                  <DropdownItem
                    onClick={(e) => {
                      e.preventDefault()
                      handleCancelShipment(row)
                    }}
                    className='txt-sub-rg rounded-4px w-100 text-dark'
                  >
                    <span className='align-middle'>{t('Cancel Shipment')}</span>
                  </DropdownItem>
                </>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        )
      }
    }
  ]

  const rowDisabledCriteria = (row) => {
    return row.shipment.creation_status === BULK_SHIPMENT_STATUSES.IN_PROGRESS.value || row.shipment.creation_status === BULK_SHIPMENT_STATUSES.FAILED.value || row.shipment.creation_status === BULK_SHIPMENT_STATUSES.PENDING.value
  }

  useEffect(() => {
    if (success.cancelShipment) {
      setCancelShipmentModal(false)
      dispatch(resetSuccess())
      queryClient.invalidateQueries({ queryKey: [API_KEYS.FETCH_WAVE_SHIPMENT_DATA] })
    }

    if (orderForPrintInvoice.id) {
      printInvoiceRef.current.click()
    }

    return () => {
      if (orderForPrintInvoice.id) {
        setOrderForPrintInvoicer({})
      }
    }
  }, [success.cancelShipment, orderForPrintInvoice])


  return <>
    <ExpandableCustomTable
      key={`${isGetOrdersFetching}}`}
      loading={isGetOrdersFetching}
      error={isGetOrdersError}
      success={isGetOrdersSuccess}
      data={tableData}
      meta={fetchOrdersResponse?.meta || {}}
      columns={tableColumns}
      searchcolumnsList={shipmentDetailsSearchCol}
      showPagination={true}
      showColumnsTableHeader={true}
      handleQueryParams={handleSearchItem}
      search_column_query={searchQuery}
      useReactPaginate={false}
      handlePagination={handlePagination}
      pagination={pagination}
      columnName={ALL_COLUMN_NAME_MAPPING.CLUSTER_DETAILS_SHIPMENT_DETAILS}
      selectable={true}
      TableHeaderComponent={
        <div className=''>
          <Button disabled={cancelShipmentPending || pusherLoading} ofStyle='outlined' onClick={handleBulkCancelShipment}>
            {(cancelShipmentPending) && <Spinner size='sm' />}
            {t('Cancel Shipment')}
          </Button>
        </div>
      }
      handleSelectedRowsChange={handleSelectedData}
      selectableRowDisabled={rowDisabledCriteria}
    />
    <div className='d-none'>
      <Invoice printInvoiceRef={printInvoiceRef} order_id={orderForPrintInvoice.id} cleanupFunction={() => setOrderForPrintInvoicer({})}/>
    </div>

    <CancelShipmentPopUp deleteModalOpen={cancelShipmentModal} setDeleteModalOpen={setCancelShipmentModal} shipping_partner_tag={selectedOrder.shipment?.shipping_partner_tag} awb_number={selectedOrder.shipment?.awb_number} shipmentOrderID={selectedOrder.shipment?.shipment_order_id}/>
  </>
}

export default ShipmentDetails