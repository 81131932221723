import { ExpandableCustomTable } from '@src/@core/components/ui/globalTable/ExpandableCustomTable'
import SideSheet from '@src/@core/components/ui/sideSheet'
import no_image from '@src/assets/images/orders/imageNotFound.svg'
import { formattedLongDate } from '@src/utility/Utils'
import { useTranslation } from 'react-i18next'
import PerfectScrollbar from 'react-perfect-scrollbar'

const FailedSkus = ({ failedSkuData }) => {
  const { data, is_serialised, is_batched } = failedSkuData
  const { t } = useTranslation()

  const columns = [
    {
      id: 1,
      name: t('S.No.'),
      key: 'failed_sku_serial_number',
      width: '60px',
      cell: (_, index) => <div className='text-center w-100'>{index + 1}</div>
    },
    {
      id: 2,
      name: t('Serialised Barcode'),
      key: 'failed_sku_serialised_barcodes',
      minWidth: '150px',
      omit: !is_serialised,
      cell: (row) => <div className='txt-sub-rg'>
        {row.serialised_barcode || '-'}
      </div>
    },
    {
      id: 3,
      name: t('Batch Number'),
      key: 'failed_sku_batch_number',
      minWidth: '140px',
      omit: !is_batched,
      cell: (row) => <div className='txt-sub-rg'>
        <p className='m-0'>{row.batch?.external_batch_id || '-'}</p>
        <p className='m-0 text-dark-6 txt-asst-rg'>{t('Expiry')}:{formattedLongDate(row.batch?.expiry_date)}</p>
      </div>
    },
    {
      id: 4,
      name: t('Reason For Failure'),
      key: 'failed_sku_reason',
      minWidth: '140px',
      cell: (row) => <div className='txt-sub-rg'>
        {row.reason || '-'}
      </div>
    },
    {
      id: 5,
      name: <div className="w-100 text-end">{t('Fail Qty')}</div>,
      key: 'failed_sku_fail_qty',
      minWidth: '60px',
      cell: (row) => <div className='txt-sub-rg w-100 text-end'>
        {row.quantity}
      </div>
    },
    {
      id: 6,
      name: t('Failed Item Images'),
      key: 'failed_sku_fail_images',
      minWidth: '150px',
      cell: (row) => <div className='txt-sub-rg flex-center-start gap-8px'>
        {row.images?.length ?
          row.images.slice(0, 3).map((item, index) => <img key={index} src={item} width={28} height={28} alt="no img"
            className="rounded-4px"
            onError={({ currentTarget }) => {
              currentTarget.onerror = null
              currentTarget.src = no_image
            }}
          />)
          : '-'}
      </div>
    }
  ]

  return <section className='p-24px'>
    <ExpandableCustomTable
      columns={columns}
      data={data || []}
      showPagination={false}
      showColumnsTableHeader={false}
    />
  </section>
}

const FailedSkuDetailsSidesheet = (props) => {

  const { failedSkuSidesheet, setFailedSkuSidesheet, failedSkuData, setFailedSkuData } = props

  return (
    <SideSheet
      isOpen={failedSkuSidesheet}
      toggle={() => setFailedSkuSidesheet(false)}
      unmountOnClose={true}
      onClosed={() => setFailedSkuData({})}
      modalClassName="modal-slide-in sidebar-todo-modal"
      contentClassName="p-0 bg-white"
      size="sm"
      title='Failed SKU Details'
    >
      <PerfectScrollbar>
        <FailedSkus failedSkuData={failedSkuData} />
      </PerfectScrollbar>
    </SideSheet>
  )
}

export default FailedSkuDetailsSidesheet