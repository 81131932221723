import { ErrorState } from '@src/assets/data/assets'
import { useTranslation } from 'react-i18next'
const ErrorNoResultFound = () => {
  const {t} = useTranslation()
  return (
    <div className='d-flex flex-column align-items-center'>
      <ErrorState primary='var(--bs-primary)' primary_dark='var(--bs-primary-dark)' primary_light='var(--bs-primary-light)' width={160} height={120}/>
      <h4 className="mt-2">{t('Oops, It Seems There Is An Issue In the Request')}.</h4>
      <p>{t('Please verify the provided information and retry')}.</p>
    </div>
  )
}
export default ErrorNoResultFound