import Button from '@src/@core/components/ui/button'
import InputField from '@src/@core/components/ui/input-field'
import { X } from 'react-feather'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Modal, ModalBody, Spinner } from 'reactstrap'

const AddDimensionModal = ({isOpen, toggle, setDimension, handleUpdateDimension, dimensions}) => {

  const {register, handleSubmit, reset, formState: { errors, isDirty } } = useForm({
    defaultValues:{
      length:'',
      breadth:'',
      height:''
    },
    mode: 'onChange'
  })
  const loading = useSelector(store => store.inventory.loadingState)
  const { t } = useTranslation()

  const onOpened = () => {
    reset({
      length: dimensions?.length?.length,
      breadth: dimensions?.breadth?.breadth,
      height: dimensions?.height?.height  
    })
  }

  const onClosed = () => {
    reset()
  }

  const onSubmitForm = (data) => {
    if (data.length && data.breadth && data.height) {
      const dataToSend = {
        dimensions:{
          length:{length:parseInt(data.length), unit:'cm'},
          breadth:{breadth:parseInt(data.breadth), unit:'cm'},
          height:{height:parseInt(data.height), unit:'cm'}
        }
      }
      setDimension(dataToSend)
      handleUpdateDimension(dataToSend)
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      centered
      onOpened={onOpened}
      onClosed={onClosed}
      toggle={toggle}
      contentClassName="rounded-12px"
    >
      <ModalBody className="d-flex flex-column gap-24px py-16px px-20px ">
        <div className="d-flex justify-content-between align-items-center">
          <div className="txt-body-md text-dark">{t('Add Dimensions (In cm)')}</div>
          <X onClick={toggle} size={16} className="text-dark cursor-pointer" />
        </div>
        <form className='d-flex flex-column gap-24px' onSubmit={handleSubmit(onSubmitForm)}>
          <InputField
            type='number'
            label="Length"
            isRequired
            defaultValue={dimensions?.length?.length}
            errors={errors}
            {...register('length', {required:t('This field is required')})}
          />
          <InputField
            type='number'
            label="Width"
            isRequired
            defaultValue={dimensions?.breadth?.breadth}
            errors={errors}
            {...register('breadth', {required:t('This field is required')})}
          />
          <InputField
            type='number'
            label="Height"
            isRequired
            defaultValue={dimensions?.height?.height}
            errors={errors}
            {...register('height', {required:t('This field is required')})}
          />
          <div className='flex-center-end gap-10px'>
            <Button ofStyle='outlined' type='button' onClick={toggle} disabled={loading.changeSkuWeight}>{t('Cancel')}</Button>
            <Button
              disabled={loading.changeSkuWeight || !isDirty}>
              {loading.changeSkuWeight ? <Spinner size='sm' className='me-4px'/> : null}
              <span>{t('Save')}</span>
            </Button>
          </div>
        </form>
      </ModalBody>
    </Modal>
  )
}

export default AddDimensionModal