import { API_ENDPOINTS } from '@src/api.urls'

export const getOrdersApi = {
  apiKey: ['get-orders'],
  url: API_ENDPOINTS.ORDERS.GET_ORDERS
}

export const getInvoiceDataApi = {
  apiKey: ['get-order-invoice-data'],
  url: API_ENDPOINTS.ORDERS.GET_INVOICE_DATA
}

export const createShipment = {
  apiKey: ['create-shipment'],
  url: API_ENDPOINTS.ORDERS.CREATE_SHIPMENT
}
  
export const downloadOrderReport = {
  apiKey: ['oms-order-report-download'],
  url: API_ENDPOINTS.ORDERS.DOWNLOAD_REPORT
}

export const orderItemsWithBatchesConfigApi = {
  apiKey: ['order-items-with-batches-config'],
  url: API_ENDPOINTS.ORDERS.GET_ORDER_DETAILS
}

export const reassignHubApi = {
  apiKey: ['reassign_hub'],
  url: API_ENDPOINTS.ORDERS.REASSIGN_HUB
}
