
import { RowExpanderIcon } from '@src/assets/data/assets'
import { useRTL } from '@src/utility/hooks/useRTL'
import classNames from 'classnames'
import './index.scss'

const RowExpanderWrapper = (props) => {
  const { data, children } = props
  const bgColor = data.stripeIndex % 2 === 0 ? 'bg-white' : 'bg-light-1'
  const [isRtl] = useRTL()
  
  return (
    <section className={`row-expander-wrapper-main border-bottom border-bottom-dark-1 flex-start-start ${bgColor}`}>
      <div className={classNames({
        rtlExpanderIcon : isRtl
      })}>
        <RowExpanderIcon/>
      </div>
      <div className='py-24px w-100 pe-24px'>
        <div className="main-table-wrapper">
          {children}
        </div>
      </div>
    </section>
  )
}

export default RowExpanderWrapper