const SVG = ({ height, fillColor, width, iswhite }) => {
  const color = iswhite ? 'white' : fillColor
  return (
    <svg width={width} height={height} viewBox="0 0 77 77" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M27.4748 47.0801V64.1548H10.4001V47.0801H27.4748ZM28.8748 44.6801C29.4271 44.6801 29.8748 45.1278 29.8748 45.6801V65.5548C29.8748 66.1071 29.4271 66.5548 28.8748 66.5548H9.00011C8.44782 66.5548 8.00011 66.1071 8.00011 65.5548V45.6801C8.00011 45.1278 8.44782 44.6801 9.0001 44.6801H28.8748Z" fill={color}/>
      <path fillRule="evenodd" clipRule="evenodd" d="M20.1833 47.0801V51.655H17.6917V47.0801H20.1833ZM21.5833 44.6801C22.1356 44.6801 22.5833 45.1278 22.5833 45.6801V53.055C22.5833 53.6073 22.1356 54.055 21.5833 54.055H16.2917C15.7394 54.055 15.2917 53.6073 15.2917 53.055V45.6801C15.2917 45.1278 15.7394 44.6801 16.2917 44.6801H21.5833Z" fill={color}/>
      <path fillRule="evenodd" clipRule="evenodd" d="M47.2661 47.0801V64.1548H30.1914V47.0801H47.2661ZM48.6661 44.6801C49.2184 44.6801 49.6661 45.1278 49.6661 45.6801V65.5548C49.6661 66.1071 49.2184 66.5548 48.6661 66.5548H28.7914C28.2391 66.5548 27.7914 66.1071 27.7914 65.5548V45.6801C27.7914 45.1278 28.2391 44.6801 28.7914 44.6801H48.6661Z" fill={color}/>
      <path fillRule="evenodd" clipRule="evenodd" d="M39.9746 47.0801V51.655H37.483V47.0801H39.9746ZM41.3746 44.6801C41.9268 44.6801 42.3746 45.1278 42.3746 45.6801V53.055C42.3746 53.6073 41.9268 54.055 41.3746 54.055H36.083C35.5307 54.055 35.083 53.6073 35.083 53.055V45.6801C35.083 45.1278 35.5307 44.6801 36.083 44.6801H41.3746Z" fill={color}/>
      <path fillRule="evenodd" clipRule="evenodd" d="M37.4944 27.2887V44.3634H20.4197V27.2887H37.4944ZM38.8944 24.8887C39.4467 24.8887 39.8944 25.3364 39.8944 25.8887V45.7634C39.8944 46.3157 39.4467 46.7634 38.8944 46.7634H19.0197C18.4674 46.7634 18.0197 46.3157 18.0197 45.7634V25.8887C18.0197 25.3364 18.4674 24.8887 19.0197 24.8887H38.8944Z" fill={color}/>
      <path fillRule="evenodd" clipRule="evenodd" d="M30.2028 27.2887V31.8636H27.7113V27.2887H30.2028ZM31.6028 24.8887C32.1551 24.8887 32.6028 25.3364 32.6028 25.8887V33.2636C32.6028 33.8159 32.1551 34.2636 31.6028 34.2636H26.3113C25.759 34.2636 25.3113 33.8159 25.3113 33.2636V25.8887C25.3113 25.3364 25.759 24.8887 26.3113 24.8887H31.6028Z" fill={color}/>
      <path fillRule="evenodd" clipRule="evenodd" d="M54.9259 13.6636V16.5507C54.9259 17.7657 53.9409 18.7507 52.7259 18.7507H23.5737V25.8808C23.5737 26.5435 23.0364 27.0808 22.3737 27.0808C21.711 27.0808 21.1737 26.5435 21.1737 25.8808V18.5507C21.1737 17.3357 22.1587 16.3507 23.3737 16.3507H52.5259V13.2197C52.5259 11.3239 54.7635 10.3158 56.1835 11.5718L68.9071 22.8266C69.9282 23.7297 69.8905 25.3346 68.8282 26.1888L56.1045 36.4204C54.6649 37.578 52.5259 36.5533 52.5259 34.706V31.8521H38.7425C38.0798 31.8521 37.5425 31.3148 37.5425 30.6521C37.5425 29.9893 38.0798 29.4521 38.7425 29.4521H52.7259C53.9409 29.4521 54.9259 30.437 54.9259 31.6521V34.2885L67.1394 24.4671L54.9259 13.6636Z" fill={color}/>
    </svg>
  )
}
export default SVG