import SkuCard from '@src/views/catalog/components/skuCard'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import './styles.scss'

export const ExpandableRowsForBundleSku = ({ data }) => {
  const { t } = useTranslation()
  return (
    <section className='bundle-section-main-wrapper w-100 bg-light-1 pt-16px pb-4px'>
      <table className='rows-for-bundle-sku-wrapper w-100'>
        <thead className='table-header-wrapper txt-asst-md'>
          <tr className='row-wrapper'>
            <th className='common-heading p-8px'>{t('SKU Details')}</th>
            <th className='common-heading p-8px w-100 text-end'>{t('Qty per Kit')}</th>
          </tr>
        </thead>
        <tbody className='table-body-wrapper'>
          {data.child_task_items?.map((item) => <tr key={item.sku.seller_sku_code}>
            <td className='py-12px px-8px w-50'>
              <SkuCard url={item.images?.[0]?.url} skuName={item.sku.name} skuCode={item.sku.seller_sku_code} toolTipID={item.seller_sku_code} />
            </td>
            <td className='py-12px px-8px w-100 text-end'>{item.display_quantity_per_assembly}</td>
          </tr>)}
        </tbody>
      </table>
    </section>
  )
}

export default memo(ExpandableRowsForBundleSku)