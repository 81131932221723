const SVG = ({ height, fillColor, width, iswhite }) => {
  const color = iswhite ? 'white' : fillColor
  return (
    <svg width={width} height={height} viewBox="0 0 77 77" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M7 20.2C7 18.4327 8.43269 17 10.2 17H50.7397C52.5071 17 53.9397 18.4327 53.9397 20.2V47.1842C53.9397 48.9515 52.5071 50.3842 50.7397 50.3842H42.0218V47.9842H50.7397C51.1816 47.9842 51.5397 47.626 51.5397 47.1842V20.2C51.5397 19.7582 51.1816 19.4 50.7397 19.4H10.2C9.75817 19.4 9.4 19.7582 9.4 20.2V47.1842C9.4 47.626 9.75817 47.9842 10.2 47.9842H12.0653V50.3842H10.2C8.43269 50.3842 7 48.9515 7 47.1842V20.2Z" fill={color}/>
      <path fillRule="evenodd" clipRule="evenodd" d="M51.5396 28.8508C51.5396 28.188 52.0768 27.6508 52.7396 27.6508H60.9732C61.6415 27.6508 62.2736 27.9546 62.6911 28.4764L69.9178 37.5098C70.2299 37.8998 70.3999 38.3845 70.3999 38.8841V48.1841C70.3999 49.3991 69.4149 50.3841 68.1999 50.3841H64.3586C63.6959 50.3841 63.1586 49.8469 63.1586 49.1841C63.1586 48.5214 63.6959 47.9841 64.3586 47.9841H67.9999V38.9542L60.8771 30.0508H52.7396C52.0768 30.0508 51.5396 29.5135 51.5396 28.8508Z" fill={color}/>
      <path fillRule="evenodd" clipRule="evenodd" d="M57.9664 58.3189C61.1773 58.3189 63.7803 55.7159 63.7803 52.505C63.7803 49.2941 61.1773 46.6911 57.9664 46.6911C54.7555 46.6911 52.1526 49.2941 52.1526 52.505C52.1526 55.7159 54.7555 58.3189 57.9664 58.3189ZM57.9664 60.7189C62.5028 60.7189 66.1803 57.0414 66.1803 52.505C66.1803 47.9686 62.5028 44.2911 57.9664 44.2911C53.43 44.2911 49.7526 47.9686 49.7526 52.505C49.7526 57.0414 53.43 60.7189 57.9664 60.7189Z" fill={color}/>
      <path fillRule="evenodd" clipRule="evenodd" d="M53.1347 37.7952H69.191V40.1952H53.1347V37.7952Z" fill={color}/>
      <path fillRule="evenodd" clipRule="evenodd" d="M28.5144 46.245V58.662H40.9313V46.245H28.5144ZM27.3144 44.045C26.7621 44.045 26.3144 44.4928 26.3144 45.045V59.862C26.3144 60.4143 26.7621 60.862 27.3144 60.862H42.1313C42.6836 60.862 43.1313 60.4143 43.1313 59.862V45.045C43.1313 44.4928 42.6836 44.045 42.1313 44.045H27.3144Z" fill={color}/>
      <path fillRule="evenodd" clipRule="evenodd" d="M34.1199 46.245V49.0523H35.3255V46.245H34.1199ZM32.9199 44.045C32.3676 44.045 31.9199 44.4928 31.9199 45.045V50.2523C31.9199 50.8046 32.3676 51.2523 32.9199 51.2523H36.5255C37.0778 51.2523 37.5255 50.8046 37.5255 50.2523V45.045C37.5255 44.4928 37.0778 44.045 36.5255 44.045H32.9199Z" fill={color}/>
      <path fillRule="evenodd" clipRule="evenodd" d="M13.2991 46.245V58.662H25.716V46.245H13.2991ZM12.0991 44.045C11.5468 44.045 11.0991 44.4928 11.0991 45.045V59.862C11.0991 60.4143 11.5468 60.862 12.0991 60.862H26.916C27.4683 60.862 27.916 60.4143 27.916 59.862V45.045C27.916 44.4928 27.4683 44.045 26.916 44.045H12.0991Z" fill={color}/>
      <path fillRule="evenodd" clipRule="evenodd" d="M18.9047 46.245V49.0523H20.1104V46.245H18.9047ZM17.7047 44.045C17.1524 44.045 16.7047 44.4928 16.7047 45.045V50.2523C16.7047 50.8046 17.1524 51.2523 17.7047 51.2523H21.3104C21.8626 51.2523 22.3104 50.8046 22.3104 50.2523V45.045C22.3104 44.4928 21.8626 44.045 21.3104 44.045H17.7047Z" fill={color}/>
      <path fillRule="evenodd" clipRule="evenodd" d="M24.0138 31.0297V43.4467H36.4307V31.0297H24.0138ZM22.8138 28.8297C22.2615 28.8297 21.8138 29.2774 21.8138 29.8297V44.6467C21.8138 45.1989 22.2615 45.6467 22.8138 45.6467H37.6307C38.183 45.6467 38.6307 45.1989 38.6307 44.6467V29.8297C38.6307 29.2774 38.183 28.8297 37.6307 28.8297H22.8138Z" fill={color}/>
      <path fillRule="evenodd" clipRule="evenodd" d="M29.6195 31.0297V33.837H30.8251V31.0297H29.6195ZM28.4195 28.8297C27.8672 28.8297 27.4195 29.2774 27.4195 29.8297V35.037C27.4195 35.5893 27.8672 36.037 28.4195 36.037H32.0251C32.5774 36.037 33.0251 35.5893 33.0251 35.037V29.8297C33.0251 29.2774 32.5774 28.8297 32.0251 28.8297H28.4195Z" fill={color}/>
    </svg>
  )
}
export default SVG