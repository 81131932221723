// ** React Imports
import { Suspense } from 'react'
import { Navigate } from 'react-router-dom'

// ** Utils
import { getHomeRouteForLoggedInUser, getUserData } from '@utils'
import PageTitle from '../page-title/PageTitle'

const PublicRoute = ({ children, route }) => {

  const pageTitle = route?.title

  if (route) {
    const user = getUserData()

    const restrictedRoute = route.meta && route.meta.restricted

    if (user && restrictedRoute) {
      return <Navigate to={getHomeRouteForLoggedInUser(user.role)} />
    }
  }

  return <Suspense fallback={null}>
    <>
      <PageTitle pageTitle={pageTitle} />
      {children}
    </>
  </Suspense>
}

export default PublicRoute
