/* eslint-disable @typescript-eslint/no-explicit-any */
import Button from '@src/@core/components/ui/button';
import CustomToast from '@src/@core/components/ui/custom-toast/CustomToast';
import ComponentSpinner from '@src/@core/components/ui/spinner/Loading-spinner';
import { API_ENDPOINTS } from '@src/api.urls';
import { queryClient } from '@src/configs/react-query/react-query-provider';
import { useApi } from '@src/configs/react-query/useApi';
import { axiosInstance } from '@src/network/AxiosInstance';
import { getApiUrl } from '@src/utility/Utils';
import { ATTACHMENTS_SIDEBAR_MODE } from '@src/views/sales/constant/orders.constants';
import Attachments from '@src/views/sales/pending-actions/pages/createOrder/attachments';
import AddDescription from '@src/views/sales/pending-actions/pages/createOrder/attachments/addDescription';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import AttachmentsTable from './attachmentsTable';
import Preview from '@src/views/sales/pending-actions/pages/createOrder/attachments/preview/preview';
import './grnAttachments.scss';

const AddAttachments = ({handleOpenAttachmentSidesheet}) => {
  const { t } = useTranslation();
  return (
    <div className="d-flex flex-column align-items-center gap-4px border border-dark-2 rounded-8px bg-white p-32px">
      <span className='text-dark txt-body-md'>{t('Add Attachments')}</span>
      <span className='pb-12px text-dark-6 txt-sub-rg'>{t('No attachments have been added')}</span>
      <Button ofStyle="outlined" className='txt-body-md' onClick={handleOpenAttachmentSidesheet}>
        {t('Attach Now')}
      </Button>
    </div>
  );
};

const GrnAttachments = (props) => {
  const { grnId, hubId } = props;
  const [attachmentSidesheet, setAttachmentSidesheet] = useState({ isOpen: false, mode: ATTACHMENTS_SIDEBAR_MODE.ADD.id });
  const [addedAttachments, setAddedAttachments] = useState([]);
  const [isDescriptionPopupOpen, setDescriptionPopupOpen] = useState(false);
  const [selectedAttachment, setSelectedAttachment] = useState(null);
  const [previewingAttachment, setPreviewingAttachment] = useState(null);

  const { isFetching: isGrnAttachmentsFetching } = useApi({
    apiKey: ['grn-attachments'],
    apiFn: () => {
      const url = getApiUrl(API_ENDPOINTS.INVENTORY.GET_GRN_ATTACHMENTS, {hubId, grnId});
      return axiosInstance.get(url);
    },
    onSuccess: (data) => {
      let filesData = [];

      if (data?.data?.length) {
        filesData = data.data.map((file) => {
          return {
            file: {
              name: file.name,
              type: file.file_type
            },
            description: file.description,
            file_url: file.url
          };
        });
        
      }

      setAddedAttachments(filesData)
    }
  });

  const toggleSidesheet = () => {
    setAttachmentSidesheet({ isOpen: false, mode: ATTACHMENTS_SIDEBAR_MODE.ADD.id });
  };

  const {mutate: editAttachment, isPending: isEditAttachmentPending} = useApi({
    isMutation: true,
    apiKey: ['edit-attachment'],
    apiFn: ({ attachments }) => {
      const body = {
        attachments
      }
      const url = getApiUrl(API_ENDPOINTS.INVENTORY.UPLOAD_GRN_ATTACHMENTS, {hubId, grnId});
      return axiosInstance.post(url, body);
    },
    onSuccess: (_, req) => {
      const message = req.toastMessage || 'Files saved successfully';
      CustomToast(message, {my_type: 'success'} );
      toggleSidesheet();
      handleToggleDescription();
      queryClient.invalidateQueries({ queryKey: ['grn-attachments'] });
    }
  });

  const onSaveAttachments = (files, toastMessage) => {
    const attachments = files.map((fileObj) => {
      return {
        name: fileObj.file?.name.split('.')[0],
        file_type: fileObj.file?.type,
        url: fileObj.file_url,
        description: fileObj.description,
        should_upload: Boolean(fileObj.should_upload)
      };
    });
    const body = {
      attachments,
      toastMessage
    };
    editAttachment(body);
  };

  const handleOpenAttachmentSidesheet = () => {
    setAttachmentSidesheet({ isOpen: true, mode: ATTACHMENTS_SIDEBAR_MODE.ADD.id });
  };

  const handleUpdateDescription = (index, description) => {
    // insert the desc in the relevant object and make edit api request
    const uploadedFiles = [...addedAttachments]

    const updatedFiles = uploadedFiles.map((file, i) => {
      if (i === index) {
        return {
          ...file,
          description
        }
      }
      return file
    })

    onSaveAttachments(updatedFiles)

  }

  const handleOpenDescriptionModal = (item, index) => {
    item.index = index
    setSelectedAttachment(item)
    setDescriptionPopupOpen(true)
  }

  const handleToggleDescription = () => {
    setDescriptionPopupOpen(false)
    setSelectedAttachment(null)
  }

  const handleRemoveFile = (index) => {
    const uploadedFiles = [...addedAttachments]
    const updatedFiles = uploadedFiles.filter((file, i) => i !== index)
    onSaveAttachments(updatedFiles)
  }

  const handleTogglePreview = (attachment) => {
    if (previewingAttachment) {
      setPreviewingAttachment(null)
    } else {
      setPreviewingAttachment(attachment)
    }
  }

  const uploadDetails = {
    service: 'wms',
    usecase: 'grn-attachments'
  }
  
  return (
    <div className='grn-attachments'>
      {isGrnAttachmentsFetching ? 
        <ComponentSpinner className='mt-4' /> :
        <div className='p-16px'>
          {!addedAttachments?.length ?
            <AddAttachments handleOpenAttachmentSidesheet={handleOpenAttachmentSidesheet} /> : 
            <AttachmentsTable
              addedAttachments={addedAttachments}
              handleOpenAttachmentSidesheet={handleOpenAttachmentSidesheet}
              handleOpenDescriptionModal={handleOpenDescriptionModal}
              handleUpdateDescription={handleUpdateDescription}
              handleRemoveFile={handleRemoveFile}
              handleTogglePreview={handleTogglePreview}
            />}
        </div> }
      <Attachments 
        isOpen={attachmentSidesheet.isOpen}
        toggle={toggleSidesheet}
        addedAttachments={addedAttachments}
        mode={attachmentSidesheet.mode}
        onSaveAttachments={onSaveAttachments}
        isLoading={isEditAttachmentPending}
        uploadDetails={uploadDetails}
      />
      <AddDescription 
        isOpen={isDescriptionPopupOpen}
        toggle={handleToggleDescription}
        index={selectedAttachment?.index}
        description={selectedAttachment?.description}
        handleDescription={handleUpdateDescription} 
        isLoading={isEditAttachmentPending}
      />
      <Preview 
        isOpen={!!previewingAttachment} 
        toggle={handleTogglePreview} 
        previewingAttachment={previewingAttachment}
      />
    </div>
  );
};

export default GrnAttachments;