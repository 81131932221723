import Button from '@src/@core/components/ui/button'
import warningImage from '@src/assets/images/catalog/bundlesku/warning-icon.svg'
import { X } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { Modal, ModalBody, ModalFooter } from 'reactstrap'

const SellerChangeWarningModal = ({confirmHandler, cancelHandler, ...rest}) => {
  const { t } = useTranslation()

  return (
    <div>
      <Modal
        className='seller-change-warning-modal-for-kits'
        modalClassName='warning-seller-change-modal-classname'
        centered
        toggle={cancelHandler}
        {...rest}
      >
        <div className='warning-modal-header'>
          <div className='modal-title m-auto'>
            <img src={warningImage} />
          </div>
          <span
            className='bg-white cursor-pointer'
            onClick={cancelHandler}
          >
            <X size={18} className='text-dark-6' />
          </span>
        </div>
        <ModalBody className='d-flex flex-column gap-16px justify-content-center mt-2 mb-2 color-black'>
          <div className='txt-h1-sb text-dark'>
            {t('Attention! Changing the seller will delete the added SKUs.')}
          </div>
          <div className='text-dark txt-body-rg'>
            {t(
              'You are attempting to change the existing seller. This will delete the added SKUs. Are you sure you want to proceed with this change?'
            )}
          </div>
        </ModalBody>
        <ModalFooter className='d-flex justify-content-end align-items-center gap-1'>
          <Button ofStyle='noBackground' onClick={cancelHandler}>
            {t('Don’t Change')}
          </Button>
          <Button onClick={confirmHandler}>{t('Yes, Change')}</Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}

export default SellerChangeWarningModal
