import Button from '@src/@core/components/ui/button'
import { PRINT_MODES, PRINT_PAGE_STYLE } from '@src/App.constants'
import { PrintIcon } from '@src/assets/images/icons/printIcon'
import { forwardRef, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useReactToPrint } from 'react-to-print'
import { Col, Row, Spinner } from 'reactstrap'
import './print-cluster-packing-list.scss'


const InfoRow = ({ title, value, isBold = false }) => {
  return <>
    <Row className='info-row'>
      <Col className='col-3 info-row-title'>{title}:</Col>
      <Col className={`col-9 ${isBold ? 'info-col-value-bold' : 'info-col-value'}`}>{value}</Col>
    </Row>
  </>
}

const PrintTemplate = forwardRef((props, ref) => {
  const { display_items_to_pick, packing_details: { display_no_of_packages, display_order_weight, box_details }, wave: { id, created_at }, display_number_of_orders } = props.data
  return (
    <div ref={ref} className='print-cluster-packing-list-template'>
      <style>{ PRINT_PAGE_STYLE }</style>
      <div className='heading'>
        <span className='heading-label'>Packing List</span> - <span>{id}</span>
      </div>
      <Row className='info-container'>
        <Col className='col-6 info-details-1 info-details-border'>
          <Row className='info-heading'>PACKAGING DETAILS</Row>
          <div className='info-body'>
            <InfoRow title="Total Items" value={display_items_to_pick} isBold={true} />
            <InfoRow title="Packages" value={display_no_of_packages} isBold={true} />
            <InfoRow title="Weight" value={display_order_weight} isBold={true} />
          </div>
        </Col>

        <Col className='col-6 info-details-2'>
          <Row className='info-heading'>CLUSTER DETAILS</Row>
          <div className='info-body'>
            <InfoRow title="Cluster ID" value={id} />
            <InfoRow title="Created At" value={created_at} />
            <InfoRow title="Orders" value={display_number_of_orders} />
          </div>
        </Col>
      </Row>

      <div className="item-details">PACKAGING PREFERENCE</div>
      <table className='w-100'>
        <thead>
          <tr>
            <th colSpan={4} className='left-aligned'>BOX/ITEM</th>
            <th colSpan={2} className='left-aligned'>SKU Code</th>
            <th colSpan={2} className='right-aligned'>Qty</th>
            <th colSpan={2} className='right-aligned'>L (IN) x W (IN) x H (IN)</th>
          </tr>
        </thead>
        <tbody>
          {box_details.map((obj, idx) => <>
            <tr key={`box-${idx}`} className='table-body-row'>
              <td colSpan={4} className='left-aligned body-row-td'>
                <div className='box-name'>{obj.box.name}</div>
              </td>
              <td colSpan={2} className='left-aligned body-row-td'>
                <div className='invisible'>-</div>
              </td>
              <td colSpan={2} className='right-aligned body-row-td'>
                <div className='invisible'>-</div>
              </td>
              <td colSpan={2} className='right-aligned body-row-td'>
                <div>{obj.box.capacity}</div>
              </td>
            </tr>
            {obj.sku_details.map((item, idx) => <tr key={`sku-${idx}`} className='table-body-sub-row'>
              <td colSpan={4} className='left-aligned body-sub-row-td'>
                <div className='padding-left-8 sku-name'>
                  {item.sku.name}
                </div>
              </td>
              <td colSpan={2} className='left-aligned sku-code body-sub-row-td' >
                <div>
                  {item.sku.seller_sku_code}
                </div>
              </td>
              <td colSpan={2} className='right-aligned body-sub-row-td'>
                {item.display_quantity}
              </td>
              <td colSpan={2} className='right-aligned body-sub-row-td'>
                <div className='invisible'>-</div>
              </td>
            </tr>)}
          </>)}
        </tbody>
      </table>

    </div>
  )
})

export const PrintClusterPackingList = ({ printData }) => {
  const { t } = useTranslation()
  const componentRef = useRef()
  const [printingLoading, setPrintingLoading] = useState(false)


  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: `@media print {
      @page {
        size: ${PRINT_MODES.A_FOUR.size};
      }
      .row,
      .col {
        margin: 0;
        padding: 0;
      }

      .heading {
        padding-top: 16px;
        padding-bottom: 16px;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
      }

      .heading-label{
        color: #979797;
      }

      .info-container {
        border-bottom: 1px solid #DEDEDE;
        padding-bottom: 12px;
      }

      .info-heading {
        margin-bottom: 12px;
        font-size: 12px;
        font-weight: 500;
        color: #000000;
        border-bottom: 1px solid #DEDEDE;
        padding-bottom: 4px;
      }

      .info-row {
        font-size: 11px;
        line-height: 18px;
      }

      .info-row-title {
        text-align: left;
      }

      .info-col-value {
        color: #000000;
        text-align: left;
      }

      .info-col-value-bold {
        font-weight: 700;
        color: #000000;
        text-align: left;
      }
      
      .info-body{
        display: flex;
        flex-direction: column;
        gap: 4px;
      }

      .item-details {
        margin-top: 24px;
        margin-bottom: 16px;
        font-size: 12px;
        font-weight: 500;
        color: #000000;
      }

      th {
        padding: 8px;
        font-size: 10px;
        line-height: 18px;
        background:#F8F8F8 !important;
        color: #666666;
      }

      td {
        font-size: 10px;
        line-height: 18px;
        color: #000000;
      }

      .left-aligned {
        text-align: left;
      }

      .right-aligned {
        text-align: right;
      }

      .padding-left-16 {
        padding-left: 16px;
      }

      .padding-left-8 {
        padding-left: 8px;
      }

      .box-name {
        font-weight: 600;
        max-width: 300px;
      }
      
      .sku-name{
        max-width: 300px !important;
      }

      .sku-code{
        max-width: 200px !important;
      }
      .body-row-td{
        padding: 12px 8px 8px 8px;
      }
      .body-sub-row-td{
        padding: 4px 8px;
      }
    }`,
    onBeforeGetContent: () => setPrintingLoading(true),
    onBeforePrint: () => setPrintingLoading(false)
  })

  const handleClick = () => {
    handlePrint()
  }

  return (
    <div className='d-flex flex-column gap-1'>
      <div className='d-none'>
        {printData && <PrintTemplate ref={componentRef} data={printData} />}
      </div>
      <Button
        onClick={(e) => {
          e.stopPropagation()
          handleClick()
        }}
        disabled={printingLoading}
        ofStyle="noBackground"
        className="txt-body-md py-4px px-8px"
      >
        {printingLoading ? <Spinner color='var(--bs-primary)' size='sm' /> : <PrintIcon color='var(--bs-primary)' size={20} />}
        {t('Print Packing List')}
      </Button>
    </div>
  )
}