import { PRINT_MODES } from '@src/App.constants'
import { getPackingListForPrint } from '@src/views/sales/store/store'
import { forwardRef, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useReactToPrint } from 'react-to-print'
import { Button, Col, Row, Spinner } from 'reactstrap'
import './style.scss'

const InfoRow = ({ title, value, isBold = false }) => {
  return <>
    <Row className='info-row'>
      <Col className='col-3 info-row-title'>{title}:</Col>
      <Col className={`col-9 ${isBold ? 'info-col-value-bold' : 'info-col-value'}`}>{value}</Col>
    </Row>
  </>
}

// Print List 

const GetPackingListRow = (props) => {
  const { type, rowData } = props
  switch (type) {
  case 'pallet_carton_item':
    return (
      <tr>
        <td colSpan={4} className='left-aligned'>
          <div className='barcode'>{rowData.barcode}</div>
          <div className='padding-left-8'>
            {rowData.cartons.map((carton) => <>
              <div className='barcode'>{carton.barcode}</div>
              <div className='padding-left-16'>
                {carton.pack_items.map(item => <div>{item.sku.name}</div>)}
              </div>
            </>
            )}
          </div>
        </td>
        <td colSpan={2} className='left-aligned'>
          <div className='invisible'>-</div>
          {rowData.cartons.map((carton) => <>
            <div className='invisible'>-</div>
            <div>
              {carton.pack_items.map(item => <div>{item.sku.seller_sku_code}</div>)}
            </div>
          </>
          )}
          <div>
          </div>
        </td>
        <td colSpan={2} className='right-aligned'>
          <div className='invisible'>-</div>
          {rowData.cartons.map((carton) => <>
            <div className='invisible'>-</div>
            <div>
              {carton.pack_items.map(item => <div>{item.quantity}</div>)}
            </div>
          </>
          )}
        </td>
        <td colSpan={2} className='right-aligned'>
          <div>{rowData.dimensions}</div>
          {rowData.cartons.map((carton) => <>
            <div>{carton.dimensions}</div>
            <div className='invisible'>
              {carton.pack_items.map(() => <div>-</div>)}
            </div>
          </>
          )}
        </td>
        <td colSpan={2} className='right-aligned'>
          <div>{rowData.weight}</div>
          {rowData.cartons.map((carton) => <>
            <div>{carton.weight}</div>
            <div className='invisible'>
              {carton.pack_items.map(() => <div>-</div>)}
            </div>
          </>
          )}
        </td>
      </tr>
    )
  case 'pallet_item':
    return (
      <tr>
        <td colSpan={4} className='left-aligned'>
          <div className='barcode'>{rowData.barcode}</div>
          <div className='padding-left-8'>
            {rowData.pack_items.map((item) => <><div>{item.sku.name}</div></>)}
          </div>
        </td>
        <td colSpan={2} className='left-aligned'>
          <div className='invisible'>-</div>
          <div>
            {rowData.pack_items.map((item) => <><div>{item.sku.seller_sku_code}</div></>)}
          </div>
          <div>
          </div>
        </td>
        <td colSpan={2} className='right-aligned'>
          <div className='invisible'>-</div>
          <div>
            {rowData.pack_items.map((item) => <><div>{item.quantity}</div></>)}
          </div>
        </td>
        <td colSpan={2} className='right-aligned'>
          <div>{rowData.dimensions}</div>
          <div className='invisible'>
            {rowData.pack_items.map(() => <div>-</div>)}
          </div>
        </td>
        <td colSpan={2} className='right-aligned'>
          <div>{rowData.weight}</div>
          <div className='invisible'>
            {rowData.pack_items.map(() => <div>-</div>)}
          </div>
        </td>
      </tr>
    )
  case 'carton_item':
    return (
      <tr>
        <td colSpan={4} className='left-aligned'>
          <div className='barcode'>{rowData.barcode}</div>
          <div className='padding-left-8'>
            {rowData.pack_items.map((item) => <><div>{item.sku.name}</div></>)}
          </div>
        </td>
        <td colSpan={2} className='left-aligned'>
          <div className='invisible'>-</div>
          <div>
            {rowData.pack_items.map((item) => <><div>{item.sku.seller_sku_code}</div></>)}
          </div>
          <div>
          </div>
        </td>
        <td colSpan={2} className='right-aligned'>
          <div className='invisible'>-</div>
          <div>
            {rowData.pack_items.map((item) => <><div>{item.quantity}</div></>)}
          </div>
        </td>
        <td colSpan={2} className='right-aligned'>
          <div>{rowData.dimensions}</div>
          <div className='invisible'>
            {rowData.pack_items.map(() => <div>-</div>)}
          </div>
        </td>
        <td colSpan={2} className='right-aligned'>
          <div>{rowData.weight}</div>
          <div className='invisible'>
            {rowData.pack_items.map(() => <div>-</div>)}
          </div>
        </td>
      </tr>
    )
  case 'item':
    return (
      <tr>
        <td colSpan={4} className='left-aligned'>
          <div>{rowData.sku.name}</div>
        </td>
        <td colSpan={2} className='left-aligned'>
          <div>{rowData.sku.seller_sku_code}</div>

        </td>
        <td colSpan={2} className='right-aligned'>
          <div>{rowData.quantity}</div>
        </td>
        <td colSpan={2} className='right-aligned'>
          <div className='invisible'>-</div>
        </td>
        <td colSpan={2} className='right-aligned'>
          <div className='invisible'>-</div>
        </td>
      </tr>
    )
  case 'item_2':
    return (
      <tr>
        <td colSpan={6} className='left-aligned'>
          <div>{rowData.sku.name}</div>
        </td>
        <td colSpan={3} className='left-aligned'>
          <div>{rowData.sku.seller_sku_code}</div>

        </td>
        <td colSpan={3} className='right-aligned'>
          <div>{rowData.quantity}</div>
        </td>
      </tr>
    )
  default:
    return null
  }
}

const PalletPackingListTemplate = forwardRef((props, ref) => {
  const { order_details, pallets, cartons, pack_items, pallet_count, box_count, total_items } = props.data
  return (
    <div ref={ref} className='pallet-packing-list-template'>
      <div className='heading'>Packing List</div>
      <Row className='info-container'>
        <Col className='col-6 info-details-1 info-details-border'>
          <Row className='info-heading'>Packing Details</Row>
          <InfoRow title="Pallet Count" value={pallet_count} isBold={true} />
          <InfoRow title="Box Count" value={box_count} isBold={true} />
          <InfoRow title="Total Items" value={total_items} isBold={true} />
        </Col>

        <Col className='col-6 info-details-2'>
          <Row className='info-heading'>Order Details</Row>
          <InfoRow title="Order ID" value={order_details.seller_sales_channel_order_id} />
          <InfoRow title="Date" value={order_details.created_at} />
          <InfoRow title="Total Weight" value={order_details.total_weight} />
          <InfoRow title="Packages" value={order_details.no_of_packages} />
        </Col>
      </Row>

      <div className="info-heading item-details">Item Details</div>
      <table className='w-100'>
        <thead>
          <tr>
            <th colSpan={4} className='left-aligned'>PALLET/BOX/ITEM</th>
            <th colSpan={2} className='left-aligned'>SKU Code</th>
            <th colSpan={2} className='right-aligned'>Qty</th>
            <th colSpan={2} className='right-aligned'>L (in) x W (in) x H (in)</th>
            <th colSpan={2} className='right-aligned'>Weight (Kg)</th>
          </tr>
        </thead>
        <tbody>
          {pallets.map(pallet => (
            pallet.cartons && pallet.cartons.length ? (
              <GetPackingListRow key={pallet.id} type="pallet_carton_item" rowData={pallet} />
            ) : (
              <GetPackingListRow key={pallet.id} type="pallet_item" rowData={pallet} />
            )
          ))}

          {cartons.length > 0 && cartons.map(carton => {
            return <>
              <GetPackingListRow type="carton_item" rowData={carton} />
            </>
          })}

          {pack_items.length > 0 && pack_items.map(item => {
            return <>
              <GetPackingListRow type="item" rowData={item} />
            </>
          })}
        </tbody>
      </table>

    </div>
  )
})

export const PalletPackingList = ({ printListRef, oms_order_id, hubId='' }) => {
  // const { t } = useTranslation()
  const componentRef = useRef()
  const [printingLoading, setPrintingLoading] = useState(false)
  const dispatch = useDispatch()
  const packingListForPrint  = useSelector(store => store.sales.packingListForPrint)
  const [isApiCalled, setIsApicalled] = useState(false)


  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: `@media print {
      body {
        height: fit-content !important;
      }
      @page {
        size: ${PRINT_MODES.A_FOUR.size};
      }
      .row,.col{
        margin:0;
        padding:0;
      }
      .heading {
        padding-top: 16px;
        padding-bottom: 16px;
        text-align: center;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
      }
      
      .info-container {
        border-top: 1px solid #DEDEDE;
        border-bottom: 1px solid #DEDEDE;
      }
      
      .info-details-1{
        padding: 12px 12px 12px 0px;
        border-right: 1px solid #DEDEDE;
      }
      
      .info-details-2{
        padding: 12px 12px 12px 24px;
      }
      
      .info-heading {
        margin-bottom: 8px;
        font-size: 12px;
        font-weight: 500;
        color: #000000;
      }
      
      .info-row{
        font-size:11px;
        line-height:18px;
      }
    
      .info-row-title{
        text-align: left;
      }
        
      .info-col-value {
        color: #000000;
        text-align: left;
      }
        
      .info-col-value-bold {
        font-weight: 700;
        color: #000000;
        text-align: left;
      }
      
      .item-details {
        margin-top: 30px;
      }

      td, th {
        padding: 8px;
        font-size: 10px;
        line-height: 18px;
      }
      td{
        color: #000000;
      }

      .left-aligned{
        text-align: left;
      }
      .right-aligned{
        text-align: right;
      }
      .padding-left-16{
        padding-left: 16px;
      }
      .padding-left-8{
        padding-left: 8px;
      }
      .barcode{
        font-weight:600
      }
    }`,
    onBeforeGetContent: () => setPrintingLoading(true),
    onBeforePrint: () => setPrintingLoading(false)
  })

  const handleClick = () => {
    if (packingListForPrint) {
      handlePrint()
    } else {
      dispatch(getPackingListForPrint({ oms_order_id, hubId }))
      setIsApicalled(true)
    }
  }

  useEffect(() => {
    if (packingListForPrint && isApiCalled) {
      handlePrint()
    }

  }, [packingListForPrint])

  return (
    <div className='d-flex flex-column gap-1'>
      <div className='d-none'>
        {packingListForPrint && <PalletPackingListTemplate ref={componentRef} data={packingListForPrint} />}
      </div>
      <Button
        onClick={handleClick}
        disabled={printingLoading}
        innerRef={printListRef}
        className='d-none'
      >
      </Button>
    </div>
  )
}

// Print Slip

const GetPackingSlipTemplate = (props) => {
  const { type, templateData, order_details } = props
  switch (type) {
  case 'pallet_carton':
    return (
      <div className='page-break'>
        <div className='heading'>Packing Slip</div>
        <Row className='info-container'>
          <Col className='col-6 info-details-1 info-details-border'>
            <Row className='info-heading'>Packing Details</Row>
            <InfoRow title="Pallet Barcode" value={templateData.barcode} isBold={true} />
            <InfoRow title="Box Count" value={templateData.cartons.length} isBold={true} />
            <InfoRow title="Dimension:" value={templateData.dimensions} isBold={true} />
          </Col>

          <Col className='col-6 info-details-2'>
            <Row className='info-heading'>Order Details</Row>
            <InfoRow title="Order ID" value={order_details.seller_sales_channel_order_id} />
            <InfoRow title="Date" value={order_details.created_at} />
            <InfoRow title="Total Weight" value={order_details.total_weight} />
            <InfoRow title="Packages" value={order_details.no_of_packages} />
          </Col>
        </Row>

        <div className="info-heading item-details">Item Details</div>
        <table className='w-100'>
          <thead>
            <tr>
              <th colSpan={4} className='left-aligned'>PALLET/BOX/ITEM</th>
              <th colSpan={2} className='left-aligned'>SKU Code</th>
              <th colSpan={2} className='right-aligned'>Qty</th>
              <th colSpan={2} className='right-aligned'>L (in) x W (in) x H (in)</th>
              <th colSpan={2} className='right-aligned'>Weight (Kg)</th>
            </tr>
          </thead>
          <tbody>
            {templateData.cartons.map((carton) => <GetPackingListRow type="carton_item" rowData={carton} />)}
          </tbody>
        </table>
      </div>
    )
  case 'pallet_item':
    return (
      <div className='page-break'>
        <div className='heading'>Packing Slip</div>
        <Row className='info-container'>
          <Col className='col-6 info-details-1 info-details-border'>
            <Row className='info-heading'>Packing Details</Row>
            <InfoRow title="Pallet Barcode" value={templateData.barcode} isBold={true} />
            <InfoRow title="Box Count" value={templateData.pack_items.length} isBold={true} />
            <InfoRow title="Dimension:" value={templateData.dimensions} isBold={true} />
          </Col>

          <Col className='col-6 info-details-2'>
            <Row className='info-heading'>Order Details</Row>
            <InfoRow title="Order ID" value={order_details.seller_sales_channel_order_id} />
            <InfoRow title="Date" value={order_details.created_at} />
            <InfoRow title="Total Weight" value={order_details.total_weight} />
            <InfoRow title="Packages" value={order_details.no_of_packages} />
          </Col>
        </Row>

        <div className="info-heading item-details">Item Details</div>
        <table className='w-100'>
          <thead>
            <tr>
              <th colSpan={4} className='left-aligned'>Pallet/ITEM</th>
              <th colSpan={2} className='left-aligned'>SKU Code</th>
              <th colSpan={2} className='right-aligned'>Qty</th>
              <th colSpan={2} className='right-aligned'>L (in) x W (in) x H (in)</th>
              <th colSpan={2} className='right-aligned'>Weight (Kg)</th>
            </tr>
          </thead>
          <tbody>
            {templateData.pack_items.map((item) => <GetPackingListRow type="item" rowData={item} />)}
          </tbody>
        </table>
      </div>
    )
  case 'carton':
    return (
      <div className='page-break'>
        <div className='heading'>Packing Slip</div>
        <Row className='info-container'>
          <Col className='col-6 info-details-1 info-details-border'>
            <Row className='info-heading'>Packing Details</Row>
            <InfoRow title="Box Barcode" value={templateData.barcode} isBold={true} />
            <InfoRow title="Box Count" value={templateData.pack_items.length} isBold={true} />
            <InfoRow title="Dimension:" value={templateData.dimensions} isBold={true} />
          </Col>

          <Col className='col-6 info-details-2'>
            <Row className='info-heading'>Order Details</Row>
            <InfoRow title="Order ID" value={order_details.seller_sales_channel_order_id} />
            <InfoRow title="Date" value={order_details.created_at} />
            <InfoRow title="Total Weight" value={order_details.total_weight} />
            <InfoRow title="Packages" value={order_details.no_of_packages} />
          </Col>
        </Row>

        <div className="info-heading item-details">Item Details</div>
        <table className='w-100'>
          <thead>
            <tr>
              <th colSpan={6} className='left-aligned'>ITEM</th>
              <th colSpan={3} className='left-aligned'>SKU Code</th>
              <th colSpan={3} className='right-aligned'>Qty</th>
            </tr>
          </thead>
          <tbody>
            {templateData.pack_items.map((item) => <GetPackingListRow type="item_2" rowData={item} />)}
          </tbody>
        </table>
      </div>
    )
  default:
    return null
  }
}

const PalletPackingSlipTemplate = forwardRef((props, ref) => {
  const { order_details, pallets, cartons } = props.data
  return (
    <div ref={ref} className='pallet-packing-list-template'>

      {pallets.map((pallet) => (
        pallet.cartons?.length ? (
          <>
            <GetPackingSlipTemplate key={pallet.id} type={'pallet_carton'} templateData={pallet} order_details={order_details} />
            {pallet.cartons.map((carton) => (
              <GetPackingSlipTemplate key={carton.id} type={'carton'} templateData={carton} order_details={order_details} />
            ))}
          </>
        ) : (
          <GetPackingSlipTemplate type={'pallet_item'} templateData={pallet} order_details={order_details} />
        )
      ))}

      {cartons.map((carton) => (
        <GetPackingSlipTemplate key={carton.id} type={'carton'} templateData={carton} order_details={order_details} />
      ))}

    </div>
  )
})

export const PalletPackingSlip = ({printSlipRef, oms_order_id, hubId=''}) => {
  const { t } = useTranslation()
  const componentRef = useRef()
  const [printingLoading, setPrintingLoading] = useState(false)
  const dispatch = useDispatch()
  const packingListForPrint  = useSelector(store => store.sales.packingListForPrint)

  const [isApiCalled, setIsApicalled] = useState(false)

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: `@media print {
      @page {
        size: ${PRINT_MODES.A_FOUR.size};
      }
      .page-break {
        page-break-after: always;
      }
      .row,.col{
        margin:0;
        padding:0;
      }
      .heading {
        padding-top: 16px;
        padding-bottom: 16px;
        text-align: center;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
      }
      
      .info-container {
        border-top: 1px solid #DEDEDE;
        border-bottom: 1px solid #DEDEDE;
      }
      
      .info-details-1{
        padding: 12px 12px 12px 0px;
        border-right: 1px solid #DEDEDE;
      }
      
      .info-details-2{
        padding: 12px 12px 12px 24px;
      }
      
      .info-heading {
        margin-bottom: 8px;
        font-size: 12px;
        font-weight: 500;
        color: #000000;
      }
      
      .info-row{
        font-size:11px;
        line-height:18px;
      }
      
      .info-row-title{
        text-align: left;
      }
        
      .info-col-value {
        color: #000000;
        text-align: left;
      }
        
      .info-col-value-bold {
        font-weight: 700;
        color: #000000;
        text-align: left;
      }
      
      .item-details {
        margin-top: 30px;
        text-align: left;
      }

      td, th {
        padding: 8px;
        font-size: 10px;
        line-height: 18px;
      }
      td{
        color: #000000;
      }

      .left-aligned{
        text-align: left;
      }
      .right-aligned{
        text-align: right;
      }
      .padding-left-16{
        padding-left: 16px;
      }
      .padding-left-8{
        padding-left: 8px;
      }
      .barcode{
        font-weight:600
      }
    }`,
    onBeforeGetContent: () => setPrintingLoading(true),
    onBeforePrint: () => setPrintingLoading(false)
  })


  const handleClick = () => {
    if (packingListForPrint) {
      handlePrint()
    } else {
      dispatch(getPackingListForPrint({ oms_order_id, hubId }))
      setIsApicalled(true)
    }
  }

  useEffect(() => {
    if (packingListForPrint && isApiCalled) {
      handlePrint()
    }

  }, [packingListForPrint])

  return (
    <div className='d-flex flex-column gap-1'>
      <div className='d-none'>
        {packingListForPrint && <PalletPackingSlipTemplate ref={componentRef} data={packingListForPrint} />}
      </div>
      <Button
        onClick={handleClick}
        disabled={printingLoading}
        innerRef={printSlipRef}
        className='d-none'
      >
        {printingLoading &&
          <Spinner size="sm" className="text-white me-1" />
        }
        {t('Print Slip')}
      </Button>
    </div>
  )
}

