export const ErrorIcon = () => { 
  return (
    <svg
      width="15"
      height="14"
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.333496 13.3333L7.66683 0.666626L15.0002 13.3333H0.333496ZM7.73641 11.3833C7.87891 11.3833 7.99739 11.3351 8.09183 11.2387C8.18627 11.1423 8.2335 11.0229 8.2335 10.8804C8.2335 10.7379 8.1853 10.6194 8.08891 10.525C7.99251 10.4305 7.87307 10.3833 7.73058 10.3833C7.58808 10.3833 7.46961 10.4315 7.37516 10.5279C7.28072 10.6243 7.2335 10.7437 7.2335 10.8862C7.2335 11.0287 7.28169 11.1472 7.37808 11.2416C7.47448 11.3361 7.59392 11.3833 7.73641 11.3833ZM7.2335 9.53329H8.2335V5.79996H7.2335V9.53329Z"
        fill="#C21808"
      />
    </svg>
  ) 
}