import { useLocation } from 'react-router-dom'
import { BULK_SHIP_PATH_NAMES } from './bulk-ship.constants'
import './bulk-ship.scss'
import Clusters from './components/clusters'
import ProcessCluster from './components/process-cluster'

const BulkShipModule = () => {
  const location = useLocation()
  
  const renderedModule = (() => {
    switch (location.pathname) {
    case BULK_SHIP_PATH_NAMES.CLUSTERS: return <Clusters />
    case BULK_SHIP_PATH_NAMES.PROCESS_CLUSTER: return <ProcessCluster />
    default: return null
    }
  })()

  return (
    <div id='__bulk-ship-module'>
      {renderedModule}
    </div>
  )
}

export default BulkShipModule