import RadioButton from '@src/@core/components/ui/radio-button'
import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import PerfectScrollbar from 'react-perfect-scrollbar'
import './styles.scss'

const TimeSlotSelector = ({ selectedFilter, handleSetSelectedOption, selectedOptions, setSelectedOptions }) => {
  const { filterKey } = selectedFilter
  const { t } = useTranslation()
  const [time, setTime] = useState({
    from_time: selectedOptions?.[filterKey] ? selectedOptions[filterKey].value[0] : '',
    to_time: selectedOptions?.[filterKey] ? selectedOptions[filterKey].value[1] : ''
  })
  const timeIntervals = useRef((() => {
    const startTime = new Date()
    startTime.setHours(0, 0, 0)
    const endTime = new Date()
    endTime.setHours(23, 45, 0)
    const intervals = []
    const currentInterval = new Date(startTime)

    while (currentInterval <= endTime) {
      intervals.push(currentInterval.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false }))
      currentInterval.setMinutes(currentInterval.getMinutes() + 15)
    }

    return intervals

  })())

  const handleTimeChange = (e) => {
    const { name, value } = e.target
    if (name === 'from_time') {
      setTime({ [name]: value, to_time: '' })
      const prevOptions = { ...selectedOptions }
      delete prevOptions[filterKey]
      setSelectedOptions(prevOptions)
    } else {
      setTime({ ...time, [name]: value })
      handleSetSelectedOption({ filterKey, option: { label: `${time.from_time}-${value}`, value: [time.from_time, value] } })
    }
  }

  return (
    <div className='time-slot-wrapper flex-start-between h-100 m-12px'>
      <div className='from-time width-45-per h-100'>
        <div className='py-4px px-10px text-center time-label txt-ass-md text-dark mb-12px'>{t('From')}</div>
        <PerfectScrollbar>
          <div className='d-flex flex-column gap-12px radio-buttons-container'>
            {timeIntervals.current.map((timeInterval) => {
              return <RadioButton
                key={`from-radio-${timeInterval}`}
                id={`from-radio-${timeInterval}`}
                name='from_time'
                label={timeInterval}
                value={timeInterval}
                checked={time.from_time === timeInterval}
                onChange={(e) => handleTimeChange(e)}
              />
            })}
          </div>
        </PerfectScrollbar>
      </div>
      <div className='to-time width-45-per h-100'>
        <div className='py-4px px-10px text-center time-label txt-ass-md text-dark mb-12px'>{t('To')}</div>
        <PerfectScrollbar>
          <div className='d-flex flex-column gap-12px radio-buttons-container'>
            {timeIntervals.current.map((timeInterval) => {
              return <RadioButton
                key={`to-radio-${timeInterval}`}
                id={`to-radio-${timeInterval}`}
                name='to_time'
                label={timeInterval}
                value={timeInterval}
                checked={time.to_time === timeInterval}
                onChange={(e) => handleTimeChange(e)}
                disabled={time.from_time && timeInterval <= time.from_time}
              />
            })}
          </div>
        </PerfectScrollbar>
      </div>
    </div>
  )
}

export default TimeSlotSelector