import CustomTag from '@src/@core/components/ui/badge/CustomTag'
import { CasePack } from '@src/assets/images/icons/casePack'
import { Pallet } from '@src/assets/images/icons/pallet'
import { PACKAGE_TYPE } from '@src/views/Purchases/purchase.constants'
import classNames from 'classnames'

const SkuTypeBadge = ({ packageType }) => {
  return (
    <CustomTag
      title={packageType === PACKAGE_TYPE.PALLET ? 'Pallet' : 'Case Pack'}
      icon={packageType === PACKAGE_TYPE.PALLET ? <Pallet /> : <CasePack />}
      alignIcon="right"
      className={classNames({
        'bg-brown-light text-brown': packageType === PACKAGE_TYPE.PALLET,
        'bg-info-light text-info': packageType === PACKAGE_TYPE.CASE_PACK
      })}
    />
  )
}

export default SkuTypeBadge