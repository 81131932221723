import SideSheet from '@src/@core/components/ui/sideSheet'
import ComponentSpinner from '@src/@core/components/ui/spinner/Loading-spinner'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { useDispatch, useSelector } from 'react-redux'
import { Col, ModalBody, Row } from 'reactstrap'
import { getSalesmanAddress } from '../../store/store'
const SalesmenDetails = (props) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const selectedGlobalHubId = useSelector(state => state.auth.selectedGlobalHubId)
  const { openSalesmanDetailsSidebar, setOpenSalesmanDetailsSidebar, salesmanDetails, isEditSalesmanEnabled, setIsEditSalesmanEnabled, handleEditSalesmanDetailsModal,setSelectedSalesman,setOpenCreateSalesmanSidebar } = props
  const salesmanAddresses = useSelector(store => store.sales.salesmanAddress?.data)

  const loading = useSelector(store => store.sales.loading)
  const success = useSelector(store => store.sales.success)


  const {
    createSalesmanSuccess,
    editSalesmanSuccess,
    getSalesmanSuccess,
    error,
    
  } = useSelector(store => {
    return {
      createSalesmanSuccess: store.sales.success.createSalesman,
      editSalesmanSuccess: store.sales.success.editSalesman,
      getSalesmanSuccess: store.sales.success.getSalesmen,
      error: store.sales.error,
      loading: store.sales.loading,
    }
  })
  
  const {
    control,
    handleSubmit,
    register,
    watch,
    setValue,
    reset,
    setError,
    clearErrors,
    formState: { errors }
  } = useForm({
    defaultValues: {
      first_name: '',
      last_name: '',
      email: '',
      salesman_phone_number: {
        countryCode: {
          name: 'Saudi Arabia',
          flag: '🇸🇦',
          country_code: 'SA',
          calling_code: '+966',
          value: 'Saudi Arabia (SA)',
          label: 'Saudi Arabia (+966)',
          maxPhoneNumberLength: 9
        },
        contactNumber: ''
      }
    },
    mode: 'onChange'
  })


  const getSalesmenAddress = () => {
    if (!loading.getSalesmanAddress) dispatch(getSalesmanAddress({hub_id:selectedGlobalHubId, sales_person_id:salesmanDetails.id}))
  }
  const handleOnsideSheetClosed = () => {
    // dispatch(clearCustomerAddresses())
    setIsEditSalesmanEnabled(false)
    reset()
  }

  const handleOnSideSheetOpen = () => {
    console.log('handleOnSideSheetOpen')
    getSalesmenAddress()
    setIsEditSalesmanEnabled(false)
   
  }
  // const handleEditSalesmanModal = (row) => {
  //   console.log(row,'open row')
  //   setSelectedSalesman(row)
  //   setIsEditSalesmanEnabled(true)
  //   setOpenCreateSalesmanSidebar({ open: true, type: SALESMAN_MODAL_TYPE.EDIT_SALESMAN.key })
    
  //   if (editSalesmanSuccess) {
  //     console.log('address')
  //     getSalesmenAddress()
  //   }
    
  // }


  useEffect(() => {
    if (watch('email') && errors.salesman_phone_number) clearErrors('salesman_phone_number')
    if (watch('salesman_phone_number.contactNumber') && errors.email?.type === 'required') clearErrors('email')
  }, [watch('email'), watch('salesman_phone_number.contactNumber')])
 

  return (
    <SideSheet
      isOpen={openSalesmanDetailsSidebar}
      toggle={() => { setOpenSalesmanDetailsSidebar(false) }}
      contentClassName="p-0"
      modalClassName="modal-slide-in sidebar-todo-modal"
      title='Salesman Details'
      onOpened={handleOnSideSheetOpen}
      onClosed={handleOnsideSheetClosed}
    >
      <PerfectScrollbar>
        {loading.getSalesmanAddress? <ComponentSpinner/> : <ModalBody className='customers-details-modal-body p-0'>
          
          <div className="d-flex flex-column gap-1 px-24px mb-5 mt-24px">

            <Row>
              <Col xs={4}>{t('Salesman Name')}:</Col>
              <Col className="text-black text-capitalize">{salesmanDetails?.first_name} {salesmanDetails?.last_name}</Col>
            </Row>
            <Row>
              <Col xs={4}>{t('Email ID')}:</Col>
              <Col className="text-black">{salesmanDetails?.email || '-'}</Col>
            </Row>
            <Row>
              <Col xs={4}>{t('Contact No')}:</Col>
              <Col className="text-black">
                {salesmanDetails?.phone 
                  ? `${salesmanDetails.country_calling_code}-${salesmanDetails.phone}` 
                  : '-'}
              </Col>
            </Row>
            <Row>
              <Col xs={4}>{t('Employee ID')}:</Col>
              <Col className="text-black">{salesmanDetails?.employee_id || '-'}</Col>
            </Row>
            <Row>
              <Col xs={4}>{t('Address Line 1')}:</Col>
              <Col className="text-black">{salesmanAddresses?.address?.address_line1 || '-'}</Col>
            </Row>
            <Row>
              <Col xs={4}>{t('Address Line 2')}:</Col>
              <Col className="text-black">{salesmanAddresses?.address?.address_line2 || '-'}</Col>
            </Row>
            <Row>
              <Col xs={4}>{t('Country')}:</Col>
              <Col className="text-black">{salesmanAddresses?.address?.country?.name|| '-'}</Col>
            </Row>
            <Row>
              <Col xs={4}>{t('State')}:</Col>
              <Col className="text-black">{salesmanAddresses?.address?.state?.name|| '-'}</Col>
            </Row>
            <Row>
              <Col xs={4}>{t('City')}:</Col>
              <Col className="text-black">{salesmanAddresses?.address?.city?.name|| '-'}</Col>
            </Row>
            <Row>
              <Col xs={4}>{t('Postal Code')}:</Col>
              <Col className="text-black">{salesmanAddresses?.address?.postal_code|| '-'}</Col>
            </Row>
            
          </div>
        </ModalBody>
        }
      </PerfectScrollbar>
      {/* <SidesheetFooter>
        <Button color='primary' onClick={() => {handleEditSalesmanModal(salesmanDetails) }}>Edit</Button>
      </SidesheetFooter> */}
    </SideSheet>
  )
}

export default SalesmenDetails
