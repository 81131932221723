const ReassignHub = ({width='40', height='40', color='var(--bs-primary)'}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M3.89474 20.9475V36.1054H19.0526V20.9475H14.5526V27.1541C14.5526 27.9992 13.6633 28.5488 12.9074 28.1709L11.4737 27.454L10.04 28.1709C9.2841 28.5488 8.39474 27.9992 8.39474 27.1541V20.9475H3.89474ZM10.2895 20.9475V25.9278L10.9653 25.5899C11.2853 25.4298 11.662 25.4298 11.9821 25.5899L12.6579 25.9278V20.9475H10.2895ZM2 20.1896C2 19.5617 2.50898 19.0527 3.13684 19.0527H19.8105C20.4384 19.0527 20.9474 19.5617 20.9474 20.1896V36.8633C20.9474 37.4911 20.4384 38.0001 19.8105 38.0001H3.13684C2.50899 38.0001 2 37.4911 2 36.8633V20.1896Z" fill={color}/>
      <path fillRule="evenodd" clipRule="evenodd" d="M29.2116 2.03693C29.3828 1.98769 29.5644 1.98769 29.7355 2.03693L37.3145 4.21751C37.7203 4.33429 37.9999 4.70562 37.9999 5.12795V14.3158C37.9999 14.839 37.5757 15.2632 37.0525 15.2632H32.9812C32.4579 15.2632 32.0338 14.839 32.0338 14.3158V9.32057H27.036V14.3158C27.036 14.839 26.6119 15.2632 26.0886 15.2632H21.8946C21.3714 15.2632 20.9473 14.839 20.9473 14.3158V5.12795C20.9473 4.70562 21.2268 4.33429 21.6327 4.21751L29.2116 2.03693ZM22.842 5.84118V13.3684H25.1413V8.37321C25.1413 7.84999 25.5654 7.42584 26.0886 7.42584H32.9812C33.5044 7.42584 33.9285 7.84999 33.9285 8.37321V13.3684H36.1052V5.84118L29.4736 3.93317L22.842 5.84118Z" fill={color}/>
      <path fillRule="evenodd" clipRule="evenodd" d="M32.3159 18.8613L35.6835 21.1064L34.6325 22.6829L33.2632 21.7701V29.4736H23.7896V27.5789H31.3685V21.7701L29.9993 22.6829L28.9483 21.1064L32.3159 18.8613Z" fill={color}/>
    </svg>
  );
};

export default ReassignHub