import BarcodeScanner from '@src/@core/components/ui/barcodeScanner'
import Button from '@src/@core/components/ui/button'
import CustomToast from '@src/@core/components/ui/custom-toast/CustomToast'
import { KEYBOARD_KEYS, PRINT_PAGE_STYLE } from '@src/App.constants'
import failBin from '@src/assets/images/inventory/icons/fail-barcode.svg'
import passBin from '@src/assets/images/inventory/icons/pass-barcode.svg'
import {
    generateFailBinId,
    generatePassBinId,
    scanFailBinAtGrn,
    scanPassBinAtGrn
} from '@src/views/inventory/store'
import classNames from 'classnames'
import { useEffect, useRef, useState } from 'react'
import Barcode from 'react-barcode'
import { Printer } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useReactToPrint } from 'react-to-print'
import { Spinner } from 'reactstrap'
import { BIN_BARCODE_STATUS, BIN_STATUS } from '../constant'
// import { GRN_TYPES } from "@src/views/inventory/inventory.constants"
import { GRN_CODE_TYPE, GRN_CONTAINER_TYPES } from '@src/views/inventory/inventory.constants'
import QRCode from 'react-qr-code'
import SelectContainerModal from './SelectContainerModal'

const IdentifyBin = ({
  control,
  setValue,
  watch,
  onClick,
  grnResponse
}) => {
  const {t} = useTranslation()
  const passBarcodeRef = useRef()
  const failBarcodeRef = useRef()
  const passQRcodeRef = useRef()
  const failQRcodeRef = useRef()
  const startQCButtonRef = useRef()
  const dispatch = useDispatch()
  // const grnResponse = useSelector(state => state.inventory.grn.grnResponse)
  const loadingState = useSelector(state => state.inventory.loadingState)
  const passBinId = useSelector(state => state.inventory.grn.pass_bin_id)
  const failBinId = useSelector(state => state.inventory.grn.fail_bin_id)
  const passBinError = useSelector(state => state.inventory.grn.pass_bin_error)
  const failBinError = useSelector(state => state.inventory.grn.fail_bin_error)
  // const loading = useSelector((state) => state.inventory.loadingState)
  const selectedGlobalHubID = useSelector((store) => store.auth.selectedGlobalHubId)
  const hasBarcodePrinted = useSelector(store => store.inventory.grn.has_barcode_printed)
  const hasBarcodeMapped = useSelector(store => store.inventory.grn.has_barcode_mapped)


  const handlePrintPassBarcode = useReactToPrint({
    content: () => passBarcodeRef.current,
    pageStyle: '@page {size: 378px 200px; margin: auto 0}',
    onAfterPrint: () => {
      startQCButtonRef.current?.focus()
    }
  })
  const handlePrintFailBarcode = useReactToPrint({
    content: () => failBarcodeRef.current,
    pageStyle: '@page {size: 378px 284px; margin: auto 0}',
    onAfterPrint: () => {
      startQCButtonRef.current?.focus()
    }
  })
  const handlePrintPassQRcode = useReactToPrint({
    content: () => passQRcodeRef.current,
    pageStyle: ` @media print {
      body {
        height: fit-content !important;
      }
      @page {
        size: 364px 150px;
        margin: auto 0 !important;
        padding: 0 !important;
      }`,
    onAfterPrint: () => {
      startQCButtonRef.current?.focus()
    }
  })
  const handlePrintFailQRcode= useReactToPrint({
    content: () => failQRcodeRef.current,
    pageStyle: ` @media print {
      body {
        height: fit-content !important;
      }
      @page {
        size: 364px 150px;
        margin: auto 0 !important;
        padding: 0 !important;
      }`,
    onAfterPrint: () => {
      startQCButtonRef.current?.focus()
    }
  })

  const [openSelectContainerModal, setOpenSelectContainerModal] = useState(false)
  const [binBarcodeStatus, setBinBarcodeStatus] = useState(null)

  const handleOpenSelectContainerModal = (status) => {
    setBinBarcodeStatus(status)
    setOpenSelectContainerModal(true)
  }

  const handleGenerateBinBarcode = async (status, container_type, code_type) => {
    if (status === BIN_BARCODE_STATUS.pass) {
      await dispatch(
        generatePassBinId({
          grn_id: grnResponse.id,
          status,
          hub_id: selectedGlobalHubID,
          is_pallet: container_type === GRN_CONTAINER_TYPES.PALLET
        })
      )
      if (code_type === GRN_CODE_TYPE.BARCODE) {
        handlePrintPassBarcode()
      } else {
        handlePrintPassQRcode()
      }
    }
    if (status === BIN_BARCODE_STATUS.fail) {
      await dispatch(
        generateFailBinId({
          grn_id: grnResponse.id,
          status,
          hub_id: selectedGlobalHubID,
          is_pallet: container_type === GRN_CONTAINER_TYPES.PALLET
        })
      )
      if (code_type === GRN_CODE_TYPE.BARCODE) {
        handlePrintFailBarcode()
      } else {
        handlePrintFailQRcode()
      }
    }
  }

  const handleAddExistingBin = (type, e) => {
    if (e.key === KEYBOARD_KEYS.ENTER) {
      if (e.target.value === '') {
        CustomToast(('Please enter a barcode'), { my_type: 'error', audioRequired: false })
        return
      }
      const body = {
        grn_id: grnResponse.id,
        // bin_qc_type: type,
        barcode: e.target.value.toString().trim(),
        hub_id: selectedGlobalHubID,
        errorConfig: {
          audioRequired: true
        }
      }
      if (type === BIN_STATUS.PASS) {
        body.bin_qc_type = BIN_BARCODE_STATUS.pass
        dispatch(scanPassBinAtGrn(body))
      } else if (type === BIN_STATUS.FAIL) {
        body.bin_qc_type = BIN_BARCODE_STATUS.fail
        dispatch(scanFailBinAtGrn(body))
      }
    }
  }

  useEffect(() => {
    if (passBinId !== null) setValue('pass_bin_barcode', passBinId.barcode)
    if (failBinId !== null) setValue('fail_bin_barcode', failBinId.barcode)
  }, [passBinId, failBinId])
  useEffect(() => {
    if (passBinError) setValue('pass_bin_barcode', '')
    if (failBinError) setValue('fail_bin_barcode', '')
  }, [passBinError, failBinError])


  return (
    <>
      <div className="d-flex flex-column gap-28px">
        <text className="txt-body-md text-dark">{t('Associate pass or fail bin/pallet.')}</text>
        <div className="d-flex gap-16px align-items-center">
          <BarcodeScanner
            startIcon={<img width="16px" height="16px" src={passBin} />}
            placeholder="Scan existing bin/pallet to keep passed items."
            control={control}
            name="pass_bin_barcode"
            hasValue={watch('pass_bin_barcode')}
            persistPlaceholder="Pass Bin/Pallet"
            disabled={!grnResponse.id}
            autoFocus
            onKeyDown={(e) => handleAddExistingBin(BIN_STATUS.PASS, e)}
            loading={loadingState.scanPassBinAtGrn}
          />
          {!watch('pass_bin_barcode') && (
            <>
              <Button
                disabled={!grnResponse.id}
                icon={() => { return loadingState.generatePassBinId ? <Spinner size='sm' /> : <Printer size={16} /> }}
                onClick={() => handleOpenSelectContainerModal(BIN_BARCODE_STATUS.pass)}
                ofStyle={'noBackground'}
              >
                {t('Add New Bin/Pallet')}
              </Button>
              <div className="d-none">
                <div ref={passBarcodeRef}>
                  <style>{ `
  @media print {
    body {
      height: fit-content !important;
    }
    @page {
      size: 364px 150px;
      margin: auto 0 !important;
      padding: 0 !important;
    }` }</style>
                  <Barcode value={passBinId.barcode} />
                </div>
              </div>
              <div className="d-none">
                <div ref={passQRcodeRef} className="d-flex justify-content-center align-items-center">
                  <style>{ PRINT_PAGE_STYLE }</style>
                  <div className='flex-1'></div>
                  <div
                    className='d-flex justify-content-start align-items-center  my-1 gap-4px overflow-hidden'
                    style={{
                      margin: 'auto',
                      padding: '0px',
                      paddingTop:'0px'
                    }}
                  >
                    <div className='position-relative h-100 p-0 d-flex align-items-center justify-content-center'>
                      <QRCode value={passBinId?.barcode || ''} size={50} />
                    </div>
                    <div className='d-flex h-100 w-100 m-0 p-0 text-dark border-2 font-medium-5'>
                      {
                        passBinId?.barcode?.split('')?.map((el, index, arr) => {
                          return (
                            <p
                              className='h-100 d-flex flex-column p-0 m-0 justify-content-between'
                              style={{
                                margin: 0
                              }}
                            >
                              <div className='flex-1'></div>
                              <div
                                className='d-flex flex-column flex-center'
                                style={{
                                  padding: ' 0',
                                }}
                              >
                                <span
                                  className='d-block p-0'
                                  style={{
                                    fontWeight: 'bolder',
                                    fontSize:20,
                                    lineHeight: 1,
                                  }}
                                >
                                  {el}
                                  {index < arr.length - 1}
                                </span>
                              </div>
                              <div className='flex-1'></div>
                            </p>
                          )
                        })}
                    </div>
                  </div>
                  <div className='flex-1'></div>
                </div>
              </div>
            </>
          )}
        </div>
        <div className="d-flex gap-16px align-items-center">
          <BarcodeScanner
            startIcon={<img width="16px" height="16px" src={failBin} />}
            placeholder="Scan existing bin/pallet to keep failed items."
            control={control}
            name="fail_bin_barcode"
            hasValue={watch('fail_bin_barcode')}
            persistPlaceholder="Fail Bin/Pallet"
            disabled={!grnResponse.id}
            autoFocus={watch('pass_bin_barcode') && !watch('fail_bin_barcode')}
            onKeyDown={(e) => handleAddExistingBin(BIN_STATUS.FAIL, e)}
            loading={loadingState.scanFailBinAtGrn}
          />
          {!watch('fail_bin_barcode') && (
            <>
              <Button
                disabled={!grnResponse.id}
                icon={() => { return loadingState.generateFailBinId ? <Spinner size='sm' /> : <Printer size={16} /> }}
                onClick={() => handleOpenSelectContainerModal(BIN_BARCODE_STATUS.fail)}
                ofStyle={'noBackground'}
              >
                {t('Add New Bin/Pallet')}
              </Button>
              <div className="d-none">
                <div ref={failBarcodeRef}>
                  <style>{ `
  @media print {
    body {
      height: fit-content !important;
    }
    @page {
      size: 364px 150px;
      margin: auto 0 !important;
      padding: 0 !important;
    }` }</style>
                  <Barcode value={failBinId.barcode} />
                </div>
              </div>
              <div className="d-none">
                <div ref={failQRcodeRef} className="d-flex justify-content-center align-items-center">
                  <style>{ PRINT_PAGE_STYLE }</style>
                  <div className='flex-1'></div>
                  <div
                    className='d-flex justify-content-start align-items-center  my-1 gap-4px overflow-hidden'
                    style={{
                      margin: 'auto',
                      padding: '0px',
                      paddingTop:'0px'
                    }}
                  >
                    <div className='position-relative h-100 p-0 d-flex align-items-center justify-content-center'>
                      <QRCode value={failBinId?.barcode || ''} size={50} />
                    </div>
                    <div className='d-flex h-100 w-100 m-0 p-0 text-dark border-2 font-medium-5'>
                      {
                        failBinId?.barcode?.split('')?.map((el, index, arr) => {
                          return (
                            <p
                              className='h-100 d-flex flex-column p-0 m-0 justify-content-between'
                              style={{
                                margin: 0
                              }}
                            >
                              <div className='flex-1'></div>
                              <div
                                className='d-flex flex-column flex-center'
                                style={{
                                  padding: ' 0',
                                }}
                              >
                                <span
                                  className='d-block p-0'
                                  style={{
                                    fontWeight: 'bolder',
                                    fontSize:20,
                                    lineHeight: 1,
                                  }}
                                >
                                  {el}
                                  {index < arr.length - 1}
                                </span>
                              </div>
                              <div className='flex-1'></div>
                            </p>
                          )
                        })}
                    </div>
                  </div>
                  <div className='flex-1'></div>
                </div>
              </div>
            </>
          )}
        </div>
        <Button
          onClick={onClick}
          disabled={loadingState.getDisplayGrnDetail || (!hasBarcodeMapped && !hasBarcodePrinted)}
          ref={startQCButtonRef}
          type="submit"
        >
          {loadingState.getDisplayGrnDetail && <Spinner size="sm" />}
          <span className={classNames({ 'ms-50': loadingState.getDisplayGrnDetail })} >{t('Start QC')}</span>
        </Button>
      </div>

      {/* Select container type modal */}
      <SelectContainerModal
        isOpen={openSelectContainerModal}
        toggle={() => setOpenSelectContainerModal(false)}
        handleGenerateBinBarcode={handleGenerateBinBarcode}
        binBarcodeStatus={binBarcodeStatus}
      />
    </>
  )
}

export default IdentifyBin
