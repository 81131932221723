// ** React Imports
import { NavLink, useLocation, useParams } from 'react-router-dom'
// ** Third Party Components
import classnames from 'classnames'
import { useTranslation } from 'react-i18next'
// ** Reactstrap Imports
import { useRTL } from '@src/utility/hooks/useRTL'
import { useEffect, useState } from 'react'
import { Badge } from 'reactstrap'
// ** CSS imports
import './primaryNav.scss'

const PrimaryMenuLink = ({ item, activeItem, setActiveItem, setSecondaryMenuActiveItem, idx, totalNavbarItems }) => {
  // ** Conditional Link Tag, if item has newTab or externalLink props use <a> tag else use NavLink
  const LinkTag = item.externalLink ? 'a' : NavLink
  // ** Hooks
  const [isHovered,setIsHovered] = useState(false);
  const { t } = useTranslation()
  const location = useLocation()
  const params = useParams()
  const [isRtl] = useRTL()
  const getActiveChildItem = primaryNavItem => {
    const copiedItem = { ...primaryNavItem }
    const { children, navLink } = copiedItem  
    // Handle children recursively
    if (children) {
      for (const child of children) {
        const activeChild = getActiveChildItem(child)
        if (activeChild) {
          return activeChild
        }
      }
    } else {
      // Function to check if a navLink matches the current location
      const isActiveLink = (link) => {
        let activeLink = link
        Object.keys(params).forEach(key => {
          activeLink = activeLink.replace(`:${key}`, params[key])
        })
        return activeLink && activeLink === location.pathname
      }

      // Handle case where navLink is an array
      if (Array.isArray(navLink)) {
        const activeLink = navLink.find(navLink => isActiveLink(navLink))
        if (activeLink) {
          return primaryNavItem
        }
      } else if (navLink && isActiveLink(navLink)) {
        return primaryNavItem
      } else {
        return null
      }
    }
  }
  useEffect(() => {
    // ! INFO: Carefully done for current use case only
    const activeChildItem = getActiveChildItem(item)
    if (activeChildItem) {
      setActiveItem(item)
      setSecondaryMenuActiveItem(activeChildItem)
    } else {
      if (item.navLink === location.pathname) {
        setActiveItem(item)
      }
    }
  }, [location])
  return (
    <>
      <li
        className={classnames('flex-center-center flex-column w-100 h-100 p-0 , primary-menu-link', {
          'nav-item': !item.children,
          disabled: item.disabled,
          active: activeItem?.id === item.id,
          'primary-nav-menu-item': true,
          'primary-nav-menu-item-rtl': isRtl
        })}
        style={{ width: 'auto', height: 'auto', position: 'relative' }}
        onMouseOver={()=>setIsHovered(true)}
        onMouseOut={()=>setIsHovered(false)}
      >
        {/* {item.id !== 'landing_page' ? <div className="primary-nav-tooltip">{item.title}</div> : null} */}
        <LinkTag
          title={t(item.title)}
          className="d-flex justify-content-center align-items-center p-0 m-0"
          target={item.newTab ? '_blank' : undefined}
          /*eslint-disable */
          {...(item.externalLink === true
            ? {
              href: Array.isArray(item.navLink) ? item.navLink[0] : item.navLink || '/'
            }
            : {
              to: Array.isArray(item.navLink) ? item.navLink[0] : item.navLink || '/',
              className: ({ isActive }) => {
                if (isActive && !item.disabled) {
                  return ''
                }
              }
            })}
          onClick={e => {
            // setActiveItem(item)
            if (item.navLink.length === 0 || item.navLink === '#' || item.disabled === true) {
              e.preventDefault()
            }
          }}
        >
          <div className="d-flex flex-column align-items-center">
            <div
              className="d-flex align-items-center justify-content-center icon-container"
              id={`positionRight-${item.id}`}
              style={
                activeItem?.id == item.id && activeItem?.id !== 'landing_page'
                  ? {
                    height: '40px',
                    width: '40px',
                    background: 'linear-gradient(90deg, var(--bs-primary) 0%, var(--bs-primary) 100%)',
                    // background: 'linear-gradient(90deg, #5468FA 0%, rgba(84, 104, 250, 0.79) 100%)',
                    // boxShadow: '0px 0px 12px 4px var(--bs-primary)',
                    borderRadius: '4px',
                    color: 'white'
                  }
                  : {
                    height: '40px',
                    width: '40px',
                    color: 'black'
                  }
              }
            >
              <div style={{ height: '40px', width: '40px' }} className="d-flex justify-content-center align-items-center">
                <item.icon iswhite={activeItem?.id === item.id} height={20} width={20} fillColor={isHovered?'#222' : '#666'}/>
              </div>
            </div>
            {false && <span className="menu-item text-truncate">{t(item.title)}</span>}
            {item.badge && item.badgeText ? (
              <Badge className="custom-badge" color={item.badge}>
                {item.badgeText}
              </Badge>
            ) : null}
            <div
              className={classnames('link-title cursor-pointer', {
                selected: activeItem?.id === item.id
              })}
              style={{color: isHovered? "#222" : "#666"}}
            >
              {t(item.title)}
            </div>
          </div>
        </LinkTag>
      </li>
      {totalNavbarItems - 2 === idx ? (
        <hr key={item.id} style={{ width: '80%', maxWidth: 42, border: 0, margin: '0 auto', height: 2, background: '#f2f2f2', borderRadius: 3 }} />
      ) : null}
    </>
  )
}
export default PrimaryMenuLink
