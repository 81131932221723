export const editSkuWeightStyles = {
  control: (provided) => ({
    ...provided,
    border: 'none',
    cursor: 'pointer',
    boxShadow: 'none'
  }),
  valueContainer: (provided) => ({
    ...provided,
    maxWidth: '50px'
  }),
  option: provided => ({
    ...provided,
    cursor: 'pointer'
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: 'none'
  }),
  menu: (provided) => ({
    ...provided,
    borderRadius: '4px',
    width: '100px',
    right: 0
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: 0,
    marginInlineEnd: '5px',
    svg: {
      width: '15px',
      height: '15px'
    }
  })
}

export const editSkuWeightTheme = (theme) => ({
  ...theme,
  boxShadow: 'none',
  colors: {
    ...theme.colors,
    primary: 'var(--bs-primary)',
    primary25: 'var(--bs-primary-lighter)',
    primary50: 'var(--bs-primary-lighter)'
  }
})