const SVG = ({ iswhite, fillColor, height, width }) => {
  const color = iswhite ? 'white' : fillColor
  return (
    <svg width={width} height={height} viewBox="0 0 77 77" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M29.637 30.5761C29.2134 30.4067 28.7413 30.4044 28.316 30.5698L11.3476 37.1696C10.6557 37.4387 10.2 38.1049 10.2 38.8472V59.6114C10.2 60.6056 11.0059 61.4114 12 61.4114H17.698V49.7885C17.698 47.4689 19.5784 45.5885 21.898 45.5885H35.7923C38.1119 45.5885 39.9923 47.4689 39.9923 49.7885V61.4114H45.4156C46.4097 61.4114 47.2156 60.6056 47.2156 59.6114V38.8262C47.2156 38.0902 46.7675 37.4283 46.0841 37.1549L29.637 30.5761ZM27.4461 28.333C28.4382 27.9471 29.5399 27.9524 30.5284 28.3478L46.9755 34.9266C48.57 35.5644 49.6156 37.1088 49.6156 38.8262V59.6114C49.6156 61.931 47.7352 63.8114 45.4156 63.8114H37.5923V49.7885C37.5923 48.7944 36.7864 47.9885 35.7923 47.9885H21.898C20.9039 47.9885 20.098 48.7944 20.098 49.7885V63.8114H12C9.68045 63.8114 7.80005 61.931 7.80005 59.6114V38.8472C7.80005 37.1151 8.86332 35.5607 10.4776 34.9328L27.4461 28.333Z" fill={color}/>
      <path d="M54.1139 39.2006V20.0056H55.3436V39.2006H54.1139ZM57.9604 25.9591C57.8805 25.2492 57.5505 24.6994 56.9707 24.3095C56.3908 23.9146 55.661 23.7171 54.7813 23.7171C54.1514 23.7171 53.6066 23.8171 53.1467 24.0171C52.6868 24.212 52.3294 24.4819 52.0745 24.8269C51.8245 25.1668 51.6996 25.5542 51.6996 25.989C51.6996 26.354 51.7845 26.6689 51.9545 26.9338C52.1295 27.1987 52.3569 27.4212 52.6368 27.6011C52.9218 27.7761 53.2267 27.9235 53.5516 28.0435C53.8765 28.1585 54.1889 28.2535 54.4888 28.3284L55.9884 28.7183C56.4783 28.8383 56.9807 29.0008 57.4956 29.2057C58.0104 29.4107 58.4878 29.6806 58.9277 30.0155C59.3676 30.3504 59.7225 30.7653 59.9924 31.2602C60.2673 31.755 60.4048 32.3474 60.4048 33.0372C60.4048 33.907 60.1798 34.6793 59.73 35.3541C59.2851 36.0289 58.6378 36.5613 57.788 36.9512C56.9432 37.3411 55.921 37.536 54.7213 37.536C53.5716 37.536 52.5768 37.3536 51.7371 36.9887C50.8973 36.6238 50.24 36.1064 49.7651 35.4366C49.2902 34.7618 49.0278 33.962 48.9778 33.0372H51.3022C51.3472 33.5921 51.5271 34.0544 51.842 34.4243C52.1619 34.7892 52.5693 35.0617 53.0642 35.2416C53.5641 35.4166 54.1114 35.5041 54.7063 35.5041C55.3611 35.5041 55.9435 35.4016 56.4533 35.1966C56.9682 34.9867 57.3731 34.6968 57.668 34.3269C57.9629 33.952 58.1104 33.5146 58.1104 33.0147C58.1104 32.5598 57.9804 32.1874 57.7205 31.8975C57.4656 31.6076 57.1182 31.3676 56.6783 31.1777C56.2434 30.9877 55.751 30.8203 55.2012 30.6753L53.3866 30.1805C52.157 29.8455 51.1822 29.3532 50.4624 28.7033C49.7476 28.0535 49.3902 27.1937 49.3902 26.124C49.3902 25.2392 49.6301 24.4669 50.11 23.8071C50.5899 23.1473 51.2397 22.6349 52.0595 22.27C52.8793 21.9001 53.804 21.7152 54.8337 21.7152C55.8735 21.7152 56.7907 21.8976 57.5855 22.2625C58.3853 22.6274 59.0152 23.1298 59.475 23.7696C59.9349 24.4045 60.1749 25.1343 60.1948 25.9591H57.9604Z" fill={color}/>
      <path fillRule="evenodd" clipRule="evenodd" d="M54.484 16.2C47.1297 16.2 41.1678 22.1619 41.1678 29.5162C41.1678 30.91 41.3816 32.2517 41.7772 33.5114L39.4875 34.2306C39.0196 32.7408 38.7678 31.1568 38.7678 29.5162C38.7678 20.8364 45.8042 13.8 54.484 13.8C63.1637 13.8 70.2001 20.8364 70.2001 29.5162C70.2001 38.196 63.1637 45.2323 54.484 45.2323C52.0271 45.2323 49.6987 44.6677 47.6246 43.6601L48.6733 41.5013C50.4278 42.3537 52.3983 42.8323 54.484 42.8323C61.8382 42.8323 67.8001 36.8705 67.8001 29.5162C67.8001 22.1619 61.8382 16.2 54.484 16.2Z" fill={color}/>
    </svg>
  )
}
  
export default SVG