/*eslint-disable no-undef*/

import { init as SentryInit, browserTracingIntegration, createReduxEnhancer, httpClientIntegration, reactRouterV6BrowserTracingIntegration } from '@sentry/react'
import { ENVIRONMENT } from '@src/App.constants'
import { useEffect } from 'react'
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom'

export default class SentryUtility {
  static init() {
    SentryInit({
      dsn: import.meta.env.VITE_SENTRY_DSN_KEY,
      environment: SentryUtility.getEnvironment(),
      tracesSampleRate: 0.1,
      normalizeDepth: 5,
      maxBreadcrumbs: 50,
      ignoreErrors: [
        'HTTP Client Error with status code: 400',
        '\'text/html\' is not a valid JavaScript MIME type.',
        'Load failed',
        'Importing a module script failed.',
        'Failed to fetch',
        'Failed to fetch dynamically imported module:',
        'Load failed',
        'Failed to execute \'removeChild\' on \'Node\': The node to be removed is not a child of this node.',
        'Failed to execute \'insertBefore\' on \'Node\': The node before which the new node is to be inserted is not a child of this node.',
        'Non-Error promise rejection captured with value: Object Not Found Matching Id:',
        'Unable to preload CSS for /assets/',
        'The object can not be found here',
        'Object captured as promise rejection with keys: code, data, message',
        'Failed to load module script: Expected a JavaScript module script but the server responded with a MIME type of "text/html". Strict MIME type checking is enforced for module scripts per HTML spec.'
      ],
      beforeSend(event, hint) {
        const error = hint.originalException

        if (error && error.message) {
          const errorMessage = error.message.toLowerCase()
          const isFetchError = errorMessage.includes('\'text/html\' is not a valid javascript mime type') ||
            errorMessage.includes('failed to fetch') ||
            errorMessage.includes('failed to fetch dynamically imported module') ||
            errorMessage.includes('failed to load module script: expected a javascript module script but the server responded with a mime type of "text/html". strict mime type checking is enforced for module scripts per html spec.') ||
            errorMessage.includes('load failed') ||
            errorMessage.includes('error loading dynamically imported module')
            
          const isIgnorableError = errorMessage.includes('http client error with status code: 400') ||
            errorMessage.includes('failed to execute \'removechild\' on \'node\': the node to be removed is not a child of this node.') ||
            errorMessage.includes('failed to execute \'insertbefore\' on \'node\': the node before which the new node is to be inserted is not a child of this node.') ||
            errorMessage.includes('non-error promise rejection captured with value: object not found matching id:') ||
            errorMessage.includes('unable to preload css for /assets/') ||
            errorMessage.includes('the object can not be found here') ||
            errorMessage.includes('object captured as promise rejection with keys: code, data, message')
          
          if (isFetchError || isIgnorableError) {
            event.level = 'warning' // Change error to warning
            if (isFetchError) {
              const hasRefreshed = JSON.parse(
                (typeof window.sessionStorage === 'object' &&
                    window.sessionStorage.getItem('retry-lazy-refreshed')) ||
                    'false'
              )
              if (!hasRefreshed) {
                if (typeof window.sessionStorage === 'object') {
                  window.sessionStorage.setItem('retry-lazy-refreshed', 'true')
                }
                window.location.reload()
              }
            }
          }
        }

        return event
      },
      integrations: [
        reactRouterV6BrowserTracingIntegration({
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        }),
        browserTracingIntegration({}),
        httpClientIntegration({
          failedRequestStatusCodes: [400, 500]
        })
      ]
    })
  }

  static getEnvironment() {
    const env = import.meta.env.VITE_ENVIRONMENT || ENVIRONMENT.DEVELOPMENT

    const environmentMap = {
      [ENVIRONMENT.DEVELOPMENT]: 'Development',
      [ENVIRONMENT.STAGING]: 'Staging',
      [ENVIRONMENT.PRODUCTION]: 'Production'
    }

    return environmentMap[env]
  }

  static logger = (data) => {
    return data
  }

  static sentryReduxEnhancer = createReduxEnhancer({})
}
