const BackFile = ({width = '60', height = '90', color}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 157 173" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.769043 3.16644V170.4C0.769043 171.652 1.78404 172.667 3.03604 172.667H154.397C155.649 172.667 156.664 171.652 156.664 170.4V17.3384C156.664 16.0864 155.649 15.0714 154.397 15.0714H57.608C56.811 15.0714 56.073 14.6534 55.664 13.9704L48.481 1.99944C48.071 1.31644 47.333 0.898438 46.537 0.898438H3.03604C1.78404 0.898438 0.769043 1.91444 0.769043 3.16644Z" fill={color} />
      <path d="M140.791 29.8086H16.074V172.666H140.791V29.8086Z" fill="url(#paint0_linear_6180_31084)"/>
      <path d="M147.593 44.5508H10.405V172.669H147.593V44.5508Z" fill="url(#paint1_linear_6180_31084)"/>
      <defs>
        <linearGradient id="paint0_linear_6180_31084" x1="78.432" y1="29.8086" x2="78.432" y2="172.666" gradientUnits="userSpaceOnUse">
          <stop stopColor="white"/>
          <stop offset="1" stopColor="#4F4F4F"/>
        </linearGradient>
        <linearGradient id="paint1_linear_6180_31084" x1="78.999" y1="44.5508" x2="78.999" y2="172.669" gradientUnits="userSpaceOnUse">
          <stop stopColor="white"/>
          <stop offset="1" stopColor="#4F4F4F"/>
        </linearGradient>
      </defs>
    </svg>
    
  )
}

export default BackFile