const SVG = ({ iswhite, fillColor, height, width }) => {
  const color = iswhite ? 'white' : fillColor
  return (
    <svg width={width} height={height} viewBox="0 0 77 77" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M51.7223 58.8633L58.2816 61.9827C59.6191 60.8604 60.8559 59.6191 61.9827 58.2816L58.8633 51.7223C60.3474 49.4092 61.4376 46.8212 62.0422 44.05L68.8809 41.6178C68.9542 40.7566 69 39.8817 69 39.0023C69 38.1228 68.9542 37.248 68.8809 36.3868L62.0422 33.9546C61.4376 31.1834 60.3474 28.5954 58.8633 26.2822L61.9827 19.723C60.8604 18.3854 59.6191 17.1487 58.2816 16.0219L51.7223 19.1412C49.4092 17.6571 46.8212 16.567 44.05 15.9624L41.6178 9.11909C40.7566 9.04581 39.8863 9 39.0023 9C38.1183 9 37.248 9.04581 36.3868 9.11909L33.9546 15.9578C31.1834 16.5624 28.5954 17.6526 26.2822 19.1367L19.723 16.0173C18.3854 17.1396 17.1487 18.3809 16.0219 19.7184L19.1412 26.2777C17.6571 28.5908 16.567 31.1788 15.9624 33.95L9.11909 36.3868C9.04581 37.248 9 38.1183 9 39.0023C9 39.8863 9.04581 40.7566 9.11909 41.6178L15.9578 44.05C16.5624 46.8212 17.6526 49.4092 19.1367 51.7223L16.0173 58.2816C17.1396 59.6191 18.3809 60.8559 19.7184 61.9827L26.2777 58.8633C28.5908 60.3474 31.1788 61.4376 33.95 62.0422L36.3822 68.8809C37.2434 68.9542 38.1183 69 38.9977 69C39.8772 69 40.752 68.9542 41.6132 68.8809L44.0454 62.0422C46.8212 61.4422 49.4092 60.3474 51.7223 58.8633Z"
        stroke={color}
        strokeWidth="2.4"
        strokeMiterlimit="10"
      />
      <path
        d="M39.0001 55.5189C48.1233 55.5189 55.5191 48.1231 55.5191 38.9999C55.5191 29.8768 48.1233 22.481 39.0001 22.481C29.8769 22.481 22.4811 29.8768 22.4811 38.9999C22.4811 48.1231 29.8769 55.5189 39.0001 55.5189Z"
        stroke={color}
        strokeWidth="2.4"
        strokeMiterlimit="10"
      />
      <path
        d="M27 50.6862C28.1136 45.0717 33.0666 40.8391 39.0081 40.8391C44.7604 40.8391 49.586 44.8063 50.8982 50.1539"
        stroke={color}
        strokeWidth="2.4"
      />
      <circle cx="38.7419" cy="33.1212" r="4.92117" stroke={color} strokeWidth="2.4" />
    </svg>
  )
}
export default SVG