export const AdHocEmptyIllustration = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="161" height="160" viewBox="0 0 161 160" fill="none">
    <ellipse cx="136.768" cy="102.383" rx="0.817369" ry="1.02171" transform="rotate(-0.802568 136.768 102.383)" fill="#505050"/>
    <ellipse cx="147.714" cy="96.0985" rx="0.817369" ry="1.02171" transform="rotate(-0.802568 147.714 96.0985)" fill="#505050"/>
    <path d="M59.7357 87.3967L21.1396 64.3266V58.4223L59.7357 80.9458L95.653 47.3246L131.57 39.9443V45.5205L95.653 53.3382L59.7357 87.3967Z" fill="#495AD9"/>
    <path d="M59.7357 87.3967L21.1396 64.3266V58.4223L59.7357 80.9458L95.653 47.3246L131.57 39.9443V45.5205L95.653 53.3382L59.7357 87.3967Z" fill="url(#paint0_linear_1532_43643)"/>
    <path d="M59.7357 80.9451L21.1396 58.4216L57.0569 24.7457L92.9742 17.3108L131.57 39.9436L95.653 47.3239L59.7357 80.9451Z" fill="url(#paint1_linear_1532_43643)"/>
    <path opacity="0.18" d="M92.9739 17.3108L131.57 39.9436L95.6527 47.3239L57.0566 24.7457L92.9739 17.3108Z" fill="black"/>
    <path d="M22.6709 103.14V65.3096L59.7362 87.3957V125.226L22.6709 103.14Z" fill="url(#paint2_linear_1532_43643)"/>
    <path d="M130.041 84.2265V45.8491L95.5999 53.2841L59.7373 87.3973V125.228L130.041 84.2265Z" fill="url(#paint3_linear_1532_43643)"/>
    <path d="M64.6543 92.5357V86.4128L125.446 50.9875V57.1104L64.6543 92.5357Z" fill="url(#paint4_linear_1532_43643)"/>
    <path d="M125.446 57.1104L64.6543 92.5357V90.677L123.696 56.4544V52.007L125.446 50.9875V57.1104Z" fill="black" fillOpacity="0.3"/>
    <path d="M52.7371 90.1288V114.183L29.5576 100.516V76.4617L52.7371 90.1288Z" fill="url(#paint5_linear_1532_43643)"/>
    <path d="M29.5576 100.516V76.4617L31.9683 77.8831V98.8758L52.7371 111.122V114.183L29.5576 100.516Z" fill="black" fillOpacity="0.3"/>
    <path d="M87.0684 85.7564V109.264L124.462 87.5058V63.9983L87.0684 85.7564Z" fill="url(#paint6_linear_1532_43643)"/>
    <path d="M124.464 87.5058V63.9983L104.018 75.8953L124.464 87.5058Z" fill="url(#paint7_linear_1532_43643)"/>
    <path d="M65.3115 102.922V121.969L81.3841 112.6V93.5186L65.3115 102.922Z" fill="url(#paint8_linear_1532_43643)"/>
    <path opacity="0.2" d="M81.3841 103.059L65.3115 112.445M81.3841 103.059V100.674M81.3841 103.059V105.445M65.3115 112.445V110.064M65.3115 112.445V114.826M81.3841 98.289L65.3115 107.684M81.3841 98.289V95.9038M81.3841 98.289V100.674M65.3115 107.684V105.303M65.3115 107.684V110.064M81.3841 107.83L65.3115 117.207M81.3841 107.83V105.445M81.3841 107.83V110.215M65.3115 117.207V114.826M65.3115 117.207V119.588M65.3115 105.303V102.922L81.3841 93.5186V95.9038M65.3115 105.303L81.3841 95.9038M81.3841 100.674L65.3115 110.064M81.3841 105.445L65.3115 114.826M81.3841 110.215V112.6L65.3115 121.969V119.588M81.3841 110.215L65.3115 119.588" stroke="black" strokeWidth="0.218675"/>
    <path d="M67.8271 96.8004V99.4245L77.0115 94.1763V91.5522L67.8271 96.8004Z" fill="#474747"/>
    <path d="M113.245 90.8609L106.106 86.9506L105.848 77.9834L112.987 81.8897L113.245 90.8609Z" fill="url(#paint9_linear_1532_43643)"/>
    <path d="M113.245 90.8609L106.106 86.9506L105.848 77.9834L112.987 81.8897L113.245 90.8609Z" fill="#495AD9" fillOpacity="0.3"/>
    <path d="M113.247 90.8598L120.926 86.3705L120.67 77.5033L112.989 81.8886L113.247 90.8598Z" fill="url(#paint10_linear_1532_43643)"/>
    <path d="M108.381 87.1547L106.846 86.2229L106.797 84.5024L108.334 85.5135L108.381 87.1547Z" fill="#CCD2FD"/>
    <path d="M105.847 77.9827L113.128 73.44L120.668 77.5037L112.987 81.889L105.847 77.9827Z" fill="url(#paint11_linear_1532_43643)"/>
    <path d="M118.627 79.1765L120.11 78.3165L120.12 78.6495L118.637 79.5254L118.627 79.1765Z" fill="#CCD2FD"/>
    <path d="M118.644 79.7793L120.127 78.9192L120.136 79.2522L118.654 80.1281L118.644 79.7793Z" fill="#CCD2FD"/>
    <path d="M110.493 80.53L108.72 79.5662L108.836 83.5868L109.715 83.252L110.604 84.3928L110.493 80.53Z" fill="url(#paint12_linear_1532_43643)"/>
    <path d="M115.991 74.984L108.732 79.5654L110.478 80.5301L117.551 75.8239L115.991 74.984Z" fill="url(#paint13_linear_1532_43643)"/>
    <path d="M29.5776 128.754L28.5557 128.078C28.4745 128.025 28.4256 127.934 28.4256 127.837L28.4256 123.047C28.4256 122.823 28.6689 122.684 28.8623 122.797L36.7558 127.42C36.8415 127.47 36.8955 127.561 36.8991 127.66L37.0716 132.5C37.0747 132.585 37.0398 132.668 36.9763 132.725L34.7338 134.753L35.0157 133.759C35.0462 133.651 35.0122 133.536 34.9283 133.462L29.5776 128.754Z" fill="#0B0B0B"/>
    <ellipse cx="2.58641" cy="4.26172" rx="2.58641" ry="4.26172" transform="matrix(-0.847659 0.530541 0.530541 0.847659 31.957 126.683)" fill="#444643"/>
    <ellipse cx="1.79438" cy="2.95665" rx="1.79438" ry="2.95665" transform="matrix(-0.847659 0.530541 0.530541 0.847659 31.916 128.204)" fill="#757675"/>
    <ellipse cx="0.759834" cy="1.252" rx="0.759834" ry="1.252" transform="matrix(-0.847659 0.530541 0.530541 0.847659 31.9385 130.19)" fill="#303231"/>
    <path d="M36.5561 133.117L35.5342 132.441C35.453 132.388 35.4042 132.297 35.4042 132.2L35.4042 127.41C35.4042 127.186 35.6474 127.047 35.8408 127.16L43.7343 131.783C43.82 131.833 43.8741 131.924 43.8776 132.023L44.0502 136.863C44.0532 136.948 44.0183 137.031 43.9548 137.088L41.7123 139.116L41.9943 138.122C42.0248 138.014 41.9907 137.899 41.9068 137.825L36.5561 133.117Z" fill="#0B0B0B"/>
    <ellipse cx="2.58641" cy="4.26172" rx="2.58641" ry="4.26172" transform="matrix(-0.847659 0.530541 0.530541 0.847659 38.9355 131.047)" fill="#444643"/>
    <ellipse cx="1.79438" cy="2.95665" rx="1.79438" ry="2.95665" transform="matrix(-0.847659 0.530541 0.530541 0.847659 38.8945 132.568)" fill="#757675"/>
    <ellipse cx="0.759834" cy="1.252" rx="0.759834" ry="1.252" transform="matrix(-0.847659 0.530541 0.530541 0.847659 38.917 134.554)" fill="#303231"/>
    <path d="M50.0875 122.102L66.0801 112.579V131.189L50.0875 140.712V122.102Z" fill="#495AD9"/>
    <path d="M50.0875 122.102L66.0801 112.579V131.189L50.0875 140.712V122.102Z" fill="black" fillOpacity="0.2"/>
    <path d="M50.0869 140.711V122.101L26.207 108.399V127.008L50.0869 140.711Z" fill="url(#paint14_linear_1532_43643)"/>
    <path d="M66.0801 112.579L50.0875 122.102L26.2076 108.399L42.2002 98.8762L66.0801 112.579Z" fill="#5468FA"/>
    <path d="M52.2592 141.255L51.2373 140.579C51.1561 140.525 51.1073 140.434 51.1073 140.337L51.1073 135.548C51.1073 135.323 51.3505 135.184 51.5439 135.297L59.4374 139.92C59.5231 139.97 59.5772 140.061 59.5807 140.16L59.7533 145C59.7563 145.086 59.7214 145.168 59.658 145.225L57.4154 147.253L57.6974 146.259C57.7279 146.151 57.6939 146.036 57.6099 145.962L52.2592 141.255Z" fill="#0B0B0B"/>
    <ellipse cx="2.58641" cy="4.26172" rx="2.58641" ry="4.26172" transform="matrix(-0.847659 0.530541 0.530541 0.847659 54.6396 139.184)" fill="#444643"/>
    <path d="M61.9375 137.075V146.016L58.3755 144.054C58.3997 143.254 57.5759 141.233 55.5405 140.128C53.505 139.023 52.1239 140.589 51.6877 141.509L50.5973 140.928V123.336L60.4836 129.224L61.9375 137.075Z" fill="#808080"/>
    <path d="M76.0426 120.647L60.4861 129.225L61.94 137.076V146.018L79.0957 136.204V128.062L76.0426 120.647Z" fill="#D1D2D4"/>
    <path d="M65.7932 114.396L76.043 120.647L60.4865 129.225L50.6002 123.337L65.7932 114.396Z" fill="#A8A9AD"/>
    <path d="M62.1564 129.807L75.314 122.465L77.7129 128.281L63.4649 136.568L62.1564 129.807Z" fill="#444643"/>
    <path d="M62.1577 129.807L66.592 127.335L72.1895 131.478L63.4662 136.567L62.1577 129.807Z" fill="#303231"/>
    <path d="M79.3857 137.367V136.059L61.9393 146.018L58.5227 144.201V145.654L61.285 147.181C61.8084 147.355 62.327 147.254 62.5208 147.181L79.3857 137.367Z" fill="#323232"/>
    <path d="M61.94 141.003L79.0957 131.262V136.205L61.94 146.019V141.003Z" fill="#A8A9AD"/>
    <path d="M76.1143 135.55L79.749 133.441V131.406L76.1143 133.441V135.55Z" fill="#FFFDF3"/>
    <path d="M61.795 143.909L65.4297 141.801V139.765L61.795 141.801V143.909Z" fill="#FFFDF3"/>
    <ellipse cx="1.79438" cy="2.95665" rx="1.79438" ry="2.95665" transform="matrix(-0.847659 0.530541 0.530541 0.847659 54.5977 140.703)" fill="#757675"/>
    <ellipse cx="0.759834" cy="1.252" rx="0.759834" ry="1.252" transform="matrix(-0.847659 0.530541 0.530541 0.847659 54.6221 142.689)" fill="#303231"/>
    <path d="M59.7596 130.752L60.7773 136.64L54.9619 133.66V128.135L59.7596 130.752Z" fill="#444643"/>
    <path d="M56.0499 128.717L59.6846 136.059L54.9595 133.66V128.135L56.0499 128.717Z" fill="#303231"/>
    <path opacity="0.12" d="M60.4131 130.171L55.7068 127.542C55.4492 127.358 54.8639 127.044 54.5829 127.265C54.4424 127.288 54.1614 127.528 54.1614 128.303C54.1614 129.078 54.1614 135.637 54.1614 138.82" stroke="black" strokeWidth="0.290775" strokeLinecap="round"/>
    <path d="M66.5171 139.838L75.3857 134.677V135.331L66.5171 140.492V139.838Z" fill="#8B8B8B"/>
    <path d="M66.5171 141.001L75.3857 135.84V136.494L66.5171 141.656V141.001Z" fill="#8B8B8B"/>
    <path d="M26.1705 127.699V126.972L28.0605 128.062V129.225L27.4063 128.862V128.426L26.1705 127.699Z" fill="#3F3F3F"/>
    <path d="M29.1533 128.716L28.0629 129.225V128.062L29.1533 128.716Z" fill="#1A1A1A"/>
    <path d="M147.164 79.4371C147.325 79.5269 147.382 79.7298 147.293 79.8904C147.203 80.0509 147 80.1084 146.839 80.0186L141.629 77.1069C141.468 77.0172 141.411 76.8143 141.501 76.6537C141.59 76.4931 141.793 76.4357 141.954 76.5254L147.164 79.4371Z" fill="#969696"/>
    <path d="M147.675 90.2355L146.855 90.8688L140.377 87.1591L141.268 86.663L147.675 90.2355Z" fill="#D9D9D9"/>
    <path d="M146.855 90.8702L148.357 94.5113L148.29 94.6505L147.812 95.0027L138.058 89.335L140.377 87.1605L146.855 90.8702Z" fill="url(#paint15_linear_1532_43643)"/>
    <path d="M134.989 101.953L147.813 95.0016L138.059 89.3339L125.437 95.8678L134.989 101.953Z" fill="url(#paint16_linear_1532_43643)"/>
    <path d="M147.674 90.236L146.854 90.8694L148.286 94.4425L148.289 94.6497L134.986 101.953L125.434 95.8682L125.448 96.9044L134.999 102.92L149.192 94.9826L149.182 94.2917L147.674 90.236Z" fill="#808080"/>
    <path d="M143.828 76.8838L144.933 76.8683L145.098 88.6128C145.102 88.9181 144.858 89.169 144.553 89.1733C144.248 89.1775 143.997 88.9336 143.993 88.6283L143.828 76.8838Z" fill="#6A6A6A"/>
    <path d="M147.164 79.4371C147.325 79.5269 147.382 79.7298 147.293 79.8904C147.203 80.0509 147 80.1084 146.839 80.0186L144.669 78.8061C144.509 78.7163 144.451 78.5134 144.541 78.3528C144.631 78.1922 144.834 78.1348 144.994 78.2245L147.164 79.4371Z" fill="#969696"/>
    <ellipse cx="144.378" cy="76.878" rx="0.552738" ry="0.276369" transform="rotate(-0.802568 144.378 76.878)" fill="#656565"/>
    <path d="M137.555 93.6969L133.282 91.2597L133.219 85.8384L137.492 88.2732L137.555 93.6969Z" fill="url(#paint17_linear_1532_43643)"/>
    <path d="M137.552 93.6994L142.238 91.0665L142.176 85.7057L137.489 88.2756L137.552 93.6994Z" fill="url(#paint18_linear_1532_43643)"/>
    <path d="M134.655 91.407L133.738 90.828L133.726 89.7878L134.644 90.4148L134.655 91.407Z" fill="#CCD2FD"/>
    <path d="M133.218 85.8396L137.664 83.1703L142.178 85.7044L137.491 88.2743L133.218 85.8396Z" fill="url(#paint19_linear_1532_43643)"/>
    <path d="M140.926 86.6934L141.831 86.1892L141.833 86.3905L140.928 86.9043L140.926 86.6934Z" fill="#CCD2FD"/>
    <path d="M140.931 87.0579L141.836 86.5537L141.838 86.755L140.933 87.2688L140.931 87.0579Z" fill="#CCD2FD"/>
    <path d="M135.999 87.4256L134.938 86.8247L134.966 89.2555L135.5 89.0623L136.026 89.7609L135.999 87.4256Z" fill="url(#paint20_linear_1532_43643)"/>
    <path d="M139.379 84.1321L134.945 86.825L135.989 87.4261L140.313 84.6559L139.379 84.1321Z" fill="url(#paint21_linear_1532_43643)"/>
    <path d="M130.937 97.6341L126.663 95.1969L126.601 89.7756L130.874 92.2104L130.937 97.6341Z" fill="url(#paint22_linear_1532_43643)"/>
    <path d="M130.937 97.6341L126.663 95.1969L126.601 89.7756L130.874 92.2104L130.937 97.6341Z" fill="#495AD9" fillOpacity="0.3"/>
    <path d="M130.936 97.6347L135.622 95.0018L135.56 89.641L130.873 92.2109L130.936 97.6347Z" fill="url(#paint23_linear_1532_43643)"/>
    <path d="M128.037 95.3462L127.119 94.7672L127.107 93.7271L128.026 94.354L128.037 95.3462Z" fill="#CCD2FD"/>
    <path d="M126.6 89.7768L131.046 87.1076L135.56 89.6416L130.873 92.2116L126.6 89.7768Z" fill="url(#paint24_linear_1532_43643)"/>
    <path d="M134.307 90.6309L135.212 90.1267L135.215 90.328L134.31 90.8418L134.307 90.6309Z" fill="#CCD2FD"/>
    <path d="M134.312 90.9949L135.217 90.4907L135.22 90.692L134.315 91.2058L134.312 90.9949Z" fill="#CCD2FD"/>
    <path d="M129.381 91.3631L128.319 90.7622L128.347 93.193L128.882 92.9998L129.408 93.6984L129.381 91.3631Z" fill="url(#paint25_linear_1532_43643)"/>
    <path d="M132.761 88.0699L128.326 90.7627L129.371 91.3638L133.694 88.5937L132.761 88.0699Z" fill="url(#paint26_linear_1532_43643)"/>
    <path d="M138.521 99.6173L134.247 97.1801L134.185 91.7588L138.458 94.1936L138.521 99.6173Z" fill="url(#paint27_linear_1532_43643)"/>
    <path d="M138.521 99.6173L134.247 97.1801L134.185 91.7588L138.458 94.1936L138.521 99.6173Z" fill="#495AD9" fillOpacity="0.3"/>
    <path d="M138.524 99.6178L143.21 96.985L143.148 91.6241L138.461 94.1941L138.524 99.6178Z" fill="url(#paint28_linear_1532_43643)"/>
    <path d="M135.623 97.3274L134.705 96.7484L134.693 95.7083L135.611 96.3352L135.623 97.3274Z" fill="#CCD2FD"/>
    <path d="M134.188 91.758L138.634 89.0888L143.148 91.6228L138.461 94.1928L134.188 91.758Z" fill="url(#paint29_linear_1532_43643)"/>
    <path d="M141.895 92.6119L142.8 92.1076L142.803 92.3089L141.898 92.8228L141.895 92.6119Z" fill="#CCD2FD"/>
    <path d="M141.9 92.9764L142.805 92.4721L142.808 92.6734L141.903 93.1873L141.9 92.9764Z" fill="#CCD2FD"/>
    <path d="M136.969 93.3441L135.907 92.7432L135.935 95.1739L136.47 94.9808L136.996 95.6794L136.969 93.3441Z" fill="url(#paint30_linear_1532_43643)"/>
    <path d="M140.348 90.0506L135.913 92.7434L136.958 93.3445L141.281 90.5744L140.348 90.0506Z" fill="url(#paint31_linear_1532_43643)"/>
    <path d="M114.312 125.435L106.63 121.227L106.353 111.58L114.034 115.782L114.312 125.435Z" fill="url(#paint32_linear_1532_43643)"/>
    <path d="M114.315 125.434L122.576 120.604L122.301 111.064L114.037 115.782L114.315 125.434Z" fill="url(#paint33_linear_1532_43643)"/>
    <path d="M109.08 121.447L107.429 120.445L107.376 118.594L109.029 119.682L109.08 121.447Z" fill="#CCD2FD"/>
    <path d="M106.352 111.58L114.185 106.692L122.297 111.064L114.033 115.783L106.352 111.58Z" fill="url(#paint34_linear_1532_43643)"/>
    <path d="M120.103 112.865L121.699 111.939L121.709 112.298L120.114 113.24L120.103 112.865Z" fill="#CCD2FD"/>
    <path d="M120.117 113.512L121.713 112.587L121.723 112.945L120.128 113.888L120.117 113.512Z" fill="#CCD2FD"/>
    <path d="M111.349 114.32L109.44 113.283L109.565 117.609L110.511 117.249L111.468 118.476L111.349 114.32Z" fill="url(#paint35_linear_1532_43643)"/>
    <path d="M117.264 108.355L109.454 113.284L111.332 114.322L118.942 109.258L117.264 108.355Z" fill="url(#paint36_linear_1532_43643)"/>
    <path d="M104.428 131.786L96.7467 127.579L96.4688 117.931L104.15 122.133L104.428 131.786Z" fill="url(#paint37_linear_1532_43643)"/>
    <path d="M104.428 131.786L96.7467 127.579L96.4688 117.931L104.15 122.133L104.428 131.786Z" fill="#495AD9" fillOpacity="0.3"/>
    <path d="M104.431 131.785L112.692 126.955L112.418 117.414L104.153 122.133L104.431 131.785Z" fill="url(#paint38_linear_1532_43643)"/>
    <path d="M99.1946 127.798L97.5436 126.796L97.4902 124.945L99.1437 126.032L99.1946 127.798Z" fill="#CCD2FD"/>
    <path d="M96.4682 117.93L104.301 113.043L112.414 117.415L104.149 122.133L96.4682 117.93Z" fill="url(#paint39_linear_1532_43643)"/>
    <path d="M110.22 119.215L111.815 118.29L111.825 118.648L110.23 119.591L110.22 119.215Z" fill="#CCD2FD"/>
    <path d="M110.236 119.863L111.832 118.938L111.842 119.296L110.247 120.238L110.236 119.863Z" fill="#CCD2FD"/>
    <path d="M101.465 120.671L99.5566 119.634L99.6813 123.959L100.627 123.599L101.584 124.827L101.465 120.671Z" fill="url(#paint40_linear_1532_43643)"/>
    <path d="M107.38 114.705L99.57 119.634L101.448 120.672L109.058 115.609L107.38 114.705Z" fill="url(#paint41_linear_1532_43643)"/>
    <path d="M118.935 134.016L111.254 129.809L110.976 120.161L118.657 124.364L118.935 134.016Z" fill="url(#paint42_linear_1532_43643)"/>
    <path d="M118.935 134.016L111.254 129.809L110.976 120.161L118.657 124.364L118.935 134.016Z" fill="#495AD9" fillOpacity="0.3"/>
    <path d="M118.938 134.016L127.199 129.185L126.924 119.645L118.66 124.363L118.938 134.016Z" fill="url(#paint43_linear_1532_43643)"/>
    <path d="M113.702 130.029L112.051 129.026L111.998 127.175L113.652 128.263L113.702 130.029Z" fill="#CCD2FD"/>
    <path d="M110.976 120.162L118.809 115.274L126.921 119.646L118.657 124.364L110.976 120.162Z" fill="url(#paint44_linear_1532_43643)"/>
    <path d="M124.727 121.447L126.323 120.521L126.333 120.879L124.738 121.822L124.727 121.447Z" fill="#CCD2FD"/>
    <path d="M124.74 122.094L126.336 121.168L126.346 121.527L124.751 122.469L124.74 122.094Z" fill="#CCD2FD"/>
    <path d="M115.973 122.902L114.064 121.865L114.189 126.191L115.135 125.83L116.092 127.058L115.973 122.902Z" fill="url(#paint45_linear_1532_43643)"/>
    <path d="M121.888 116.936L114.078 121.865L115.956 122.903L123.566 117.84L121.888 116.936Z" fill="url(#paint46_linear_1532_43643)"/>
    <defs>
      <linearGradient id="paint0_linear_1532_43643" x1="49.786" y1="15.5621" x2="57.7676" y2="75.9163" gradientUnits="userSpaceOnUse">
        <stop/>
        <stop offset="1" stopOpacity="0"/>
      </linearGradient>
      <linearGradient id="paint1_linear_1532_43643" x1="69.3574" y1="26.7138" x2="70.4508" y2="146.001" gradientUnits="userSpaceOnUse">
        <stop stopColor="#5468FA"/>
        <stop offset="1"/>
      </linearGradient>
      <linearGradient id="paint2_linear_1532_43643" x1="59.8456" y1="125.117" x2="-37.7926" y2="-7.29039" gradientUnits="userSpaceOnUse">
        <stop stopColor="#8D8D8D"/>
        <stop offset="1" stopColor="#ECECEC"/>
      </linearGradient>
      <linearGradient id="paint3_linear_1532_43643" x1="143.162" y1="48.8012" x2="62.3614" y2="114.294" gradientUnits="userSpaceOnUse">
        <stop stopColor="#D9D9D9"/>
        <stop offset="1" stopColor="#737373"/>
      </linearGradient>
      <linearGradient id="paint4_linear_1532_43643" x1="83.5696" y1="50.9875" x2="123.587" y2="75.9164" gradientUnits="userSpaceOnUse">
        <stop stopColor="#4D4D4D"/>
        <stop offset="1" stopColor="#737373"/>
      </linearGradient>
      <linearGradient id="paint5_linear_1532_43643" x1="-3.68092" y1="120.197" x2="52.8465" y2="90.4568" gradientUnits="userSpaceOnUse">
        <stop/>
        <stop offset="1" stopColor="#7B7B7B"/>
      </linearGradient>
      <linearGradient id="paint6_linear_1532_43643" x1="115.059" y1="100.408" x2="96.2527" y2="68.5905" gradientUnits="userSpaceOnUse">
        <stop stopColor="#F4F4F4"/>
        <stop offset="1"/>
      </linearGradient>
      <linearGradient id="paint7_linear_1532_43643" x1="121.949" y1="71.8706" x2="104.018" y2="69.1371" gradientUnits="userSpaceOnUse">
        <stop stopColor="#464646"/>
        <stop offset="1" stopColor="#212121"/>
      </linearGradient>
      <linearGradient id="paint8_linear_1532_43643" x1="73.3478" y1="93.5186" x2="78.8693" y2="129.272" gradientUnits="userSpaceOnUse">
        <stop stopColor="#D9D9D9"/>
        <stop offset="1" stopColor="#737373"/>
      </linearGradient>
      <linearGradient id="paint9_linear_1532_43643" x1="113.76" y1="92.3505" x2="101.923" y2="76.3179" gradientUnits="userSpaceOnUse">
        <stop stopColor="#495AD9"/>
        <stop offset="1" stopColor="#CCD2FD"/>
      </linearGradient>
      <linearGradient id="paint10_linear_1532_43643" x1="113.718" y1="90.8201" x2="137.286" y2="60.8115" gradientUnits="userSpaceOnUse">
        <stop stopColor="#495AD9"/>
        <stop offset="1" stopColor="#CCD2FD"/>
      </linearGradient>
      <linearGradient id="paint11_linear_1532_43643" x1="115.025" y1="74.465" x2="111.476" y2="81.0431" gradientUnits="userSpaceOnUse">
        <stop stopColor="#495AD9"/>
        <stop offset="1" stopColor="#5468FA"/>
      </linearGradient>
      <linearGradient id="paint12_linear_1532_43643" x1="109.291" y1="69.9844" x2="111.022" y2="84.1355" gradientUnits="userSpaceOnUse">
        <stop stopColor="#495AD9"/>
        <stop offset="1" stopColor="#CCD2FD"/>
      </linearGradient>
      <linearGradient id="paint13_linear_1532_43643" x1="124.046" y1="70.4263" x2="108.78" y2="80.5576" gradientUnits="userSpaceOnUse">
        <stop stopColor="#495AD9"/>
        <stop offset="1" stopColor="#CCD2FD"/>
      </linearGradient>
      <linearGradient id="paint14_linear_1532_43643" x1="-9.36395" y1="61.2638" x2="50.0062" y2="140.643" gradientUnits="userSpaceOnUse">
        <stop stopColor="#CCD2FD"/>
        <stop offset="1" stopColor="#5468FA"/>
      </linearGradient>
      <linearGradient id="paint15_linear_1532_43643" x1="143.106" y1="87.1222" x2="140.649" y2="91.7863" gradientUnits="userSpaceOnUse">
        <stop stopColor="#D9D9D9"/>
        <stop offset="1" stopColor="#434343"/>
      </linearGradient>
      <linearGradient id="paint16_linear_1532_43643" x1="147.394" y1="94.731" x2="117.347" y2="100.403" gradientUnits="userSpaceOnUse">
        <stop stopColor="#A8A9AD"/>
        <stop offset="1" stopColor="#454547"/>
      </linearGradient>
      <linearGradient id="paint17_linear_1532_43643" x1="138.928" y1="100.741" x2="132.732" y2="83.8734" gradientUnits="userSpaceOnUse">
        <stop/>
        <stop offset="1" stopColor="#5468FA"/>
      </linearGradient>
      <linearGradient id="paint18_linear_1532_43643" x1="137.837" y1="93.6803" x2="152.391" y2="75.7922" gradientUnits="userSpaceOnUse">
        <stop stopColor="#495AD9"/>
        <stop offset="1" stopColor="#CCD2FD"/>
      </linearGradient>
      <linearGradient id="paint19_linear_1532_43643" x1="138.8" y1="83.8095" x2="136.587" y2="87.7475" gradientUnits="userSpaceOnUse">
        <stop stopColor="#495AD9"/>
        <stop offset="1" stopColor="#5468FA"/>
      </linearGradient>
      <linearGradient id="paint20_linear_1532_43643" x1="135.383" y1="81.0407" x2="136.281" y2="89.6098" gradientUnits="userSpaceOnUse">
        <stop stopColor="#495AD9"/>
        <stop offset="1" stopColor="#CCD2FD"/>
      </linearGradient>
      <linearGradient id="paint21_linear_1532_43643" x1="144.293" y1="81.4619" x2="134.963" y2="87.425" gradientUnits="userSpaceOnUse">
        <stop stopColor="#495AD9"/>
        <stop offset="1" stopColor="#CCD2FD"/>
      </linearGradient>
      <linearGradient id="paint22_linear_1532_43643" x1="131.232" y1="98.5396" x2="124.246" y2="88.7284" gradientUnits="userSpaceOnUse">
        <stop stopColor="#495AD9"/>
        <stop offset="1" stopColor="#CCD2FD"/>
      </linearGradient>
      <linearGradient id="paint23_linear_1532_43643" x1="131.22" y1="97.6156" x2="145.774" y2="79.7275" gradientUnits="userSpaceOnUse">
        <stop stopColor="#495AD9"/>
        <stop offset="1" stopColor="#CCD2FD"/>
      </linearGradient>
      <linearGradient id="paint24_linear_1532_43643" x1="132.182" y1="87.7467" x2="129.969" y2="91.6848" gradientUnits="userSpaceOnUse">
        <stop stopColor="#495AD9"/>
        <stop offset="1" stopColor="#5468FA"/>
      </linearGradient>
      <linearGradient id="paint25_linear_1532_43643" x1="128.764" y1="84.9782" x2="129.663" y2="93.5473" gradientUnits="userSpaceOnUse">
        <stop stopColor="#495AD9"/>
        <stop offset="1" stopColor="#CCD2FD"/>
      </linearGradient>
      <linearGradient id="paint26_linear_1532_43643" x1="137.675" y1="85.3996" x2="128.345" y2="91.3628" gradientUnits="userSpaceOnUse">
        <stop stopColor="#495AD9"/>
        <stop offset="1" stopColor="#CCD2FD"/>
      </linearGradient>
      <linearGradient id="paint27_linear_1532_43643" x1="138.816" y1="100.523" x2="131.83" y2="90.7115" gradientUnits="userSpaceOnUse">
        <stop stopColor="#495AD9"/>
        <stop offset="1" stopColor="#CCD2FD"/>
      </linearGradient>
      <linearGradient id="paint28_linear_1532_43643" x1="138.808" y1="99.5987" x2="153.362" y2="81.7107" gradientUnits="userSpaceOnUse">
        <stop stopColor="#495AD9"/>
        <stop offset="1" stopColor="#CCD2FD"/>
      </linearGradient>
      <linearGradient id="paint29_linear_1532_43643" x1="139.77" y1="89.7279" x2="137.557" y2="93.666" gradientUnits="userSpaceOnUse">
        <stop stopColor="#495AD9"/>
        <stop offset="1" stopColor="#5468FA"/>
      </linearGradient>
      <linearGradient id="paint30_linear_1532_43643" x1="136.352" y1="86.9591" x2="137.251" y2="95.5283" gradientUnits="userSpaceOnUse">
        <stop stopColor="#495AD9"/>
        <stop offset="1" stopColor="#CCD2FD"/>
      </linearGradient>
      <linearGradient id="paint31_linear_1532_43643" x1="145.262" y1="87.3803" x2="135.932" y2="93.3435" gradientUnits="userSpaceOnUse">
        <stop stopColor="#495AD9"/>
        <stop offset="1" stopColor="#CCD2FD"/>
      </linearGradient>
      <linearGradient id="paint32_linear_1532_43643" x1="116.972" y1="137.931" x2="105.427" y2="108.097" gradientUnits="userSpaceOnUse">
        <stop/>
        <stop offset="1" stopColor="#5468FA"/>
      </linearGradient>
      <linearGradient id="paint33_linear_1532_43643" x1="114.821" y1="125.392" x2="140.179" y2="93.105" gradientUnits="userSpaceOnUse">
        <stop stopColor="#495AD9"/>
        <stop offset="1" stopColor="#CCD2FD"/>
      </linearGradient>
      <linearGradient id="paint34_linear_1532_43643" x1="116.227" y1="107.795" x2="112.408" y2="114.873" gradientUnits="userSpaceOnUse">
        <stop stopColor="#495AD9"/>
        <stop offset="1" stopColor="#5468FA"/>
      </linearGradient>
      <linearGradient id="paint35_linear_1532_43643" x1="110.055" y1="102.974" x2="111.918" y2="118.199" gradientUnits="userSpaceOnUse">
        <stop stopColor="#495AD9"/>
        <stop offset="1" stopColor="#CCD2FD"/>
      </linearGradient>
      <linearGradient id="paint36_linear_1532_43643" x1="125.93" y1="103.451" x2="109.506" y2="114.351" gradientUnits="userSpaceOnUse">
        <stop stopColor="#495AD9"/>
        <stop offset="1" stopColor="#CCD2FD"/>
      </linearGradient>
      <linearGradient id="paint37_linear_1532_43643" x1="104.981" y1="133.388" x2="92.2461" y2="116.139" gradientUnits="userSpaceOnUse">
        <stop stopColor="#495AD9"/>
        <stop offset="1" stopColor="#CCD2FD"/>
      </linearGradient>
      <linearGradient id="paint38_linear_1532_43643" x1="104.938" y1="131.742" x2="130.295" y2="99.4556" gradientUnits="userSpaceOnUse">
        <stop stopColor="#495AD9"/>
        <stop offset="1" stopColor="#CCD2FD"/>
      </linearGradient>
      <linearGradient id="paint39_linear_1532_43643" x1="106.343" y1="114.146" x2="102.524" y2="121.223" gradientUnits="userSpaceOnUse">
        <stop stopColor="#495AD9"/>
        <stop offset="1" stopColor="#5468FA"/>
      </linearGradient>
      <linearGradient id="paint40_linear_1532_43643" x1="100.171" y1="109.324" x2="102.034" y2="124.55" gradientUnits="userSpaceOnUse">
        <stop stopColor="#495AD9"/>
        <stop offset="1" stopColor="#CCD2FD"/>
      </linearGradient>
      <linearGradient id="paint41_linear_1532_43643" x1="116.046" y1="109.801" x2="99.6218" y2="120.702" gradientUnits="userSpaceOnUse">
        <stop stopColor="#495AD9"/>
        <stop offset="1" stopColor="#CCD2FD"/>
      </linearGradient>
      <linearGradient id="paint42_linear_1532_43643" x1="119.488" y1="135.619" x2="106.753" y2="118.369" gradientUnits="userSpaceOnUse">
        <stop stopColor="#495AD9"/>
        <stop offset="1" stopColor="#CCD2FD"/>
      </linearGradient>
      <linearGradient id="paint43_linear_1532_43643" x1="119.444" y1="133.973" x2="144.802" y2="101.686" gradientUnits="userSpaceOnUse">
        <stop stopColor="#495AD9"/>
        <stop offset="1" stopColor="#CCD2FD"/>
      </linearGradient>
      <linearGradient id="paint44_linear_1532_43643" x1="120.851" y1="116.377" x2="117.032" y2="123.454" gradientUnits="userSpaceOnUse">
        <stop stopColor="#495AD9"/>
        <stop offset="1" stopColor="#5468FA"/>
      </linearGradient>
      <linearGradient id="paint45_linear_1532_43643" x1="114.679" y1="111.556" x2="116.542" y2="126.781" gradientUnits="userSpaceOnUse">
        <stop stopColor="#495AD9"/>
        <stop offset="1" stopColor="#CCD2FD"/>
      </linearGradient>
      <linearGradient id="paint46_linear_1532_43643" x1="130.554" y1="112.032" x2="114.13" y2="122.933" gradientUnits="userSpaceOnUse">
        <stop stopColor="#495AD9"/>
        <stop offset="1" stopColor="#CCD2FD"/>
      </linearGradient>
    </defs>
  </svg>
)