import Button from '@src/@core/components/ui/button'
import ComponentSpinner from '@src/@core/components/ui/spinner/Loading-spinner'
import { TENANT_TYPE } from '@src/App.constants'
import Vector from '@src/assets/images/catalog/bundlesku/Vector.svg'
import no_sku_image from '@src/assets/images/omniful/no_sku_image.svg'
import { uploadFileOnS3 } from '@src/redux/authentication'
import { debounceAction, isObjEmpty } from '@src/utility/Utils'
import { clearSellerSkuList, getSellerSkus } from '@src/views/Purchases/store'
import {
  ADD,
  BUNDLE,
  BUNDLE_SKU_DEFAULT_VALUE,
  BUNDLE_SKU_FIELDS_NAME,
  CATALOG_FLOATING_YES_AND_NO_OPTIONS,
  DISCOUNT_OPTIONS,
  MAX_BUNDLE_IMAGE_CAN_UPLOAD,
  MAX_SKU_CAN_ADD_IN_BUNDLE_AND_ASSEMBLE
} from '@src/views/catalog/catalog.constants'
import { cleanupCreateBundleSkuResponse, cleanupSingleSku, cleanupUpdateBundleSku, createBundleSku, getBundleSkus, handleIsRowHighlighted, removeBundleSkuFromIndex, setBundleSkus, updateBundleSkus, updateQtyOfBundleSkuAtIndex } from '@src/views/catalog/store'
import classNames from 'classnames'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  Spinner
} from 'reactstrap'
import DifferentCurrencyWarningModal from '../DifferentCurrencyWarningModal'
import SellerChangeWarningModal from '../SellerChangeWarningModal'
import SkuImageHandler from '../SkuImageHandler'
import SkuQuantityChangeWarningModal from '../SkuQuantityChangeWarningModal'
import { AddSkuSection } from './AddSkuSection'
import { BundleCurrencies } from './BundleCurrencies'
import BundleDetailsSection from './BundleDetailsSection'
import './styles.scss'
let imageGettingUpload = false

const CreateBundle = ({
  setIsModalOpen,
  selectedSeller: initialSeller,
  isEdit,
  setIsEdit,
  setDataTodEdit,
  dataToEdit,
  setSearchParams,
  handleSubmit,
  register,
  watch,
  reset,
  control,
  errors,
  setValue,
  resetRef,
  setFilter,
  filter
}) => {
  const [uploadedImages, setUploadedImages] = useState([{ value: '' }])
  const [currentSelectedImage, setCurrentSelectedImage] = useState(0)
  const [showAddItemsDropdown, setShowAddItemsDropdown] = useState(false)
  const [skuRetailPrice, setSkuRetailPrice] = useState(null)
  const [skuSellingPrice, setSkuSellingPrice] = useState(null)
  const [warningValueChangeModal, setWarningValueChangeModal] = useState(false)
  const [storeIndex, setStoreIndex] = useState()
  const [quantityChangeType, setQuantityChangeType] = useState('')
  const [isSkuDetailsChange, setIsSkuDetailsChange] = useState(false)
  const [skuDetailChangedType, setSkuDetailChangedType] = useState('')
  const [storeDeletedSkuData, setStoreDeletedSkuData] = useState({})
  const [storeSkuDetailsUpdatedData, setStoreSkuDetailsUpdatedData] = useState([])
  const [storeChangedSeller, setStoreChangedSeller] = useState({})
  const [isSellerChangeWarningVisibled, setIsSellerChangeWarningVisibled] = useState(false)
  const [sellerChangedWarningModal, setSellerChangedWarningModal] = useState(false)
  const [isDifferentCurrencyModalOpen, setIsDifferentCurrencyModalOpen] = useState(false)

  const tenantType = useSelector((state) => state.auth.userData.tenant.type)
  const bundleSkus = useSelector((state) => state.catalog.bundleSkus)
  const { is_success, loading: bundleCreationLoading } = useSelector((state) => state.catalog.createBundleSkuResponse)
  const singleSkuDetail = useSelector(state => state.catalog.sku)

  const sellerSkusListFromStore = useSelector(
    (state) => state.purchase.sellerSkuList
  )
  const loading = useSelector((state) => state.purchase.loading)
  const { getSku: singleSkuLoading } = useSelector((state) => state.catalog.loading)
  const updateBundleSkusRes = useSelector((state) => state.catalog.updateBundleSku)

  // for pos
  const isPosEnabled = useSelector(store => store.auth.userData?.user_plan?.is_pos_enabled)

  const { t } = useTranslation()
  const inputRef = useRef(null)
  const imageRef = useRef(null)
  const addImageRef = useRef(null)
  const warningModalRef = useRef(0)
  const retailPriceRef = useRef(null)
  const sellingPriceRef = useRef(null)

  const editSkuClassName = false

  const dispatch = useDispatch()
  const selectedSeller = watch(BUNDLE_SKU_FIELDS_NAME.SELLER)
  const totalSellingPrice = bundleSkus.reduce(
    (acc, elem) => acc + ((elem.quantity || 0) * (elem.selling_price || 0)),
    0
  )
  const totalRetailPrice = bundleSkus.reduce(
    (acc, elem) => acc + ((elem.quantity || 0) * (elem.retail_price || 0)),
    0
  )


  const onModalClosed = () => {
    reset(BUNDLE_SKU_DEFAULT_VALUE)
    setUploadedImages([{ value: '' }])
    dispatch(setBundleSkus([]))
    if (isEdit) {
      setIsEdit(false)
    }
    setDataTodEdit(null)
    dispatch(cleanupSingleSku())
    setSkuSellingPrice(null)
    setSkuRetailPrice(null)
    warningModalRef.current = 0
    setWarningValueChangeModal(false)
    setStoreIndex(null)
    setQuantityChangeType(null)
    setSkuDetailChangedType('')
  }

  resetRef.current = onModalClosed

  useEffect(() => {
    setValue(BUNDLE_SKU_FIELDS_NAME.SELLER, initialSeller)
  }, [initialSeller])


  useEffect(() => {
    if (isEdit && uploadedImages.length > 1) {
      addImageRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' })
    }
  }, [uploadedImages])

  useEffect(() => {
    if (singleSkuDetail?.id && isEdit) {
      reset(singleSkuDetail)
      setValue(
        BUNDLE_SKU_FIELDS_NAME.TAX_PERCENTAGE,
        singleSkuDetail.tax_percentage?.selling_price
      )
      setValue(
        BUNDLE_SKU_FIELDS_NAME.SELLING_PRICE_INCLUDES_TAX,
        Object.values(CATALOG_FLOATING_YES_AND_NO_OPTIONS).find(
          (ele) => ele.value === singleSkuDetail.tax_inclusive?.selling_price
        ) || CATALOG_FLOATING_YES_AND_NO_OPTIONS[1]
      )
      setValue(BUNDLE_SKU_FIELDS_NAME.SELLER, selectedSeller)
      setValue(BUNDLE_SKU_FIELDS_NAME.CURRENCY, { label: singleSkuDetail.currency, value: singleSkuDetail.currency })
      const singleSkuChild = singleSkuDetail.child_skus?.map((ele) => {
        return { ...ele, image: ele.images?.[0]?.default || no_sku_image }
      }) || []
      dispatch(setBundleSkus(singleSkuChild))
      const collectImages = singleSkuDetail.images?.map((ele) => {
        return { ...ele, value: ele.default }
      }) || []
      if (collectImages.length < MAX_BUNDLE_IMAGE_CAN_UPLOAD) {
        collectImages.push({ value: '' })
      }
      setUploadedImages(collectImages)
      setSkuSellingPrice(Number(singleSkuDetail.selling_price).toFixed(2))
      setSkuRetailPrice(Number(singleSkuDetail.retail_price).toFixed(2))
      setValue(BUNDLE_SKU_FIELDS_NAME.LABELS, singleSkuDetail.labels?.length ? singleSkuDetail.labels.map((el) => ({ label: el.name, value: el.id })) : [])
      setValue(BUNDLE_SKU_FIELDS_NAME.TAX_CATEGORY, singleSkuDetail.tax_category ? { label: singleSkuDetail.tax_category.name, value: singleSkuDetail.tax_category.id } : null)
    }
  }, [singleSkuDetail?.id])

  useEffect(() => {
    if (is_success) {
      const data = watch(BUNDLE_SKU_FIELDS_NAME.SELLER)
      setIsModalOpen(false)
      dispatch(cleanupCreateBundleSkuResponse())
      reset(BUNDLE_SKU_DEFAULT_VALUE)
      dispatch(setBundleSkus([]))
      setUploadedImages([{ value: '' }])
      dispatch(getBundleSkus({ type: BUNDLE, seller_id: data.value, isPosEnabled }))
      setSkuSellingPrice(null)
      setSkuRetailPrice(null)
      warningModalRef.current = 0
      setWarningValueChangeModal(false)
      setStoreIndex(null)
      setQuantityChangeType(null)
      setSearchParams({})
      if (data?.value) {
        setFilter({ ...filter, seller_id: data })
      }
      setSkuDetailChangedType('')
      dispatch(handleIsRowHighlighted({ kit: true }))
    } else if (updateBundleSkusRes.is_success) {
      setIsModalOpen(false)
      dispatch(cleanupCreateBundleSkuResponse())
      reset(BUNDLE_SKU_DEFAULT_VALUE)
      dispatch(setBundleSkus([]))
      setUploadedImages([{ value: '' }])
      dispatch(getBundleSkus({ type: BUNDLE, isPosEnabled }))
      if (isEdit) {
        setIsEdit(false)
      }
      setDataTodEdit(null)
      dispatch(cleanupUpdateBundleSku())
      dispatch(cleanupSingleSku())
      setSkuSellingPrice(null)
      setSkuRetailPrice(null)
      warningModalRef.current = 0
      setWarningValueChangeModal(false)
      setStoreIndex(null)
      setQuantityChangeType(null)
      setSearchParams({})
      setSkuDetailChangedType('')
      dispatch(handleIsRowHighlighted({ kit: true }))
    }
  }, [is_success, updateBundleSkusRes.is_success])

  useEffect(() => {
    if (!isEdit && isSellerChangeWarningVisibled) {
      dispatch(setBundleSkus([]))
      setIsSellerChangeWarningVisibled(false)
      setSkuSellingPrice(null)
      setSkuRetailPrice(null)
    }
  }, [watch(BUNDLE_SKU_FIELDS_NAME.SELLER)])

  useEffect(() => {
    if (bundleSkus.length) {
      if ((watch(BUNDLE_SKU_FIELDS_NAME.CURRENCY).value !== bundleSkus[bundleSkus.length - 1].currency) && (skuDetailChangedType === ADD)) {
        setIsDifferentCurrencyModalOpen(true)
        setSkuDetailChangedType('')
      }
    }
  }, [bundleSkus])

  const handleShowAddItemsDropdown = (status) => {
    setShowAddItemsDropdown(status)
  }

  const debouncedDispatch = debounceAction(
    (payload) => (
      dispatch(
        getSellerSkus({
          query: payload,
          type: 'simple',
          seller_id:
            tenantType === TENANT_TYPE.FULFILLMENT_CENTRE
              ? selectedSeller?.value
              : initialSeller.value
        })
      ),
      500
    )
  )

  const submitHandler = async (data) => {
    try {
      imageGettingUpload = true
      const filterExistsImages = uploadedImages.filter((ele) => {
        if (ele.default) {
          return ele
        }
      })
      const storeExistImage = filterExistsImages.map((ele) => {
        return ele.default
      })
      let imagesResolved = await Promise.all(uploadedImages.filter(el => {
        if (!el.default && el.value) {
          return el.value
        }
      }).map(img => {
        return uploadFileOnS3({
          service: 'product',
          usecase: 'bundleImage',
          file: img.file
        })
      }))
      imageGettingUpload = false
      imagesResolved = [...storeExistImage, ...imagesResolved]
      const collectImage = imagesResolved.map(uploadId => ({ default: uploadId }))
      const body = {
        name: data.name,
        description: data.description,
        labels: data[BUNDLE_SKU_FIELDS_NAME.LABELS]?.map((option) => ({
          id: option.value,
          name: option.label
        })) || [],
        child_skus: bundleSkus?.map(({ seller_sku_code, quantity }) => ({
          seller_sku_code,
          quantity
        })),
        sellerId: data.seller?.value || initialSeller.value,
        seller_sku_code: data.seller_sku_code.trim(),
        images: collectImage,
        type: 'bundle',
        creation_type: 'Kit',
        status: 'live',
        currency: data[BUNDLE_SKU_FIELDS_NAME.CURRENCY].value,
        selling_price: Number((skuSellingPrice === 0 || skuSellingPrice > 0) ? skuSellingPrice : totalSellingPrice),
        retail_price: Number((skuRetailPrice === 0 || skuRetailPrice > 0) ? skuRetailPrice : totalRetailPrice),
        tax_inclusive: {
          selling_price:
            data[BUNDLE_SKU_FIELDS_NAME.SELLING_PRICE_INCLUDES_TAX].value
        },
        tax_percentage: {
          selling_price: parseInt(data[BUNDLE_SKU_FIELDS_NAME.TAX_PERCENTAGE])
        },
        tax_category_id: data[BUNDLE_SKU_FIELDS_NAME.TAX_CATEGORY]?.value || null
      }
      isEdit ? dispatch(updateBundleSkus({ seller_id: singleSkuDetail.seller_id, sku_id: dataToEdit.id, body, isPosEnabled })) : dispatch(createBundleSku({ body, isPosEnabled }))
    } catch (error) {
      imageGettingUpload = false
      console.error(error)
    }
  }

  const skuRetailPriceHandler = (e) => {
    const value = parseFloat(e.target.value)
    if (value < 0) {
      return setSkuRetailPrice(null)
    }
    if (value === 0) {
      return setSkuRetailPrice(0)
    }
    setSkuRetailPrice(Number(value).toFixed(2))
  }

  const skuSellingPriceHandler = (e) => {
    const value = parseFloat(e.target.value)
    if (value < 0) {
      return setSkuSellingPrice(null)
    }
    if (value === 0) {
      return setSkuSellingPrice(0)
    }
    setSkuSellingPrice(Number(value).toFixed(2))
  }

  const sellerWarningConfirmHandler = () => {
    setValue(BUNDLE_SKU_FIELDS_NAME.SELLER, storeChangedSeller)
    setStoreChangedSeller({})
    setSellerChangedWarningModal(false)
  }

  const sellerWarningCancelHandler = () => {
    setStoreChangedSeller({})
    setSellerChangedWarningModal(false)
    setIsSellerChangeWarningVisibled(false)
  }


  const confirmModalHandler = () => {
    setWarningValueChangeModal(false)
    setSkuRetailPrice(null)
    setSkuSellingPrice(null)
    warningModalRef.current++
    if (isSkuDetailsChange) {
      setIsSkuDetailsChange(false)
      if (storeSkuDetailsUpdatedData.length) {
        dispatch(setBundleSkus(storeSkuDetailsUpdatedData))
        setStoreSkuDetailsUpdatedData([])
      } else {
        if (!(storeDeletedSkuData.disabled)) dispatch(removeBundleSkuFromIndex(storeDeletedSkuData.idx))
        setStoreDeletedSkuData({})
      }
    } else {
      if (quantityChangeType === 'inc') {
        dispatch(
          updateQtyOfBundleSkuAtIndex({
            idx: storeIndex.idx,
            value: storeIndex.qty + 1
          })
        )
      } else {
        dispatch(
          updateQtyOfBundleSkuAtIndex({
            idx: storeIndex.idx,
            value: storeIndex.qty - 1
          })
        )
      }
    }
  }

  const cancelModalHandler = () => {
    setWarningValueChangeModal(false)
    setStoreSkuDetailsUpdatedData([])
    setIsSkuDetailsChange(false)
    setStoreDeletedSkuData({})
  }

  const currencyChangeWarningModalHandler = () => {
    setIsDifferentCurrencyModalOpen(false)
    warningModalRef.current++
  }

  return (
    <>
      {singleSkuLoading ? <ComponentSpinner /> :
        <form onSubmit={handleSubmit(submitHandler)} className='bundle-sku'>
          <div className='d-flex flex-column gap-30px p-24px'>
            <div className='d-flex gap-2'>
              <div>
                <SkuImageHandler
                  uploadedImages={uploadedImages}
                  currentSelectedImage={currentSelectedImage}
                  imageRef={imageRef}
                  loading={bundleCreationLoading}
                  updateBundleSkusRes={updateBundleSkusRes}
                  imageGettingUpload={imageGettingUpload}
                  addImageRef={addImageRef}
                  setCurrentSelectedImage={setCurrentSelectedImage}
                  setUploadedImages={setUploadedImages}
                />
              </div>

              <BundleDetailsSection
                watch={watch}
                selectedSeller={selectedSeller}
                tenantType={tenantType}
                DISCOUNT_OPTIONS={DISCOUNT_OPTIONS}
                control={control}
                errors={errors}
                register={register}
                Vector={Vector}
                t={t}
                isEdit={isEdit}
                updateBundleSkusRes={updateBundleSkusRes}
                singleSkuLoading={singleSkuLoading}
                bundleCreationLoading={bundleCreationLoading}
                imageGettingUpload={imageGettingUpload}
                setIsSellerChangeWarningVisibled={setIsSellerChangeWarningVisibled}
                isSellerChangeWarningVisibled={isSellerChangeWarningVisibled}
                setValue={setValue}
                bundleSkus={bundleSkus}
                setSellerChangedWarningModal={setSellerChangedWarningModal}
                setStoreChangedSeller={setStoreChangedSeller}
                isPosEnabled={isPosEnabled}
              />
            </div>

            <div>
              <div className='txt-h3-sb mb-24px text-dark'> {t(`Add SKUs (Maximum ${MAX_SKU_CAN_ADD_IN_BUNDLE_AND_ASSEMBLE})`)}</div>
              <div>
                <div className="items-table">
                  <AddSkuSection
                    t={t}
                    bundleSkus={bundleSkus}
                    setSkuRetailPrice={setSkuRetailPrice}
                    skuRetailPrice={skuRetailPrice}
                    skuSellingPrice={skuSellingPrice}
                    setSkuSellingPrice={setSkuSellingPrice}
                    setWarningValueChangeModal={setWarningValueChangeModal}
                    warningModalRef={warningModalRef}
                    warningValueChangeModal={warningValueChangeModal}
                    setStoreIndex={setStoreIndex}
                    storeIndex={storeIndex}
                    quantityChangeType={quantityChangeType}
                    setQuantityChangeType={setQuantityChangeType}
                    storeSkuDetailsUpdatedData={storeSkuDetailsUpdatedData}
                    setStoreSkuDetailsUpdatedData={setStoreSkuDetailsUpdatedData}
                    isSkuDetailsChange={isSkuDetailsChange}
                    setIsSkuDetailsChange={setIsSkuDetailsChange}
                    setSkuDetailChangedType={setSkuDetailChangedType}
                    skuDetailChangedType={skuDetailChangedType}
                    bundleCreationLoading={bundleCreationLoading}
                    imageGettingUpload={imageGettingUpload}
                    setStoreDeletedSkuData={setStoreDeletedSkuData}
                    MAX_SKU_CAN_ADD_IN_BUNDLE_AND_ASSEMBLE={
                      MAX_SKU_CAN_ADD_IN_BUNDLE_AND_ASSEMBLE
                    }
                    inputRef={inputRef}
                    debouncedDispatch={debouncedDispatch}
                    handleShowAddItemsDropdown={handleShowAddItemsDropdown}
                    dispatch={dispatch}
                    clearSellerSkuList={clearSellerSkuList}
                    showAddItemsDropdown={showAddItemsDropdown}
                    editSkuClassName={editSkuClassName}
                    sellerSkusListFromStore={sellerSkusListFromStore}
                    classNames={classNames}
                    no_sku_image={no_sku_image}
                    setBundleSkus={setBundleSkus}
                    updateBundleSkusRes={updateBundleSkusRes}
                    loading={loading}
                  />
                </div>
              </div>

              <hr className='seperator-hr' />
            </div>

            <BundleCurrencies
              t={t}
              totalRetailPrice={totalRetailPrice}
              bundleCreationLoading={bundleCreationLoading}
              imageGettingUpload={imageGettingUpload}
              retailPriceRef={retailPriceRef}
              skuRetailPrice={skuRetailPrice}
              skuRetailPriceHandler={skuRetailPriceHandler}
              watch={watch}
              control={control}
              isEdit={isEdit}
              singleSkuLoading={singleSkuLoading}
              totalSellingPrice={totalSellingPrice}
              sellingPriceRef={sellingPriceRef}
              skuSellingPrice={skuSellingPrice}
              skuSellingPriceHandler={skuSellingPriceHandler}
              updateBundleSkusRes={updateBundleSkusRes} />

            <div className='d-flex justify-content-end'>
              <Button disabled={!bundleSkus?.length || !isObjEmpty(errors) || bundleCreationLoading || updateBundleSkusRes.loading || imageGettingUpload}>
                {(bundleCreationLoading || updateBundleSkusRes.loading || imageGettingUpload) ? 
                  <div className='flex-center-start gap-8px'> <Spinner size="sm"/> <span>{isEdit ? t('Save') : t('Create Kit')}</span></div>
                  : isEdit ? t('Save') : t('Create Kit')}</Button>
            </div>
          </div>
        </form>}
     

      <SellerChangeWarningModal isOpen={sellerChangedWarningModal} toggle={() => setSellerChangedWarningModal((p) => !p)} confirmHandler={sellerWarningConfirmHandler} cancelHandler={sellerWarningCancelHandler} />
      <DifferentCurrencyWarningModal isOpen={isDifferentCurrencyModalOpen} toggle={currencyChangeWarningModalHandler} />
      <SkuQuantityChangeWarningModal isOpen={warningValueChangeModal} toggle={cancelModalHandler} isSkuDetailsChange={isSkuDetailsChange} skuDetailChangedType={skuDetailChangedType} confirmModalHandler={confirmModalHandler} />
    </>
  )
}

export default CreateBundle