import { useTranslation } from 'react-i18next'
import { components } from 'react-select'

const CustomCourierPartnerOption = (props) => {
  const { data: { label, value } } = props
  const {t} = useTranslation()
  const { Option } = components
  return (
    <Option key={value} {...props}>
      <div className="flex-start-between overflow-hidden gap-24px" title={label}>
        <div className='w-50 text-truncate'>{label}</div>
        <div className='d-flex gap-4px text-dark-5 txt-asst-rg'>
          <span>{t('Shipping Cost')}:</span>
          <span>{value.shipping_cost}</span>
        </div>
      </div>
    </Option>
  )
}
export default CustomCourierPartnerOption