import ComponentSpinner from '@src/@core/components/ui/spinner/Loading-spinner'
import {
  // getAwbsOfPo,
  getShipmentsOfSinglePo,
  getSinglePurchaseOrderList
} from '@src/views/Purchases/store'
// import { getStoRequestDetail } from "@src/views/sales/store/store"
// import { ExpandableCustomTable } from "@src/views/tables/CustomizedTable/ExpandableCustomTable"
import { ExpandableCustomTable as CustomTable } from '@src/@core/components/ui/globalTable/ExpandableCustomTable'
import SKUDetailsCol from '@src/@core/components/ui/sku-details'
import { getStoItems } from '@src/views/inventory/store'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

const PoStoDetail = ({ grnDetails, setTabs }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const hub_id = useSelector((state) => state.auth.selectedGlobalHubId)
  // const loading = useSelector(state => state.purchase.loading)
  const stoDetail = useSelector((store) => store.inventory.stoDetail)
  // const [awbNumber, setAwbNumber] = useState({
  //   value: grnDetails?.awb,
  //   label: grnDetails?.awb
  // })
  const shipmentsOfPoData = useSelector(
    (store) => store.purchase.shipmentsOfSinglePo
  )
  const stoRequestMetaData = useSelector(
    (store) => store.inventory.stoRequestMetaData
  )
  const singlepurchaseOrderData = useSelector(
    (state) => state.purchase.singlepurchaseOrderData
  )
  const loading = useSelector(
    (state) => state.purchase.loading
  )

  const [tableData, setTableData] = useState(null)
  const [tableMeta, setTableMeta] = useState({})

  useEffect(() => {
    const poData = singlepurchaseOrderData?.data?.purchase_order_items
    const stoData = stoDetail
    const poShimpentData = shipmentsOfPoData?.data?.purchase_order_items
    if (poData?.length > 0) {
      const data = poData.map((item) => ({
        id: item.id,
        name: item.sku.name,
        images: item.sku.images,
        seller_sku_code: item.sku.seller_sku_code,
        quantity: item.quantity
      }))
      setTableMeta(singlepurchaseOrderData.meta)
      setTabs(prev => prev.map(tab => {
        if (tab.id === 2) {
          return ({
            ...tab,
            tabCount: singlepurchaseOrderData.meta.total
          })
        }
        return tab
      }))
      setTableData(data)
    } else if (stoData?.length > 0) {
      const data = stoData.map((item) => ({
        id: item.id,
        name: item.sku.name,
        images: item.sku.images,
        seller_sku_code: item.sku.seller_sku_code,
        quantity: item.quantity,
        packed_quantity: item.packed_quantity
      }))
      setTableData(data)
      setTableMeta(stoRequestMetaData)
      setTabs(prev => prev.map(tab => {
        if (tab.id === 2) {
          return ({
            ...tab,
            tabCount: stoRequestMetaData.total
          })
        }
        return tab
      }))
    } else if (poShimpentData?.length > 0) {
      const data = poShimpentData.map((item) => ({
        id: item.id,
        name: item.sku.name,
        images: item.sku.images,
        seller_sku_code: item.sku.seller_sku_code,
        quantity: item.ordered_quantity
      }))
      setTableMeta(shipmentsOfPoData.meta)
      setTableData(data)
      setTabs(prev => prev.map(tab => {
        if (tab.id === 2) {
          return ({
            ...tab,
            tabCount: shipmentsOfPoData.meta.total
          })
        }
        return tab
      }))
    }
  }, [singlepurchaseOrderData, stoDetail, shipmentsOfPoData])

  useEffect(() => {
    if (
      grnDetails?.is_purchase_order &&
      !singlepurchaseOrderData &&
      !shipmentsOfPoData
    ) {
      if (grnDetails?.awb) {
        dispatch(
          getShipmentsOfSinglePo({
            po_id: grnDetails.purchase_order_id,
            awb: grnDetails.awb
          })
        )
      } else {
        const pathParams = { seller_id: grnDetails.purchase_order_id, hub_id }
        dispatch(getSinglePurchaseOrderList({ pathParams }))
      }
    } else if (grnDetails?.is_sto && !stoDetail) {
      dispatch(getStoItems({ sto_id: grnDetails.sto_wms_id, hub_id }))
    }
  }, [])

  const TableColumns = [
    {
      id: 1,
      name: t('SKU Details'),
      minWidth: '60%',
      maxWidth: '300px',
      reorder: true,
      cell: (row) => {
        return (
          <SKUDetailsCol
            skuDetailData={{
              isValidSku: true,
              id: row.id,
              image: {
                src: row.images?.[0]?.url,
                alt: row.name
              },
              details: {
                name: row.name,
                skuCode: row.seller_sku_code,
                columnKey: 'grn_sku_details'
              }
            }}
          /> 
        )
      } 
    },
    {
      id: 2,
      name: <div className="w-100 text-end">{t('Ordered Qty')}</div>,
      reorder: true,
      cell: (row) => <div className="w-100 text-end">{row.quantity}</div>
    },
    {
      id: 3,
      name: <div className="w-100 text-end">{t('Shipped Qty')}</div>,
      reorder: true,
      omit: !stoDetail,
      cell: (row) => <div className="w-100 text-end">{row.packed_quantity}</div>
    }
  ]

  // const handleAwbChange = (e) => {
  //   dispatch(
  //     getShipmentsOfSinglePo({
  //       po_id: grnDetails.purchase_order_id,
  //       awb: e.value
  //     }))
  //     setAwbNumber({value: e.value, label: e.value})
  // }

  const handlePagination = (page) => {
    setTableMeta((prev) => ({ ...prev, page: page.selected }))
    if (grnDetails?.is_purchase_order) {
      if (grnDetails?.awb) {
        dispatch(
          getShipmentsOfSinglePo({
            po_id: grnDetails.purchase_order_id,
            awb: grnDetails.awb,
            page: page.selected,
            per_page: page.per_page
          })
        )
      } else {
        const pathParams = {
          seller_id: grnDetails.purchase_order_id,
          hub_id,
          param: { page: page.selected, per_page: page.per_page }
        }
        dispatch(getSinglePurchaseOrderList({ pathParams }))
      }
    } else if (grnDetails?.is_sto) {
      const params = { page: page.selected, per_page: page.per_page }
      dispatch(getStoItems({ sto_id: grnDetails.sto_wms_id, hub_id, ...params }))
    }
  }

  return (
    <div className="px-16px mt-24px">
      <div className="po-sto-detail">
        {!tableData ? (
          <ComponentSpinner />
        ) : (
          <>
            {/* {grnDetails.awb && (
              <div className="w-50 my-2">
                <FloatingDropDown
                  name="awb_number"
                  title="Select AWB"
                  isAsync
                  value={awbNumber}
                  defaultValue={awbNumber}
                  onChangeFunc={(e) => handleAwbChange(e)}
                  loadOptions={getAwbsOfPo}
                  additional={{
                    page: 1,
                    hub_id,
                    po_id: grnDetails.purchase_order_id
                  }}
                />{" "}
              </div>
            )} */}
            {/* <ExpandableCustomTable
              data={tableData}
              columns={TableColumns}
              meta={tableMeta}
              handlePagination={handlePagination}
              useReactPaginate={false}
            /> */}
            <CustomTable 
              data={tableData || []}
              columns={TableColumns}
              meta={tableMeta}
              handlePagination={handlePagination}
              useReactPaginate={false}
              showColumnsTableHeader={false}
              loading={loading.getSinglePurchaseOrderList || loading.getShipmentsOfSinglePo}
            />
          </>
        )}
      </div>
    </div>
  )
}

export default PoStoDetail
