const SVG = ({ height, fillColor, width, iswhite }) => {
  const color = iswhite ? 'white' : fillColor
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M3.06122 3.81633C2.92597 3.81633 2.81633 3.92597 2.81633 4.06122V14.5102C2.81633 14.6455 2.92597 14.7551 3.06122 14.7551H8.28571C8.51114 14.7551 8.69388 14.9378 8.69388 15.1633C8.69388 15.3887 8.51114 15.5714 8.28571 15.5714H3.06122C2.47513 15.5714 2 15.0963 2 14.5102V4.06122C2 3.47513 2.47513 3 3.06122 3H13.5102C14.0963 3 14.5714 3.47513 14.5714 4.06122V11.898C14.5714 12.1234 14.3887 12.3061 14.1633 12.3061C13.9378 12.3061 13.7551 12.1234 13.7551 11.898V4.06122C13.7551 3.92597 13.6455 3.81633 13.5102 3.81633H10V8.63265C10 8.79549 9.90321 8.94275 9.75373 9.00734C9.60425 9.07192 9.43068 9.04148 9.3121 8.92988L8.37193 8.04501C8.27763 7.95626 8.13054 7.95626 8.03624 8.04501L7.09607 8.92988C6.97749 9.04148 6.80392 9.07192 6.65444 9.00734C6.50495 8.94275 6.40816 8.79549 6.40816 8.63265V3.81633H3.06122ZM7.22449 3.81633V7.68799L7.47676 7.45056C7.88538 7.06598 8.52278 7.06597 8.93141 7.45056L9.18367 7.68799V3.81633H7.22449Z" fill={color} />
      <path fillRule="evenodd" clipRule="evenodd" d="M14.8648 10.3077C14.8648 10.1041 15.0299 9.93896 15.2336 9.93896H19.3455C19.6306 9.93896 19.8617 10.1701 19.8617 10.4552V11.9195C19.8617 12.1413 19.722 12.3303 19.5257 12.4035C20.6712 13.68 20.7943 14.8312 20.5736 15.51C21.8423 16.1892 22.0552 17.4991 21.9894 18.1243C21.9696 18.312 21.8114 18.4545 21.6226 18.4545H9.30755C9.10389 18.4545 8.93878 18.2894 8.93878 18.0857V16.1552C8.93878 15.3813 9.56618 14.7539 10.3401 14.7539H10.4203V13.3634C10.4203 13.1597 10.5854 12.9946 10.7891 12.9946H13.9836C14.6731 12.9946 15.232 13.5535 15.232 14.243C15.232 14.821 14.8392 15.3072 14.3061 15.4494V16.0502H16.552C16.812 15.9365 17.0048 15.7125 17.1421 15.4372C17.2865 15.1477 17.3505 14.8375 17.3649 14.6467V12.3027C17.1917 12.2107 17.0341 12.0953 16.9082 11.9464C16.7208 11.725 16.6241 11.4539 16.6241 11.1411C16.6241 11.0352 16.5877 10.9176 16.5277 10.8036C16.5025 10.7557 16.4754 10.7128 16.4499 10.6765H15.2336C15.0299 10.6765 14.8648 10.5114 14.8648 10.3077ZM17.2763 10.6765C17.3263 10.815 17.3617 10.9725 17.3617 11.1411C17.3617 11.2912 17.4039 11.3905 17.4712 11.47C17.5374 11.5483 17.6439 11.6257 17.8079 11.6983H19.1242V10.6765H17.2763ZM18.1024 12.4358V14.6597C18.1024 14.6679 18.1022 14.6761 18.1016 14.6842C18.0834 14.9575 17.9989 15.372 17.8021 15.7665C17.6049 16.1618 17.2751 16.5741 16.7486 16.7656C16.7082 16.7803 16.6655 16.7878 16.6225 16.7878H13.9373C13.7336 16.7878 13.5685 16.6227 13.5685 16.419V15.4914H10.3401C9.97351 15.4914 9.67632 15.7886 9.67632 16.1552V17.7169H17.4883L19.8392 15.3661C19.9916 15.0276 20.1153 13.8917 18.5186 12.4358H18.1024ZM20.1339 16.1144L18.5314 17.7169H21.2539C21.2036 17.2137 20.936 16.5018 20.1339 16.1144ZM14.4945 14.243C14.4945 13.9609 14.2657 13.7321 13.9836 13.7321H11.1578V14.7539H13.9836C14.2657 14.7539 14.4945 14.5252 14.4945 14.243Z" fill={color} />
      <path fillRule="evenodd" clipRule="evenodd" d="M10.0483 17.7169C10.252 17.7169 10.4171 17.882 10.4171 18.0857C10.4171 18.7003 10.9153 19.1984 11.5298 19.1984C12.1444 19.1984 12.6426 18.7003 12.6426 18.0857C12.6426 17.882 12.8077 17.7169 13.0113 17.7169C13.215 17.7169 13.3801 17.882 13.3801 18.0857C13.3801 19.1076 12.5517 19.936 11.5298 19.936C10.5079 19.936 9.67954 19.1076 9.67954 18.0857C9.67954 17.882 9.84464 17.7169 10.0483 17.7169Z" fill={color} />
      <path fillRule="evenodd" clipRule="evenodd" d="M17.7337 17.8095C17.9373 17.8095 18.1024 17.9746 18.1024 18.1783C18.1024 18.7928 18.6006 19.291 19.2152 19.291C19.8297 19.291 20.3279 18.7928 20.3279 18.1783C20.3279 17.9746 20.493 17.8095 20.6967 17.8095C20.9004 17.8095 21.0655 17.9746 21.0655 18.1783C21.0655 19.2002 20.2371 20.0286 19.2152 20.0286C18.1933 20.0286 17.3649 19.2002 17.3649 18.1783C17.3649 17.9746 17.53 17.8095 17.7337 17.8095Z" fill={color} />
    </svg>

  )
}
export default SVG