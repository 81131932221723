import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import './styles.scss'

const Button = ({ text, isSelected, icon, onChange, btn }) => {
  const {t} = useTranslation()
  return <button onClick={() => onChange(btn)} className={classNames('__switch-button', {
    '__switch-button--selected': isSelected
  })}>
    {icon ? icon : null}
    {t(text)}
  </button>
}

const SwitchButton = ({buttons = [], selectedButton, onChange}) => {
  const {t} = useTranslation()
  const Buttons = buttons.map(btn => (<Button key={btn.id} text={t(btn.text)} isSelected={+selectedButton?.id === +btn.id} icon={btn.icon(+selectedButton?.id === +btn.id ? 'white' : 'black')} onChange={onChange} btn={btn} />))
  return (
    <div className='__switch'>
      {Buttons}
    </div>
  )
}

export default SwitchButton