import Button from '@src/@core/components/ui/button'
import CustomToast from '@src/@core/components/ui/custom-toast/CustomToast'
import QuantityButton from '@src/@core/components/ui/quantity-button'
import SideSheet from '@src/@core/components/ui/sideSheet'
import SidesheetFooter from '@src/@core/components/ui/sidesheet-footer'
import ComponentSpinner from '@src/@core/components/ui/spinner/Loading-spinner'
import { KEYBOARD_KEYS } from '@src/App.constants'
import { useApi } from '@src/configs/react-query/useApi'
import { axiosInstance } from '@src/network/AxiosInstance'
import { uploadMultipleFilesOnS3 } from '@src/redux/authentication'
import { setBinDisabledGrnTableData, setScannedSkuDetails } from '@src/views/inventory/store'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { ModalBody, Spinner } from 'reactstrap'
import { BATCH_STATUS } from '../../constant'
import OverReceiveItemModal from '../qc-component/overReceiveItemModal'
import ImageUploaderBinDisabled from '../serialized-mark-as-fail/imageUploader'

const NonSerializeMarkAsFail = (props) => {
  const { isOpen, toggle, skuDetails, grnResponse, selectedGlobalHubId, scannedBarcode, gtinNonSerialised } = props
  const [reasonsForFail, setReasonsForFail] = useState([])
  const tableData = useSelector(store => store.inventory.binDisabledGrn.tableData)
  const [passQty, setPassQty] = useState(0)
  const [failQty, setFailQty] = useState(0)
  const [isOverReceiveModalOpen, setIsOverReceiveModalOpen] = useState(false)
  const dispatch = useDispatch()
  const [requestBody, setRequestBody] = useState({})
  const returnGrnResponse = useSelector((state) => state.returns.createReturnGrnResponse?.data)
  const [imagesUploading, setImagesUploading] = useState(false)
  const { t } = useTranslation()
  const [allImages, setAllmages] = useState([])
  const imagesToUpload = reasonsForFail?.flatMap((reason) => (reason?.quantity > 0 ? (reason?.images || []) : []))
  const isSaveBtnDisabled = skuDetails?.fail_qty === failQty && allImages?.length === imagesToUpload?.length

  const { isFetching: failReasonsLoading } = useApi({
    apiKey: ['get-non-serialize-skus-reasons'],
    apiFn: () => axiosInstance.get('/api/v1/wms/reasons?reason_type=grn_items'),
    onSuccess: ({ data }) => {
      let currentSelectedSku
      if (skuDetails?.batch_status === BATCH_STATUS.BATCHED) {
        currentSelectedSku = tableData?.find(item => item.seller_sku_id === skuDetails?.seller_sku_id && item?.batch?.id === skuDetails?.batch?.id)
      } else {
        currentSelectedSku = tableData?.find(item => item.seller_sku_id === skuDetails?.seller_sku_id)
      }
      const previousReasons = currentSelectedSku?.reasons || []

      const mapAllImages = previousReasons?.flatMap((reason) => (reason.quantity > 0 ? (reason?.images || []) : []))
      setAllmages(mapAllImages)

      setPassQty(currentSelectedSku?.pass_qty || 0)
      setFailQty(currentSelectedSku?.fail_qty || 0)

      if (previousReasons.length > 0) {
        const mappedReasons = data?.map((item) => {
          const previousReason = previousReasons.find((reason) => reason?.id === item?.id)
          const mappedImages = previousReason?.images?.map((image) => {
            image.isUploaded = true
            return image
          })

          return previousReason ? {
            ...item,
            quantity: previousReason?.quantity,
            images: previousReason?.quantity > 0 ? (mappedImages || []) : [],
            upload_ids: previousReason?.upload_ids
          } : item
        })
        setReasonsForFail(mappedReasons)
      } else {
        const mappedReasons = data?.map((item) => {
          return {
            ...item,
            quantity: 0,
            images: [],
            upload_ids: []
          }
        })
        setReasonsForFail(mappedReasons)
      }
    },
    enabled: isOpen
  })

  const { mutate: markASFailNonSerializeSku, isPending:loadingState } = useApi({
    apiKey: ['mark-as-fail-non-serialize-sku'],
    isMutation: true,
    apiFn: (body) => {

      const skuInTable = tableData.find(item => item.seller_sku_id === skuDetails?.seller_sku_id)

      const bodyToSend = {
        ...body,
        over_receive: !!skuInTable?.allow_over_receive,
        return_order_id: returnGrnResponse?.return_order_detail?.id,
        non_serialised_gtin_barcode: gtinNonSerialised ? scannedBarcode : undefined
      }
      const grn_id = grnResponse?.id || returnGrnResponse?.id
      return axiosInstance.post(`/api/v1/wms/hubs/${selectedGlobalHubId}/grns/${grn_id}`, bodyToSend)
    },
    onSuccess: (data) => {
      if (data?.data?.is_over_receive) {
        setIsOverReceiveModalOpen(true)
      } else {
        const reasonsToAdd = reasonsForFail?.map((reason) => ({
          id: reason.id,
          quantity: reason.quantity,
          images: reason.images,
          upload_ids: reason.upload_ids
        }))

        dispatch(setScannedSkuDetails({
          data: {
            ...skuDetails,
            pass_qty: passQty || 0,
            fail_qty: failQty,
            reasons: reasonsToAdd
          }
        }))

        if (skuDetails?.batch_status === BATCH_STATUS.BATCHED) {
          const updateTableData = tableData.map((item) => {
            return item.seller_sku_id === skuDetails.seller_sku_id && item.batch?.id === skuDetails?.batch?.id ? {
              ...item,
              pass_qty: passQty || 0,
              fail_qty: failQty,
              reasons: reasonsToAdd
            } : item
          })
          dispatch(setBinDisabledGrnTableData(updateTableData))
        } else {
          const updateTableData = tableData.map((item) => {
            return item.seller_sku_id === skuDetails.seller_sku_id ? {
              ...item,
              pass_qty: passQty || 0,
              fail_qty: failQty,
              reasons: reasonsToAdd
            } : item
          })
          dispatch(setBinDisabledGrnTableData(updateTableData))
        }
        CustomToast(`${failQty - skuDetails?.fail_qty} ${failQty - skuDetails?.fail_qty > 1 ? 'Items' : 'Item'} failed successfully`, { my_type: 'success', duration:2000 })
        setFailQty(0)
        setPassQty(0)
        setReasonsForFail([])
        toggle()
      }
    }
  })

  const { mutate: markASFailNonSerializeSkuModal, isPending:loadingStateModal } = useApi({
    apiKey: ['mark-as-fail-non-serialize-sku-modal'],
    isMutation: true,
    apiFn: () => {
      const body = {
        ...requestBody,
        over_receive: true,
        return_order_id: returnGrnResponse?.return_order_detail?.id,
        non_serialised_gtin_barcode: gtinNonSerialised ? scannedBarcode : undefined
      }
      const grn_id = grnResponse?.id || returnGrnResponse?.id
      return axiosInstance.post(`/api/v1/wms/hubs/${selectedGlobalHubId}/grns/${grn_id}`, body)
    },
    onSuccess: () => {
      const reasonsToAdd = reasonsForFail?.map((reason) => ({
        id: reason.id,
        quantity: reason.quantity,
        images: reason.images,
        upload_ids: reason.upload_ids
      }))

      dispatch(setScannedSkuDetails({
        data: {
          ...skuDetails,
          pass_qty: passQty || 0,
          fail_qty: failQty,
          reasons: reasonsToAdd
        }
      }))

      if (skuDetails?.batch_status === BATCH_STATUS.BATCHED) {
        const updateTableData = tableData.map((item) => {
          return item.seller_sku_id === skuDetails.seller_sku_id && item.batch?.id === skuDetails?.batch?.id ? {
            ...item,
            pass_qty: passQty || 0,
            fail_qty: failQty,
            reasons: reasonsToAdd,
            allow_over_receive: true
          } : item
        })
        dispatch(setBinDisabledGrnTableData(updateTableData))
      } else {
        const updateTableData = tableData.map((item) => {
          return item.seller_sku_id === skuDetails.seller_sku_id ? {
            ...item,
            pass_qty: passQty || 0,
            fail_qty: failQty,
            reasons: reasonsToAdd,
            allow_over_receive: true
          } : item
        })
        dispatch(setBinDisabledGrnTableData(updateTableData))
      }
      CustomToast(`${failQty - skuDetails?.fail_qty} ${failQty - skuDetails?.fail_qty > 1 ? 'Items' : 'Item'} failed successfully`, { my_type: 'success', duration:2000 })
      setIsOverReceiveModalOpen(false)
      setFailQty(0)
      setPassQty(0)
      setReasonsForFail([])
      toggle()
    }
  })

  const decreaseQuantity = (item) => {
    if (failQty <= skuDetails?.pass_qty + skuDetails?.fail_qty) {
      setPassQty(passQty + 1)
    }

    setFailQty(failQty - 1)
    const updatedReasonsForFail = reasonsForFail.map((reason) => {
      return reason.id === item?.id ? {
        ...reason,
        quantity: reason.quantity - 1
      } : reason
    })
    setReasonsForFail(updatedReasonsForFail)
  }

  const increaseQuantity = (item) => {
    if (passQty > 0) {
      setPassQty(passQty - 1)
    }

    setFailQty(failQty + 1)
    const updatedReasonsForFail = reasonsForFail.map((reason) => {
      return reason.id === item?.id ? {
        ...reason,
        quantity: reason.quantity + 1
      } : reason
    })
    setReasonsForFail(updatedReasonsForFail)
  }

  const handleSave = async () => {
    let uploadIds

    const isImagesToUpload = reasonsForFail?.some((reason) => reason.images?.length > 0)

    if (isImagesToUpload) {
      setImagesUploading(true)
      const imagesToUpload = reasonsForFail
        .flatMap((reason) => (reason.quantity > 0 ? (reason.images || []) : []))
        .filter((item) => !item.isUploaded)
        .map((item) => ({
          service: 'serialised_qc_fail_reason_images',
          usecase: 'reason_images',
          file: item
        }))

      uploadIds = await uploadMultipleFilesOnS3(imagesToUpload)
    }

    let updatedReasonsForFail
    if (isImagesToUpload && !uploadIds) {
      setImagesUploading(false)
      return
    } else {
      let uploadIndex = 0
      updatedReasonsForFail = reasonsForFail.map((reason) => {
        if (reason.images && reason.images?.length > 0 && reason.quantity > 0) {
          const reasonUploadIds = reason.images.map(() => uploadIds[uploadIndex++])
          return {
            ...reason,
            upload_ids: reasonUploadIds?.filter((item) => item !== null)
          }
        } else {
          return {
            ...reason,
            upload_ids: []
          }
        }
      })

      setReasonsForFail(updatedReasonsForFail)
    }

    const reasons = updatedReasonsForFail?.map((reason) => ({
      id: reason.id,
      quantity: reason.quantity,
      images: [],
      upload_ids: reason.upload_ids
    }))

    const reasonsToSend = reasons?.filter((reason) => reason?.quantity > 0)

    const body = {
      batch_id: skuDetails?.batch_status === BATCH_STATUS.BATCHED ? skuDetails?.batch?.id : null,
      seller_sku_code: skuDetails?.seller_sku_code,
      reasons:reasonsToSend,
      pass_quantity:passQty
    }

    setRequestBody(body)

    markASFailNonSerializeSku(body)
    setImagesUploading(false)
  }

  const handleSetImages = (item, file, index) => {
    if (file) {
      const mappedReasons = reasonsForFail?.map((reason) => {
        return reason.id === item?.id ? {
          ...reason,
          images: Array.isArray(reason.images) && reason.images?.length ? [...reason.images, file] : [file]
        } : reason
      })
      setReasonsForFail(mappedReasons)
    }
    
    if (index !== null && index >= 0) {
      const mappedReasons = reasonsForFail?.map((reason) => {
        if (reason.id === item?.id) {
          const images = Array.isArray(reason.images) ? [...reason.images] : []
          const updatedImages = images.filter((_, i) => {
            return i !== index
          })
          return {
            ...reason,
            images: updatedImages || []
          }
        }
        return reason
      })
      setReasonsForFail(mappedReasons)
    }
  }

  const getFailedQuantityExceptOne = (currItemId) => {
    return reasonsForFail.reduce((acc, item) => {
      if (item.id !== currItemId) {
        return acc + item.quantity
      }
      return acc
    }, 0)
  }

  const handleChangeQuantity = (e, item) => {
    const number = e.target.value?.trim()
    const value = parseInt(number)

    const updatedReasonsForFail = reasonsForFail.map((reason) => {
      return reason.id === item?.id ? {
        ...reason,
        quantity: value
      } : reason
    })
    setReasonsForFail(updatedReasonsForFail)
  }

  const handleKeyDown = (e) => {
    if (e.key === KEYBOARD_KEYS.ENTER) {
      e.preventDefault()
      e.target.blur()
    }
  }

  const handleBlur = (e, item) => {
    e.preventDefault()
    // checking if the quantity is valid
    let validQuantity = parseInt(item.quantity)
    const restFailedQty = getFailedQuantityExceptOne(item.id)

    if (isNaN(validQuantity) || restFailedQty + validQuantity < skuDetails?.fail_qty) {
      validQuantity = skuDetails.fail_qty - restFailedQty
    } else if (restFailedQty + validQuantity > skuDetails.scanned_qty) {
      validQuantity = skuDetails.scanned_qty - restFailedQty
    }
    setFailQty(restFailedQty + validQuantity)
    setPassQty(skuDetails.scanned_qty - (restFailedQty + validQuantity))

    // updating the quantity in the state
    const updatedReasonsForFail = reasonsForFail.map((reason) => {
      return reason.id === item?.id ? {
        ...reason,
        quantity: validQuantity
      } : reason
    })
    setReasonsForFail(updatedReasonsForFail)

  }

  return (
    <>
      <SideSheet
        title='Select Fail Qty'
        isOpen={isOpen}
        toggle={() => {
          setFailQty(0)
          setPassQty(0)
          setReasonsForFail([])
          toggle()
        }}
        modalClassName="modal-slide-in"
      >
        <ModalBody className='overflow-auto p-2'>
          {failReasonsLoading ? <ComponentSpinner /> : <div className="d-flex flex-column gap-2">
            <div className="flex-center-start w-100 pb-24px">
              <div className="w-100">
                <div className="text-dark-6 txt-sub-md">{t('Scanned Qty')}</div>
                <div className="text-dark txt-h2-sb">{skuDetails?.pass_qty + skuDetails?.fail_qty}</div>
              </div>
              <div className="w-100">
                <div className="text-dark-6 txt-sub-md">{t('Failed Qty')}</div>
                <div className="text-dark txt-h2-sb">{failQty}</div>
              </div>
              <div className="w-100">
                <div className="text-dark-6 txt-sub-md">{t('Passed Qty')}</div>
                <div className="text-dark txt-h2-sb">{passQty}</div>
              </div>
            </div>
            <div style={{ marginBottom: '70px' }} className="d-flex flex-column gap-24px">
              {reasonsForFail?.map((item) => <div key={item.id} className="flex-center-between">
                <div className="text-dark txt-sub-rg max-width-200 text-truncate" title={item.title}>{item.title}</div>
                <div className="flex-center-end gap-16px">
                  {item.quantity > 0 &&
                    <ImageUploaderBinDisabled
                      imageSize={34}
                      customizedUploadIcon={true}
                      customRadius={true}
                      data={item.images}
                      setData={(file, index) => handleSetImages(item, file, index)}
                      isNonSerialise={true}
                      maxImages={10}
                    />}
                  
                  <QuantityButton
                    quantity={item.quantity}
                    minQuantityEnabled={failQty === skuDetails?.fail_qty}
                    maxQuantityEnabled={failQty === skuDetails?.scanned_qty}
                    increaseQuantity={() => increaseQuantity(item)}
                    decreaseQuantity={() => decreaseQuantity(item)}
                    onChange={(e) => handleChangeQuantity(e, item)}
                    onKeyDown={(e) => handleKeyDown(e)}
                    onBlur={(e) => handleBlur(e, item)}
                  />
                </div>
              </div>
              )}
            </div>
          </div>}
          <SidesheetFooter>
            <Button onClick={handleSave} className="w-100 flex-center-center"
              disabled={imagesUploading || loadingState || isSaveBtnDisabled}
            >
              {(imagesUploading || loadingState) && <Spinner size='sm'/>}
              {t('Save and Continue')}
            </Button>
          </SidesheetFooter>
        </ModalBody>
      </SideSheet>

      <OverReceiveItemModal
        isOpen={isOverReceiveModalOpen}
        toggle={() => setIsOverReceiveModalOpen(p => !p)}
        handleChangeQuantity={() => setIsOverReceiveModalOpen(p => !p)}
        handleSaveAnyway={() => markASFailNonSerializeSkuModal()}
        loading={loadingStateModal}
      />
    </>
  )
}

export default NonSerializeMarkAsFail