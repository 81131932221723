import { PageNotFoundIllustration } from '@src/assets/data/assets'
import { useTranslation } from 'react-i18next'

export const PageNotFound = () => {
  const {t} = useTranslation()
  return (
    <div className='d-flex flex-column align-items-center'>
      <PageNotFoundIllustration primary='var(--bs-primary)' primary_dark='var(--bs-primary-dark)' height={200} width={200}/>
      <p className='pe-20px txt-h1-rg text-dark'>{t('Page Not Found')}</p>
    </div>
  )
}
