import {
  cleanupGetPicklistsInZone,
  cleanupGetPicklistsOfStatus,
  cleanupPicklistDetails,
  cleanupPicklistSidebarFilterData,
  getItemDetailsOfPicklist
} from '@src/views/sales/store/store'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  ITEMS_DETAILS_OF_PICKLIST_CONFIG,
  columnsOfAllPicklistsOfStatus
} from '../../constants'
import AllPicklistsOfStatus from './components/AllPicklistsOfStatus'
import ItemDetailsOfPicklist from './components/ItemDetailsOfPicklist'
import './sidebarContainer.scss'

// TODO: Match keys with the backend in order to implement search
const dropdownOptionsAllPicklistsOfStatus = [{ id: 'zone_name', name: 'Zone Name' }]
const dropdownOptionsPicklistsInZone = [{ id: 'zone_name', name: 'Zone Name' }]

const SidebarContainer = (props) => {
  // Props
  const {
    // status = "picked",
    status,
    waveDetails,
    is_sidebar_open,
    set_is_sidebar_open,
    hub_id
  } = props
  const { wave } = waveDetails || {
    wave: { name: 'Wave 1', time: '02:00 PM, 12-03-2023' }
  }
  // States
  const [isItemDetailsOfPicklistOpen, setIsItemDetailsOfPicklistOpen] =
    useState(false)
  const [selectedPicklist, setSelectedPicklist] = useState({})

  const [allPicklistsOfStatusTableData, setAllPicklistsOfStatusTableData] =
    useState([])
  const [itemDetailsOfPicklistTableData, setItemDetailsOfPicklistTableData] =
    useState([])

  //   useSelectors
  const allPicklistsOfStatus = useSelector(
    (state) => state.sales.picklistsOfStatus
  )
  const picklistsInZone = useSelector((state) => state.sales.picklistsInZone)
  const itemDetailsOfPicklist = useSelector(
    (state) => state.sales.picklistDetails
  )

  //   Dispatch
  const dispatch = useDispatch()

  const onAllPicklistsOfStatusClosed = () => {
    set_is_sidebar_open(false)
    setIsItemDetailsOfPicklistOpen(false)
    dispatch(cleanupGetPicklistsOfStatus())
    dispatch(cleanupGetPicklistsInZone())
    dispatch(cleanupPicklistDetails())
    dispatch(cleanupPicklistSidebarFilterData())
  }

  const onItemDetailsOfPicklistClosed = () => {
    setIsItemDetailsOfPicklistOpen(false)
    dispatch(cleanupPicklistDetails())
  }

  const toggleItemDetailsOfPicklist = ({ picklist }) => {
    setSelectedPicklist({
      picklist_status: picklist.picklist_status,
      picklist_id: picklist.picklist_id
    })
    setIsItemDetailsOfPicklistOpen(true)
    dispatch(getItemDetailsOfPicklist({ picklist_id: picklist.picklist_id, hub_id }))
  }

  // UseEffects
  useEffect(() => {
    return () => {
      setIsItemDetailsOfPicklistOpen(false)
      dispatch(cleanupGetPicklistsOfStatus())
      dispatch(cleanupGetPicklistsInZone())
      dispatch(cleanupPicklistDetails())
    }
  }, [])

  useEffect(() => {
    if (allPicklistsOfStatus?.success) {
      const tableData = allPicklistsOfStatus?.data?.data?.map((elem) => ({
        ...elem,
        nextSidebarToggler: toggleItemDetailsOfPicklist
      }))

      setAllPicklistsOfStatusTableData(tableData)
    }
   
    if (itemDetailsOfPicklist?.is_success) {
      // Data Mapping of the actual API
      const { item_details, ...picklistDetail } =
        itemDetailsOfPicklist.data || {}
      const itemsData = item_details?.map((singleItem, index) => ({
        ...singleItem,
        id: `${wave.waveId}_${index}`,
        sku_name: singleItem?.sku_details?.name,
        sku_code: singleItem?.sku_details?.code,
        image: singleItem?.sku_details?.image?.[0]?.url,
        items_to_be_picked: singleItem?.quantity_to_be_picked,
        picked_items: singleItem?.picked_quantity,
        status:singleItem?.status,
        color:singleItem?.colour
      }))

      setSelectedPicklist((p) => ({
        ...p,
        unique_skus: picklistDetail?.unique_skus,
        total_items: picklistDetail?.item_quantity,
        picker_name: picklistDetail?.picker?.name
      }))
      setItemDetailsOfPicklistTableData(itemsData)
    }
  }, [allPicklistsOfStatus, picklistsInZone, itemDetailsOfPicklist])

  const currentPicklistInfo = ITEMS_DETAILS_OF_PICKLIST_CONFIG(
    selectedPicklist?.picklist_status, itemDetailsOfPicklist?.data
  )(selectedPicklist?.picklist_id)

  return (
    <div className="waveSidebarContainer">
      {
        <AllPicklistsOfStatus
          toggleSidebar={() => set_is_sidebar_open(!is_sidebar_open)}
          isSidebarOpen={is_sidebar_open}
          wave={wave}
          time={wave?.time}
          dropdownOptions={dropdownOptionsAllPicklistsOfStatus}
          columns={columnsOfAllPicklistsOfStatus(status)}
          status={status}
          tableData={allPicklistsOfStatusTableData}
          handleSidebarClosed={onAllPicklistsOfStatusClosed}
          hub_id={hub_id}
        />
      }

      <ItemDetailsOfPicklist
        toggleSidebar={() => setIsItemDetailsOfPicklistOpen(!isItemDetailsOfPicklistOpen)}
        handleSidebarClosed={onItemDetailsOfPicklistClosed}
        isSidebarOpen={is_sidebar_open && isItemDetailsOfPicklistOpen}
        wave={wave}
        time={wave?.time}
        dropdownOptions={dropdownOptionsPicklistsInZone}
        columns={currentPicklistInfo.columns || []}
        title={currentPicklistInfo.title}
        status={status}
        tableData={itemDetailsOfPicklistTableData}
        picklist={selectedPicklist}
        hub_id={hub_id}
      />
    </div>
  )
}

export default SidebarContainer
