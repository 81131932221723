import { SKU_TYPE } from '@src/views/inventory/inventory.constants'
import React from 'react'
import Casepack from './Casepack'
import Pallet from './Pallet'
import SkuDetailsAndQc from './SkuDetailsAndQc'

const SkuComponent = React.forwardRef(({ sku_details, selectedTabId, searchBarcode, grnType, scannedBarcode }, ref) => {
  switch (sku_details.package_type) {
  case SKU_TYPE.PALLET.value:
    return <Pallet sku_details={sku_details} selectedTabId={selectedTabId} ref={ref} searchBarcode={searchBarcode} grnType={grnType}/>
  case SKU_TYPE.CASE_PACK.value:
    return <Casepack sku_details={sku_details} selectedTabId={selectedTabId} ref={ref} searchBarcode={searchBarcode} grnType={grnType}/>
  default:
    return <SkuDetailsAndQc sku_details={sku_details} selectedTabId={selectedTabId} ref={ref} searchBarcode={searchBarcode} grnType={grnType} scannedBarcode={scannedBarcode}/>
  }
})

export default SkuComponent