const SVG = ({ height, fillColor, width, iswhite }) => {
  const color = iswhite ? 'white' : fillColor
  return <svg width={width} height={height} viewBox="0 0 77 77" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M6.80005 15C6.80005 12.1282 9.12817 9.80005 12 9.80005H32.4211C35.293 9.80005 37.6211 12.1282 37.6211 15V28.5162H35.2211V15C35.2211 13.4536 33.9675 12.2 32.4211 12.2H12C10.4536 12.2 9.20005 13.4536 9.20005 15V35.4211C9.20005 36.9675 10.4537 38.2211 12 38.2211H26.1418V40.6211H12C9.12817 40.6211 6.80005 38.293 6.80005 35.4211V15Z" fill={color}/>
    <path fillRule="evenodd" clipRule="evenodd" d="M17.6013 24.1749V11.2521H20.0013V22.6084L21.0595 21.6607C21.7051 21.0826 22.6822 21.0826 23.3277 21.6607L24.3859 22.6084V11.2521H26.7859V24.1749C26.7859 25.6447 25.0468 26.4218 23.9518 25.4413L22.1936 23.8668L20.4354 25.4413C19.3405 26.4218 17.6013 25.6447 17.6013 24.1749Z" fill={color}/>
    <path fillRule="evenodd" clipRule="evenodd" d="M46.4211 65.4421C57.0134 65.4421 65.6001 56.8554 65.6001 46.2632C65.6001 35.6709 57.0134 27.0842 46.4211 27.0842C35.8289 27.0842 27.2422 35.6709 27.2422 46.2632C27.2422 56.8554 35.8289 65.4421 46.4211 65.4421ZM46.4211 67.8421C58.3388 67.8421 68.0001 58.1809 68.0001 46.2632C68.0001 34.3454 58.3388 24.6842 46.4211 24.6842C34.5034 24.6842 24.8422 34.3454 24.8422 46.2632C24.8422 58.1809 34.5034 67.8421 46.4211 67.8421Z" fill={color}/>
    <path d="M46 36L46 56" stroke={color} strokeWidth="2.5" strokeLinecap="round"/>
    <path d="M56 46L36 46" stroke={color} strokeWidth="2.5" strokeLinecap="round"/>
  </svg>
}
export default SVG