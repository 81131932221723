import crossIconImg from '@src/assets/images/catalog/bundlesku/cross-icon-red.svg'
import defaultImg from '@src/assets/images/catalog/bundlesku/default-img.svg'
import error_img from '@src/assets/images/catalog/bundlesku/error_img.svg'
import { MAX_BUNDLE_IMAGE_CAN_UPLOAD } from '@src/views/catalog/catalog.constants'
import classNames from 'classnames'
import { useEffect, useState } from 'react'
import { ChevronLeft, ChevronRight, Plus, X } from 'react-feather'
import { useTranslation } from 'react-i18next'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { Modal, ModalBody } from 'reactstrap'
import './styles.scss'

const ImagePreviewModal = ({
  imageUploadHandler,
  currentSelectedImage,
  uploadedImages,
  toggle,
  setUploadedImages,
  imageRef,
  isView = false,
  isImageRemoveNotAllowed,
  ...rest
}) => {
  const { t } = useTranslation()
  const [selectedImage, setSelectedImage] = useState(currentSelectedImage)
  const handleNext = () => {
    setSelectedImage((prev) => prev + 1)
  }
  const handlePrev = () => {
    setSelectedImage((prev) => prev - 1)
  }
  const handleReset = () => {
    setSelectedImage(0)
  }

  const handleImageRemover = (index) => {
    const result = uploadedImages.filter((ele, ind) => {
      return ind !== index
    })
    const needAddIcon = result.filter((ele) => {
      return ele.value === ''
    })
    if (!needAddIcon.length) {
      result.push({ value: '' })
    }
    if (index === selectedImage) {
      if (selectedImage !== 0) {
        setSelectedImage((p) => p - 1)
      }
    } else {
      if (
        !(
          selectedImage < index &&
          uploadedImages.length > selectedImage
        )
      ) {
        setSelectedImage((p) => p - 1)
      }
    }
    setUploadedImages(result)
  }

  useEffect(() => {
    setSelectedImage(currentSelectedImage)
  }, [currentSelectedImage])

  return (
    <Modal className='image-action-modal position-relative' toggle={toggle} centered {...rest} onClosed={handleReset}>
      <div className='image-action-modal-header'>
        <div className='text-dark txt-h3-rg'>{t(`Photo ${isView ? selectedImage + 1 : uploadedImages.length ? uploadedImages.length === 1 ? 0 : selectedImage + 1 : selectedImage } of ${isView ? uploadedImages.length : ((uploadedImages.length === MAX_BUNDLE_IMAGE_CAN_UPLOAD) && uploadedImages[uploadedImages.length - 1].value) ? uploadedImages.length : uploadedImages.length - 1}`)}</div>
        <span
          className='bg-white cursor-pointer position-absolute image-action-cross-icon'
          onClick={toggle}
        >
          <X size={18} color='var(--bs-dark-6)' />
        </span>
      </div>
      <ModalBody className='d-flex flex-column justify-content-center mt-2 mb-2 color-black gap-1'>
        <div className='d-flex justify-content-center align-items-center image-wrapper'>
          <div>
            <button className='prev-btn bg-light-3' disabled={selectedImage <= 0} onClick={handlePrev} >
              <ChevronLeft color={selectedImage <= 0 ? 'var(--bs-dark-3)' : 'var(--bs-dark)'} />
            </button>
          </div>
          <div className='image-action-main-container'>
            <img
              src={uploadedImages[selectedImage]?.value || defaultImg}
              ref={imageRef}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null
                currentTarget.src = error_img
              }}
              className={classNames({
                'w-100 h-100 exist-images':
                  uploadedImages[selectedImage]?.value,
                'default-width': !uploadedImages[selectedImage]?.value
              })}
            />
          </div>
          <div >
            <button className='next-btn bg-light-3' disabled={selectedImage >= (isView ? uploadedImages.length - 1 : ((uploadedImages.length === MAX_BUNDLE_IMAGE_CAN_UPLOAD) && uploadedImages[uploadedImages.length - 1].value) ? uploadedImages.length - 1 : uploadedImages.length - 2)} onClick={handleNext}>
              <ChevronRight color={selectedImage >= (isView ? uploadedImages.length - 1 : ((uploadedImages.length === MAX_BUNDLE_IMAGE_CAN_UPLOAD) && uploadedImages[uploadedImages.length - 1].value) ? uploadedImages.length - 1 : uploadedImages.length - 2) ? 'var(--bs-dark-3)' : 'var(--bs-dark)'} />
            </button>
          </div>
        </div>
        <div className='image-children-wrapper w-100'>
          <PerfectScrollbar>
            <div className='d-flex justify-content-center gap-8px image-children-wrapper-child'>
              {uploadedImages.length && uploadedImages.map((ele, ind) => {
                if (ind === uploadedImages.length - 1 && !ele.value) {
                  return (
                    <div key={ind}>
                      <label htmlFor='select-action-image' id='info-tooltip'>
                        <div className='d-flex gap-4px'>
                          <div className='d-flex justify-content-center align-items-center add-container-solid add-image-cont'>
                            <div className='h-100 w-100 d-flex align-items-center justify-content-center cursor-pointer'>
                              <Plus color='#222' />
                            </div>
                          </div>
                        </div>
                      </label>
                      <input
                        id='select-action-image'
                        type='file'
                        style={{ display: 'none' }}
                        accept='image/png, image/jpeg, image/jpg'
                        multiple
                        onChange={(e) => imageUploadHandler(e)}
                      />
                    </div>
                  )
                } else {
                  return (
                    <div
                      key={ind}
                      className={classNames(
                        'd-flex justify-content-center align-items-center add-container-solid',
                        {
                          'selected-index':
                            selectedImage === ind &&
                            ((isView && uploadedImages.length) || (!isView && uploadedImages.length > 1)),
                          'not-selected-index border border-dark-5':
                            selectedImage !== ind
                        }
                      )}

                    >
                      <div className='h-100 w-100 d-flex align-items-center justify-content-center cursor-pointer position-relative'>
                        {!isView && !isImageRemoveNotAllowed && <div className='position-absolute image-remover d-flex justify-content-center' onClick={() => handleImageRemover(ind)}><img src={crossIconImg} width='12px' /></div>}
                        <img
                          src={ele.value}
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null
                            currentTarget.src = error_img
                          }}
                          width='48px'
                          height='48px'
                          className='exist-images'
                          onClick={() => setSelectedImage(ind)}
                        />
                      </div>
                    </div>
                  )
                }
              })}
            </div>
          </PerfectScrollbar>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default ImagePreviewModal
