const abilityMap = {
  order: {
    view: {
      action: 'view',
      resource: 'order'
    },
    create: {
      action: 'create',
      resource: 'order'
    },
    approve: {
      action: 'approve',
      resource: 'order'
    },
    create_shipment: {
      action: 'create_shipment',
      resource: 'order'
    },
    cancel: {
      action: 'cancel',
      resource: 'order'
    },
    view_log: {
      action: 'view_log',
      resource: 'order'
    },
    view_tag: {
      action: 'view_tag',
      resource: 'order'
    },
    create_tag: {
      action: 'create_tag',
      resource: 'order'
    },
    edit_tag: {
      action: 'edit_tag',
      resource: 'order'
    },
    edit_status: {
      action: 'edit_status',
      resource: 'order'
    },
    view_on_hold: {
      action: 'view_on_hold',
      resource: 'order'
    },
    edit_details: {
      action: 'edit_details',
      resource: 'order'
    },
    forceful_route: {
      action: 'forceful_route',
      resource: 'order'
    },
    view_timeline: {
      action: 'view_timeline',
      resource: 'order'
    },
    reassign_hub: {
      action: 'reassign_hub',
      resource: 'order'
    }
  },
  sto: {
    view: {
      action: 'view',
      resource: 'sto'
    },
    create: {
      action: 'create',
      resource: 'sto'
    },
    approve: {
      action: 'approve',
      resource: 'sto'
    },
    receive: {
      action: 'receive',
      resource: 'sto'
    }
  },
  manifest: {
    view: {
      action: 'view',
      resource: 'manifest'
    },
    edit: {
      action: 'edit',
      resource: 'manifest'
    }
  },
  report: {
    view: {
      action: 'view',
      resource: 'report'
    }
  },
  return: {
    create_request: {
      action: 'create_request',
      resource: 'return'
    },
    view_request: {
      action: 'view_request',
      resource: 'return'
    },
    edit_request: {
      action: 'edit_request',
      resource: 'return'
    },
    create_order: {
      action: 'create_order',
      resource: 'return'
    },
    edit_order: {
      action: 'edit_order',
      resource: 'return'
    },
    cancel_order: {
      action: 'cancel_order',
      resource: 'return'
    },
    view_order: {
      action: 'view_order',
      resource: 'return'
    },
    qc_order: {
      action: 'qc_order',
      resource: 'return'
    },
    refund_order: {
      action: 'refund_order',
      resource: 'return'
    },
    create_shipment: {
      action: 'create_shipment',
      resource: 'return'
    }
  },
  shipment: {
    edit_status: {
      action: 'edit_status',
      resource: 'shipment'
    },
    view: {
      action: 'view',
      resource: 'shipment'
    }
  },
  hub: {
    view: {
      action: 'view',
      resource: 'hub'
    },
    edit: {
      action: 'edit',
      resource: 'hub'
    },
    create: {
      action: 'create',
      resource: 'hub'
    },
    edit_configuration: {
      action: 'edit_configuration',
      resource: 'hub'
    }
  },
  delivery_zone: {
    view: {
      action: 'view',
      resource: 'delivery_zone'
    },
    update: {
      action: 'update',
      resource: 'delivery_zone'
    },
    create: {
      action: 'create',
      resource: 'delivery_zone'
    },
    update_precedence: {
      action: 'update_precedence',
      resource: 'delivery_zone'
    }
  },
  reason: {
    view: {
      action: 'view',
      resource: 'reason'
    },
    edit: {
      action: 'edit',
      resource: 'reason'
    },
    create: {
      action: 'create',
      resource: 'reason'
    },
    delete: {
      action: 'delete',
      resource: 'reason'
    }
  },
  hub_location: {
    view: {
      action: 'view',
      resource: 'hub_location'
    },
    edit: {
      action: 'edit',
      resource: 'hub_location'
    },
    create: {
      action: 'create',
      resource: 'hub_location'
    },
    view_bin: {
      action: 'view_bin',
      resource: 'hub_location'
    },
    create_bin: {
      action: 'create_bin',
      resource: 'hub_location'
    },
    create_bin_hub_location: {
      action: 'create_bin',
      resource: 'hub_location'
    }
  },
  product: {
    create: {
      action: 'create',
      resource: 'product'
    },
    view: {
      action: 'view',
      resource: 'product'
    },
    view_price: {
      action: 'view_price',
      resource: 'product'
    }
  },
  purchase_order: {
    create: {
      action: 'create',
      resource: 'purchase_order'
    },
    view: {
      action: 'view',
      resource: 'purchase_order'
    },
    delete: {
      action: 'delete',
      resource: 'purchase_order'
    }
  },
  supplier: {
    create: {
      action: 'create',
      resource: 'supplier'
    },
    view: {
      action: 'view',
      resource: 'supplier'
    },
    edit: {
      action: 'edit',
      resource: 'supplier'
    }
  },
  seller: {
    create: {
      action: 'create',
      resource: 'seller'
    },
    view: {
      action: 'view',
      resource: 'seller'
    },
    edit: {
      action: 'edit',
      resource: 'seller'
    }
  },
  gate_entry: {
    create: {
      action: 'create',
      resource: 'gate_entry'
    },
    view: {
      action: 'view',
      resource: 'gate_entry'
    }
  },
  grn: {
    create: {
      action: 'create',
      resource: 'grn'
    },
    view: {
      action: 'view',
      resource: 'grn'
    }
  },
  inventory: {
    view: {
      action: 'view',
      resource: 'inventory'
    },
    edit: {
      action: 'edit',
      resource: 'inventory'
    },
    upload: {
      action: 'upload',
      resource: 'inventory'
    },
    configure_reorder_point: {
      action: 'configure_reorder_point',
      resource: 'inventory'
    },
    configure_safety_stock: {
      action: 'configure_safety_stock',
      resource: 'inventory'
    }
  },
  batch: {
    edit: {
      action: 'edit',
      resource: 'batch'
    }
  },
  sku_mapping: {
    view: {
      action: 'view_sku_mapping',
      resource: 'hub_location'
    },
    edit: {
      action: 'edit_sku_mapping',
      resource: 'hub_location'
    }
  },
  user: {
    create: {
      action: 'create',
      resource: 'user'
    },
    view: {
      action: 'view',
      resource: 'user'
    },
    edit: {
      action: 'edit',
      resource: 'user'
    },
    resend_password_link: {
      action: 'resend_password_link',
      resource: 'user'
    }
  },
  role: {
    create: {
      action: 'create',
      resource: 'role'
    },
    view: {
      action: 'view',
      resource: 'role'
    },
    edit: {
      action: 'edit',
      resource: 'role'
    }
  },
  sales_channel_app: {
    add: {
      action: 'add',
      resource: 'sales_channel_app'
    },
    view: {
      action: 'view',
      resource: 'sales_channel_app'
    },
    edit: {
      action: 'edit',
      resource: 'sales_channel_app'
    }
  },
  shipping_app: {
    add: {
      action: 'add',
      resource: 'shipping_app'
    },
    view: {
      action: 'view',
      resource: 'shipping_app'
    }
  },
  picking: {
    create_wave: {
      action: 'create_wave',
      resource: 'picking'
    },
    view_wave: {
      action: 'view_wave',
      resource: 'picking'
    }
  },
  put_away: {
    view: {
      action: 'view',
      resource: 'put_away'
    }
  },
  catalog: {
    compare: {
      action: 'compare',
      resource: 'catalog'
    },
    view_configuration_product: {
      action: 'view_configuration',
      resource: 'catalog'
    },
    edit_configuration_product: {
      action: 'edit_configuration',
      resource: 'catalog'
    }
  },
  configuration: {
    view: {
      action: 'view',
      resource: 'configuration'
    },
    create: {
      action: 'create',
      resource: 'configuration'
    }
  },
  fleet: {
    view_trip: {
      action: 'view',
      resource: 'trip'
    },
    create_trip: {
      action: 'create',
      resource: 'trip'
    },
    view_driver: {
      action: 'view',
      resource: 'driver'
    },
    create_driver: {
      action: 'create',
      resource: 'driver'
    },
    view_configuration: {
      action: 'view',
      resource: 'fleet_configuration'
    },
    create_configuration: {
      action: 'create',
      resource: 'fleet_configuration'
    }
  },
  cycle_count: {
    view: {
      action: 'view',
      resource: 'cycle_count'
    },
    create: {
      action: 'create',
      resource: 'cycle_count'
    },
    count: {
      action: 'count',
      resource: 'cycle_count'
    }
  },
  assembly: {
    view: {
      action: 'view',
      resource: 'assembly'
    }
  },
  shipping_rule: {
    create: {
      action: 'add',
      resource: 'shipping_rule'
    },
    view: {
      action: 'view',
      resource: 'shipping_rule'
    },
    edit: {
      action: 'edit',
      resource: 'shipping_rule'
    }
  },
  //use this for hidden navlink
  alwaysHidden: {
    hidden: {
      action: 'always_hidden_use_case',
      resource: 'always_hidden_use_case'
    }
  },
  customer: {
    create: {
      action: 'create',
      resource: 'customer'
    },
    view: {
      action: 'view',
      resource: 'customer'
    },
    edit: {
      action: 'edit',
      resource: 'customer'
    },
    create_address: {
      action: 'create_address',
      resource: 'customer'
    },
    view_address: {
      action: 'view_address',
      resource: 'customer'
    },
    edit_address: {
      action: 'edit_address',
      resource: 'customer'
    }
  },
  
  salesman: {
    create: {
      action:  'create',
      resource: 'sales_person'
    },
    view: {
      action: 'view',
      resource: 'sales_person'
    },
    edit: {
      action: 'edit',
      resource: 'sales_person'
    },
    
  },
  
  invoices: {
    view: {
      action: 'view',
      resource: 'invoice'
    },
    configure:{
      action: 'configure',
      resource: 'invoice'
    }
  },
  home_dashboard: {
    view: {
      action: 'view_home',
      resource: 'dashboard'
    }
  },
  analytics_seller_dashboard: {
    view: {
      action: 'view_seller',
      resource: 'dashboard'
    }
  },
  analytics_hub_dashboard: {
    view: {
      action: 'view_hub',
      resource: 'dashboard'
    }
  },
  analytics_shipping_dashboard: {
    view: {
      action: 'view_shipping',
      resource: 'dashboard'   
    }
  },
  weighted_average_cost: {
    view: {
      action: 'view',
      resource: 'weighted_average_cost'
    }
  },
  forecast: {
    generate: {
      action: 'generate',
      resource: 'forecast'
    }
  },
  tenant: {
    view: {
      action: 'view',
      resource: 'tenant'
    },
    edit: {
      action: 'edit',
      resource: 'tenant'
    }
  },
  split_order: {
    view: {
      action: 'view',
      resource: 'split_order'
    },
    create: {
      action: 'create',
      resource: 'split_order'
    },
    edit: {
      action: 'edit',
      resource: 'split_order'
    }
  },
  shipment_order: {
    add: {
      action: 'add',
      resource: 'shipment_order'
    },
    view: {
      action: 'view',
      resource: 'shipment_order'
    },
    cancel: {
      action: 'cancel',
      resource: 'shipment_order'
    },
    view_log: {
      action: 'view_log',
      resource: 'shipment_order'
    },
    edit_status: {
      action: 'edit_status',
      resource: 'shipment_order'
    },
    edit_details: {
      action: 'edit_details',
      resource: 'shipment_order'
    }
  },
  pickup_location: {
    view: {
      action: 'view',
      resource: 'pickup_location'
    },
    create: {
      action: 'create',
      resource: 'pickup_location'
    },
    edit: {
      action: 'edit',
      resource: 'pickup_location'
    }
  },
  tax_authority: {
    view: {
      action: 'view',
      resource: 'tax_authority'
    },
    create: {
      action: 'create',
      resource: 'tax_authority'
    }
  },
  billing_profile: {
    view: {
      action: 'view_profile',
      resource: 'billing'
    },
    create: {
      action: 'create_profile',
      resource: 'billing'
    },
    edit: {
      action: 'edit_profile',
      resource: 'billing'
    }
  },
  contract: {
    create: {
      action: 'create_contract',
      resource: 'billing'
    },
    view: {
      action: 'view_contract',
      resource: 'billing'
    },
    terminate: {
      action: 'terminate_contract',
      resource: 'billing'
    }
  },
  bill: {
    create: {
      action: 'create_bill',
      resource: 'billing'
    },
    view: {
      action: 'view_bill',
      resource: 'billing'
    },
    edit: {
      action: 'edit_bill',
      resource: 'billing'
    },
    finalize: {
      action: 'finalize_bill',
      resource: 'billing'
    },
    view_profile: {
      action: 'view_profile',
      resource: 'billing'
    }
  },
  generate_bill: {
    create: {
      action: 'generate_bill',
      resource: 'billing'
    }
  },
  adhoc: {
    create: {
      action: 'create',
      resource: 'adhoc'
    },
    view: {
      action: 'view',
      resource: 'adhoc'
    }
  },
  adhoc_usage: {
    create: {
      action: 'create_usage',
      resource: 'adhoc'
    },
    view: {
      action: 'view_usage',
      resource: 'adhoc'
    },
    export: {
      action: 'export_usage',
      resource: 'adhoc'
    }
  },
  tenant_city_mapping: {
    add: {
      action: 'add',
      resource: 'tenant_city_mapping'
    },
    view: {
      action: 'view',
      resource: 'tenant_city_mapping'
    }
  },
  external_wms: {
    view: {
      action: 'view',
      resource: 'external_wms'
    },
    edit: {
      action: 'edit',
      resource: 'external_wms'
    }
  },
  reports_api: {
    edit: {
      action: 'edit',
      resource: 'reports_api'
    }
  },
  custom_app: {
    view: {
      action: 'view',
      resource: 'custom_app'
    }
  },
  analytics: {
    view: {
      action: 'view',
      resource: 'analytics'
    }
  },
  dashboard:{
    download_orders:{
      action:'download_orders',
      resource:'dashboard'
    }
  },
  sku_configurtion:{
    view:{
      action:'view',
      resource:'sku_configuration'
    },
    set:{
      action:'set',
      resource:'sku_configuration'
    }
  },
  view_hub: {
    view: {
      action: 'view',
      resource: 'hub'
    }
  },
  pos: {
    view: {
      action: 'view',
      resource: 'pos'
    }
  },
  pos_setting: {
    set: {
      action: 'set',
      resource: 'pos_setting'
    }
  },
  register: {
    create: {
      action: 'create',
      resource: 'register'
    },
    view: {
      action: 'view',
      resource: 'register'
    },
    open: {
      action: 'open',
      resource: 'register'
    },
    close: {
      action: 'close',
      resource: 'register'
    }
  },
  cash_management: {
    view: {
      action: 'view',
      resource: 'cash_management'
    }
  },
  custom: {
    grn_view: {
      action: 'custom_view',
      resource: 'grn'
    },
    gate_entry_view: {
      action: 'custom_view',
      resource: 'gate_entry'
    },
    inventory_view: {
      action: 'custom_view',
      resource: 'inventory'
    },
    sku_locations_view: {
      action: 'custom_view',
      resource: 'sku_locations'
    },
    put_away_view: {
      action: 'custom_view',
      resource: 'put_away'
    },
    assembly_view: {
      action: 'custom_view',
      resource: 'assembly'
    },
    stock_transfer_view: {
      action: 'custom_view',
      resource: 'stock_transfer'
    },
    seller_view: {
      action: 'custom_view',
      resource: 'seller'
    },
    pos_view: {
      action: 'custom_view',
      resource: 'pos'
    },
    bulk_ship: {
      action: 'custom_view',
      resource: 'bulk_ship'
    },
    tax_invoices: {
      action: 'custom_view',
      resource: 'tax_invoices'
    },
    sales_channel_listing: {
      action: 'custom_view',
      resource: 'sales_channel_listing'
    },
    kits: {
      action: 'custom_view',
      resource: 'kits'
    },
    manage_invoice: {
      action: 'custom_view',
      resource: 'manage_invoice'
    },
    hub_bin: {
      action: 'custom_view',
      resource: 'hub_bin'
    },
    manifest_view: {
      action: 'custom_view',
      resource: 'manifest_view'
    },
    shipments_tracking_view: {
      action: 'custom_view',
      resource: 'shipments_tracking_view'
    }
  },
  automation_rules: {
    packaging: {
      view: {
        action: 'view_packaging_rule',
        resource: 'automation_rules'
      },
      add: {
        action: 'add_packaging_rule',
        resource: 'automation_rules'
      },
      edit: {
        action: 'edit_packaging_rule',
        resource: 'automation_rules'
      }
    },
    hub_routing: {
      view: {
        action: 'view_hub_routing_rule',
        resource: 'automation_rules'
      },
      add: {
        action: 'add_hub_routing_rule',
        resource: 'automation_rules'
      },
      edit: {
        action: 'edit_hub_routing_rule',
        resource: 'automation_rules'
      }
    }
  },
  view_configuration_hub: {
    view : {
      action: 'view_configuration',
      resource: 'hub'
    }
  },
  configure_max_shelf_life_inventory: {
    action:'configure_max_shelf_life',
    resource:'inventory'
  },
  sto_requests_view: {
    view:{
      action:'custom_view',
      resource:'sto_requests'
    }
  },
  picking_wave_view:{
    view:{
      action:'custom_view',
      resource:'picking_wave'
    }
  },
  hub_assets_view:{
    view:{
      action:'custom_view',
      resource:'hub_assets'
    }
  },
  hub_locations_zones_view:{
    view:{
      action:'custom_view',
      resource:'hub_locations_zones'
    }
  },
  purchase_orders_view:{
    view:{
      action:'custom_view',
      resource:'purchase_orders'
    }
  },
  inventory_operations_view:{
    view:{
      action:'custom_view',
      resource:'inventory_operations'
    }
  }
}

export default abilityMap