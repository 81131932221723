import CustomLabel from '@src/@core/components/ui/badge/CustomLabel'
import CustomToast from '@src/@core/components/ui/custom-toast/CustomToast'
import ComponentSpinner from '@src/@core/components/ui/spinner/Loading-spinner'
import { CREATE_RETURN_REQUES_PATH, ORDER_TYPES } from '@src/App.constants'
import abilityMap from '@src/assets/data/abilityMapping/abilityMapping'
import trackingImage from '@src/assets/images/orders/trackingOrder.svg'
import { AbilityContext } from '@src/utility/context/Can'
import { STRINGIFY } from '@src/utility/hooks/useFilters'
import CancelOrderPopup from '@src/views/sales/components/CancelOrderPopup'
import MultipleAwbSideSheet from '@src/views/sales/components/MultipleAwbSideSheet/MultipleAwbSideSheet'
import ReassignHubModal from '@src/views/sales/components/reassignHubModal'
import { ATTACHMENTS_SIDEBAR_MODE, EDIT_ORDER_ENTITY_TYPES, ORDER_OPERATIONAL_BUTTONS } from '@src/views/sales/constant/orders.constants'
import CancelShipmentPopUp from '@src/views/sales/create/CancelShipmentPopUp'
import { getOrdersApi } from '@src/views/sales/sales.apis'
import { EDIT_MODAL_TYPE } from '@src/views/sales/sales.constant'
import STODetailSidebar from '@src/views/sales/StockTransferOrder/components/storeDetail/stoDetailSidebar'
import { clearCreateOrderItems, clearInvoiceData, editOrder, getSingleOrderDetails, resetSuccess, syncOrderPaymentStatus } from '@src/views/sales/store/store'
import { useQueryClient } from '@tanstack/react-query'
import classNames from 'classnames'
import { useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import EditCustomerAndShippingDetailsModal from '../../../components/EditCustomerAndShippingDetailsModal/EditCustomerAndShippingDetailsModal'
import OnHoldConfirmationPopUp from '../../../components/OnHoldConfirmationPopUp/OnHoldConfirmationPopUp'
import Attachments from '../../createOrder/attachments'
import OrderDetailsTable from '../../ordersForApproval/OrderDetailsTable'
import SplittedOrderDetailsSideSheet from '../../splittedOrders/components/splittedOrderDetailsSideSheet'
import BasicDetailsCard from '../components/basicDetailsCard/basicDetailsCard'
import ButtonSection from '../components/buttonSection/buttonSection'
import CustomerDetailsCard from '../components/customerDetailsCard/customerDetailsCard'
import DestinationHubDetailsCard from '../components/destinationHubDetailsCard/destinationHubDetailsCard'
import QunatityEditAbleInfo from '../components/qunatityEditAbleInfo/qunatityEditAbleInfo'
import ReassignedHubInfo from '../components/reassignedHubInfo'
import ShipmentDetailsCard from '../components/shipmentDetailsCard/shipmentDetailsCard'
import ShippingAddressCard from '../components/shippingAddressCard/shippingAddressCard'
import './ItemDetails.scss'

const ItemDetails = ({ setShipmentSlider, setIsModalOpen, handleGetParentData }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const ability = useContext(AbilityContext)
  const { singleOrderDetailsData } = useSelector((state) => state.sales)
  const salesSuccess = useSelector((state) => state.sales.success)
  const returnSuccess = useSelector((state) => state.returns.success)
  const sales_loading = useSelector((state) => state.sales.loading)
  const orderDetails = singleOrderDetailsData?.data

  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [isEditDetailsModalOpen, setIsEditDetailsModalOpen] = useState({ open: false, key: EDIT_MODAL_TYPE.CUSTOMER_DETAILS.key })
  const [isSplittedOrderDetailsSideSheetOpen, setIsSplittedOrderDetailsSideSheetOpen] = useState(false)
  const [multipleAwbModalOpen, setMultipleAwbModalOpen] = useState(false)
  const [multipleAwbList, setMultipleAwbList] = useState([])
  const [isCancelOrderPopupOpen, setIsCancelOrderPopupOpen] = useState(false)
  const [isOnHoldOrderCofirmationPopUpOpen, setIsOnHoldOrderCofirmationPopUpOpen] = useState(false)
  const [Buttons, setButton] = useState({ actionButtons: {}, syncButtons: {}, editButtons: {} })
  const [isStoDetailsOpen, setIsStoDetailsOpen] = useState(false)
  const [isReassignHubModalOpen, setReassignHubModalOpen] = useState(false)

  const { syncButtons, editButtons } = Buttons

  const hasEditAttachmentButton = useMemo(() => {
    return orderDetails?.buttons?.find(btn => btn.key === ORDER_OPERATIONAL_BUTTONS.EDIT_ATTACHMENTS.id)
  }, [orderDetails])
  const [attachmentSidebar, setAttachmentSidebar] = useState({
    isOpen: false,
    mode: ATTACHMENTS_SIDEBAR_MODE.ADD.id
  })
  const [addedAttachments, setAddedAttachments] = useState([])
  const queryClient = useQueryClient()
  const [isOpenAddLocationAttributesModal, setIsOpenAddLocationAttributesModal] = useState(false)

  const hasEditOrderDetailsPermission = ability.can(abilityMap.order.edit_details.action, abilityMap.order.edit_details.resource)

  const toggleAddLocationAttributesModal = () => {
    setIsOpenAddLocationAttributesModal(p => !p)
  }

  const handleToggleAttachmentSidebar = () => {
    const mode = hasEditOrderDetailsPermission && hasEditAttachmentButton
      ? orderDetails.attachments?.length ? ATTACHMENTS_SIDEBAR_MODE.EDIT.id : ATTACHMENTS_SIDEBAR_MODE.ADD.id
      : ATTACHMENTS_SIDEBAR_MODE.VIEW.id
    setAttachmentSidebar(prev => ({ ...prev, isOpen: !prev.isOpen, mode }))
  }

  const handleWhenAttachmentEdited = (req) => {
    const message = req.toastMessage || 'Files saved successfully';
    CustomToast(message, { my_type: 'success' })
    handleToggleAttachmentSidebar()
    dispatch(getSingleOrderDetails(orderDetails.id))
    queryClient.invalidateQueries({ queryKey: [getOrdersApi.apiKey] })
  }

  const { mutate: editAttachment, isPending: isEditAttachmentPending } = editOrder({
    onSuccess: handleWhenAttachmentEdited
  })

  const handleNavigateToReturnOrders = () => {
    const searchQuery = {
      column: { id: 'seller_sales_channel_order_ids', name: 'Order ID' },
      query: orderDetails.seller_sales_channel_order_id
    }
    const stringifiedSearchQuery = STRINGIFY(searchQuery)
    navigate(`/order/return/return-order?currentTab=all&q=${stringifiedSearchQuery}`)
  }

  const handleSyncPaymentStatus = () => {
    dispatch(syncOrderPaymentStatus({ seller_sales_channel_order_id: orderDetails.seller_sales_channel_order_id, seller_id: orderDetails.seller_id, seller_sales_channel_id: orderDetails.seller_sales_channel_id }))
  }

  useEffect(() => {
    if (salesSuccess.editCustomerAndShippingDetails) {
      setIsEditDetailsModalOpen(prev => ({ ...prev, open: false }))
      dispatch(clearInvoiceData())
      dispatch(resetSuccess())
      dispatch(clearCreateOrderItems())
      if (orderDetails) {
        dispatch(getSingleOrderDetails(orderDetails.id))
      }
      queryClient.invalidateQueries({ queryKey: [getOrdersApi.apiKey] })
    }

    if (salesSuccess.syncOrderPaymentStatus) {
      dispatch(getSingleOrderDetails(orderDetails.id))
      dispatch(resetSuccess())
      dispatch(clearInvoiceData())
    }

    if (returnSuccess.getReturnOrderToCreateReturnRequest) {
      navigate(CREATE_RETURN_REQUES_PATH)
    }
  }, [salesSuccess.editCustomerAndShippingDetails, salesSuccess.syncOrderPaymentStatus, returnSuccess.getReturnOrderToCreateReturnRequest])

  useEffect(() => {
    if (orderDetails?.attachments?.length) {
      const attachments = orderDetails.attachments.map(attachment => (
        {
          file: {
            name: attachment.name,
            type: attachment.mime_type
          },
          description: attachment.description,
          file_url: attachment.file_url
        }
      ))
      setAddedAttachments(attachments)
    } else {
      setAddedAttachments([])
    }
  }, [orderDetails])

  const handleEditDetailsModal = (key) => {
    setIsEditDetailsModalOpen({ open: true, key })
  }

  const togglemultipleAwbModal = (awbData) => {
    setMultipleAwbList(
      awbData ? awbData : []
    )
    setMultipleAwbModalOpen((p) => !p)
  }

  const handleOpenSplittedOrderDetailSideSheet = () => {
    setIsSplittedOrderDetailsSideSheetOpen(true)
  }

  const handleCloseSplittedOrderDetailSideSheet = () => {
    setIsSplittedOrderDetailsSideSheetOpen(false)
  }

  const handleCancelOrderSuccess = () => {
    handleGetParentData()
    setIsCancelOrderPopupOpen(false)
    setIsModalOpen(false)
  }

  const handleReassignHubSuccess = () => {
    handleGetParentData()
    setReassignHubModalOpen(false)
    setIsModalOpen(false)
  }

  const onSaveAttachments = (files, toastMessage) => {
    const attachments = files.map((fileObj) => {
      return {
        file_url: fileObj.file_url,
        name: fileObj.file.name.split('.')[0],
        description: fileObj.description,
        mime_type: fileObj.file.type,
        should_upload: Boolean(fileObj.should_upload)
      }
    })
    const body = {
      attachments,
      edit_entity_type: EDIT_ORDER_ENTITY_TYPES.ORDER_ATTACHMENTS
    }
    editAttachment({ body, order_id: orderDetails.id, toastMessage })
  }

  const handleGetButtonArray = (buttonsData) => {
    setButton(buttonsData)
  }

  const handleShowSTODetail = () => {
    setIsStoDetailsOpen(p=>!p)
  }

  const uploadDetails = {
    service: 'oms',
    usecase: 'order-attachments'
  }

  return <div className="mb-2 order-item-detail w-100">
    {orderDetails && !sales_loading.getSingleOrderDetails ? (
      <div>
        {orderDetails.is_quantity_edited && <QunatityEditAbleInfo source={orderDetails.source} />}

        {orderDetails.is_hub_reassigned && <ReassignedHubInfo orderDetails={orderDetails} />}

        <div className="flex-center-between gap-16px">
          <div className="status-wrapper bg-white rounded-8px d-flex align-items-center gap-12px flex-grow-1">
            <div className="status-label rounded-start text-secondary txt-h3-md py-8px px-16px">
              {t('Status')}
            </div>
            {orderDetails.status && (
              <div className="d-flex gap-8px pe-12px">
                <CustomLabel title={orderDetails.status} className="order-status-tag text-uppercase" />
              </div>
            )}
          </div>

          <ButtonSection
            orderDetails={orderDetails}
            setIsModalOpen={setIsModalOpen}
            setShipmentSlider={setShipmentSlider}
            setDeleteModalOpen={setDeleteModalOpen}
            setIsOnHoldOrderCofirmationPopUpOpen={setIsOnHoldOrderCofirmationPopUpOpen}
            setIsCancelOrderPopupOpen={setIsCancelOrderPopupOpen}
            handleGetButtonArray={handleGetButtonArray}
            setReassignHubModalOpen={setReassignHubModalOpen}
          />
        </div>

        <BasicDetailsCard
          orderDetails={orderDetails}
          hasEditOrderDetailsPermission={hasEditOrderDetailsPermission}
          toggleAddLocationAttributesModal={toggleAddLocationAttributesModal}
          isOpenAddLocationAttributesModal={isOpenAddLocationAttributesModal}
          syncButtons={syncButtons}
          handleSyncPaymentStatus={handleSyncPaymentStatus}
          handleNavigateToReturnOrders={handleNavigateToReturnOrders}
          handleOpenSplittedOrderDetailSideSheet={handleOpenSplittedOrderDetailSideSheet}
          handleToggleAttachmentSidebar={handleToggleAttachmentSidebar}
          hasEditAttachmentButton={hasEditAttachmentButton}
          handleShowSTODetail={handleShowSTODetail}
        />

        <div className="my-24px details-card-container">
          <div className={classNames({ 'd-flex flex-column gap-24px': orderDetails?.shipment?.awb_number })}>
            <CustomerDetailsCard
              customer={orderDetails.customer}
              editButtons={editButtons}
              handleEditDetailsModal={handleEditDetailsModal}
              orderType={orderDetails.type}
              hasEditOrderDetailsPermission={hasEditOrderDetailsPermission}
            />

            {orderDetails?.shipment?.awb_number && (
              <ShipmentDetailsCard orderDetails={orderDetails} trackingImage={trackingImage} />
            )}
          </div>

          {
            orderDetails.type === ORDER_TYPES.STO.value ? (
              <DestinationHubDetailsCard
                shipping_address={orderDetails.shipping_address}
                destination_hub={orderDetails.destination_hub}
              />
            ) : (
              <ShippingAddressCard
                shipping_address={orderDetails.shipping_address}
                editButtons={editButtons}
                handleEditDetailsModal={handleEditDetailsModal}
                hasEditOrderDetailsPermission={hasEditOrderDetailsPermission}
              />
            )
          }

        </div>

        <OrderDetailsTable orderDetails={orderDetails} editButtons={editButtons} />
      </div>
    ) : (
      <ComponentSpinner className="m-5" />
    )}

    {orderDetails && <CancelShipmentPopUp shipmentOrderID={orderDetails.shipment_order_id} deleteModalOpen={deleteModalOpen} setDeleteModalOpen={setDeleteModalOpen} shipping_partner_tag={orderDetails.shipment.shipping_partner_tag} awb_number={orderDetails.shipment.awb_number} />}

    {orderDetails && isEditDetailsModalOpen.open && <EditCustomerAndShippingDetailsModal isModalOpen={isEditDetailsModalOpen} setIsModalOpen={setIsEditDetailsModalOpen} order_id={orderDetails.id} editDetails={(isEditDetailsModalOpen.key === EDIT_MODAL_TYPE.CUSTOMER_DETAILS.key || isEditDetailsModalOpen.key === EDIT_MODAL_TYPE.RECEPIENT_DETAILS.key) ? orderDetails.customer : orderDetails.shipping_address} />}

    <SplittedOrderDetailsSideSheet isModalOpen={isSplittedOrderDetailsSideSheetOpen} handleCloseSideSheet={handleCloseSplittedOrderDetailSideSheet} order_id={orderDetails?.is_split_order ? orderDetails?.parent_order_details.id : orderDetails?.id} isSplitOrderCreated={orderDetails?.is_split_orde} />

    <MultipleAwbSideSheet modalOpen={multipleAwbModalOpen} data={multipleAwbList} toggleSidebar={togglemultipleAwbModal} />

    <CancelOrderPopup
      isCancelOrderPopupOpen={isCancelOrderPopupOpen}
      setIsCancelOrderPopupOpen={setIsCancelOrderPopupOpen}
      orderDetail={{ ...orderDetails, sellerOrderId: orderDetails?.seller_sales_channel_order_id }}
      setOrderDetail={() => { }}
      handleCancelOrderSuccess={handleCancelOrderSuccess}
    />

    <OnHoldConfirmationPopUp isPopUpOpen={isOnHoldOrderCofirmationPopUpOpen} setIsOnHoldOrderCofirmationPopUpOpen={setIsOnHoldOrderCofirmationPopUpOpen} order_id={orderDetails?.id} />
    <Attachments
      isOpen={attachmentSidebar.isOpen}
      toggle={handleToggleAttachmentSidebar}
      addedAttachments={addedAttachments}
      setAddedAttachments={setAddedAttachments}
      mode={attachmentSidebar.mode}
      onSaveAttachments={onSaveAttachments}
      isLoading={isEditAttachmentPending}
      uploadDetails={uploadDetails}
    />
        
    <STODetailSidebar
      isShowSTODetail={isStoDetailsOpen}
      handleShowSTODetail={handleShowSTODetail}
      stoRequestId={orderDetails?.sto_request_id}
    />
        
    <ReassignHubModal
      isOpen={isReassignHubModalOpen}
      setIsOpen={setReassignHubModalOpen}
      orderDetail={orderDetails}
      handleReassignHubSuccess={handleReassignHubSuccess}
    />
  </div>
}

export default ItemDetails