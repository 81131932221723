import { useSearchParams } from 'react-router-dom'
import { useMemo } from 'react'

export const PARSE = (x) => {
  try {
    // return JSON.parse(atob(x))
    const decodedString = decodeURIComponent(x)
    return JSON.parse(decodedString)
  } catch {
    return {}
  }
}
export const STRINGIFY = (x) => {
  try {
    // return btoa(JSON.stringify(x))
    return encodeURIComponent(JSON.stringify(x))
  } catch {
    return {}
  }
}
export const urlParamsToObject = (searchParams) => Object.fromEntries([...searchParams])
const RemovefilterWhichValueAll = (filterobject) => {
  const copiedFilterobject = {...filterobject}
  for (const propery in copiedFilterobject) {
    if (copiedFilterobject[propery]?.value === 'all') {
      delete copiedFilterobject[propery]
    }
  }
  return copiedFilterobject
}

export const useSearchParamsManager = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const params = urlParamsToObject(searchParams)

  const getQueryParam = (paramName, defaultValue) => params[paramName] || defaultValue

  const setQueryParam = (paramName, value, shouldAdd = true) => {
    const updatedParams = Object.fromEntries([...searchParams])
    if (shouldAdd) {
      updatedParams[paramName] = value
    } else {
      delete updatedParams[paramName]
    }
    setSearchParams(updatedParams)
  }

  const setQueryParamsChain = (...paramValuePairs) => {
    const chain = () => {
      for (const [paramName, value, shouldAdd = true] of paramValuePairs) {
        setQueryParam(paramName, value, shouldAdd)
      }
    }

    return chain
  }


  return { getQueryParam, setQueryParam, setQueryParamsChain, params, setSearchParams }
}

export const useFilters = (initialValues) => {
  const { getQueryParam, setSearchParams, params } = useSearchParamsManager()

  const filter = useMemo(() => {
    const filterParam = getQueryParam('f', null)
    return filterParam ? PARSE(filterParam) : initialValues
  }, [initialValues, getQueryParam])

  const formattedFilters = useMemo(() => { 
    return Object.entries(filter || {}).reduce((acc, [key, curVal]) => {
      if (Array.isArray(curVal)) {
        acc[key] = curVal.map(el => el.value)
      } else if (curVal?.value) {
        acc[key] = curVal.value
      }
      return acc
    }, {})
  }, [initialValues, getQueryParam])

  const setFilter = (newFilterData) => {
    const newFilterDataAfterRemovingAllFillterValue = RemovefilterWhichValueAll(newFilterData)
    const stringifiedFilter = STRINGIFY({ ...newFilterDataAfterRemovingAllFillterValue })
    const updatedParams = JSON.parse(JSON.stringify(params))
    delete updatedParams.p
    updatedParams.f = stringifiedFilter
    setSearchParams(updatedParams)
  }

  const resetFilter = () => {
    setFilter(initialValues)
  }

  return { filter, setFilter, formattedFilters, resetFilter }
}

/**
 ** Setting search the searchparams
 * @param {Object} initialQuery {key: searchColumn, value: searchQuery}
 */
export const useSearch = (initialQuery) => {
  const { getQueryParam, setSearchParams, params } = useSearchParamsManager()

  const searchQuery = useMemo(() => {
    const searchParam = getQueryParam('q', null)
    return searchParam ? PARSE(searchParam) : initialQuery
  }, [initialQuery, getQueryParam])

  const setSearchQuery = (newQuery) => {
    const stringifiedFilter = STRINGIFY({ ...newQuery })
    const updatedParams = JSON.parse(JSON.stringify(params))
    delete updatedParams.p
    updatedParams.q = stringifiedFilter
    setSearchParams(updatedParams)
  }

  return { searchQuery, setSearchQuery }
}

export const usePagination = (initialPagination = { page: 1, per_page: 20 }) => {
  const { getQueryParam, setSearchParams, params } = useSearchParamsManager()

  const pagination = useMemo(() => {
    const paginationParam = getQueryParam('p', null)
    return paginationParam ? PARSE(paginationParam) : initialPagination
  }, [initialPagination, getQueryParam])

  const setPagination = (pagination) => {
    const stringifiedFilter = STRINGIFY({ ...pagination })
    const updatedParams = JSON.parse(JSON.stringify(params))
    updatedParams.p = stringifiedFilter
    setSearchParams(updatedParams)
  }

  return { pagination, setPagination }
}

export const useSorting = (initialSort = {}) => {
  const { getQueryParam, setSearchParams, params } = useSearchParamsManager()

  const sort = useMemo(() => {
    const sortParam = getQueryParam('s', null)
    return sortParam ? PARSE(sortParam) : initialSort
    // return JSON.stringify(sortValue)
  }, [initialSort, getQueryParam])

  const setSort = (sortField) => {
    const stringifiedFilter = STRINGIFY({ ...sortField })
    const updatedParams = JSON.parse(JSON.stringify(params))
    delete updatedParams.p
    updatedParams.s = stringifiedFilter
    setSearchParams(updatedParams)
  }
  return { sort, setSort }
}

// export {
//   useFilters,
//   useSearch,
//   usePagination,
//   useSorting,
//   useSearchParamsManager
// }
