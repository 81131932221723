import FilterTabs from '@src/components/filterTabs'
import classNames from 'classnames'
import PageHeader from '../page-header'
import './styles.scss'

const SidesheetHeader = (props) => {
  const { isBodyScrollable, tabDataList, onTabChange, selectedTabId, customBackground, headerWithTabs, ...rest } = props
  const headerClasses = classNames('sidesheet-header', {
    'mb-1':headerWithTabs,
    'side-sheet-box-shadow-header': isBodyScrollable
  })

  return (
    <div className={headerClasses}>
      <PageHeader {...rest} />
      {headerWithTabs ? <FilterTabs
        customBackground={customBackground}
        selectedTabId={selectedTabId}
        tabDataList={tabDataList}
        onTabChange={onTabChange}
      /> : null}
    </div>
  )
}

export default SidesheetHeader
