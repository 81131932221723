const SVG = ({ iswhite, fillColor, height, width }) => {
  const color = iswhite ? 'white' : fillColor
  return (
    <svg width={width} height={height} viewBox="0 0 77 77" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M59.9633 31.8401L63.0994 33.3315C63.7388 32.795 64.3301 32.2015 64.8689 31.562L63.3775 28.426C64.087 27.32 64.6083 26.0827 64.8973 24.7577L68.167 23.5949C68.202 23.1831 68.2239 22.7649 68.2239 22.3444C68.2239 21.9239 68.202 21.5056 68.167 21.0939L64.8973 19.931C64.6083 18.6061 64.087 17.3687 63.3775 16.2628L64.8689 13.1267C64.3323 12.4873 63.7388 11.896 63.0994 11.3572L59.9633 12.8486C58.8574 12.1391 57.62 11.6178 56.2951 11.3288L55.1322 8.05694C54.7205 8.0219 54.3044 8 53.8817 8C53.4591 8 53.043 8.0219 52.6312 8.05694L51.4684 11.3266C50.1434 11.6157 48.9061 12.1369 47.8002 12.8464L44.6641 11.3551C44.0246 11.8916 43.4333 12.4851 42.8946 13.1246L44.386 16.2606C43.6764 17.3665 43.1552 18.6039 42.8661 19.9288L39.5943 21.0939C39.5593 21.5056 39.5374 21.9217 39.5374 22.3444C39.5374 22.767 39.5593 23.1831 39.5943 23.5949L42.8639 24.7577C43.153 26.0827 43.6742 27.32 44.3838 28.426L42.8924 31.562C43.4289 32.2015 44.0224 32.7928 44.6619 33.3315L47.798 31.8401C48.9039 32.5497 50.1412 33.0709 51.4662 33.36L52.6291 36.6296C53.0408 36.6647 53.4591 36.6866 53.8795 36.6866C54.3 36.6866 54.7183 36.6647 55.13 36.6296L56.2929 33.36C57.62 33.0731 58.8574 32.5497 59.9633 31.8401Z"
        stroke={color}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M53.8806 30.2412C58.2425 30.2412 61.7785 26.7052 61.7785 22.3433C61.7785 17.9814 58.2425 14.4454 53.8806 14.4454C49.5187 14.4454 45.9827 17.9814 45.9827 22.3433C45.9827 26.7052 49.5187 30.2412 53.8806 30.2412Z"
        stroke={color}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.5679 26.0798H38.0345V24.0165H17.4205C16.3623 24.0165 15.5045 24.8743 15.5045 25.9325V59.291H17.5679V26.0798ZM49.6778 35.6597V63.3853C49.6778 67.0415 46.7054 70 43.0455 70C39.3857 70 36.4133 67.0415 36.4133 63.3853V59.291H38.4766V63.3853C38.4766 65.8959 40.5192 67.9367 43.0455 67.9367C45.5719 67.9367 47.6144 65.8959 47.6144 63.3853V35.6597H49.6778Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 60.1253C9 59.0672 9.85782 58.2094 10.916 58.2094H37.445C38.0147 58.2094 38.4766 58.6713 38.4766 59.241C38.4766 59.8108 38.0147 60.2727 37.445 60.2727H11.0634V62.7782C11.0634 65.6271 13.3729 67.9366 16.2218 67.9366H42.8981C43.4679 67.9366 43.9298 68.3985 43.9298 68.9683C43.9298 69.5381 43.4679 70 42.8981 70H16.2218C12.2333 70 9 66.7667 9 62.7782V60.1253Z"
        fill={color}
      />
      <path
        d="M31.4798 54.2686V32.985H32.8739V54.2686H31.4798ZM35.8407 39.5862C35.75 38.7992 35.376 38.1895 34.7186 37.7572C34.0612 37.3193 33.2338 37.1004 32.2364 37.1004C31.5223 37.1004 30.9046 37.2112 30.3832 37.4329C29.8618 37.6491 29.4566 37.9484 29.1676 38.3308C28.8842 38.7077 28.7425 39.1373 28.7425 39.6195C28.7425 40.0241 28.8389 40.3733 29.0316 40.667C29.2299 40.9608 29.4878 41.2074 29.8051 41.407C30.1282 41.601 30.4739 41.7645 30.8422 41.8975C31.2106 42.025 31.5648 42.1303 31.9048 42.2134L33.605 42.6457C34.1604 42.7788 34.7299 42.9589 35.3136 43.1861C35.8974 43.4134 36.4386 43.7127 36.9373 44.084C37.436 44.4554 37.8384 44.9154 38.1444 45.4641C38.4561 46.0129 38.612 46.6697 38.612 47.4345C38.612 48.3989 38.3569 49.2553 37.8469 50.0035C37.3425 50.7518 36.6086 51.3421 35.6452 51.7744C34.6874 52.2067 33.5285 52.4229 32.1684 52.4229C30.8649 52.4229 29.7371 52.2206 28.785 51.816C27.8329 51.4114 27.0877 50.8377 26.5493 50.095C26.0109 49.3467 25.7134 48.4599 25.6567 47.4345H28.292C28.343 48.0498 28.547 48.5625 28.904 48.9726C29.2667 49.3772 29.7286 49.6793 30.2897 49.8788C30.8564 50.0728 31.477 50.1698 32.1514 50.1698C32.8938 50.1698 33.554 50.0562 34.132 49.8289C34.7158 49.5962 35.1748 49.2747 35.5092 48.8645C35.8435 48.4488 36.0107 47.9639 36.0107 47.4096C36.0107 46.9052 35.8634 46.4923 35.5687 46.1708C35.2796 45.8494 34.8858 45.5833 34.3871 45.3727C33.894 45.1621 33.3358 44.9764 32.7124 44.8157L30.6552 44.2669C29.2611 43.8956 28.156 43.3496 27.3399 42.6291C26.5295 41.9086 26.1243 40.9552 26.1243 39.7691C26.1243 38.7881 26.3963 37.9318 26.9404 37.2001C27.4844 36.4685 28.2211 35.9004 29.1506 35.4958C30.08 35.0856 31.1284 34.8806 32.2959 34.8806C33.4746 34.8806 34.5146 35.0829 35.4157 35.4875C36.3224 35.8921 37.0365 36.4491 37.5579 37.1586C38.0792 37.8625 38.3513 38.6717 38.3739 39.5862H35.8407Z"
        fill={color}
      />
    </svg>
  )
}
export default SVG