import { axiosInstance } from '@src/network/AxiosInstance';
import { clearRedirectResponse, setRedirectResponse } from '@src/redux/authentication';
import AlertModal from '@src/views/inventory/components/inventoryOperations/cycleCount/components/AlertModal/AlertModal';
import { AxiosResponse } from 'axios';
import React, { ReactNode, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

type iAxiosInterceptors = {
  children: ReactNode;
}

type RedirectState = {
  data: {
    redirect: {
      url: string;
    },
    payload: {
      title: string,
      cta: string,
      description: string
    }
  };
}

type AuthState = {
  redirectState: RedirectState;
}

type StoreState = {
  auth: AuthState;
}

const AxiosInterceptors: React.FC<iAxiosInterceptors> = ({ children }) => {
  
  const dispatch = useDispatch();
  const redirectionData = useSelector((store: StoreState) => store.auth.redirectState);

  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleModalAction = () => {
    const formattedRedirectUrl = redirectionData?.data?.redirect?.url ? `/${redirectionData.data.redirect.url}` : '/';
    navigate(formattedRedirectUrl);
    dispatch(clearRedirectResponse());
  };
  
  useEffect(() => {
    const responseInterceptor = axiosInstance.interceptors.response.use(
      (response: AxiosResponse) => {
        if (response.data.status_code === 301) {
          dispatch(setRedirectResponse(response.data));
          // navigate(`/${response.data.data.redirect.url}`)
        }
        return response;
      }
    );

    // Cleanup function to remove interceptors when component unmounts
    return () => {
      axiosInstance.interceptors.response.eject(responseInterceptor);
    };
  }, []);

  return (
    <>
      {children}
      <AlertModal isModalOpen={redirectionData} setIsModalOpen={() => { }} title={t(redirectionData?.data?.payload?.title)} description={redirectionData?.data?.payload?.description} ctaText={redirectionData?.data?.payload?.cta} ctaHandler={handleModalAction} />
    </>
  );
};

export default AxiosInterceptors;
