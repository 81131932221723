import { API_ENDPOINTS, getApiUrl } from '@src/api.urls'
import { axiosInstance } from '@src/network/AxiosInstance'
import { paramsSerializer } from '@src/utility/Utils'

export const API_KEYS = {
  FETCH_DELIVERY_ZONES: 'FETCH_DELIVERY_ZONES',
  CREATE_DELIVERY_ZONE: 'CREATE_DELIVERY_ZONE',
  UPDATE_SINGLE_DELIVERY_ZONE: 'UPDATE_SINGLE_DELIVERY_ZONE',
  GET_COORDINATES: 'GET_COORDINATES',
  UPDATE_DELIVERY_ZONE_PRIORITY_RULES: 'UPDATE_DELIVERY_ZONE_PRIORITY_RULES',
  FETCH_SINGLE_DELIVERY_ZONE: 'FETCH_SINGLE_DELIVERY_ZONE',
  FETCH_ALL_DELIVERY_ZONES: 'FETCH_ALL_DELIVERY_ZONES'
}


export const fetchDeliveryZones = async ({params}) => {
  const {hubId, ...rest} = params
  const apiUrl = getApiUrl(API_ENDPOINTS.ORDERS.DELIVERY_ZONES, { hubId})
  const response = await axiosInstance.get(apiUrl, {params: rest})
  return response
}

export const fetchSingleDeliveryZone = async ({params}) => {
  const {hubId, deliveryZoneId} = params
  const apiUrl = getApiUrl(API_ENDPOINTS.ORDERS.SINGLE_DELIVERY_ZONE, {hubId, deliveryZoneId})
  const response = await axiosInstance.get(apiUrl)
  return response
}

export const createDeliveryZone = async ({params, body}) => {
  const {hubId} = params
  const apiUrl = getApiUrl(API_ENDPOINTS.ORDERS.DELIVERY_ZONES, {hubId})
  const response = await axiosInstance.post(apiUrl, body)
  return response
}

export const getDeliveryZoneCoordinates = async ({params}) => {
  const {latitude, longitude, ...rest} = params
  const apiUrl = getApiUrl(API_ENDPOINTS.ORDERS.GET_DELIVERY_ZONE_COORDINATES, {latitude, longitude})
  const response = await axiosInstance.get(apiUrl, {params: rest})
  return response
}

export const updateDeliveryZonePriorityRules = async ({params, body}) => {
  const {hubId} = params
  const apiUrl = getApiUrl(API_ENDPOINTS.ORDERS.DELIVERY_ZONES, {hubId})
  const response = await axiosInstance.put(apiUrl, body)
  return response
}

export const updateSingleZone = async ({params, body}) => {
  const {hubId, deliveryZoneId} = params
  const apiUrl = getApiUrl(API_ENDPOINTS.ORDERS.SINGLE_DELIVERY_ZONE, {hubId, deliveryZoneId})
  const response = await axiosInstance.put(apiUrl, body)
  return response
}

export const fetchAllDeliveryZones = async ({params}) => {
  const apiUrl = getApiUrl(API_ENDPOINTS.ORDERS.ALL_DELIVERY_ZONES)
  const response = await axiosInstance.get(`${apiUrl}?${paramsSerializer(params)}`)
  return response
}