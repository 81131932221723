
import Button from '@src/@core/components/ui/button';
import React from 'react';
import { AlertTriangle } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { Modal } from 'reactstrap';
import './styles.scss';

type iGtinWarningModalProps = {
  isOpen: boolean,
  handleSkip: () => void,
  handleGoBack: () => void
}
const GtinWarningModal: React.FC<iGtinWarningModalProps> = ({
  isOpen,
  handleSkip,
  handleGoBack
}) => {
  const { t } = useTranslation();
  
  return (
    <Modal
      isOpen={isOpen}
      className='gtin-grn-warning-modal-wrapper w-100'
      centered
    >
      <div className='flex-center-start gap-16px'>
        <div className='p-10px rounded-8px bg-warning-light flex-center-center'>
          <AlertTriangle 
            size={38} 
            color='var(--bs-warning)' 
            fill='var(--bs-warning)'
            strokeWidth={1.5}
            stroke="var(--bs-warning-light)"
          />
        </div>
        <div className='text-dark txt-h1-sb'>
          {t('You Are Scanning a Different SKU')}
        </div>
      </div>
      <div className='text-dark-6 txt-body-rg pb-16px'>
        {t('Scanning a different SKU without assigning bins to the scanned items will result in the loss of progress for the currently scanned items.')}
      </div>
      <div className='flex-center-end w-100 gap-16px'>
        <Button onClick={handleSkip} ofStyle='noBackground'>{t('Continue')}</Button>
        <Button onClick={handleGoBack}>{t('Assign Bin')}</Button>
      </div>
    </Modal>
  );
};

export default GtinWarningModal;