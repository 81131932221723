import { CENTIMETERS_PER_INCH, DELIVERY_AREA_TYPES, DELIVERY_TYPES, RESOLUTION, WAREHOUSE_TYPES_OPTIONS } from '../constants.hubs'
import { Countries } from './countries'

export function convertTo12HourFormat(time) {
  const [hours, minutes] = time.split(':')
  const hoursNum = parseInt(hours, 10)
  const period = hoursNum >= 12 ? 'PM' : 'AM'
  const hours12 = hoursNum % 12 || 12
  return `${hours12}:${minutes} ${period}`
}

export function convertTo24HourFormatFromMilitaryTime(time) {
  if (typeof time !== 'number') {
    throw new Error('Invalid input. Time must be a number.')
  }

  if (time < 0 || time > 2359) {
    throw new Error('Invalid input. Time must be between 0 and 2359.')
  }

  const hours = Math.floor(time / 100)
  const minutes = time % 100
  let formattedHours = hours.toString().padStart(2, '0')
  const formattedMinutes = minutes.toString().padStart(2, '0')

  if (hours === 24) {
    formattedHours = '00'
  }

  return `${formattedHours}:${formattedMinutes}`
}

export const mapHubCreateBasicDetails = (data = { address: {} }) => {
  const countryData = Countries.find(cntry => cntry.calling_code === data.country_calling_code)
  return {
    hubName: data.name,
    warehouseType: WAREHOUSE_TYPES_OPTIONS[data.type],
    hubEmail: data.email,
    contactDetails: {contactNumber: data.phone_number, countryCode: countryData},
    hubCode: data.code,
    currency: {
      label: data.currency?.display_name,
      value: data.currency?.code,
      name: data.currency?.name
    },
    // PosId: data.pos_id,
    // clickCollect: data.is_click_and_collect,
    addressLine1: data.address?.address_line1,
    addressLine2: data.address?.address_line2,
    country: {
      label: data.address?.country?.name,
      value: data.address?.country?.id,
      code: data.address?.country?.code
    },
    state: { label: data.address?.state?.name, value: data.address?.state?.id },
    city: { label: data.address?.city?.name, value: data.address?.city?.id },
    pincode: data.address?.postal_code,
    latitude: data.address?.latitude,
    longitude: data.address?.longitude,
    isPosEnabled: data.pos_id
  }
}

function removeNullUndefinedFields(obj) {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (obj[key] === null || obj[key] === undefined) {
        delete obj[key]
      } else if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
        removeNullUndefinedFields(obj[key])
        if (Object.keys(obj[key]).length === 0) {
          delete obj[key]
        }
      }
    }
  }

  return obj
}

function removeEmptyObjects(obj) {
  for (const key in obj) {
    if (obj.hasOwnProperty(key) && typeof obj[key] === 'object') {
      if (Array.isArray(obj[key])) {
        obj[key].forEach((item) => removeEmptyObjects(item))
      }

      if (Object.keys(obj[key]).length === 0 && key !== 'single_order_tags') {
        delete obj[key]
      } else {
        removeEmptyObjects(obj[key])
      }
    }
  }

  return obj
}

export function removeNullUndefinedAndEmpty(obj) {
  const withoutNullUndefined = removeNullUndefinedFields(obj)
  const withoutEmptyObjects = removeEmptyObjects(withoutNullUndefined)
  return withoutEmptyObjects
}

export function getDeliveryZoneBody(data) {
  const slots = data.slots?.filter(slot => slot.startTime && slot.endTime && slot.orderCapacity)
  return {
    delivery_method: data.deliveryType || undefined,
    delivery_map_method: data.deliveryType === DELIVERY_TYPES.CLICK_COLLECT.type ? undefined : data.deliveryAreaType || undefined,
    delivery_map_details: data.deliveryType === DELIVERY_TYPES.CLICK_COLLECT.type ? undefined : {
      custom: data.deliveryAreaType === DELIVERY_AREA_TYPES.CUSTOM.key || undefined,
      system_generated: data.deliveryAreaType === DELIVERY_AREA_TYPES.SYSTEM_GENERATED.key || undefined,
      estimated_delivery: data.deliveryAreaType === DELIVERY_AREA_TYPES.SYSTEM_GENERATED.key ? +data.estimatedDeliveryTime : undefined,
      aerial_distance: data.deliveryAreaType === DELIVERY_AREA_TYPES.AERIAL.key && +data.aerialDistance ? +data.aerialDistance : undefined,
      city_id: data.deliveryAreaType === DELIVERY_AREA_TYPES.CITY.key && +data.cityId ? +data.cityId : undefined
    },
    area_covered: data.deliveryAreaType === DELIVERY_AREA_TYPES.CUSTOM.key && data.coordinates?.length ? data.coordinates : undefined,
    slots: slots?.length ? slots?.map((slot, i) => ({
      slot_number: +i + 1,
      start_time: +slot.startTime.split(':').join(''),
      end_time: +slot.endTime.split(':').join(''),
      order_slots: +slot.orderCapacity
    })) : undefined
    //   [
    //     {
    //         slot_number: 1,
    //         start_time: 30,
    //         end_time: 200,
    //         order_slots: 100
    //     },
    //     {
    //         slot_number: 2,
    //         start_time: 200,
    //         end_time: 300,
    //         order_slots: 200
    //     }
    // ]
  }
}

export function calculatePrintPageWidth(barcodes, fontSize) {
  // Find the longest barcode length
  const longestBarcodeLength = barcodes.reduce((maxLength, barcode) => {
    const barcodeLengthWithoutHyphens = barcode.barcode.replace(/-/g, '').length
    return Math.max(maxLength, barcodeLengthWithoutHyphens)
  }, 0)
  // Calculate the required width based on character count and desired character width
  const characterWidth = fontSize * 0.8// Replace with your desired character width (e.g., 8 for typical barcode fonts)
  const pageWidth = (longestBarcodeLength < 9 ? 9 : longestBarcodeLength) * characterWidth

  return parseInt(pageWidth)
}

export function cmToPixels(widthCm) {
  /*Converts a width in centimeters to pixels based on resolution and centimeters per inch.
  Args:
      widthCm: The width in centimeters.

  Returns:
      The width in pixels (rounded down to nearest integer).
  */
  const widthInches = +widthCm / CENTIMETERS_PER_INCH
  return Math.floor(widthInches * RESOLUTION)
}

export function pixelsToCm(widthPx) {
  /*Converts a width in pixels to centimeters based on resolution and centimeters per inch.

  Args:
      widthPx: The width in pixels.

  Returns:
      The width in centimeters.
  */
  const widthInches = +widthPx / RESOLUTION
  return parseInt(widthInches * CENTIMETERS_PER_INCH)
}

