import ComponentSpinner from '@src/@core/components/ui/spinner/Loading-spinner'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SHIPPING_ENTITY, SHIPPING_OPTION } from '../constant/orders.constants'
import EditCustomerAndShippingDetailsModal from '../pending-actions/components/EditCustomerAndShippingDetailsModal/EditCustomerAndShippingDetailsModal'
import { EDIT_MODAL_TYPE } from '../sales.constant'
import {
  clearShipmentDetailData,
  clearShippingDetailData,
  getShippingDetails
} from '../store/store'
import CreateShipmentComponents from './CreateShipmentComponents'
import OrderDetails from './OrderDetails'
import CreateShipmentWithSalesChannel from './createShipmentWithSalesChannel'
import ShipmentNotCreated from './shipmentNotCreated'

const CreateShipment = ({ orderID, sellerSalesChannelId, handleEditDetailsModal, isEditDetailsModalOpen, setIsEditDetailsModalOpen }) => {
  const dispatch = useDispatch()
  const loading = useSelector((store) => store.sales.loading)
  const shippingDetailsData = useSelector((store) => store.sales.shippingDetailsData)
  const shipmentDetails = useSelector((store) => store.sales.shipmentDetails)
  const [omnifulGeneratedShipmentType, setOmnifulGeneratedShipmentType] = useState()
  const [isShipmentCreated, setIsShipmentCreated] = useState(false)
  
  useEffect(() => {
    if (!shippingDetailsData) {
      dispatch(getShippingDetails({params: {entity_id: orderID, entity_name: SHIPPING_ENTITY.FORWARD_ORDER.value}}))
    }
    return () => dispatch(clearShippingDetailData())
  }, [])

  useEffect(() => {
    if (shippingDetailsData) {
      if (shippingDetailsData.shipping_options && shippingDetailsData.shipping_options?.length > 0) {
        setOmnifulGeneratedShipmentType(shippingDetailsData.shipping_options[0].key)
      } else {
        setOmnifulGeneratedShipmentType(false)
      }
    }
    return () => dispatch(clearShipmentDetailData())
  }, [shippingDetailsData])

  useEffect(() => {
    if (shipmentDetails) {
      setIsShipmentCreated(true)
    }
  }, [shipmentDetails])

  const renderCreateShipment = (activeComponent) => {
    switch (activeComponent) {
    case SHIPPING_OPTION.SALES_CHANNEL.name:
      return <>
        <OrderDetails setIsShipmentCreated={setIsShipmentCreated} isShipmentCreated={isShipmentCreated} handleEditDetailsModal={handleEditDetailsModal} />
        <CreateShipmentWithSalesChannel orderID={orderID} shippingDetailsData={shippingDetailsData} handleEditDetailsModal={handleEditDetailsModal} />
      </>
    case SHIPPING_OPTION.SHIPPING_PARTNER.name:
    case SHIPPING_OPTION.MANUAL_DISPATCH.name:
    case SHIPPING_OPTION.SALES_CHANNEL_NOTIFIED.name:
      return <>
        <OrderDetails setIsShipmentCreated={setIsShipmentCreated} isShipmentCreated={isShipmentCreated} handleEditDetailsModal={handleEditDetailsModal} />
        <CreateShipmentComponents
          orderID={orderID}
          shippingDetailsData={shippingDetailsData}
          sellerSalesChannelId={sellerSalesChannelId}
          omnifulGeneratedShipmentType={omnifulGeneratedShipmentType}
          setOmnifulGeneratedShipmentType={setOmnifulGeneratedShipmentType}
          handleEditDetailsModal={handleEditDetailsModal}
        />
      </>
    default:
      return <div className="h-100 d-flex flex-column justify-content-center"><ShipmentNotCreated orderID={orderID} /></div>
    }
  }
  return (
    <>
      {(loading.getShippingDetails || (!omnifulGeneratedShipmentType && shippingDetailsData)) ?
        <ComponentSpinner className='mt-5' /> : renderCreateShipment(omnifulGeneratedShipmentType)
      }

      {shippingDetailsData && isEditDetailsModalOpen.open &&  <EditCustomerAndShippingDetailsModal isModalOpen={isEditDetailsModalOpen} setIsModalOpen={setIsEditDetailsModalOpen} order_id={orderID} editDetails={isEditDetailsModalOpen.key === EDIT_MODAL_TYPE.CUSTOMER_DETAILS.key ? shippingDetailsData.entity_data.customer : { ...shippingDetailsData.entity_data.shipping_address, zip: shippingDetailsData.entity_data.shipping_address.postal_code, address1: shippingDetailsData.entity_data.shipping_address.address_line_1, address2: shippingDetailsData.entity_data.shipping_address.address_line_2 }} />}
    </>
  )
}

export default CreateShipment
