import EditIcon from '@src/assets/images/icons/EditIcon'
import { CheckCircle, Plus, Slash } from 'react-feather'

export const CC_SKU_EXPORT_INCLUDE_KEYS = {
  ALL: 'all',
  DISCREPANCIES: 'discrepancies',
  NON_DISCREPANCIES: 'non_discrepancies'
}

export const INVENTORY_CONSTANTS = {
  GRN: {
    VIEW: {
      id: 1,
      name: 'view',
      ITEM_DETAILS: { id: 1, name: 'item-details' },
      QC_DETAILS: { id: 2, name: 'qc-details' },
      ADD_BATCH: { id: 3, name: 'add-batch' },
      SCAN_EXISTING: { id: 4, name: 'scan-existing-bins' }
    },
    SERIALISED_OPTION: {
      id: 2,
      name: 'serialised-option',
      SERIALISED: { id: 1, name: 'serialised' },
      NON_SERIALISED: { id: 2, name: 'non-serialised' }
    },
    SCAN_OPTION: {
      id: 3,
      name: 'scan-option'
    }
  }
}
// GATE ENTRY
export const GATE_ENTRY_CREATE_TYPES = {
  PO_ENABLED: 'Purchase Order',
  PO_DISABLED: 'Purchase Order Disabled'
}
export const GATE_ENTRY_CREATE_VIEW = {
  CARTON_DETAILS: 'Carton Details',
  PRINT_BARCODES: 'PRINT_BARCODES'
}
//GRN
export const GRN_VIEW = {
  ITEM_DETAILS: 'item-details',
  QC_DETAILS: 'qc-details',
  ADD_BATCH: 'add-batch',
  SCAN_EXISTING: 'scan-existing-bins'
}
export const GRN_QC_TYPES = {
  INDIVIDUAL: 'Individual',
  BULK: 'Bulk'
}
export const CREATE_QC_BIN_ID_MODE = {
  PRINT: 'Print',
  SCAN: 'Scan'
}
export const QC_STATUS = {
  PASS: 'pass',
  FAIL: 'fail'
}
// GRN Dated: 29th April
export const GRN_STEPS = {
  IDENTIFICATION: 'Identification',
  PRINT_BARCODES: 'Print Barcode',
  QUALITY_CHECK: 'Quality Check'
}
export const GRN_BIN = {
  PASS: 'pass',
  FAIL: 'fail'
}
export const HUB_SKU_SERIALIZATION_STATUS = {
  SERIALISED: 'serialized',
  NON_SERIALISED: 'non-serialized',
  SCAN: 'scan'
}

export const QC_FAIL_REASON = [
  { id: 1, value: 'Damaged item', label: 'Damaged item' },
  { id: 2, value: 'Expired Product', label: 'Expired Product' },
  { id: 3, value: 'Packaging Damaged', label: 'Packaging Damaged' },
  { id: 4, value: 'Unidentified object is inside packaging', label: 'Unidentified object is inside packaging' }
]
export const SERIALISATION_CONFIG = {
  SERIALISED: 'serialised',
  NON_SERIALISED: 'non_serialised',
  UNDEFINED: 'undefined'
}
export const BATCH_CONFIG = {
  IN_BATCH: 'batched',
  NOT_IN_BATCH: 'not_batched',
  UNDEFINED: 'undefined'
}
export const GRN_ITEMS_VIEW_TABS = isPutawayVisible => ({
  OVERVIEW: 'Overview',
  PUTAWAY_DETAILS: isPutawayVisible ? 'Putaway Details' : undefined
})
// PUTAWAY
export const PUTAWAY_TYPES = {
  ITEM_PUTAWAY: 'Item Putaway',
  BIN_PUTAWAY: 'Bin Putaway'
}
export const INVENTORY_ROUTES = {
  TOTAL_INVENTORY: '/inventory/inventory-reports/total-inventory',
  LOCATION_INVENTORY: '/inventory/inventory-reports/location-inventory',
  SKU_LOCATIONS: '/inventory/inventory-reports/sku-locations',
  BATCHES: '/inventory/inventory-reports/batches',
  GATE_ENTRY: '/inventory/inventory-operations/gate-entry',
  GRN: '/inventory/inventory-operations/grn',
  PUTAWAY: '/inventory/inventory-operations/putaway',
  PENDING_PUTAWAY: '/inventory/inventory-operations/pending-putaway',
  CYCLE_COUNT: '/inventory/inventory-operations/cycle-count',
  CYCLE_COUNT_DETAILS: '/inventory/inventory-operations/cycle-count/cycle-count-details',
  ASSEMBLY: '/inventory/inventory-operations/assembly',
  DEMAND_FORECASTING: '/inventory/inventory-reports/demand-forecasting',
  AD_HOC_USAGE: '/inventory/inventory-operations/ad-hoc-usage',
  STOCK_TRANSFER: '/inventory/stock-transfer'
}

export const INVENTORY_ENUMS = {
  BULK_UPLOAD_SKUS: 'BulkUploadSKUs',
  SKUS_MAPPING: 'SkusMapping'
}

export const BARCODE_PRINTING = {
  BIN: 'Bin Barcode',
  SKU: 'SKU'
}

export const PENDING_PUTAWAY_TABS = {
  CANCELLED_ITEM_PUTAWAY: { id: 1, name: 'Cancelled Item Putaway', title: 'Pending Putaway' },
  GRN_PENDING_PUTAWAY: { id: 2, name: 'GRN Pending Putaway', title: 'Pending Putaway' },
  ADJUST_INVENTORY: { id: 3, name: 'Adjust Inventory', title: 'Pending Putaway' }
}

export const BIN_TYPE_FILTER = {
  ALL: { id: 1, name: 'All' },
  PASS_BIN: { id: 2, name: 'Pass Bin' },
  FAIL_BIN: { id: 3, name: 'Fail Bin' }
}

export const INVENTORY_SAMPLE_SHEETS = {
  BULK_UPLOAD_SKUS_INVENTORY: 'https://omniful-staging-uploads.s3.eu-central-1.amazonaws.com/wms/hub_inventory/hub_inventory_sample.csv',
  SKUS_LOCATION_MAPPING: 'https://omniful-uploads-dev.s3.eu-central-1.amazonaws.com/tenant-onboarding/Location+Sku+Mapping+Sample+Sheet+-+Sheet1+(1).csv',
  BATCH_UPLOAD: 'https://omniful-staging-uploads.s3.eu-central-1.amazonaws.com/wms/batch/Upload+Batch+-+Sheet1+(1).csv',
  BINS_BULK_UPLOAD: 'https://omniful-staging-uploads.s3.eu-central-1.amazonaws.com/wms/bins/Sample+Bin+Upload+Sheet+-+Sheet1.csv',
  SAFETY_STOCK_BULK_UPLOAD: 'https://omniful-staging-uploads.s3.eu-central-1.amazonaws.com/wms/hub_inventory/Safety+Stock+Update+-+Sheet1.csv',
  CYCLE_COUNT_BULK_UPLOAD: 'https://omniful-production-uploads.s3.eu-central-1.amazonaws.com/wms/cycle_count/cycle_count_locations.csv',
  PALLETS_BULK_UPLOAD: 'https://omniful-production-uploads.s3.eu-central-1.amazonaws.com/wms/bins/bulk/Pallet+Upload.csv',
  CUSTOMER_BULK_UPLOAD: 'https://omniful-staging-uploads.s3.eu-central-1.amazonaws.com/orders/customers/sampleBulkCustomer.csv',
  FOERCAST_BULK_UPLOAD: 'https://omniful-production-uploads.s3.eu-central-1.amazonaws.com/analytics/sample/demand_forecasting_sample.csv',
  REORDER_POINT: 'https://omniful-staging-uploads.s3.eu-central-1.amazonaws.com/wms/hub_inventory/reorder+point.csv',
  BULK_UPLOAD_EXPIRY_THRESHOLD: 'https://omniful-staging-uploads.s3.eu-central-1.amazonaws.com/wms/hub_inventory/near+expiry+threshold.csv',
  BULK_UPLOAD_PO: 'https://omniful-staging-uploads.s3.eu-central-1.amazonaws.com/wms/purchase_order/bulk/Multiple+PO+Creation.csv',
  UPLOAD_MAX_SHELF_LIFE:'https://omniful-uploads-dev.s3.eu-central-1.amazonaws.com/tenant-onboarding/max_shelf_life+(1).csv'
}

export const SKU_MASTER_ENUMS = {
  ADD_VARIATION_DETAIL: 'AddVariationDetail',
  SEARCH_PRODUCT_ADD_DETAIL: 'SearchProductAddDetail',
  SHOW_UPLOAD_PRODUCT: 'ShowUploadProduct',
  SHOW_CREATE_BUNDLE: 'ShowCreateBundle',
  SHOW_BATCH_UPLOAD: 'ShowBatchUpload',
  SHOW_UPLOAD_EXPIRY_THRESHOLD: 'ShowUploadExpiryThreshold',
  SHOW_UPLOAD_MAX_SHELF_LIFE: 'ShowUploadMaxShelfLife',
}

export const GRN_TYPES = {
  STANDARD: 'standard',
  RETURN: 'return',
  BIN_DISABLED:'bin_disabled'
}

export const CYCLE_COUNT_DISCREPANCIES = {
  INVENTORY_MISMATCH: 'Inventory Mismatch',
  BIN_MISSING: 'Bin Missing'
}

export const ACTION_TYPES = {
  INDIVIDUAL: 'Individual',
  BULK: 'Bulk'
}

export const CYCLE_COUNT_LOCATION_STATUS = {
  PENDING: 'Pending',
  IN_PROGRESS: 'In Progress',
  COUNTING: 'Counting',
  COUNTED: 'Counted',
  INVENTORY_UPDATE: 'Inventory Update',
  RECOUNT_PENDING: 'Recount Pending',
  RECOUNT_IN_PROGRESS: 'Recount In Progress',
  RECOUNT_COUNTED: 'Recount Counted',
  REJECTED: 'Rejected',
  EXPIRED: 'Expired',
  COMPLETED: 'Completed',
  CANCELLED:  'Cancelled'
}

export const ENTITY_TYPES = {
  PO: 'po',
  PO_SHIPMENT: 'po_shipment',
  STO: 'sto'
}

export const SKU_LOCATION_TYPE = {
  IMPORT_AND_ADD_SKUS: 'Add SKUs',
  IMPORT_AND_REMOVE_SKUS: 'Remove SKUs'
}

export const BULK_ACTIONS = {
  ADD: 'add',
  REMOVE: 'remove'
}

export const CYCLE_COUNT_LOCATIONS_LIMIT = 1000

export const CYCLE_COUNT_LOCATION_OPERATIONS = {
  UPDATE: 'update',
  DELETE: 'delete',
  RECOUNT: 'recount',
  REJECTED: 'rejected'
}

export const GRN_PUTAWAY_DETAILS_TYPE = [
  { value: 'item_putaway', label: 'Item Putaway'},
  { value: 'bin_putaway', label: 'Bin Putaway'}
]

export const SKU_TYPE = {
  EACH: { label: 'Each', value: 'ea' },
  CASE_PACK: { label: 'Case Pack', value: 'case_pack' },
  PALLET: { label: 'Pallet', value: 'pallet' }
}

export const GRN_CONTAINER_TYPES = {
  BIN: 'bin',
  PALLET: 'pallet'
}
export const GRN_CODE_TYPE = {
  BARCODE: 'barcode',
  QR_CODE: 'qr-code'
}

export const LIVE_ORDER_TYPES = {
  STO: 'STO',
  B2C: 'B2C'
}

export const DATE_FILTER_KEY = {
  CREATED: 'created'
}

export const STOCK_TRANSFER_STATUS = {
  ORDERED: 'Ordered',
  ARRIVED: 'Arrived',
  PARTIAL_RECEIVED: 'Partially Recieved',
  RECEIVED: 'Recieved',
  CLOSED: 'Closed',
  ARRIVING: 'Arriving',
  CANCELLED: 'Cancelled'
}

export const STOCK_TRANSFER_LOGS_ACTIONS = {
  CREATE: {value: 'create', id: 1, icon: Plus},
  EDIT: {value: 'edit', id: 2, icon: EditIcon},
  ACCEPT: {value: 'recieved', id: 3, icon: CheckCircle},
  CLOSE: {value: 'closed', id: 4, icon: Slash}
}

export const GE_OPTIONS = {
  PO: 'po',
  STO: 'sto',
  SELLER: 'seller'
}

export const GET_SELECTED_SELLER = (setValue, sellerData, selectedSeller, firstTimeSelectedSeller) => {
  const getseller = sellerData ? { label: sellerData.label, value: sellerData.value } : selectedSeller || firstTimeSelectedSeller 
  setValue('seller', getseller)  
}