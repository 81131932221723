import BarcodeScanner from '@src/@core/components/ui/barcodeScanner'
import InputField from '@src/@core/components/ui/input-field'
import ToggleTabs from '@src/@core/components/ui/toggleTabs'
import { KEYBOARD_KEYS } from '@src/App.constants'
import {
  clearGrnFailBinId,
  clearGrnPassBinId
} from '@src/views/inventory/store'
import { SEARCH_RETURN_ORDER_TYPES } from '@src/views/returns/returns.constants'
import {
  clearCreateReturnGrnResponse,
  clearSingleReturnOrderDetail,
  createReturnGrn,
  processNextReturnOrder
} from '@src/views/returns/store'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

const ReturnGrnWithGateEntry = ({ control, errors, watch, setValue, reset, register, isNextOrderProcess }) => {

  const dispatch = useDispatch()
  const [selectedTabId, setSelectedTabId] = useState(SEARCH_RETURN_ORDER_TYPES.AWB_NUMBER.id)
  const configurations = useSelector(store => store.auth.selectedHubConfiguration)
  const isBinDisabled = !configurations?.find(item => item.configuration_type === 'bin')?.configuration_values?.enabled
  const createReturnGRNResError = useSelector(state => state.returns.error.createReturnGrn)
  const createRturnGRNresLoading = useSelector(state => state.returns.loading.createReturnGrn)
  const processNextReturnOrderresLoading = useSelector(state => state.returns.loading.processNextReturnOrderresLoading)
  const returnGrnResponse = useSelector((state) => state.returns.createReturnGrnResponse?.data)

  const tabChangeHandler = (tab) => {
    if (!isBinDisabled) {
      setSelectedTabId(tab.id)
      dispatch(clearSingleReturnOrderDetail())
      
      setValue('search_barcode', '')
      setValue('return_order_id', '')
  
      reset()
    } else {
      setSelectedTabId(tab.id)
      setValue('search_barcode', '')
      setValue('return_order_id', '')
  
      reset()
    }
  }

 
  const handleReturnGRNinit = (e) => {
    if (e.key === KEYBOARD_KEYS.ENTER) {
      if (isNextOrderProcess) {
        const params = {}
        if (selectedTabId === SEARCH_RETURN_ORDER_TYPES.AWB_NUMBER.id) {
          params.awb_number = watch('awb_number')?.trim()
        } else {
          params.return_order_id = watch('return_order_id')?.trim()
        }
        dispatch(processNextReturnOrder({ grn_id: returnGrnResponse.id, params }))
        return
      }
      
      if (watch('awb_number') || watch('return_order_id')) {
        const body = {
          awb_number: watch('awb_number')?.trim(),
          return_order_id: watch('return_order_id')?.trim(),
          errorConfig: {
            audioRequired: watch('awb_number')? true : false
          }
        }
        dispatch(clearCreateReturnGrnResponse())
        dispatch(clearGrnPassBinId())
        dispatch(clearGrnFailBinId())
        setValue('pass_bin_barcode', '')
        setValue('fail_bin_barcode', '')
        dispatch(createReturnGrn(body))
      } else {
        // dispatch(clearCreateReturnGrnResponse())
      }
    }
  }

  const handleClearReturnGRN = () => {
    setValue('pass_bin_barcode', '')
    setValue('fail_bin_barcode', '')
    setValue('awb_number', '')
    setValue('return_order_id', '')
  }

  useEffect(() => {
    if ((!watch('awb_number') || !watch('return_order_id')) && !isNextOrderProcess) {
      dispatch(clearCreateReturnGrnResponse())
      dispatch(clearGrnPassBinId())
      dispatch(clearGrnFailBinId())
      setValue('pass_bin_barcode', '')
      setValue('fail_bin_barcode', '')
    }
  }, [watch('awb_number'), watch('return_order_id')])

  useEffect(() => {
    if (createReturnGRNResError) {
      setValue('awb_number', '')
      setValue('return_order_id', '')
    }
  }, [createReturnGRNResError])

  return (
    <div className="d-flex flex-column gap-16px">
      <ToggleTabs
        tabDataList={Object.values(SEARCH_RETURN_ORDER_TYPES)}
        selectedTabId={selectedTabId}
        onTabChange={tabChangeHandler}
      />
      {SEARCH_RETURN_ORDER_TYPES.AWB_NUMBER.id === selectedTabId ? (
        <BarcodeScanner
          width="380px"
          key="awb_number"
          startIcon={
            <img
              src="https://cdn-icons-png.flaticon.com/128/1550/1550324.png"
              alt="QR Code"
              width="16px"
              height="16px"
            />
          }
          placeholder="Scan AWB Number"
          control={control}
          loading={createRturnGRNresLoading || processNextReturnOrderresLoading}
          name="awb_number"
          onKeyDown={handleReturnGRNinit}
          hasValue={watch('awb_number')}
          handleClear={handleClearReturnGRN}
        />
      ) : (
        <InputField
          errors={errors}
          {...register('return_order_id')}
          name='return_order_id'
          isClearable
          label="Return Order ID"
          // isRequired
          width="100%"
          onKeyDown={handleReturnGRNinit}
          handleClear={handleClearReturnGRN}
        />
      )}
    </div>
  )
}

export default ReturnGrnWithGateEntry
