import { X } from 'react-feather'
import PropTypes from 'prop-types'

const SideSheetCloseButton = (props) => {
  const { clearButtonHandler } = props
  return (
    <button onClick={clearButtonHandler} className='bg-white d-flex align-items-center justify-content-center border-0 rounded-1 p-4px height-fit-content width-fit-content'>
      <X size={16} />
    </button>
  )
}

SideSheetCloseButton.propTypes = {
  clearButtonHandler: PropTypes.func.isRequired
}

export default SideSheetCloseButton