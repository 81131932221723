import RowExpanderWrapper from '@src/@core/components/ui/row-expander-wrapper'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ExpandRow from './ExpandRow'
import './skuExpandStyles.scss'

const SkuExpander = ({ data }) => {

  const [nestedSkus, setNestedSkus] = useState([])
  const [visibleSkus, setVisibleSkus] = useState([])
  const { t } = useTranslation()

  const flattenObjIntoArr = (obj, resArr) => {
    if (!obj) return resArr

    const currLevel = { ...obj }
    delete currLevel.child_skus

    return flattenObjIntoArr(obj.child_skus, [...resArr, currLevel])
  }

  useEffect(() => {
    const skus = flattenObjIntoArr(data.child_skus, [])
    setNestedSkus(skus)
    if (skus?.[0]) setVisibleSkus(data.child_skus)
  }, [])

  return (
    <RowExpanderWrapper data={data}>
      <div className='bulk-ship-expand-table-main'>
        <table>
          <thead>
            <tr className='txt-sub-md text-dark'>
              <th className='txt-body-md'>{t('SKU Details')}</th>
              <th className='txt-body-md text-end'>{t('Qty Per Kit')}</th>
              <th className='txt-body-md text-end'>{t('Qty Per Order')}</th>
              <th className='txt-body-md text-end'>{t('Total Qty To Be Picked')}</th>
            </tr>
          </thead>
          <tbody>
            {visibleSkus.map((el, idx) => <ExpandRow key={idx} data={el} currIndex={idx} nestedSkus={nestedSkus} visibleSkus={visibleSkus} setVisibleSkus={setVisibleSkus} />)}
          </tbody>
        </table>
      </div>
    </RowExpanderWrapper>
  )
}

export default SkuExpander