import Button from '@src/@core/components/ui/button'
import SidesheetFooter from '@src/@core/components/ui/sidesheet-footer'
import ComponentSpinner from '@src/@core/components/ui/spinner/Loading-spinner'
import { PACKAGE_SKU_TYPE } from '@src/App.constants'
import Vector from '@src/assets/images/catalog/bundlesku/Vector.svg'
import { uploadFileOnS3 } from '@src/redux/authentication'
import { isObjEmpty } from '@src/utility/Utils'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Spinner } from 'reactstrap'
import {
  CATALOG_FLOATING_YES_AND_NO_OPTIONS,
  CATALOG_UNIT_OF_MEASUREMENT,
  MAX_BUNDLE_IMAGE_CAN_UPLOAD,
  SIMPLE,
  SIMPLE_SKU_DEFAULT_VALUE,
  SIMPLE_SKU_FIELDS_NAME,
  SINGLE_SKU_UPDATE_TYPE
} from '../../catalog.constants'
import {
  cleanupCreateBundleSkuResponse,
  cleanupSingleSku,
  cleanupUpdateBundleSku,
  createBundleSku,
  getSkuMaster,
  handleIsRowHighlighted,
  setBundleSkus,
  updateBundleSkus
} from '../../store'
import SkuImageHandler from '../SkuImageHandler'
import SingleSkuDetailField from './SingleSkuDetailField'
import SingleSkuMetaData from './SingleSkuMetaData'
import './styles.scss'
let imageGettingUpload = false

const SingleSkuCreate = ({
  setIsModalOpen,
  selectedSeller: initialSeller,
  isEdit,
  setIsEdit,
  setDataTodEdit,
  dataToEdit,
  setSearchParams,
  register,
  watch,
  reset,
  control,
  errors,
  handleSubmit,
  setValue,
  resetRef,
  clearErrors,
  filter,
  setFilter,
  getValues,
  productConfiguration
}) => {
  const [uploadedImages, setUploadedImages] = useState([{ value: '' }])
  const [barcodeData, setBarcodeData] = useState([])
  const [currentSelectedImage, setCurrentSelectedImage] = useState(0)
  const updateBundleSkusRes = useSelector(
    (state) => state.catalog.updateBundleSku
  )
  const imageRef = useRef(null)
  const addImageRef = useRef(null)
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { is_success, loading: bundleCreationLoading } = useSelector(
    (state) => state.catalog.createBundleSkuResponse
  )
  const tenantType = useSelector((state) => state.auth.userData.tenant.type)
  const selectedSeller = watch(SIMPLE_SKU_FIELDS_NAME.SELLER)
  const singleSkuDetail = useSelector((state) => state.catalog.sku)
  const { getSku: singleSkuLoading } = useSelector(
    (state) => state.catalog.loading
  )

  // for pos
  const isPosEnabled = useSelector(store => store.auth.userData?.user_plan?.is_pos_enabled)

  const onModalClosed = () => {
    reset(SIMPLE_SKU_DEFAULT_VALUE)
    setUploadedImages([{ value: '' }])
    setBarcodeData([])
    dispatch(setBundleSkus([]))
    if (isEdit) {
      setIsEdit(false)
    }
    setDataTodEdit(null)
    dispatch(cleanupSingleSku())
  }

  resetRef.current = onModalClosed


  const submitHandler = async (data) => {
    imageGettingUpload = true
    const filterExistsImages = uploadedImages.filter((ele) => {
      if (ele.default) {
        return ele
      }
    })
    const storeExistImage = filterExistsImages.map((ele) => {
      return ele.default
    })
    let imagesResolved = await Promise.all(uploadedImages.filter(el => {
      if (!el.default && el.value) {
        return el.value
      }
    }).map(img => {
      return uploadFileOnS3({
        service: 'product',
        usecase: 'simpleSkuImage',
        file: img.file
      })
    }))
    imageGettingUpload = false
    imagesResolved = [...storeExistImage, ...imagesResolved]
    const collectImage = imagesResolved.map((uploadId) => ({
      default: uploadId
    }))
    const body = {
      // ...data,
      seller: data[SIMPLE_SKU_FIELDS_NAME.SELLER],
      name: data[SIMPLE_SKU_FIELDS_NAME.SKU_NAME],
      description: data[SIMPLE_SKU_FIELDS_NAME.DESCRIPTION],
      labels: data[SIMPLE_SKU_FIELDS_NAME.LABELS]?.map((option) => ({
        id: option.value,
        name: option.label
      })) || [],
      images: collectImage,
      dimensions: {
        length: {
          length: Number(data[SIMPLE_SKU_FIELDS_NAME.SKU_LENGTH]),
          unit: 'cm'
        },
        breadth: {
          breadth: Number(data[SIMPLE_SKU_FIELDS_NAME.SKU_WIDTH]),
          unit: 'cm'
        },
        height: {
          height: Number(data[SIMPLE_SKU_FIELDS_NAME.SKU_HEIGHT]),
          unit: 'cm'
        }
      },
      package_level_skus: data[SIMPLE_SKU_FIELDS_NAME.PACKAGE_TYPE]?.value === PACKAGE_SKU_TYPE.CASE_PACK.value || data[SIMPLE_SKU_FIELDS_NAME.PACKAGE_TYPE]?.value === PACKAGE_SKU_TYPE.PALLET.value ? [
        {
          seller_sku_code: data[SIMPLE_SKU_FIELDS_NAME.PACKAGE_TYPE_SKU]?.sku_code,
          quantity: parseInt(data[SIMPLE_SKU_FIELDS_NAME.PACKAGE_TYPE_SKU_QTY])
        }
      ] : undefined,
      package_type: data[SIMPLE_SKU_FIELDS_NAME.PACKAGE_TYPE]?.value,
      type: SIMPLE,
      status: 'live',
      barcodes: barcodeData.map((ele) => ele.barcode),
      weight: {
        value: Number(data[SIMPLE_SKU_FIELDS_NAME.SKU_WEIGHT]),
        uom: data[SIMPLE_SKU_FIELDS_NAME.WEIGHT_UOM].value
      },
      sellerId: data.seller?.value || initialSeller.value,
      selling_price: Number(data[SIMPLE_SKU_FIELDS_NAME.SELLING_PRICE]),
      retail_price: Number(data[SIMPLE_SKU_FIELDS_NAME.RETAIL_PRICE]),
      uom: data[SIMPLE_SKU_FIELDS_NAME.PACKAGE_TYPE]?.value === PACKAGE_SKU_TYPE.UNIT.value ? data[SIMPLE_SKU_FIELDS_NAME.MEASUREMENT_UNIT]?.value : 'ea',
      is_perishable: data[SIMPLE_SKU_FIELDS_NAME.PERISHABLE]?.value || null,
      seller_sku_code: data[SIMPLE_SKU_FIELDS_NAME.SKU_CODE].trim(),
      hs_code: data[SIMPLE_SKU_FIELDS_NAME.HS_CODE],
      country_of_origin: data[SIMPLE_SKU_FIELDS_NAME.COUNTRY_OF_ORIGIN],
      manufacturer: data[SIMPLE_SKU_FIELDS_NAME.MANUFACTURER],
      brand: data[SIMPLE_SKU_FIELDS_NAME.BRAND],
      tax_inclusive: {
        selling_price:
          data[SIMPLE_SKU_FIELDS_NAME.SELLING_PRICE_INCLUDES_TAX].value
      },
      tax_percentage: {
        selling_price: Number(data[SIMPLE_SKU_FIELDS_NAME.TAX_PERCENTAGE])
      },
      tax_category_id: data[SIMPLE_SKU_FIELDS_NAME.TAX_CATEGORY]?.value || null,
      currency: data[SIMPLE_SKU_FIELDS_NAME.PRICE_UNIT].value,
      creation_type: 'SKU',
      gtin: data[SIMPLE_SKU_FIELDS_NAME.GTIN],
      is_gtin_picking_enabled: data[SIMPLE_SKU_FIELDS_NAME.IS_GTIN_PICKING_ENABLED].value,
      wholesale_price: Number(data[SIMPLE_SKU_FIELDS_NAME.WHOLESALE_PRICE])
    }
    isEdit
      ? dispatch(
        updateBundleSkus({
          seller_id: singleSkuDetail.seller_id,
          sku_id: dataToEdit.id,
          body,
          update_type: { update_type: SINGLE_SKU_UPDATE_TYPE },
          isPosEnabled
        })
      )
      : dispatch(createBundleSku({ body, isPosEnabled }))
  }

  useEffect(() => {
    if (is_success) {
      const data = watch(SIMPLE_SKU_FIELDS_NAME.SELLER)
      setIsModalOpen(false)
      dispatch(cleanupCreateBundleSkuResponse())
      reset(SIMPLE_SKU_DEFAULT_VALUE)
      dispatch(setBundleSkus([]))
      setUploadedImages([{ value: '' }])
      dispatch(getSkuMaster({ type: SIMPLE, seller_id: data.value, isPosEnabled }))
      setBarcodeData([])
      setSearchParams({})
      if (data?.value) {
        setFilter({ ...filter, seller_id: data })
      }
      dispatch(handleIsRowHighlighted({ sku: true }))
    }
  }, [is_success])

  useEffect(() => {
    if (updateBundleSkusRes.is_success) {
      setIsModalOpen(false)
      dispatch(cleanupCreateBundleSkuResponse())
      reset(SIMPLE_SKU_DEFAULT_VALUE)
      dispatch(setBundleSkus([]))
      setUploadedImages([{ value: '' }])
      dispatch(getSkuMaster({ type: SIMPLE, isPosEnabled }))
      if (isEdit) {
        setIsEdit(false)
      }
      setBarcodeData([])
      setSearchParams({})
      setDataTodEdit(null)
      dispatch(cleanupUpdateBundleSku())
      dispatch(cleanupSingleSku())
      dispatch(handleIsRowHighlighted({ sku: true }))
    }
  }, [updateBundleSkusRes.is_success])

  useEffect(() => {
    if (singleSkuDetail?.id && isEdit) {
      reset(singleSkuDetail)
      dispatch(setBundleSkus(singleSkuDetail.child_skus))
      const collectImages =
        singleSkuDetail.images?.map((ele) => {
          return { ...ele, value: ele.default }
        }) || []
      if (collectImages.length < MAX_BUNDLE_IMAGE_CAN_UPLOAD) {
        collectImages.push({ value: '' })
      }
      setUploadedImages(collectImages)
      setValue(SIMPLE_SKU_FIELDS_NAME.SELLER, selectedSeller)
      setValue(
        SIMPLE_SKU_FIELDS_NAME.RETAIL_PRICE,
        singleSkuDetail.retail_price || 0
      )
      setValue(
        SIMPLE_SKU_FIELDS_NAME.SELLING_PRICE,
        singleSkuDetail.selling_price || 0
      )
      setValue(
        SIMPLE_SKU_FIELDS_NAME.TAX_PERCENTAGE,
        singleSkuDetail.tax_percentage?.selling_price || 0
      )
      setValue(
        SIMPLE_SKU_FIELDS_NAME.SKU_HEIGHT,
        singleSkuDetail.dimensions.height?.height || ''
      )
      setValue(
        SIMPLE_SKU_FIELDS_NAME.SKU_WIDTH,
        singleSkuDetail.dimensions.breadth?.breadth || ''
      )
      setValue(
        SIMPLE_SKU_FIELDS_NAME.SKU_LENGTH,
        singleSkuDetail.dimensions.length?.length || ''
      )
      setValue(
        SIMPLE_SKU_FIELDS_NAME.SKU_WEIGHT,
        singleSkuDetail.weight?.value || ''
      )
      setValue(SIMPLE_SKU_FIELDS_NAME.BRAND, singleSkuDetail.brand || '')
      setValue(
        SIMPLE_SKU_FIELDS_NAME.MANUFACTURER,
        singleSkuDetail.manufacturer || ''
      )
      setValue(
        SIMPLE_SKU_FIELDS_NAME.WEIGHT_UOM,
        Object.values(CATALOG_UNIT_OF_MEASUREMENT).find(
          (ele) => ele.value === singleSkuDetail.weight?.uom
        ) || CATALOG_UNIT_OF_MEASUREMENT.KG
      )
      setValue(
        SIMPLE_SKU_FIELDS_NAME.MEASUREMENT_UNIT,
        Object.values(CATALOG_UNIT_OF_MEASUREMENT).find(
          (ele) => ele.value === singleSkuDetail.uom
        ) || CATALOG_UNIT_OF_MEASUREMENT.EA
      )
      setValue(
        SIMPLE_SKU_FIELDS_NAME.PERISHABLE,
        Object.values(CATALOG_FLOATING_YES_AND_NO_OPTIONS).find(
          (ele) => ele.value === singleSkuDetail.is_perishable
        ) || CATALOG_FLOATING_YES_AND_NO_OPTIONS[1]
      )
      setValue(
        SIMPLE_SKU_FIELDS_NAME.SELLING_PRICE_INCLUDES_TAX,
        Object.values(CATALOG_FLOATING_YES_AND_NO_OPTIONS).find(
          (ele) => ele.value === singleSkuDetail.tax_inclusive?.selling_price
        ) || CATALOG_FLOATING_YES_AND_NO_OPTIONS[1]
      )
      setValue(
        SIMPLE_SKU_FIELDS_NAME.PRICE_UNIT, { label: singleSkuDetail.currency, value: singleSkuDetail.currency }
      )
      setValue(
        SIMPLE_SKU_FIELDS_NAME.IS_GTIN_PICKING_ENABLED,
        Object.values(CATALOG_FLOATING_YES_AND_NO_OPTIONS).find(
          (ele) => ele.value === singleSkuDetail[SIMPLE_SKU_FIELDS_NAME.IS_GTIN_PICKING_ENABLED]
        ) || CATALOG_FLOATING_YES_AND_NO_OPTIONS[1]
      )
      setBarcodeData(singleSkuDetail.barcodes?.map((ele) => {
        return {barcode: ele, isEditable: true}
      }) || [])
      setValue(
        SIMPLE_SKU_FIELDS_NAME.PACKAGE_TYPE,
        PACKAGE_SKU_TYPE.UNIT
      )
      setValue(SIMPLE_SKU_FIELDS_NAME.LABELS, singleSkuDetail.labels?.length ? singleSkuDetail.labels.map((el) => ({ label: el.name, value: el.id })) : [])
      setValue(SIMPLE_SKU_FIELDS_NAME.TAX_CATEGORY, singleSkuDetail.tax_category ? { label: singleSkuDetail.tax_category.name, value: singleSkuDetail.tax_category.id } : null)
    }
  }, [singleSkuDetail?.id])

  useEffect(() => {
    if (isEdit && uploadedImages.length > 1) {
      addImageRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' })
    }
  }, [uploadedImages])

  return (
    <>
      {singleSkuLoading ? <ComponentSpinner /> :
        <form
          className='simple-sku p-24px'
          onSubmit={handleSubmit(submitHandler)}
        >
          <div className='d-flex gap-2 flex-column'>
            <div className='d-flex gap-2'>
              <div>
                <SkuImageHandler
                  uploadedImages={uploadedImages}
                  currentSelectedImage={currentSelectedImage}
                  imageRef={imageRef}
                  loading={bundleCreationLoading}
                  updateBundleSkusRes={updateBundleSkusRes}
                  imageGettingUpload={imageGettingUpload}
                  addImageRef={addImageRef}
                  setCurrentSelectedImage={setCurrentSelectedImage}
                  setUploadedImages={setUploadedImages}
                />
              </div>
              <div className='w-100'>
                <SingleSkuDetailField
                  watch={watch}
                  selectedSeller={selectedSeller}
                  tenantType={tenantType}
                  control={control}
                  errors={errors}
                  register={register}
                  Vector={Vector}
                  t={t}
                  isEdit={isEdit}
                  setBarcodeData={setBarcodeData}
                  barcodeData={barcodeData}
                  singleSkuLoading={singleSkuLoading}
                  clearErrors={clearErrors}
                  updateBundleSkusRes={updateBundleSkusRes}
                  bundleCreationLoading={bundleCreationLoading}
                  imageGettingUpload={imageGettingUpload}
                  getValues={getValues}
                  productConfiguration={productConfiguration}
                  setValue={setValue}
                />
              </div>
            </div>
            <div>
              <SingleSkuMetaData
                watch={watch}
                selectedSeller={selectedSeller}
                tenantType={tenantType}
                control={control}
                errors={errors}
                register={register}
                setValue={setValue}
                Vector={Vector}
                t={t}
                isEdit={isEdit}
                singleSkuLoading={singleSkuLoading}
                updateBundleSkusRes={updateBundleSkusRes}
                bundleCreationLoading={bundleCreationLoading}
                imageGettingUpload={imageGettingUpload}
                singleSkuDetail={singleSkuDetail}
                clearErrors={clearErrors}
                isPosEnabled={isPosEnabled}
              />
            </div>
            <SidesheetFooter>
              <Button
                disabled={
                  !isObjEmpty(errors) ||
                  bundleCreationLoading ||
                  updateBundleSkusRes.loading || imageGettingUpload
                }
              >
                {(bundleCreationLoading || updateBundleSkusRes.loading || imageGettingUpload) ? <div className='flex-center-start gap-8px'> <Spinner size="sm"/> <span>{isEdit ? t('Save') : t('Create SKU')}</span></div> : isEdit ? t('Save') : t('Create SKU')}
              </Button>
            </SidesheetFooter>
          </div>
        </form>
      }
    </>
  )
}

export default SingleSkuCreate
