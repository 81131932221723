import { HUB_TYPE, TENANT_TYPE } from '@src/App.constants'
import FilterTabs from '@src/components/filterTabs'
import { PageNotFound } from '@src/components/pageNotFound/pageNotFound'
import { mergeAddress, OrderDetailsRow } from '@src/views/sales/sales.utils'
import { useMemo, useState } from 'react'
import { Info } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Card } from 'reactstrap'
import { STO_STATUS, STO_TYPES } from '../../sto.constant'
import './printSTODetail.scss'
import './stoDetailSidebar.scss'
import ItemsTab from './stoDetailsTabs/itemsTab'
import OrderDetails from './stoDetailsTabs/orderDetails'

const STODetail = ({ stoRequestId }) => {

  // hooks
  const { t } = useTranslation()

  
  // stores
  const stoDetail = useSelector((store) => store.sales.stoDetail)
  const error = useSelector((store) => store.sales.error)
  const tenant = useSelector(store => store.auth.userData.tenant)
  
  const TABS_DATA = {
    ITEM_DETAILS: { id: 'ITEM_DETAILS', name: 'Item Details', omit:false },
    ORDER_DETAILS: { id: 'ORDER_DETAILS', name: 'Order Details', omit: stoDetail?.status !== STO_STATUS.ACCEEPTED.label }
  }

  const tabList = Object.values(TABS_DATA).filter(tab => !tab.omit);

  const componentMap = {
    [TABS_DATA.ITEM_DETAILS.id]: ItemsTab,
    [TABS_DATA.ORDER_DETAILS.id]: OrderDetails
  }

  // states
  const [activeTab, setActiveTab] = useState(TABS_DATA.ITEM_DETAILS)
  
  // variables
  const stoType = stoDetail?.type
  const isSourceHubVirtual = stoDetail?.source_hub?.type === HUB_TYPE.VIRTUAL
  const isDestinationHubVirtual = stoDetail?.destination_hub?.type === HUB_TYPE.VIRTUAL
  
  // functions
  const toggleTab = (tab) => {
    setActiveTab(tab)
  }

  const virtualHubDetails = useMemo(() => {
    if (!stoDetail) return null;

    const { source_hub, destination_hub } = stoDetail;
    if (source_hub?.type === HUB_TYPE.VIRTUAL) return source_hub;
    if (destination_hub?.type === HUB_TYPE.VIRTUAL) return destination_hub;

    return null;
  }, [stoDetail]);

  const basicDetailsData = useMemo(() => [
    {
      id: 'created_by',
      label: t('Created By'),
      value: stoDetail?.created_by.name,
    },
    {
      id: 'created_at',
      label: t('Created At'),
      value: stoDetail?.created_at,
    },
    {
      id: 'seller_name',
      label: t('Seller Name'),
      value: stoDetail?.seller_name,
      omit: tenant.type !== TENANT_TYPE.FULFILLMENT_CENTRE
    },
    {
      id: 'quantity',
      label: t('Quantity'),
      value: stoDetail?.total_item_count,
    },
    {
      id: 'source_hub',
      label: t('Source Hub'),
      value: stoDetail?.source_hub.name,
    },
    {
      id: 'destination_hub',
      label: t('Destination Hub'),
      value: stoDetail?.destination_hub.name,
    },
    {
      id: 'source_status',
      label: t('Source Status'),
      value: stoDetail?.display_source_status,
      omit: stoType === STO_TYPES.EXT_TO_INT || stoDetail?.is_sto_split || stoDetail?.status !== STO_STATUS.ACCEEPTED.label ,
    },
    {
      id: 'destination_status',
      label: t('Destination Status'),
      value: stoDetail?.received_status,
      omit: stoType === STO_TYPES.INT_TO_EXT || stoDetail?.is_sto_split || stoDetail?.status !== STO_STATUS.ACCEEPTED.label ,
    },
  ], [stoDetail, tenant.type, stoType])

  const deliveryAddressData = useMemo(() => [
    {
      id: 'hub_name',
      label: t('Hub Name'),
      value: stoDetail?.destination_hub.name,
    },
    {
      id: 'hub_contact_no',
      label: t('Hub\'s Phone No.'),
      value: stoDetail?.destination_hub.phone_number,
    },
    {
      id: 'receiver_name',
      label: t('Receiver\'s Name'),
      value: stoDetail?.customer.customer_name,
    },
    {
      id: 'receiver_contact_no',
      label: t('Receiver\'s No.'),
      value: stoDetail?.customer.mobile,
    },
    {
      id: 'address',
      label: t('Address'),
      value: mergeAddress(stoDetail?.destination_hub.address),
    },
  ], [stoDetail])


  if (error.getStoRequestDetail) {
    return <PageNotFound />
  }

  const ActiveComponent = componentMap[activeTab.id]

  return (
    <>
      <div className="sto-request-details-wrapper d-flex flex-column gap-20px p-24px">

        {virtualHubDetails ?
          <div className='text-dark p-8px txt-sub-rg flex-center-start rounded-4px bg-primary-lighter-global'>
            <div className='flex-center-start'>
              <div className='flex-center-start gap-6px'>
                <span className='flex-center-center'><Info size={16} color="white" fill="var(--bs-primary)" /></span>
                <span>{`${virtualHubDetails.name} is not being powered by ${tenant.name}`}.</span>
              </div>
            </div>
          </div>
          : null}

        <Card className="details-card bg-white w-100 rounded-4">
          <div className="card-header py-12px px-16px rounded-top-4 text-secondary txt-h3-md">
            <span>{t('Basic Details')}</span>
          </div>
          <div className="basic-details-row-wrapper">
            {
              basicDetailsData
                .filter((item) => !item.omit)
                .map((item) => (
                  <OrderDetailsRow
                    key={item.id}
                    entityNameColSpan={4}
                    entityValueColSpan={8}
                    entityName={item.label}
                    entityValue={item.value || '-'}
                  />
                ))
            }
          </div>
        </Card>

        <Card className="details-card bg-white w-100 rounded-4">
          <div className="card-header py-12px px-16px rounded-top-4 text-secondary txt-h3-md">
            <span>{t('Delivery Address')}</span>
          </div>
          <div className="basic-details-row-wrapper">
            {
              deliveryAddressData
                .filter((item) => !item.omit)
                .map((item) => (
                  <OrderDetailsRow
                    key={item.id}
                    entityNameColSpan={4}
                    entityValueColSpan={8}
                    entityName={item.label}
                    entityValue={item.value || '-'} />
                ))
            }
          </div>
        </Card>
        {/* 
            <div className="d-flex gap-16px">
                
              <Card className="details-card bg-white w-100 rounded-4">
                <div className="card-header py-12px px-16px rounded-top-4 text-secondary txt-h3-md">
                  <span>{t('Hub Summary')}</span>
                </div>
                <div className="details-row-wrapper">
                  {
                    hubSummaryData
                      .filter((item) => !item.omit)
                      .map((item) => (
                        <OrderDetailsRow
                          key={item.id}
                          entityNameColSpan={4}
                          entityValueColSpan={8}
                          entityName={item.label}
                          entityValue={item.value || '-'}
                        />
                      ))
                  }
                </div>
              </Card>
  
            </div> */}

        <div>
          <FilterTabs tabDataList={tabList} selectedTabId={activeTab.id} onTabChange={toggleTab} />
        </div>
        <ActiveComponent stoRequestId={stoRequestId} stoDetail={stoDetail} isSourceHubVirtual={isSourceHubVirtual} isDestinationHubVirtual={isDestinationHubVirtual} />
      </div>
    </>
  )
}
export default STODetail