import Button from '@src/@core/components/ui/button'
import { PrintIcon } from '@src/assets/images/icons/printIcon'
import { axiosInstance } from '@src/network/AxiosInstance'
import { bindToChannelEvent, initRealTime } from '@src/network/RealTime'
import { useMutation } from '@tanstack/react-query'
import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Spinner } from 'reactstrap'
import PrintInvoice from './print-invoice'

const ShipmentPrintBtns = ({ selectedOrders, pickingWave }) => {
  const { t } = useTranslation()
  const printInvoiceRef = useRef(null)
  const hub_id = useSelector(store => store.auth.selectedGlobalHubId)
  const pusherLoading = useSelector(state => state.auth.pusherLoading)

  // Print Bulk Awbs
  const { mutate: printBulkAwbs, isPending: isPrintBulkAwbsPending } = useMutation({
    mutationKey: ['print-awb-bulk'],
    mutationFn: (body) => {
      return axiosInstance.post('/api/v1/oms/orders/shipments/awbs', body)
    },
    onSuccess: (data) => {
      const eventName = data?.data?.data?.event
      bindToChannelEvent(eventName)
    }
  })

  const handlePrintBulkAwbs = () => {
    const body = {
      order_ids: Object.values(selectedOrders).map(el => el.id),
      wave_id: String(pickingWave?.wave?.id),
      hub_id
    }
    initRealTime(() => {
      printBulkAwbs(body)
    })
  }

  return (
    <div className='flex-center-center gap-8px'>
      <PrintInvoice printInvoiceRef={printInvoiceRef} selectedOrders={selectedOrders} pickingWave={pickingWave} />
      <div style={{ width: '1px' }} className='h-75 bg-dark-2'></div>
      <Button
        ofStyle="noBackground"
        to="/"
        className="txt-body-md py-4px px-8px"
        onClick={(e) => {
          e.stopPropagation()
          handlePrintBulkAwbs()
        }}
        disabled={isPrintBulkAwbsPending || pusherLoading}
      >
        {isPrintBulkAwbsPending ? (
          <Spinner size='sm' />
        ) : (
          <PrintIcon color='var(--bs-primary)' size={20} />
        )}
        {t('Print AWB')}
      </Button>
    </div>
  )
}

export default ShipmentPrintBtns