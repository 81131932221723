import { useEffect } from 'react'

export const useOutsideClick = (refs, handler, events = ['mousedown', 'touchstart']) => {
  useEffect(() => {
    // Event listener to handle clicks outside of specified refs
    const listener = event => {
      // Check if the click target is not within any of the specified refs
      if (refs.some(ref => ref.current && ref.current.contains(event.target))) {
        return // Click is inside one of the specified refs, do nothing
      }
      handler(event) // Call the provided handler function on click outside
    }

    // Function to add event listeners for specified event types
    const addEventListeners = () => {
      events.forEach(eventType => {
        document.addEventListener(eventType, listener)
      })
    }

    // Function to remove event listeners when the component unmounts
    const removeEventListeners = () => {
      events.forEach(eventType => {
        document.removeEventListener(eventType, listener)
      })
    }

    addEventListeners() // Attach event listeners

    // Clean up event listeners when the component unmounts
    return () => {
      removeEventListeners()
    }
  }, [refs, handler, events])
}
