import Button from '@src/@core/components/ui/button'
import { Note } from '@src/assets/images/svg/note'
import PropTypes from 'prop-types'
import { X } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { Modal, ModalBody } from 'reactstrap'
import './styles.scss'

const CustomerNoteModal = (prop) => {
  const { isCustomerNoteShow, setIsCustomerNoteShow, customerNote } = prop
  const { t } = useTranslation()

  const handleToggle = () => {
    setIsCustomerNoteShow(prev => !prev)
  }

  return (
    <Modal
      isOpen={isCustomerNoteShow}
      className="custom-customer-note-modal rounded-24px"
      size="lg"
      centered
      toggle={handleToggle}
    >
      <ModalBody className="m-0 p-0">
        <div className="mt-24px content-body">
          <div className="flex-start-between px-24px w-100 mt-24px customer-note-header pb-12px" >
            <div className="flex-center-start gap-16px">
              <div className="flex-center-start rounded-8px p-10px note-container">
                <Note color={'var(--bs-primary)'} />
              </div>
              <div className="txt-h1-sb text-dark">{t('Customer Note')}</div>
            </div>
            <div className="cursor-pointer text-dark" onClick={handleToggle} ><X color="var(--bs-dark)" strokeWidth={2} size={20} /></div>
          </div>
          <div className="txt-body-rg text-dark m-24px customer-note-content">
            {customerNote}
          </div>
        </div>
        <div className="customer-note-footer p-24px flex-center-end">
          <Button onClick={handleToggle}>{t('Close')}</Button>
        </div>
      </ModalBody>
    </Modal>
  )
}

CustomerNoteModal.propTypes = {
  isCustomerNoteShow: PropTypes.bool,
  setIsCustomerNoteShow: PropTypes.func,
  customerNote: PropTypes.string
}

export default CustomerNoteModal
