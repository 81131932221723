import Button from '@src/@core/components/ui/button';
import { FILE_TYPES } from '@src/App.constants';
import image_fallback from '@src/assets/images/omniful/image_fallback.svg';
import FileIcon from '@src/views/sales/pending-actions/pages/createOrder/attachments/file-icon';
import classNames from 'classnames';
import { Paperclip, Trash2 } from 'react-feather';
import { useTranslation } from 'react-i18next';

const AttachmentsTable = ({ addedAttachments, handleOpenAttachmentSidesheet, handleOpenDescriptionModal, handleUpdateDescription, handleRemoveFile, handleTogglePreview }) => {
  const { t } = useTranslation();

  return (
    <div className='d-flex flex-column gap-8px attachments-table'>
      <div className='flex-center-between'>
        <div className='txt-body-md text-dark'>{t('Attachments')}</div>
        <div>
          <Button icon={Paperclip} iconSize={16} className='py-8px px-12px d-flex gap-4px shadow-none' ofStyle='noBackground' onClick={handleOpenAttachmentSidesheet}>{t('Add Attachments')}</Button>
        </div>
      </div>
      <div className='d-flex flex-column gap-12px'>
        {addedAttachments?.map((item, index) => { 
          const fileType = item.file.type.split('/')[0] // Get the file type (image, video, text, etc.)
          const fileName = item.file.name
          const fileUrl = item.preview_url || item.file_url
          const isPreviewAble = item.file.type.startsWith('image/') || item.file.type === FILE_TYPES.PDF.type
          return (
            <div key={index} className="rounded-8px border border-dark-2 bg-white p-10px flex-center-between attachment-card">
              <div className="flex-center-start gap-10px width-80-per">
                {fileType === 'image' ?
                  <div className='file-icon-wrapper flex-shrink-0 rounded-4px border border-dark-2'>
                    <img
                      src={fileUrl || image_fallback}
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null
                        currentTarget.src = image_fallback
                      }}
                      alt={fileName}
                      className='rounded-4px object-fit-contain w-100 h-100'
                    />
                  </div>
                  :
                  <div className="rounded-4px bg-light-2 flex-center-center file-icon-wrapper flex-shrink-0 rounded-4px border border-dark-2">
                    <FileIcon fileType={fileType} size={24} />
                  </div>
                }
                <div className={classNames('d-flex flex-column width-90-per', {
                  'gap-4px': item.description,
                  'gap-12px': !item.description
                })}>
                  <div className='d-flex flex-column gap-2px w-100'>
                    <div className={classNames('txt-body-md text-dark w-100 text-truncate', {
                      'file_title': isPreviewAble
                    })}
                    onClick={() => {
                      const properties = { name: fileName, type: fileType, file_url: fileUrl }
                      if (isPreviewAble) {
                        handleTogglePreview(properties)
                      }
                    }}
                    >
                      {fileName}
                    </div>
                    {item.description && <div className="txt-sub-rg text-dark-6 w-100 text-truncate">{item.description}</div>}
                  </div>
                  {item.description ?
                    <div className='flex-center-start gap-16px'>
                      <Button ofType="compressed" ofStyle="noBackground" className="txt-sub-md p-0 shadow-none" onClick={() => handleOpenDescriptionModal(item, index)}>
                        {t('Edit')}
                      </Button>
                      <Button ofType="compressed" ofStyle="noBackground" className="txt-sub-md p-0 shadow-none" onClick={() => handleUpdateDescription(index, '')}>
                        {t('Remove')}
                      </Button>
                    </div> :
                    <div>
                      <Button ofType="compressed" ofStyle="noBackground" className="txt-sub-md p-0 shadow-none" onClick={() => handleOpenDescriptionModal(item, index)}>
                        { t('Add Description')}
                      </Button>
                    </div>
                  }
                </div>
              </div>
              <div className="flex-center-end gap-16px flex-shrink-0">
                <Trash2 
                  size={16} color="var(--bs-danger)" 
                  className="cursor-pointer"
                  onClick={() => handleRemoveFile(index)}
                />
              </div>
            </div>
          )
        })}
      </div>
    </div>
  );
};

export default AttachmentsTable;