const ReturnOrder = ({ iswhite, fillColor, height, width }) => {
  const color = iswhite ? 'white' : fillColor
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M15.1661 11.5651C14.0959 11.5651 13.1318 12 12.4476 12.6971C12.3276 12.8193 12.1312 12.8211 12.009 12.7011C11.8868 12.5812 11.885 12.3848 12.0049 12.2626C12.8026 11.4499 13.9249 10.9448 15.1661 10.9448C17.5783 10.9448 19.5467 12.8551 19.5467 15.2275C19.5467 17.5998 17.5783 19.5101 15.1661 19.5101C13.9249 19.5101 12.8026 19.005 12.0049 18.1924C11.885 18.0701 11.8868 17.8738 12.009 17.7538C12.1312 17.6338 12.3276 17.6356 12.4476 17.7579C13.1318 18.4549 14.0959 18.8899 15.1661 18.8899C17.2501 18.8899 18.9265 17.2431 18.9265 15.2275C18.9265 13.2119 17.2501 11.5651 15.1661 11.5651Z" fill={color}/>
      <path fillRule="evenodd" clipRule="evenodd" d="M11.6369 10.5996C11.8082 10.5996 11.947 10.7385 11.947 10.9097V12.0283C11.947 12.4008 12.2489 12.7027 12.6214 12.7027H13.74C13.9113 12.7027 14.0501 12.8416 14.0501 13.0128C14.0501 13.1841 13.9113 13.3229 13.74 13.3229H12.6214C11.9064 13.3229 11.3268 12.7433 11.3268 12.0283V10.9097C11.3268 10.7385 11.4656 10.5996 11.6369 10.5996Z" fill={color}/>
      <path fillRule="evenodd" clipRule="evenodd" d="M2 4.29461C2 3.57962 2.57962 3 3.29461 3H10.6524C11.3674 3 11.947 3.57962 11.947 4.29461V9.05832H11.3268V4.29461C11.3268 3.92216 11.0249 3.62023 10.6524 3.62023H3.29461C2.92216 3.62023 2.62023 3.92216 2.62023 4.29461V11.6524C2.62023 12.0249 2.92216 12.3268 3.29461 12.3268H8.26361V12.947H3.29461C2.57962 12.947 2 12.3674 2 11.6524V4.29461Z" fill={color}/>
      <path fillRule="evenodd" clipRule="evenodd" d="M5.54456 8.00084V3.39282H6.16479V7.30683L6.76102 6.77289C6.87878 6.66743 7.05703 6.66743 7.17479 6.77289L7.77102 7.30683V3.39282H8.39126V8.00084C8.39126 8.12316 8.31934 8.23406 8.20766 8.28397C8.09597 8.33388 7.96538 8.31347 7.87426 8.23186L6.96791 7.4202L6.06156 8.23186C5.97043 8.31347 5.83984 8.33388 5.72815 8.28397C5.61647 8.23406 5.54456 8.12316 5.54456 8.00084Z" fill={color}/>
      <path fillRule="evenodd" clipRule="evenodd" d="M14.9186 21.3435C18.487 21.3435 21.3798 18.4507 21.3798 14.8822C21.3798 11.3138 18.487 8.42101 14.9186 8.42101C11.3501 8.42101 8.45733 11.3138 8.45733 14.8822C8.45733 18.4507 11.3501 21.3435 14.9186 21.3435ZM14.9186 21.9637C18.8295 21.9637 22 18.7932 22 14.8822C22 10.9713 18.8295 7.80078 14.9186 7.80078C11.0076 7.80078 7.8371 10.9713 7.8371 14.8822C7.8371 18.7932 11.0076 21.9637 14.9186 21.9637Z" fill={color}/>
    </svg>
  )
}
export default ReturnOrder