import classNames from 'classnames'
import React from 'react'
import { Check } from 'react-feather'
import { useTranslation } from 'react-i18next'
import './style.scss'

const Stepper = ({ steps, currentStep }) => {
  const { t } = useTranslation()

  return (
    <div className="d-flex align-items-center stepper gap-4px">
      {steps.map((step, index) => (
        <React.Fragment key={index}>
          {0 < index && <div className={classNames('step-divider', {
            'complete-divider': index < currentStep
          })} />}
          <div className={classNames('d-flex align-items-center', {
            active: currentStep === index + 1,
            complete: index + 1 < currentStep
          })}>
            <div className='d-flex flex-column align-items-center gap-8px width-20px'>
              <div className="step-number custom-back-ground">{index + 1 < currentStep && <Check className='complete-icon' size={14} />}</div>
              <div className="step-label text-nowrap txt-sub-md">{t(step)}</div>
            </div>
          </div>
        </React.Fragment>
      ))}
    </div>
  )
}

export default Stepper

// const [currentStep, setCurrentStep] = useState(1)
//   const steps = ['GRN details & Bins', 'Quality Control']
//   const handleStepNext = () => {
//     setCurrentStep(preStep => preStep + 1)
//   }
// <Stepper steps={steps} currentStep={currentStep} />
// {steps.length > currentStep && <Button onClick={handleStepNext}>Next</Button>}