import CustomTag from '@src/@core/components/ui/badge/CustomTag'
import CopyOnClick from '@src/@core/components/ui/click-and-copy'
import abilityMap from '@src/assets/data/abilityMapping/abilityMapping'
import no_sku_image from '@src/assets/images/omniful/no_sku_image.svg'
import ability from '@src/configs/acl/ability'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { UncontrolledTooltip } from 'reactstrap'
import './styles.scss'

const handleImageError = (currentTarget) => {
  currentTarget.onerror = null
  currentTarget.src = no_sku_image
}

const SKUDetailsCol = ({ skuDetailData }) => {
  const { isValidSku, id, image, details, customTag, warnings } = skuDetailData
  const isProductViewAllowed = ability.can(abilityMap.product.view.action, abilityMap.product.view.resource)

  const {t} = useTranslation()

  return (
    <div id="sku-details-wrapper" className="w-100 flex-center-start gap-8px">
      <div className="border border-dark-2 rounded-2 flex-center-center max-height-32px max-width-32px overflow-hidden">
        <img
          onError={({ currentTarget }) => handleImageError(currentTarget)}
          src={image?.src || no_sku_image}
          alt={image?.alt}
          className="sku-img h-full w-full object-fit-fill"
          width="32"
          height="32"
          loading="lazy"
        />
      </div>
      <div className="sku-info" style={{ minWidth: 0 }}>
        <CopyOnClick
          id={`${id}_copy_sku_name_id`}
          handleDetails={isProductViewAllowed ? ((isValidSku && details?.name) && details?.handleSkuDetails) : null}
          columnKey={`${details?.columnKey}_name`}
          isCopyIconVisible={isValidSku && details?.name}
          customClassName="txt-sub-rg text-nowrap"
          title={details?.name}
        >
          {isValidSku ? (details?.name ? details?.name : '-') : 'N/A'}
        </CopyOnClick>
        <CopyOnClick
          id={`${id}_copy_sku_code_id`}
          columnKey={`${details?.columnKey}_code`}
          isCopyIconVisible={isValidSku && details?.skuCode}
          customClassName="txt-asst-rg text-dark-6 text-nowrap"
          title={details?.skuCode}
        >
          {isValidSku ? (details?.skuCode ? details.skuCode : '-') : 'N/A'}
        </CopyOnClick>
      </div>
      {customTag?.isCustomTagPresent && (
        <div className="ms-8px">
          <CustomTag className={customTag.className} title={customTag.title} icon={customTag.icon}  alignIcon={customTag.alignIcon} />
        </div>
      )}
      {
        (warnings?.isWarningShown && warnings?.name === details?.name) && <div id="warningIDonHold" className="warning-icon">
          <img src={warnings.src} alt={warnings.alt} />
          <UncontrolledTooltip target="warningIDonHold" offset={[0, 5]} autohide={false}>
            {t(`${warnings.message}`)}
          </UncontrolledTooltip>
        </div> 
      }
    </div>
  )
}

SKUDetailsCol.propTypes = {
  skuDetailData: PropTypes.shape({
    isValidSku: PropTypes.bool,
    id: PropTypes.string.isRequired,
    image: PropTypes.shape({
      src: PropTypes.string,
      alt: PropTypes.string
    }),
    details: PropTypes.shape({
      handleSkuDetails: PropTypes.func,
      columnKey: PropTypes.string,
      name: PropTypes.string,
      skuCode: PropTypes.string
    }),
    customTag: PropTypes.shape({
      isCustomTagPresent: PropTypes.bool,
      className: PropTypes.string,
      title: PropTypes.string
    }),
    warnings: {
      isWarningShown: PropTypes.bool,
      src: PropTypes.string,
      alt: PropTypes.string,
      message: PropTypes.string
    }
  })
}

SKUDetailsCol.defaultProps = {
  skuDetailData: {
    isValidSku: true,
    image: {
      src: no_sku_image,
      alt: 'no img'
    },
    details: {
      columnKey: 'sku_details'
    },
    customTag: {
      isCustomTagPresent: false,
      className: '',
      title: ''
    },
    warnings: {
      isWarningShown: false,
      src: '',
      alt: 'warning icon'
    }
  }
}

export default SKUDetailsCol

// structure for reference 

// skuDetailData={{
//   isValidSku: true,
//   id: row.seller_sku.id,
//   image: {
//     src: row.seller_sku?.images?.[0]?.default,
//     alt: row.seller_sku?.name
//   },
//   details: {
//     name: row.seller_sku?.name,
//     handleSkuDetails: () => (row.seller_sku?.name ? handleSkuDetailsSidebar(row) : null),
//     skuCode: row.seller_sku_code,
//     columnKey: "sku_details"
//   },
//   customTag: {
//     isCustomTagPresent: showSkuTypeTag(row.seller_sku?.type),
//     className:"text-info bg-info-light",
//     title: showSkuTypeTag(row.seller_sku?.type)
//   }
// }}
// warnings: {
//   name: row.seller_sku?.name,
//   src: circularWarningIcon,
//   alt: 'warning icon',
//   isWarningShown: row.remaining_quantity > row.available_quantity, 
//   message: "You do not have sufficient inventory available to fulfil this SKU."
// }