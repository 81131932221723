import BackFile from '@src/assets/images/icons/BackFile'
import DropPage from '@src/assets/images/icons/DropPage'
import FrontFile from '@src/assets/images/icons/FrontFile'
import { useRTL } from '@src/utility/hooks/useRTL'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import './styles.scss'

const ProgressLoader = ({dimensions = '250', styles}) => {
  const [isRtl] = useRTL()
  return (
    <div id="progress-loader-wrapper">
      <svg width={dimensions} height={dimensions} viewBox="0 0 250 250" className="circular-progress">
        <circle className="bg"></circle>
        <circle className={classNames('fg', {rtl:isRtl, ltr: !isRtl})}></circle>
      </svg> 
      <div className="loader-container" style={styles}>
        <div className="file-cover">
          <div className={classNames('drop-page', {rtl:isRtl, ltr: !isRtl})}>
            <DropPage/>         
          </div>
          <div className={classNames('back-file-cover', {rtl:isRtl, ltr: !isRtl})}>
            <BackFile color="var(--bs-primary)" />  
          </div>
          <div className={classNames('front-file-cover', {rtl:isRtl, ltr: !isRtl})}>
            <FrontFile color="var(--bs-primary)" />         
          </div>
        </div>
      </div>
    </div>
  )
}

ProgressLoader.defaultProps = {
  dimensions: '250',
  styles: {}
}

ProgressLoader.propTypes = {
  dimensions: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  styles: PropTypes.object
}

export default ProgressLoader