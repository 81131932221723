import { GRN_CONTAINER_TYPES } from '@src/views/inventory/inventory.constants'
import classNames from 'classnames'
import { memo, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { BIN_TYPE } from '../../constant'
import AddBinModal from './addBinModal'
import SelectContainerQcModal from './SelectContainerQcModal'

const BinDetailCard = ({ bin_type, data, selectedBin, setSelectedBin, grnId, singleGrnDetail }) => {
  const [isAddBinModalOpen, setIsAddBinModalOpen] = useState({ isOpen: false, type: null })
  const has_barcode_mapped = useSelector((store) => store.inventory.grn.has_barcode_mapped)
  const pass_bin_id = useSelector((store) => store.inventory.grn.pass_bin_id)
  const fail_bin_id = useSelector((store) => store.inventory.grn.fail_bin_id)
  const [containerType, setContainerType] = useState(GRN_CONTAINER_TYPES.BIN)
  const [isSelectContainerModalOpen, setIsSelectContainerModalOpen] = useState(false)


  const handleAddBinModal = (bin_type) => {
    setIsAddBinModalOpen({ isOpen: true, type: bin_type })
  }

  useEffect(() => {
    if (has_barcode_mapped || pass_bin_id !== '' || fail_bin_id !== '') {
      setIsAddBinModalOpen({ isOpen: false, type: null })
    }
  }, [has_barcode_mapped, pass_bin_id, fail_bin_id])

  const handleSelectedBin = (bin) => {
    if (bin.id !== selectedBin.id) {
      setSelectedBin({ id: bin.id, barcode: bin.barcode, type: bin_type, container_type: bin.type })
    }
  }

  return (
    <div className="bin-detail-card">
      <div className="bin-detail-main-header">
        <div
          className={classNames('bin-detail-header', {
            'bg-danger': bin_type.id === BIN_TYPE.fail_bin.id
          })}
        >
          <img height={16} width={16} src={bin_type.bin_icon} />
          <div>{bin_type.name?.split(' ')?.[0]}</div>
          <button className="add-bin-button" onClick={() => setIsSelectContainerModalOpen(true)}>
            <img height={16} width={16} src={bin_type.plus_icon} alt="plus" />
          </button>
        </div>
      </div>

      <table className="total-bins-detail">
        {data?.length > 0 ? (
          <tbody>
            {data.map((bin, index) => {
              return (
                <tr
                  key={index}
                  className={classNames('text-dark total-bin-row', { 'selected-row': selectedBin.id === bin.id, 'fail-bin': bin_type.id === BIN_TYPE.fail_bin.id, 'cursor-pointer': selectedBin.id !== bin.id })}
                  onClick={() => handleSelectedBin(bin)}
                >
                  <td>{index + 1}</td>
                  <td>{bin.barcode}</td>
                </tr>
              )
            })}
          </tbody>
        ) : (
          <div className="text-center p-1">{`No ${bin_type.status} Bin Added`}</div>
        )}
      </table>
      <SelectContainerQcModal
        isOpen={isSelectContainerModalOpen}
        toggle={() => setIsSelectContainerModalOpen(prev => !prev)}
        binType={bin_type}
        containerType={containerType}
        setContainerType={setContainerType}
        handleAddBinModal={handleAddBinModal}
      />
      <AddBinModal isAddBinModalOpen={isAddBinModalOpen} setIsAddBinModalOpen={setIsAddBinModalOpen} grnId={grnId} containerType={containerType} setContainerType={setContainerType} singleGrnDetail={singleGrnDetail} />
    </div>
  )
}

export default memo(BinDetailCard)
