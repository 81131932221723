import { ExpandableCustomTable } from '@src/@core/components/ui/globalTable/ExpandableCustomTable'
import { ALL_COLUMN_NAME_MAPPING } from '@src/@core/components/ui/globalTable/globalTable.constant'
import SKUDetailsCol from '@src/@core/components/ui/sku-details'
import no_sku_image from '@src/assets/images/omniful/no_sku_image.svg'
import SimpleSkuDetailModal from '@src/views/catalog/components/simple-sku-detail-modal/SimpleSkuDetailModal'
import { getStoRequestDetail } from '@src/views/sales/store/store'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { STO_STATUS } from '../../../sto.constant'

const ItemsTab = ({ stoRequestId, stoDetail, isSourceHubVirtual, isDestinationHubVirtual }) => {

  // hooks
  const { t } = useTranslation()
  const dispatch = useDispatch()

  // stores
  const loading = useSelector((store) => store.sales.loading)
  const error = useSelector((store) => store.sales.error)
  const success = useSelector((store) => store.sales.success)
  const stoRequestMetaData = useSelector((store) => store.sales.stoRequestMetaData)

  // states
  const [tableData, setTableData] = useState([])
  const [params, setParams] = useState({ page: 1, per_page: 20 })
  const [openSkuDetailsSidebar, setOpenSkuDetailsSidebar] = useState(false)
  const [skuForDetails, setSkuForDetails] = useState({})

  // functions
  const handleGetStoRequestDetail = ({ page, per_page }) => {
    let localParams
    if (page && per_page) {
      localParams = { page, per_page }
    } else {
      localParams = params
    }
    dispatch(getStoRequestDetail({ params: localParams, order_id: stoRequestId }))
  }

  const handlePagination = page => {
    const updatedPagination = { page: page.selected, per_page: page.per_page ? page.per_page : 20 }
    setParams(updatedPagination)
    handleGetStoRequestDetail(updatedPagination)
  }

  const handleSkuDetailsSidebar = (data) => {
    setSkuForDetails({...data, seller_id: stoDetail.seller_id})
    setOpenSkuDetailsSidebar(!openSkuDetailsSidebar)
  }

  const columns = useMemo(() => [
    {
      id: 1,
      name: t('SKU Details'),
      minWidth: '300px',
      // maxWidth: '400px',
      cell: (row) => {
        return (
          <SKUDetailsCol
            skuDetailData={{
              isValidSku: !!(row.id && (row.name || row.sku_code)),
              id: row.id,
              image: {
                src: row.image,
                alt: row.name
              },
              details: {
                name: row.name,
                skuCode: row.sku_code,
                columnKey: 'sku_details',
                handleSkuDetails: row.id ? () => (handleSkuDetailsSidebar(row)) : null,
              }
            }}
          />
        )
      }
    },
    {
      id: 2,
      name: <div className="w-100 text-end">{t('Ordered Qty')}</div>,
      minWidth: '140px',
      maxWidth: '160px',
      cell: row => <div className="w-100 text-end">{row.quantity}</div>
    },
    {
      id: 3,
      name: <div className="w-100 text-end">{t('Approved Qty')}</div>,
      minWidth: '140px',
      maxWidth: '160px',
      omit: stoDetail?.status !== STO_STATUS.ACCEEPTED.label,
      cell: row => <div className="w-100 text-end">{row.approved_quantity}</div>
    },
    {
      id: 4,
      name: <div className="w-100 text-end">{t('Shipped Qty')}</div>,
      minWidth: '140px',
      maxWidth: '160px',
      omit: (stoDetail?.status !== STO_STATUS.ACCEEPTED.label) || isSourceHubVirtual,
      cell: row => <div className="w-100 text-end">{row.transferred_quantity}</div>
    },
    {
      id: 5,
      name: <div className="w-100 text-end">{t('Received Qty')}</div>,
      minWidth: '140px',
      maxWidth: '160px',
      omit: (stoDetail?.status !== STO_STATUS.ACCEEPTED.label) || isDestinationHubVirtual,
      cell: row => <div className="w-100 text-end">{row.received_quantity}</div>
    }
  ], [stoDetail])

  useEffect(() => {
    if (stoDetail) {
      const StoItemsArray = stoDetail.order_items.map((item) => {
        return {
          ...item,
          id: item.seller_sku?.id,
          sku_code: item.seller_sku_code,
          name: item.seller_sku?.name,
          image: item.seller_sku?.images?.[0]?.default || no_sku_image,
          unit_price: item.unit_price,
          quantity: item.quantity,
          orderedQty: item.quantity,
          available_quantity: item.available_quantity,
          approved_quantity: item.approved_quantity,
          received_quantity: item.received_quantity,
          display_source_status: item.display_source_status,
          received_status: item.received_status,
          transferred_quantity: item.transferred_quantity,
          seller_id: item.seller_sku.seller_id,
        }
      })
      setTableData(StoItemsArray)
    }
  }, [stoDetail])

  return (
    <div className="sku-item-table">
      <ExpandableCustomTable
        loading={loading.getStoRequestDetail}
        error={error.getStoRequestDetail}
        success={success.getStoRequestDetail}
        data={tableData}
        columns={columns}
        columnName={ALL_COLUMN_NAME_MAPPING.STO_ORDER_DETAILS}
        handlePagination={handlePagination}
        meta={stoRequestMetaData || {}}
        useReactPaginate={false}
        handleRefreshTable={handleGetStoRequestDetail}
        showColumnsTableHeader={true}
      />

      <SimpleSkuDetailModal
        skuForDetails={skuForDetails}
        toggleSimpleSkuModal={() => handleSkuDetailsSidebar(false)}
        isOpen={openSkuDetailsSidebar} 
      />
      
    </div>
  )
}

export default ItemsTab