import { SKU_TYPE } from '@src/views/inventory/inventory.constants'
import { useState } from 'react'
import Casepack from './Casepack'
import ItemToggleBar from './ItemToggleBar'
import SkuDetailsAndQc from './SkuDetailsAndQc'

const OpenedCasepack = ({ sku_details, maxQty, child_data, selectedTabId, qcBinBarcodeRef, searchBarcode }) => {
  const [isExpanded, setIsExpanded] = useState(true)

  const toggleExpanded = () => setIsExpanded(!isExpanded)

  if (!isExpanded) {
    return <ItemToggleBar sku_details={sku_details} isExpanded={isExpanded} toggleExpanded={toggleExpanded} mode='opened' />
  } else {
    return <>
      {/* toggle bar */}
      <ItemToggleBar sku_details={sku_details} isExpanded={isExpanded} toggleExpanded={toggleExpanded} mode='opened' />
      {/* render child */}
      <div className='ms-3'>
        {child_data.package_type === SKU_TYPE.CASE_PACK.value && <Casepack sku_details={child_data} maxQty={maxQty} selectedTabId={selectedTabId} qcBinBarcodeRef={qcBinBarcodeRef} searchBarcode={searchBarcode} />}
        {child_data.package_type === SKU_TYPE.EACH.value && <SkuDetailsAndQc sku_details={child_data} maxQty={maxQty} selectedTabId={selectedTabId} qcBinBarcodeRef={qcBinBarcodeRef} searchBarcode={searchBarcode} />}
      </div>
    </>
  }
}

export default OpenedCasepack