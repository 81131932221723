import { X } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Modal, ModalBody, Spinner } from 'reactstrap'
import '../style.scss'
import GrnWithOffTypeReturns from './grnWithOffTypeReturns'

const BinDisabledProcessNextOrder = (props) => {
  const { isOpen, toggle } = props
  const { t } = useTranslation()
  const processNextReturnOrderLoading = useSelector((state) => state.returns.loading.processNextReturnOrder)

  return (
    <Modal
      isOpen={isOpen}
      contentClassName='rounded-24px bg-white bin-disabled-process-next-order-modal'
    >
      <ModalBody className="d-flex flex-column gap-28px p-24px">
        <div className="flex-start-between">
          <div className="txt-h1-sb text-dark">
            {t('Process Next Order')}
          </div>
          <X size={24} onClick={toggle} color={'var(--bs-dark)'} className="cursor-pointer" />
        </div>

        <div> 
          <GrnWithOffTypeReturns
            isNextOrderProcess={true}
          />

          <div className="w-100 flex-center-center mt-8px">
            {
              processNextReturnOrderLoading && <div className="d-flex justify-content-center"> <Spinner className="text-primary width-40px height-40px" /></div>
            }
          </div>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default BinDisabledProcessNextOrder