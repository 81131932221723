import Button from '@src/@core/components/ui/button'
import { clearSkuConfigResponse, setBatchAndSerialisationConfigForSku, setSkuConfig } from '@src/views/inventory/store'
import { useEffect, useRef } from 'react'
import { AlertTriangle, X } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Modal, ModalBody, Spinner } from 'reactstrap'
import { SERIALISATION_OPTION } from '../../constant'

const ConfirmConfigModal = ({isOpen, toggle, batchStatus, serialisationStatus, skuDetails, setCurrentSkuConfig = () => {} }) => {
  const {t} = useTranslation()
  const dispatch = useDispatch()
  const primaryCtaRef = useRef()

  const latestSkuDetails = useSelector(store => store.inventory.grn.sku_details?.data)
  const loadingState = useSelector(store => store.inventory.loadingState)
  const skuConfigResponse = useSelector(store => store.inventory.skuConfigResponse)
  const selectedGlobalHubID = useSelector(
    (store) => store.auth.selectedGlobalHubId
  )
  const handleSaveConfig = () => {
    const body = {
      seller_sku_id: skuDetails.seller_sku_id,
      is_batch_required: batchStatus,
      hub_id: selectedGlobalHubID,
      is_serialised: serialisationStatus === SERIALISATION_OPTION.serialised.id,
      is_edit: skuDetails.is_configuration_editable
    }
    dispatch(setSkuConfig(body))
  }
  useEffect(() => {
    if (skuConfigResponse.is_success) {
      dispatch(setBatchAndSerialisationConfigForSku({ prevState: latestSkuDetails, skuId: skuDetails.seller_sku_id, batchStatus, serialisationStatus }))
      toggle()
      dispatch(clearSkuConfigResponse())
      setCurrentSkuConfig(null)
    }
  }, [skuConfigResponse])

  const onOpened = () => {
    if (primaryCtaRef.current) primaryCtaRef.current?.focus()
  }
  
  return (
    <Modal
      isOpen={isOpen}
      centered
      toggle={toggle}
      onOpened={onOpened}
      contentClassName='rounded-12px'
    >
      <ModalBody className="d-flex flex-column py-16px px-20px ">
        <div className="d-flex justify-content-end align-items-center">
          <X onClick={toggle} size={16} className="text-dark" />
        </div>
        <div className="d-flex flex-column gap-8px mb-32px px-36px align-items-center">
          <AlertTriangle style={{ strokeWidth: 1.5, color: '#FF991F' }} size={80} fill="currentColor" strokeColor="white" stroke="white" />
          <div className='txt-h3-sb text-dark text-center'>
            {` ${t('Are you sure you want to keep this SKU as')} ${batchStatus ? t('Batched') : t('Non-Batched')} ${t('and')} ${serialisationStatus === SERIALISATION_OPTION.serialised.id ? t(SERIALISATION_OPTION.serialised.name) : t(SERIALISATION_OPTION.non_serialised.name)}?`}
          </div>
          <div className='txt-body-md text-dark-6 text-center'>{t('You won\'t be able to modify the SKU configurations after the GRN is completed for the SKU.')}</div>
        </div>
        <div className='d-flex gap-20px'>
          <Button
            ofStyle="outlined"
            className='w-100 justify-content-center'
            onClick={toggle}
            disabled={loadingState.setSkuConfig}
          >
            {t('Cancel')}
          </Button>
          <Button
            className='w-100 justify-content-center'
            onClick={handleSaveConfig}
            ref={primaryCtaRef}
            disabled={loadingState.setSkuConfig}
          >
            {loadingState.setSkuConfig && <Spinner size="sm" />}
            <span className="align-middle ms-25">{t('Save and Continue')}</span>
          
          </Button>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default ConfirmConfigModal