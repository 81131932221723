import SideSheet from '@src/@core/components/ui/sideSheet'
import { memo } from 'react'
import { useDispatch } from 'react-redux'
import OrderDetails from './pending-actions/pages/orderDetails/OrderDetails'
import { clearCreateOrderItems, clearInvoiceData, clearOrderLogs, clearPackingListForPrint, clearSingleOrderDetailsData, clearWmsOrderDetailsData, getSingleOrderDetails } from './store/store'


const OrderDetailsSideSlider = ({ sellerId, setShipmentSlider = () => {}, isModalOpen, setIsModalOpen = () => {}, handleGetParentData = () => {}}) => {

  const dispatch = useDispatch()

  const handleCloseScrolll = () => {
    setIsModalOpen(false)
  }

  const handleSideBarOpened = () => {
    dispatch(getSingleOrderDetails(sellerId))
    // dispatch(getOrderLogs(sellerId))
  }

  const handleSideBarClosed = () => {
    dispatch(clearSingleOrderDetailsData())
    dispatch(clearOrderLogs())
    dispatch(clearInvoiceData())
    dispatch(clearWmsOrderDetailsData())
    dispatch(clearPackingListForPrint())
    dispatch(clearCreateOrderItems())
  }

  return (
    <SideSheet
      isOpen={isModalOpen}
      toggle={handleCloseScrolll}
      unmountOnClose={true}
      onOpened={handleSideBarOpened}
      onClosed={handleSideBarClosed}
      modalClassName="modal-slide-in sidebar-todo-modal"
      contentClassName="p-0 bg-white"
      size="lg"
    >
      <OrderDetails setShipmentSlider={setShipmentSlider} sellerId={sellerId} setIsModalOpen={setIsModalOpen} handleGetParentData={handleGetParentData}/>
    </SideSheet>
  )
}
export default memo(OrderDetailsSideSlider)