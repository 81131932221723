import classNames from 'classnames'
import { useEffect, useState } from 'react'
import { ChevronLeft, ChevronRight, XCircle } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { Modal } from 'reactstrap'
import './index.scss'

const BinDisabledImageCarousel = (props) => {

  const { isOpen, toggle, imageData, handleRemoveImage } = props
  const [images, setImages] = useState([])
  const [currentIndex, setCurrentIndex] = useState(0)
  const { t } = useTranslation()

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? images?.length - 1 : prevIndex - 1))
  }

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex === images?.length - 1 ? 0 : prevIndex + 1))
  }

  const handleThumbnailClick = (index) => {
    setCurrentIndex(index)
  }

  useEffect(() => {
    setImages(imageData)

    if (imageData?.length === 0 && isOpen) {
      toggle()
    }
  }, [imageData])

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      className='image-carousel-modal-wrapper'
      onClosed={() => setCurrentIndex(0)}
      centered
    >
      <div className="carousel-container">
        <div className="text-center text-dark txt-h1-md">{t('Photo')} {currentIndex + 1} {t('of')} {images?.length}</div>
        <div className="carousel-main">
          <div onClick={currentIndex === 0 ? null : handlePrevious} className={`bg-dark-1 rounded-circle p-4px flex-center-center ${currentIndex === 0 ? 'cursor-default' : 'cursor-pointer'}`}>
            <ChevronLeft style={{ color: currentIndex === 0 ? 'var(--bs-primary-light)' : 'var(--bs-primary)' }} />
          </div>
          <div className='border border-dark-2 position-relative rounded-8px p-40px flex-center-center image-wrapper-main'>
            <div className='image-wrapper-content'>
              <img
                className="d-block w-100 image-preview"
                src={images?.[currentIndex] ? URL.createObjectURL(images[currentIndex]) : ''}
                alt={`Slide ${currentIndex + 1}`}
                width={300}
                height={300}
              />
            </div>
            {images?.[currentIndex]?.isUploaded ? null : <XCircle size={22} fill='var(--bs-primary)' color='var(--bs-white)' className='cursor-pointer position-absolute remove-image top-0 end-0' onClick={() => {
              handleRemoveImage(currentIndex)
              setImages(images?.filter((_, index) => index !== currentIndex))
              if (currentIndex > 0) { setCurrentIndex(currentIndex - 1) }
            }} />}
          </div>
          <div onClick={images?.length - 1 === currentIndex ? null : handleNext} className={`bg-dark-1 rounded-circle p-4px flex-center-center ${images?.length - 1 === currentIndex ? 'cursor-default' : 'cursor-pointer'}`}>
            <ChevronRight style={{ color: images?.length - 1 === currentIndex ? 'var(--bs-primary-light)' : 'var(--bs-primary)' }} />
          </div>
        </div>
        <div className={`carousel-thumbnails ${imageData?.length > 8 ? 'flex-center-start' : 'flex-center-center'} pt-6px gap-12px overflow-auto`}>
          {images?.map((image, index) => (
            <div onClick={() => handleThumbnailClick(index)} key={index}
              className={classNames({
                active: currentIndex === index
              })}>
              <img
                src={URL.createObjectURL(image)}
                alt={`Thumbnail ${index + 1}`}
                width={50}
                height={50}
              />
            </div>
          ))}
        </div>
      </div>
    </Modal>
  )
}

export default BinDisabledImageCarousel