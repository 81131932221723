import Button from '@src/@core/components/ui/button'
import { X } from 'react-feather'
import { Modal, ModalBody } from 'reactstrap'

const InfoPopupModal = ({ isOpen, toggle }) => {

  const InfoArray = [
    'If you don\'t assign an SKU to a batch, you will not be able to know when it expires if the SKU is perishable.',
    'During Putaway, SKUs with different batch numbers will not be put together on the same location.',
    'During picking, batched items are selected following the FEFO (First Expired First Out) method. This means that even if an item arrived later, it will be picked first if it\'s set to expire sooner.',
    'During packing, the batch number of the picked item should match with the batch number listed in the manifest document.'
  ]
  return (
    <Modal
      isOpen={isOpen}
      centered
      toggle={toggle}
      contentClassName="rounded-12px"
    >
      <ModalBody className="d-flex flex-column gap-24px py-16px px-20px ">
        <div className="d-flex justify-content-between align-items-center">
          <div className="txt-body-md text-dark">Batched SKUs</div>
          <X onClick={toggle} size={16} className="text-dark cursor-pointer" />
        </div>
        <div className="d-flex flex-column gap-8px">
          {
            InfoArray.map((ele, ind) => <div className="py-8px px-12px bg-light-3 text-dark-6 txt-sub-rg  rounded-right info-modal-row" key={ind}>{ele}</div>)
          }
        </div>
        <Button
          onClick={toggle}
          className="w-100 justify-content-center"
          ofStyle="outlined"
        >
          Go Back
        </Button>
      </ModalBody>
    </Modal>
  )
}

export default InfoPopupModal
