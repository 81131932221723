// ** React Imports
import { Fragment, memo, useEffect } from 'react'

// ** Third Party Components
import classnames from 'classnames'

// ** Store & Actions
import { handleContentWidth, handleMenuCollapsed, handleMenuHidden, handleSecondaryMenu } from '@store/layout'
import { useDispatch, useSelector } from 'react-redux'

// ** ThemeConfig
import themeConfig from '@configs/themeConfig'

// ** Styles
import 'animate.css/animate.css'
import Footer from '../footer'

const LayoutWrapper = props => {
  // ** Props
  const { children, routeMeta } = props

  // ** Store Vars
  const dispatch = useDispatch()
  const layoutStored = useSelector(state => state.layout.layout)
  const menuCollapsed = useSelector(state => state.layout.menuCollapsed)
  const menuHidden = useSelector(state => state.layout.menuHidden)
  const contentWidth = useSelector(state => state.layout.contentWidth)
  const layoutStore = useSelector(state => state.layout)
  const isLayoutReady = layoutStore.isLayoutReady
  // const isFeatureNotificationBannerVisible = useSelector(store => store.auth.isFeatureNotificationBannerVisible)

  // const navbarStore = store.navbar
  // const layoutStored = store.layout.layout
  // const contentWidth = store.layout.contentWidth
  //** Vars
  const appLayoutCondition =
    (layoutStored.layout === 'horizontal' && !routeMeta) || (layoutStored.layout === 'horizontal' && routeMeta && !routeMeta.appLayout)
  const Tag = appLayoutCondition ? 'div' : Fragment

  // ** Clean Up Function
  const cleanUp = () => {
    if (routeMeta) {
      if (routeMeta.contentWidth && routeMeta.contentWidth === contentWidth) {
        dispatch(handleContentWidth(themeConfig.layout.contentWidth))
      }
      if (routeMeta.menuCollapsed && routeMeta.menuCollapsed === menuCollapsed) {
        dispatch(handleMenuCollapsed(!menuCollapsed))
      }
      if (routeMeta.menuHidden && routeMeta.menuHidden === menuHidden) {
        dispatch(handleMenuHidden(!menuHidden))
      }
    }
  }

  // ** ComponentDidMount
  useEffect(() => {
    if (routeMeta) {
      if (routeMeta.contentWidth) {
        dispatch(handleContentWidth(routeMeta.contentWidth))
      }
      if (routeMeta.menuCollapsed) {
        dispatch(handleMenuCollapsed(routeMeta.menuCollapsed))
      }
      if (routeMeta.menuHidden) {
        dispatch(handleMenuHidden(routeMeta.menuHidden))
      }
      dispatch(handleSecondaryMenu(routeMeta.hasSecondaryMenu))
    } else {
      dispatch(handleSecondaryMenu(false))
    }
    return () => cleanUp()
  }, [routeMeta])

  return (
    <div
      style={{ transition: 'all .5s' }}
      className={classnames('app-content content overflow-hidden px-0 position-relative', {
        [routeMeta ? routeMeta.className : '']: routeMeta && routeMeta.className
        // 'with-banner': isFeatureNotificationBannerVisible
        // 'show-overlay': navbarStore.query.length
      })}
    >
      <div className="content-overlay"></div>
      <div className="header-navbar-shadow" />
      <div
        className={classnames('mb-3', {
          'content-wrapper m-0': routeMeta && !routeMeta.appLayout,
          'content-area-wrapper': routeMeta && routeMeta.appLayout,
          'p-0': contentWidth === 'boxed'
        })}
      >
        <Tag {...(appLayoutCondition ? { className: 'content-body' } : {})}>{children}</Tag>
      </div>
      {isLayoutReady && <Footer/> }
    </div>
  )
}

export default memo(LayoutWrapper)
