import classNames from 'classnames'
import { DropdownItem, DropdownMenu } from 'reactstrap'
import './styles.scss'

const SkuItemSkeleton = ({ width, editSkuClassName }) => {
  return (
    <DropdownMenu style={{ width }} className={classNames('skeleton-component', {'custom-skeleton-wrapper':editSkuClassName})}>
      <DropdownItem className="skeleton-card w-100">
        <div className="skeleton-main">
          <div className="skeleton-box skeleton-effect for-image"></div>
          <div className="skeleton-body">
            <div className="skeleton-box skeleton-effect for-title w-50"></div>
            <div className="skeleton-box skeleton-effect for-description w-75"></div>
            <div className="skeleton-box skeleton-effect for-sub-description w-100"></div>
          </div>
        </div>
      </DropdownItem>
    </DropdownMenu>
  )
}

export default SkuItemSkeleton
