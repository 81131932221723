import BarcodeScanner from '@src/@core/components/ui/barcodeScanner'
import Button from '@src/@core/components/ui/button'
import { ExpandableCustomTable } from '@src/@core/components/ui/globalTable/ExpandableCustomTable'
import { ALL_COLUMN_NAME_MAPPING } from '@src/@core/components/ui/globalTable/globalTable.constant'
import SideSheet from '@src/@core/components/ui/sideSheet'
import { KEYBOARD_KEYS } from '@src/App.constants'
import { assembledKitDetails, clearAssembleBarcodeDetail, clearAssembleExportData, exportAssembledSku } from '@src/views/inventory/store'
import { useEffect, useState } from 'react'
import { Download } from 'react-feather'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
// import no_sku_image from "@src/assets/images/omniful/no_sku_image.svg"
import barcode from '@src/assets/images/icons/barcode.svg'
// import sku from "@src/assets/images/icons/sku.svg"
import { initRealTime } from '@src/network/RealTime'
import PerfectScrollbar from 'react-perfect-scrollbar'
import './styles.scss'
// import classNames from "classnames"

const AssmbledKitBarcodes = (props) => {
  const { isModalOpen, toggle, skuData, taskId, hubId } = props
  const { control, watch, clearErrors, setValue } = useForm()

  const { t } = useTranslation()
  const dispatch = useDispatch()

  const hub_id = hubId || useSelector(state => state.auth.selectedGlobalHubId)
  const pusherLoading = useSelector(state => state.auth.pusherLoading)

  const loading = useSelector(state => state.inventory.assembledKitData.loading)
  const error = useSelector(state => state.inventory.assembledKitData.error)
  const success = useSelector(state => state.inventory.assembledKitData.success)
  const data = useSelector(state => state.inventory.assembledKitData.data?.data)
  const meta = useSelector(state => state.inventory.assembledKitData.data?.meta)

  const [tableData, setTableData] = useState([])
  const [tableColumns, setTableColumns] = useState([])
  const [param, setParams] = useState(meta)

  // const tableColumns = [
  //   {
  //     id: 1,
  //     name: t("S. No."),
  //     key: "serialNumber",
  //     width: "60px",
  //     cell: (_, index) => (
  //       // eslint-disable-next-line no-mixed-operators
  //       <div>{(meta?.current_page - 1) * meta?.per_page + index + 1}</div>
  //     )
  //   },
  //   {
  //     id: 2,
  //     name: t("Assembled Kit Barcode"),
  //     key: "assembled_kit_barcode",
  //     minWidth: "80px",
  //     maxWidth: "200px",
  //     selector: (row) => {
  //       return (
  //         <div className="text-truncate" title={row.barcode}>
  //             {row.barcode}
  //         </div>
  //       )
  //     }
  //   },
  //   {
  //     id: 3,
  //     name: t("SKU Barcodes"),
  //     key: "skus",
  //     minWidth: "600px",
  //     selector: (row) => {
  //       return (
  //         <div className="text-primary flex-start-start">
  //           {row.assembled_skus?.map((assembleSkus, index) => {
  //             return (
  //               <div key={assembleSkus} className={classNames("flex-center-start px-8px py-12px assembled-skus-container", {"border-end border-dark-1" : index !== row.assembled_skus.length - 1})}>
  //                 <div className="sku-images-wrapper flex-center-start me-8px">
  //                   <img
  //                     className="w-100 h-100 img-fluid"
  //                     loading="lazy"
  //                     onError={({ currentTarget }) => {
  //                       currentTarget.onerror = null
  //                       currentTarget.src = no_sku_image
  //                     }}
  //                     src={
  //                       assembleSkus.sku.images?.[0]?.url
  //                         ? assembleSkus.sku.images[0].url
  //                         : no_sku_image
  //                     }
  //                     alt={assembleSkus.sku.name}
  //                     width={50}
  //                     height={50}
  //                   />
  //                 </div>
  //                 <div className="flex-start-start flex-column text-start">
  //                   <div className="text-dark txt-sub-rg flex-center-start">
  //                      <div>
  //                         <img
  //                           className="w-100 h-100 img-fluid"
  //                           loading="lazy"
  //                           src={barcode}
  //                           alt="barcode"
  //                           width={50}
  //                           height={50}
  //                         />
  //                       </div>
  //                       <div className="ms-4px d-flex"><span className="align-items-center">{assembleSkus.barcode}</span></div>
  //                   </div>
  //                   <div className="text-dark-5 txt-asst-rg flex-center-start">
  //                       <div>
  //                           <img
  //                             className="w-100 h-100 img-fluid"
  //                             loading="lazy"
  //                             src={sku}
  //                             alt="sku"
  //                             width={50}
  //                             height={50}
  //                           />
  //                         </div>  
  //                        <div className="ms-4px d-flex"><span className="align-items-center">{assembleSkus.sku.seller_sku_code}</span></div>
  //                   </div>
  //                 </div>
  //               </div>
  //             )
  //           })}
  //         </div>
  //       )
  //     }
  //   }
  // ]

  const handleMapTableColumn = (data) => {
    const mappedColumns = data.map((columnName, index) => {
      return {
        id: index + 1,
        name: columnName,
        minWidth: '200px',
        selector: row => row.value[index]
      }
    })
    return [
      {
        id: data.length + 1,
        name: <div className="w-100 text-center">{'S.No.'}</div>,
        minWidth: '60px',
        maxWidth: '60px',
        cell: (row, index) => <div className="flex-center-center w-100">
          {((meta.current_page - 1) * meta.per_page) + index + 1}
        </div>
      }
    ].concat(mappedColumns)
  }

  const handleScanAWBBarcodeNumber = (e) => {
    if (e.key === KEYBOARD_KEYS.ENTER) {
      if (e.target.value.trim()) clearErrors('sku_barcode')
      const skuBarcode = e.target.value.trim()
      const newParams = { sku_barcode: skuBarcode }
      setParams({ ...param, newParams })
      const pathParams = {
        hub_id,
        assembly_task_id: taskId,
        assembly_task_item_id: skuData?.id,
        param: { ...newParams }
      }
      dispatch(assembledKitDetails({ pathParams }))
    }
  }

  const handlePagination = (page) => {
    const newParam = { ...param, per_page: page.per_page, page: page.selected }
    setParams({ ...param, page: page.selected, per_page: page.per_page || 10 })
    const pathParams = {
      hub_id,
      assembly_task_id: taskId,
      assembly_task_item_id: skuData?.id,
      param: { ...newParam }
    }
    dispatch(assembledKitDetails({ pathParams }))
  }

  useEffect(() => {
    if (data?.headers?.length) {
      setTableColumns(handleMapTableColumn(data.headers))
      const mappedData = []
      if (data.kit_details?.length) {
        data.kit_details.forEach((kit, index) => {
          mappedData.push({
            id: index,
            value: kit
          })
        })
      }
      setTableData(mappedData)

    } else {
      setTableData([])
      setTableColumns([])
    }
  }, [data])

  useEffect(() => {
    const pathParams = {
      hub_id,
      assembly_task_id: taskId,
      assembly_task_item_id: skuData?.id,
      param
    }
    if (skuData?.id) {
      dispatch(assembledKitDetails({ pathParams }))
    }
  }, [skuData])

  const handleExportAssembledDetailsFunc = () => {
    const pathParams = {
      hub_id,
      assembly_task_id: taskId,
      assembly_task_item_id: skuData?.id
    }
    dispatch(exportAssembledSku({ pathParams }))
  }

  const handleExportAssembledDetails = () => {
    initRealTime(handleExportAssembledDetailsFunc)
  }

  const onModalClosed = () => {
    setParams()
    setValue('sku_barcode', '')
    dispatch(clearAssembleBarcodeDetail())
    dispatch(clearAssembleExportData())
  }

  const handleClearAWBNumber = () => {
    dispatch(clearAssembleBarcodeDetail())
    setValue('sku_barcode', '')
    setParams()
    const pathParams = {
      hub_id,
      assembly_task_id: taskId,
      assembly_task_item_id: skuData?.id
    }
    if (skuData?.id) {
      dispatch(assembledKitDetails({ pathParams }))
    }
  }

  return (
    <SideSheet
      title="Serialised Barcodes"
      isOpen={isModalOpen}
      modalClassName="modal-slide-in sidebar-todo-modal"
      toggle={toggle}
      size="md"
      onClosed={onModalClosed}
    >
      <PerfectScrollbar>
        <div className="assembled-kit-wrapper m-24px">
          <div className="flex-center-between mb-24px">
            <div className="">
              <BarcodeScanner
                startIcon={
                  <img
                    className="w-100 h-100 img-fluid"
                    loading="lazy"
                    src={barcode}
                    alt="search"
                    width={50}
                    height={50}
                  />
                }
                placeholder="Scan Assembled Kit Barcode or SKU Barcode"
                control={control}
                name="sku_barcode"
                hasValue={watch('sku_barcode')}
                onKeyDown={handleScanAWBBarcodeNumber}
                autoFocus
                handleClear={handleClearAWBNumber}
              />
            </div>
            <div>
              <Button
                Download
                className="w-100"
                onClick={handleExportAssembledDetails}
                disabled={pusherLoading}
              >
                <span>
                  <Download className="me-6px" size={14} />
                  {t('Export')}
                </span>
              </Button>
            </div>
          </div>
          <div className="custom-assembled-table-wrapper">
            <ExpandableCustomTable
              loading={loading}
              error={error}
              success={success}
              data={tableData}
              columns={tableColumns}
              showColumnsDropdown={false}
              showColumnsTableHeader={false}
              columnName={ALL_COLUMN_NAME_MAPPING.ASSEMBLED_KIT_BARCODES}
              meta={meta}
              handlePagination={handlePagination}
              useReactPaginate={false}
            />
          </div>
        </div>
      </PerfectScrollbar>
    </SideSheet>
  )
}

export default AssmbledKitBarcodes
