import { iButtonProps } from '@src/app.types';
import React from 'react';
import { Spinner } from 'reactstrap';
import './styles.scss';

const Button = React.forwardRef<HTMLButtonElement, iButtonProps>((props, ref) => {
  const {
    ofType = 'regular',
    ofStyle = 'filled',
    alignIcon = 'left',
    iconSize = 20,
    loading = false,
    disabled = false,
    icon,
    children,
    className,
    ...rest
  } = props;

  const buttonClass = `${className || ''} omniful-${ofType}-btn omniful-${ofStyle}-btn`;

  const Icon = icon;

  return (
    <button
      className={`omniful-button ${buttonClass}`}
      // onClick={handleClick}
      disabled={loading || disabled}
      {...rest}
      ref={ref}
    >
      {loading && alignIcon === 'left' &&
        <Spinner
          style={{
            height: '16px',
            width: '16px'
          }}
          size="sm" />
      }
      {!loading && Icon && alignIcon === 'left' && <Icon size={iconSize} />}
      {children}
      {!loading && Icon && alignIcon === 'right' && <Icon size={iconSize} />}
      {loading && alignIcon === 'right' &&
        <Spinner
          style={{
            height: '16px',
            width: '16px'
          }}
          size="sm" />
      }
    </button>
  );
});

Button.displayName = 'Button';

export default Button;