import CopyOnClick from '@src/@core/components/ui/click-and-copy'
import { ExpandableCustomTable } from '@src/@core/components/ui/globalTable/ExpandableCustomTable'
import { ALL_COLUMN_NAME_MAPPING } from '@src/@core/components/ui/globalTable/globalTable.constant'
import SKUDetailsCol from '@src/@core/components/ui/sku-details'
import no_sku_image from '@src/assets/images/omniful/no_sku_image.svg'
import SKUBarcodeModal from '@src/views/sales/components/SKUBarcodeModal'
import OrderDetailsSideSlider from '@src/views/sales/OrderDetailsSideSlider'
import { resetSuccess } from '@src/views/sales/store/store'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import OrderItemsChildTable from '../../ordersForApproval/OrderItemsChildTable/OrderItemsChildTable'

const SplittedChildOrdersTab = () => {
  
  const TableColumnsHeader = [{ id: 'query', name: 'SKU' }]
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const singleSplitOrderDetails = useSelector(store => store.sales.singleSplitOrderDetails)
  const success = useSelector(store => store.sales.success)

  const [searchQuery, setSearchQuery] = useState({ column: TableColumnsHeader[0] })
  const [tableData, setTableData] = useState([])
  const [barcodeModalOpen, setBarcodeModalOpen] = useState(false)
  const [barcodeList, setBarcodeList] = useState([])
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [splittedOrderIdToShowDetails, setSplittedOrderIdToShowDetails] = useState(false)

  const handleSearchItem = ({ searchVal, selectedColumn = null }) => {
    const searchQuery = searchVal ? { column: selectedColumn, query: searchVal } : null
    setSearchQuery(searchQuery)
  }

  const handleOpenOrderDetailSideSheet = (id) => {
    setSplittedOrderIdToShowDetails(id)
    setIsModalOpen(true)
  }

  const toggleBarcodeModal = (barcodes) => {
    setBarcodeList(
      barcodes ? barcodes?.map((barcode) => ({ barcodeValue: barcode })) : []
    )
    setBarcodeModalOpen((p) => !p)
  }

  useEffect(() => {
    if (singleSplitOrderDetails) {
      setTableData(singleSplitOrderDetails.splitted_orders)
    }
  }, [singleSplitOrderDetails])

  useEffect(() => {
    if (success.putOrderOnHold || success.updateOrderStatus) {
      dispatch(resetSuccess())
      setIsModalOpen(false)
    }
  }, [success.putOrderOnHold, success.updateOrderStatus])

  const TableColumns = [
    {
      id: 2,
      name: t('Order ID'),
      minWidth: '320px',
      key: 'order_id',
      cell: (row) => (row.seller_sales_channel_order_id ? <CopyOnClick
        id={row.oms_order_id}
        handleDetails={handleOpenOrderDetailSideSheet}
        columnKey="order_id"
      >{row.seller_sales_channel_order_id}</CopyOnClick> : '-')
    },
    {
      id: 4,
      name: <div className='w-100 text-end text-truncate'>{t('Split Qty')}</div>,
      key: 'splitted_qty',
      minWidth: '100px',
      cell: (row) => <div className='w-100 text-end'>{row.total_ordered_quantity}</div>
    },
    {
      id: 5,
      name: <div className='w-100 text-end text-truncate'>{t('Packed Qty')}</div>,
      key: 'fulfilled_qty',
      minWidth: '100px',
      cell: (row) => <div className='w-100 text-end'>{row.total_packed_quantity}</div>
    },
    {
      id: 6,
      name: <div className='w-100 text-end text-truncate'>{t('Price')}</div>,
      minWidth: '100px',
      key: 'price',
      cell: (row) => <div className='w-100 text-end'>{row.total_price}</div>
    },
    {
      id: 7,
      name: t('Order Status'),
      minWidth: '100px',
      key: 'order_status',
      cell: (row) => row.status
    }
  ]

  const childTableColumns = [
    {
      id: 1,
      name: t('SKU Details'),
      minWidth: '350px',
      key: 'sku_details',
      cell: (row, index) => {
        const removeSpacesAndSpecialCharacters = (str = '') => {
          return `${str}`.replace(/[^a-zA-Z0-9]/g, (match) => {
            return `${match.charCodeAt(0).toString(16)}`
          })
        }
        return (
          <SKUDetailsCol
            skuDetailData={{
              isValidSku: true,
              id: `split_order_${removeSpacesAndSpecialCharacters(row.seller_sku?.name)}_${index + 1}_${Date.now()}`,
              image: {
                src: row.seller_sku?.images?.[0]?.default || no_sku_image,
                alt: row.seller_sku?.name
              },
              details: {
                name: row.seller_sku?.name,
                skuCode: row.seller_sku_code,
                columnKey: 'sku_details'
              }
            }}
          />
        )
      } 
    },
    {
      id: 2,
      name: t('SKU Barcode'),
      key: 'sku_barcode',
      minWidth: '200px',
      cell: (row) => {
        return (
          <div className="box-cell">
            <span className="text-truncate w-75">
              {row.seller_sku?.barcodes?.[0] || '-'}
            </span>
            {row.seller_sku?.barcodes?.length > 1 && (
              <span
                onClick={() => toggleBarcodeModal(row.seller_sku?.barcodes)}
                className={'text-primary ms-1 cursor-pointer w-25'}
              >
                {`+${row.seller_sku.barcodes.length - 1}`}
              </span>
            )}
          </div>
        )
      }
    },
    {
      id: 3,
      name: <div className='w-100 text-end text-truncate' title={t('Split Qty')}>{t('Split Qty')}</div>,
      key: 'splitted_qty',
      minWidth: '100px',
      cell: (row) => <div className='w-100 text-end'>{row.ordered_quantity}</div>
    },
    {
      id: 4,
      name: <div className='w-100 text-end text-truncate' title={t('Packed Qty')}>{t('Packed Qty')}</div>,
      key: 'fulfilled_qty',
      minWidth: '100px',
      cell: (row) => <div className='w-100 text-end'>{row.packed_quantity}</div>
    },
    {
      id: 5,
      name: <div className='w-100 text-end text-truncate' title={t('Price')}>{t('Price')}</div>,
      minWidth: '100px',
      key: 'price',
      cell: (row) => <div className='w-100 text-end'>{row.unit_price}</div>
    }
  ]


  return (
    <div>
      <ExpandableCustomTable
        loading={false}
        error={false}
        success={true}
        data={tableData}
        columns={TableColumns}
        columnName={ALL_COLUMN_NAME_MAPPING.SPLITTED_ORDERS}
        searchcolumnsList={TableColumnsHeader}
        search_column_query={searchQuery}
        handleQueryParams={handleSearchItem}
        meta={{}}
        useReactPaginate={false}
        showPagination={false}
        showColumnsTableHeader={false}
        expandableRows
        expandableRowsComponent={OrderItemsChildTable}
        expandableRowsComponentProps={{childTableColumns, childDataKey: 'order_items'}}
      />
      
      <OrderDetailsSideSlider
        sellerId={splittedOrderIdToShowDetails}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />

      <SKUBarcodeModal
        modalOpen={barcodeModalOpen}
        toggleSidebar={toggleBarcodeModal}
        data={barcodeList}
      />
    </div>
  )
}

export default SplittedChildOrdersTab