const BulkShip = ({ iswhite, fillColor='#222', height, width }) => {
  const color = iswhite ? 'white' : fillColor

  return (
    <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M2.01294 2.66085C2.01294 2.48154 2.1583 2.33617 2.33761 2.33617H4.28224C4.46156 2.33617 4.60692 2.48154 4.60692 2.66085V14.5596H16.7834C16.9627 14.5596 17.1081 14.7049 17.1081 14.8842C17.1081 15.0635 16.9627 15.2089 16.7834 15.2089H4.28224C4.10293 15.2089 3.95757 15.0635 3.95757 14.8842V2.98552H2.33761C2.1583 2.98552 2.01294 2.84016 2.01294 2.66085Z" fill={color}/>
      <path fillRule="evenodd" clipRule="evenodd" d="M5.06885 8.77259C5.06885 8.59327 5.21421 8.44791 5.39352 8.44791H10.394C10.5733 8.44791 10.7187 8.59327 10.7187 8.77259V13.7731C10.7187 13.9524 10.5733 14.0977 10.394 14.0977H5.39352C5.21421 14.0977 5.06885 13.9524 5.06885 13.7731V8.77259ZM5.7182 9.09726V13.4484H10.0693V9.09726H8.21844V10.7868C8.21844 10.9661 8.07307 11.1115 7.89376 11.1115C7.71445 11.1115 7.56908 10.9661 7.56908 10.7868V9.09726H5.7182Z" fill={color}/>
      <path fillRule="evenodd" clipRule="evenodd" d="M11.1802 8.77259C11.1802 8.59327 11.3255 8.44791 11.5049 8.44791H16.5053C16.6846 8.44791 16.83 8.59327 16.83 8.77259V13.7731C16.83 13.9524 16.6846 14.0977 16.5053 14.0977H11.5049C11.3255 14.0977 11.1802 13.9524 11.1802 13.7731V8.77259ZM11.8295 9.09726V13.4484H16.1807V9.09726H14.3298V10.7868C14.3298 10.9661 14.1844 11.1115 14.0051 11.1115C13.8258 11.1115 13.6804 10.9661 13.6804 10.7868V9.09726H11.8295Z" fill={color}/>
      <path fillRule="evenodd" clipRule="evenodd" d="M7.84668 2.66085C7.84668 2.48154 7.99204 2.33617 8.17136 2.33617H13.1718C13.3511 2.33617 13.4965 2.48154 13.4965 2.66085V7.66132C13.4965 7.84064 13.3511 7.986 13.1718 7.986H8.17136C7.99204 7.986 7.84668 7.84064 7.84668 7.66132V2.66085ZM8.49603 2.98552V7.33665H12.8472V2.98552H10.9963V4.46658C10.9963 4.64589 10.8509 4.79125 10.6716 4.79125C10.4923 4.79125 10.3469 4.64589 10.3469 4.46658V2.98552H8.49603Z" fill={color}/>
      <path fillRule="evenodd" clipRule="evenodd" d="M6.78254 15.209C6.19472 15.209 5.7182 15.6855 5.7182 16.2733C5.7182 16.8612 6.19472 17.3377 6.78254 17.3377C7.37037 17.3377 7.84689 16.8612 7.84689 16.2733C7.84689 15.6855 7.37037 15.209 6.78254 15.209ZM5.06885 16.2733C5.06885 15.3269 5.8361 14.5596 6.78254 14.5596C7.72899 14.5596 8.49624 15.3269 8.49624 16.2733C8.49624 17.2198 7.72899 17.987 6.78254 17.987C5.8361 17.987 5.06885 17.2198 5.06885 16.2733Z" fill={color}/>
      <path fillRule="evenodd" clipRule="evenodd" d="M14.5609 15.209C13.973 15.209 13.4965 15.6855 13.4965 16.2733C13.4965 16.8612 13.973 17.3377 14.5609 17.3377C15.1487 17.3377 15.6252 16.8612 15.6252 16.2733C15.6252 15.6855 15.1487 15.209 14.5609 15.209ZM12.8472 16.2733C12.8472 15.3269 13.6144 14.5596 14.5609 14.5596C15.5073 14.5596 16.2746 15.3269 16.2746 16.2733C16.2746 17.2198 15.5073 17.987 14.5609 17.987C13.6144 17.987 12.8472 17.2198 12.8472 16.2733Z" fill={color}/>
    </svg>
    
  )
}
export default BulkShip
