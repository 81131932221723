export const SALES_CHANNEL_TAG = {
  SUPERCOMMERCE : 'superCommerce',
  SHOPIFY : 'shopify',
  ZID : 'zid',
  WO_COMMERCE : 'woocommerce',
  MAGENTO_2 : 'magento-2',
  NOON : 'noon',
  TO_YOU : 'toyou',
  FLIPKART : 'flipkart',
  SALLA : 'salla',
  OPENCART : 'opencart',
  TAKER : 'taker',
  AMAZON_VENDOR_CENTRAL : 'amazonVendorCentral',
  AMAZON_SELLER_CENTRAL : 'amazonSellerCentral',
  ZID_RESEND_CODE : 'zidReSendCode',
  JAHEZ: 'jahez',
  CUSTOM : 'custom',
  ZID_FULFILLMENT : 'zidFulfillment',
  WO_COMMERCE_v2 : 'woocommerceV2',
  AMAZON: 'amazon',
  WEYANNA: 'weyanna',
  PIK: 'pik',
  SALLA_VERSION_TWO:'salla_v2',
  BLU: 'blu',
  WIX: 'wix',
  NANA: 'nana',
  ECWID:'ecwid',
  AMAZON_SELLER_FLEX: 'amazonSellerFlex',
}

export const CUSTOM_AND_NANA_TAGS = ['custom', 'nana']

export const SALES_CHANNEL_DETAILS_DEFAULT_TAB = 'DETAILS'

export const SALES_CHANNEL_MODAL_STATES = {
  CHANNEL_ADD_BUTTON_CLICK:'channelAddBtnClick',
  STORES:'stores',
  DETAILS:'details',
  STORES_ADD_BUTTON_CLICK:'storesAddBtnClick'
}
export const API_METHOD = {
  POST: 'post'
}

export const SALES_CHANNEL_API_PREFIX = '/api/v1/sales_channels'

export const SALES_CHANNEL_STATUS_DROPDOWN_OPTION = [
  { label: 'All', value: ''},
  { label: 'Enabled', value: true},
  { label: 'Disabled', value: false}
]

export const SALES_CHANNEL_CONFIG = {
  PICKING_CONFIG: { title: 'Partial Order Fulfillment', value:'picking_config', key:'PICKING_CONFIG'},
  ADDRESS_PREFERENCES_CONFIG: { title: 'Use Billing Address For Missing Shipping Address', value:'address_preferences_config', key:'ADDRESS_PREFERENCES_CONFIG'},
  CANCEL_ORDER_CONFIG: { title: 'Automated Order Cancellation On Sales Channel', value:'cancel_order_config', key:'CANCEL_ORDER_CONFIG'},
  NO_INVENTORY_CANCEL_ORDER_CONFIG: { title: 'Automated Order Rejection On Sales Channel', value:'no_inventory_cancel_order_config', key:'NO_INVENTORY_CANCEL_ORDER_CONFIG'},
  ORDER_SYNC_ALLOWED_CONFIG: {title: '', value:'order_sync_allowed_config', key:'ORDER_SYNC_ALLOWED_CONFIG'},
  AUTO_REJECTED_ORDERS_ON_HOLD_CONFIG: {title: 'Auto Rejected Orders On Hold', value:'auto_reject_orders_on_hold', key:'AUTO_REJECTED_ORDERS_ON_HOLD_CONFIG'},
  SC_ORDER_TAGS_CONFIG: {title: 'Sc Order Tag Config Orders', value:'sc_order_tags_config', key:'SC_ORDER_TAGS_CONFIG'},
  ON_HOLD_ORDERS_INVENTORY_RESERVATION_CONFIG: {title: 'On Hold Orders Inventory Reservation', value:'on_hold_orders_inventory_reservation_config', key:'ON_HOLD_ORDERS_INVENTORY_RESERVATION_CONFIG'},
  INCLUDE_BACKSTOCK_IN_AVAILABLE_INVENTORY_CONFIG: {title: 'Include Backstock In Available Inventory', value:'include_backstock_in_available_inventory_config', key:'INCLUDE_BACKSTOCK_IN_AVAILABLE_INVENTORY_CONFIG'},
}

export const SYNC_STATUS = {
  IN_PROGRESS:'in_progress'
}

export const CONFIG_STATUS_VALUE = {
  true:'On',
  false:'Off'
}
  
export const SALES_CHANNEL_SAMPLE_SHEET = {
  BULK_ORDER: {
    key: 'Bulk Order',
    value:'https://omniful-staging-uploads.s3.eu-central-1.amazonaws.com/sales-channel-orders/samples/test-sheet-order.csv'
  },
  IGNORE_INVENTORY_UPDATE: {
    key: 'Ignore Inventory Update',
    value:'https://omniful-staging-uploads.s3.eu-central-1.amazonaws.com/sales-channel-service/inventory-ignore/ignore_inventory_update_sheet.csv'
  },
  SKU_PRICE_UPDATE: {
    key: 'Sku Price Update',
    value:'https://omniful-staging-uploads.s3.eu-central-1.amazonaws.com/sales-channel-service/price_update/price_update_sheet.csv'
  }
}

export const SC_BULK_UPLOAD_SIDE_SHEET = {
  CATALOGUE_SHEET:{key:'catalogue_sheet', sheet_type:'catalogue-sheet'},
  CATEGORY_SHEET:{key:'category_sheet', sheet_type:'category-sheet'}
}

export const SC_CATEGORY = {
  SALES_CHANNEL:{key:'SalesChannel'},
  MARKET_PLACE:{key:'Marketplace'},
  SHIPPING:{key:'Shipping'}
}

export const SC_DOCUMENTATION_LINKS = {
  CUSTOM_INTEGRATION:{link:'https://docs.omniful.tech'},
  TENANT_REPORTS:{link:'https://docs.omniful.tech/#c2e2a1e4-51ff-446b-a004-dc0e657e2700'}
}

export const INTEGRATION_TYPES = {
  SHIPPING_ONLY_INTEGRATION: { label: 'Shipping Only Integration', value: 'shipping_only_integration' },
  FULFILLMENT_PLUS_SHIPPING_INTEGRATION: { label: 'Fulfillment + Shipping Integration', value: 'fulfillment_plus_shipping_integration' }
}

export const SELLER_SALES_CHANNEL_TYPES = {
  SHIPPING_ONLY_INTEGRATION: { label: 'Shipping Only Integration', value: 'shipping_only_integration' }
}

export const GRANT_TYPE = {
  REFRESH_TOKEN:{key:'refresh_token'}
}

export const CUSTOM_APP_CONFIGURATION_TYPES = {
  ORDER_STATUS_CONFIG:{value:'order_status_config'},
  WEBHOOK_CONFIG:{value:'webhook_config'},
  RETURN_ORDER_STATUS_CONFIG:{value:'return_order_status_config'},
  RETURN_REQUEST_STATUS_CONFIG:{value:'return_request_status_config'}
}

export const URL_REGEX = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i

export const ALLOWED_ORDER_TAG_TYPE = {
  ALL: 'all',
  ADD_TAGS: 'add_tags'
}

export const SYNC_ENTITY = {
  ORDERS:{key:'ORDERS', title:'Orders', value:'orders'},
  RETURN_ORDERS:{key:'RETURN_ORDERS', title:'Return Orders', value:'returns' },
  INVENTORY:{key:'INVENTORY', title:'Inventory', value:'inventory'},
  CATALOG:{key:'CATALOG', title:'Catalog', value:'products'}
}

export const EXTERNAL_WMS_TAGS = {
  CUSTOM_WMS:'customWms',
  CROSS_WORKSPACE_WMS:'crossWorkspaceWms'
}

export const INTEGRATION_TYPE_OPTIONS = {
  fulfillment_only: {
    label: 'Fulfillment',
    value: 'fulfillment_only'
  },
  fulfillment_and_shipping: {
    label: 'Fulfillment and Shipping',
    value: 'fulfillment_and_shipping'
  }
}

export const EXTERNAL_ORDER_STATUS_CODE = {
  CANCELLED: 'canceled'
}