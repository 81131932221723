import Button from '@src/@core/components/ui/button'
import warningImage from '@src/assets/images/catalog/bundlesku/warning-icon.svg'
import { X } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { Modal, ModalBody, ModalFooter } from 'reactstrap'
import { ADD } from '../catalog.constants'

const SkuQuantityChangeWarningModal = ({ toggle, isSkuDetailsChange, skuDetailChangedType, confirmModalHandler, ...rest }) => {
  const { t } = useTranslation()
  return (
    <Modal
      className='warning-modal-for-quantity-change'
      modalClassName='warning-modal-for-quantity-change-classname'
      centered
      {...rest}
      // isOpen={warningValueChangeModal}
      toggle={toggle}
    >
      <div className='warning-modal-header'>
        <div className='modal-title m-auto'>
          <img src={warningImage} />
        </div>
        <span
          className='bg-white cursor-pointer'
          onClick={toggle}
        >
          <X size={18} className='text-dark-6' />
        </span>
      </div>
      <ModalBody className='d-flex flex-column gap-16px justify-content-center mt-2 mb-2 color-black'>
        <div className='txt-h1-sb text-dark'>
          {isSkuDetailsChange ? t('Attention! Modifying number of SKUs will reset the kit prices.') : t('Attention! Modifying the quantity will reset the kit prices.')}
        </div>
        <div className='text-dark txt-body-rg'>
          {isSkuDetailsChange ? t(
            `You are attempting to ${skuDetailChangedType === ADD ? 'add' : 'remove'} an SKU. This will reset the kit prices. Are you sure you want to proceed with this change?`
          ) : t('You are attempting to change the quantity of an SKU. This will reset the kit prices. Are you sure you want to proceed with this change?')}
        </div>
      </ModalBody>
      <ModalFooter className='d-flex justify-content-end align-items-center gap-1'>
        <Button ofStyle='noBackground' onClick={toggle}>
          {t('Don’t Modify')}
        </Button>
        <Button onClick={confirmModalHandler}>{t('Yes, Modify')}</Button>
      </ModalFooter>
    </Modal>
  )
}

export default SkuQuantityChangeWarningModal
