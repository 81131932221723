import { ExpandableCustomTable } from '@src/@core/components/ui/globalTable/ExpandableCustomTable'
import InputField from '@src/@core/components/ui/input-field'
import QuantityButton from '@src/@core/components/ui/quantity-button'
import { KEYBOARD_KEYS } from '@src/App.constants'
import { useEffect, useState } from 'react'
import { Search } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

const SearchBatchAndUpdateQuantity = ({
  skuBatchDetails,
  selectedSkuBatches,
  uniqueItemId,
  handleIncreaseQtyOfSelectedBatch,
  handleDecreaseQtyOfSelectedBatch,
  handleChangeQtyOfSelectedBatch,
  totalSelectedBatch,
  orderQuantity
}) => {
  //states
  const [batchTableData, setBatchTableData] = useState([])
  const getBatchesForSKUCodeLoading = useSelector(
    (state) => state.sales.loading.getBatchesForSKUCode
  )

  //hooks
  const { t } = useTranslation()

  //function
  const handleSetBatchTableData = (data) => {
    setBatchTableData(data.batch_inventory)
  }

  const handleSkuBatchFilter = (e) => {
    if ((e.key === KEYBOARD_KEYS.ENTER)) {
      setBatchTableData((pre) => pre.filter((batch) => batch.batch.external_batch_id.toUpperCase().includes(e.target.value.toUpperCase())))
    }
  }

  const handleChangeSearchedBatch = (e) => {
    if (!e.target.value) handleSetBatchTableData(skuBatchDetails)
  }

  useEffect(() => {
    if (skuBatchDetails.batch_inventory) {
      handleSetBatchTableData(skuBatchDetails)
    }
  }, [skuBatchDetails])

  //constant
  const selectBatchTableColumns = [
    {
      id: 1,
      name: t('Batch'),
      width: '140px',
      key: 'batch',
      cell: (row) => <div className="w-100">{row.batch.external_batch_id}</div>
    },
    {
      id: 2,
      name: t('Expiry'),
      width: '152px',
      key: 'expiryDate',
      cell: (row) => <div className="w-100">{row.batch.expiry_date}</div>
    },
    {
      id: 3,
      name: <div className="w-100 text-end">{t('Avl Qty')}</div>,
      width: '120px',
      key: 'avlQty',
      cell: (row) => <div className="w-100 text-end">{row.available_quantity}</div>
    },
    {
      id: 4,
      name: <div className="w-100 text-center">{t('Item Qty')}</div>,
      maxWidth: '150px',
      key: 'itemQty',
      cell: (row) => (
        <QuantityButton
          minQuantityEnabled={false}
          maxQuantityEnabled={
            !(
              (selectedSkuBatches?.[row.batch.external_batch_id]?.quantity ||
                0) < row.available_quantity &&
              totalSelectedBatch < orderQuantity
            )
          }
          decreaseQuantity={() => handleDecreaseQtyOfSelectedBatch(uniqueItemId, row.batch.external_batch_id)
          }
          increaseQuantity={() => handleIncreaseQtyOfSelectedBatch(uniqueItemId, row.batch.external_batch_id)
          }
          quantity={
            selectedSkuBatches?.[row.batch.external_batch_id]?.quantity || 0
          }
          onChange={(e) => handleChangeQtyOfSelectedBatch(
            uniqueItemId,
            row.batch.external_batch_id,
            row.available_quantity,
            e.target.value
          )
          }
        />
      )
    }
  ]
  return (
    <div className="d-flex flex-column gap-16px">
      <InputField
        onKeyDown={handleSkuBatchFilter}
        onChange={handleChangeSearchedBatch}
        startAdornment={<Search size={16} />}
        placeholder="Search Batch"
        isClearable
      />
      <div className="select-batch-table-container">
        <ExpandableCustomTable
          loading={getBatchesForSKUCodeLoading}
          error={false}
          success={true}
          showColumnsTableHeader={false}
          showColumnsDropdown={false}
          useReactPaginate={false}
          showPagination={false}
          columns={selectBatchTableColumns}
          data={batchTableData}
        />
      </div>
    </div>
  )
}

export default SearchBatchAndUpdateQuantity
