import { ExpandableCustomTable } from '@src/@core/components/ui/globalTable/ExpandableCustomTable'
import SKUDetailsCol from '@src/@core/components/ui/sku-details'
import ComponentSpinner from '@src/@core/components/ui/spinner/Loading-spinner'
import { getBinDetails } from '@src/views/hubs/store'
import { clearLastScannedBin, clearSkuQtyUpdated } from '@src/views/inventory/store'
import classNames from 'classnames'
import { memo, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { BIN_TYPE, BULK_UPLOAD_PUSHER_RES } from '../../constant'
import './grnDetails.scss'

const BinDetail = ({ selectedBin }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const { loading: binItemDetailsLoading, data: binItemDetailsData } =
    useSelector((state) => state.revampedHubs.binItemDetails)
  const lastScannedBin = useSelector(store => store.inventory.grn.lastScannedBin)
  const skuQtyUpdated = useSelector(store => store.inventory.grn.skuQtyUpdated)
  const bulkUploadPusherRes = useSelector(store => store.auth.pusherResponse)
  const selectedGlobalHubId = useSelector(
    (state) => state.auth.selectedGlobalHubId
  )

  useEffect(() => {
    if (selectedBin.id && (selectedBin.id !== binItemDetailsData?.grn_bin?.bin?.bin_id || bulkUploadPusherRes[BULK_UPLOAD_PUSHER_RES])) {
      dispatch(
        getBinDetails({ hubId: selectedGlobalHubId, binId: selectedBin.id, type: selectedBin.container_type })
      )
    }
  }, [selectedBin, bulkUploadPusherRes])

  useEffect(() => {
    if (selectedBin.barcode === lastScannedBin) {
      dispatch(
        getBinDetails({ hubId: selectedGlobalHubId, binId: selectedBin.id, type: selectedBin.container_type })
      )
      dispatch(clearLastScannedBin())
    } else if (selectedBin.barcode === skuQtyUpdated.bin_barcode) {
      dispatch(
        getBinDetails({ hubId: selectedGlobalHubId, binId: selectedBin.id, type: selectedBin.container_type })
      )
      dispatch(clearSkuQtyUpdated())
    }

  }, [lastScannedBin, skuQtyUpdated])

  const TableColumns = [
    {
      id: 1,
      name: t('SKU Details'),
      minWidth: '30%',
      maxWidth: '30%',
      reorder: true,
      cell: (row) => {
        return (
          <SKUDetailsCol
            skuDetailData={{
              isValidSku: true,
              id: row.sku.seller_sku_id,
              image: {
                src: row.sku.images?.find(img => img?.url)?.url,
                alt: row.sku.name
              },
              details: {
                name: row.sku.name,
                skuCode: row.sku.seller_sku_code,
                columnKey: 'sku_details'
              }
            }}
          /> 
        )
      } 
    },
    {
      id: 2,
      name:<div className="d-block w-100 text-end">{t('Qty')}</div>,
      minWidth: '20%',
      maxWidth: '20%',
      // reorder: true,
      cell: (row) => <div className="w-100 text-end">{row.quantity}</div>
    },
    {
      id: 3,
      name: t('Barcode Setting'),
      minWidth: '30%',
      maxWidth: '30%',
      reorder: true,
      cell: (row) => <div>
        {row.sku.is_serialised ? t('Serialised') : t('Non Serialised')}
      </div> 
    },
    {
      id: 4,
      name: t('Batch Detail'),
      minWidth: '21%',
      maxWidth: '21%',
      reorder: true,
      cell: (row) => (
        <div className="text-uppercase">
          <div className="txt-sub-rg">
            {row.sku.batch?.external_batch_id || '-'}
          </div>
          <div className="text-dark-6 txt-asst-rg">
            {row.sku.batch?.expiry_date}
          </div>
        </div>
      )
    }
  ]
  return (
    <div className="bin-detail-table-card mt-24px">
      <div className="d-flex align-items-center gap-10px p-12px bin-detail-header">
        <img height={24} width={24} src={selectedBin.type?.bin_border_icon} />
        <div
          className={classNames('detail-header', {
            'text-success': selectedBin.type?.id === BIN_TYPE.pass_bin.id,
            'text-danger': selectedBin.type?.id === BIN_TYPE.fail_bin.id
          })}
        >
          {selectedBin.barcode}
        </div>
      </div>
      {binItemDetailsLoading ? (
        <ComponentSpinner />
      ) : (
        <ExpandableCustomTable
          columns={TableColumns}
          data={binItemDetailsData?.grn_bin?.items || []}
          showPagination={false}
          loading={false}
          error={false}
          success={true}
          showColumnsTableHeader={false}
        />
      )}
    </div>
  )
}

export default memo(BinDetail)
