const CrossIcon = () => {
  return (
    <svg className='cross-icon' width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
      <g id="cancel_FILL0_wght300_GRAD0_opsz24 1">
        <circle cx="8" cy="8" r="6" fill="white" />
        <path id="Vector" d="M5.60031 11.1026L8.00031 8.70262L10.4003 11.1026L11.1029 10.4001L8.70286 8.00007L11.1029 5.60007L10.4003 4.89752L8.00031 7.29751L5.60031 4.89752L4.89776 5.60007L7.29776 8.00007L4.89776 10.4001L5.60031 11.1026ZM8.00143 14.3334C7.12547 14.3334 6.30211 14.1672 5.53136 13.8347C4.76059 13.5023 4.09014 13.0511 3.51999 12.4812C2.94984 11.9113 2.49846 11.2412 2.16588 10.4707C1.83329 9.70032 1.66699 8.87714 1.66699 8.00118C1.66699 7.12523 1.83321 6.30187 2.16566 5.53111C2.4981 4.76035 2.94927 4.08989 3.51916 3.51975C4.08906 2.94959 4.75922 2.49822 5.52964 2.16563C6.30005 1.83304 7.12324 1.66675 7.99919 1.66675C8.87515 1.66675 9.6985 1.83297 10.4693 2.16542C11.24 2.49786 11.9105 2.94903 12.4806 3.51891C13.0508 4.08881 13.5022 4.75898 13.8347 5.5294C14.1673 6.29981 14.3336 7.12299 14.3336 7.99895C14.3336 8.8749 14.1674 9.69826 13.835 10.469C13.5025 11.2398 13.0513 11.9102 12.4815 12.4804C11.9116 13.0505 11.2414 13.5019 10.471 13.8345C9.70057 14.1671 8.87738 14.3334 8.00143 14.3334ZM8.00031 13.3334C9.4892 13.3334 10.7503 12.8167 11.7836 11.7834C12.817 10.7501 13.3336 9.48895 13.3336 8.00007C13.3336 6.51118 12.817 5.25007 11.7836 4.21673C10.7503 3.1834 9.4892 2.66673 8.00031 2.66673C6.51142 2.66673 5.25031 3.1834 4.21698 4.21673C3.18364 5.25007 2.66698 6.51118 2.66698 8.00007C2.66698 9.48895 3.18364 10.7501 4.21698 11.7834C5.25031 12.8167 6.51142 13.3334 8.00031 13.3334Z" fill="#C21808"/>
      </g>
    </svg>
  )
}
export default CrossIcon