import CustomLabel from '@src/@core/components/ui/badge/CustomLabel'
import SideSheetCloseButton from '@src/@core/components/ui/page-header/sideSheetCloseButton'
import SideSheet from '@src/@core/components/ui/sideSheet'
import ComponentSpinner from '@src/@core/components/ui/spinner/Loading-spinner'
import { PageNotFound } from '@src/components/pageNotFound/pageNotFound'
import { cleanupReturnOrderData, getReturnOrderDetail, getReturnOrderHubDetail } from '@src/views/returns/store'
import { clearAwbDataForPrint } from '@src/views/sales/store/store'
import { memo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { Nav, NavItem, NavLink } from 'reactstrap'
import { RETURN_ORDER_TABS, STORE_VARIABLE_NAME_MAPPING } from '../../../returns.constants'
import ItemDetail from './itemDetail'
import './returnOrderDetails.scss'
import ReturnOrderLog from './returnOrderLog'

const Tab = memo(({ tab, active, onClick }) => {
  const { name, key } = tab
  const isActive = active === name

  return (
    <NavItem
      style={{
        borderBottom:
          active === name ? '2px solid var(--bs-primary)' : '2px solid transparent'
      }}
      className={'d-flex NavItemMainPending'}
    >
      <NavLink
        active={isActive}
        onClick={() => onClick(name)}
        role="tab"
        className="d-flex align-items-center px-1"
      >
        <span
          style={{
            fontSize: '12px',
            color: active === name ? 'var(--bs-primary)' : '#222222'
          }}
        >
          {key}
        </span>
      </NavLink>
    </NavItem>
  )
})
const ReturnOrderDetail = (props) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { isReturnOrderDetailModalOpen, handleCreateReverseShipmentModal, handleReturnOrderDetailModal, returnOrderToShowDetail, handleOrderDetailSidebar, handleCreateReturnOrderProcessingModal, fetchParentData = () => {} } = props

  const loading = useSelector(state => state.returns.loading)
  const returnOrderDetail = useSelector(state => state.returns.returnOrderDetail)
  const error = useSelector(state => state.returns.error)
  const returnOrderHubDetail = useSelector((state) => state.returns.returnOrderHubDetail)
  const [searchParams] = useSearchParams()
  const activeReturnOrderPage = searchParams.get('currentTab')
  const awbDataForPrint = useSelector(store => store.sales.awbDataForPrint)
  
  const TABS_DATA = {
    ITEM_DETAILS: { key: t('Item Details'), name: 'Item Details' },
    RETURN_ORDER_LOG: { key: t('Return Order Log'), name: 'Return Order Log' }
  }
  const componentMap = {
    [TABS_DATA.ITEM_DETAILS.name]: ItemDetail,
    [TABS_DATA.RETURN_ORDER_LOG.name]: ReturnOrderLog
  }
  const [activeTab, setActiveTab] = useState(TABS_DATA.ITEM_DETAILS.name)
  const toggleTab = (tab) => {
    setActiveTab(tab === activeTab ? activeTab : tab)
  }
  const ActiveComponent = componentMap[activeTab]
  const handleWhenModalOpened = () => {
    dispatch(getReturnOrderDetail(returnOrderToShowDetail.orderID))
    if (activeReturnOrderPage === RETURN_ORDER_TABS.ALL.id) {
      dispatch(getReturnOrderHubDetail(returnOrderToShowDetail.hub_id))
    }
  }
  const handleWhenModalClosed = () => {
    dispatch(cleanupReturnOrderData({ variableName: STORE_VARIABLE_NAME_MAPPING.RETURN_ORDER_DETAIL }))
    setActiveTab(TABS_DATA.ITEM_DETAILS.name)
    if (returnOrderHubDetail) {
      dispatch(cleanupReturnOrderData({ variableName: STORE_VARIABLE_NAME_MAPPING.RETURN_ORDER_HUB_DETAIL }))
    }
    if (awbDataForPrint) {
      dispatch(clearAwbDataForPrint())
    }
  }
  return (
    <SideSheet
      isOpen={isReturnOrderDetailModalOpen}
      toggle={handleReturnOrderDetailModal}
      onOpened={handleWhenModalOpened}
      onClosed={handleWhenModalClosed}
      unmountOnClose={true}
      modalClassName="modal-slide-in return-order-detail-modal"
      contentClassName="p-0 bg-white"
      size="lg"
    >
      <div className="return-order-details-header">
        <div className="d-flex justify-content-between align-items-start min-height-40px">
          <div className="d-flex flex-column align-items-start gap-8px">
            <div className="txt-h1-md text-dark d-flex align-items-center">
              <span>{t('Return Order Details')}</span>
              {returnOrderDetail?.status ? <CustomLabel title={returnOrderDetail.status} style={{ color: 'var(--bs-primary)', background: 'var(--bs-primary-light)' }} className="ms-12px" /> : null}
            </div>
            <div className="d-flex align-items-center gap-8px text-dark-6">
              <span>
                {t('Return Order ID')}: {returnOrderToShowDetail?.return_order_id || '-'}
              </span>
              <span>
                {returnOrderToShowDetail?.order_alias && `(${returnOrderToShowDetail.order_alias})`}
              </span>
            </div>
          </div>
          <SideSheetCloseButton clearButtonHandler={handleReturnOrderDetailModal} />
        </div>
        {returnOrderDetail ? <div>
          <Nav
            className="w-100 txt-sub-md"
            style={{
              borderRadius: '0'
            }}
            role="tablist"
          >
            {Object.entries(TABS_DATA).map(([key, tab]) => (
              <Tab key={key} tab={tab} active={activeTab} onClick={toggleTab} />
            ))}
          </Nav>
        </div> : <div></div>}
        {/* empty div added so that the gap will be remains in initial loading state */}
      </div>
      <PerfectScrollbar>
        <div className="return-order-detail-body">
          {loading.getReturnOrderDetail ? (
            <div className="spinner-container">
              <ComponentSpinner />
            </div>
          ) : error.getReturnOrderDetail ? (
            <PageNotFound />
          ) : (
            <ActiveComponent
              handleReturnOrderDetailModal={handleReturnOrderDetailModal}
              handleCreateReverseShipmentModal={handleCreateReverseShipmentModal}
              handleOrderDetailSidebar={handleOrderDetailSidebar}
              returnOrderToShowDetail={returnOrderToShowDetail}
              handleCreateReturnOrderProcessingModal={handleCreateReturnOrderProcessingModal}
              fetchParentData={fetchParentData}
            />
          )}
        </div>
      </PerfectScrollbar>
    </SideSheet>
  )
}
export default ReturnOrderDetail
