import no_sku_image from '@src/assets/images/omniful/no_sku_image.svg'
import { DropdownItem } from 'reactstrap'

const SkuDropdownItem = ({ itemDetails }) => {
  const image = itemDetails?.images?.[0]?.default || null
  return (
    <DropdownItem
      style={{ width: '100%', height: '110px' }}
      className='sku-card'
    >
      <div className='d-flex justify-content-between'>
        <div className='w-25'>
          <img
            onError={({ currentTarget }) => {
              currentTarget.onerror = null
              currentTarget.src = no_sku_image
            }}
            src={image || no_sku_image}
            alt=''
            height='60px'
            width='60px'
            style={{ borderRadius: '2px' }} />
        </div>
        <div className='w-75 d-flex flex-column ps-1'>
          <div className='sku-info text-truncate'>{itemDetails.name}</div>
          <div className=''>
            <span className='currency'>
              {itemDetails?.display_selling_price}
            </span>
            <span
              className='code'
              style={{
                marginLeft: '3px',
                marginRight: '3px',
                fontWeight: '500',
                fontSize: '10px',
                textDecorationLine: 'lineThrough',
                color: '#666666'
              }}
            >
              {itemDetails?.display_retail_price}
            </span>{' '}
            <span className='sku-discount'>
              {itemDetails?.offer ? `(${itemDetails?.offer}% off)` : ''}
            </span>
          </div>
          <div className='sku-code text-truncate'>
            SKU Code: {itemDetails?.seller_sku_code}
          </div>
          {/* <div className="available-qty">Available Qty: {itemDetails?.quantity ? itemDetails?.quantity : ''}</div> */}
        </div>
      </div>
    </DropdownItem>
  )
}

export default SkuDropdownItem