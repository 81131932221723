import { KEYBOARD_KEYS } from '@src/App.constants'
import classNames from 'classnames'
import { useEffect, useRef, useState } from 'react'
import { AlertTriangle } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { Input, InputGroup, InputGroupText, Spinner } from 'reactstrap'
import './styles.scss'

const QrCodeScannerBinDisabled = ({ handleSkuBarcodeScan, placeholder = 'Scan SKU Barcode', loading, disabled, doNotClear = false, setReason }) => {
  const { t } = useTranslation()
  const barcodeInputRef = useRef(null)
  const [isFocused, setIsFocused] = useState(false)
  const [isError, setIsError] = useState(false)

  useEffect(() => {
    barcodeInputRef.current.focus()
  }, [loading])

  const onKeyDown = (e) => {
    if (e.key === KEYBOARD_KEYS.ENTER) {
      e.preventDefault()
      const value = e.target.value?.trim()
      if (value === '') {
        setReason(null)
        setIsError(true)
        handleSkuBarcodeScan(false)
        return
      }
      setIsError(false)
      handleSkuBarcodeScan(value)

      if (!doNotClear) {
        barcodeInputRef.current.value = ''
      }
    }
  }

  const onChange = (e) => {
    if (!e.target.value) {
      setIsError(true)
      handleSkuBarcodeScan(false)
      setReason(null)
    } else {
      setIsError(false)
      handleSkuBarcodeScan(e.target.value)
    }
  }

  return (
    <section className='qrCodeScannerMainBinDisabled'>
      <InputGroup className={classNames('scanQrHeaderInputGroup', {
        focused: isFocused,
        error: isError
      })}>
        <InputGroupText className="border-0 pe-0 bg-white">
        </InputGroupText>
        <Input
          className="border-0 txt-sub-md barcodeInput flex-center-start bg-white"
          placeholder={t(placeholder)}
          name="searchAttribute"
          autoFocus
          autocomplete="off"
          innerRef={barcodeInputRef}
          onFocus={() => setIsFocused(true)}
          onBlur={() => {
            setIsFocused(false)
            setIsError(false)
          }}
          disabled={loading || disabled}
          onKeyDown={onKeyDown}
          onChange={onChange}
        />
        <InputGroupText className="border-0 ps-0">
          {loading && <Spinner color="primary" size="sm" style={{ borderWidth: '1px' }} />}
        </InputGroupText>
      </InputGroup>
      {isError &&
                <div className="text-danger flex-center-start gap-4px">
                  <AlertTriangle fill="var(--bs-danger)" color="var(--bs-white)" size={16} className="m-0 p-0" />
                  <span className="flex-center-start txt-asst-rg pt-2px">{t('Please scan barcode')}</span>
                </div>
      }
    </section>
  )
}

export default QrCodeScannerBinDisabled