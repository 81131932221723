// ** Dropdowns Imports
import Freshdesk from '@src/@core/components/ui/freshdesk'
import IntlDropdown from './IntlDropdown'
import NavbarHub from './NavbarHub'
// import CartDropdown from './CartDropdown'
import UserDropdown from './UserDropdown'
import './styles.scss'
// import NavbarSearch from './NavbarSearch'
// import NotificationDropdown from './NotificationDropdown'

// ** Third Party Components
import { Moon, Sun, Sunset } from 'react-feather'

// ** Reactstrap Imports
import OmnifulApps from './OmnifulApps'
// import DownloadedReportDetailAction from '@src/views/components/downloadedReportDetail/DownloadedReportDetailAction'

const NavbarUser = props => {
  // ** Props
  const { skin, setSkin } = props

  // ** Function to toggle Theme (Light/Dark)
  const ThemeToggler = () => {
    if (skin === 'dark') {
      return <Sun className='ficon' onClick={() => setSkin('light')} />
    } else if (skin === 'light') {
      return <Sunset className='ficon' onClick={() => setSkin('semi-dark')} />
    } else if (skin === 'semi-dark') {
      return <Moon className='ficon' onClick={() => setSkin('dark')} />
    }
  }

  return (
    <ul className='nav navbar-nav align-items-center ms-auto gap-1'>
      <li>
        <NavbarHub />
      </li>
      <IntlDropdown />
      {/* <li className='ms-2'><Bell /></li> */}
      <Freshdesk />
      {/* <NavItem className='d-none d-lg-block'>
        <NavLink className='nav-link-style'>
          <ThemeToggler />
        </NavLink>
      </NavItem> */}
      {/* <DownloadedReportDetailAction /> */}
      {/* <NavbarSearch /> */}
      {/* <CartDropdown/> */}
      {/* <NotificationDropdown /> */}
      <OmnifulApps/>
      <div className='navbar-user-name-link-separator' />
      <UserDropdown />
    </ul>
  )
}
export default NavbarUser
