// ** React Imports
import { Outlet, useLocation } from 'react-router-dom'

// ** Core Layout Import
// !Do not remove the Layout import
import Layout from '@layouts/VerticalLayout'
// import CustomVerticalLayout from './CustomVerticalLayout'

// ** Menu Items Array
import navigation from '@src/navigation/vertical'
import PrimaryMenu from './components/PrimaryMenu'

const RerenderComponent = ({ children }) => {
  const Component = ({ children }) => {
    return children
  }
  const location = useLocation()

  return <Component key={location.key}>
    {children}
  </Component>
}

const VerticalLayout = props => {
  // ** For ServerSide navigation
  // useEffect(() => {
  //   axios.get(URL).then(response => setMenuData(response.data))
  // }, [])

  return (
    <Layout menu={props => <PrimaryMenu {...props} />} menuData={navigation} {...props}>
      <RerenderComponent>
        <Outlet />
      </RerenderComponent>
    </Layout>
  )
}

export default VerticalLayout
