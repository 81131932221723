import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import CustomToast from '@src/@core/components/ui/custom-toast/CustomToast'
import { API_ENDPOINTS } from '@src/api.urls'
import { axiosInstance } from '@src/network/AxiosInstance'
import { bindToChannelEvent } from '@src/network/RealTime'
import { getApiUrl, paramsSerializer } from '@src/utility/Utils'

// SUPPLIER
export const createSupplier = createAsyncThunk('purchase/createSupplier', async ({ seller_id, ...data }) => {
  const res = axiosInstance.post('/api/v1/wms/suppliers', { ...data, seller_id })
  return res
})
export const editSupplier = createAsyncThunk('purchase/editSupplier', async ({ supplier_id, ...data }) => {
  const res = axiosInstance.put(`/api/v1/wms/suppliers/${supplier_id}`, { ...data })
  return res
})
export const getTenantSuppliers = createAsyncThunk('purchase/getTenantSuppliers', async params => {
  const res = await axiosInstance.get('/api/v1/wms/suppliers', { params })
  return res?.data
})
// suppliers of a seller
export const getAllSuppliers = createAsyncThunk('purchase/getAllSuppliers', async allParams => {
  const params = { ...allParams }
  delete params.seller_id
  const res = await axiosInstance.get(`/api/v1/wms/sellers/${allParams.seller_id}/suppliers`, { params, paramsSerializer })
  return res?.data
})

// PURCHASE ORDER
export const getPurchaseOrderList = createAsyncThunk('purchase/purchase-order', async allParams => {
  const params = { ...allParams }
  delete params.hub_id
  if (allParams.hub_id) {
    const res = await axiosInstance.get(`/api/v1/wms/hubs/${allParams.hub_id}/purchase_order`, { params, paramsSerializer })
    return res?.data
  }
})
// single purchase itemDetails
export const getSinglePurchaseOrderList = createAsyncThunk('purchase/single-purchase-order-list', async ({ pathParams, queryParams }) => {
  const res = await axiosInstance.get(`/api/v1/wms/hubs/${pathParams.hub_id}/purchase_order/${pathParams.seller_id}`, { params: queryParams })
  return res?.data
})
// single gateEntry details
export const getSingleGateEntryList = createAsyncThunk('gateentry/single-gate-entry-list', async ({ pathParams }) => {
  const res = await axiosInstance.get(`/api/v1/wms/hubs/${pathParams.hub_id}/purchase_order/${pathParams.seller_id}/gate_entry`)
  return res?.data
})
// single GRN details
export const getSingleGrnList = createAsyncThunk('grn/single-grn-list', async ({ pathParams }) => {
  const res = await axiosInstance.get(`/api/v1/wms/hubs/${pathParams.hub_id}/purchase_order/${pathParams.seller_id}/grn`, { params: pathParams.param })
  return res?.data
})
// single GRN details
export const getSinglePutawayList = createAsyncThunk('grn/single-putaway-list', async ({ pathParams }) => {
  const res = await axiosInstance.get(`/api/v1/wms/hubs/${pathParams.hub_id}/purchase_order/${pathParams.seller_id}/put_away`, { params: pathParams.param })
  return res?.data
})

// reason details
export const getGrnReasons = createAsyncThunk('grn/reason-list', async ({ pathParams }) => {
  const res = await axiosInstance.get(`/api/v1/wms/hubs/${pathParams.hub_id}/purchase_order/${pathParams.seller_id}/grn/sku?seller_sku_id=${pathParams.seller_sku_id}`)
  return res?.data
})

// export const getSinglePurchaseOrder = createAsyncThunk('purchase/single-purchase-order', async () => {
//   const res = await axiosInstance.get('/api/v1/wms/hubs/1/purchase_order/2')
//   return res?.data
// })

export const createPurchaseOrder = createAsyncThunk('purchase/createPurchaseOrder', async body => {
  body.data.seller_id = String(`${body.data.seller_id}`)
  body.data.supplier_id = parseInt(`${body.data.supplier_id}`)
  const { hub_id } = body
  const res = await axiosInstance.post(`/api/v1/wms/hubs/${hub_id}/purchase_order`, body.data)
  return res?.data
})

// create buk-po-order
export const createBulkOrder = createAsyncThunk('create/bulkOrder/po', async body => {
  const { hub_id } = body
  const response = await axiosInstance.post(`/api/v1/wms/hubs/${hub_id}/purchase_order/bulk`, body)
  return response.data
})

// SELLER
export const getAllSellers = createAsyncThunk('purchase/getAllSellers', async () => {
  const res = await axiosInstance.get('/api/v1/tenant/sellers', { params: { sort: 'name_asc', is_active: true } })
  return res?.data
})

// SELLER-SKUs
// Seller and supplier
export const getSellerSuppliers = createAsyncThunk('purchase/getAllSellerSuppliers', async ({ searchSupplierValue = '', page = 1, take_previous_data = false, seller_id }) => {
  const params = searchSupplierValue
    ? { search_column: 'name', search_query: searchSupplierValue, page, per_page: 20, not_seller_id: seller_id, sort: 'name_asc' }
    : { page, per_page: 20, not_seller_id: seller_id, sort: 'name_asc' }
  const res = await axiosInstance.get('/api/v1/wms/suppliers', { params })
  return { data: res?.data, take_previous_data }
})
// map Seller Supplier
export const mapSellerSupplier = createAsyncThunk('purchase/mapSellerToSupplier', async ({ sellerId, body }) => {
  const res = await axiosInstance.put(`/api/v1/wms/sellers/${sellerId}/suppliers`, body)
  return res
})
export const getSellerSkus = createAsyncThunk('purchase/getSellerSkus', async ({ seller_id, query, type }, store) => {
  const getPurchaseOrderItems = store.getState()?.purchase?.purchaseOrderItems
  const res = await axiosInstance.get(`/api/v1/products/sellers/${seller_id}/skus`, { params: { query, type } })
  const newData = res?.data?.data

  // Filtering based on purchase order items
  const filteredItems = newData.filter(item => {
    return !getPurchaseOrderItems.some(existingItem => existingItem.seller_sku_id === item.id)
  })
  return filteredItems
})

export const editPurchaseOrder = createAsyncThunk('purchase/edit-po', async ({ hub_id, po_id, forReceiveInventory, forAddingItems, ...body }) => {
  const res = await axiosInstance.put(`/api/v1/wms/hubs/${hub_id}/purchase_orders/${po_id}`, body)
  return { ...res.data, forReceiveInventory, forAddingItems }
})

export const getSingleSupplier = createAsyncThunk('purchase/get-supplier', async (id) => {
  const res = await axiosInstance.get(`/api/v1/wms/suppliers/${id}`)
  return res?.data
})

// SELLER-AsyncPaginate
export const getSellersAsyncDataPurchase = async (search, loadedOptions, { page }) => {
  try {
    const response = await axiosInstance.get('/api/v1/tenant/sellers', {
      params: { search_column: 'name', search_query: search, page, sort: 'name_asc', is_active: true }
    })
    const data = response?.data

    return {
      options: data?.data?.map(ele => ({
        value: ele?.id,
        label: ele?.name,
        ...ele
      })),
      hasMore: page < data?.meta?.last_page,
      additional: {
        page: page + 1
      }
    }
  } catch (error) {
    console.warn(error)
    console.warn(error)
    // Handle the 403 error or other errors that may occur during the API request
    return {
      options: [],
      hasMore: false,
      additional: {
        page
      }
    }
  }
}

export const getHubsAsyncDataPO = async (search, loadedOptions, { page = 1 }) => {
  try {
    const response = await axiosInstance.get('/api/v1/wms/hubs', { params: { search_column: 'name', search_query: search, page } })
    const data = response?.data
    const modiFiedData = data?.data?.map(ele => ({
      value: ele.id,
      label: ele.name,
      ...ele
    }))
    const isHasMore = page < data?.meta?.last_page
    return {
      options: modiFiedData,
      hasMore: isHasMore,
      additional: {
        page: data?.meta?.current_page + 1
      }
    }
  } catch (error) {
    console.warn(error)
    console.warn(error)
    // Handle the 403 error or other errors that may occur during the API request
    return {
      options: [],
      hasMore: false,
      additional: {
        page
      }
    }
  }
}
export const getAsyncSuppliersListPo = async (search, prevOptions, { page, seller_id }) => {
  // debugger
  try {
    const response = await axiosInstance.get(`/api/v1/wms/sellers/${seller_id}/suppliers?sort=name_asc`, {
      params: { search_column: 'name', search_query: search, page }
    })
    const data = response?.data
    return {
      options: data?.data?.map(ele => ({
        value: ele.id,
        label: { name: ele.name, email: ele.email, phone: ele.phone },
        ...ele
      })),
      hasMore: page < data?.meta?.last_page,
      additional: {
        page: page + 1,
        seller_id
      }
    }
  } catch (error) {
    console.warn(error)
    // Handle the 403 error or other errors that may occur during the API request
    return {
      options: [],
      hasMore: false,
      additional: {
        page
      }
    }
  }
}

export const getPoShipments = createAsyncThunk('get-po-shipments', async (params, store) => {
  const hub_id = store.getState().auth.selectedGlobalHubId
  const res = await axiosInstance.get(`/api/v1/wms/hubs/${hub_id}/purchase_orders/shipments`, { params })
  return res.data
})

export const getShipmentsOfSinglePo = createAsyncThunk('get-shipments-of-single-po', async ({ po_id, awb, queryParams }, store) => {
  const hub_id = store.getState().auth.selectedGlobalHubId
  const res = await axiosInstance.get(`/api/v1/wms/hubs/${hub_id}/purchase_orders/${po_id}/shipments?awb=${awb}`, { params: queryParams })
  return res.data
})

export const getAwbsOfPo = async (search, prevOptions, { page, hub_id, po_id, watch, setValue, currentAwb }) => {
  try {
    const response = await axiosInstance.get(`/api/v1/wms/hubs/${hub_id}/purchase_orders/${po_id}/awbs`, {
      params: { search_column: 'name', search_query: search, page }
    })
    const data = response?.data

    if (!currentAwb && !watch('awb_number') && data?.data?.awb?.[0]) {
      setValue('awb_number', { label: data?.data?.awb?.[0], value: data?.data?.awb?.[0] })
    }

    return {
      options: data?.data?.awb?.map(ele => ({
        value: ele,
        label: ele
      })),
      hasMore: page < data?.meta?.last_page,
      additional: {
        page: page + 1,
        hub_id,
        po_id
      }
    }
  } catch (error) {
    console.warn(error)
    // Handle the 403 error or other errors that may occur during the API request
    return {
      options: [],
      hasMore: false,
      additional: {
        page,
        hub_id,
        po_id
      }
    }
  }
}

export const getAwbsAgainstPo = async (search, prevOptions, { page, hub_id, po_id, currentAwb, setCurrentAwb }) => {
  try {
    const response = await axiosInstance.get(`/api/v1/wms/hubs/${hub_id}/purchase_orders/${po_id}/awbs`, {
      params: { search_column: 'name', search_query: search, page }
    })

    const data = response?.data

    if (!currentAwb && data?.data?.awb?.[0]) {
      setCurrentAwb({ label: data?.data?.awb?.[0], value: data?.data?.awb?.[0] })
    }

    return {
      options: data?.data?.awb?.map(ele => ({
        value: ele,
        label: ele
      })),
      hasMore: page < data?.meta?.last_page,
      additional: {
        page: page + 1,
        hub_id,
        po_id
      }
    }
  } catch (error) {
    console.warn(error)
    // Handle the 403 error or other errors that may occur during the API request
    return {
      options: [],
      hasMore: false,
      additional: {
        page,
        hub_id,
        po_id
      }
    }
  }
}

export const getShipmentsAwb = createAsyncThunk('get-shipments-awb', async ({ hub_id, po_id }) => {
  const response = await axiosInstance.get(`/api/v1/wms/hubs/${hub_id}/purchase_orders/${po_id}/awbs`)
  return response.data
})

export const deletePurchaseOrderShipment = createAsyncThunk('delete-purchase-order-shipment', async ({ purchaseOrderId, params }, store) => {
  const hubId = store.getState().auth.selectedGlobalHubId
  const url = getApiUrl(API_ENDPOINTS.PURCHASE_ORDER.CANCEL_PO_SHIPMENT, { hubId, purchaseOrderId })
  const res = await axiosInstance.delete(url, { params })
  return res.data
})

export const handleClosePurchaseOrder = createAsyncThunk('close-purchase-order', async ({ hub_id, po_id }) => {
  const res = await axiosInstance.put(`/api/v1/wms/hubs/${hub_id}/purchase_orders/close?purchase_order_id=${po_id}`)
  return res
})

// get purchase order logs
export const getPurchaseOrderLogs = createAsyncThunk('purchase/purchase-order-logs', async ({hub_id, po_id}) => {
  const res = await axiosInstance.get(`/api/v1/wms/hubs/${hub_id}/purchase_orders/${po_id}/logs`)
  return res?.data
})

const initialState = {
  loading: {},
  success: {},
  error: {},
  allSuppliersData: [],
  allSellerData: [],
  createSupplierResponse: null,
  // mapSellerResponse
  mapSellerResponse: null,
  purchaseOrderData: [],
  singlepurchaseOrderData: null,
  singleGateEntryData: [],
  singleGrnData: [],
  singlePutawayData: [],
  grnReasonData: [],
  purchaseOrders: [],
  selectedSeller: null,
  purchaseLoading: {},
  singlePurchaseOrder: null,
  purchaseOrder: {
    createPurchaseOrderResponse: {}
  },
  itemDetails: [
    { id: 1, item_name: 'inging', sku_code: 'test code1' },
    { id: 2, item_name: 'rerer' },
    { id: 2, item_name: 'rerer' }
  ],
  allSuppliersList: [],
  allSellersList: [],
  sellerSuppliers: [],
  sellerSupplierList: [],
  sellerSupplierMetaData: null,
  supplierSellers: [],
  sellerSkuList: [],
  purchaseOrderItems: [],
  createPurchaseOrderResponse: {},
  hasPurchaseOrderCreatedSuccessfully: false,
  totalCost: 0,
  supplier: '',
  createSupplierButtonLoading: false,
  uploadedFiles: null,
  editPurchaseOrderResponse: null,
  poShipments: null,
  shipmentsOfSinglePo: null,
  shipmentAwbNum: {},
  selectedShipmentValue: null,
  poItemsData: [],
  singleSupplier: null,
  editSupplier: null,
  closePurchaseOrder: null,
  purchaseOrderLogs: null,
  newEntityAddedInPo: false,
  isAwbDataUpdated:false
}
const purchaseSlice = createSlice({
  name: 'purchase',
  initialState,
  reducers: {
    setItemDetails: (state, action) => {
      state.itemDetails = action.payload
    },
    // setSelected seller
    setSelectedSeller: (state, action) => {
      state.selectedSeller = action.payload
    },
    addItemDetails: (state, action) => {
      state.itemDetails.push(action.payload)
    },
    addPurchaseOrderItems: (state, action) => {
      state.purchaseOrderItems.push({ ...action.payload })
    },
    removePurchaseOrderItemFromIndex: (state, action) => {
      const unitPrice = state.purchaseOrderItems[action.payload]?.unit_price || 0
      const quantity = state.purchaseOrderItems[action.payload]?.quantity || 0
      state.totalCost -= unitPrice * quantity
      const purchaseOrderItemsArray = [...state.purchaseOrderItems]
      purchaseOrderItemsArray.splice(action.payload, 1)
      state.purchaseOrderItems = purchaseOrderItemsArray
    },
    updateQtyOfPurchaseOrderItemAtIndex: (state, action) => {
      state.purchaseOrderItems[action.payload.idx].quantity = parseInt(action.payload.value)
      state.totalCost = 0
      state.purchaseOrderItems.forEach(purchaseOrderItem => {
        const { unit_price, quantity } = purchaseOrderItem
        state.totalCost += unit_price * 1.0 * quantity
      })
    },
    updateUnitPriceOfPurchaseOrderItemAtIndex: (state, action) => {
      state.purchaseOrderItems[action.payload.idx].unit_price = parseFloat(action.payload.value)
      state.totalCost = 0
      state.purchaseOrderItems.forEach(purchaseOrderItem => {
        const { unit_price, quantity } = purchaseOrderItem
        state.totalCost += unit_price * 1.0 * quantity
      })
    },
    discardCurrentPurchaseOrder: state => {
      state.allSellerData = []
      state.allSuppliersData = []
      state.purchaseOrderItems = []
      state.totalCost = 0
    },
    clearSellerSkuList: state => {
      state.sellerSkuList = []
    },
    clearCreatePurchaseOrderState: state => {
      state.hasPurchaseOrderCreatedSuccessfully = false
    },
    clearPurchaseDetails: state => {
      state.singlepurchaseOrderData = null
      state.shipmentsOfSinglePo = null
      state.singleGateEntryData = []
      state.singleGrnData = []
      state.singlePutawayData = []
    },
    setSupplier: (state, action) => {
      state.supplier = action.payload
    },
    setUploadFilesInStore: (state, action) => {
      state.uploadedFiles = {
        ...state.uploadedFiles,
        ...action.payload
      }
    },
    clearUploadFileFromStore: state => {
      state.uploadedFiles = {}
    },
    setTotalCost: (state, action) => {
      state.totalCost = action.payload
    },
    clearEditPoData: state => {
      state.singlepurchaseOrderData = null
      state.editPurchaseOrderResponse = null
    },
    clearPurchaseOrderItems: state => {
      state.purchaseOrderItems = []
    },
    clearShipmentsOfSinglePo: state => {
      state.shipmentsOfSinglePo = null
    },
    clearShipmentsAwb: state => {
      state.shipmentAwbNum = {}
    },
    selectedShipmentsAwb: (state, action) => {
      state.selectedShipmentValue = action.payload
    },
    clearSelectedShipmentsAwb: state => {
      state.selectedShipmentValue = null
      state.shipmentAwbNum = {}
    },
    clearCancelPoShipments: state => {
      delete state.success.deletePurchaseOrderShipment
    },
    setPoItemsData: (state, action) => {
      state.poItemsData = action.payload
    },
    resetSuccess: (state) => {
      state.success = {}
    },
    resetError: (state) => {
      state.error = {}
    },
    clearSingleSupplier: state => {
      state.singleSupplier = null
    },
    clearEditSupplier: state => {
      state.editSupplier = null
    },
    resetCreateSupplierResponse: state => {
      state.createSupplierResponse = null
    },
    clearPurchaseOrderClose: state => {
      state.loading = {...state.loading, handleClosePurchaseOrder: false}
      state.success = {...state.success, handleClosePurchaseOrder: false}
      state.error = {...state.error, handleClosePurchaseOrder: false}
    },
    updateIsPurchaseOrderCreated : (state, action) => {
      state.hasPurchaseOrderCreatedSuccessfully = action.payload
    },
    updateEditPurchaseOrderResponse: (state, action) => {
      state.editPurchaseOrderResponse = action.payload
    },
    clearEditPoSuccess: (state) => {
      state.success = {...state.success, editPurchaseOrder:false}
    },
    upadteNewEntityAddedInPo: (state, action) => {
      state.newEntityAddedInPo = action.payload
    },
    updateIsAwbDataUpdated : (state, action) => {
      state.isAwbDataUpdated = action.payload
    } 
  },
  extraReducers: builder => {
    builder
      .addCase(getPurchaseOrderList.pending, state => {
        state.loading = { ...state.loading, getPurchaseOrderList: true }
      })
      .addCase(getPurchaseOrderList.fulfilled, (state, action) => {
        state.loading = { ...state.loading, getPurchaseOrderList: false }
        state.success = { ...state.success, getPurchaseOrderList: true }
        state.error = { ...state.error, getPurchaseOrderList: false }
        state.purchaseOrderData = action.payload
      })
      .addCase(getPurchaseOrderList.rejected, state => {
        state.loading = { ...state.loading, getPurchaseOrderList: false }
        state.error = { ...state.error, getPurchaseOrderList: true }
        state.success = { ...state.success, getPurchaseOrderList: false }
      })

      .addCase(getSinglePurchaseOrderList.pending, state => {
        state.loading = { ...state.loading, getSinglePurchaseOrderList: true }
        state.error = { ...state.error, getSinglePurchaseOrderList: false }
        state.success = { ...state.success, getSinglePurchaseOrderList: false }
      })
      .addCase(getSinglePurchaseOrderList.fulfilled, (state, action) => {
        state.loading = { ...state.loading, getSinglePurchaseOrderList: false }
        state.success = { ...state.success, getSinglePurchaseOrderList: true }
        state.error = { ...state.error, getSinglePurchaseOrderList: false }
        state.singlepurchaseOrderData = action.payload
      })
      .addCase(getSinglePurchaseOrderList.rejected, state => {
        state.loading = { ...state.loading, getSinglePurchaseOrderList: false }
        state.error = { ...state.error, getSinglePurchaseOrderList: true }
        state.success = { ...state.success, getSinglePurchaseOrderList: false }
      })

      .addCase(getSingleGateEntryList.pending, state => {
        state.loading = { ...state.loading, getSingleGateEntryList: true }
        state.error = { ...state.error, getSingleGateEntryList: false }
        state.success = { ...state.success, getSingleGateEntryList: false }
      })
      .addCase(getSingleGateEntryList.fulfilled, (state, action) => {
        state.loading = { ...state.loading, getSingleGateEntryList: false }
        state.success = { ...state.success, getSingleGateEntryList: true }
        state.singleGateEntryData = action.payload
      })
      .addCase(getSingleGateEntryList.rejected, state => {
        state.loading = { ...state.loading, getSingleGateEntryList: false }
        state.error = { ...state.error, getSingleGateEntryList: true }
        state.success = { ...state.success, getSingleGateEntryList: false }
      })

      .addCase(getSingleGrnList.pending, state => {
        state.loading = { ...state.loading, getSingleGrnList: true }
        state.success = { ...state.success, getSingleGrnList: false }
        state.error = { ...state.error, getSingleGrnList: false }
      })
      .addCase(getSingleGrnList.fulfilled, (state, action) => {
        state.loading = { ...state.loading, getSingleGrnList: false }
        state.success = { ...state.success, getSingleGrnList: true }
        state.error = { ...state.error, getSingleGrnList: false }
        state.singleGrnData = action.payload
      })
      .addCase(getSingleGrnList.rejected, state => {
        state.loading = { ...state.loading, getSingleGrnList: false }
        state.error = { ...state.error, getSingleGrnList: true }
        state.success = { ...state.success, getSingleGrnList: false }
      })

      .addCase(getSinglePutawayList.pending, state => {
        state.loading = { ...state.loading, getSinglePutawayList: true }
        state.success = { ...state.success, getSinglePutawayList: false }
        state.error = { ...state.error, getSinglePutawayList: false }
      })
      .addCase(getSinglePutawayList.fulfilled, (state, action) => {
        state.loading = { ...state.loading, getSinglePutawayList: false }
        state.success = { ...state.success, getSinglePutawayList: true }
        state.error = { ...state.error, getSinglePutawayList: false }
        state.singlePutawayData = action.payload
      })
      .addCase(getSinglePutawayList.rejected, state => {
        state.loading = { ...state.loading, getSinglePutawayList: false }
        state.error = { ...state.error, getSinglePutawayList: true }
        state.success = { ...state.success, getSinglePutawayList: false }
      })

      .addCase(getGrnReasons.pending, state => {
        state.loading = { ...state.loading, getGrnList: true }
        state.success = { ...state.success, getGrnList: false }
        state.error = { ...state.error, getGrnList: false }
      })
      .addCase(getGrnReasons.fulfilled, (state, action) => {
        state.loading = { ...state.loading, getGrnList: false }
        state.success = { ...state.success, getGrnList: true }
        state.grnReasonData = action.payload
        state.error = { ...state.error, getGrnList: false }
      })
      .addCase(getGrnReasons.rejected, state => {
        state.loading = { ...state.loading, getGrnList: false }
        state.success = { ...state.success, getGrnList: false }
        state.error = { ...state.error, getGrnList: true }
      })
      // .addCase(getSinglePurchaseOrder.pending, state => {
      //   state.loading = true
      // })
      // .addCase(getSinglePurchaseOrder.fulfilled, (state, action) => {
      //   state.loading = false
      //   state.getSinglePurchaseOrder = action.payload.data
      // })
      // .addCase(getSinglePurchaseOrder.rejected, state => {
      //   state.loading = false
      // })

      .addCase(createSupplier.pending, state => {
        state.createSupplierButtonLoading = true
        state.loading = {...state.loading, createSupplier: true}
        state.error = {...state.error, createSupplier: false}
        state.success = {...state.success, createSupplier: false}
      })
      .addCase(createSupplier.fulfilled, (state, action) => {
        state.createSupplierResponse = action.payload?.data
        state.loading = { ...state.loading, createSupplier: false }
        state.success = { ...state.success, createSupplier: true }
        state.createSupplierButtonLoading = false
        CustomToast('Supplier created successfully', { my_type: 'success' })
      })
      .addCase(createSupplier.rejected, state => {
        state.createSupplierButtonLoading = false
        state.loading = {...state.loading, createSupplier: false}
        state.error = {...state.error, createSupplier: true}
      })
      .addCase(editSupplier.pending, state => {
        state.loading = {...state.loading, editSupplier: true}
        state.error = {...state.error, editSupplier: false}
        state.success = {...state.success, editSupplier: false}
      })
      .addCase(editSupplier.fulfilled, (state, action) => {
        state.editSupplier = action.payload?.data
        state.loading = { ...state.loading, editSupplier: false }
        state.success = { ...state.success, editSupplier: true }
        state.editSupplier = action.payload?.data
        CustomToast('Supplier updated successfully', {my_type: 'success'})
      })
      .addCase(editSupplier.rejected, state => {
        state.loading = {...state.loading, editSupplier: false}
        state.error = {...state.error, editSupplier: true}
      })

      .addCase(createPurchaseOrder.pending, state => {
        state.loading = { ...state.loading, createPurchaseOrder: true }
        state.hasPurchaseOrderCreatedSuccessfully = false
      })
      .addCase(createPurchaseOrder.fulfilled, (state, action) => {
        state.loading = { ...state.loading, createPurchaseOrder: false }
        CustomToast('Order placed successfully', { my_type: 'success', duration: 2000 })
        state.createPurchaseOrderResponse = action.payload
        state.hasPurchaseOrderCreatedSuccessfully = true
      })
      .addCase(createPurchaseOrder.rejected, state => {
        state.loading = { ...state.loading, createPurchaseOrder: false }
        state.hasPurchaseOrderCreatedSuccessfully = false
      })

      .addCase(getAllSellers.pending, state => {
        state.purchaseLoading = { ...state.purchaseLoading, getAllSellers: true }
      })
      .addCase(getAllSellers.fulfilled, (state, action) => {
        state.loading = false
        state.purchaseLoading = { ...state.purchaseLoading, getAllSellers: false }
        state.allSellersList = action.payload.data
        if (!state.selectedSeller && action.payload.data?.length > 0) {
          state.selectedSeller = { label: action.payload.data[0].name, value: action.payload.data[0].id }
        }
      })
      .addCase(getAllSellers.rejected, state => {
        state.loading = false
        state.purchaseLoading = { ...state.purchaseLoading, getAllSellers: false }
      })
      // seller suppliers
      .addCase(getSellerSuppliers.pending, state => {
        state.purchaseLoading = { ...state.purchaseLoading, getSellerSuppliers: true }
      })
      .addCase(getSellerSuppliers.fulfilled, (state, action) => {
        state.purchaseLoading = { ...state.purchaseLoading, getSellerSuppliers: false }
        if (action.payload?.take_previous_data) {
          state.sellerSupplierList = [...state.sellerSupplierList, ...action.payload.data.data]
        } else {
          state.sellerSupplierList = action.payload.data.data
        }
        state.sellerSupplierMetaData = action.payload.data.meta
      })
      .addCase(getSellerSuppliers.rejected, state => {
        state.purchaseLoading = { ...state.purchaseLoading, getSellerSuppliers: false }
      })
      // map Seller suppliers
      .addCase(mapSellerSupplier.pending, state => {
        state.purchaseLoading = { ...state.purchaseLoading, mapSellerSupplier: true }
      })
      .addCase(mapSellerSupplier.fulfilled, (state, action) => {
        state.purchaseLoading = { ...state.purchaseLoading, mapSellerSupplier: false }
        state.mapSellerResponse = action.payload?.data
      })
      .addCase(mapSellerSupplier.rejected, state => {
        state.purchaseLoading = { ...state.purchaseLoading, mapSellerSupplier: false }
      })
      .addCase(getAllSuppliers.pending, state => {
        state.loading = { ...state.loading, getAllSuppliers: true }
      })
      .addCase(getAllSuppliers.fulfilled, (state, action) => {
        state.loading = { ...state.loading, getAllSuppliers: false }
        state.success = { ...state.success, getAllSuppliers: true }
        state.error = { ...state.error, getAllSuppliers: false }
        state.allSuppliersList = action.payload
      })
      .addCase(getAllSuppliers.rejected, state => {
        state.loading = { ...state.loading, getAllSuppliers: false }
        state.error = { ...state.error, getAllSuppliers: true }
        state.success = { ...state.success, getAllSuppliers: false }
      })

      .addCase(getSellerSkus.pending, state => {
        state.loading = { ...state.loading, getSellerSkus: true }
      })
      .addCase(getSellerSkus.fulfilled, (state, action) => {
        state.loading = { ...state.loading, getSellerSkus: false }
        state.sellerSkuList = action.payload
      })
      .addCase(getSellerSkus.rejected, state => {
        state.loading = { ...state.loading, getSellerSkus: false }
      })
      .addCase(getTenantSuppliers.pending, state => {
        state.loading = { ...state.loading, getTenantSuppliers: true }
      })
      .addCase(getTenantSuppliers.fulfilled, (state, action) => {
        state.loading = { ...state.loading, getTenantSuppliers: false }
        state.success = { ...state.success, getTenantSuppliers: true }
        state.error = { ...state.error, getTenantSuppliers: false }
        state.allSuppliersList = action.payload
        state.createSupplierResponse = null
        state.mapSellerResponse = null
      })
      .addCase(getTenantSuppliers.rejected, state => {
        state.loading = { ...state.loading, getTenantSuppliers: false }
        state.error = { ...state.error, getTenantSuppliers: true }
        state.success = { ...state.success, getTenantSuppliers: false }
      })

      .addCase(createBulkOrder.pending, state => {
        state.loading = { ...state.loading, createBulkOrder: true }
        state.hasPurchaseOrderCreatedSuccessfully = false
      })
      .addCase(createBulkOrder.fulfilled, (state, action) => {
        state.loading = { ...state.loading, createBulkOrder: false }
        state.hasPurchaseOrderCreatedSuccessfully = true
        state.uploadedFiles = null
        const eventName = action.payload.data.event
        bindToChannelEvent(eventName)
      })
      .addCase(createBulkOrder.rejected, state => {
        state.loading = { ...state.loading, createBulkOrder: false }
        state.hasPurchaseOrderCreatedSuccessfully = false
      })
      .addCase(editPurchaseOrder.pending, (state) => {
        state.loading = { ...state.loading, editPurchaseOrder: true }
        state.error = { ...state.error, editPurchaseOrder: false }
        state.success = { ...state.success, editPurchaseOrder: false }
      })
      .addCase(editPurchaseOrder.fulfilled, (state, action) => {
        state.loading = { ...state.loading, editPurchaseOrder: false }
        state.success = { ...state.success, editPurchaseOrder: true }
        state.editPurchaseOrderResponse = action.payload
        if (action.payload.forReceiveInventory) {
          CustomToast('Inventory updated successfully', { my_type: 'success', duration: 2000 })
        } else {
          CustomToast('Purchase order edited successfully', { my_type: 'success', duration: 2000 })
        }
      })
      .addCase(editPurchaseOrder.rejected, (state) => {
        state.loading = { ...state.loading, editPurchaseOrder: false }
        state.error = { ...state.error, editPurchaseOrder: true }
        state.editPurchaseOrderResponse = null
      })
      .addCase(getPoShipments.pending, (state) => {
        state.loading = { ...state.loading, getPoShipments: true }
        state.error = { ...state.error, getPoShipments: false }
        state.success = { ...state.success, getPoShipments: false }
      })
      .addCase(getPoShipments.fulfilled, (state, action) => {
        state.loading = { ...state.loading, getPoShipments: false }
        state.error = { ...state.error, getPoShipments: false }
        state.success = { ...state.success, getPoShipments: true }
        state.poShipments = action.payload
      })
      .addCase(getPoShipments.rejected, (state) => {
        state.loading = { ...state.loading, getPoShipments: false }
        state.error = { ...state.error, getPoShipments: true }
        state.success = { ...state.success, getPoShipments: false }
      })
      .addCase(getShipmentsOfSinglePo.pending, (state) => {
        state.loading = { ...state.loading, getShipmentsOfSinglePo: true }
        state.error = { ...state.error, getShipmentsOfSinglePo: false }
        state.success = { ...state.success, getShipmentsOfSinglePo: false }
      })
      .addCase(getShipmentsOfSinglePo.fulfilled, (state, action) => {
        state.loading = { ...state.loading, getShipmentsOfSinglePo: false }
        state.success = { ...state.success, getShipmentsOfSinglePo: true }
        state.error = { ...state.error, getShipmentsOfSinglePo: false }
        state.shipmentsOfSinglePo = action.payload
      })
      .addCase(getShipmentsOfSinglePo.rejected, (state) => {

        state.loading = { ...state.loading, getShipmentsOfSinglePo: false }
        state.error = { ...state.error, getShipmentsOfSinglePo: true }
        state.success = { ...state.success, getShipmentsOfSinglePo: false }
      })
      .addCase(deletePurchaseOrderShipment.pending, (state) => {
        state.loading = { ...state.loading, deletePurchaseOrderShipment: true }
        state.success = { ...state.success, deletePurchaseOrderShipment: false }
        state.error = { ...state.error, deletePurchaseOrderShipment: false }
      })
      .addCase(deletePurchaseOrderShipment.fulfilled, (state, action) => {
        state.loading = { ...state.loading, deletePurchaseOrderShipment: false }
        state.success = { ...state.success, deletePurchaseOrderShipment: true }
        state.error = { ...state.error, deletePurchaseOrderShipment: false }
        CustomToast(action.payload.data.message, { my_type: 'success' })
      })
      .addCase(deletePurchaseOrderShipment.rejected, (state) => {
        state.loading = { ...state.loading, deletePurchaseOrderShipment: false }
        state.success = { ...state.success, deletePurchaseOrderShipment: false }
        state.error = { ...state.error, deletePurchaseOrderShipment: true }
      })
      .addCase(getSingleSupplier.pending, (state) => {
        state.loading = { ...state.loading, getSingleSupplier: true }
        state.success = { ...state.success, getSingleSupplier: false}
        state.error = { ...state.error, getSingleSupplier: false }
      })
      .addCase(getSingleSupplier.fulfilled, (state, action) => {
        state.loading = { ...state.loading, getSingleSupplier: false }
        state.success = { ...state.success, getSingleSupplier: true}
        state.error = { ...state.error, getSingleSupplier: false }
        state.singleSupplier = action.payload.data
        // CustomToast(action.payload.data.message, { my_type: "success" })
      })
      .addCase(getSingleSupplier.rejected, (state) => {
        state.loading = { ...state.loading, getSingleSupplier: false }
        state.success = { ...state.success, getSingleSupplier: false}
        state.error = { ...state.error, getSingleSupplier: true }
      })
      .addCase(getShipmentsAwb.pending, (state) => {
        state.loading = { ...state.loading, getShipmentsAwb: true }
        state.error = { ...state.error, getShipmentsAwb: false }
        state.success = { ...state.success, getShipmentsAwb: false }
      })
      .addCase(getShipmentsAwb.fulfilled, (state, action) => {
        state.loading = { ...state.loading, getShipmentsAwb: false }
        state.error = { ...state.error, getShipmentsAwb: false }
        state.success = { ...state.success, getShipmentsAwb: true }
        state.shipmentAwbNum = action.payload
      })
      .addCase(getShipmentsAwb.rejected, (state) => {
        state.loading = { ...state.loading, getShipmentsAwb: false }
        state.success = { ...state.success, getShipmentsAwb: false }
        state.error = { ...state.error, getShipmentsAwb: true }
      })
      .addCase(handleClosePurchaseOrder.pending, (state) => {
        state.loading = { ...state.loading, handleClosePurchaseOrder: true }
        state.success = { ...state.success, handleClosePurchaseOrder: false }
        state.error = { ...state.error, handleClosePurchaseOrder: false }
      })
      .addCase(handleClosePurchaseOrder.fulfilled, (state) => {
        state.loading = { ...state.loading, handleClosePurchaseOrder: false }
        state.success = { ...state.success, handleClosePurchaseOrder: true }
        state.error = { ...state.error, handleClosePurchaseOrder: false }
      })
      .addCase(handleClosePurchaseOrder.rejected, (state) => {
        state.loading = { ...state.loading, handleClosePurchaseOrder: false }
        state.success = { ...state.success, handleClosePurchaseOrder: false }
        state.error = { ...state.error, handleClosePurchaseOrder: true }
      })
      .addCase(getPurchaseOrderLogs.pending, (state) => {
        state.loading = { ...state.loading, getPurchaseOrderLogs: true }
        state.success = { ...state.success, getPurchaseOrderLogs: false }
        state.error = { ...state.error, getPurchaseOrderLogs: false }
      })
      .addCase(getPurchaseOrderLogs.fulfilled, (state, action) => {
        state.loading = { ...state.loading, getPurchaseOrderLogs: false }
        state.success = { ...state.success, getPurchaseOrderLogs: true }
        state.error = { ...state.error, getPurchaseOrderLogs: false }
        state.purchaseOrderLogs = action.payload
      })
      .addCase(getPurchaseOrderLogs.rejected, (state) => {
        state.loading = { ...state.loading, getPurchaseOrderLogs: false }
        state.success = { ...state.success, getPurchaseOrderLogs: false }
        state.error = { ...state.error, getPurchaseOrderLogs: true }
      })
  }
})
export const {
  setItemDetails,
  // selectedSeller
  setSelectedSeller,
  addItemDetails,
  addPurchaseOrderItems,
  removePurchaseOrderItemFromIndex,
  updateQtyOfPurchaseOrderItemAtIndex,
  updateUnitPriceOfPurchaseOrderItemAtIndex,
  discardCurrentPurchaseOrder,
  clearSellerSkuList,
  clearCreatePurchaseOrderState,
  clearPurchaseDetails,
  setSupplier,
  setUploadFilesInStore,
  clearUploadFileFromStore,
  setTotalCost,
  clearEditPoData,
  clearPurchaseOrderItems,
  clearShipmentsOfSinglePo,
  clearShipmentsAwb,
  selectedShipmentsAwb,
  clearSelectedShipmentsAwb,
  clearCancelPoShipments,
  setPoItemsData,
  resetError,
  clearSingleSupplier,
  clearEditSupplier,
  resetSuccess,
  resetCreateSupplierResponse,
  clearPurchaseOrderClose,
  updateIsPurchaseOrderCreated,
  updateEditPurchaseOrderResponse,
  clearEditPoSuccess,
  upadteNewEntityAddedInPo,
  updateIsAwbDataUpdated
} = purchaseSlice.actions
export default purchaseSlice.reducer
//TODO: add rejected cases in store