
export const Note = ({ width = '16', height = '16', color = '#666666' }) => { 
  return (
    <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Frame">
        <path id="Vector" d="M13.5 3H2.50002C2.2348 3 1.98045 3.10536 1.79291 3.29289C1.60537 3.48043 1.50002 3.73478 1.50002 4V14C1.49889 14.1906 1.55277 14.3775 1.65521 14.5382C1.75764 14.699 1.90427 14.8268 2.07752 14.9062C2.2099 14.9677 2.35406 14.9997 2.50002 15C2.73477 14.9994 2.96173 14.9157 3.14064 14.7638L3.14627 14.7594L5.18752 13H13.5C13.7652 13 14.0196 12.8946 14.2071 12.7071C14.3947 12.5196 14.5 12.2652 14.5 12V4C14.5 3.73478 14.3947 3.48043 14.2071 3.29289C14.0196 3.10536 13.7652 3 13.5 3ZM13.5 12H5.00002C4.87996 12.0001 4.76394 12.0433 4.67314 12.1219L2.50002 14V4H13.5V12ZM5.50002 7C5.50002 6.86739 5.5527 6.74021 5.64646 6.64645C5.74023 6.55268 5.86741 6.5 6.00002 6.5H10C10.1326 6.5 10.2598 6.55268 10.3536 6.64645C10.4473 6.74021 10.5 6.86739 10.5 7C10.5 7.13261 10.4473 7.25979 10.3536 7.35355C10.2598 7.44732 10.1326 7.5 10 7.5H6.00002C5.86741 7.5 5.74023 7.44732 5.64646 7.35355C5.5527 7.25979 5.50002 7.13261 5.50002 7ZM5.50002 9C5.50002 8.86739 5.5527 8.74021 5.64646 8.64645C5.74023 8.55268 5.86741 8.5 6.00002 8.5H10C10.1326 8.5 10.2598 8.55268 10.3536 8.64645C10.4473 8.74021 10.5 8.86739 10.5 9C10.5 9.13261 10.4473 9.25979 10.3536 9.35355C10.2598 9.44732 10.1326 9.5 10 9.5H6.00002C5.86741 9.5 5.74023 9.44732 5.64646 9.35355C5.5527 9.25979 5.50002 9.13261 5.50002 9Z" fill={color} />
      </g>
    </svg>
  )
}

