import { useState } from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { Modal } from 'reactstrap'
import SidesheetHeader from '../sidesheet-header'

const   SideSheet = ({
  customBackground,
  headerWithTabs,
  selectedTabId,
  size = 'sm',
  subTextData,
  onTabChange,
  tabDataList,
  style = {},
  children,
  title,
  tag,
  ...restProps
}) => {
  const modalSize = (() => {
    switch (size) {
    case 'sm': return { width: '600px' }
    case 'md': return { width: '900px' }
    case 'lg': return { width: '1200px' }
    default: return { width: '600px' }
    }
  })()

  const [isBodyScrollable, setisBodyScrollable] = useState(false)

  const handleScroll = (container) => {
    const { scrollTop } = container
    if (scrollTop > 0) {
      setisBodyScrollable(true)
    } else {
      setisBodyScrollable(false)
    }
  }

  return (
    <Modal
      className='mx-1 my-1 overflow-hidden rounded-5 side-sheet-main'
      style={{ ...style, ...modalSize }}
      {...restProps}
    >
      {!!title && <SidesheetHeader
        backButtonHandler={restProps.backButtonHandler}
        hasBackButton={restProps.hasBackButton}
        clearButtonHandler={restProps.toggle}
        customBackground={customBackground}
        isBodyScrollable={isBodyScrollable}
        headerWithTabs={headerWithTabs}
        selectedTabId={selectedTabId}
        subTextData={subTextData}
        tabDataList={tabDataList}
        onTabChange={onTabChange}
        hasClearButton
        title={title}
        tag={tag}
      />}
      {headerWithTabs ? <PerfectScrollbar onScrollY={handleScroll}>
        {children}
      </PerfectScrollbar> : children}
    </Modal>
  )
}

export default SideSheet
