import { useSelector } from 'react-redux'
import { GRN_SOURCE_TYPE } from '../../constant'
import GrnWithGateEntry from '../grnWithGateEntry'
import GrnWithPOAndSto from '../grnWithPOAndSto'
import GrnWithSellerAndSupplier from '../grnWithSellerAndSupplier'

const GrnSourceIdentify = ({control, errors, reset, setValue, watch, isBarcodePrint, isNextOrderProcess, setIsNextOrderProcess}) => {
  const selectedHubConfiguration = useSelector(
    (state) => state.auth.selectedHubConfiguration
  )
  const grnConfig = selectedHubConfiguration.reduce((acc, item) => {
    if (GRN_SOURCE_TYPE.includes(item.configuration_type)) {
      acc[item.configuration_type] = item.configuration_values.enabled
    }
    return acc
  }, {})
  return (
    <div>
      {grnConfig.gate_entry ? (
        <GrnWithGateEntry isBarcodePrint={isBarcodePrint} isNextOrderProcess={isNextOrderProcess} setIsNextOrderProcess={setIsNextOrderProcess}/>
      ) : grnConfig.purchase_order || grnConfig.stock_transfer_order ? (
        <GrnWithPOAndSto isBarcodePrint={isBarcodePrint} grnConfig={grnConfig} control={control} errors={errors} reset={reset} isNextOrderProcess={isNextOrderProcess} setIsNextOrderProcess={setIsNextOrderProcess}/>
      ) : (
        <GrnWithSellerAndSupplier control={control} errors={errors} setValue={setValue} watch={watch} reset={reset} isBarcodePrint={isBarcodePrint} isNextOrderProcess={isNextOrderProcess} setIsNextOrderProcess={setIsNextOrderProcess}/>
      )}
    </div>
  )
}

export default GrnSourceIdentify
