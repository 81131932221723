// ** React Imports
import { Suspense, lazy } from 'react'
import { createRoot } from 'react-dom/client'

// ** Redux Imports
import { store } from './redux/store'
// ** Intl, CASL & ThemeColors Context

// ** ThemeConfig

// ** Toast

// ** i18n
import './configs/i18n'

// ** Spinner (Splash Screen)

// ** Ripple Button
// import './@core/components/ripple-button'

// ** React Perfect Scrollbar
import 'react-perfect-scrollbar/dist/css/styles.css'

// ** React Hot Toast Styles
import '@styles/react/libs/react-hot-toasts/react-hot-toasts.scss'

// ** Core styles
import './@core/assets/fonts/feather/iconfont.css'
import './@core/scss/core.scss'
import './assets/scss/style.scss'

// ** React helmet

// ** Service Worker
// import * as serviceWorker from './serviceWorker'
// ** Sentry for error tracking
import themeConfig from '@configs/themeConfig'
import { AbilityContext } from '@src/utility/context/Can'
import ErrorBoundary from '@src/views/home/ErrorBoundary'
import { HelmetProvider } from 'react-helmet-async'
import { Toaster } from 'react-hot-toast'
import { Provider } from 'react-redux'
import { ENVIRONMENT } from './App.constants'
import ability from './configs/acl/ability'
import ReactQueryProvider from './configs/react-query/react-query-provider'
import Router from './router/Router'
import SentryUtility from './utility/Sentry'
import { ThemeContext } from './utility/context/ThemeColors'

if (import.meta.env.VITE_ENVIRONMENT !== ENVIRONMENT.DEVELOPMENT) {
  SentryUtility.init()
}

// ** Lazy load app
const LazyApp = lazy(() => import('./App'))
const container = document.getElementById('root')
const root = createRoot(container)


root.render(
  <LazyApp>
    <Provider store={store}>
      <ErrorBoundary>
        <Suspense fallback={null}>
          <ReactQueryProvider>
            <AbilityContext.Provider value={ability}>
              <ThemeContext>
                <HelmetProvider>
                  <Router />
                </HelmetProvider>
                <Toaster position={themeConfig.layout.toastPosition} />
              </ThemeContext>
            </AbilityContext.Provider>
          </ReactQueryProvider>
        </Suspense>
      </ErrorBoundary>
    </Provider>
  </LazyApp>

)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister()
