import { SKU_TYPES } from '@src/views/sales/constant/orders.constants'
import { useTranslation } from 'react-i18next'
import { DropdownItem } from 'reactstrap'
import no_sku_image from '@src/assets/images/omniful/no_sku_image.svg'

const CustomDropdownItem = ({ itemDetails }) => {
  const { t } = useTranslation()
  return (
    <DropdownItem className="sku-card">
      <div className="d-flex justify-content-between">
        <div className="w-25">
          <img
            onError={({ currentTarget }) => {
              currentTarget.onerror = null
              currentTarget.src = no_sku_image
            }}
            src={itemDetails.sku.images?.[0]?.default || no_sku_image}
            alt=""
            height="60px"
            width="60px"
            className="rounded-4px"
          />
        </div>
        <div className="w-75 d-flex flex-column">
          <div className="sku-info">{itemDetails.sku.name}</div>
          <span className="code txt-sub-md text-dark-6">
            {itemDetails.sku.display_selling_price}
          </span>
          <div className="sku-code flex-center-between gap-25">
            <span>
              {t('SKU Code')}: {itemDetails.sku.seller_sku_code}
            </span>
            {itemDetails.sku.product?.type === SKU_TYPES.BUNDLE.key && (
              <span className="txt-asst-md py-1px px-10px rounded-10px text-info bg-info-light text-nowrap">
                {t('Kit')}
              </span>
            )}
          </div>
        </div>
      </div>
    </DropdownItem>
  )
}

export default CustomDropdownItem