import Button from '@src/@core/components/ui/button'
import CustomToast from '@src/@core/components/ui/custom-toast/CustomToast'
import FloatingDropDown from '@src/@core/components/ui/floating-dropdown'
import { ExpandableCustomTable } from '@src/@core/components/ui/globalTable/ExpandableCustomTable'
import Checkbox from '@src/@core/components/ui/new-checkbox'
import { useApi } from '@src/configs/react-query/useApi'
import { axiosInstance } from '@src/network/AxiosInstance'
import { bindToChannelEvent, initRealTime } from '@src/network/RealTime'
import { useMutation } from '@tanstack/react-query'
import { useMemo, useState } from 'react'
import { X } from 'react-feather'
import { useSelector } from 'react-redux'
import { Modal, ModalBody, ModalFooter, Spinner } from 'reactstrap'
import { getAsyncShippingEligibleAccountsData } from '../../sales/store/store'
import { SHIPMENT_TYPE } from '../bulk-ship.constants'

const RetryShipmentModal = ({ pickingWave, isOpen, t, toggle, handleOrderDetails }) => {
  const selectedGlobalHubId = useSelector((store) => store.auth.selectedGlobalHubId)
  const pusherLoading = useSelector(store => store.auth.pusherLoading)

  const [shippingPartner, setShippingPartner] = useState(null)

  const columns = [
    {
      id: 1,
      name: <span className="txt-body-md">{t('Order ID')}</span>,       
      cell: row => {
        const { orderId } = row
        return <div className='flex-center-start gap-16px pe-30px text-primary cursor-pointer' onClick={() => handleOrderDetails(row)}>
          { orderId }
        </div>
      } 
    },
    {
      id: 2,
      name: <div className='w-100 txt-body-md'>{t('Shipping Account')}</div>,
      key: 'shipping_account',
      reorder: true,
      cell: (row) => {
        return (
          <div className='w-100'>
            <div className='w-100 txt-sub-rg text-truncation'>{ shippingPartner?.label || row.shippingPartner || '-'}</div>
          </div>
        )
      }
    }
  ]

  const [pagination, setPagination] = useState({})
  
  const handlePagination = page => {
    const pagination = { page: page.selected, per_page: page.per_page ? page.per_page : 10 }
    setPagination(pagination)
  }

  const {
    data: fetchOrdersResponse,
    refetch,
    isFetching,
    isError,
    isSuccess 
  } = useApi({
    apiKey: ['retry-failed-shipments', pagination],
    apiFn: () => {
      return axiosInstance.get('/api/v1/oms/orders/bulk_ship', { params: { picking_wave_id: pickingWave?.wave?.id, creation_status: 'failed', hub_id: selectedGlobalHubId } })
    },
    enabled: false
  })

  const tableData = useMemo(() => {
    return fetchOrdersResponse?.data?.orders?.map(item => ({
      id: item.id,
      orderId: item.seller_sales_channel_order_id,
      shippingPartner: item.shipment?.shipping_partner_name
    }))
  }, [fetchOrdersResponse])

  const { mutate: retryShipmentForOrders, isPending: isRetryShipmentForOrdersPending } = useMutation({
    mutationKey: ['retry-shipment-for-orders'],
    mutationFn: () => {
      const body = {
        creation_status: 'failed',
        wave_id: `${pickingWave?.wave?.id}`,
        hub_id: selectedGlobalHubId,
        shipment_type: shippingPartner ? SHIPMENT_TYPE.SHIPPING_PARTNER : SHIPMENT_TYPE.AUTOMATION_RULE,
        order_details: {
          number_of_boxes: +pickingWave.packing_details?.box_details?.length || 0,
          weight: +pickingWave.packing_details?.order_weight
        },
        shipping_account: shippingPartner ? 
          {
            id: shippingPartner.id,
            name: shippingPartner.name
          } : undefined,
        is_all_orders: true,
        shipping_partner_tag: shippingPartner?.shipping_partner.tag,
        order_shipments: []
      }
      return axiosInstance.post('/api/v1/oms/orders/bulk/shipments', body)
    },
    onSuccess: ({ data }) => {
      CustomToast('Shipment creation started', {my_type: 'info'})
      bindToChannelEvent(data?.data?.event)
      toggle()
    }
  })

  const closeModal = () => toggle(p => ({ ...p, isOpen: false }))
  const handleModalOpening = () => {
    refetch()
  }

  const [shippingPartnerChangeEnabled, setShippingPartnerChangeEnabled] = useState(false)
  const handleChangeShippingPartnerEnabling = (e) => {
    setShippingPartnerChangeEnabled(e.target.checked)
  }

  const handleModalClosing = () => {
    setShippingPartner(null)
    setShippingPartnerChangeEnabled(false)
  }

  const handleRetryShipment = () => {
    initRealTime(() => {
      retryShipmentForOrders()
    })
  }

  return <Modal onOpened={handleModalOpening} onClosed={handleModalClosing} container='__bulk-ship-module' isOpen={isOpen} toggle={closeModal} centered contentClassName="rounded-16px bg-white" modalClassName="modal-max-w-800">
    <div className='flex-start-between p-20px text-dark'>
      <div className="d-flex flex-column gap-4px">
        <div className="txt-h3-md">
          {t('Retry Shipment Creation')}
        </div>
        <div className="txt-asst-rg text-dark-6">
          {t('Retry with the same shipping accounts or select a new shipping account for the selected orders')}
        </div>
      </div>
      <div
        className="h-100 flex-start-start cursor-pointer"
        onClick={closeModal}
      >
        <X size={22} />
      </div>
    </div>
    <ModalBody className='max-height-450 overflow-auto'>
      <div className="d-flex flex-column gap-16px">
        <div className="p-16px bg-light-3 rounded-6px d-flex flex-column gap-16px">
          <Checkbox size='sm' label='Change Shipping Account For All These Orders' value={shippingPartnerChangeEnabled} onChange={handleChangeShippingPartnerEnabling} />
          {shippingPartnerChangeEnabled && <FloatingDropDown
            isAsync
            isRequired
            modifiedStyles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
            menuPortalTarget={document.body}
            title={shippingPartner ? 'Shipping Account' : 'Select Different Shipping Account'}
            value={shippingPartner}
            onChangeFunc={val => setShippingPartner(val)}
            loadOptions={getAsyncShippingEligibleAccountsData}
            disabled={!shippingPartnerChangeEnabled}
          />}
        </div>
        <ExpandableCustomTable
          showColumnsTableHeader={false}
          columns={columns || []}
          data={tableData || []}
          TableHeaderComponent={null}
          showPagination={false}
          handlePagination={handlePagination}
          loading={isFetching}
          error={isError}
          success={isSuccess}
        />
      </div>
    </ModalBody>
    <ModalFooter className="height-80px">
      <Button
        type='button'
        onClick={handleRetryShipment}
        disabled={(shippingPartnerChangeEnabled && !shippingPartner) || isRetryShipmentForOrdersPending || pusherLoading}
      >
        {(isRetryShipmentForOrdersPending) && <Spinner size='sm' />}
        {t('Retry Now')}
      </Button>
    </ModalFooter>
  </Modal>
}

export default RetryShipmentModal