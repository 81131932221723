const SVG = ({ iswhite, fillColor, height, width }) => {
  return <svg width={width} height={height} viewBox="0 0 77 77" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M46.9333 52.4222V66.6H61.1111V52.4222H46.9333ZM45.5333 50.0222C44.9811 50.0222 44.5333 50.4699 44.5333 51.0222V68C44.5333 68.5523 44.9811 69 45.5333 69H62.5111C63.0634 69 63.5111 68.5523 63.5111 68V51.0222C63.5111 50.4699 63.0634 50.0222 62.5111 50.0222H45.5333Z" fill={iswhite ? 'white' : fillColor}/>
    <path fillRule="evenodd" clipRule="evenodd" d="M53.2593 52.4222V55.7555H54.7852V52.4222H53.2593ZM51.8593 50.0222C51.307 50.0222 50.8593 50.4699 50.8593 51.0222V57.1556C50.8593 57.7078 51.307 58.1556 51.8593 58.1556H56.1852C56.7375 58.1556 57.1852 57.7078 57.1852 57.1556V51.0222C57.1852 50.4699 56.7375 50.0222 56.1852 50.0222H51.8593Z" fill={iswhite ? 'white' : fillColor}/>
    <path fillRule="evenodd" clipRule="evenodd" d="M29.763 52.4222V66.6H43.9408V52.4222H29.763ZM28.363 50.0222C27.8107 50.0222 27.363 50.4699 27.363 51.0222V68C27.363 68.5523 27.8107 69 28.363 69H45.3408C45.8931 69 46.3408 68.5523 46.3408 68V51.0222C46.3408 50.4699 45.8931 50.0222 45.3408 50.0222H28.363Z" fill={iswhite ? 'white' : fillColor}/>
    <path fillRule="evenodd" clipRule="evenodd" d="M36.089 52.4222V55.7555H37.6149V52.4222H36.089ZM34.689 50.0222C34.1367 50.0222 33.689 50.4699 33.689 51.0222V57.1556C33.689 57.7078 34.1367 58.1556 34.689 58.1556H39.0149C39.5672 58.1556 40.0149 57.7078 40.0149 57.1556V51.0222C40.0149 50.4699 39.5672 50.0222 39.0149 50.0222H34.689Z" fill={iswhite ? 'white' : fillColor}/>
    <path fillRule="evenodd" clipRule="evenodd" d="M45.1259 35.2519V49.4296H59.3037V35.2519H45.1259ZM43.7259 32.8519C43.1737 32.8519 42.7259 33.2996 42.7259 33.8519V50.8296C42.7259 51.3819 43.1737 51.8296 43.7259 51.8296H60.7037C61.256 51.8296 61.7037 51.3819 61.7037 50.8296V33.8519C61.7037 33.2996 61.256 32.8519 60.7037 32.8519H43.7259Z" fill={iswhite ? 'white' : fillColor}/>
    <path fillRule="evenodd" clipRule="evenodd" d="M51.4518 35.2519V38.5852H52.9778V35.2519H51.4518ZM50.0518 32.8519C49.4996 32.8519 49.0518 33.2996 49.0518 33.8519V39.9852C49.0518 40.5375 49.4996 40.9852 50.0518 40.9852H54.3778C54.9301 40.9852 55.3778 40.5375 55.3778 39.9852V33.8519C55.3778 33.2996 54.9301 32.8519 54.3778 32.8519H50.0518Z" fill={iswhite ? 'white' : fillColor}/>
    <path fillRule="evenodd" clipRule="evenodd" d="M15 9.19999C14.0059 9.19999 13.2 10.0059 13.2 11V60C13.2 60.9941 14.0059 61.8 15 61.8H28.1379C28.8007 61.8 29.3379 62.3372 29.3379 63C29.3379 63.6627 28.8007 64.2 28.1379 64.2H15C12.6804 64.2 10.8 62.3196 10.8 60V11C10.8 8.68039 12.6804 6.79999 15 6.79999H48C50.3196 6.79999 52.2 8.68039 52.2 11V33.5C52.2 34.1627 51.6627 34.7 51 34.7C50.3373 34.7 49.8 34.1627 49.8 33.5V11C49.8 10.0059 48.9941 9.19999 48 9.19999H15Z" fill={iswhite ? 'white' : fillColor}/>
    <path fillRule="evenodd" clipRule="evenodd" d="M38.6 14.4H24.4V17.6H38.6V14.4ZM22 12V20H41V12H22Z" fill={iswhite ? 'white' : fillColor}/>
    <path fillRule="evenodd" clipRule="evenodd" d="M38.6 33.4H24.4V36.6H38.6V33.4ZM22 31V39H41V31H22Z" fill={iswhite ? 'white' : fillColor}/>
    <path fillRule="evenodd" clipRule="evenodd" d="M20.8 50.7999H28.5V53.1999H23.2V56.7999H28.5V59.1999H20.8V50.7999Z" fill={iswhite ? 'white' : fillColor}/>
    <path fillRule="evenodd" clipRule="evenodd" d="M34.8867 24L32 29L29.1132 24L31.5 24L31.5 22L32.5 22L32.5 24L34.8867 24Z" fill={iswhite ? 'white' : fillColor}/>
    <path fillRule="evenodd" clipRule="evenodd" d="M34.8867 43L32 48L29.1132 43L31.5 43L31.5 41L32.5 41L32.5 43L34.8867 43Z" fill={iswhite ? 'white' : fillColor}/>
  </svg>
}
export default SVG