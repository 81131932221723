import Button from '@src/@core/components/ui/button'
import { axiosInstance } from '@src/network/AxiosInstance'
// import PropTypes from 'prop-types'
import { PRINT_MODES } from '@src/App.constants'
import { PrintIcon } from '@src/assets/images/icons/printIcon'
import { InvoiceLayout } from '@src/views/sales/components/invoice/invoice'
import { useMutation } from '@tanstack/react-query'
import { forwardRef, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useReactToPrint } from 'react-to-print'
import { Spinner } from 'reactstrap'
import './print-invoice.scss'


const MultiInvoiceLayout = forwardRef(({ invoiceData }, ref) => {
  return <div ref={ref}>
    {invoiceData?.map(singleInvoiceData => <InvoiceLayout key={singleInvoiceData.invoice_id} isMultiInvoice data={singleInvoiceData} />)}
  </div>
})


const PrintInvoice = ({ printInvoiceRef, selectedOrders, pickingWave }) => {

  const contentRef = useRef(null)
  const { t } = useTranslation()
  const [invoiceData, setInvoiceData] = useState()
  const [isPrintClicked, setIsPrintClicked] = useState(false)
  const hub_id = useSelector(store => store.auth.selectedGlobalHubId)

  const handlePrint  =  useReactToPrint({
    content: () => contentRef.current,
    pageStyle: `@media print {
      @page  {
        size: ${PRINT_MODES.A_FOUR.size};   /* auto is the initial value */
        margin: ${PRINT_MODES.A_FOUR.margin};  /* this affects the margin in the printer settings */
        padding:${PRINT_MODES.A_FOUR.id && PRINT_MODES.A_FOUR.padding};
      }
    }`,
    onAfterPrint:() => setIsPrintClicked(false)
    // onBeforeGetContent: () => setPrintingLoading(true),
    // onBeforePrint: () => setPrintingLohttps://preview-omniful.web.app/2?merchant_id=4125f07e-6c11-46c7-bae4-7c234d7257ab&page=9acd6b98-caae-46e1-bc73-7111edf52f81&status=draftading(false)
    
  })

  // Print Bulk Invoices
  const { mutate: printBulkInvoice, isPending: isPrintBulkInvoicePending } = useMutation({
    mutationKey: ['print-bulk-invoice'],
    mutationFn: () => {
      const body = {
        order_ids: Object.values(selectedOrders).map(el => el.id),
        picking_wave_id: String(pickingWave?.wave?.id),
        hub_id
        // creation_status: 
      }
      return axiosInstance.post('/api/v1/oms/orders/print_invoice/bulk', body)
    },
    onSuccess: (res) => {
      const orders = res?.data?.data
      setInvoiceData(orders)
      setIsPrintClicked(true)
    }
  })

  useEffect(() => {
    if (invoiceData && isPrintClicked) handlePrint()
  }, [invoiceData, isPrintClicked])

  return (
    <div>
      <div className='d-none'>
        <MultiInvoiceLayout invoiceData={invoiceData} ref={contentRef} />
      </div>
      <Button
        ref={printInvoiceRef}
        ofStyle="noBackground"
        to="/"
        className="txt-body-md py-4px px-8px"
        onClick={(e) => {
          e.stopPropagation()
          printBulkInvoice()
        }}
        disabled={isPrintBulkInvoicePending}
      >
        {isPrintBulkInvoicePending ? (
          <Spinner size='sm' />
        ) : (
          <PrintIcon color='var(--bs-primary)' size={20} />
        )}
        {t('Print Invoice')}
      </Button>
    </div>
  )
}

export default PrintInvoice