import Button from '@src/@core/components/ui/button'
import CustomToast from '@src/@core/components/ui/custom-toast/CustomToast'
import FloatingDropDown from '@src/@core/components/ui/floating-dropdown'
import InputField from '@src/@core/components/ui/input-field'
import { API_ENDPOINTS, getApiUrl } from '@src/api.urls'
import { WarningLogo } from '@src/assets/images/omniful/warning'
import { axiosInstance } from '@src/network/AxiosInstance'
import { OMS_REASON_CONFIG } from '@src/views/settings/settings.constants'
import { useMutation } from '@tanstack/react-query'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { Info, X } from 'react-feather'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Form, Modal, ModalBody, Spinner } from 'reactstrap'
import { CANCEL_ORDER_REASON } from '../constant/orders.constants'
import { getReasonsForCancelOrders } from '../store/store'
import './CancelOrderPopup.scss'

const CancelOrderPopup = (prop) => {
  const { isCancelOrderPopupOpen, setIsCancelOrderPopupOpen, orderDetail, setOrderDetail, handleCancelOrderSuccess } = prop
  const [showInfoPopUp, setShowInfoPopUp] = useState({open:false, message:null}) 
  const { t } = useTranslation()
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    register,
    watch
  } = useForm({
    mode: 'onChange'
  })

  const handleInfoPopUpClose = () => {
    handleCancelOrderSuccess()
    setShowInfoPopUp({open:false, message:null})
    setOrderDetail(null)
  }

  const { mutate: cancelOrder, isPending: isCancelOrderPending } = useMutation({
    mutationKey: ['cancel-order'],
    mutationFn: (body) => axiosInstance.put(getApiUrl(API_ENDPOINTS.ORDERS.CANCEL_ORDER, {orderId:orderDetail.id}), body),
    onSuccess: (response) => {
      if (response.data.data.level === 'info') {
        setShowInfoPopUp({ open: true, message: response.data.data.message })
      } else {
        handleCancelOrderSuccess()
        CustomToast(response.data.data.message, { my_type: 'success' })
        setOrderDetail(null)
      }
      reset()
    }
  })

  const handleTakeMeBack = () => {
    setIsCancelOrderPopupOpen((prev) => !prev)
    setOrderDetail(null)
    reset()
  }

  const handleCancelOrder = (data) => {
    const reason = data.cancel_reasons.value === CANCEL_ORDER_REASON.OTHER.key ? data.reason_description : data.cancel_reasons.value 
    cancelOrder({reason})
  }

  const handleToggle = () => {
    setIsCancelOrderPopupOpen(prev => !prev)
    reset()
  }
  
  return (
    <Modal
      isOpen={isCancelOrderPopupOpen}
      className="custom-cancel-order-modal rounded-5"
      size="lg"
      centered
      toggle={showInfoPopUp.open ? handleInfoPopUpClose : handleToggle}
    >
      <div className="flex-center-end ms-24px mt-24px me-24px cursor-pointer text-dark" onClick={showInfoPopUp.open ? handleInfoPopUpClose : handleToggle} ><X /></div>
      <ModalBody className="m-0 p-0">
        <div className="content-body px-24px">
          {showInfoPopUp.open ?
            <>
              <div className="d-flex justify-content-center content-logo">
                <Info size="120" color="var(--bs-primary)"/>
              </div>
              <div className="d-flex flex-column align-items-start gap-12px">
                <div className="txt-h1-sb text-dark">
                  {`${orderDetail.sellerOrderId} cancelled successfully.`}
                </div>
                <div className="txt-body-rg text-dark">{showInfoPopUp.message}</div>
                <div className="flex-center-end w-100 py-24px">
                  <div>
                    <Button onClick={handleInfoPopUpClose}>{t('Okay')}</Button>
                  </div>
                </div>
              </div>
            </> :
            <>
              <div className="d-flex justify-content-center content-logo">
                <WarningLogo size="120" />
              </div>
              <div className="d-flex flex-column align-items-start gap-12px mb-24px">
                <div className="txt-h1-sb text-dark">
                  <div>{t('Are you sure you want to cancel this order?')}</div>
                  <div>{orderDetail?.sellerOrderId && <span>{t('Order ID')}: {orderDetail.sellerOrderId}</span>}</div>
                </div>
                <div className="txt-body-rg text-dark">{t('This action cannot be reversed')}.</div>
              </div>
              <Form onSubmit={handleSubmit(handleCancelOrder)}>
                <FloatingDropDown
                  name="cancel_reasons"
                  control={control}
                  errors={errors}
                  loadOptions={getReasonsForCancelOrders}
                  isAsync
                  isRequired
                  title="Select Reason"
                  width="100%"
                  additional={{
                    page: 1,
                    entity_type:OMS_REASON_CONFIG.cancel_order.key
                  }}
                  validationSchema={{ cancel_reasons: { required: 'This field is required' } }}
                  disabled={isCancelOrderPending}
                />

                {watch('cancel_reasons')?.value === CANCEL_ORDER_REASON.OTHER && <div className="mt-1">
                  <InputField
                    name="reason_description"
                    type="textarea"
                    label="Enter reason here"
                    maxLength="3000"
                    errors={errors}
                    {...register('reason_description', {required: 'Reason is required'})}
                    isRequired
                    disabled={isCancelOrderPending}
                  />
                </div>}
                <div className="flex-center-end gap-1 mb-24px mt-32px">
                  <Button
                    className="flex-center-center"
                    onClick={handleTakeMeBack}
                    ofStyle='noBackground'
                    disabled={isCancelOrderPending}
                    type="button"
                  >
                    {t('Take Me Back')}
                  </Button>
                  <Button type="submit" disabled={isCancelOrderPending} className="flex-center-center">
                    {(isCancelOrderPending) && <Spinner size="sm" />}
                    <span className={classNames({ 'ms-50': isCancelOrderPending })}>{t('Yes, Cancel')}</span>
                  </Button>
                </div>
              </Form>
            </>}
        </div>
      </ModalBody>
    </Modal>
  )
}

CancelOrderPopup.propTypes = {
  isCancelOrderPopupOpen: PropTypes.bool,
  setIsCancelOrderPopupOpen: PropTypes.func,
  orderDetail: PropTypes.object,
  setOrderDetail: PropTypes.func,
  handleCancelOrderSuccess: PropTypes.func
}

export default CancelOrderPopup
