const SVG = ({ iswhite, fillColor, height, width }) => {
  const color = iswhite ? 'white' : fillColor

  return (
    <svg width={width} height={height} viewBox="0 0 77 77" fill={color} xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M45.3022 33.4L46.3094 48.5085H48.4549L49.4621 33.4H45.3022ZM43.8057 31C43.2272 31 42.7694 31.4893 42.8079 32.0665L44.0018 49.975C44.0368 50.5003 44.4731 50.9085 44.9996 50.9085H49.7647C50.2911 50.9085 50.7274 50.5003 50.7624 49.975L51.9563 32.0665C51.9948 31.4893 51.537 31 50.9586 31H43.8057Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M47.3821 59.1263C48.6222 59.1263 49.6274 58.1211 49.6274 56.881C49.6274 55.641 48.6222 54.6357 47.3821 54.6357C46.1421 54.6357 45.1368 55.641 45.1368 56.881C45.1368 58.1211 46.1421 59.1263 47.3821 59.1263ZM47.3821 61.5263C49.9477 61.5263 52.0274 59.4466 52.0274 56.881C52.0274 54.3155 49.9477 52.2357 47.3821 52.2357C44.8166 52.2357 42.7368 54.3155 42.7368 56.881C42.7368 59.4466 44.8166 61.5263 47.3821 61.5263Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.79999 15C6.79999 12.1281 9.12811 9.79999 12 9.79999H32.421C35.2929 9.79999 37.621 12.1281 37.621 15V28.5162H35.221V15C35.221 13.4536 33.9674 12.2 32.421 12.2H12C10.4536 12.2 9.19999 13.4536 9.19999 15V35.421C9.19999 36.9674 10.4536 38.221 12 38.221H26.1418V40.621H12C9.12811 40.621 6.79999 38.2929 6.79999 35.421V15Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.6013 24.1748V11.252H20.0013V22.6083L21.0595 21.6607C21.705 21.0825 22.6821 21.0825 23.3277 21.6607L24.3859 22.6083V11.252H26.7859V24.1748C26.7859 25.6446 25.0467 26.4218 23.9518 25.4412L22.1936 23.8667L20.4354 25.4412C19.3404 26.4218 17.6013 25.6446 17.6013 24.1748Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M46.421 65.4421C57.0133 65.4421 65.6 56.8554 65.6 46.2632C65.6 35.6709 57.0133 27.0842 46.421 27.0842C35.8288 27.0842 27.2421 35.6709 27.2421 46.2632C27.2421 56.8554 35.8288 65.4421 46.421 65.4421ZM46.421 67.8421C58.3388 67.8421 68 58.1809 68 46.2632C68 34.3454 58.3388 24.6842 46.421 24.6842C34.5033 24.6842 24.8421 34.3454 24.8421 46.2632C24.8421 58.1809 34.5033 67.8421 46.421 67.8421Z"
        fill={color}
      />
    </svg>
  )
}
export default SVG