const SVG = ({ iswhite, fillColor, height, width }) => {
  return <svg width={width} height={height} viewBox="0 0 77 77" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_375_822" style={{maskType:'luminance'}} maskUnits="userSpaceOnUse" x="7" y="7" width="63" height="63">
      <path d="M70 7H7V70H70V7Z" fill="white"/>
      <path d="M37.7614 26.6888C38.4406 26.6888 38.9919 27.24 38.9919 27.9193C38.9919 28.5985 38.4406 29.1497 37.7614 29.1497C37.0822 29.1497 36.5309 28.5985 36.5309 27.9193C36.5309 27.24 37.0822 26.6888 37.7614 26.6888Z" fill="white"/>
      <path d="M37.7614 31.6106C38.4406 31.6106 38.9919 32.1618 38.9919 32.8411C38.9919 33.5203 38.4406 34.0715 37.7614 34.0715C37.0822 34.0715 36.5309 33.5203 36.5309 32.8411C36.5309 32.1618 37.0822 31.6106 37.7614 31.6106Z" fill="white"/>
    </mask>
    <g mask="url(#mask0_375_822)">
      <mask id="mask1_375_822" style={{maskType:'luminance'}} maskUnits="userSpaceOnUse" x="6" y="6" width="64" height="64">
        <path d="M6.99876 6.99506H69.9987V69.9951H6.99876V6.99506Z" fill="white"/>
      </mask>
      <g mask="url(#mask1_375_822)">
        <path d="M37.7601 26.6815C38.4394 26.6815 38.9906 27.2327 38.9906 27.9119C38.9906 28.5911 38.4394 29.1424 37.7601 29.1424C37.0809 29.1424 36.5297 28.5911 36.5297 27.9119C36.5297 27.2327 37.0809 26.6815 37.7601 26.6815Z" fill={iswhite ? 'white' : fillColor}/>
        <path d="M37.7608 31.6033C38.44 31.6033 38.9912 32.1545 38.9912 32.8337C38.9912 33.513 38.44 34.0642 37.7608 34.0642C37.0816 34.0642 36.5303 33.513 36.5303 32.8337C36.5303 32.1545 37.0816 31.6033 37.7608 31.6033Z" fill={iswhite ? 'white' : fillColor}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M55.2639 20.529C55.2639 21.1917 55.8011 21.729 56.4639 21.729H68.7686C69.4313 21.729 69.9686 21.1917 69.9686 20.529C69.9686 19.8663 69.4313 19.329 68.7686 19.329H57.6639V8.22432C57.6639 7.56158 57.1266 7.02432 56.4639 7.02432C55.8011 7.02432 55.2639 7.56158 55.2639 8.22432V20.529Z" fill={iswhite ? 'white' : fillColor}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M37.299 15.6072C37.299 16.2699 37.8363 16.8072 38.499 16.8072H51.542C52.2047 16.8072 52.742 16.2699 52.742 15.6072C52.742 14.9444 52.2047 14.4072 51.542 14.4072H38.499C37.8363 14.4072 37.299 14.9444 37.299 15.6072Z" fill={iswhite ? 'white' : fillColor}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M37.2991 20.529C37.2991 21.1917 37.8363 21.729 38.4991 21.729H51.542C52.2048 21.729 52.742 21.1917 52.742 20.529C52.742 19.8663 52.2048 19.329 51.542 19.329H38.4991C37.8363 19.329 37.2991 19.8663 37.2991 20.529Z" fill={iswhite ? 'white' : fillColor}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M7.0289 27.9119C7.0289 35.3706 13.075 41.4166 20.5336 41.4166C21.4572 41.4166 22.3603 41.3236 23.233 41.1466L23.2331 41.1466C29.3974 39.8959 34.0383 34.4468 34.0383 27.9119C34.0383 21.377 29.3974 15.928 23.2331 14.6773L23.233 14.6773C22.3603 14.5003 21.4572 14.4073 20.5336 14.4073C13.075 14.4073 7.0289 20.4533 7.0289 27.9119ZM20.5336 39.0166C14.4004 39.0166 9.4289 34.0451 9.4289 27.9119C9.4289 21.7788 14.4004 16.8073 20.5336 16.8073C21.2957 16.8073 22.039 16.884 22.756 17.0294C27.8234 18.0576 31.6383 22.5409 31.6383 27.9119C31.6383 33.283 27.8234 37.7663 22.756 38.7945M20.5336 39.0166C21.2957 39.0166 22.0389 38.9399 22.7559 38.7945L20.5336 39.0166Z" fill={iswhite ? 'white' : fillColor}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M11.9514 27.9118C11.9514 32.6462 15.7998 36.4946 20.5342 36.4946C21.197 36.4946 21.7342 35.9574 21.7342 35.2946C21.7342 34.6319 21.197 34.0946 20.5342 34.0946C17.1253 34.0946 14.3514 31.3207 14.3514 27.9118C14.3514 27.2491 13.8141 26.7118 13.1514 26.7118C12.4887 26.7118 11.9514 27.2491 11.9514 27.9118Z" fill={iswhite ? 'white' : fillColor}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M19.3342 27.9118C19.3342 28.5746 19.8715 29.1118 20.5342 29.1118H32.8389C33.5016 29.1118 34.0389 28.5746 34.0389 27.9118C34.0389 27.2491 33.5016 26.7118 32.8389 26.7118H21.7342V15.6071C21.7342 14.9444 21.197 14.4071 20.5342 14.4071C19.8715 14.4071 19.3342 14.9444 19.3342 15.6071V27.9118Z" fill={iswhite ? 'white' : fillColor}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M25.4561 67.5634C24.7592 67.5634 24.1952 66.9994 24.1952 66.3025V39.9705C24.1952 39.3077 23.6579 38.7705 22.9952 38.7705C22.3324 38.7705 21.7952 39.3077 21.7952 39.9705V66.3025C21.7952 68.3249 23.4337 69.9634 25.4561 69.9634H66.3076C68.33 69.9634 69.9686 68.3249 69.9686 66.3025V20.5291C69.9686 20.2108 69.8421 19.9056 69.6171 19.6805L57.3124 7.37585C57.0874 7.15081 56.7821 7.02438 56.4639 7.02438H25.4561C23.4337 7.02438 21.7952 8.66291 21.7952 10.6853V15.8533C21.7952 16.516 22.3324 17.0533 22.9952 17.0533C23.6579 17.0533 24.1952 16.516 24.1952 15.8533V10.6853C24.1952 9.98839 24.7592 9.42438 25.4561 9.42438H55.9668L67.5686 21.0261V66.3025C67.5686 66.9994 67.0046 67.5634 66.3076 67.5634H25.4561Z" fill={iswhite ? 'white' : fillColor}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M26.717 58.1814C26.717 60.2038 28.3556 61.8423 30.378 61.8423C32.4004 61.8423 34.0389 60.2038 34.0389 58.1814C34.0389 57.1708 33.6274 56.254 32.9664 55.593C32.3053 54.932 31.3886 54.5205 30.378 54.5205C28.3556 54.5205 26.717 56.159 26.717 58.1814ZM30.378 59.4423C29.681 59.4423 29.117 58.8783 29.117 58.1814C29.117 57.4845 29.681 56.9205 30.378 56.9205C30.7258 56.9205 31.0395 57.0602 31.2693 57.2901C31.4991 57.5199 31.6389 57.8336 31.6389 58.1814C31.6389 58.8783 31.0749 59.4423 30.378 59.4423Z" fill={iswhite ? 'white' : fillColor}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M37.1411 50.926C37.8021 51.587 38.7189 51.9985 39.7295 51.9985C40.7401 51.9985 41.6569 51.587 42.3179 50.926C42.9789 50.265 43.3904 49.3482 43.3904 48.3376C43.3904 46.3152 41.7519 44.6767 39.7295 44.6767C37.7071 44.6767 36.0686 46.3152 36.0686 48.3376C36.0686 49.3482 36.4801 50.265 37.1411 50.926ZM39.7295 49.5985C39.3817 49.5985 39.068 49.4588 38.8381 49.229C38.6083 48.9991 38.4686 48.6854 38.4686 48.3376C38.4686 47.6407 39.0326 47.0767 39.7295 47.0767C40.4264 47.0767 40.9904 47.6407 40.9904 48.3376C40.9904 48.6854 40.8507 48.9991 40.6208 49.229C40.391 49.4588 40.0773 49.5985 39.7295 49.5985Z" fill={iswhite ? 'white' : fillColor}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M44.6819 58.1814C44.6819 60.2038 46.3204 61.8423 48.3428 61.8423C50.3652 61.8423 52.0037 60.2038 52.0037 58.1814C52.0037 56.159 50.3652 54.5205 48.3428 54.5205C47.3322 54.5205 46.4154 54.932 45.7544 55.593C45.0934 56.254 44.6819 57.1708 44.6819 58.1814ZM48.3428 59.4423C47.6459 59.4423 47.0819 58.8783 47.0819 58.1814C47.0819 57.8336 47.2216 57.5199 47.4514 57.2901C47.6813 57.0602 47.9949 56.9205 48.3428 56.9205C49.0397 56.9205 49.6037 57.4845 49.6037 58.1814C49.6037 58.8783 49.0397 59.4423 48.3428 59.4423Z" fill={iswhite ? 'white' : fillColor}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M57.3557 43.4158C57.3557 45.4382 58.9942 47.0767 61.0166 47.0767C63.039 47.0767 64.6775 45.4382 64.6775 43.4158C64.6775 41.3934 63.039 39.7548 61.0166 39.7548C58.9942 39.7548 57.3557 41.3934 57.3557 43.4158ZM61.0166 44.6767C60.3197 44.6767 59.7557 44.1127 59.7557 43.4158C59.7557 42.7189 60.3197 42.1548 61.0166 42.1548C61.7135 42.1548 62.2775 42.7189 62.2775 43.4158C62.2775 44.1127 61.7135 44.6767 61.0166 44.6767Z" fill={iswhite ? 'white' : fillColor}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M31.3038 57.3246C31.7909 57.7741 32.5501 57.7435 32.9995 57.2564L38.8713 50.8924C39.3207 50.4054 39.2902 49.6462 38.8031 49.1968C38.316 48.7473 37.5568 48.7779 37.1074 49.265L31.2356 55.629C30.7862 56.116 30.8167 56.8752 31.3038 57.3246Z" fill={iswhite ? 'white' : fillColor}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M47.356 57.3768C47.8719 56.9607 47.9527 56.2052 47.5366 55.6894L42.4031 49.3254C41.987 48.8095 41.2315 48.7287 40.7157 49.1448C40.1998 49.5609 40.119 50.3164 40.5351 50.8322L45.6686 57.1962C46.0847 57.712 46.8402 57.7929 47.356 57.3768Z" fill={iswhite ? 'white' : fillColor}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M48.8903 57.0131C49.3752 57.4649 50.1345 57.4381 50.5863 56.9532L60.5285 46.2825C60.9803 45.7977 60.9534 45.0383 60.4685 44.5865C59.9836 44.1348 59.2243 44.1616 58.7725 44.6465L48.8304 55.3171C48.3786 55.802 48.4054 56.5613 48.8903 57.0131Z" fill={iswhite ? 'white' : fillColor}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M41.4826 27.9118C41.4826 28.5746 42.0199 29.1118 42.6826 29.1118H61.3858C62.0485 29.1118 62.5858 28.5746 62.5858 27.9118C62.5858 27.2491 62.0485 26.7118 61.3858 26.7118H42.6826C42.0199 26.7118 41.4826 27.2491 41.4826 27.9118Z" fill={iswhite ? 'white' : fillColor}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M41.4826 32.8338C41.4826 33.4966 42.0199 34.0338 42.6826 34.0338H61.3858C62.0485 34.0338 62.5858 33.4966 62.5858 32.8338C62.5858 32.1711 62.0485 31.6338 61.3858 31.6338H42.6826C42.0199 31.6338 41.4826 32.1711 41.4826 32.8338Z" fill={iswhite ? 'white' : fillColor}/>
      </g>
    </g>
  </svg>
}
export default SVG