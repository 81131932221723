import Button from '@src/@core/components/ui/button'
import { ExpandableCustomTable } from '@src/@core/components/ui/globalTable/ExpandableCustomTable'
import ImageActions from '@src/@core/components/ui/image-preview-modal'
import SideSheet from '@src/@core/components/ui/sideSheet'
import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ModalBody } from 'reactstrap'
import './reasonsModal.scss'

const ReasonsModal = ({ openReasonsModal, setOpenReasonsModal, reasons, setReasons, title, loading }) => {
  const { t } = useTranslation()
  const [images, setImages] = useState([])
  const [isImageActionModalOpen, setIsImageActionModalOpen] = useState(false)
  const [currentSelectedImage, setCurrentSelectedImage] = useState(0)
  const imageRef = useRef(null)

  const toggleModal = () => {
    setImages([])
    setReasons([])
    setOpenReasonsModal(!openReasonsModal)
  }

  const handleShowImages = (images) => {
    const imagesData = images.map((imageUrl, index) => {
      return {
        value: imageUrl,
        key: index + 1
      }
    })
    setIsImageActionModalOpen(true)
    setImages(imagesData)
    setCurrentSelectedImage(0)
  }

  const columns = [
    {
      id: 1,
      name: <div className="w-100 text-end text-truncate" title={t('Failed Qty')}>{t('Failed Qty')}</div>,
      width: '100px',
      cell: row => <div className="w-100 text-end">{row.quantity || '-'}</div>
    },
    {
      id: 2,
      name: t('Failed Reason'),
      minWidth: '200px',
      cell: row => <div>{row.reason || '-'}</div>
    },
    {
      id: 3,
      name: <div className="w-100 text-center" title={t('Failed Qty')}>{t('Images')}</div>,
      width: '100px',
      cell: (row) => {
        return (
          <div className="flex-center-center w-100">
            {row.images?.length > 0 ? (
              <Button
                ofType="compressed"
                ofStyle="noBackground"
                className="txt-sub-rg"        
                onClick={() => handleShowImages(row.images)}>
                {t('View')}
              </Button>
            ) : (
              '-'
            )}
          </div>
        )              
      } 
    }
  ]

  return (
    <>
      <SideSheet
        isOpen={openReasonsModal}
        toggle={toggleModal}
        title={title}
        modalClassName="modal-slide-in"
        size="sm"
        handleFormClose={toggleModal}
      >
        <ModalBody className='reasons-modal-body mt-1'>

          <ExpandableCustomTable
            loading={loading}
            columns={columns}
            data={reasons || []}
            showColumnsTableHeader={false}
            showPagination={false}
          />

        </ModalBody>
      </SideSheet>
      <ImageActions
        isOpen={isImageActionModalOpen}
        toggle={() => setIsImageActionModalOpen(false)}
        imgArray={images}
        imageUploadHandler={images}
        uploadedImages={images}
        selectedImage={images}
        setCurrentSelectedImage={setCurrentSelectedImage}
        currentSelectedImage={currentSelectedImage}
        imageRef={imageRef}
        isImageRemoveNotAllowed={true}
        isView={true}
      />
    </>
  )
}

export default ReasonsModal