import { ORDER_SOURCE } from '@src/App.constants'
import { Info } from 'react-feather'
import { useTranslation } from 'react-i18next'

const QunatityEditAbleInfo = ({ source }) => {

  const { t } = useTranslation()
  return (
    <div className='mb-24px mx-0'>
      <div className='text-dark p-8px txt-sub-rg flex-center-start rounded-4px bg-primary-lighter-global'>
        <div className='flex-center-start'>
          <div className='flex-center-start gap-8px'>
            <span className='flex-center-center'>
              <Info color='var(--bs-primary)' size={14} />
            </span>
            <span>
              {source === ORDER_SOURCE.MANUAL.label ? t('The order items have been edited') : t('The order has been synchronised and item quantities have been updated accordingly')}.
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default QunatityEditAbleInfo