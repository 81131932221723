import { axiosInstance } from '@src/network/AxiosInstance'
import PropTypes from 'prop-types'

// we can add these things to make these functions more optimized (Caching, Handle Concurrent Requests, Memoization)
export const GetDropdownOptions = async (queryParams, config = {}) => {
  try {
    const url = config.url
    const response = await axiosInstance.get(url, {params:queryParams})
    const data = response?.data
    if (data) {
      if (config.isLoadedOptions) {

        const optionsData = data.data
        const dropdownOptions = typeof config.manualOptions === 'function' ? config.manualOptions(optionsData) : optionsData

        const selectOptions = dropdownOptions?.map((obj) => ({
          value: obj.id,
          label: obj.name,
          ...obj
        }))

        return {
          options: selectOptions,
          hasMore: data.meta?.current_page < data.meta?.last_page,
          additional: {
            ...config.additional,
            page: data.meta?.current_page + 1
          }
        }

      } else {
        return data
      }

    } else {
      throw new Error('API response data is null or undefined')
    }

  } catch (error) {

    if (config.isLoadedOptions) {
      return {
        options: [],
        hasMore: false,
        additional: {
          page:config.page
        }
      }

    } else {
      throw new Error(`Failed to fetch : ${error.message}`)
    }
  }
}

// options:-
// The options property represents the array of options that should be displayed in the React Select dropdown menu.
// In the context of asynchronous pagination, this array typically holds the currently visible or loaded options.
// As the user interacts with the Select component (e.g., scrolls through options), additional options may be loaded and added to this array.

// hasMore:-
// The hasMore property is a boolean value that indicates whether there are more options available to load.
// It's often used to control whether the Select component should trigger additional data loading when the user reaches the end of the currently displayed options.
// When hasMore is true, the component can initiate a request to load more options as needed.

// additional:-
// The additional property is an object that can hold any additional data or configuration required for the pagination process.
// It's used to pass along metadata or additional parameters related to the pagination, which may include page numbers, filters, or any other contextual data.
// By including this data, you can customize the pagination behavior or request additional options based on the context.

// manualAndAllOptions:- to prepend two options in dropdown All, Manual
// allOption:- to prepend only All option in dropdown
// manualOption:- to prepend only Manual option in dropdown 
// isLoadedOptions: if true then async paginate else simple react select
// manualOptions: function which will return data with manual options

GetDropdownOptions.propTypes = {
  queryParams: PropTypes.object,
  config: PropTypes.shape({
    url: PropTypes.string.isRequired,
    page:PropTypes.number,
    isLoadedOptions: PropTypes.bool,
    additional: PropTypes.object,
    manualOptions: PropTypes.func
  }).isRequired
}