// ** React Imports
import PropTypes from 'prop-types'
import React from 'react'
import { Link, useNavigate } from 'react-router-dom'

// ** Reactstrap Imports
import { useRTL } from '@src/utility/hooks/useRTL'
import OrderTagsMapper from '@src/views/sales/live-orders/pages/packingOrderWise/StationPages/packingOrderTabsPages/OrderTagsMapper'
import { ArrowLeft, ArrowRight } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { Breadcrumb, BreadcrumbItem } from 'reactstrap'
import CustomTag from '../badge/CustomTag'

const BreadCrumbs = ({ data, title, icon = null, hasBackButton = false, backButtonHandler, tag }) => {
  const [isRtl] = useRTL()
  const { t } = useTranslation()
  
  const Icon = icon
  const BackArrow = isRtl ? ArrowRight : ArrowLeft

  const tagList = Array.isArray(tag)

  const { icon: TagIcon, text: tagText, tagClassName } = tag || { icon: null, text: '', tagClassName: '' }

  const BackButton = () => {
    const navigate = useNavigate()

    const goBack = () => {
      if (backButtonHandler) {
        backButtonHandler()
      } else {
        navigate(-1)
      }
    }

    return (
      <button className="bg-transparent px-0 border-0" onClick={goBack}>
        <BackArrow size={20}/>
      </button>
    )
  }

  const renderBreadCrumbs = () => {
    const dataWithEmptyLastItem = [...data, { title: '' }]
    return dataWithEmptyLastItem.map((item, index) => {
      const Wrapper = item.link ? Link : React.Fragment
      const isLastItem = data.length - 1 === index
      return (
        <BreadcrumbItem
          tag='li'
          key={index}
          active={!isLastItem}
        >
          <Wrapper {...(item.link ? { to: item.link } : {})}>
            {typeof item.title === 'string' ? t(item.title) : item.title}
          </Wrapper>
        </BreadcrumbItem>
      )
    })
  }

  return (
    <div className="content-header row">
      <div className="content-header-left d-flex justify-content-between">
        <div className="row breadcrumbs-top">
          <div className="col-12">
            {data && (
              <div className="breadcrumb-wrapper vs-breadcrumbs d-sm-flex align-items-center d-none col-12">
                {icon && <Icon size={16} height={16} width={16} />}
                <Breadcrumb>{renderBreadCrumbs()}</Breadcrumb>
              </div>
            )}
            <div className="d-flex align-items-center">
              {title && (
                <h2
                  className="content-header-title d-flex align-items-center gap-1 mb-0 border-0 text-dark txt-h2-md">
                  {hasBackButton && <BackButton />}
                  {typeof title === 'string' ?  t(title) : title}
                </h2>
              )}
              {tagList ? (<OrderTagsMapper tagsArray={tag}/>) 
                : (
                  tagText && (
                    <CustomTag title={tagText} icon={TagIcon} className={tagClassName} />
                  )
                )

              }
            </div>
          </div>
        </div>
        {/* {handleCrossButton && <div onClick={handleCrossButton} ><img className='cross-icon' src = {close} /></div>} */}
      </div>
      <div className="content-header-right text-md-end col-md-3 col-12 d-md-block d-none"></div>
    </div>
  )
}

// ** PropTypes
BreadCrumbs.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.object
  ]),
  data: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.string,
      title: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
      ])
    })
  )
}

export default BreadCrumbs