
export const CasePack = () => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Frame">
        <path id="Vector" d="M10.485 3.10085L6.36 0.843819C6.24978 0.782921 6.12592 0.750977 6 0.750977C5.87408 0.750977 5.75022 0.782921 5.64 0.843819L1.515 3.10179C1.3972 3.16624 1.29886 3.26114 1.23026 3.37658C1.16166 3.49202 1.1253 3.62376 1.125 3.75804V8.24116C1.1253 8.37545 1.16166 8.50718 1.23026 8.62262C1.29886 8.73806 1.3972 8.83296 1.515 8.89741L5.64 11.1554C5.75022 11.2163 5.87408 11.2482 6 11.2482C6.12592 11.2482 6.24978 11.2163 6.36 11.1554L10.485 8.89741C10.6028 8.83296 10.7011 8.73806 10.7697 8.62262C10.8383 8.50718 10.8747 8.37545 10.875 8.24116V3.75851C10.8749 3.62399 10.8387 3.49195 10.7701 3.37625C10.7015 3.26054 10.603 3.16542 10.485 3.10085ZM6 1.50007L9.76594 3.56257L8.37047 4.32663L4.60406 2.26413L6 1.50007ZM6 5.62507L2.23406 3.56257L3.82312 2.69257L7.58906 4.75507L6 5.62507ZM1.875 4.21882L5.625 6.27101V10.2924L1.875 8.24163V4.21882ZM10.125 8.23976L6.375 10.2924V6.27288L7.875 5.4521V7.12507C7.875 7.22453 7.91451 7.31991 7.98483 7.39023C8.05516 7.46056 8.15054 7.50007 8.25 7.50007C8.34946 7.50007 8.44484 7.46056 8.51517 7.39023C8.58549 7.31991 8.625 7.22453 8.625 7.12507V5.04148L10.125 4.21882V8.23929V8.23976Z" fill="#00CFE8"/>
      </g>
    </svg>
  )
}
