const FilledInfo = ({width, height, color}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="InfoFilled">
        <path id="Vector" d="M9.43749 14.1667H10.6875V9.16667H9.43749V14.1667ZM9.99961 7.625C10.1943 7.625 10.3576 7.56112 10.4896 7.43334C10.6215 7.30556 10.6875 7.14723 10.6875 6.95834C10.6875 6.75764 10.6216 6.58942 10.4899 6.45365C10.3582 6.31789 10.195 6.25 10.0004 6.25C9.80567 6.25 9.64235 6.31789 9.51041 6.45365C9.37846 6.58942 9.31249 6.75764 9.31249 6.95834C9.31249 7.14723 9.37834 7.30556 9.51003 7.43334C9.64174 7.56112 9.80493 7.625 9.99961 7.625ZM10.0055 18.3333C8.85645 18.3333 7.77659 18.1146 6.76595 17.6771C5.75531 17.2396 4.87152 16.6424 4.11457 15.8854C3.35763 15.1285 2.76041 14.2442 2.32291 13.2325C1.88541 12.2208 1.66666 11.1399 1.66666 9.98959C1.66666 8.83932 1.88541 7.75836 2.32291 6.74669C2.76041 5.73501 3.35763 4.85417 4.11457 4.10417C4.87152 3.35417 5.75583 2.76042 6.76751 2.32292C7.77918 1.88542 8.86014 1.66667 10.0104 1.66667C11.1607 1.66667 12.2416 1.88542 13.2533 2.32292C14.265 2.76042 15.1458 3.35417 15.8958 4.10417C16.6458 4.85417 17.2396 5.73612 17.6771 6.75C18.1146 7.76389 18.3333 8.84538 18.3333 9.99446C18.3333 11.1435 18.1146 12.2234 17.6771 13.234C17.2396 14.2447 16.6458 15.1272 15.8958 15.8816C15.1458 16.636 14.2639 17.2332 13.25 17.6733C12.2361 18.1133 11.1546 18.3333 10.0055 18.3333Z" fill={color}/>
      </g>
    </svg>
  )
}

export default FilledInfo