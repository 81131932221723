// ** Custom Components

// ** Utils
// import { isUserLoggedIn } from '@utils'

// ** Store & Actions
import { logout } from '@store/authentication'
import { useDispatch, useSelector } from 'react-redux'

// ** Third Party Components
import { ChevronDown, Power } from 'react-feather'
import { useTranslation } from 'react-i18next'

// ** Reactstrap Imports
import { DropdownItem, DropdownMenu, DropdownToggle, Spinner, UncontrolledDropdown } from 'reactstrap'

// ** Default Avatar Image
import defaultAvatar from '@src/assets/images/avatars/avatar-blank.png'
import { getNameInitials } from '@src/utility/Utils'
import { useState } from 'react'
import './styles.scss'

const UserDropdown = () => {
  // ** Store Vars
  const dispatch = useDispatch()
  const {t} = useTranslation()
  const userData = useSelector(state => state.auth.userData)
  const loading = useSelector(state => state.auth.loading)
  const [isChevron, setIsChevron] = useState()
  // ** State
  // const [userData, setUserData] = useState(null)

  //** ComponentDidMount
  // useEffect(() => {
  //   if (isUserLoggedIn() !== null) {
  //     setUserData(JSON.parse(localStorage.getItem('userData')))
  //   }
  // }, [])

  //** Vars
  const userAvatar = (userData && userData.avatar) || defaultAvatar
  const toggleDropdown = (e) => {
    e.preventDefault()
    setIsChevron(!isChevron)
  }

  const handleLogout = () => {
    dispatch(logout())
  }

  return (
    <UncontrolledDropdown tag="li" className="dropdown-user nav-item hub-user-dropdown-wrapper">
      <DropdownToggle toggle={() => {}} href="/" tag="a" 
        onClick={(e) => toggleDropdown(e)} data-toggle='dropdown' className="nav-link dropdown-user-link mb-10px">
        <div className='me-1 w-100' >
          <span className='border height-36px width-36px rounded-circle bg-dark-1 p-2px flex-center-center text-dark'>{getNameInitials(userData && userData.name)}</span>
          {/* <Avatar img={userAvatar} imgHeight="35" imgWidth="35" status="online"/> */}
        </div>
        <div className="w-100">
          <div className='d-flex w-100'>
            <span className="user-name txt-body-rg text-dark" title={userData && userData.name}>
              {userData && userData.name}
            </span> 
            <span>
              <ChevronDown
                className={`boldChevron fw-bolder text-dark ${isChevron ? 'up' : 'down'}`}
                size={12}
              /><br/> 
            </span>
            {/* <span className="user-name fw-bolder text-dark" style={{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}} >{(userData && userData?.name) || 'John Doe'} </span> {isOpen ? <ChevronUp className='boldChevron fw-bolder text-dark' size={12} /> : <ChevronDown  className='boldChevron fw-bolder text-dark' size = {12} />} <br/> */}
          </div>
          <span className="user-status text-dark-6 text-truncate" title={userData && userData?.roles[0]?.name} >{userData && userData?.roles[0]?.name}</span> 
        </div>
      </DropdownToggle>
      <DropdownMenu className='custom-dropdown-menu' style={{transition: 'none'}} placement = "bottom-center">
        <DropdownItem toggle={false} className='w-100 custom-dropdown-item flex-center-start' disabled={loading} onClick={handleLogout}>
          {!loading && <Power size={14} className="me-75"/>}
          <span className="align-middle flex-center-center">
            {loading && <Spinner size="sm" className="me-75"/>}
            <span>{loading ? t('Logging out...') : t('Logout')}</span>
          </span>
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  )
}

export default UserDropdown
