import DropdownWithTitle from '@src/@core/components/ui/dropdown'
import {
  getAllZones,
  getPicklistsOfStatus,
  setSelectedZone
} from '@src/views/sales/store/store'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { components } from 'react-select'
const ZoneFilter = (props) => {
  const { wave, hub_id } = props
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const selectedGlobalHubId = useSelector((state) => state.auth.selectedGlobalHubId)
  const { zonesOptions, selectedZone, selectedPicklistStatus } = useSelector((state) => state.sales)
  const handleSelectZone = (zone) => {
    dispatch(setSelectedZone(zone))
    dispatch(
      getPicklistsOfStatus({
        type: selectedPicklistStatus?.value,
        waveId: wave?.waveId,
        zone: zone?.value?.id,
        hub_id
      })
    )
  }
  const CustomValueContainer = ({ children, ...props }) => {
    const { ValueContainer, Placeholder } = components

    return (
      <ValueContainer {...props}>
        <Placeholder {...props} isFocused={props.isFocused}>
          {props.selectProps.placeholder}
        </Placeholder>
        {React.Children.map(children, (child) => {
          return child && child.type !== Placeholder ? child : null
        })}
      </ValueContainer>
    )
  }
  useEffect(() => {
    if (!zonesOptions) {
      dispatch(getAllZones(selectedGlobalHubId))
    }
  }, [])
  return (
    <div>
      <DropdownWithTitle
        name="Select Zone"
        options={zonesOptions}
        value={(() => {
          if (!selectedZone) return { value: '', label:'All'}
          return selectedZone
        })()}
        isClearable={!!selectedZone}
        title={  t('Select Zone')  }
        onChange={handleSelectZone}
      />
    </div>
  )
}
export default ZoneFilter
