import CrossIcon from '@src/assets/images/icons/tableFilter/crossIcon'
import NoAppliedFilter from '@src/assets/images/icons/tableFilter/noAppliedFilter'
import classNames from 'classnames'
import { X } from 'react-feather'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'

const customStyles = {
  container: (provided) => ({
    ...provided,
    height: '100%'
  }),
  control: (provided) => ({
    ...provided,
    display: 'block',
    border: 'none',
    height: '100%',
    boxShadow: 'none'
  }),
  valueContainer: provided => ({
    ...provided,
    height: '100%',
    padding: '16px',
    gap: '12px',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'start',
    alignContent: 'flex-start',
    overflowY: 'auto'
  }),
  input: provided => ({
    ...provided,
    display: 'none !important'
  }),
  indicatorsContainer: provided => ({
    ...provided,
    display: 'none'
  }),
  dropdownIndicator: provided => ({
    ...provided,
    display: 'none'
  }),
  placeholder: provided => ({
    ...provided,
    margin: 0,
    textAlign: 'center'
  })
}
const CustomMultiValueContainer = (props) => {
  const {handleRemoveAppliedFilter, data: {color, logo, label, value, filterKey}} = props
  const customKey = `${filterKey}-${value}`
  const labelStyle = {color: color || 'text-dark', background: color ? `${color}1A` : 'transparent'}
  return (
    <div key={customKey} className='cursor-pointer multi-value-badge' onClick={() => handleRemoveAppliedFilter(props.data)}> 
      <div className='applied-filter-label-container d-flex align-items-center'>
        {logo && <div className='custom-option-logo bg-white'><img className='object-fit-contain' src={logo} width={24} height={16} alt={name}/></div>}
        <span className={classNames('multi-value-label txt-sub-rg rounded-10px', {'custom-option-label-extra-padding' : color})} style={labelStyle} >{label}</span>
      </div>
      <X size={18} className='ms-25 text-dark-6'/>
    </div>
  )
}
const CustomPlaceholder = () => {
  const {t} = useTranslation()
  return (
    <div className='d-flex flex-column align-items-center justify-content-center h-100 w-100 applied-filters-custom-placeholder'>
      <div className='position-relative bg-primary-lighter placeholder-icon-container'>
        <CrossIcon />
        <NoAppliedFilter/>
      </div>
      <div>{t('No applied filter to show')}</div>
    </div>
  )
}

const AppliedFilter = ({handleRemoveAppliedFilter, allFilters}) => {
  return (
    <Select 
      value={allFilters}
      isMulti
      styles={customStyles}
      menuIsOpen={false}
      isSearchable={false}
      isClearable={false}
      components={{
        Placeholder: CustomPlaceholder,
        MultiValue: (props) => (
          <CustomMultiValueContainer
            handleRemoveAppliedFilter={handleRemoveAppliedFilter}
            {...props}
          />
        )
      }}
    />
  )
}
export default AppliedFilter