// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'

export const bulkShipSlice = createSlice({
  name: 'bulkShip',
  initialState: {
    selectedCluster: null,
    clusterPickingWave: null,
    selectedOrdersForShipments: {},
    changedOrdersForShipments: {},
    creationStatuses: null
  },
  reducers: {
    setSelectedCluster: (state, { payload }) => {
      state.selectedCluster = payload
    },
    clearSelectedCluster: (state) => {
      state.selectedCluster = null
    },
    setClusterPickingWave: (state, { payload }) => {
      state.clusterPickingWave = payload
    },
    clearClusterPickingWave: (state) => {
      state.clusterPickingWave = null
    },
    setSelectedOrdersForShipments: (state, { payload }) => {
      state.selectedOrdersForShipments = payload
    },
    setChangedOrdersForShipments: (state, { payload }) => {
      state.changedOrdersForShipments = payload
    },
    setCreationStatuses: (state, { payload }) => {
      state.creationStatuses = payload
    }
  }
})

export const { clearSelectedCluster, setSelectedCluster, setClusterPickingWave, clearClusterPickingWave, setSelectedOrdersForShipments, setChangedOrdersForShipments, setCreationStatuses } = bulkShipSlice.actions

export default bulkShipSlice.reducer
