import { forwardRef } from 'react'
import { useTranslation } from 'react-i18next'

const ThermalPreview = forwardRef(({ printData, hideFooterDiscountFiled }, ref) => {
  const { t } = useTranslation('translation', { lng: printData.preferences.language })
  const isRTL = printData.preferences.language === 'ar'
  if (!printData) return null

  const currency = printData.currency || ''
  const columnClass = printData.itemSection.include_price.show ? 'four-columns' :
    (printData.itemSection.total_tax_inclusive.show || printData.itemSection.total_tax_exclusive.show) ? 'three-columns' : 'two-columns'

  const shouldIgnoreHeaderFieldInInvoice = (fieldId) => {
    const ignoredFields = ['company_logo']
    return ignoredFields.includes(fieldId)
  }

  const isHeaderVisible = Object.values(printData.headerSection)
    .filter(item => !shouldIgnoreHeaderFieldInInvoice(item.id))
    .some(item => item.show === true)

  const textSize = printData.preferences.textSize || 'medium'

  const fontSizeMap = {
    small: '12px',
    medium: '14px',
    large: '16px'
  }

  const fontSize = fontSizeMap[textSize] || fontSizeMap['medium']

  const styles = {
    thermalPreviewLayout: {
      maxWidth: '500px',
      background: '#FFF',
      fontSize,
      direction: isRTL ? 'rtl' : 'ltr'
    },
    header: {
      padding: '16px 0',
      textAlign: 'center',
      fontWeight: '600',
      borderBottom: '1px solid #DEDEDE',
      color: '#222222'
    },
    logo: {
      height: '46px',
      width: '158px',
      textAlign: 'center',
      paddingTop: '16px',
      margin:'auto'
    },
    details: {
      display: 'flex',
      flexDirection: 'column',
      gap: '8px',
      borderBottom: '1px solid #DEDEDE',
      padding: '16px'
    },
    detail: {
      display: 'flex',
      fontWeight: '400',
      gap: '24px'
    },
    label: {
      minWidth: '40%',
      maxWidth: '40%',
      color: '#666666'
    },
    value: {
      color: '#222222',
      wordBreak: 'break-all',
      verticalAlign: 'top'
    },
    itemSection: {
      marginTop: '24px',
      borderBottom: '1px solid #DEDEDE',
      padding: '0px 16px'
    },
    fourColumns: {
      '& th, & td': {
        '&:nth-child(1)': {
          width: '5%'
        },
        '&:nth-child(2)': {
          width: '55%'
        },
        '&:nth-child(3)': {
          width: '20%'
        },
        '&:nth-child(4)': {
          width: '20%'
        }
      }
    },
    threeColumns: {
      '& th, & td': {
        '&:nth-child(1)': {
          width: '5%'
        },
        '&:nth-child(2)': {
          width: '55%'
        },
        '&:nth-child(3)': {
          width: '40%'
        }
      }
    },
    twoColumns: {
      '& th, & td': {
        '&:nth-child(1)': {
          width: '5%'
        },
        '&:nth-child(2)': {
          width: '95%'
        }
      }
    },
    generalTable: {
      width: '100%',
      borderCollapse: 'collapse',
      direction: isRTL ? 'rtl' : 'ltr',
      padding: '16px'
    },
    tableHeader: {
      textAlign: 'start',
      fontWeight: '500',
      color: '#666666',
      backgroundColor: '#F8F8F8',
      verticalAlign: 'top',
      wordBreak: 'break-all'
    },
    tableCell: {
      fontWeight: '400',
      color: '#222222',
      wordWrap: 'break-word',
      whiteSpace: 'normal',
      verticalAlign: 'top',
      padding:'4px'
    },
    footerSection: {
      display: 'flex',
      flexDirection: 'column',
      gap: '8px',
      borderBottom: '1px solid #DEDEDE',
      padding: '16px'
    },
    footerItem: {
      display: 'flex',
      gap: '16px',
      direction: isRTL ? 'rtl' : 'ltr'
    },
    footerLabel: {
      minWidth: '45%',
      maxWidth: '45%',
      color: '#666666',
      fontSize,
      wordBreak: 'break-all'
    },
    footerValue: {
      color: '#222222',
      textAlign: 'right',
      width: '100%',
      fontSize,
      wordBreak: 'break-all'
    },
    commentSection: {
      display: 'flex',
      flexDirection: 'column',
      gap: '16px',
      padding: '16px',
      alignItems: 'center',
      textAlign: 'center',
      color: '#222222'
    },
    commentImage: {
      objectFit: 'contain',
      height: '80px',
      width: '80px'
    },
    salesNotes: {
      textAlign: 'center',
      padding: '24px',
      borderBottom: '1px solid #DEDEDE',
      fontSize
    }
  }
  

  const renderCommentSection = () => {
    const comment = printData.footerSection.comment
    const commentImage = printData.footerSection.comment_image

    if ((comment.show && comment.value) || (commentImage.show && commentImage.value)) {
      return (
        <div style={styles.commentSection}>
          {commentImage.show && commentImage.value && (
            <img src={commentImage.url} style={styles.commentImage} alt='comment image' />
          )}
          {comment.show && comment.value && (
            <p style={{ margin: '0' }}>{comment.value}</p>
          )}
        </div>
      )
    } else {
      return null
    }
  }

  return (
    <div ref={ref} style={styles.thermalPreviewLayout}>
      {/* header section start */}
      {printData.headerSection.company_logo.show && printData.headerSection.company_logo.value && (
        <div style={styles.logo}>
          <img src={printData.headerSection.company_logo.value} alt="company-logo" style={{ objectFit: 'contain' }} height="38px" width="150px" />
        </div>
      )}
      <div style={styles.header}>
        {t('Invoice')}
      </div>
      {isHeaderVisible && <div style={styles.details}>
        {Object.values(printData.headerSection).map((item, index) => (
          !shouldIgnoreHeaderFieldInInvoice(item.id) && item.show && (
            <div style={styles.detail} key={index}>
              <span style={styles.label}>{t(item.label)}</span>
              <span style={styles.value}>{item.value}</span>
            </div>
          )
        ))}
      </div>}
      {/* header section end */}

      {/* items section start */}
      <div style={{ ...styles.itemSection, ...styles[columnClass] }}>
        <table style={styles.generalTable} className={columnClass}>
          <thead>
            <tr>
              <th style={styles.tableHeader}></th>
              <th style={{...styles.tableHeader}}>
                <span>{t('Product Name')}</span>
                {printData.itemSection.sku_barcode.show && <span>{' '}/ {t('SKU')}</span>}
              </th>
              {printData.itemSection.include_price.show && <th style={styles.tableHeader}>{t('Price (Tax Inclusive)')}</th>}
              {(printData.itemSection.total_tax_inclusive.show || printData.itemSection.total_tax_exclusive.show) && <th style={styles.tableHeader}>
                {printData.itemSection.total_tax_inclusive.show && <span>{t('Total (Tax Inclusive)')}</span>}
                {printData.itemSection.total_tax_exclusive.show && !printData.itemSection.total_tax_inclusive.show && <span>{' '}{t('Total (Tax Exclusive)')}</span>}
              </th>}
            </tr>
          </thead>
          <tbody>
            {printData.items.map((item, index) => (
              <tr key={index}>
                <td style={styles.tableCell}><p style={{ margin: '0' }}>{item.qty}x</p></td>
                <td style={styles.tableCell}>
                  <p style={{ margin: '0' }}>{item.product_name}</p>
                  {printData.itemSection.sku_barcode.show && <p style={{ margin: '0' }}>{item.sku_barcode}</p>}
                  {(printData.itemSection.tax_amount.show || printData.itemSection.tax_percentage.show) && <p style={{ margin: '0' }}>
                    <span>{t('Tax')}: </span>
                    {printData.itemSection.tax_amount.show && <span>{item.tax_amount} {currency}</span>}
                    {(printData.itemSection.tax_amount.show && printData.itemSection.tax_percentage.show) && <span> / </span>}
                    {printData.itemSection.tax_percentage.show && <span>{item.tax_percentage}%</span>}
                  </p>}
                </td>
                {printData.itemSection.include_price.show && <td style={styles.tableCell}>
                  <p style={{ margin: '0' }}>{item.price} {currency}</p>
                  {!(printData.footerSection.hide_discount_if_not_exist.show && item.discount_amount === '0.00') && printData.itemSection.discount_amount.show && <p style={{ margin: '0' }}>{t('Disc')}: {item.discount_amount} {currency}</p>}
                </td>}
                {(printData.itemSection.total_tax_inclusive.show || printData.itemSection.total_tax_exclusive.show) && <td style={styles.tableCell}>
                  {printData.itemSection.total_tax_inclusive.show && <p style={{ margin: '0' }}>{item.total_tax_inclusive} {currency}</p>}
                  {printData.itemSection.total_tax_exclusive.show && <p style={{ margin: '0' }}>{t('Tax Exclusive')} {item.total_tax_exclusive} {currency}</p>}
                </td>}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {/* items section end */}

      {/* footer section start */}
      {printData.footerSection.display_footer_section.show && <div style={styles.footerSection}>
        {printData.footerSection.display_total_items.show && <div style={styles.footerItem}>
          <span style={styles.footerLabel}>{t('Total Items')}</span>
          <span style={styles.footerValue}>{printData.footerSection.totalItems}</span>
        </div>}
        <div style={styles.footerItem}>
          <span style={styles.footerLabel}>{t('Sub Total (Tax Exclusive)')}</span>
          <span style={styles.footerValue}>{printData.footerSection.subTotal} {currency}</span>
        </div>
        {!hideFooterDiscountFiled && <div style={styles.footerItem}>
          <span style={styles.footerLabel}>{t('Total Discounts')}</span>
          <span style={styles.footerValue}>{printData.footerSection.totalDiscounts} {currency}</span>
        </div>}
        {/* <div style={styles.footerItem}>
          <span style={styles.footerLabel}>{t('Other Discounts')}</span>
          <span style={styles.footerValue}>{printData.footerSection.otherDiscounts} {currency}</span>
        </div> */}
        <div style={styles.footerItem}>
          <span style={styles.footerLabel}>{t('Total Tax')}</span>
          <span style={styles.footerValue}>{printData.footerSection.totalTax} {currency}</span>
        </div>
        <div style={styles.footerItem}>
          <span style={styles.footerLabel}>{t('Total')}</span>
          <span style={styles.footerValue}>{printData.footerSection.total} {currency}</span>
        </div>
      </div>}
      {/* footer section end */}
      {printData.footerSection.sales_note.show && <div style={styles.salesNotes}>
        <p style={{ margin: '0', color:'#666666' }}>{t('Sales Note')}:</p>
        <p style={{ margin: '0' }}>{printData.footerSection.sales_note.value}</p>
      </div>}
      {/* comment section start */}
      {renderCommentSection()}
      {/* comment section end */}
    </div>
  )
})

export default ThermalPreview
