export const DeliveryNote = `<html>
<head>
<link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">
<style>
@page {
    margin: 16px 25px 16px 25px;
}
@media screen {
    .print-purchase-order {
      
    }
  }
.print-purchase-order {
    box-sizing: border-box;
    color: #222222;

    .horizontal_divider {
        border-bottom: 1px solid #EEEFF2;
        padding-bottom: 24px;
        margin-bottom: 24px;
    }

    .title {
        font-weight: 600;
        font-size: 16px;
        // font-family: Poppins;
    }

    .heading {
        font-weight: 600;
        font-size: 14px;
        color: #222222;
        margin-bottom: 12px;
    }

    .amount_section{
        text-align:right;
    }

    .table-title {
        font-size: 12px;
        color: #222222;
        font-weight: 600;
        line-height: 18px;
        background-color: #F4F5F7 !important;
        print-color-adjust: exact
    }

    .section_1{
        margin-top: 29px;

        .deliverly_note_attribute{
            font-weight: 400;
            font-size: 12px;
            color: #666666;
            // font-family: Poppins;
        }

        .deliverly_note_value{
            font-weight: 500;
            font-size: 12px;
            color: #222222;
            line-height: 20px;
            // font-family: Poppins;
        }
        .margins {
            margin: 12px 0px 12px 0px;
        }
        .paddings{
            padding-right: 9px;
        }

    }

    .section_2{
        .deliverly_note_attribute{
            font-weight: 400;
            font-size: 12px;
            color: #666666;
            // font-family: Poppins;
        }
        .deliverly_note_value{
            font-weight: 400;
            font-size: 12px;
            color: #222222;
            line-height: 20px;
            // font-family: Poppins;
        }
        .margins {
            margin: 8px 0px 8px 0px;
        }
        .paddings{
            padding-right: 14px;
        }
    }

    .section_3{
        .deliverly_note_value{
            font-weight: 400;
            font-size: 12px;
            color: #222222;
            line-height: 20px;
            // font-family: Poppins;
        }
        .margins {
            margin: 8px 0px 8px 0px;
        }
        .paddings{
            padding-right: 15.5px;
        }
    }

    .section_4 {
        .deliverly_note_value{
            font-weight: 400;
            font-size: 12px;
            color: #222222;
            line-height: 20px;
            // font-family: Poppins;
        }
        .signature{
           border-bottom : 1px dotted black;
           width:30%
        }
    }

    .remarks{
        min-height: 80px;
        border: 1px solid #EEEFF2;
        padding: 8px;
        border-radius: 4px;
        word-spacing: 1px;
        font-size: 18px;
        margin-top: 4px;
    }

    .authorization_section{
        margin-top: 5px;
        .margins{
            margin: 16px 0px 16px 0px
        }
    }

    & table {
    margin: 24px 0px 32px 0px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border: 1px solid #EEEFF2;
    border-collapse: separate;
    border-spacing: 0;
    }

    & tr:first-child td:first-child { border-top-left-radius: 6px; }
    & tr:first-child td:last-child { border-top-right-radius: 6px; }

    & td {
        height: 32px;
        font-size: 11px;
        font-weight: 400;
        padding-left: 12px;
        border: 1px solid #EEEFF2;
        border-collapse: separate;
        border-spacing: 0;
        padding: 10px 10px;
        color: #222222;
    }

    & th {
        font-size: 11px;
        font-weight: 500;
        padding-left: 12px;
        border: 1px solid #EEEFF2;
        border-collapse: separate;
        border-spacing: 0;
        padding: 10px 10px;
        color: #222222;
    }
    .row_serial_number{
        min-width:56px
    }
    .row_seller_sku_name{
        min-width:150px 
    }
    .row_seller_sku_code{
        min-width:100px
    }
    .row_ordered_quantity{
        min-width:24px
    }
    .row_packed_quantity{
        min-width:96px
    }
    .row_unit_price{
        min-width:90px
    }
    .row_total{
        min-width:90px
    }
}

</style>
</head><body><div id='root'>

</div></body></html>`