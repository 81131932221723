import { SKU_TYPE } from '@src/views/inventory/inventory.constants'
import classNames from 'classnames'
import { ChevronDown, ChevronRight } from 'react-feather'
import { Badge } from 'reactstrap'

const ItemToggleBar = ({ sku_details, isExpanded, toggleExpanded, mode = 'opened' }) => {
  return (
    <div
      className='bg-light-1 py-8px ps-8px pe-16px rounded-28px flex-center-between cursor-pointer mb-1'
      style={{ border: '1px solid #DEDEDE' }}
      onClick={toggleExpanded}
    >
      <div className='flex-center-start gap-12px'>
        <div className='flex-center-start gap-8px'>
          <div className='text-primary'>
            {isExpanded ? <ChevronDown size={20} /> : <ChevronRight size={20} />}
          </div>
          <Badge pill className={classNames({
            'bg-brown-light text-brown': sku_details.package_type === SKU_TYPE.PALLET.value,
            'bg-info-light text-info': sku_details.package_type === SKU_TYPE.CASE_PACK.value,
            'bg-light-1 text-dark': sku_details.package_type === SKU_TYPE.EACH.value
          })}>
            {(sku_details.package_type === SKU_TYPE.PALLET.value) && SKU_TYPE.PALLET.label}
            {(sku_details.package_type === SKU_TYPE.CASE_PACK.value) && SKU_TYPE.CASE_PACK.label}
            {(sku_details.package_type === SKU_TYPE.EACH.value) && SKU_TYPE.EACH.label}
          </Badge>
        </div>
        <div className='text-dark txt-sub-rg'>{sku_details.name}</div>
      </div>
      <div className={classNames('txt-asst-md', {
        'text-success': mode === 'opened',
        'text-dark-6': mode === 'closed'
      })}>
        {mode === 'opened' ? `Opened (${sku_details.openedQty})` : `Closed (${sku_details.closedQty})`}
      </div>
    </div>
  )
}

export default ItemToggleBar