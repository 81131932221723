import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { Nav, NavItem, NavLink } from 'reactstrap'


const TabOrderDetails = memo(({ tab, active, onClick }) => {
  const { name } = tab
  const isActive = active === name
  const {t} = useTranslation()
  return (
    <NavItem style={{ borderBottom: active === name ? '2px solid var(--bs-primary)' : '2px solid  rgba(238, 240, 255, 0.25)' }} className={'d-flex'}>
      <NavLink
        active={isActive}
        onClick={() => onClick(name)}
        role="tab"
        style={{padding: '6px 12px'}}
        className="d-flex align-items-center"
      >
        <span style={{ fontSize: '12px', color: active === name ? 'var(--bs-primary)' : '#222222' }}>
          {t(name)}
        </span>
      </NavLink>

    </NavItem>
  )
})


const OrderDetailsTabNames = ({ TABS_DATA, activeTab, toggleTab }) => {
  return (
    <Nav className="w-100 txt-sub-md" role="tablist">
      {Object.entries(TABS_DATA).filter((entry) => entry[1].is_visible).map(([key, tab]) => {
        return <TabOrderDetails key={key} tab={tab} active={activeTab} onClick={toggleTab} />
      })}
    </Nav>
  )
}

const OrderDetailsTabs = ({ orderDetails, TABS_COMPONENTS, activeTab, orderDetail, setShipmentSlider, sellerId, setIsModalOpen, setHandlePackingSideSlider, hubId, handleGetParentData }) => {

  const ActiveComponent = TABS_COMPONENTS[activeTab]

  return (<>
    <ActiveComponent id={orderDetails?.id} orderDetail={orderDetail} setShipmentSlider={setShipmentSlider} sellerId={sellerId} setIsModalOpen={setIsModalOpen} setHandlePackingSideSlider={setHandlePackingSideSlider} hubId={hubId} handleGetParentData={handleGetParentData}/>
  </>
  )
}

export { OrderDetailsTabs, OrderDetailsTabNames }  