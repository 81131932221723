// ** Reducers Imports
import auth from './authentication'
import layout from './layout'
// import navbar from './navbar'

// import hubs from '@src/views/hubs-management/store'
import purchase from '@src/views/Purchases/store'
import catalog from '@src/views/catalog/store'
import fleet from '@src/views/fleet/store'
import revampedHubs from '@src/views/hubs/store'
import inventory from '@src/views/inventory/store/index'
import salesChannel from '@src/views/sales-channel/store'
import sales from '@src/views/sales/store/store'
import seller from '@src/views/sellers/store'
import shipping from '@src/views/shipping/store'
// import roleSettings from '@src/views/settings/role-settings/store/store.js'
// import manageInvoice from '@src/views/settings/manage-invoice/store'
import bulkShip from '@src/views/bulk-ship/store'
import reports from '@src/views/reports/store'
import returns from '@src/views/returns/store'
import allSettings from '@src/views/settings/store'

const rootReducer = {
  auth,
  // navbar,
  layout,
  // hubs,
  revampedHubs,
  shipping,
  salesChannel,
  catalog,
  inventory,
  sales,
  purchase,
  seller,
  // roleSettings,
  // manageInvoice,
  fleet,
  returns,
  allSettings,
  reports,
  bulkShip
}

export default rootReducer