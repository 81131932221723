import BarcodeScanner from '@src/@core/components/ui/barcodeScanner'
import CustomToast from '@src/@core/components/ui/custom-toast/CustomToast'
import ToggleTabs from '@src/@core/components/ui/toggleTabs'
import { KEYBOARD_KEYS } from '@src/App.constants'
import { clearAddItemInGrnBinResponse, clearBinStatus, clearLastScannedBin, clearLastScannedSKUComplete, clearQcPrevData, clearScannedSkuDetails, getSkuDetails, resetSuccessState, setScannedSkuDetails, updateGtinSkuDetails, updateIsGtinChanged, updateIsShelfLifeBreached } from '@src/views/inventory/store'
import { forwardRef, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { BATCH_STATUS, SEARCH_SKU_TYPES } from '../constant'
import NoSkuScannedState from './NoSkuScannedState'
import SkuComponent from './SkuComponent'
import { nonSerialisedBatchedSkuScan, nonSerialisedBatchedSkuScanFromModal, useEnforcedFailNonSerialisedBatchedSkuScan, useEnforcedFailNonSerialisedBatchedSkuScanFromModal, useEnforcedFailSerialisedBatchedSkuScan, useEnforcedFailSerialisedBatchedSkuScanFromModal, useGetUpdatedSkuDetails, useNonSerialisedNonBatchedSkuScan, useNonSerialisedNonBatchedSkuScanFromModal, useSerialisedBatchedSkuScan, useSerialisedBatchedSkuScanFromModal } from './grnBinDisabledApiHooks/skuScanHooks'
import GtinWarningModal from './gtinWarningModal'
import OverReceiveItemModal from './qc-component/overReceiveItemModal'
import SearchSkuByCode from './qc-component/searchSkuByCode'
import SkuNotPartOfOrderModal from './qc-component/skuNotPartOfOrderModal'

const SkuScan = forwardRef(({ control, reset, watch, setValue, register, searchBarcode, grnType }, ref) => {
  const {
    tableData, 
    enforcedFailReason, 
    enforcedFailUploadIds, 
    returnGrnResponse, 
    selectedGlobalHubId, 
    grnResponse, 
    displayGrnDetails,
    skuScanLoading,
    skuScanFailed,
    sku_details,
    sku_details_loading,
    configurations,
    singleGrnDetail,
    isShelfLifeBreached,
    successState,
    isGtinChanged,
    gtinSkuDetails
  } = useSelector(store => ({
    tableData: store.inventory.binDisabledGrn.tableData,
    enforcedFailReason: store.inventory.enforcedFailReason,
    enforcedFailUploadIds: store.inventory.enforcedFailUploadIds,
    returnGrnResponse: store.returns.createReturnGrnResponse?.data,
    selectedGlobalHubId: store.auth.selectedGlobalHubId,
    grnResponse: store.inventory.grn.grnResponse,
    displayGrnDetails: store.inventory.grn.displayGrnDetails,
    skuScanLoading: store.inventory.loadingState.getSkuDetails,
    skuScanFailed: store.inventory.errorState.getSkuDetails,
    sku_details: store.inventory.grn.sku_details?.data,
    sku_details_loading: store.inventory.grn.sku_details_loading,
    configurations: store.auth.selectedHubConfiguration,
    singleGrnDetail: store.inventory.grn.singleGrnDetail,
    isShelfLifeBreached: store.inventory.isShelfLifeBreached,
    successState: store.inventory.successState,
    isGtinChanged : store.inventory.isGtinChanged,
    gtinSkuDetails: store.inventory.gtinSkuDetails
  }))

  const qcBinBarcodeRef = useRef(null)
  const isBinDisabled = !configurations?.find(item => item.configuration_type === 'bin')?.configuration_values?.enabled
  const dispatch = useDispatch()
  const [isBarcodeScanned, setIsBarcodeScanned] = useState(false)
  const [isOverReceiveModalOpen, setIsOverReceiveModalOpen] = useState(false)
  const [isSkuNotExistModalOpen, setIsSkuNotExistModalOpen] = useState(false)
  const entityID = singleGrnDetail?.is_stock_transfer_order ? singleGrnDetail.entity_id : singleGrnDetail?.is_purchase_order && !singleGrnDetail.is_po_over_receive ? singleGrnDetail.entity_id : undefined
  const entityType = singleGrnDetail?.is_stock_transfer_order ? singleGrnDetail.entity_type : singleGrnDetail?.is_purchase_order && !singleGrnDetail.is_po_over_receive ? singleGrnDetail.entity_type : undefined
  const isMaxShelfLifeEnforced = configurations?.find(item => item.configuration_type === 'acceptable_shelf_life_validation')?.configuration_values?.enabled
  const [scannedBarcode, setScannedBarcode] = useState(null)

  const updateNonSerialsedNonBatchedBinDisabledSkuDetails = useNonSerialisedNonBatchedSkuScan({sku_details, isOverReceiveModalOpen, setIsOverReceiveModalOpen, setIsSkuNotExistModalOpen, grnResponse, tableData, displayGrnDetails, dispatch, selectedGlobalHubId})
  const {updateNonSerialisedNonBatchedBinDisabledSkuDetailsFromModal, nonSerialisedNonBatchedloadingState} = useNonSerialisedNonBatchedSkuScanFromModal({sku_details, isOverReceiveModalOpen, isSkuNotExistModalOpen, setIsOverReceiveModalOpen, setIsSkuNotExistModalOpen, grnResponse, tableData, dispatch, selectedGlobalHubId})
  const updateNonSerializedBatchedBinDisabledSkuDetails = nonSerialisedBatchedSkuScan({sku_details, isOverReceiveModalOpen, returnGrnResponse, setIsOverReceiveModalOpen, setIsSkuNotExistModalOpen, grnResponse, tableData, displayGrnDetails, dispatch, selectedGlobalHubId, scannedBarcode})
  const {updateNonSerializedBatchedBinDisabledSkuDetailsFromModal, nonSerialisedBatchedloadingState} = nonSerialisedBatchedSkuScanFromModal({skuDetails:sku_details, isOverReceiveModalOpen, isSkuNotPartModalOpen:isSkuNotExistModalOpen, setIsSkuNotPartModalOpen:setIsSkuNotExistModalOpen, setIsOverReceiveModalOpen, grnResponse, tableData, displayGrnDetails, returnGrnResponse, selectedGlobalHubId, dispatch, scannedBarcode})
  const updateNonSerialisedBatchedEnforcedMarkAsFail = useEnforcedFailNonSerialisedBatchedSkuScan({skuDetails:sku_details, isOverReceiveModalOpen, setIsOverReceiveModalOpen, setIsSkuNotPartModalOpen:setIsSkuNotExistModalOpen, tableData, enforcedFailReason, enforcedFailUploadIds, returnGrnResponse, selectedGlobalHubId, grnResponse, displayGrnDetails, dispatch, scannedBarcode})
  const {updateNonSerialisedBatchedEnforcedMarkAsFailModalOpen, enforcedFailNonSerialisedBatchedloadingState} = useEnforcedFailNonSerialisedBatchedSkuScanFromModal({skuDetails:sku_details, isOverReceiveModalOpen, setIsOverReceiveModalOpen, setIsSkuNotPartModalOpen:setIsSkuNotExistModalOpen, isSkuNotPartModalOpen:isSkuNotExistModalOpen, tableData, enforcedFailReason, enforcedFailUploadIds, returnGrnResponse, selectedGlobalHubId, grnResponse, displayGrnDetails, dispatch, scannedBarcode})
  const updateSerializedBatchedBinDisabledSkuDetails = useSerialisedBatchedSkuScan({sku_details, isOverReceiveModalOpen, scannedBarcode, returnGrnResponse, setIsOverReceiveModalOpen, setIsSkuNotExistModalOpen, grnResponse, tableData, displayGrnDetails, dispatch, selectedGlobalHubId})
  const {updateBinDisabledSerialisedBatchedSkuDetailsFromModal, serialisedBatchedloadingState} = useSerialisedBatchedSkuScanFromModal({skuDetails:sku_details, isOverItemModalOpen:isOverReceiveModalOpen, isSkuNotPartModalOpen:isSkuNotExistModalOpen, scannedBarcode, setOverItemModalOpen:setIsOverReceiveModalOpen, setSkuNotPartModalOpen:setIsSkuNotExistModalOpen, tableData, returnGrnResponse, grnResponse, displayGrnDetails, dispatch, selectedGlobalHubId})
  const updateEnforcedSerialisedBatchedBinsabledSkuDetails = useEnforcedFailSerialisedBatchedSkuScan({sku_details, isOverReceiveModalOpen, setIsOverReceiveModalOpen, setIsSkuNotExistModalOpen, scannedBarcode, grnResponse, tableData, displayGrnDetails, enforcedFailUploadIds, enforcedFailReason, returnGrnResponse, dispatch, selectedGlobalHubId})
  const {updateEnforcedFailSerialisedBatchedFailedModal, enforcedFailSerialisedBatchedloadingState} = useEnforcedFailSerialisedBatchedSkuScanFromModal({skuDetails:sku_details, isOverItemModalOpen:isOverReceiveModalOpen, scannedBarcode, isSkuNotPartModalOpen:isSkuNotExistModalOpen, setOverItemModalOpen:setIsOverReceiveModalOpen, setSkuNotPartModalOpen:setIsSkuNotExistModalOpen, grnResponse, tableData, displayGrnDetails, enforcedFailUploadIds, enforcedFailReason, returnGrnResponse, dispatch, selectedGlobalHubId})
  const getUpdatedSkuDetails = useGetUpdatedSkuDetails({sku_details, isMaxShelfLifeEnforced, isShelfLifeBreached, updateNonSerialsedNonBatchedBinDisabledSkuDetails, updateEnforcedSerialisedBatchedBinsabledSkuDetails, updateSerializedBatchedBinDisabledSkuDetails, updateNonSerializedBatchedBinDisabledSkuDetails, updateNonSerialisedBatchedEnforcedMarkAsFail, grnResponse, tableData, dispatch, selectedGlobalHubId})

  const [selectedTabId, setSelectedTabId] = useState(
    SEARCH_SKU_TYPES.sku_barcode.id
  )
  const [isGtinModalOpen, setIsGtinModalOpen] = useState(false)

  const tabChangeHandler = (tab) => {
    setSelectedTabId(tab.id)
    setValue('search_barcode', '')
    setScannedBarcode(null)
    setValue('selected_batch', null)
    setValue('omniful-sku-barcode', '')
    dispatch(clearAddItemInGrnBinResponse())
    dispatch(clearBinStatus())
    dispatch(clearQcPrevData())
    dispatch(clearScannedSkuDetails())
    // setTotalItemQtyToScan(1)
    reset()
  }

  const handleSearchBarcode = (e) => {
    if (e.key === KEYBOARD_KEYS.ENTER) {
      if (e.target.value === '') {
        CustomToast(('Please enter a barcode'), { my_type: 'error', audioRequired: false })
        return
      }
      const rawBarcode = e.target.value.trim();
      let decodedBarcode;
      try {
        decodedBarcode = decodeURIComponent(rawBarcode);
      } catch (error) {
        // can put a cutom toast to show user that barcode format is invalid but need to confirm with PM
        console.log(error.message, '- Invalid Barcode Format')
        return;
      }
      setScannedBarcode(rawBarcode)
      
      dispatch(updateIsShelfLifeBreached(false))
      setValue('selected_batch', null)
      dispatch(clearAddItemInGrnBinResponse())
      dispatch(clearLastScannedSKUComplete())
      setValue('omniful-sku-barcode', '')
      if (!isBinDisabled){
        dispatch(clearBinStatus())
        setValue('scanned-bin', '')
      }
      if (!gtinSkuDetails.seller_sku_id) {
        dispatch(clearScannedSkuDetails())
      }
      dispatch(
        getSkuDetails({
          sku_barcode: decodedBarcode,
          seller_id: grnResponse?.seller?.id,
          hub_id: selectedGlobalHubId,
          grn_id: grnResponse?.id,
          entity_id: entityID,
          entity_type:entityType,
          gtinSkuScanned: !isBinDisabled,
          errorConfig: {
            audioRequired: true
          }
        })
      )
      if (isBinDisabled) {
        setIsBarcodeScanned(true)
      }   
    }
  }

  const handleClearSearchBarcode = () => {
    setValue('search_barcode', '')
    setScannedBarcode(null)
    setValue('omniful-sku-barcode', undefined)
    setValue('scanned-bin', '')
    setValue('search_sku', '')
    setValue('selected_batch', null)
    // setTotalItemQtyToScan(1)
    dispatch(clearAddItemInGrnBinResponse())
    dispatch(clearBinStatus())
    if (!gtinSkuDetails.seller_sku_id) {
      dispatch(clearScannedSkuDetails())
    }
  }

  const handleChangeQty = () => {
    setIsOverReceiveModalOpen(p => !p)
  }

  const handleSaveAnyway = () => {
    if (!sku_details?.sku_config?.is_batching_enable && !sku_details?.sku_config?.is_serialisation_enable) {
      updateNonSerialisedNonBatchedBinDisabledSkuDetailsFromModal()
    }

    if (sku_details?.is_gtin_enabled && sku_details.sku_config?.is_batching_enable) {
      if (sku_details.sku_config.is_serialisation_enable) {
        if (isMaxShelfLifeEnforced && isShelfLifeBreached) {
          updateEnforcedFailSerialisedBatchedFailedModal()
        } else {
          updateBinDisabledSerialisedBatchedSkuDetailsFromModal()
        }
      } else {
        if (isMaxShelfLifeEnforced && isShelfLifeBreached) {
          updateNonSerialisedBatchedEnforcedMarkAsFailModalOpen()
        } else {
          updateNonSerializedBatchedBinDisabledSkuDetailsFromModal()
        }
      }
    }
  }

  const handleSkipSku = () => {
    setValue('search_sku', '')
    setValue('search_barcode', '')
    setScannedBarcode(null)
    setValue('selected_batch', null)
    setIsSkuNotExistModalOpen(false)
    dispatch(clearScannedSkuDetails())
  }

  const handleGtinSkip = async () => {
    const rawBarcode = watch('search_barcode')?.trim() || scannedBarcode?.trim();
    let decodedBarcode;

    try {
      decodedBarcode = decodeURIComponent(rawBarcode);
    } catch (error) {
      // can put a cutom toast to show user that barcode format is invalid but need to confirm with PM
      console.log(error.message, '- Invalid Barcode Format')
      return;
    }

    await dispatch(clearScannedSkuDetails())
    await dispatch(updateGtinSkuDetails({}))
    await dispatch(updateIsGtinChanged(false))
    await dispatch(
      getSkuDetails({
        sku_barcode: decodedBarcode,
        seller_id: grnResponse?.seller?.id,
        hub_id: selectedGlobalHubId,
        grn_id: grnResponse?.id,
        entity_id: entityID,
        entity_type:entityType,
        gtinSkuScanned: !isBinDisabled
      })
    )
    setValue('search_barcode', '')
    setScannedBarcode(null)
    ref.current?.focus()
    setIsGtinModalOpen(p => !p)
  }

  const handleGtinGoBack = async () => {
    await dispatch(setScannedSkuDetails({
      data : {
        ...gtinSkuDetails,
        isGoBack:true
      }
    }))
    setValue('search_barcode', '')
    setScannedBarcode(null)
    qcBinBarcodeRef.current?.focus()
    setIsGtinModalOpen(p => !p)
  }

  useEffect(() => {
    if (!isBinDisabled) {
      if (sku_details) {
        setValue('search_barcode', '')
        dispatch(clearLastScannedBin())
        if (gtinSkuDetails?.seller_sku_id && !sku_details.isGoBack && !isGtinChanged) {
          ref.current?.focus()
        } else {
          qcBinBarcodeRef.current?.focus()
        }
      }
      if (skuScanFailed) {
        setValue('search_barcode', '')
        setScannedBarcode(null)
      }
    }
    if (isBinDisabled) {
      if (skuScanFailed) {
        setValue('search_barcode', '')
        setScannedBarcode(null)
        ref.current?.focus()
      }
    }
  }, [sku_details, skuScanFailed])

  useEffect(() => {
    if (isBinDisabled) {
      if (sku_details?.seller_sku_id && isBarcodeScanned && !skuScanFailed) {
        setIsBarcodeScanned(false)
        getUpdatedSkuDetails()
        setValue('search_barcode', '')
        if (!sku_details?.is_gtin_enabled && sku_details.batch_status !== BATCH_STATUS.BATCHED) {
          setScannedBarcode(null)
        }
        ref.current?.focus()
      }
    }
  }, [sku_details])

  useEffect(() => {
    if (successState.getSkuDetails && !isBinDisabled) {
      if (isGtinChanged) {
        setIsGtinModalOpen(p => !p)
      } else {
        if (gtinSkuDetails?.is_gtin_enabled && gtinSkuDetails.batch_status === BATCH_STATUS.BATCHED) {
          dispatch(setScannedSkuDetails({
            data : {
              ...gtinSkuDetails,
              isGoBack:false
            }
          }))
        }
      }
      dispatch(resetSuccessState())
    }
  }, [successState])

  return (
    <>
      <div className="d-flex flex-column gap-16px">
        <ToggleTabs
          tabDataList={Object.values(SEARCH_SKU_TYPES)}
          selectedTabId={selectedTabId}
          onTabChange={tabChangeHandler}
        />
        {SEARCH_SKU_TYPES.sku_barcode.id === selectedTabId && (
          <BarcodeScanner
            ref={ref}
            width="380px"
            startIcon={
              <img
                src="https://cdn-icons-png.flaticon.com/128/1550/1550324.png"
                alt="QR Code"
                width="16px"
                height="16px"
              />
            }
            placeholder="Scan SKU Barcode"
            loading={skuScanLoading}
            control={control}
            name="search_barcode"
            onKeyDown={handleSearchBarcode}
            hasValue={watch('search_barcode')}
            handleClear={handleClearSearchBarcode}
          />
        )}
      
        {SEARCH_SKU_TYPES.search_sku.id === selectedTabId && (
          <SearchSkuByCode ref={qcBinBarcodeRef} register={register} watch={watch} setValue={setValue} />
        )}

        {(sku_details_loading && !gtinSkuDetails?.seller_sku_id) ? <div className="d-flex justify-content-between rounded-8px bg-light-1 p-16px border border-success-light">
          <div className="d-flex flex-column gap-8px w-50">
            <div className="skeleton-effect height-20px rounded-30px skeleton-row-1"></div>
            <div className="skeleton-effect height-20px rounded-30px skeleton-row-2"></div>
            <div className="skeleton-effect height-20px rounded-30px skeleton-row-3"></div>
            <div className="skeleton-effect height-20px rounded-30px skeleton-row-4"></div>
          </div>
          <div className="skeleton-effect height-50px me-40px width-50 align-self-center rounded-8px"></div>
        </div> :
          (gtinSkuDetails?.id || sku_details) ? <SkuComponent sku_details={gtinSkuDetails?.id ? gtinSkuDetails : sku_details} selectedTabId={selectedTabId} ref={qcBinBarcodeRef} searchBarcode={searchBarcode} grnType={grnType} scannedBarcode={scannedBarcode}/>
            : (isBinDisabled ? <NoSkuScannedState /> : null)
        }
      </div>

      <OverReceiveItemModal
        isOpen={isOverReceiveModalOpen}
        toggle={() => setIsOverReceiveModalOpen(p => !p)}
        handleChangeQuantity={handleChangeQty}
        handleSaveAnyway={handleSaveAnyway}
        loading={nonSerialisedNonBatchedloadingState || nonSerialisedBatchedloadingState || enforcedFailNonSerialisedBatchedloadingState || serialisedBatchedloadingState || enforcedFailSerialisedBatchedloadingState}
      />

      <SkuNotPartOfOrderModal
        isOpen={isSkuNotExistModalOpen}
        toggle={() => setIsSkuNotExistModalOpen(p => !p)}
        handleSaveAnyway={handleSaveAnyway}
        handleSkipThisSku={handleSkipSku}
        loading={nonSerialisedNonBatchedloadingState || nonSerialisedBatchedloadingState || enforcedFailNonSerialisedBatchedloadingState || serialisedBatchedloadingState || enforcedFailSerialisedBatchedloadingState}
      />

      <GtinWarningModal
        isOpen={isGtinModalOpen}
        handleGoBack={handleGtinGoBack}
        handleSkip={handleGtinSkip}
      />
    </>
  )
})

export default SkuScan

