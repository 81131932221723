import { Modal } from 'reactstrap'
import './confirmationPopup.scss'
const ConfiramtionPopup = (props) => {
  const {isConfirmationPopupOpen, handlePopupToggle, isToggle, popupBody, customWidth = '400px'} = props
  return (
    <Modal
      isOpen={isConfirmationPopupOpen}
      centered
      toggle={isToggle && handlePopupToggle}
      modalClassName="return-confirmation-popup"
      style={{width:customWidth}}
    >
      {popupBody}
    </Modal >
  )
}
export default ConfiramtionPopup