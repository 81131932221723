import { endOfMonth, format, startOfMonth, startOfYear, subDays, subHours, subMonths } from 'date-fns'

export const ORDER_STATUS = {
  ON_HOLD: { id: 1, name: 'On Hold', key: 'on_hold' },
  HUB_ASSIGNED: { id: 2, name: 'Hub Assigned' },
  IN_PICKING: { id: 3, name: 'In Picking', key: 'in_picking' },
  PICKED: { id: 4, name: 'Picked', key: 'picked' },
  CONSOLIDATED: { id: 5, name: 'Consolidated', key: 'consolidated' },
  IN_PACKING: { id: 6, name: 'In Packing', key: 'in_packing' },
  READY_TO_SHIP: { id: 7, name: 'Ready To Ship', key: 'ready_to_ship' },
  SHIPPED: { id: 8, name: 'Shipped', key: 'shipped' },
  PACKED: { id: 9, name: 'Packed', key: 'packed' },
  CANCELLED: { id: 10, name: 'Cancelled' },
  DELIVERED: { id: 11, name: 'Delivered' },
  RETURN_IN_PROGRESS: { id: 12, name: 'Return In Progress' },
  RETURN_IN_ORIGIN: { id: 13, name: 'Return In Origin' },
  RETURNED: { id: 14, name: 'Returned' },
  NEW_ORDER: { id: 15, name: 'New Order', key: 'new_order' },
  CREATED: { id: 16, name: 'Created' },
  ALL: { id: 17, name: 'All' },
  RETURN_TO_ORIGIN: { id: 18, name: 'Return to Origin' }
}
export const ORDER_TYPE = {
  B2C: 'b2c',
  STO: 'sto',
  B2b: 'b2b',
  RTV: 'rtv'
}
export const SHIPPING_OPTION = {
  SALES_CHANNEL: { id: 1, name: 'sales_channel' },
  MANUAL_DISPATCH: { id: 2, name: 'manual_dispatch' },
  SHIPPING_PARTNER: { id: 3, name: 'shipping_partner' },
  SALES_CHANNEL_NOTIFIED: { id: 4, name: 'sales_channel_notified' }
}

export const DELIVERY_TYPE = {
  CLICK_AND_COLLECT: { id: 1, name: 'click_and_collect', label: 'Click & Collect'},
  EXPRESS_DELIVERY: { id: 2, name: 'express_delivery', label:'Express Delivery'},
  SLOT_DELIVERY: { id: 3, name: 'slot_delivery', label:'Slot Delivery' },
  NORMAL_DELIVERY: { id: 4, name: 'normal_delivery', label:'Normal Delivery' }
}
export const customStyles = {
  control: (provided, state) => ({
    ...provided,
    width: '100%',
    borderColor: state.isFocused ? 'var(--bs-dark-2) !important' : 'var(--bs-dark-2) !important',
    height: '40px',
    fontSize: '12px',
    paddingTop: 0,
    '&:hover': {
      borderColor: 'var(--bs-primary) !important',
      cursor: 'pointer'
    }
  }),
  singleValue: provided => (
    {
      ...provided,
      color: 'var(--bs-dark) !important'
    }
  ),
  valueContainer: provided => ({
    ...provided,
    overflow: 'visible'
  }),
  placeholder: (provided, state) => ({
    ...provided,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    position: 'absolute',
    top: state.hasValue || state.selectProps.inputValue ? '-50%' : '14%',
    transition: 'top 0.1s, font-size 0.1s',
    fontSize: (state.hasValue || state.selectProps.inputValue) && 12,
    backgroundColor: 'var(--bs-white)'
  }),
  clearIndicator: provided => ({
    ...provided,
    padding: 0,
    fontSize: 2
  }),
  dropdownIndicator: provided => ({
    ...provided,
    padding: 0,
    marginInlineEnd: '10px',
    width: '20px'
  }),
  indicatorSeparator: provided => ({
    ...provided,
    display: 'none'
  }),
  menuList: provided => ({
    ...provided,
    color: 'var(--bs-dark)'
  })
}
export const orderStatusFilterOptions = (t) => [
  { value: 'new_order', label: t('New Order') },
  { value: 'consolidated', label: t('Consolidated') },
  { value: 'in_picking', label: t('In Picking') },
  { value: 'picked', label: t('Picked') },
  { value: 'in_packing', label: t('In Packing') },
  { value: 'packed', label: t('Packed') },
  { value: 'ready_to_ship', label: t('Ready To Ship') },
  { value: 'shipped', label: t('Shipped') },
  { value: 'delivered', label: t('Delivered') },
  { value: 'on_hold', label: t('On Hold') },
  { value: 'cancelled', label: t('Cancelled') },
  { value: 'partial_return', label: t('Partial Return') },
  { value: 'returned', label: t('Returned') },
  { value: 'return_in_progress', label: t('Return In Progress') },
  { value: 'return_to_origin', label: t('Return To Origin') }
]

export const deliveryStatusFilterOptions = (t) => [
  { label: t('Ready To Ship'), value: 'ready_to_ship' },
  { label: t('Dispatched'), value: 'dispatched' },
  { label: t('In Transit'), value: 'in_transit' },
  { label: t('Out For Delivery'), value: 'out_for_delivery' },
  { label: t('Delivered'), value: 'delivered' },
  { label: t('Cancelled'), value: 'cancelled' },
  { label: t('Return in Progress'), value: 'return_in_progress' },
  { label: t('Return To Origin'), value: 'return_to_origin' }
]

export const BULK_UPLOAD_FILE_FORMATS = {
  CSV: {
    format: '.csv',
    sampleFile: {
      b2b: 'https://omniful-staging-uploads.s3.eu-central-1.amazonaws.com/orders/offline-orders/samples/orders_b2b.csv',
      b2c: 'https://omniful-staging-uploads.s3.eu-central-1.amazonaws.com/orders/offline-orders/samples/orders_b2c.csv'
    }
  },
  XLS: { format: '.xls', sampleFile: '' }
}

export const CUSTOMER_ADDRESS = {
  CREATE_ADDRESS: { key: 'CREATE_ADDRESS', headerTitle: 'Add New Address', infoText:'create a address'  },
  EDIT_ADDRESS: { key: 'EDIT_ADDRESS', headerTitle: 'Edit Address', infoText:'edit a address' }
}
export const SALESMAN_ADDRESS = {
  CREATE_ADDRESS: { key: 'CREATE_ADDRESS', headerTitle: 'Add New Address', infoText:'create a address'  },
  EDIT_ADDRESS: { key: 'EDIT_ADDRESS', headerTitle: 'Edit Address', infoText:'edit a address' }
}

export const CHANGE_ADDRESS = {
  DELIVERY_ADDRESS: { key: 'DELIVERY_ADDRESS', headerTitle: 'Add New Address' },
  BILLING_ADDRESS: { key: 'BILLING_ADDRESS', headerTitle: 'Edit Address' }
}

export const ADDITIONAL_FILTER_DROPDOWN_STYLE = {
  control: (baseStyles) => ({
    ...baseStyles,
    width: '120px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    textAlign: 'start',
    border: 'none',
    boxShadow: 'none'
  })
}

export const SHIPPING_ENTITY = {
  FORWARD_ORDER: { key: 'FORWARD_ORDER', value: 'forward_order' },
  SHIPMENT_ORDER: { key: 'SHIPMENT_ORDER', value: 'shipment_order' },
  RETURN_ORDER: { key: 'RETURN_ORDER', value: 'return_order' },
}
// currency options 
export const currencyOptions = [
  { label: 'SAR', value: 'SAR' },
  { label: 'USD', value: 'USD' }
]

export const DATE_FILTER_KEY = {
  CREATED: 'created'
}

export const CREATE_SHIPMENT_STATUS = {
  SHIPPING_PARTNER_UNSELECTED: 'SHIPPING_PARTNER_UNSELECTED',
  SHIPPING_PARTNER_SELECTED: 'SHIPPING_PARTNER_SELECTED'
}

export const PRINT_BUTTON_FOR_MANUAL_SHIPMENT = {
  PRINT_AWB: 'print_awb',
  PRINT_DELIVERY_NOTE: 'print_delivery_note'
}

export const AUTOMATIC_CREATE_SHIPMENT_WITH_SALES_CHANNEL = false

export const WEIGHT_OPTIONS = [
  { label: 'Kg', value: 'Kg' },
  { label: 'g', value: 'g' }
]

export const ORDER_CREATION_TYPE = {
  SINGLE: 'single',
  BULK: 'bulk'
}

export const EMBEDED_FILTER_KEYS = {
  SELLER_ID: 'seller_id',
  SUPPLIER_ID: 'supplier_id'
}

export const WEIGHT_REPRESENTATION = {
  QUANTITY: 'quantity'
}

export const GRN_SEARCH_BY = {
  BARCODE: 'Barcode',
  SKU_CODE: 'SKU Code'
}

export const PICKING_TYPE = {
  SINGLE_ORDER: 'Single order',
  MULTI_ORDER: 'Multi order'
}

export const CONFIGURATION_TYPE = {
  PICKING: 'picking',
  LOCATION_INVENTORY: 'location_inventory'
}

export const SORT_ORDER = {
  ASC: 'asc',
  DESC: 'desc'
}

export const PICKING_TYPES = {
  SINGLE_ORDERS: 'Single Order',
  MULTI_ORDERS: 'Multi Order'
}

export const WAREHOUSE_PICKING_TYPE = {
  MULTI_COMPLETE_SEGREGATED_PICKING: { label: 'MultiCompleteSegregatedPicking', value: 1, maxLimit: 20 }, // First quadrant
  MULTI_COMPLETE_NON_SEGREGATED_PICKING: { label: 'MultiCompleteNonSegregatedPicking', value: 2 }, // Second quadrant
  MULTI_PARTIAL_NON_SEGREGATED_PICKING: { label: 'MultiPartialNonSegregatedPicking', value: 3, maxLimit: 20 }, // Third quadrant
  MULTI_PARTIAL_SEGREGATED_PICKING: { label: 'MultiPartialSegregatedPicking', value: 4 } // Fourth quadrant
}

export const PACKING_QTY = {
  ORDERED_QTY: { key: 'ordered', name: 'Ordered Qty' },
  PICKED_QTY: { key: 'picked', name: 'Picked Qty' },
  PASSED_QTY: { key: 'passed', name: 'Passed Qty' },
  FAILED_QTY: { key: 'failed', name: 'Failed Qty' }
}

export const FILTER_TYPES = {
  SELLER_ID: 'seller_id',
  SUPPLIER_ID: 'supplier_id'
}

export const FILTER_KEYS = {
  USAGE: 'usage',
  SHIPPING_PARTNER_TAG: 'shipping_partner_tag'
}

export const CUSTOMER_MODAL_TYPE = {
  CREATE_CUSTOMER: { key: 'CREATE_CUSTOMER', headerTitle: 'Create Customer', infoText:'create a customer' },
  EDIT_CUSTOMER: { key: 'EDIT_CUSTOMER', headerTitle: 'Edit Customer', infoText:'edit a customer' }
}
export const SALESMAN_MODAL_TYPE = {
  CREATE_SALESMAN: { key: 'CREATE_SALESMAN', headerTitle: 'Create Salesman', infoText:'create a salesman' },
  EDIT_SALESMAN: { key: 'EDIT_SALESMAN', headerTitle: 'Edit Salesman', infoText:'edit a salesman' }
}

export const PACKING_STEPS = {
  FIRST: { step: 1 },
  SECOND: { step: 2 },
  FINISH: { step: 3}
}

export const SKU_TYPES = {
  BUNDLE: {key: 'bundle', display_name: 'Kit'},
  ASSEMBLE: {key: 'assemble', display_name: 'Assemble Kit'},
  STANDARD: {key: 'standard', display_name: 'SKU'}
}

export const FILTER_TYPE = {
  PASS: 'pass',
  FAIL: 'fail'
}

export const PACKAGE_TYPES = {
  CASE_PACK: {key: 'case_pack'},
  PALLET: {key: 'pallet'}
}

export const FULFILLMENT_STATUS = {
  PICKLIST_GENERATED: {key: 'Picklist Generated'}
}

export const RECIPIENT_OPTIONS = {
  RETURN_TO_SELLER:{label:'Return To Seller', value:'return_to_seller', key: 'seller'},
  RETURN_TO_SUPPLIER:{label:'Return To Supplier', value:'return_to_supplier', key:'supplier'},
  OTHER:{label:'Other', value:'other', key: 'other'}
}
export const PICKING_STATUS = {
  PICKING: {key: 'Picking'},
  PICKED: {key: 'Picked'}
}

const TODAY_DATE = new Date()
const FORMATTED_TODAY_DATE = format(TODAY_DATE, 'dd-MM-yyyy HH:mm')
export const ORDER_TRANSITION_DURATION_OPTIONS = [
  // {label: "All", value: "all"},
  {label: 'Last 6 hours', value: 'last_6_hours', range: {start: format(subHours(TODAY_DATE, 6), 'dd-MM-yyyy HH:mm'), end: FORMATTED_TODAY_DATE}},
  {label: 'Last 12 hours', value: 'last_12_hours', range: {start: format(subHours(TODAY_DATE, 12), 'dd-MM-yyyy HH:mm'), end: FORMATTED_TODAY_DATE}},
  {label: 'Last 18 hours', value: 'last_18_hours', range: {start: format(subHours(TODAY_DATE, 18), 'dd-MM-yyyy HH:mm'), end: FORMATTED_TODAY_DATE}},
  {label: 'Today', value: 'today', range: {start: format(TODAY_DATE, 'dd-MM-yyyy 00:00'), end: FORMATTED_TODAY_DATE}},
  {label: 'Yesterday', value: 'yesterday', range: {start: format(subDays(TODAY_DATE, 1), 'dd-MM-yyyy 00:00'), end: format(subDays(TODAY_DATE, 1), 'dd-MM-yyyy 23:59')}},
  {label: 'Last 3 days', value: 'last_3_days', range: {start: format(subDays(TODAY_DATE, 3), 'dd-MM-yyyy 00:00'), end: FORMATTED_TODAY_DATE}},
  {label: 'Last 7 days', value: 'last_7_days', range: {start: format(subDays(TODAY_DATE, 7), 'dd-MM-yyyy 00:00'), end: FORMATTED_TODAY_DATE}},
  {label: 'Last 15 days', value: 'last_15_days', range: {start: format(subDays(TODAY_DATE, 15), 'dd-MM-yyyy 00:00'), end: FORMATTED_TODAY_DATE}},
  {label: 'Last Month', value: 'last_month', range: {start: format(startOfMonth(subMonths(TODAY_DATE, 1)), 'dd-MM-yyyy 00:00'), end: format(endOfMonth(subMonths(TODAY_DATE, 1)), 'dd-MM-yyyy 23:59')}},
  {label: 'This Year', value: 'this_year', range: {start: format(startOfYear(TODAY_DATE), 'dd-MM-yyyy 00:00'), end: FORMATTED_TODAY_DATE}}
]

export const PAYMENT_STATUS = {
  ALL: {label: 'All', value: 'all'},
  PREPAID: {label: 'Prepaid', value: 'prepaid'},
  POSTPAID: {label: 'Postpaid', value: 'postpaid'},
  PENDING: {label: 'Pending', value: 'pending'}
}

export const PUSHER_EVENTS = {
  BULK_ORDER_APPROVE: 'approve-order'
}

export const CANCEL_ORDER_REASON = {
  OTHER:{key:'other'}
}

export const SHIPPING_PARTNER_TAG = {
  MANUAL: {label: 'Manual', value: 'manual'}
}

export const ATTACHMENTS_SIDEBAR_MODE = {
  ADD: {id: 'add', name: 'Add'},
  EDIT:{id: 'edit', name: 'Edit'},
  VIEW: {id: 'view', name: 'View'}
}

export const EDIT_ORDER_ENTITY_TYPES = {
  ORDER_ATTACHMENTS: 'order_attachments'
}

export const ORDER_OPERATIONAL_BUTTONS = {
  EDIT_ATTACHMENTS: {id: 'edit_attachments'},
}

export const ORDERS_BUTTONS = {
  CREATE_SHIPMENT: {
    KEY: 'create_shipment'
  }
}