import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import CustomToast from '@src/@core/components/ui/custom-toast/CustomToast'
import { API_ENDPOINTS, CUSTOMER_PREFIX } from '@src/api.urls'
import { useApi } from '@src/configs/react-query/useApi'
import { axiosInstance } from '@src/network/AxiosInstance'
import { bindToChannelEvent } from '@src/network/RealTime'
import { getApiUrl, paramsSerializer } from '@src/utility/Utils'
import { STO_STATUS } from '../StockTransferOrder/sto.constant'
import { LIVE_ORDERS_VIEW_OPTIONS, ORDER_FREE_TAGS } from '../sales.constant'

export const calculateCreateOrderTotalWeight = (items) => {
  return items.reduce((acc, item) => {
    if (item.isWeighted && !!item.skuWeight?.value) {
      if (item.skuWeight.uom === 'g') {
        const weightInKg = item.skuWeight.value / 1000
        return acc + weightInKg
      } else {
        return acc + (item.skuWeight.value)
      }
    } else {
      return acc + 0
    }
  }, 0)
}

const convertProductTypeData = (originalData) => {
  const convertedData = []

  // Iterate over each inner array in the original data
  originalData.forEach(item => {
    // Extract id and value from each inner array
    const id = item.find(obj => obj?.Key === 'id')?.Value
    const valueArray = item?.find(obj => obj?.Key === 'value')?.Value

    // Extract label and value from the 'value' array
    const label = valueArray?.find(obj => obj?.Key === 'label')?.Value
    const value = valueArray?.find(obj => obj?.Key === 'value')?.Value

    // Create a new object in the desired format
    if (id && label && value) {
      const convertedItem = {
        id: +id,
        value: {
          label,
          value
        }
      }

      // Push the converted item to the array
      convertedData.push(convertedItem)
    }
  })
  return convertedData
}

const getPrioritisedShippingPartnersResActionValueParser = (action_value) => {
  const modifiedData = {}
  action_value.forEach((ele) => {
    if (ele.Key === 'product_type' && ele.Value?.length) {
      modifiedData[ele.Key] = convertProductTypeData(ele.Value)
    } else {
      modifiedData[ele.Key] = ele.Value
    }
  })
  return modifiedData
}


// get All seller's

export const getAllSellers = createAsyncThunk(
  'orders/getAllSellers',
  async () => {
    const res = await axiosInstance.get('/api/v1/tenant/sellers', { params: { sort: 'name_asc', page: 1, per_page: 100, is_active: true } })
    let sellerData = res?.data?.data
    sellerData.unshift({ id: 'all', name: 'All' })
    sellerData = sellerData.map((seller) => ({
      value: seller,
      label: seller.name
    }))
    return sellerData
  }
)
// get orders by status
export const getOrdersByStatus = createAsyncThunk(
  'orders/ordersByStatus',
  async (allParams, store) => {
    const params = allParams?.currentTab === 'all' ? { ...allParams } : { ...allParams, hub_id: store.getState()?.auth?.selectedGlobalHubId }
    delete params?.currentTab
    const deliverySlotTime = params?.delivery_slot_time?.length ? `delivery_slot_time=${params.delivery_slot_time[0]}&delivery_slot_time=${params?.delivery_slot_time[1]}&` : ''
    delete params?.delivery_slot_time
    const response = await axiosInstance.get(
      `/api/v1/oms/orders?${deliverySlotTime}${paramsSerializer({ ...params })}`
    )
    return response?.data
  }
)

// sync order status 
export const syncOrderStatus = createAsyncThunk('synct-order-status', async (params) => {
  const response = await axiosInstance.put(`/api/v1/oms/orders/${params.order_id}/sync`)
  return response
})

//consolidation
export const getConsolidatedData = createAsyncThunk(
  'orders/ordersConsolidatedData',
  async ({ searchParams }, store) => {
    const hubId = store.getState()?.auth?.selectedGlobalHubId
    if (hubId) {
      const response = await axiosInstance.get(
        `/api/v1/wms/hubs/${hubId}/orders/consolidation`
      )
      const response2 = await axiosInstance.get(
        `/api/v1/wms/hubs/${hubId}/consolidation/orders/details`
      )
      return { data: response?.data?.data || [], searchParams, consolidationStatus: response2?.data?.data || {} }
    }
  }
)
// consolidation sidebar
export const getConsolidationSidebarData = createAsyncThunk(
  'orders/ordersConsolidtionSidebarData',
  async ({ warehouse_order_id }, store) => {
    const hubId = store.getState()?.auth?.selectedGlobalHubId
    if (hubId) {
      const response = await axiosInstance.get(
        `/api/v1/wms/hubs/${hubId}/orders/${warehouse_order_id}/consolidation/items`
      )
      return response?.data
    }
  }
)
//picking
export const getPickingData = createAsyncThunk(
  'orders/ordersPickingData',
  async (params, store) => {
    const hubId = store.getState()?.auth?.selectedGlobalHubId
    if (hubId) {
      const response = await axiosInstance.get(
        `/api/v1/wms/hubs/${hubId}/orders/picking`,
        { params }
      )
      return response?.data
    }
  }
)

//packing
export const getPackingData = createAsyncThunk(
  'orders/ordersPackingData',
  async (params) => {
    const deliverySlotTime = params?.delivery_slot_time?.length ? `delivery_slot_time=${params.delivery_slot_time[0]}&delivery_slot_time=${params?.delivery_slot_time[1]}&` : ''
    delete params?.delivery_slot_time
    const response = await axiosInstance.get(`/api/v1/oms/orders/pending?${deliverySlotTime}${paramsSerializer({ ...params })}`)
    return response?.data
  }
)

//get single order details
export const getSingleOrderDetails = createAsyncThunk(
  'sales/singleOrderDetails',
  async (id) => {
    const response = await axiosInstance.get(`/api/v1/oms/orders/${id}`)
    return response?.data
  }
)

//order logs
export const getOrderLogs = createAsyncThunk('sales/orderLogs', async (id) => {
  const response = await axiosInstance.get(
    `/api/v1/oms/orders/${id}/order_logs`
  )
  return response?.data
})

//approve, hold, cancel
export const updateOrderStatus = createAsyncThunk(
  'orders/updateOrderStatus',
  async ({ orderID, status }) => {
    const response = await axiosInstance.put(`/api/v1/oms/orders/${orderID}`, { status })
    return response?.data
  }
)

// all shipping partners
export const getAllShippingPartners = createAsyncThunk(
  'orders/allShippingPartner',
  async (seller_id, state) => {
    const allState = state.getState()
    const params = { seller_id: allState.auth.userData.tenant.type !== 'Self' ? seller_id ? seller_id : allState.sales.selectedSeller?.value?.id : allState.auth.globalSeller?.seller_id }
    const response = await axiosInstance.get(
      '/api/v1/shipping/shipping_partners?is_integrated=true', { params }
    )
    return response?.data
  }
)

// packing order details
export const getPackingOrderDetail = createAsyncThunk('orders/packingOrder/barcode', async ({ barcode }, store) => {
  const hubId = store.getState()?.auth?.selectedGlobalHubId
  if (hubId) {
    const response = await axiosInstance.get(`/api/v1/wms/hubs/${hubId}/order/packing/details`, { params: { barcode, in_packing: false } })
    return response?.data
  }
})

// in packing order details
export const getInPackingOrderDetail = createAsyncThunk(
  'orders/packingOrder',
  async ({ oms_order_id, in_packing, barcode }, store) => {
    const hubId = store.getState()?.auth?.selectedGlobalHubId
    if (hubId) {
      const response = await axiosInstance.get(
        `/api/v1/wms/hubs/${hubId}/order/packing/details`,
        { params: { oms_order_id, in_packing, barcode } }
      )
      return response?.data
    }
  }
)

export const getOrderDetailsInPacking = createAsyncThunk('order/details/in/packing', async (params, store) => {
  const { errorConfig, ...config } = params 
  const hubId = store.getState().auth.selectedGlobalHubId
  const url = getApiUrl(API_ENDPOINTS.ORDERS.GET_PACKING_ORDER_DETAILS, { hubId })
  const response = await axiosInstance.get(url, { params:config, _audioRequired: Boolean(errorConfig?.audioRequired) })
  return response?.data
})

// create packing
export const createPacking = createAsyncThunk(
  'createPacking',
  async ({ orderId, data }, store) => {
    const hubId = store.getState().auth.selectedGlobalHubId
    const response = await axiosInstance.post(
      `/api/v1/wms/hubs/${hubId}/order/packing/order/${orderId}`,
      data
    )
    return response?.data
  }
)

// generate picklist
export const generatePickList = createAsyncThunk('generatePickList', async (body, store) => {
  const hubId = store.getState()?.auth?.selectedGlobalHubId
  const response = await axiosInstance.put(`/api/v1/wms/hubs/${hubId}/picking/wave`, body)
  return response.data
})

// edit picklist
export const editPickList = createAsyncThunk('editPickList', async (body, store) => {
  const {waveId } = body 
  const hubId = store.getState()?.auth?.selectedGlobalHubId
  const response = await axiosInstance.put(`/api/v1/wms/hubs/${hubId}/picking/waves/${waveId}`, body)
  return response.data
})

export const getAllTags = createAsyncThunk('order/getAllTags', async () => {
  const res = await axiosInstance.get('/api/v1/oms/orders/tags')
  const tagsOptions = res.data.data.map(tag => ({ value: tag.id, label: tag.name }))
  return tagsOptions
})

//create shipment
export const createShipment = createAsyncThunk(
  'orders/createShipment',
  async (body) => {
    const { orderID, shipmentData } = body
    const response = await axiosInstance.post(
      `/api/v1/oms/orders/shipments/${orderID}`,
      shipmentData
    )
    return response?.data
  }
)

// cancel shipment
export const cancelShipment = createAsyncThunk(
  'orders/cancelShipment',
  async (body) => {
    const response = await axiosInstance.patch('/api/v1/oms/orders/shipments/cancel', body)
    return response?.data
  }
)

//print awb
export const printAWB = createAsyncThunk(
  'orders/printAWB',
  async (shipment_order_id) => {
    const response = await axiosInstance.get(
      `/api/v1/oms/orders/shipments/${shipment_order_id}/awb`
    )
    return response?.data
  }
)
export const getAWBDetail = async (order_id) => {
  try {
    const response = await axiosInstance.get(
      `/api/v1/oms/orders/${order_id}/awb`
    )
    return response?.data
  } catch (err) {
    return err
  }
}

// packing reasons
export const getPackingReasons = createAsyncThunk(
  'orders/packingReasons',
  async () => {
    const response = await axiosInstance.get(
      '/api/v1/wms/reasons?reason_type=packing'
    )
    return response?.data
  }
)
export const getNotPickedReasons = createAsyncThunk(
  'orders/not-picked-reasons',
  async () => {
    const response = await axiosInstance.get(
      '/api/v1/wms/reasons?reason_type=picking_in_packing'
    )
    return response?.data
  }
)

// create order
export const createOrder = createAsyncThunk('create/order', async (body) => {
  const response = await axiosInstance.post('/api/v1/oms/orders', body)
  return response
})

// seller skus
export const getSellerSkus = createAsyncThunk(
  'orders/getSellerSkus',
  async ({ seller_id, sku_name, ...rest  }) => {
    const res = await axiosInstance.get(
      `/api/v1/products/sellers/${seller_id}/skus`,
      { params: { query: sku_name, ...rest  } }
    )
    return res?.data
  }
)

// seller skus for create oreder
export const getSellerSkusForCreateOrder = createAsyncThunk(
  'orders/getSellerSkusForCreateOrder',
  async ({ seller_id, sku_name, hubID, ...rest }) => {
    const  params = { hub_id: hubID, search_value: sku_name, ...rest }
    const res = await axiosInstance.get(
      `/api/v1/products/sellers/${seller_id}/order_skus?${paramsSerializer({ ...params })}` 
    )
    return res?.data
  }
)

export const loadMoreSellerSkusForCreateOrder = createAsyncThunk(
  'orders/load-more',
  async ({ seller_id, sku_name, hubID, ...rest }) => {
    const res = await axiosInstance.get(
      `/api/v1/products/sellers/${seller_id}/order_skus`,
      { params: { hub_id: hubID, query: sku_name, ...rest } }
    )
    return res?.data
  }
)

// Picking Wave 

//ongoing wave table

export const getPickingWaveData = createAsyncThunk('get/pickingWave', async (params, store) => {
  const hubId = store.getState()?.auth?.selectedGlobalHubId
  const response = await axiosInstance.get(`/api/v1/wms/hubs/${hubId}/picking/waves`, { params })
  return response
})

//Picking Wave configuration
export const getPickingConfiguration = createAsyncThunk('get/pickingWaveConfiguration', async (_params, store) => {
  const hubId = store.getState()?.auth?.selectedGlobalHubId
  const response = await axiosInstance.get(`/api/v1/wms/hubs/${hubId}/picking/waves/details`)
  return response
})

// get all Picklists of Status
export const getPicklistsOfStatus = createAsyncThunk('sales/get/picklist/wave/status',
  async (props, store) => {
    const { type, waveId, hub_id } = props
    const hubId = hub_id || store.getState()?.auth?.selectedGlobalHubId
    const response = await axiosInstance.get(`/api/v1/wms/hubs/${hubId}/waves/${waveId}/picklists/details`, { params: { type, zone_id: props?.zone } })
    return response.data
  })

// get all Picklists in Zone
export const getPicklistsInZone = createAsyncThunk('sales/get/picklist/wave/zone',
  async ({ waveId, type, zoneId }, store) => {
    const hubId = store.getState()?.auth?.selectedGlobalHubId
    const response = await axiosInstance.get(`/api/v1/wms/hubs/${hubId}/waves/${waveId}/zones/${zoneId}/picklists`, { params: { type } })
    return response.data
  })

// get all Picklists of Status
export const getItemDetailsOfPicklist = createAsyncThunk('get/items/picklist/wave/details',
  async ({ picklist_id, hub_id }, store) => {
    const hubId = hub_id || store.getState()?.auth?.selectedGlobalHubId
    const response = await axiosInstance.get(`/api/v1/wms/hubs/${hubId}/picking/${picklist_id}/picklist_details`)
    return response.data
  })

export const createBulkOrder = createAsyncThunk(
  'create/bulkOrder',
  async (body) => {
    const response = await axiosInstance.post('/api/v1/oms/orders/bulk', body)
    return response
  })

export const getLocationInventoryForPacking = createAsyncThunk('packing/available-sku', async ({ seller_sku_id }, store) => {
  const hubId = store.getState()?.auth?.selectedGlobalHubId
  if (hubId) {
    const res = await axiosInstance.get(`/api/v1/wms/hubs/${hubId}/location_inventory/picking`, { params: { seller_sku_id } })
    return {
      data: res
    }
  }
})

export const createPicking = createAsyncThunk('packing/picking', async ({ orderId, data }, store) => {
  const hubId = store.getState()?.auth?.selectedGlobalHubId
  if (hubId) {
    const res = await axiosInstance.put(`/api/v1/wms/hubs/${hubId}/order/packing/order/${orderId}/pick_order_item`, data)
    return res?.data
  }
})

//shipping details
export const getShippingDetails = createAsyncThunk('shipment/shippingDetails', async ({ params }) => {
  const res = await axiosInstance.get('/api/v1/oms/orders/shipments/details', { params })
  return res?.data
})

export const getPickingLocationSkuBarcode = createAsyncThunk('packing/picking_location', async (sku_barcode, store) => {
  const hubId = store.getState()?.auth?.selectedGlobalHubId
  if (hubId) {
    const res = await axiosInstance.get(`/api/v1/wms//hubs/${hubId}/location_inventory/serialised_skus`, { params: { sku_barcode } })
    return res?.data
  }
})

// create sto order
export const createStoOrder = createAsyncThunk('create/sto-order', async (body) => {
  const response = await axiosInstance.post('/api/v1/oms/orders/sto', body)
  return response
})

export const createBulkStoOrder = createAsyncThunk('create/bulk/sto-order', async (body) => {
  const response = await axiosInstance.post('/api/v1/oms/orders/sto/bulk', body)
  return response.data
})


export const getStoDetail = createAsyncThunk('orders/get-Sto-detail', async (order_id) => {
  const stoDetailResponse = await axiosInstance.get(`/api/v1/oms/orders/sto/${order_id}`)
  return stoDetailResponse.data
})


// STO-REQUEST
export const getStoRequestDetail = createAsyncThunk('orders/get-Sto-request-detail', async ({ params, order_id }) => {
  const stoDetailResponse = await axiosInstance.get(`/api/v1/oms/orders/sto/requests/${order_id}`, { params })
  return stoDetailResponse.data
})

export const approveStoRequest = createAsyncThunk('orders/approve-sto-request', async ({ order_id, body }) => {
  const approveStoRequestResponse = await axiosInstance.put(`/api/v1/oms/orders/sto/requests/${order_id}/approve`, body)
  return approveStoRequestResponse.data
})
export const cancelStoRequest = createAsyncThunk('order/cancel-sto-request', async ({ orderID, body }) => {
  const res = await axiosInstance.put(`/api/v1/oms/orders/sto/requests/${orderID}/reject`, body)
  return res?.data
})
export const updateStoRequest = createAsyncThunk('order/update-sto-request', async ({ orderID, body }) => {
  const res = await axiosInstance.put(`/api/v1/oms/orders/sto/requests/${orderID}`, body)
  return res?.data
})
export const addNewSkuInStoRequest = createAsyncThunk('order/add-new-sku-sto-request', async ({ orderID, body }) => {
  const res = await axiosInstance.put(`/api/v1/oms/orders/sto/requests/${orderID}`, body)
  return res?.data
})
export const getAllStoData = createAsyncThunk('orders/get-all-Sto-data', async (params) => {
  const statusMap = {
    cancelled: STO_STATUS.CANCELED.key,
    accepted: STO_STATUS.ACCEEPTED.key,
    'pending-request': STO_STATUS.PENDING_REQUEST.key
  }
  params = { ...params, status: statusMap[params.status] }

  const allStoDataResponse = await axiosInstance.get('/api/v1/oms/orders/sto/requests/', { params })
  return allStoDataResponse.data
})
export const createBulkStoRequest = createAsyncThunk('create/bulk/sto-request', async (body) => {
  const response = await axiosInstance.post('/api/v1/oms/orders/sto/requests/bulk', body)
  return response.data
})
export const createStoRequest = createAsyncThunk('create/sto-request', async (body) => {
  const response = await axiosInstance.post('/api/v1/oms/orders/sto/requests', body)
  return response
})

//shipping details

export const cancelOrderInBulk = createAsyncThunk('order/cancelOderInBulk', async (orderIds) => {
  const res = await axiosInstance.put('/api/v1/oms/orders/cancel', {
    order_ids: orderIds
  })
  return res.data
})

export const approveOrderInBulk = createAsyncThunk('order/approveOderInBulk', async (orderIds) => {
  const res = await axiosInstance.put('/api/v1/oms/orders/approve_bulk', {
    order_ids: orderIds
  })
  return res.data
})

export const changeOrderStatus = createAsyncThunk('order/status', async (body) => {
  const res = await axiosInstance.put('/api/v1/oms/orders/status', body)
  return res?.data
})

// manifest 
//create manifest thunk
export const createManifestOrder = createAsyncThunk('create/manifest-order', async (body, store) => {
  const response = await axiosInstance.post('/api/v1/oms/manifests', { ...body, hub_id: store.getState().auth.selectedGlobalHubId })
  return response.data.data
})
// complete manifest
export const completeManifest = createAsyncThunk('complete/manifest-order', async ({ body, id }, store) => {
  const hub_id = store.getState().auth.selectedGlobalHubId 
  const response = await axiosInstance.put(`/api/v1/oms/manifests/${id}`, {...body, hub_id})
  return response.data
})
// draft manifest
export const draftManifest = createAsyncThunk('draft/manifest-order', async ({ body, id }, store) => {
  const hub_id = store.getState().auth.selectedGlobalHubId 
  const response = await axiosInstance.put(`/api/v1/oms/manifests/${id}`, {...body, hub_id})
  return response.data
})
// handover manifest
export const handOverManifest = createAsyncThunk('handover/manifest-order', async ({ body, id }, store) => {
  const hub_id = store.getState().auth.selectedGlobalHubId 
  const response = await axiosInstance.put(`/api/v1/oms/manifests/${id}`, {...body, hub_id})
  return response.data
})
// add order in manifest
export const addOrderInManifestWithAwb = createAsyncThunk('create/add-item-in-manifest', async ({ body, id }, store) => {
  const {errorConfig, ...config} = body
  const hub_id = store.getState().auth.selectedGlobalHubId 
  const response = await axiosInstance.put(`/api/v1/oms/manifests/${id}`, {...config, hub_id}, {_audioRequired: Boolean(errorConfig?.audioRequired)})
  return response.data.data
})
// removeManifestOrder
export const removeManifestOrder = createAsyncThunk('remove/remove-item-in-manifest', async ({ body, id }, store) => {
  const hub_id = store.getState().auth.selectedGlobalHubId 
  const response = await axiosInstance.put(`/api/v1/oms/manifests/${id}`, {...body, hub_id})
  return response.data
})
// getAllManifestOrder
export const getAllManifestOrder = createAsyncThunk('manifest/get-all-manifest-orders', async ({ params }, store) => {
  const allParams = { ...params, hub_id: store.getState().auth.selectedGlobalHubId }
  const allManifestOrdersResponse = await axiosInstance.get('/api/v1/oms/manifests', { params: allParams })
  return allManifestOrdersResponse.data
})
// cancelManifest
export const cancelManifest = createAsyncThunk('cancel/draft-manifest-order', async ({ body, id }, store) => {
  const hub_id = store.getState().auth.selectedGlobalHubId 
  const response = await axiosInstance.put(`/api/v1/oms/manifests/${id}`, {...body, hub_id})
  return response.data
})
// manifest ends here 
export const getAllZones = createAsyncThunk('sales/getAllZones', async (hubId) => {
  const res = await axiosInstance.get(`/api/v1/wms/hubs/${hubId}/zones`)
  const mappedData = res.data.data?.map((zone) => ({
    value: zone,
    label: zone.name
  }))
  return mappedData
})
// getForwardShipment
export const getShipments = createAsyncThunk('/order/shipment/get-forward-shipment', async ({ params }, store) => {
  const hub_id = store.getState()?.auth?.selectedGlobalHubId
  const response = await axiosInstance.get(`/api/v1/oms/orders/shipments?${paramsSerializer({ ...params, hub_id })}`)
  return response.data
})
// updateShipmentStatus
export const updateShipmentStatus = createAsyncThunk('/order/shipment/updateShipmentStatus', async ({ body }) => {
  const response = await axiosInstance.put('/api/v1/oms/orders/shipments', body)
  return response.data
})
// createBulkReturnOrder
export const createBulkReturnOrder = createAsyncThunk('/order/shipment/create-bulk-return-order', async ({ body }) => {
  const response = await axiosInstance.post('/api/v1/oms/returns/orders/bulk', body)
  return response.data
})

export const syncPendingOrderWithSalesChannel = createAsyncThunk('sales/pending-order/sync-order-with-sales-channel', async ({ seller_sales_channel_order_id, sales_channel_id, seller_sales_channel_id }) => {
  const params = { sales_channel_id }
  const res = await axiosInstance.get(`/api/v1/sales_channels/seller_sales_channels/${seller_sales_channel_id}/orders/${seller_sales_channel_order_id}`, { params })
  return res.data
})

export const addNewVirtualHub = createAsyncThunk('add/new-virtual-hub', async (body) => {
  const response = await axiosInstance.post('/api/v1/oms/hubs', body)
  return response.data
})
export const addNewPickupLocation = createAsyncThunk('add/new-pickup-location', async (body) => {
  const response = await axiosInstance.post('/api/v1/oms/pickup_locations', body)
  return response.data
})
export const editPickupLocation = createAsyncThunk('add/edit-pickup-location', async ({body, hub_code}) => {
  const response = await axiosInstance.put(`/api/v1/oms/pickup_locations/${hub_code}`, body)
  return response.data
})
export const getCreateShipmentDetails = createAsyncThunk('get/create-shipment-details', async ({ params }) => {
  const response = await axiosInstance.get('/api/v1/oms/orders/shipments/details', { params })
  return response.data 
})

export const getCustomerAddresses = createAsyncThunk('/order/customer-addresses', async ({ params }) => {
  const sort = JSON.stringify([{ field: 'default', order: 'desc' }])
  params = { ...params, sort }
  const response = await axiosInstance.get(`${CUSTOMER_PREFIX}/addresses?`, { params })
  return response.data
})

export const getCustomerDetail = createAsyncThunk('/order/customer-search', async ({ seller_id, ...params }) => {
  // const dispatch = store.dispatch
  const response = await axiosInstance.get(`${CUSTOMER_PREFIX}/sellers/${seller_id}/customers`, { params })
  // const data = response?.data?.data
  // if (data?.length > 0) {
  //   const params = {entity_id: data[0].id, entity_name: 'customer' }
  //         dispatch(getCustomerAddresses({params}))
  //   }
  return response.data
})
export const createCustomer = createAsyncThunk('/order/create-customer', async ({ seller_id, body }) => {
  const response = await axiosInstance.post(`${CUSTOMER_PREFIX}/sellers/${seller_id}/customers`, body)
  return response.data
})
export const getCustomerAddress = createAsyncThunk('/order/customer-address', async ({ address_id }) => {
  const response = await axiosInstance.get(`${CUSTOMER_PREFIX}/addresses/${address_id}`)
  return response.data
})

export const createAddress = createAsyncThunk('/order/create-address', async ({ body }) => {
  const response = await axiosInstance.post(`${CUSTOMER_PREFIX}/addresses`, body)
  return response.data
})
export const editAddress = createAsyncThunk('/order/edit-address', async ({ address_id, body }) => {
  const response = await axiosInstance.put(`${CUSTOMER_PREFIX}/addresses/${address_id}`, body)
  return response.data
})

// This api is used to edit customer, shipping details and order items
export const editCustomerAndShippingDetails = createAsyncThunk('/order/edit-customer-and-shipping-details', async ({ order_id, body }) => {
  const response = await axiosInstance.put(`/api/v1/oms/orders/${order_id}/edit`, body)
  return response.data
})

export const editCustomer = createAsyncThunk('/order/edit-customer', async ({seller_id, customer_id, body}) => {
  const response = await axiosInstance.put(`${CUSTOMER_PREFIX}/sellers/${seller_id}/customers/${customer_id}`, body)
  return response.data
})
export const getShippingDetailsForPacking = createAsyncThunk('new/packing/shippingDetails', async (params) => {
  const res = await axiosInstance.get('/api/v1/oms/orders/shipments/details', { params })
  return res?.data
})

export const getCurrencyOptions = async (search, _loadedOptions, { page }) => {
  try {
    const param = {
      params: { currency: search, page}
    }
    const response = await axiosInstance.get(`/api/v1/tenant/countries?${paramsSerializer({...param.params})}`)

    const options = response.data?.data?.map((ele) => ({
      value: ele.currency?.code,
      label: ele.currency?.code,
      ...ele.currency
    }))

    return {
      options,
      hasMore: page < response.data?.meta?.last_page,
      additional: { page: page + 1 }
    }
  } catch (error) {
    console.warn(error)
    return {
      options: [],
      hasMore: false,
      additional: { page }
    }
  }
}

export const getWmsOrderDetails = createAsyncThunk('wms/order/details/data', async ({params, hubId}) => {
  const url = getApiUrl(API_ENDPOINTS.ORDERS.GET_WMS_ORDER_DETAILS, {hubId})
  const response = await axiosInstance.get(url, {params})
  return response.data
})

export const getBatchesForSKUCode = createAsyncThunk('/order/create-order/select-custom-batch', async ({sellerId, sellerSkuId }, store) => {
  const hubId = store.getState()?.auth?.selectedGlobalHubId
  const response = await axiosInstance.get(`/api/v1/wms/hubs/${hubId}/sellers/${sellerId}/skus/${sellerSkuId}/batches/inventory`)
  return response.data
})
export const createPallet = createAsyncThunk('/packing/create-pallet', async ({warehouse_order_id}, store) => {
  const hubId = store.getState().auth.selectedGlobalHubId
  const response = await axiosInstance.post(`/api/v1/wms/hubs/${hubId}/pallets`, {warehouse_order_id})
  return response.data
})
export const getPallet = createAsyncThunk('/packing/get-pallet', async (params, store) => {
  const hubId = store.getState().auth.selectedGlobalHubId
  const response = await axiosInstance.post(`/api/v1/wms/hubs/${hubId}/pallet/scan`, { ...params })
  return response.data
})

export const createBox = createAsyncThunk('/packing/create-box', async ({box_id, pallet_id, warehouse_order_id}, store) => {
  const hubId = store.getState().auth.selectedGlobalHubId
  const response = await axiosInstance.post(`/api/v1/wms/hubs/${hubId}/cartons`, {box_id, warehouse_order_id, pallet_id})
  return response.data
})

// upload customers in bulk 
export const bulkCustomersUpload = createAsyncThunk('order/bulk/customers/upload',
  async ({ sellerId, body }) => {
    const res = await axiosInstance.post(`${CUSTOMER_PREFIX}/sellers/${sellerId}/customers/bulk`, body)
    return res.data
  }
)

export const getCreateSplitOrderDetails = createAsyncThunk('get/create-split-order-details',
  async ({order_id}) => {
    const res = await axiosInstance.get(`/api/v1/oms/orders/${order_id}/check_split`)
    return res.data
  }
)

export const getAllSplittedOrders = createAsyncThunk('get/all/splitted-orders',
  async (params) => {
    const res = await axiosInstance.get('/api/v1/oms/split_orders', {params})
    return res.data
  }
)

export const getSingleSplittedOrder = createAsyncThunk('get/single/splitted-order',
  async ({order_id}) => {
    const res = await axiosInstance.get(`/api/v1/oms/split_orders/${order_id}`)
    return res.data
  }
)

export const createSplitOrder = createAsyncThunk('create/split-order',
  async ({body}) => {
    const res = await axiosInstance.post('/api/v1/oms/split_orders', body)
    return res.data
  }
)

export const updateSplitOrder = createAsyncThunk('update/splitted-order',
  async ({order_id, body}) => {
    const res = await axiosInstance.put(`/api/v1/oms/split_orders/${order_id}`, body)
    return res.data
  }
)

export const getPackingListForPrint = createAsyncThunk('packing/packing-list', async ({ oms_order_id, hubId }, store) => {
  const hub_id = hubId || store.getState().auth.selectedGlobalHubId
  const response = await axiosInstance.get(`/api/v1/wms/hubs/${hub_id}/order/packing/list`, { params: {oms_order_id}})
  return response.data
})

export const getSplitOrderMetaData = createAsyncThunk('get/splitted-order/meta-data',
  async () => {
    const res = await axiosInstance.get('/api/v1/oms/split_orders/metadata')
    return res.data
  }
)
export const updateReceiveQuantityForSto = createAsyncThunk('sto/receive-quantity', async (body, store) => {
  const hubId = store.getState().auth.selectedGlobalHubId
  const response = await axiosInstance.put(`/api/v1/wms/hubs/${hubId}/stock_transfer_order`, body)
  return response.data
})

// const fakeApiCall = async () => {
//   // Simulating an API call delay
//   await new Promise((resolve) => setTimeout(resolve, 1000))

//   // Simulating a successful response
//   return { data: 'Mocked Api Response' }

// }

export const markStoAsDelivered = createAsyncThunk('sto/mark-as-delivered', async ({ id }) => {

  const res = await axiosInstance.put(`/api/v1/oms/orders/sto/requests/${id}/delivered`)
  return res
})

export const syncOrderPaymentStatus = createAsyncThunk('order/payment-status/sync', async ({ seller_sales_channel_order_id, seller_id, seller_sales_channel_id }) => {
  const res = await axiosInstance.post(`/api/v1/sales_channels/sellers/${seller_id}/seller_sales_channels/${seller_sales_channel_id}/order/${seller_sales_channel_order_id}/update_payment_status`)
  return res.data
})

export const putOrderOnHold = createAsyncThunk('order/put-on-hold', async ({order_id, body}) => {
  const res = await axiosInstance.put(`/api/v1/oms/orders/${order_id}/put_on_hold`, body)
  return res.data
})

//create shipment order shipment
export const createShipmentOrderShipment = createAsyncThunk(
  'shipment_order/create/shipment',
  async (body) => {
    const { orderID, shipmentData } = body
    const response = await axiosInstance.post(
      `/api/v1/oms/shipment_orders/${orderID}/shipments`,
      shipmentData
    )
    return response?.data
  }
)

export const getShippingPartnersAccounts = createAsyncThunk('/get/shipment-order/shipping-partners', async ({ body }) => {
  const response = await axiosInstance.put('/api/v1/oms/orders/shipments/shipping_accounts', body)
  return response.data
})

export const createShipmentOrder = createAsyncThunk('/create/shipment-order', async (body) => {
  const response = await axiosInstance.post('/api/v1/oms/shipment_orders', body)
  return response
})

export const getAWBDetailForPrint = createAsyncThunk('get/print-awb-details', async ({params}) => {
  const response = await axiosInstance.get('/api/v1/oms/orders/shipments/awb', {params})
  return response
})

export const fetchAWB = async ({params}) => {
  try {
    const response = await axiosInstance.get(
      '/api/v1/oms/orders/shipments/awb', {params}
    )
    return response?.data
  } catch (err) {
    return err
  }
}

export const editShipmentOrderDetails = createAsyncThunk('edit/shipment-order', async ({ shipment_order_id, body }) => {
  const response = await axiosInstance.put(`/api/v1/oms/shipment_orders/${shipment_order_id}/edit`, body)
  return response.data
})

export const putOrdersOnHoldInBulk = createAsyncThunk('bulk/order/put-on-hold', async ({body}) => {
  const res = await axiosInstance.put('/api/v1/oms/orders/put_on_hold', body)
  return res.data
})

export const getHubsDetailsForShipmentOrder = createAsyncThunk('get/hub-details-for-shipment-order', async ({page, per_page, query}) => {
  const response = await axiosInstance.get('/api/v1/oms/hubs/all', {  params: { search_column: 'name', search_query: query, page, per_page }})
  return response?.data
})
export const getPickupLocation = createAsyncThunk('get/pickup-location', async ({page, per_page, query}) => {
  const response = await axiosInstance.get('/api/v1/oms/pickup_locations', {  params: {search_query: query, page, per_page }})
  return response?.data
})

//order logs
export const getShipmentOrderLogs = createAsyncThunk('sales/shipment-order-logs', async (params) => {
  const response = await axiosInstance.get(
    '/api/v1/oms/logs', {params}
  )
  return response?.data
})

export const cancelShipmentOrder = createAsyncThunk('cancel/shipment-order', async ({ shipment_order_id, body }) => {
  const response = await axiosInstance.put(`/api/v1/oms/shipment_orders/${shipment_order_id}/cancel`, body)
  return response.data
})

export const createBulkShipmentOrder = createAsyncThunk('create/shipment-bulk-order', async (body) => {
  const response = await axiosInstance.post('/api/v1/oms/shipment_orders/bulk', body)
  return response
})

// get Sto logs
export const getStoOrderLogs = createAsyncThunk('stocks/stock-order-logs', async ({hub_id, sto_id}) => {
  const res = await axiosInstance.get(`/api/v1/wms/hubs/${hub_id}/stock_transfer_order/${sto_id}/logs`)
  return res.data
})

// get seller skus with sku inventory in the hub
export const getSellerSkusWithInventory = createAsyncThunk('orders/get-seller-skus-with-inventory', async ({ seller_id, sku_name, hubId, ...rest  }) => {
  const res = await axiosInstance.get(`/api/v1/products/sellers/${seller_id}/skus/hub/${hubId}/hub_inventories`, { params: { query: sku_name, ...rest  } })
  return res?.data
}
)

// get All salesmen
export const getSalesmen = createAsyncThunk('orders/salesmen', async ({ hubId  , ...rest }) => {

  const res = await axiosInstance.get(`/api/v1/pos/hubs/${hubId}/sales_persons`, { params: { ...rest } })
  return res?.data
}
)

// create Salesman
export const createSalesman = createAsyncThunk('orders/create/salesmen', async ({ hubId ,body}) => {

  const res = await axiosInstance.post(`/api/v1/pos/hubs/${hubId}/sales_persons`,body)  
  return res?.data
}
)
// edit Salesman
export const editSalesman = createAsyncThunk('orders/edit/salesmen', async ({ hubId ,salesmanId,body}) => {
 
  const res = await axiosInstance.put(`/api/v1/pos/hubs/${hubId}/sales_persons/${salesmanId}`, body)  
  return res?.data
}
)

export const getSalesmanAddress = createAsyncThunk('orders/salesmenaddress', async ({ hub_id, sales_person_id }) => {

  const res = await axiosInstance.get(`/api/v1/pos/hubs/${hub_id}/sales_persons/${sales_person_id}`)  
  return res?.data
})


const initialState = {
  loading: {},
  success: {},
  error: {},
  ordersData: null,
  singleOrderDetailsData: null,
  sellerID: null,
  handleOrderDetailsSideSlider: false,
  countryID: null,
  packingOrderDetails: null,
  shipmentDetails: null,
  shipmentCancelled: null,
  orderTableMeta: {},
  packingTableMeta: {},
  packingData: null,
  // consolidation
  consolidatedData: null,
  consolidatedSelectedRowData: null,
  consolidationSidebarData: null,
  consolidationMeta: null,
  consolidationStatusesData: null,
  //
  // selectedSeller
  sellers: null,
  selectedSeller: { label: 'All', value: { id: 'all' } },
  // selectedOrderTag
  selectedOrderTag: { label: 'All', value: 'all' },
  // 
  pickingData: null,
  clearUpdatedOrderDetails: null,
  awbData: null,
  packingCreated: false,
  orderIdForCreatingShipment: null,
  unPackedItemReason: null,
  allShippingPartners: null,
  sellerSkuList: [],
  sellerSkusForCreateOrder: [],
  sellerSkusForCreateOrderMeta: {},
  orderLogs: null,
  createOrder: null,
  createOrderItems: {},
  picklistLoading: false,
  picklistState: false,
  totalCost: 0,
  createdOrderTotalWeight: 0,
  uploadedFiles: null,
  picklistsOfStatus: {loading: false, error: false, success: false, data: null},
  picklistsInZone: null,
  picklistDetails: null,
  pickingWaveData: null,
  pickingConfigurationData: null,
  availableInventoryForPacking: null,
  pickingDetail: null,
  shippingDetailsData: null,
  approveLoading: false,
  approveState: null,
  // STO states
  allStoData: null,
  stoDetail: null,
  stoMetaData: {},
  stoRequestMetaData: {},
  uploadedStoFiles: null,
  // STO states ends here
  isOrderCanceled: false,
  isStoRequestApproved: false,
  pickingLocation: null,
  isOrderStatusChanged: false,
  allOrderTags: null,
  // manifest state
  singleDraftManifest: null,
  isManifestCreated: false,
  isManifestDraftCreated: false,
  isManifestOrderRemoved: false,
  allManifestOrders: null,
  manifestOrdersMeta: null,
  isDraftManifestCancelled: false,
  isManifestReadyForHandOver: false,
  isManifestHandovered: false,
  // manifest state ends here
  timeFrom: null,
  timeTo: null,
  completedWaveState: false,
  // shipments state
  shipments: null,
  zonesOptions: null,
  selectedZone: null,
  selectedPicklistStatus: null,
  selectedShippintPartnerFilterValue: null,
  // sto-request
  isStoRequestUpdate: false,
  isVirtualHubCreated: false,
  virtualHubCreatedResponse: {},
  isStoRequestCanceled: false,
  isStoRequestCreated: false,
  createShipmentDetails: null,
  isNewSkuAddedInSTO: false,
  customerDetail: null,
  customerAddresses: null,
  customerAddress: null,
  customerData: null,
  invoiceData: null,
  getPrioritisedShippingPartnersRes: { data: null, loading: false, is_success: false },
  customerMetaData:{},
  customerAddressesMetaData: {},
  

  //new packing
  packingConfiguration: {
    apiLoading: {},
    apiError: {},
    apiSuccess: {},
    packingCreated:{},
    orderDetailsInPackingData: {},
    shippingDetails:{},

    skuBatchDetails: {}

  },

  //wms order-details
  wmsOrderDetailsData:{},
  pallet: null,
  getPalletData: null,
  carton: null,
  customerBulkUploadFile: null,
  uploadBulkCustomesResponse: {loading: false, error: false, success: false, data: null},
  notPickedReasons: null,
  singleSplitOrderDetails:null,
  allSplittedOrdersData:null,
  allSplittedOrdersMetaData:null,
  packingListForPrint: null,
  splitOrderMetaData:null,
  markStoAsDeliveredResponse: null,
  awbDataForPrint: null,
  hubsDetailsForShipmentOrder: [],
  hubsMetaDataForShipmentOrder: null,
  pickupLocationData: [],
  pickupLocationMeta: null,
  shipmentOrderLogs: null,
  editPicklist: { loading: false, error: false, success: false, data: null },
  stoLogs: null,
  cleanUpStoDelivery: null,
  orderView:LIVE_ORDERS_VIEW_OPTIONS.TIMELINE,
  preFilledWeight:null,
  preFilledNumberOfPackages: null,

  salesPersons: {},
  salesmanMetaData: {},
  salesmanAddress: null,
}

const salesSlice = createSlice({
  name: 'sales',
  initialState,
  reducers: {
    alreadyAddedCreateOrderItems: (state, action) => {
      state.createdOrderTotalWeight = calculateCreateOrderTotalWeight(Object.values(action.payload))
      state.createOrderItems = {...action.payload}
    },
    addCreateOrderItems: (state, action) => {
      const addedOrderItems = { ...state.createOrderItems,
        [action.payload.uniqueItemId]: {
          ...action.payload,
          quantity: 1,
          expiry_status: null
        }
      }
      if(action.payload.isWeighted){
        state.createdOrderTotalWeight = calculateCreateOrderTotalWeight(Object.values(addedOrderItems))
      }
      state.createOrderItems = addedOrderItems
    },
    removeCreateOrderItemFromIndex: (state, action) => {
      const createOrderItemsObject = { ...state.createOrderItems }
      if(createOrderItemsObject[action.payload].isWeighted){
        delete createOrderItemsObject[action.payload]
        state.createdOrderTotalWeight = calculateCreateOrderTotalWeight(Object.values(createOrderItemsObject))
      }else{
        delete createOrderItemsObject[action.payload]
        if(Object.values(createOrderItemsObject).length === 0 ){
          state.createdOrderTotalWeight = 0
        }
      }
      state.createOrderItems = createOrderItemsObject
    },
    updateQtyOfCreateOrderItemAtIndex: (state, action) => {
      const createOrderItemsObject = { ...state.createOrderItems }
      createOrderItemsObject[action.payload.uniqueItemId].quantity = parseInt(
        action.payload?.value
      )
      let totalCost = 0
      Object.values(createOrderItemsObject).forEach(createOrderItems => {
        const { cutoff_price, quantity } = createOrderItems
        totalCost += cutoff_price * 1.0 * quantity
      })
      state.totalCost = totalCost
      state.createOrderItems = createOrderItemsObject
    },
    updateUnitPriceOfCreateOrderItem: (state, action) => {
      const createOrderItemsObject = { ...state.createOrderItems }
      const taxAmount = +((action.payload.value * action.payload.taxPercentage) / 100).toFixed(2)
      createOrderItemsObject[action.payload.uniqueItemId].cutoff_price = action.payload.value
      createOrderItemsObject[action.payload.uniqueItemId].tax_amount = taxAmount
      let totalCost = 0
      Object.values(createOrderItemsObject).forEach(createOrderItems => {
        const { cutoff_price, quantity, tax_percentage } = createOrderItems
        const taxValue = +((tax_percentage * cutoff_price) / 100).toFixed(2)
        totalCost += (cutoff_price + taxValue) * quantity
      })
      state.totalCost = totalCost
      state.createOrderItems = createOrderItemsObject
    },
    updateTaxPercentOfCreateOrderItem: (state, action) => {
      const createOrderItemsObject = { ...state.createOrderItems }
      const taxPercent = action.payload.value <= 100 ? action.payload.value : 100
      const taxAmount = +((action.payload.unitPrice * taxPercent) / 100).toFixed(2)
      createOrderItemsObject[action.payload.uniqueItemId].tax_percentage = taxPercent
      createOrderItemsObject[action.payload.uniqueItemId].tax_amount = taxAmount
      let totalCost = 0
      Object.values(createOrderItemsObject).forEach(createOrderItems => {
        const { cutoff_price, quantity, tax_percentage } = createOrderItems
        const taxValue = +((tax_percentage * cutoff_price) / 100).toFixed(2)
        totalCost += (cutoff_price + taxValue) * quantity
      })
      state.totalCost = totalCost
      state.createOrderItems = createOrderItemsObject
    },
    applyExpiryStatus: (state, action) => {
      state.createOrderItems[action.payload.uniqueItemId].expiry_status = action.payload?.value
    },
    updateCreateOrderItemWiseWeightValue: (state, action) => {
      const {uniqueItemId, value} = action.payload
      const createOrderItemsObject = { ...state.createOrderItems }
      createOrderItemsObject[uniqueItemId].skuWeight.value = value
      state.createdOrderTotalWeight = calculateCreateOrderTotalWeight(Object.values(createOrderItemsObject))
      state.createOrderItems = createOrderItemsObject
    },
    updateCreateOrderItemWiseNoteValue: (state, action) => {
      const {uniqueItemId, value} = action.payload
      const createOrderItemsObject = { ...state.createOrderItems }
      createOrderItemsObject[uniqueItemId].customer_note = value
      state.createOrderItems = createOrderItemsObject
    },
    updateCreatedOrderTotalWeight: (state, action) => {
      state.createdOrderTotalWeight = action.payload
    },
    clearSellerSkuList: (state) => {
      state.sellerSkuList = []
    },
    clearSellerSkusForCreateOrder: (state) => {
      state.sellerSkusForCreateOrder = []
    },
    handleOrderDetailsSideSlider: (state, action) => {
      state.handleOrderDetailsSideSlider = action.payload
    },
    getSellerID: (state, action) => {
      state.sellerID = action.payload
    },
    clearOrdersData: (state) => {
      state.ordersData = null
    },
    clearPickingData: (state) => {
      state.pickingData = null
    },
    clearConsolidationData: (state) => {
      state.consolidatedData = null
    },
    clearSingleOrderDetailsData: (state) => {
      state.singleOrderDetailsData = null
    },
    clearPackingData: (state) => {
      state.packingData = null
    },
    clearPackingOrderDetails: (state) => {
      state.packingOrderDetails = null
      state.packingCreated = false
    },
    getOrderIdForCreatingShipment: (state, action) => {
      state.orderIdForCreatingShipment = action.payload
    },
    clearOrderLogs: (state) => {
      state.orderLogs = null
    },
    clearCreateOrderItems: (state) => {
      state.createOrderItems = {}
    },
    updateCreateOrderStatus: (state) => {
      state.isOrderCreated = false
    },
    setUploadFilesInStore: (state, action) => {
      state.uploadedFiles = {
        ...state.uploadedFiles,
        ...action.payload
      }
    },
    cleanupGetPicklistsOfStatus: (state) => {
      state.picklistsOfStatus = {loading: false, error: false, success: false, data: null}
    },
    cleanupGetPicklistsInZone: (state) => {
      state.picklistsInZone = null
    },
    cleanupPicklistDetails: (state) => {
      state.picklistDetails = null
    },
    clearPickingDetail: state => {
      state.pickingDetail = null
    },
    setConsolidatedSelectedRowData: (state, action) => {
      state.consolidatedSelectedRowData = action.payload
    },
    setConsolidationMeta: (state, action) => {
      state.consolidationMeta = {
        ...state.consolidationMeta,
        ...action.payload
      }
    },
    cleanupAvailableInventoryForPacking: (state) => {
      state.availableInventoryForPacking = null
    },
    // set seller 
    selectedSellerDispatch: (state, action) => {
      state.selectedSeller = action.payload
    },
    selectedOrderTagDispatch: (state, action) => {
      state.selectedOrderTag = action.payload
    },
    clearShippingDetailData: (state) => {
      state.shippingDetailsData = null
    },
    clearShipmentDetailData: (state) => {
      state.shipmentDetails = null
    },
    resetCancelOrder: state => {
      state.isOrderCanceled = false
    },
    resetApproveStoRequest: state => {
      state.isStoRequestApproved = false
    },
    resetCancelStoRequest: state => {
      state.isStoRequestCanceled = false
    },
    resetChangedOrderStatus: state => {
      state.isOrderStatusChanged = false
    },
    resetSerilisedPickingLocationDetail: state => {
      state.pickingLocation = null
    },
    setUploadStoFilesInStore: (state, action) => {
      state.uploadedStoFiles = {
        ...state.uploadedStoFiles,
        ...action.payload
      }
    },
    clearUploadStoFileFromStore: state => {
      state.uploadedStoFiles = {}
    },
    resetUpdateStoRequest: state => {
      state.isStoRequestUpdate = false
    },
    resetAddNewSkuInStoRequest: state => {
      state.isNewSkuAddedInSTO = false
    },
    // manifest state clean up function
    resetManifestStates: (state, action) => {
      if (action.payload === 'isManifestOrderRemoved') {
        state.isManifestOrderRemoved = false
      } else if (action.payload === 'clearDataAndMeta') {
        state.allManifestOrders = null
        state.manifestOrdersMeta = null
      } else if (action.payload === 'isManifestDraftCreated') {
        state.isManifestDraftCreated = false
      } else {
        state.singleDraftManifest = null
        state.isManifestCreated = false
        state.isManifestDraftCreated = false
        state.isDraftManifestCancelled = false
        state.isManifestReadyForHandOver = false
        state.isManifestHandovered = false
      }
    },
    setManifestStatusAsCreated: (state, action) => {
      if (action.payload.status === 'canCelledOrCompleted') {
        state.isManifestCreated = true
        state.singleDraftManifest = action.payload.data
      } else {
        state.singleDraftManifest = action.payload.data
      }
    },
    setDeliverySlotFromTime: (state, action) => {
      state.timeFrom = action.payload
    },
    setDeliverySlotToTime: (state, action) => {
      state.timeTo = action.payload
    },
    SetcompletedWaveState: (state, action) => {
      state.completedWaveState = action.payload
    },
    resetShippingOptionsData: (state) => {
      state.allShippingPartners = null
    },
    resetSuccess: (state) => {
      state.success = {}
    },
    setSuccess: (state, action) => {
      state.success = {
        ...state.success,
        ...action.payload
      }
    },
    cleanupPicklistSidebarFilterData: (state) => {
      state.zonesOptions = null
      state.selectedZone = null
      state.selectedPicklistStatus = null
    },
    setSelectedZone: (state, action) => {
      state.selectedZone = action.payload
    },
    setPicklistStatus: (state, action) => {
      state.selectedPicklistStatus = action.payload
    },
    setShippingPartnerFilterValue: (state, action) => {
      state.selectedShippintPartnerFilterValue = action.payload
    },
    clearStoDetail: (state) => {
      state.stoDetail = null
    },
    resetVirtualHubCreated: state => {
      state.isVirtualHubCreated = false
    },
    resetCreateStoRequest: (state) => {
      state.isStoRequestCreated = false
    },
    clearCustomerAddress: (state) => {
      state.customerAddress = null
      // state.loading = {}
      state.success = {}
    },
    clearCustomerData: (state) => {
      state.customerDetail = null
      state.customerAddresses = null
    },
    clearInvoiceData: (state) => {
      state.invoiceData = null
    },
    clearGetPrioritisedShippingPartners: state => {
      state.getPrioritisedShippingPartnersRes = { data: null, loading: false, is_success: false }
    },
    clearAllStoData: (state) => {
      state.allStoData = null
    },
    updatepackingOrderDetails: (state, action) => {
      state.packingConfiguration.orderDetailsInPackingData = action.payload
    },
    cleanupSalesData: (state, action) => {
      const { key } = action.payload
      state[key] = null
    },
    clearSpecificPackingConfiguration: (state, action) => {
      // for (const key in action.payload) {
      //   state.packingConfiguration = {
      //     ...state.packingConfiguration,
      //     [key]: {}
      //   }
      // }
      const configurationToReset = {}
      for (const key in action.payload) {
        configurationToReset[key] = {}
      }
      state.packingConfiguration = {...state.packingConfiguration, ...configurationToReset} 
    },
    setPicklistState: (state, action) => {
      state.picklistState = action.payload
    },
    clearCustomerList: (state) => {
      state.customerDetail = null
      state.customerMetaData = {}
    },
    clearCustomerAddresses: (state) => {
      state.customerAddresses = null
      state.customerAddressesMetaData = {}
    },
    resetError: (state) => {
      state.error = {}
    },
    clearWmsOrderDetailsData: (state) => {
      state.wmsOrderDetailsData = {}
    },
    setCustomerUploadedFile: (state, action) => {
      state.customerBulkUploadFile = { ...action.payload }
    },
    cleanupCustomerUploadedFile: (state) => {
      state.customerBulkUploadFile = null
    },
    cleanupCustomerBulkResponse: (state) => {
      state.uploadBulkCustomesResponse = {loading: false, errors: false, success: false, data: null}
    },
    clearSingleSplitOrderDetails: (state) => {
      state.singleSplitOrderDetails = null
    },
    clearPalletData: state => {
      state.pallet = null
      state.getPalletData = null
    },
    clearCartonData: state => {
      state.carton = null
    },
    clearPackingListForPrint: state => {
      state.packingListForPrint = null
    },
    clearSingleSalesState: (state, action) => {
      const {key} = action.payload
      state[key] = null
    },
    clearCreateShipmentDetails: state => {
      state.createShipmentDetails = null
    },
    clearAwbDataForPrint: state => {
      state.awbDataForPrint = null
    },
    clearHubsDetailsForShipmentOrder: state => {
      state.hubsDetailsForShipmentOrder = []
      state.hubsMetaDataForShipmentOrder = null
    },
    clearShipmentOrderLogs: state => {
      state.shipmentOrderLogs = null
    },
    clearEditOrderWave: state => {
      state.editPicklist = {loading: false, error: false, success: false, data: null}
    },
    cleanUpStoDelivery: (state) => {
      state.loading = { ...state.loading, markStoAsDelivered: false }
      state.success = { ...state.success, markStoAsDelivered: false }
      state.error = { ...state.success, markStoAsDelivered: false }
      state.markStoAsDeliveredResponse = null
    },
    updateOrderView: (state,action) => {
      state.orderView = action.payload
    },
    updatePrefilledWeight: (state, action) => {
      state.preFilledWeight = action.payload
    },
    updatePrefilledNumberOfPackages: (state, action) => {
      state.preFilledNumberOfPackages = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllSellers.pending, (state) => {
        state.loading = { ...state.loading, getAllSellers: true }
      })
      .addCase(getAllSellers.fulfilled, (state, action) => {
        state.sellers = action.payload
        state.loading = { ...state.loading, getAllSellers: false }
        state.success = { ...state.success, getAllSellers: true }
        state.error = { ...state.error, getAllSellers: false }
      })
      .addCase(getAllSellers.rejected, (state) => {
        state.loading = { ...state.loading, getAllSellers: false }
        state.success = { ...state.success, getAllSellers: false }
        state.error = { ...state.error, getAllSellers: true }
      })
      .addCase(getOrdersByStatus.pending, (state) => {
        state.loading = { ...state.loading, getOrdersByStatus: true }
      })
      .addCase(getOrdersByStatus.fulfilled, (state, action) => {
        state.loading = { ...state.loading, getOrdersByStatus: false }
        state.success = { ...state.success, getOrdersByStatus: true }
        state.error = { ...state.error, getOrdersByStatus: false }
        state.ordersData = action.payload?.data
        state.orderTableMeta = action.payload?.meta
        state.tabTotal1 = action.payload?.meta?.total
      })
      .addCase(getOrdersByStatus.rejected, (state) => {
        state.loading = { ...state.loading, getOrdersByStatus: false }
        state.error = { ...state.error, getOrdersByStatus: true }
        state.success = { ...state.success, getOrdersByStatus: false }
      })
      .addCase(getPackingData.pending, (state) => {
        state.loading = { ...state.loading, getPackingData: true }
        state.success = { ...state.loading, getPackingData: false }
        state.error = { ...state.loading, getPackingData: false }
        state.packingData = null
        state.packingTableMeta = {}
      })
      .addCase(getPackingData.fulfilled, (state, action) => {
        state.loading = { ...state.loading, getPackingData: false }
        state.success = { ...state.success, getPackingData: true }
        state.error = { ...state.error, getPackingData: false }
        state.packingData = action.payload?.data
        state.packingTableMeta = action.payload?.meta
      })
      .addCase(getPackingData.rejected, (state) => {
        state.loading = { ...state.loading, getPackingData: false }
        state.error = { ...state.error, getPackingData: true }
        state.success = { ...state.success, getPackingData: false }
        state.packingData = null
        state.packingTableMeta = {}
      })

      .addCase(getPickingData.pending, (state) => {
        state.loading = { ...state.loading, getPickingData: true }
        // state.error = false
      })
      .addCase(getPickingData.fulfilled, (state, action) => {
        state.loading = { ...state.loading, getPickingData: false }
        state.success = { ...state.success, getPickingData: true }
        state.error = { ...state.error, getPickingData: false }
        state.pickingData = action.payload
      })
      .addCase(getPickingData.rejected, (state) => {
        state.loading = { ...state.loading, getPickingData: false }
        state.success = { ...state.success, getPickingData: false }
        state.error = { ...state.error, getPickingData: true }
        state.pickingData = { data: [], meta: {} }
      })
      // conolidation
      .addCase(getConsolidatedData.pending, (state) => {
        state.loading = { ...state.loading, getConsolidatedData: true }
      })
      .addCase(getConsolidatedData.fulfilled, (state, action) => {
        state.loading = { ...state.loading, getConsolidatedData: false }
        state.success = { ...state.success, getConsolidatedData: true }
        state.error = { ...state.error, getConsolidatedData: false }
        state.consolidatedData = { data: action.payload?.data }
        state.consolidationStatusesData = action.payload?.consolidationStatus
        const per_page = action.payload?.searchParams.get('per_page') || 20
        const total = action.payload?.data?.length
        const last_page = Math.abs(Math.ceil(total / per_page))
        const current_page =
          Number(action.payload?.searchParams.get('page')) || 1
        const start = (current_page - 1) * per_page
        const end = start + per_page
        state.consolidationMeta = {
          current_page,
          last_page,
          per_page,
          start,
          end,
          total
        }
      })
      .addCase(getConsolidatedData.rejected, (state) => {
        state.loading = { ...state.loading, getConsolidatedData: false }
        state.success = { ...state.success, getConsolidatedData: false }
        state.error = { ...state.error, getConsolidatedData: true }
        state.consolidatedData = { data: [] }
        state.consolidationStatusesData = {}
      })
      // consolidationSidebar
      .addCase(getConsolidationSidebarData.pending, (state) => {
        state.loading = { ...state.loading, getConsolidationSidebarData: true }
      })
      .addCase(getConsolidationSidebarData.fulfilled, (state, action) => {
        state.loading = { ...state.loading, getConsolidationSidebarData: false }
        state.error = { ...state.error, getConsolidationSidebarData: false }
        state.success = { ...state.success, getConsolidationSidebarData: true }
        state.consolidationSidebarData = action.payload?.data
      })
      .addCase(getConsolidationSidebarData.rejected, (state) => {
        state.loading = { ...state.loading, getConsolidationSidebarData: false }
        state.error = { ...state.error, getConsolidationSidebarData: true }
        state.success = { ...state.success, getConsolidationSidebarData: false }
      })
      //
      .addCase(getSingleOrderDetails.pending, (state) => {
        state.loading = { ...state.loading, getSingleOrderDetails: true }
        state.error = { ...state.error, getSingleOrderDetails: false }
        state.success = { ...state.success, getSingleOrderDetails: false }
      })
      .addCase(getSingleOrderDetails.fulfilled, (state, action) => {
        state.loading = { ...state.loading, getSingleOrderDetails: false }
        state.error = { ...state.error, getSingleOrderDetails: false }
        state.success = { ...state.success, getSingleOrderDetails: true }
        state.singleOrderDetailsData = action.payload
      })
      .addCase(getSingleOrderDetails.rejected, (state) => {
        state.loading = { ...state.loading, getSingleOrderDetails: false }
        state.error = { ...state.error, getSingleOrderDetails: true }
        state.success = { ...state.success, getSingleOrderDetails: false }
      })
      .addCase(getOrderLogs.pending, (state) => {
        state.loading = { ...state.loading, getOrderLogs: true }
      })
      .addCase(getOrderLogs.fulfilled, (state, action) => {
        state.loading = { ...state.loading, getOrderLogs: false }
        state.error = { ...state.error, getOrderLogs: false }
        state.success = { ...state.success, getOrderLogs: true }
        state.orderLogs = action.payload?.data
      })
      .addCase(getOrderLogs.rejected, (state) => {
        state.loading = { ...state.loading, getOrderLogs: false }
        state.error = { ...state.error, getOrderLogs: true }
        state.success = { ...state.success, getOrderLogs: false }
      })
      .addCase(getAllShippingPartners.pending, (state) => {
        state.loading = { ...state.loading, getAllShippingPartners: true }
      })
      .addCase(getAllShippingPartners.fulfilled, (state, action) => {
        state.loading = { ...state.loading, getAllShippingPartners: false }
        state.success = { ...state.success, getAllShippingPartners: true }
        state.error = { ...state.error, getAllShippingPartners: false }
        state.allShippingPartners = action.payload
      })
      .addCase(getAllShippingPartners.rejected, (state) => {
        state.loading = { ...state.loading, getAllShippingPartners: false }
        state.success = { ...state.success, getAllShippingPartners: false }
        state.error = { ...state.error, getAllShippingPartners: true }
      })
      .addCase(getPackingOrderDetail.fulfilled, (state, action) => {
        state.loading = { ...state.loading, getPackingOrderDetail: false }
        if (action?.payload?.status_code === 200) {
          state.packingOrderDetails = action.payload.data
          const wmsOrderId = action.payload.data.warehouse_order_id
          if (wmsOrderId) {
            const eventName = `order_cancel_${wmsOrderId}`
            bindToChannelEvent(eventName)
          }
        }
      })
      .addCase(getPackingOrderDetail.pending, (state) => {
        state.loading = { ...state.loading, getPackingOrderDetail: true }
      })
      .addCase(getPackingOrderDetail.rejected, (state) => {
        state.loading = { ...state.loading, getPackingOrderDetail: false }
      })
      .addCase(getInPackingOrderDetail.pending, (state, action) => {
        state.loading = { ...state.loading, getInPackingOrderDetail: { value: true, id: action.meta.arg.oms_order_id } }
      })
      .addCase(getInPackingOrderDetail.fulfilled, (state, action) => {
        // action.payload.data.warehouse_order_items = sampleData
        state.packingOrderDetails = action.payload?.data
        state.loading = { ...state.loading, getInPackingOrderDetail: { value: false, id: action.meta.arg.oms_order_id } }
      })
      .addCase(getInPackingOrderDetail.rejected, (state, action) => {
        state.loading = { ...state.loading, getInPackingOrderDetail: { value: false, id: action.meta.arg.oms_order_id } }
      })
      .addCase(createShipment.pending, state => {
        state.loading = { ...state.loading, createShipment: true }
      })
      .addCase(createShipment.rejected, state => {
        state.loading = { ...state.loading, createShipment: false }
        state.success = { ...state.success, createShipment: false }
        state.error = { ...state.error, createShipment: true }
      })
      .addCase(createShipment.fulfilled, (state, action) => {
        state.loading = { ...state.loading, createShipment: false }
        state.success = { ...state.success, createShipment: true }
        state.error = { ...state.error, createShipment: false }
        if (action.payload.status_code === 200) {
          state.shipmentDetails = action.payload
          CustomToast('Shipment created successfully', { my_type: 'success' })
        }
      })
      //  createShipment with sales channel ends here
      .addCase(cancelShipment.pending, (state) => {
        state.loading = { ...state.loading, cancelShipment: true }
      })
      .addCase(cancelShipment.fulfilled, (state, action) => {
        state.loading = { ...state.loading, cancelShipment: false }
        state.shipmentCancelled = action.payload
        if (action?.payload?.status_code === 200) {
          state.success = { ...state.success, cancelShipment: true }
          CustomToast('Shipment cancelled successfully', {
            my_type: 'success'
          })
        }
      })
      .addCase(cancelShipment.rejected, (state) => {
        state.loading = { ...state.loading, cancelShipment: false }
      })
      .addCase(createPacking.pending, (state) => {
        state.packingConfiguration.packingCreated = { ...state.packingConfiguration.packingCreated, createPacking: false }
        state.packingConfiguration.packingCreated = { ...state.packingConfiguration.packingCreated, loading: true }
      })
      .addCase(createPacking.fulfilled, (state, action) => {
        if (action.payload?.status_code === 200) {
          state.packingCreated = true
          state.packingConfiguration.packingCreated = { ...state.packingConfiguration.packingCreated, createPacking: true }
          state.packingConfiguration.packingCreated = { ...state.packingConfiguration.packingCreated, loading: false }
          state.packingConfiguration.packedItemsResponse = action.payload
        }
      })
      .addCase(createPacking.rejected, (state) => {
        state.packingConfiguration.packingCreated = { ...state.packingConfiguration.packingCreated, createPacking: false }
        state.packingConfiguration.packingCreated = { ...state.packingConfiguration.packingCreated, loading: false }
        state.error = { ...state.error, createPacking: true }
      })
      .addCase(printAWB.fulfilled, (state, action) => {
        state.awbData = action.payload
      })

      .addCase(getPackingReasons.pending, (state) => {
        state.loading = { ...state.loading, getPackingReasons: true }
      })

      .addCase(getPackingReasons.fulfilled, (state, action) => {
        state.loading = { ...state.loading, getPackingReasons: false }
        state.success = { ...state.success, getPackingReasons: true }
        state.unPackedItemReason = action.payload?.data?.map((item) => ({
          value: item.id,
          label: item.title
        }))
        state.error = { ...state.error, getPackingReasons: false }
      })

      .addCase(getPackingReasons.rejected, (state) => {
        state.loading = { ...state.loading, getPackingReasons: false }
        state.success = { ...state.success, getPackingReasons: false }
        state.error = { ...state.error, getPackingReasons: true }
      })
      .addCase(getNotPickedReasons.pending, (state) => {
        state.loading = { ...state.loading, getNotPickedReasons: true }
      })

      .addCase(getNotPickedReasons.fulfilled, (state, action) => {
        state.loading = { ...state.loading, getNotPickedReasons: false }
        state.success = { ...state.success, getNotPickedReasons: true }
        state.notPickedReasons = action.payload?.data?.map((item) => ({
          value: item.id,
          label: item.title
        }))
        state.error = { ...state.error, getNotPickedReasons: false }
      })

      .addCase(getNotPickedReasons.rejected, (state) => {
        state.loading = { ...state.loading, getNotPickedReasons: false }
        state.success = { ...state.success, getNotPickedReasons: false }
        state.error = { ...state.error, getNotPickedReasons: true }
      })

      .addCase(createOrder.pending, (state) => {
        state.loading = { ...state.loading, createOrder: true }
        state.success = { ...state.success, createOrder: false }
        state.error = { ...state.error, createOrder: false }
      })
      .addCase(createOrder.fulfilled, (state, action) => {

        if (action?.meta?.requestStatus === 'fulfilled') {
          state.isStoRequestCreated = true
          CustomToast('Order created successfully', { my_type: 'success' })
        }
        state.loading = { ...state.loading, createOrder: false }
        state.success = { ...state.success, createOrder: true }
        state.error = { ...state.error, createOrder: false }
      })
      .addCase(createOrder.rejected, (state) => {
        state.loading = { ...state.loading, createOrder: false }
        state.success = { ...state.success, createOrder: false }
        state.error = { ...state.error, createOrder: true }
      })
      .addCase(getSellerSkus.pending, (state) => {
        state.loading = { ...state.loading, getSellerSkus: true }
      })
      .addCase(getSellerSkus.fulfilled, (state, action) => {
        state.loading = { ...state.loading, getSellerSkus: false }
        state.sellerSkuList = action.payload?.data
      })

      .addCase(getSellerSkus.rejected, (state) => {
        state.loading = { ...state.loading, getSellerSkus: false }
      })

      .addCase(getSellerSkusForCreateOrder.pending, (state) => {
        state.loading = { ...state.loading, getSellerSkusForCreateOrder: true }
      })

      .addCase(getSellerSkusForCreateOrder.fulfilled, (state, action) => {
        state.sellerSkusForCreateOrder = action.payload?.data
        state.sellerSkusForCreateOrderMeta = action.payload?.meta
        state.loading = { ...state.loading, getSellerSkusForCreateOrder: false }
      })

      .addCase(getSellerSkusForCreateOrder.rejected, (state) => {
        state.loading = { ...state.loading, getSellerSkusForCreateOrder: false }
      })

      .addCase(loadMoreSellerSkusForCreateOrder.pending, (state) => {
        state.loading = { ...state.loading, loadMoreSellerSkusForCreateOrder: true }
      })

      .addCase(loadMoreSellerSkusForCreateOrder.fulfilled, (state, action) => {
        state.sellerSkusForCreateOrder = [...state.sellerSkusForCreateOrder, ...(action.payload?.data || {})]
        state.sellerSkusForCreateOrderMeta = action.payload?.meta
        state.loading = { ...state.loading, loadMoreSellerSkusForCreateOrder: false }
      })

      .addCase(loadMoreSellerSkusForCreateOrder.rejected, (state) => {
        state.loading = { ...state.loading, loadMoreSellerSkusForCreateOrder: false }
      })

      .addCase(editPickList.pending, (state) => {
        state.editPicklist = { loading: true, error: false, success: false, data: null }
      })
      .addCase(editPickList.fulfilled, (state, action) => {
        state.editPicklist = { loading: false, error: false, success: true, data: action.payload }
      })
      .addCase(editPickList.rejected, (state) => {
        state.editPicklist = { loading: false, error: true, success: false, data: null }
      })
      .addCase(generatePickList.pending, (state) => {
        state.picklistLoading = true
        state.picklistState = false
      })
      .addCase(generatePickList.fulfilled, (state, action) => {
        if (action?.payload?.status_code === 200) {
          state.picklistState = true
          const eventName = action.payload.data.event
          if (eventName) {
            bindToChannelEvent(eventName)
          }
        }
        state.picklistLoading = false
      })
      .addCase(generatePickList.rejected, (state) => {
        state.picklistLoading = false
        state.picklistState = false
      })
      .addCase(createBulkOrder.pending, (state) => {
        state.loading = { ...state.loading, createBulkOrder: true }
      })
      .addCase(createBulkOrder.fulfilled, (state, action) => {
        CustomToast(action.payload.data.data.message, { my_type: 'success' })
        const eventName = action.payload.data.data.pusher_response.event
        bindToChannelEvent(eventName)
        state.uploadedFiles = null
        state.loading = { ...state.loading, createBulkOrder: false }
      })
      .addCase(createBulkOrder.rejected, (state) => {
        state.loading = { ...state.loading, createBulkOrder: false }
      })
      .addCase(getPicklistsOfStatus.pending, (state) => {
        state.picklistsOfStatus = { loading: true, error: false, success: false }
      })
      .addCase(getPicklistsOfStatus.fulfilled, (state, action) => {
        state.picklistsOfStatus = { loading: false, error: false, success: true, data: action.payload }
      })
      .addCase(getPicklistsOfStatus.rejected, (state) => {
        state.picklistsOfStatus = { error: true, loading: false, success: false }
      })
      .addCase(getPicklistsInZone.fulfilled, (state, action) => {
        state.picklistsInZone = action.payload
      })
      .addCase(getPicklistsInZone.pending, (state) => {
        state.picklistsInZone = { loading: true }
      })
      .addCase(getItemDetailsOfPicklist.fulfilled, (state, action) => {
        state.picklistDetails = action.payload
      })
      .addCase(getItemDetailsOfPicklist.pending, (state) => {
        state.picklistDetails = { loading: true }
      })
      .addCase(getItemDetailsOfPicklist.rejected, (state) => {
        state.picklistDetails = { loading: false, error: true }
      })
      .addCase(getPickingWaveData.pending, (state) => {
        state.loading = { ...state.loading, getPickingWaveData: true }
      })
      .addCase(getPickingWaveData.fulfilled, (state, action) => {
        state.loading = { ...state.loading, getPickingWaveData: false }
        state.success = { ...state.success, getPickingWaveData: true }
        state.error = { ...state.error, getPickingWaveData: false }
        state.pickingWaveData = action.payload
      })
      .addCase(getPickingWaveData.rejected, (state) => {
        state.loading = { ...state.loading, getPickingWaveData: false }
        state.success = { ...state.success, getPickingWaveData: false }
        state.error = { ...state.error, getPickingWaveData: true }
      })
      .addCase(getPickingConfiguration.pending, (state) => {
        state.loading = { ...state.loading, getPickingConfiguration: true }
      })
      .addCase(getPickingConfiguration.fulfilled, (state, action) => {
        state.loading = { ...state.loading, getPickingConfiguration: false }
        state.success = { ...state.success, getPickingConfiguration: true }
        state.error = { ...state.error, getPickingConfiguration: false }
        state.pickingConfigurationData = action.payload
      })
      .addCase(getPickingConfiguration.rejected, (state) => {
        state.loading = { ...state.loading, getPickingConfiguration: false }
        state.success = { ...state.success, getPickingConfiguration: false }
        state.error = { ...state.error, getPickingConfiguration: true }
      })
      .addCase(getLocationInventoryForPacking.pending, (state) => {
        state.loading = { ...state.loading, getLocationInventoryForPacking: true }
      })
      .addCase(getLocationInventoryForPacking.fulfilled, (state, action) => {
        state.loading = { ...state.loading, getLocationInventoryForPacking: false }
        state.success = { ...state.success, getLocationInventoryForPacking: true }
        state.error = { ...state.error, getLocationInventoryForPacking: false }
        state.availableInventoryForPacking = action.payload.data
      })
      .addCase(getLocationInventoryForPacking.rejected, (state) => {
        state.loading = { ...state.loading, getLocationInventoryForPacking: false }
        state.success = { ...state.success, getLocationInventoryForPacking: false }
        state.error = { ...state.error, getLocationInventoryForPacking: true }
      })
      .addCase(createPicking.pending, state => {
        state.loading = { ...state.loading, createPicking: true }
        state.success = { ...state.success, createPicking: false }
        state.error = { ...state.error, createPicking: false }
      })
      .addCase(createPicking.fulfilled, (state, action) => {
        state.loading = { ...state.loading, createPicking: false }
        state.success = { ...state.success, createPicking: true }
        state.error = { ...state.error, createPicking: false }
        state.pickingDetail = action.payload?.data
        CustomToast('Picked quantity updated successfully', { my_type: 'success' })
      })
      .addCase(createPicking.rejected, state => {
        state.loading = { ...state.loading, createPicking: false }
        state.success = { ...state.success, createPicking: false }
        state.error = { ...state.error, createPicking: true }
      })
      .addCase(getShippingDetails.pending, state => {
        state.loading = { ...state.loading, getShippingDetails: true }
      })
      .addCase(getShippingDetails.fulfilled, (state, action) => {
        state.loading = { ...state.loading, getShippingDetails: false }
        state.shippingDetailsData = action.payload?.data
      })
      .addCase(getShippingDetails.rejected, state => {
        state.loading = { ...state.loading, getShippingDetails: false }
      })
      .addCase(updateOrderStatus.pending, state => {
        state.loading = { ...state.loading, updateOrderStatus: true }
        state.success = { ...state.success, updateOrderStatus: false }
        state.error = { ...state.error, updateOrderStatus: false }
      })
      .addCase(updateOrderStatus.fulfilled, (state, action) => {
        state.loading = { ...state.loading, updateOrderStatus: false }
        state.success = { ...state.success, updateOrderStatus: true }
        CustomToast(action.payload.data?.message, { my_type: 'success' })
      })
      .addCase(updateOrderStatus.rejected, state => {
        state.loading = { ...state.loading, updateOrderStatus: false }
        state.error = { ...state.error, updateOrderStatus: true }
      })
      // sto response
      // sto response
      .addCase(createStoOrder.pending, (state) => {
        state.loading = { ...state.loading, createStoOrder: true }
      })
      .addCase(createStoOrder.fulfilled, (state, action) => {
        state.loading = { ...state.loading, createStoOrder: false }
        if (action?.meta?.requestStatus === 'fulfilled') {
          state.isOrderCreated = true
          CustomToast('Order created successfully', { my_type: 'success' })
        }
      })
      .addCase(createStoOrder.rejected, (state) => {
        state.loading = { ...state.loading, createStoOrder: false }
      })
      // createBulkStoOrder
      .addCase(createBulkStoOrder.pending, (state) => {
        state.loading = { ...state.loading, createBulkStoOrder: true }
      })
      .addCase(createBulkStoOrder.fulfilled, (state, action) => {
        state.loading = { ...state.loading, createBulkStoOrder: false }
        state.isOrderCreated = true
        state.uploadedStoFiles = null
        CustomToast('Your request has been accepted. You will receive a confirmation shortly.', { my_type: 'success' })
        const eventName = action.payload.data.event
        bindToChannelEvent(eventName)
      })
      .addCase(createBulkStoOrder.rejected, (state) => {
        state.loading = { ...state.loading, createBulkStoOrder: false }
      })
      // 
      .addCase(getAllStoData.pending, (state) => {
        state.loading = { ...state.loading, getAllStoData: true }
      })
      .addCase(getAllStoData.fulfilled, (state, action) => {
        state.allStoData = action.payload.data
        state.stoMetaData = action.payload.meta
        state.isStoRequestApproved = false
        state.loading = { ...state.loading, getAllStoData: false }
        state.success = { ...state.success, getAllStoData: true }
        state.error = { ...state.error, getAllStoData: false }
      })
      .addCase(getAllStoData.rejected, (state) => {
        state.loading = { ...state.loading, getAllStoData: false }
        state.success = { ...state.success, getAllStoData: false }
        state.error = { ...state.error, getAllStoData: true }
      })
      .addCase(getStoDetail.pending, (state) => {
        state.loading = { ...state.loading, getStoDetail: true }
      })
      .addCase(getStoDetail.fulfilled, (state, action) => {
        state.loading = { ...state.loading, getStoDetail: false }
        state.stoDetail = action.payload.data
      })
      .addCase(getStoDetail.rejected, (state) => {
        state.loading = { ...state.loading, getStoDetail: false }
      })
      .addCase(approveStoRequest.pending, (state) => {
        state.loading = { ...state.loading, approveStoRequest: true }
      })
      .addCase(approveStoRequest.fulfilled, (state) => {
        state.loading = { ...state.loading, approveStoRequest: false }
        state.isStoRequestApproved = true
        CustomToast('Request approved successfully.', { my_type: 'success' })
      })
      .addCase(approveStoRequest.rejected, (state) => {
        state.loading = { ...state.loading, approveStoRequest: false }
      })
      .addCase(cancelStoRequest.pending, state => {
        state.loading = { ...state.loading, cancelStoRequest: true }
      })
      .addCase(cancelStoRequest.fulfilled, (state, action) => {
        state.loading = { ...state.loading, cancelStoRequest: false }
        if (action?.payload?.status_code === 200) {
          state.isStoRequestCanceled = true
          CustomToast('Request cancel successfully', { my_type: 'success' })
        }
      })
      .addCase(cancelStoRequest.rejected, state => {
        state.loading = { ...state.loading, cancelStoRequest: false }
      })
      .addCase(updateStoRequest.pending, state => {
        state.loading = { ...state.loading, updateStoRequest: true }
      })
      .addCase(updateStoRequest.fulfilled, (state, action) => {
        state.loading = { ...state.loading, updateStoRequest: false }
        if (action?.payload?.status_code === 200) {
          state.isStoRequestUpdate = true
          CustomToast('Request updated successfully', { my_type: 'success' })
        }
      })
      .addCase(updateStoRequest.rejected, state => {
        state.loading = { ...state.loading, updateStoRequest: false }
      })
      .addCase(addNewSkuInStoRequest.pending, state => {
        state.loading = { ...state.loading, addNewSkuInStoRequest: true }
      })
      .addCase(addNewSkuInStoRequest.fulfilled, (state, action) => {
        state.loading = { ...state.loading, addNewSkuInStoRequest: false }
        if (action?.payload?.status_code === 200) {
          state.isNewSkuAddedInSTO = true
          CustomToast('New Item added successfully', { my_type: 'success' })
        }
      })
      .addCase(addNewSkuInStoRequest.rejected, state => {
        state.loading = { ...state.loading, addNewSkuInStoRequest: false }
      })
      // sto response ends here
      .addCase(getPickingLocationSkuBarcode.pending, state => {
        state.loading = { ...state.loading, getPickingLocation: true }
      })
      .addCase(getPickingLocationSkuBarcode.fulfilled, (state, action) => {
        state.loading = { ...state.loading, getPickingLocation: false }
        state.success = { ...state.success, getPickingLocation: true }
        state.error = { ...state.error, getPickingLocation: false }
        state.pickingLocation = action?.payload?.data
      })
      .addCase(getPickingLocationSkuBarcode.rejected, state => {
        state.loading = { ...state.loading, getPickingLocation: false }
        state.success = { ...state.success, getPickingLocation: false }
        state.error = { ...state.error, getPickingLocation: true }
      })
      .addCase(cancelOrderInBulk.pending, state => {
        state.loading = { ...state.loading, cancelOrderInBulk: true }
        state.success = { ...state.success, cancelOrderInBulk: false }
      })
      .addCase(cancelOrderInBulk.fulfilled, (state, action) => {
        state.loading = { ...state.loading, cancelOrderInBulk: false }
        state.success = { ...state.success, cancelOrderInBulk: true }
        const eventName = action.payload.data.event
        if (eventName) {
          bindToChannelEvent(eventName)
        }
        state.isOrderCanceled = true
        // if (action.payload?.data?.message) {
        //   CustomToast(action.payload?.data?.message, { my_type: "success" })
        // }
      })
      .addCase(cancelOrderInBulk.rejected, state => {
        state.loading = { ...state.loading, cancelOrderInBulk: false }
        state.error = { ...state.error, cancelOrderInBulk: true }
        state.success = { ...state.success, cancelOrderInBulk: false }
      })
      // approve order in bulk
      .addCase(approveOrderInBulk.pending, state => {
        state.loading = { ...state.loading, approveOrderInBulk: true }
      })
      .addCase(approveOrderInBulk.fulfilled, (state, action) => {
        state.loading = { ...state.loading, approveOrderInBulk: false }
        state.success = { ...state.success, approveOrderInBulk: true }
        state.error = { ...state.error, approveOrderInBulk: false }
        const eventName = action.payload.data.pusher_response?.event
        if (eventName) {
          bindToChannelEvent(eventName)
        }
      })
      .addCase(approveOrderInBulk.rejected, state => {
        state.loading = { ...state.loading, approveOrderInBulk: false }
        state.error = { ...state.error, approveOrderInBulk: true }
        state.success = { ...state.success, approveOrderInBulk: false }
      })
      .addCase(changeOrderStatus.pending, state => {
        state.loading = { ...state.loading, changeOrderStatus: true }
      })
      .addCase(changeOrderStatus.fulfilled, (state, action) => {
        state.loading = { ...state.loading, changeOrderStatus: false }
        if (action?.payload?.status_code === 200) {
          state.isOrderStatusChanged = true
          CustomToast(action.payload?.data?.message, { my_type: 'success' })
        }
      })
      .addCase(changeOrderStatus.rejected, state => {
        state.isOrderStatusChanged = true
        state.loading = { ...state.loading, changeOrderStatus: false }
      })
      .addCase(getAllTags.fulfilled, (state, action) => {
        state.allOrderTags = action.payload
      })
      // manifest response 
      // create manifest response 
      .addCase(createManifestOrder.pending, (state) => {
        state.loading = { ...state.loading, createManifestOrder: true }
      })
      .addCase(createManifestOrder.fulfilled, (state, action) => {
        state.loading = { ...state.loading, createManifestOrder: false }
        state.singleDraftManifest = action.payload
      })
      .addCase(createManifestOrder.rejected, (state) => {
        state.loading = { ...state.loading, createManifestOrder: false }
      })
      // addOrderInManifestWithAwb
      .addCase(addOrderInManifestWithAwb.pending, (state) => {
        state.loading = { ...state.loading, addOrderInManifestWithAwb: true }
        state.success = { ...state.success, addOrderInManifestWithAwb: false }
        state.error = { ...state.error, addOrderInManifestWithAwb: false }
      })
      .addCase(addOrderInManifestWithAwb.fulfilled, (state, action) => {
        state.loading = { ...state.loading, addOrderInManifestWithAwb: false }
        state.success = { ...state.success, addOrderInManifestWithAwb: true }
        state.singleDraftManifest = action.payload
        CustomToast('Package added successfully', { my_type: 'success' })
      })
      .addCase(addOrderInManifestWithAwb.rejected, (state) => {
        state.loading = { ...state.loading, addOrderInManifestWithAwb: false }
        state.error = { ...state.error, addOrderInManifestWithAwb: true }
      })
      // completeManifest
      .addCase(completeManifest.pending, (state) => {
        state.loading = { ...state.loading, completeManifest: true }
      })
      .addCase(completeManifest.fulfilled, (state, action) => {
        state.loading = { ...state.loading, completeManifest: false }
        state.success = { ...state.success, completeManifest: true }
        if (action?.payload?.status_code === 200) {
          state.isManifestHandovered = true
          state.singleDraftManifest = { ...action.payload.data, handoverManifest: true }
          CustomToast('Manifest created successfully', { my_type: 'success' })
        }
      })
      .addCase(completeManifest.rejected, (state) => {
        state.success = { ...state.success, completeManifest: false }
        state.loading = { ...state.loading, completeManifest: false }
      })
      // draftManifest
      .addCase(draftManifest.pending, (state) => {
        state.loading = { ...state.loading, draftManifest: true }
      })
      .addCase(draftManifest.fulfilled, (state, action) => {
        state.success = { ...state.success, draftManifest: true }
        state.loading = { ...state.loading, draftManifest: false }
        if (action?.payload?.status_code === 200) {
          state.singleDraftManifest = action.payload.data
          state.isManifestDraftCreated = true
          CustomToast('Draft saved successfully', { my_type: 'success' })
        }
      })
      .addCase(draftManifest.rejected, (state) => {
        state.success = { ...state.success, draftManifest: false }
        state.loading = { ...state.loading, draftManifest: false }
      })
      .addCase(handOverManifest.pending, (state) => {
        state.loading = { ...state.loading, handoverManifest: true }
      })
      .addCase(handOverManifest.fulfilled, (state, action) => {
        state.loading = { ...state.loading, handoverManifest: false }
        state.success = { ...state.success, handOverManifest: true }
        if (action?.payload?.status_code === 200) {
          state.singleDraftManifest = action.payload.data
          state.isManifestReadyForHandOver = true
          CustomToast('Manifest moved to \'Ready for Handover\' successfully.', { my_type: 'success' })
        }
      })
      .addCase(handOverManifest.rejected, (state) => {
        state.loading = { ...state.loading, handoverManifest: false }
        state.success = { ...state.success, handOverManifest: false }
      })
      // removeManifestOrder
      .addCase(removeManifestOrder.pending, (state) => {
        state.loading = { ...state.loading, removeManifestOrder: true }
      })
      .addCase(removeManifestOrder.fulfilled, (state, action) => {
        state.loading = { ...state.loading, removeManifestOrder: false }
        if (action?.payload?.status_code === 200) {
          state.isManifestOrderRemoved = true
          state.singleDraftManifest = action.payload.data
          CustomToast('Order removed successfully', { my_type: 'success' })
        }
      })
      .addCase(removeManifestOrder.rejected, (state) => {
        state.loading = { ...state.loading, removeManifestOrder: false }
        state.isManifestOrderRemoved = false
      })
      // getAllManifestOrder
      .addCase(getAllManifestOrder.pending, (state) => {
        state.loading = { ...state.loading, getAllManifestOrder: true }
        if (state.isDraftManifestCancelled) {
          state.isDraftManifestCancelled = false
        }
      })
      .addCase(getAllManifestOrder.fulfilled, (state, action) => {
        state.allManifestOrders = action.payload.data
        state.manifestOrdersMeta = action.payload.meta
        state.loading = { ...state.loading, getAllManifestOrder: false }
        state.success = { ...state.success, getAllManifestOrder: true }
        state.error = { ...state.error, getAllManifestOrder: false }
      })
      .addCase(getAllManifestOrder.rejected, (state) => {
        state.loading = { ...state.loading, getAllManifestOrder: false }
        state.success = { ...state.success, getAllManifestOrder: false }
        state.error = { ...state.error, getAllManifestOrder: true }
      })
      // cancelManifest
      .addCase(cancelManifest.pending, (state) => {
        state.loading = { ...state.loading, cancelManifest: true }
      })
      .addCase(cancelManifest.fulfilled, (state, action) => {
        state.loading = { ...state.loading, cancelManifest: false }
        if (action?.payload?.status_code === 200) {
          state.isDraftManifestCancelled = true
          state.singleDraftManifest = null
          CustomToast('Discarded successfully', { my_type: 'success' })
        }
      })
      .addCase(cancelManifest.rejected, (state) => {
        state.loading = { ...state.loading, cancelManifest: false }
      })
      .addCase(addNewVirtualHub.pending, (state) => {
        state.loading = { ...state.loading, addNewVirtualHub: true }
      })
      .addCase(addNewVirtualHub.fulfilled, (state, action) => {
        state.loading = { ...state.loading, addNewVirtualHub: false }
        state.success = { ...state.success, addNewVirtualHub: true }
        if (action?.payload?.status_code === 200) {
          state.isVirtualHubCreated = true
          const data = action.payload.data
          state.virtualHubCreatedResponse = data
          CustomToast(action.payload?.data?.message || 'New Hub Added Successfully', { my_type: 'success' })
        }
      })
      .addCase(addNewVirtualHub.rejected, (state) => {
        state.loading = { ...state.loading, addNewVirtualHub: false }
      })
      .addCase(addNewPickupLocation.pending, (state) => {
        state.loading = { ...state.loading, addNewPickupLocation: true }
      })
      .addCase(addNewPickupLocation.fulfilled, (state, action) => {
        state.loading = { ...state.loading, addNewPickupLocation: false }
        state.success = { ...state.success, addNewPickupLocation: true }
        if (action?.payload?.status_code === 200) {
          state.isVirtualHubCreated = true
          const data = action.payload.data
          state.virtualHubCreatedResponse = data
          CustomToast(action.payload?.data?.message || 'New Location Added Successfully', { my_type: 'success' })
        }
      })
      .addCase(addNewPickupLocation.rejected, (state) => {
        state.loading = { ...state.loading, addNewPickupLocation: false }
      })
      .addCase(editPickupLocation.pending, (state) => {
        state.loading = { ...state.loading, editPickupLocation: true }
      })
      .addCase(editPickupLocation.fulfilled, (state, action) => {
        state.loading = { ...state.loading, editPickupLocation: false }
        state.success = { ...state.success, editPickupLocation: true }
        if (action?.payload?.status_code === 200) {
          state.isVirtualHubCreated = true
          const data = action.payload.data
          state.virtualHubCreatedResponse = data
          CustomToast(action.payload?.data?.message || 'New Pickup Location Update Successfully', { my_type: 'success' })
        }
      })
      .addCase(editPickupLocation.rejected, (state) => {
        state.loading = { ...state.loading, editPickupLocation: false }
      })

      .addCase(getStoRequestDetail.pending, (state) => {
        state.loading = { ...state.loading, getStoRequestDetail: true }
      })
      .addCase(getStoRequestDetail.fulfilled, (state, action) => {
        state.loading = { ...state.loading, getStoRequestDetail: false }
        state.success = { ...state.success, getStoRequestDetail: true }
        state.error = { ...state.error, getStoRequestDetail: false }
        state.stoDetail = action.payload.data
        state.stoRequestMetaData = action.payload.meta
      })
      .addCase(getStoRequestDetail.rejected, (state) => {
        state.loading = { ...state.loading, getStoRequestDetail: false }
        state.success = { ...state.success, getStoRequestDetail: false }
        state.error = { ...state.error, getStoRequestDetail: true }
      })
      .addCase(createBulkStoRequest.pending, (state) => {
        state.loading = { ...state.loading, createBulkStoRequest: true }
      })
      .addCase(createBulkStoRequest.fulfilled, (state, action) => {
        state.loading = { ...state.loading, createBulkStoRequest: false }
        state.isStoRequestCreated = true
        state.uploadedStoFiles = null
        CustomToast('Your request has been accepted. You will receive a confirmation shortly.', { my_type: 'success' })
        const eventName = action.payload.data.event
        bindToChannelEvent(eventName)
      })
      .addCase(createBulkStoRequest.rejected, (state) => {
        state.loading = { ...state.loading, createBulkStoRequest: false }
      })
      .addCase(createStoRequest.pending, (state) => {
        state.loading = { ...state.loading, createStoRequest: true }
      })
      .addCase(createStoRequest.fulfilled, (state, action) => {
        state.loading = { ...state.loading, createStoRequest: false }
        if (action?.meta?.requestStatus === 'fulfilled') {
          state.isStoRequestCreated = true
          CustomToast('Request created successfully', { my_type: 'success' })
        }
      })
      .addCase(createStoRequest.rejected, (state) => {
        state.loading = { ...state.loading, createStoRequest: false }
      })
      .addCase(getCreateShipmentDetails.pending, (state) => {
        state.loading = { ...state.loading, getCreateShipmentDetails: true }
      })
      .addCase(getCreateShipmentDetails.fulfilled, (state, action) => {
        state.loading = { ...state.loading, getCreateShipmentDetails: false }
        if (action?.meta?.requestStatus === 'fulfilled') {
          state.createShipmentDetails = action.payload.data.shipping_options
        }
      })
      .addCase(getCreateShipmentDetails.rejected, (state) => {
        state.loading = { ...state.loading, getCreateShipmentDetails: false }
      })
      // manifest response ends here
      .addCase(getAllZones.pending, (state) => {
        state.loading = { ...state.loading, getAllZones: true }
        state.zonesOptions = null
      })
      .addCase(getAllZones.fulfilled, (state, action) => {
        state.loading = { ...state.loading, getAllZones: false }
        state.zonesOptions = action.payload
      })
      .addCase(getAllZones.rejected, (state) => {
        state.loading = { ...state.loading, getAllZones: false }
      })
      // shipments response
      // getShipments
      .addCase(getShipments.pending, (state) => {
        state.loading = { ...state.loading, getShipments: true }
        state.success = { ...state.success, getShipments: false }
        state.error = { ...state.error, getShipments: false }
        state.shipments = null
      })
      .addCase(getShipments.fulfilled, (state, action) => {
        state.loading = { ...state.loading, getShipments: false }
        state.success = { ...state.success, getShipments: true }
        state.shipments = action.payload
      })
      .addCase(getShipments.rejected, (state) => {
        state.loading = { ...state.loading, getShipments: false }
        state.error = { ...state.error, getShipments: true }
      })
      // updateShipmentStatus
      .addCase(updateShipmentStatus.pending, (state) => {
        state.loading = { ...state.loading, updateShipmentStatus: true }
      })
      .addCase(updateShipmentStatus.fulfilled, (state) => {
        state.loading = { ...state.loading, updateShipmentStatus: false }
        state.success = { ...state.success, updateShipmentStatus: true }
        CustomToast('Shipment status updated successfully', { my_type: 'success' })
      })
      .addCase(updateShipmentStatus.rejected, (state) => {
        state.loading = { ...state.loading, updateShipmentStatus: false }
      })
      // createBulkReturnOrder
      .addCase(createBulkReturnOrder.pending, (state) => {
        state.loading = { ...state.loading, createBulkReturnOrder: true }
      })
      .addCase(createBulkReturnOrder.fulfilled, (state) => {
        state.loading = { ...state.loading, createBulkReturnOrder: false }
        state.success = { ...state.success, createBulkReturnOrder: true }
        CustomToast('Return order created successfully', { my_type: 'success' })
      })
      .addCase(createBulkReturnOrder.rejected, (state) => {
        state.loading = { ...state.loading, createBulkReturnOrder: false }
      })
      // shipment response ends here
      // syncPendingOrderWithSalesChannel response
      .addCase(syncPendingOrderWithSalesChannel.pending, (state, action) => {
        const { seller_sales_channel_order_id } = action.meta.arg
        state.loading = { ...state.loading, syncPendingOrderWithSalesChannel: true, [seller_sales_channel_order_id]: true }
      })
      .addCase(syncPendingOrderWithSalesChannel.fulfilled, (state, action) => {
        const { seller_sales_channel_order_id } = action.meta.arg
        state.loading = { ...state.loading, syncPendingOrderWithSalesChannel: false, [seller_sales_channel_order_id]: false }
        state.success = { ...state.success, syncPendingOrderWithSalesChannel: true }
        CustomToast('Order updated successfully', { my_type: 'success' })
      })
      .addCase(syncPendingOrderWithSalesChannel.rejected, (state, action) => {
        const { seller_sales_channel_order_id } = action.meta.arg
        state.loading = { ...state.loading, syncPendingOrderWithSalesChannel: false, [seller_sales_channel_order_id]: false }
        state.success = { ...state.success, syncPendingOrderWithSalesChannel: false }
      })
      .addCase(getCustomerDetail.pending, (state) => {
        state.loading = { ...state.loading, getCustomerDetail: true }
        state.error = { ...state.error, getCustomerDetail: false }
        state.success = { ...state.success, getCustomerDetail: false }
      })
      .addCase(getCustomerDetail.fulfilled, (state, action) => {
        state.loading = { ...state.loading, getCustomerDetail: false }
        state.success = { ...state.success, getCustomerDetail: true }
        state.customerDetail = action.payload.data
        state.customerMetaData = action.payload.meta
      })
      .addCase(getCustomerDetail.rejected, (state) => {
        state.loading = { ...state.loading, getCustomerDetail: false }
        state.error = { ...state.error, getCustomerDetail: true }
      })
      .addCase(createCustomer.pending, (state) => {
        state.loading = { ...state.loading, createCustomer: true }
      })
      .addCase(createCustomer.fulfilled, (state, action) => {
        state.loading = { ...state.loading, createCustomer: false }
        state.success = { ...state.success, createCustomer: true }
        CustomToast('Customer created successfully', { my_type: 'success' })
        state.customerData = action.payload.data
      })
      .addCase(createCustomer.rejected, (state) => {
        state.loading = { ...state.loading, createCustomer: false }
      })
      .addCase(getCustomerAddresses.pending, (state) => {
        state.loading = { ...state.loading, getCustomerAddresses: true }
      })
      .addCase(getCustomerAddresses.fulfilled, (state, action) => {
        const customerData = action.payload?.data || []
        state.loading = { ...state.loading, getCustomerAddresses: false }
        state.success = { ...state.success, getCustomerAddresses: true }
        if (action.payload.meta?.current_page === 1) {
          state.customerAddresses = customerData
        } else if (action.payload.meta) {
          state.customerAddresses = [...state.customerAddresses, ...customerData]
        }
        state.customerAddressesMetaData = action.payload.meta
      })
      .addCase(getCustomerAddresses.rejected, (state) => {
        state.loading = { ...state.loading, getCustomerAddresses: false }
      })
      .addCase(getCustomerAddress.pending, (state) => {
        state.loading = { ...state.loading, getCustomerAddress: true }
      })
      .addCase(getCustomerAddress.fulfilled, (state, action) => {
        state.loading = { ...state.loading, getCustomerAddress: false }
        state.success = { ...state.success, getCustomerAddress: true }
        state.customerAddress = action.payload.data
      })
      .addCase(getCustomerAddress.rejected, (state) => {
        state.loading = { ...state.loading, getCustomerAddress: false }
      })
      .addCase(createAddress.pending, (state) => {
        state.loading = { ...state.loading, createAddress: true }
      })
      .addCase(createAddress.fulfilled, (state, action) => {
        state.loading = { ...state.loading, createAddress: false }
        state.success = { ...state.success, createAddress: true }
        CustomToast('Address created successfully', { my_type: 'success' })
        if (state.customerAddresses) {
          state.customerAddresses = [action.payload.data, ...state.customerAddresses]
        } else {
          state.customerAddresses = [action.payload.data]
        }
      })
      .addCase(createAddress.rejected, (state) => {
        state.loading = { ...state.loading, createAddress: false }
      })
      .addCase(editAddress.pending, (state) => {
        state.loading = { ...state.loading, editAddress: true }
      })
      .addCase(editAddress.fulfilled, (state, action) => {
        state.loading = { ...state.loading, editAddress: false }
        state.success = { ...state.success, editAddress: true }
        const edit_id = action.payload.data.id
        CustomToast('Address edit successfully', { my_type: 'success' })
        let data = state.customerAddresses
        data = data.filter(item => item.id !== edit_id)

        state.customerAddresses = [action.payload.data, ...data]

        // state.customerDetail = action.payload.data
      })
      .addCase(editAddress.rejected, (state) => {
        state.loading = { ...state.loading, editAddress: false }
      })
      .addCase(editCustomerAndShippingDetails.pending, (state) => {
        state.loading = { ...state.loading, editCustomerAndShippingDetails: true }
        state.success = { ...state.success, editCustomerAndShippingDetails: false }
      })
      .addCase(editCustomerAndShippingDetails.fulfilled, (state, action) => {
        state.loading = { ...state.loading, editCustomerAndShippingDetails: false }
        state.error = { ...state.error, editCustomerAndShippingDetails: false }
        state.success = { ...state.success, editCustomerAndShippingDetails: true }
        if (action.payload?.status_code === 200) {
          CustomToast('Order details updated', { my_type: 'success' })
        }
      })
      .addCase(editCustomerAndShippingDetails.rejected, (state) => {
        state.loading = { ...state.loading, editCustomerAndShippingDetails: false }
        state.error = { ...state.error, editCustomerAndShippingDetails: true }
      })
      .addCase(getOrderDetailsInPacking.pending, (state, action) => {
        state.packingConfiguration.apiLoading = { ...state.packingConfiguration.apiLoading, getOrderDetailsInPacking: { value: true, id: action.meta.arg.oms_order_id } }
        state.packingConfiguration.apiSuccess = { ...state.packingConfiguration.apiSuccess, getOrderDetailsInPacking: false }
        state.packingConfiguration.apiError = { ...state.packingConfiguration.apiError, getOrderDetailsInPacking: false }
      })
      .addCase(getOrderDetailsInPacking.fulfilled, (state, action) => {
        if (action.payload?.status_code === 200) {
          state.packingConfiguration.orderDetailsInPackingData = action.payload.data
          state.packingConfiguration.apiLoading = { ...state.packingConfiguration.apiLoading, getOrderDetailsInPacking: { value: false, id: action.meta.arg.oms_order_id } }
          state.packingConfiguration.apiSuccess = { ...state.packingConfiguration.apiSuccess, getOrderDetailsInPacking: true }
          state.packingConfiguration.apiError = { ...state.packingConfiguration.apiError, getOrderDetailsInPacking: false }
        }
      })
      .addCase(getOrderDetailsInPacking.rejected, (state, action) => {
        state.packingConfiguration.apiLoading = { ...state.packingConfiguration.apiLoading, getOrderDetailsInPacking: { value: false, id: action.meta.arg.oms_order_id } }
        state.packingConfiguration.apiSuccess = { ...state.packingConfiguration.apiSuccess, getOrderDetailsInPacking: false }
        state.packingConfiguration.apiError = { ...state.packingConfiguration.apiError, getOrderDetailsInPacking: true }
      })
      .addCase(getShippingDetailsForPacking.pending, state => {
        state.loading = { ...state.loading, getShippingDetailsForPacking: true }
        state.packingConfiguration.shippingDetails = { ...state.packingConfiguration.shippingDetails, getShippingData: false }
      })
      .addCase(getShippingDetailsForPacking.fulfilled, (state, action) => {
        state.loading = { ...state.loading, getShippingDetailsForPacking: false }
        state.shippingDetailsData = action.payload?.data
        state.packingConfiguration.shippingDetails = { ...state.packingConfiguration.shippingDetails, getShippingData: true }
      })
      .addCase(getShippingDetailsForPacking.rejected, state => {
        state.loading = { ...state.loading, getShippingDetailsForPacking: false }
        state.packingConfiguration.shippingDetails = { ...state.packingConfiguration.shippingDetails, getShippingData: false }
      })
      .addCase(editCustomer.pending, (state) => {
        state.loading = { ...state.loading, editCustomer: true }
      })
      .addCase(editCustomer.fulfilled, (state, action) => {
        state.loading = { ...state.loading, editCustomer: false }
        state.success = { ...state.success, editCustomer: true }
        CustomToast('Customer edit successfully', { my_type: 'success' })
        state.customerData = action.payload.data
      })
      .addCase(editCustomer.rejected, (state) => {
        state.loading = { ...state.loading, editCustomer: false }
      })
      .addCase(getWmsOrderDetails.pending, (state) => {
        state.loading = { ...state.loading, getWmsOrderDetails: true }
        state.success = { ...state.success, getWmsOrderDetails: false }
        state.error = { ...state.error, getWmsOrderDetails: false }
      })
      .addCase(getWmsOrderDetails.fulfilled, (state, action) => {
        state.loading = { ...state.loading, getWmsOrderDetails: false }
        state.success = { ...state.success, getWmsOrderDetails: true }
        state.error = { ...state.error, getWmsOrderDetails: false }
        state.wmsOrderDetailsData = action.payload
      })
      .addCase(getWmsOrderDetails.rejected, (state) => {
        state.loading = { ...state.loading, getWmsOrderDetails: false }
        state.success = { ...state.success, getWmsOrderDetails: false }
        state.error = { ...state.error, getWmsOrderDetails: true }
        state.wmsOrderDetailsData = {}
      })
      .addCase(getBatchesForSKUCode.fulfilled, (state, action) => {
        state.loading.getBatchesForSKUCode = false
        state.success.getBatchesForSKUCode = true
        state.error.getBatchesForSKUCode = false
        state.skuBatchDetails = action.payload.data
      })
      .addCase(getBatchesForSKUCode.pending, state => {
        state.loading.getBatchesForSKUCode = true
        state.success.getBatchesForSKUCode = false
        state.error.getBatchesForSKUCode = false
      })
      .addCase(getBatchesForSKUCode.rejected, state => {
        state.loading.getBatchesForSKUCode = false
        state.success.getBatchesForSKUCode = false
        state.error.getBatchesForSKUCode = true
      })
      .addCase(createPallet.fulfilled, (state, action) => {
        state.loading.createPallet = false
        state.success.createPallet = true
        state.error.createPallet = false
        const data = action.payload.data ?? []
        state.pallet = { ...data, cartons: [], packed_items: [], type: 'pallet' }
      })
      .addCase(createPallet.pending, state => {
        state.loading.createPallet = true
        state.success.createPallet = false
        state.error.createPallet = false
      })
      .addCase(createPallet.rejected, state => {
        state.loading.createPallet = false
        state.success.createPallet = false
        state.error.createPallet = true
      })
      .addCase(getPallet.fulfilled, (state, action) => {
        state.loading.getPallet = false
        state.success.getPallet = true
        state.error.getPallet = false
        const data = action.payload.data ?? []
        state.getPalletData = { ...data, cartons: [], packed_items: [], type: 'pallet' }
      })
      .addCase(getPallet.pending, state => {
        state.loading.getPallet = true
        state.success.getPallet = false
        state.error.getPallet = false
      })
      .addCase(getPallet.rejected, state => {
        state.loading.getPallet = false
        state.success.getPallet = false
        state.error.getPallet = true
      })
      .addCase(createBox.fulfilled, (state, action) => {
        state.loading.createBox = false
        state.success.createBox = true
        state.error.createBox = false
        const data = action.payload.data ?? []
        state.carton = { ...data, packed_items: [], type: 'carton', pallet_id: action.meta.arg.pallet_id, box_id: action.meta.arg.box_id }
      })
      .addCase(createBox.pending, state => {
        state.loading.createBox = true
        state.success.createBox = false
        state.error.createBox = false
      })
      .addCase(createBox.rejected, state => {
        state.loading.createBox = false
        state.success.createBox = false
        state.error.createBox = true
      })
      .addCase(bulkCustomersUpload.pending, (state) => {
        state.uploadBulkCustomesResponse = {
          loading: true,
          success: false,
          data: null
        }
      })
      .addCase(bulkCustomersUpload.fulfilled, (state, action) => {
        state.uploadBulkCustomesResponse = {
          loading: false,
          success: true,
          data: action.payload
        }
        const eventName = action.payload.data.pusher_response.event
        if (eventName) {
          bindToChannelEvent(eventName)
        }
      })
      .addCase(bulkCustomersUpload.rejected, (state) => {
        state.uploadBulkCustomesResponse = { loading: false, success: false }
      })

      .addCase(getCreateSplitOrderDetails.pending, state => {
        state.loading.getCreateSplitOrderDetails = true
        state.success.getCreateSplitOrderDetails = false
        state.error.getCreateSplitOrderDetails = false
      })
      .addCase(getCreateSplitOrderDetails.fulfilled, (state, action) => {
        state.loading.getCreateSplitOrderDetails = false
        state.success.getCreateSplitOrderDetails = true
        state.singleSplitOrderDetails = action.payload.data
      })
      .addCase(getCreateSplitOrderDetails.rejected, state => {
        state.loading.getCreateSplitOrderDetails = false
        state.error.getCreateSplitOrderDetails = true
      })

      .addCase(getSingleSplittedOrder.pending, state => {
        state.loading.getSingleSplittedOrder = true
        state.success.getSingleSplittedOrder = false
        state.error.getSingleSplittedOrder = false
      })
      .addCase(getSingleSplittedOrder.fulfilled, (state, action) => {
        state.loading.getSingleSplittedOrder = false
        state.success.getSingleSplittedOrder = true
        state.singleSplitOrderDetails = action.payload.data
      })
      .addCase(getSingleSplittedOrder.rejected, state => {
        state.loading.getSingleSplittedOrder = false
        state.error.getSingleSplittedOrder = true
      })

      .addCase(getAllSplittedOrders.pending, state => {
        state.loading.getAllSplittedOrders = true
        state.success.getAllSplittedOrders = false
        state.error.getAllSplittedOrders = false
      })
      .addCase(getAllSplittedOrders.fulfilled, (state, action) => {
        state.loading.getAllSplittedOrders = false
        state.success.getAllSplittedOrders = true
        state.allSplittedOrdersData = action.payload.data
        state.allSplittedOrdersMetaData = action.payload.meta
      })
      .addCase(getAllSplittedOrders.rejected, state => {
        state.loading.getAllSplittedOrders = false
        state.error.getAllSplittedOrders = true
      })

      .addCase(createSplitOrder.pending, state => {
        state.loading.createSplitOrder = true
        state.success.createSplitOrder = false
        state.error.createSplitOrder = false
      })
      .addCase(createSplitOrder.fulfilled, (state) => {
        state.loading.createSplitOrder = false
        state.success.createSplitOrder = true
      })
      .addCase(createSplitOrder.rejected, state => {
        state.loading.createSplitOrder = false
        state.error.createSplitOrder = true
      })

      .addCase(updateSplitOrder.pending, state => {
        state.loading.updateSplitOrder = true
        state.success.updateSplitOrder = false
        state.error.updateSplitOrder = false
      })
      .addCase(updateSplitOrder.fulfilled, (state) => {
        state.loading.updateSplitOrder = false
        state.success.updateSplitOrder = true
      })
      .addCase(updateSplitOrder.rejected, state => {
        state.loading.updateSplitOrder = false
        state.error.updateSplitOrder = true
      })

      .addCase(getPackingListForPrint.fulfilled, (state, action) => {
        state.loading.getPackingListForPrint = false
        state.success.getPackingListForPrint = true
        state.error.getPackingListForPrint = false
        state.packingListForPrint = action.payload.data
      })
      .addCase(getPackingListForPrint.pending, state => {
        state.loading.getPackingListForPrint = true
        state.success.getPackingListForPrint = false
        state.error.getPackingListForPrint = false
      })
      .addCase(getPackingListForPrint.rejected, state => {
        state.loading.getPackingListForPrint = false
        state.success.getPackingListForPrint = false
        state.error.getPackingListForPrint = true
      })
    
      .addCase(getSplitOrderMetaData.pending, state => {
        state.loading.getSplitOrderMetaData = true
        state.success.getSplitOrderMetaData = false
        state.error.getSplitOrderMetaData = false
      })
      .addCase(getSplitOrderMetaData.fulfilled, (state, action) => {
        state.loading.getSplitOrderMetaData = false
        state.success.getSplitOrderMetaData = true
        state.splitOrderMetaData = action.payload.data
      })
      .addCase(getSplitOrderMetaData.rejected, state => {
        state.loading.getSplitOrderMetaData = false
        state.error.getSplitOrderMetaData = true
      })
      .addCase(updateReceiveQuantityForSto.pending, (state => {
        state.loading = { ...state.loading, updateReceiveQuantityForSto: true }
      }))
      .addCase(updateReceiveQuantityForSto.fulfilled, (state => {
        state.loading = { ...state.loading, updateReceiveQuantityForSto: false }
        state.success = { ...state.success, updateReceiveQuantityForSto: true }
      }))
      .addCase(updateReceiveQuantityForSto.rejected, (state => {
        state.loading = { ...state.loading, updateReceiveQuantityForSto: false }
        state.error = { ...state.error, updateReceiveQuantityForSto: true }
      }))
      .addCase(markStoAsDelivered.pending, (state) => {
        state.loading = { ...state.loading, markStoAsDelivered: true }
        state.success = { ...state.success, markStoAsDelivered: false }
        state.error = { ...state.error, markStoAsDelivered: false }
      })
      .addCase(markStoAsDelivered.fulfilled, (state, action) => {
        state.loading = { ...state.loading, markStoAsDelivered: false }
        state.success = { ...state.success, markStoAsDelivered: true }
        state.markStoAsDeliveredResponse = { stoId: action.meta.arg.id }
        state.error = { ...state.error, markStoAsDelivered: false }
      })
      .addCase(markStoAsDelivered.rejected, (state) => {
        state.loading = { ...state.loading, markStoAsDelivered: false }
        state.error = { ...state.error, markStoAsDelivered: true }
        state.success = { ...state.success, markStoAsDelivered: false }
      })

      .addCase(syncOrderPaymentStatus.pending, (state) => {
        state.loading = { ...state.loading, syncOrderPaymentStatus: true }
        state.error = { ...state.error, syncOrderPaymentStatus: false }
        state.success = { ...state.success, syncOrderPaymentStatus: false }
      })
      .addCase(syncOrderPaymentStatus.fulfilled, (state, action) => {
        state.loading = { ...state.loading, syncOrderPaymentStatus: false }
        state.success = { ...state.success, syncOrderPaymentStatus: true }
        CustomToast(action.payload.data, { my_type: 'success' })
      })
      .addCase(syncOrderPaymentStatus.rejected, (state) => {
        state.loading = { ...state.loading, syncOrderPaymentStatus: false }
        state.error = { ...state.error, syncOrderPaymentStatus: true }
      })

      .addCase(putOrderOnHold.pending, (state) => {
        state.loading = { ...state.loading, putOrderOnHold: true }
        state.error = { ...state.error, putOrderOnHold: false }
        state.success = { ...state.success, putOrderOnHold: false }
      })
      .addCase(putOrderOnHold.fulfilled, (state) => {
        state.loading = { ...state.loading, putOrderOnHold: false }
        state.success = { ...state.success, putOrderOnHold: true }
        CustomToast('Order Successfully Put On Hold.', { my_type: 'success' })
      })
      .addCase(putOrderOnHold.rejected, (state) => {
        state.loading = { ...state.loading, putOrderOnHold: false }
        state.error = { ...state.error, putOrderOnHold: true }
      })
      
      .addCase(createShipmentOrderShipment.pending, state => {
        state.loading = { ...state.loading, createShipmentOrderShipment: true }
      })
      .addCase(createShipmentOrderShipment.rejected, state => {
        state.loading = { ...state.loading, createShipmentOrderShipment: false }
        state.success = { ...state.success, createShipmentOrderShipment: false }
        state.error = { ...state.error, createShipmentOrderShipment: true }
      })
      .addCase(createShipmentOrderShipment.fulfilled, (state, action) => {
        state.loading = { ...state.loading, createShipmentOrderShipment: false }
        state.success = { ...state.success, createShipmentOrderShipment: true }
        state.error = { ...state.error, createShipmentOrderShipment: false }
        if (action.payload.status_code === 200) {
          state.shipmentDetails = action.payload
          CustomToast('Shipment created successfully', { my_type: 'success' })
        }
      })
      .addCase(getShippingPartnersAccounts.pending, (state) => {
        state.getPrioritisedShippingPartnersRes = { data: null, loading: true, is_success: false }
      })
      .addCase(getShippingPartnersAccounts.fulfilled, (state, action) => {
        if (action.payload?.data?.action_value) {
          const modifiedData = { ...action.payload.data, action_value: getPrioritisedShippingPartnersResActionValueParser(action.payload.data.action_value) }
          state.getPrioritisedShippingPartnersRes = {
            data: { data: modifiedData },
            loading: false,
            is_success: true
          }
        } else {
          state.getPrioritisedShippingPartnersRes = {
            data: action.payload,
            loading: false,
            is_success: true
          }
        }
      })
      .addCase(getShippingPartnersAccounts.rejected, (state) => {
        state.getPrioritisedShippingPartnersRes = { data: null, loading: false, is_success: false }
      })

      .addCase(createShipmentOrder.pending, (state) => {
        state.loading = { ...state.loading, createShipmentOrder: true }
        state.success = { ...state.success, createShipmentOrder: false }
        state.error = { ...state.error, createShipmentOrder: false }
      })
      .addCase(createShipmentOrder.fulfilled, (state, action) => {
        if (action?.meta?.requestStatus === 'fulfilled') {
          CustomToast('Order created successfully', { my_type: 'success' })
        }
        state.loading = { ...state.loading, createShipmentOrder: false }
        state.success = { ...state.success, createShipmentOrder: true }
      })
      .addCase(createShipmentOrder.rejected, (state) => {
        state.loading = { ...state.loading, createShipmentOrder: false }
        state.error = { ...state.error, createShipmentOrder: true }
      })

      .addCase(getAWBDetailForPrint.pending, (state) => {
        state.loading = { ...state.loading, getAWBDetailForPrint: true }
        state.success = { ...state.success, getAWBDetailForPrint: false }
        state.error = { ...state.error, getAWBDetailForPrint: false }
      })
      .addCase(getAWBDetailForPrint.fulfilled, (state, action) => {
        state.loading = { ...state.loading, getAWBDetailForPrint: false }
        state.success = { ...state.success, getAWBDetailForPrint: true }
        state.awbDataForPrint = action.payload.data?.data.entity_data
      })
      .addCase(getAWBDetailForPrint.rejected, (state) => {
        state.loading = { ...state.loading, getAWBDetailForPrint: false }
        state.error = { ...state.success, getAWBDetailForPrint: true }
      })
          
      .addCase(putOrdersOnHoldInBulk.pending, (state) => {
        state.loading = { ...state.loading, putOrdersOnHoldInBulk: true }
        state.error = { ...state.error, putOrdersOnHoldInBulk: false }
        state.success = { ...state.success, putOrdersOnHoldInBulk: false }
      })
      .addCase(putOrdersOnHoldInBulk.fulfilled, (state, action) => {
        state.loading = { ...state.loading, putOrdersOnHoldInBulk: false }
        state.success = { ...state.success, putOrdersOnHoldInBulk: true }
        CustomToast(action.payload.data.message, { my_type: 'success' })
      })
      .addCase(putOrdersOnHoldInBulk.rejected, (state) => {
        state.loading = { ...state.loading, putOrdersOnHoldInBulk: false }
        state.error = { ...state.error, putOrdersOnHoldInBulk: true }
      })
    
      .addCase(editShipmentOrderDetails.pending, (state) => {
        state.loading = { ...state.loading, editShipmentOrderDetails: true }
        state.success = { ...state.success, editShipmentOrderDetails: false }
        state.error = { ...state.error, editShipmentOrderDetails: false }
      })
      .addCase(editShipmentOrderDetails.fulfilled, (state, action) => {
        state.loading = { ...state.loading, editShipmentOrderDetails: false }
        state.success = { ...state.success, editShipmentOrderDetails: true }
        if (action.payload?.status_code === 200) {
          CustomToast('Order details updated', { my_type: 'success' })
        }
      })
      .addCase(editShipmentOrderDetails.rejected, (state) => {
        state.loading = { ...state.loading, editShipmentOrderDetails: false }
        state.error = { ...state.error, editShipmentOrderDetails: true }
      })

      .addCase(getHubsDetailsForShipmentOrder.pending, (state) => {
        state.loading = { ...state.loading, getHubsDetailsForShipmentOrder: true }
        state.success = { ...state.success, getHubsDetailsForShipmentOrder: false }
        state.error = { ...state.error, getHubsDetailsForShipmentOrder: false }
      })
      .addCase(getPickupLocation.pending, (state) => {
        state.loading = { ...state.loading, getPickupLocation: true }
        state.success = { ...state.success, getPickupLocation: false }
        state.error = { ...state.error, getPickupLocation: false }
      })
      .addCase(getHubsDetailsForShipmentOrder.fulfilled, (state, action) => {
        state.loading = { ...state.loading, getHubsDetailsForShipmentOrder: false }
        state.success = { ...state.success, getHubsDetailsForShipmentOrder: true }
        if (action.payload.meta?.current_page === 1) {
          state.hubsDetailsForShipmentOrder = action.payload.data
        } else {
          state.hubsDetailsForShipmentOrder = [...state.hubsDetailsForShipmentOrder, ...action.payload.data]
        }
        
        state.hubsMetaDataForShipmentOrder = action.payload.meta
      })
      .addCase(getPickupLocation.fulfilled, (state, action) => {
        state.loading = { ...state.loading, getPickupLocation: false }
        state.success = { ...state.success, getPickupLocation: true }
        state.pickupLocationData = action.payload.data
        state.pickupLocationMeta = action.payload.meta
      })
      .addCase(getHubsDetailsForShipmentOrder.rejected, (state) => {
        state.loading = { ...state.loading, getHubsDetailsForShipmentOrder: false }
        state.error = { ...state.error, getHubsDetailsForShipmentOrder: true }
      })
      .addCase(getPickupLocation.rejected, (state) => {
        state.loading = { ...state.loading, getPickupLocation: false }
        state.error = { ...state.error, getPickupLocation: true }
      })

      .addCase(getShipmentOrderLogs.pending, (state) => {
        state.loading = { ...state.loading, getShipmentOrderLogs: true }
        state.success = { ...state.success, getShipmentOrderLogs: false }
        state.error = { ...state.error, getShipmentOrderLogs: false }
      })
      .addCase(getShipmentOrderLogs.fulfilled, (state, action) => {
        state.loading = { ...state.loading, getShipmentOrderLogs: false }
        state.success = { ...state.success, getShipmentOrderLogs: true }
        state.shipmentOrderLogs = action.payload.data
      })
      .addCase(getShipmentOrderLogs.rejected, (state) => {
        state.loading = { ...state.loading, getShipmentOrderLogs: false }
        state.error = { ...state.error, getShipmentOrderLogs: true }
      })
    
      .addCase(cancelShipmentOrder.pending, (state) => {
        state.loading = { ...state.loading, cancelShipmentOrder: true }
        state.success = { ...state.success, cancelShipmentOrder: false }
        state.error = { ...state.error, cancelShipmentOrder: false }
      })
      .addCase(cancelShipmentOrder.fulfilled, (state) => {
        state.loading = { ...state.loading, cancelShipmentOrder: false }
        state.success = { ...state.success, cancelShipmentOrder: true }
      })
      .addCase(cancelShipmentOrder.rejected, (state) => {
        state.loading = { ...state.loading, cancelShipmentOrder: false }
        state.error = { ...state.error, cancelShipmentOrder: true }
      })

      .addCase(createBulkShipmentOrder.pending, (state) => {
        state.loading = { ...state.loading, createBulkShipmentOrder: true }
        state.success = { ...state.success, createBulkShipmentOrder: false }
        state.error = { ...state.error, createBulkShipmentOrder: false }
      })
      .addCase(createBulkShipmentOrder.fulfilled, (state, action) => {
        state.loading = { ...state.loading, createBulkShipmentOrder: false }
        state.success = { ...state.success, createBulkShipmentOrder: true }
        CustomToast(action.payload.data?.data.message, { my_type: 'success' })
        const eventName = action.payload.data?.data.pusher_response.event
        if (eventName) {
          bindToChannelEvent(eventName)
        }
      })
      .addCase(createBulkShipmentOrder.rejected, (state) => {
        state.loading = { ...state.loading, createBulkShipmentOrder: false }
        state.error = { ...state.error, createBulkShipmentOrder: true }
      })
      .addCase(syncOrderStatus.pending, (state) => {
        state.loading = { ...state.loading, syncOrderStatus: true }
      })
      .addCase(syncOrderStatus.fulfilled, (state, action) => {
        state.loading = { ...state.loading, syncOrderStatus: false }
        state.success = { ...state.success, syncOrderStatus: action.payload.data }
        state.error = { ...state.error, syncOrderStatus: false }
      })
      .addCase(syncOrderStatus.rejected, (state) => {
        state.loading = { ...state.loading, syncOrderStatus: false }
        state.error = { ...state.error, syncOrderStatus: true }
        state.success = { ...state.success, syncOrderStatus: false }
      })
      .addCase(getStoOrderLogs.pending, (state) => {
        state.loading = { ...state.loading, getStoOrderLogs: true }
        state.success = { ...state.success, getStoOrderLogs: false }
        state.error = { ...state.error, getStoOrderLogs: false }
      })
      .addCase(getStoOrderLogs.fulfilled, (state, action) => {
        state.loading = { ...state.loading, getStoOrderLogs: false }
        state.success = { ...state.success, getStoOrderLogs: true }
        state.error = { ...state.error, getStoOrderLogs: false }
        state.stoLogs = action.payload
      })
      .addCase(getStoOrderLogs.rejected, (state) => {
        state.loading = { ...state.loading, getStoOrderLogs: false }
        state.success = { ...state.success, getStoOrderLogs: false }
        state.error = { ...state.error, getStoOrderLogs: true }
      })

      // get seller skus with sku inventory in the hub
      .addCase(getSellerSkusWithInventory.pending, (state) => {
        state.loading = { ...state.loading, getSellerSkusWithInventory: true }
        state.error = { ...state.error, getSellerSkusWithInventory: false }
        state.success = { ...state.success, getSellerSkusWithInventory: false }
      })
      .addCase(getSellerSkusWithInventory.fulfilled, (state, action) => {
        state.loading = { ...state.loading, getSellerSkusWithInventory: false }
        state.success = { ...state.success, getSellerSkusWithInventory: true }
        state.sellerSkuList = action.payload?.data
      })

      .addCase(getSellerSkusWithInventory.rejected, (state) => {
        state.loading = { ...state.loading, getSellerSkusWithInventory: false }
        state.error = { ...state.error, getSellerSkusWithInventory: true }
      })
      .addCase(getSalesmen.pending, (state) => {
        state.loading = { ...state.loading, getSalesmen: true }
        state.success = { ...state.success, getSalesmen: false }
        state.error = { ...state.error, getSalesmen: false }
        state.salesPersons = {}
      })
      .addCase(getSalesmen.fulfilled, (state, action) => {
        state.loading = { ...state.loading, getSalesmen: false }
        state.success = { ...state.success, getSalesmen: true }
        state.salesPersons = action.payload
        state.salesmanMetaData = action.payload.meta
      })
      .addCase(getSalesmen.rejected, (state) => {
        state.loading = { ...state.loading, getSalesmen: false }
        state.error = { ...state.error, getSalesmen: true }
        state.salesPersons = {}
      })
      .addCase(createSalesman.pending, (state) => {
        state.loading = { ...state.loading, createSalesman: true }
        state.success = { ...state.success, createSalesman: false }
        state.error = { ...state.error, createSalesman: false }
      })
      .addCase(createSalesman.fulfilled, (state) => {
        state.loading = { ...state.loading, createSalesman: false }
        state.success = { ...state.success, createSalesman: true }
        // state.salesPersons = action.payload
      })
      .addCase(createSalesman.rejected, (state) => {
        state.loading = { ...state.loading, createSalesman: false }
        state.error = { ...state.error, createSalesman: true }
      })
      .addCase(editSalesman.pending, (state) => {
        state.loading = { ...state.loading, editSalesman: true }
        state.success = { ...state.success, editSalesman: false }
        state.error = { ...state.error, editSalesman: false }
      })
      .addCase(editSalesman.fulfilled, (state) => {
        state.loading = { ...state.loading, editSalesman: false }
        state.success = { ...state.success, editSalesman: true }
        
      })
      .addCase(editSalesman.rejected, (state) => {
        state.loading = { ...state.loading, editSalesman: false }
        state.error = { ...state.error, editSalesman: true }
      })
      .addCase(getSalesmanAddress.pending, (state) => {
        state.loading = { ...state.loading, getSalesmanAddress: true }
        state.success = { ...state.success, getSalesmanAddress: false }
        state.error = { ...state.error, getSalesmanAddress: false }
      })
      .addCase(getSalesmanAddress.fulfilled, (state, action) => {
     
        state.loading = { ...state.loading, getSalesmanAddress: false }
        state.success = { ...state.success, getSalesmanAddress: true }
        state.salesmanAddress = action.payload
      })
      .addCase(getSalesmanAddress.rejected, (state) => {
        state.loading = { ...state.loading, getSalesmanAddress: false }
        state.error = { ...state.error, getSalesmanAddress: true }
      })  
    
  }
})

export const {
  handleOrderDetailsSideSlider,
  getSellerID,
  clearOrdersData,
  clearSingleOrderDetailsData,
  clearPackingData,
  clearPackingOrderDetails,
  getOrderIdForCreatingShipment,
  clearConsolidationData,
  clearPickingData,
  alreadyAddedCreateOrderItems,
  addCreateOrderItems,
  removeCreateOrderItemFromIndex,
  updateQtyOfCreateOrderItemAtIndex,
  applyExpiryStatus,
  clearSellerSkuList,
  clearSellerSkusForCreateOrder,
  clearOrderLogs,
  clearCreateOrderItems,
  updateCreateOrderStatus,
  setUploadFilesInStore,
  cleanupGetPicklistsOfStatus,
  cleanupGetPicklistsInZone,
  cleanupPicklistDetails,
  clearPickingDetail,
  setConsolidatedSelectedRowData,
  setConsolidationMeta,
  selectedSellerDispatch,
  selectedOrderTagDispatch,
  cleanupAvailableInventoryForPacking,
  clearShippingDetailData,
  clearShipmentDetailData,
  resetCancelOrder,
  resetApproveStoRequest,
  resetChangedOrderStatus,
  resetSerilisedPickingLocationDetail,
  resetManifestStates,
  setManifestStatusAsCreated,
  setDeliverySlotFromTime,
  setDeliverySlotToTime,
  SetcompletedWaveState,
  resetShippingOptionsData,
  resetSuccess,
  setSuccess,
  cleanupPicklistSidebarFilterData,
  setSelectedZone,
  setPicklistStatus,
  setShippingPartnerFilterValue,
  setUploadStoFilesInStore,
  clearUploadStoFileFromStore,
  clearStoDetail,
  resetUpdateStoRequest,
  resetAddNewSkuInStoRequest,
  resetVirtualHubCreated,
  resetCancelStoRequest,
  resetCreateStoRequest,
  clearCustomerAddress,
  clearCustomerData,
  clearInvoiceData,
  updateUnitPriceOfCreateOrderItem,
  updateTaxPercentOfCreateOrderItem,
  clearGetPrioritisedShippingPartners,
  clearAllStoData,
  updatepackingOrderDetails,
  cleanupSalesData,
  clearSpecificPackingConfiguration,
  setPicklistState,
  clearCustomerList,
  clearCustomerAddresses,
  resetError,
  updateCreateOrderItemWiseWeightValue,
  updateCreateOrderItemWiseNoteValue,
  updateCreatedOrderTotalWeight,
  clearWmsOrderDetailsData,
  setCustomerUploadedFile,
  cleanupCustomerUploadedFile,
  cleanupCustomerBulkResponse,
  clearSingleSplitOrderDetails,
  clearCartonData,
  clearPalletData,
  clearPackingListForPrint,
  clearSingleSalesState,
  clearCreateShipmentDetails,
  clearAwbDataForPrint,
  clearHubsDetailsForShipmentOrder,
  clearShipmentOrderLogs,
  clearEditOrderWave,
  cleanUpStoDelivery,
  updateOrderView,
  updatePrefilledWeight,
  updatePrefilledNumberOfPackages
} = salesSlice.actions

export default salesSlice.reducer

//************** async functions *************
export const getShippingPartnerAsyncData = async (
  search,
  _loadedOptions,
  { params }
) => {
  const allParams = {...params,  name_filter: search}
  try {
    const response = await axiosInstance.get('/api/v1/shipping/shipping_partners', {params: allParams})
    let data = response?.data?.data
    data = [{tag: 'manual', name: 'Manual', id: 'manual' }, ...data]
    return {
      options: data.map((ele) => ({
        id: ele.id,
        value: ele.tag,
        logo:  ele.logo,
        label: ele.name
      })),
      hasMore: response?.data?.meta?.current_page < response?.data?.meta?.last_page,
      additional: {
        page: response?.data?.meta?.current_page + 1
      }
    }
  } catch (error) {
    console.warn(error)
    // Handle the 403 error or other errors that may occur during the API request
    return {
      options: [],
      hasMore: false,
      additional: {
        params
      }
    }
  }
}

export const getShippingPartnerAsyncDataValueAsTag = async (
  search,
  _loadedOptions,
  { params, isManualHidden }
) => {
  const allParams = {...params,  name_filter: search}
  try {
    const response = await axiosInstance.get('/api/v1/shipping/shipping_partners', {params: allParams})
    const data = response?.data?.data
    if (!isManualHidden) {
      data.unshift({ id: 'manual', tag: 'manual', name: 'Manual' })
    }
    return {
      options: data.map((ele) => ({
        value: ele.tag,
        label: ele?.name,
        ...ele
      })),
      hasMore: response?.data?.meta?.current_page < response?.data?.meta?.last_page,
      additional: {
        page: response?.data?.meta?.current_page + 1
      }
    }
  } catch (error) {
    console.warn(error)
    // Handle the 403 error or other errors that may occur during the API request
    return {
      options: [],
      hasMore: false,
      additional: {
        params,
        isManualHidden
      }
    }
  }
}

// courierPartner async
export const getCourierPartnerAsyncData = async (
  search,
  _loadedOptions,
  { page }
) => {
  try {
    const params = { name_filter: search || undefined, page }
    const response = await axiosInstance.get('/api/v1/shipping/courier_partners', { params })
    const data = response?.data
    const defaultOption =  [{value: 'manual', label: 'Manual', id: 'manual' }]
    const courierPartnerOptionsData = data.data.map((ele) => ({
      id: ele.id,
      value: ele.tag,
      logo: ele.logo,
      label: ele.name
    }))
    return {
      options: courierPartnerOptionsData.length > 0 ? (page === 1 ? [...defaultOption, ...courierPartnerOptionsData] : [...courierPartnerOptionsData]) : [],
      hasMore: data?.meta?.current_page < data?.meta?.last_page,
      additional: {
        page: data?.meta?.current_page + 1
      }
    }
  } catch (error) {
    console.warn(error)
    // Handle the 403 error or other errors that may occur during the API request
    return {
      options: [],
      hasMore: false,
      additional: {
        page
      }
    }
  }
}

// countries async data
export const getCountriesAsyncData = async (
  search,
  _loadedOptions,
  { page = 1, ...options }
) => {
  try {
    const response = await axiosInstance.get('/api/v1/tenant/countries', {
      params: { search_column: 'name', search_query: search, page }
    })
    const data = response?.data
    return {
      options: data?.data?.map((ele) => ({
        value: options.isValueString ? `${ele.id}` : ele.id,
        label: ele.name,
        code: ele.iso2,
        currenyCode:ele.currency.code
      })),
      hasMore: page < data?.meta?.last_page,
      additional: {
        page: data?.meta?.current_page + 1
      }
    }
  } catch (error) {
    console.warn(error)
    // Handle the 403 error or other errors that may occur during the API request
    return {
      options: [],
      hasMore: false,
      additional: {
        page,
        options
      }
    }
  }
}
// cities async data
export const getCitiesAsyncData = async (
  search,
  _loadedOptions,
  { page = 1, country_id }
) => {
  try {
   
    
    const response = await axiosInstance.get('/api/v1/tenant/cities', {
      params: { country_id, search_column: 'name', search_query: search, page }
    })
    const data = response?.data
    return {
      options: data?.data?.map((ele) => ({
        value: ele.id,
        label: ele.name
      })),
      hasMore: page < data?.meta?.last_page,
      additional: {
        page: data?.meta?.current_page + 1,
        country_id
      }
    }
  } catch (error) {
    console.warn(error)
    // Handle the 403 error or other errors that may occur during the API request
    return {
      options: [],
      hasMore: false,
      additional: {
        page
      }
    }
  }
}

export const getStatesCitiesAsync = async (search = '', _loadedOptions, { state, page }) => {
  try {
    const params = search ? { search_column: 'name', search_query: search } : { page }
    const res = await axiosInstance.get(`/api/v1/tenant/states/${state.value}/cities`, { params })
 
    return {
      options: res.data.data.map((city) => ({
        value: city.id,
        label: city.name
      })),
      hasMore: res?.data?.meta?.current_page < res?.data?.meta?.last_page,
      additional: {
        page: res?.data?.meta?.current_page + 1,
        state
      }
    }
  } catch (error) {
    console.warn(error)
    // Handle the 403 error or other errors that may occur during the API request
    return {
      options: [],
      hasMore: false,
      additional: {
        state, page
      }
    }
  }
}

export const getStatesAsync = async (search, _loadedOptions, { country, page }) => {
  
  try {
    const params = search ? { search_column: 'name', search_query: search, page } : { page }
    const res = await axiosInstance.get(`/api/v1/tenant/countries/${country?.value}/states`, { params })
   
    return {
      options: res.data.data.map((state) => ({
        value: state.id,
        label: state.name,
        code:state.iso2
      })),
      hasMore: res?.data?.meta?.current_page < res?.data?.meta?.last_page,
      additional: {
        page: res?.data?.meta?.current_page + 1,
        country
      }
    }
  } catch (error) {
    console.warn(error)
    // Handle the 403 error or other errors that may occur during the API request
    return {
      options: [],
      hasMore: false,
      additional: {
        country, page
      }
    }
  }
}
// get async Seller data
export const getSellersAsync = async (search = '', _loadedOptions, { page = 1 }) => {
  const params = search ? { search_column: 'name', search_query: search } : { page }
  try {
    const res = await axiosInstance.get('/api/v1/tenant/sellers', { params: { ...params, sort: 'name_asc', is_active: true } })
    const arr = res?.data?.data
    return {
      options: arr.map((seller) => ({
        value: seller.id,
        label: seller.name
      })),
      hasMore: res?.data?.meta?.current_page < res?.data?.meta?.last_page,
      additional: {
        page: res?.data?.meta?.current_page + 1
      }
    }
  } catch (error) {
    console.warn(error)
    // Handle the 403 error or other errors that may occur during the API request
    return {
      options: [],
      hasMore: false,
      additional: {
        page
      }
    }
  }
}

// get async Order Tags data
export const getOrderTagsAsync = async (search = '', _loadedOptions, { page = 1 }) => {
  const params = search ? { name: search } : { page }
  delete params?.page
  delete params?.per_page
  try {
    const res = await axiosInstance.get('/api/v1/oms/orders/tags', { params })
    const arr = res?.data?.data
    // arr.unshift({ id: 'all', name: 'All' })
    return {
      options: arr.map((tags) => ({
        value: tags.name,
        color: tags.color,
        label: tags.name
      })),
      hasMore: res?.data?.meta?.current_page < res?.data?.meta?.last_page,
      additional: {
        page: res?.data?.meta?.current_page + 1
      }
    }
  } catch (error) {
    console.warn(error)
    // Handle the 403 error or other errors that may occur during the API request
    return {
      options: [],
      hasMore: false,
      additional: {
        page
      }
    }
  }
}

export const getHubsAsyncDataSTO = async (search, _loadedOptions, { page = 1 }) => {
  try {
    const response = await axiosInstance.get('/api/v1/wms/hubs', { params: { search_column: 'name', search_query: search, page } })
    const data = response?.data
    const modiFiedData = data?.data?.map(ele => ({
      value: ele.id,
      label: ele.name,
      address: ele.address,
      phone: ele.phone_number,
      email: ele.email
    }))
    const isHasMore = page < data?.meta?.last_page
    return {
      options: modiFiedData,
      hasMore: isHasMore,
      additional: {
        page: data?.meta?.current_page + 1
      }
    }
  } catch (error) {
    console.warn(error)
    // Handle the 403 error or other errors that may occur during the API request
    return {
      options: [],
      hasMore: false,
      additional: {
        page
      }
    }
  }
}
export const getSourceHubsAsyncDataSTO = async (search, _loadedOptions, { page = 1, hasCreateHubPermission }) => {
  try {
    const response = await axiosInstance.get('/api/v1/oms/hubs/all', { params: { search_column: 'name', search_query: search, page } })
    const data = response?.data
    const modiFiedData = data?.data?.map(ele => ({
      value: {
        id: ele.id,
        type: ele.type,
        code: ele.hub_code
      },
      label: ele.name,
      address: ele.address
    }))
    const isHasMore = page < data?.meta?.last_page
    if (!isHasMore && hasCreateHubPermission) {
      modiFiedData.push({ value: 'add_new_virtual_source', label: '+ Add New Source', type: 'virtual' })
    }
    return {
      options: modiFiedData,
      hasMore: isHasMore,
      additional: {
        page: data?.meta?.current_page + 1,
        hasCreateHubPermission
      }
    }
  } catch (error) {
    console.warn(error)
    // Handle the 403 error or other errors that may occur during the API request
    return {
      options: [],
      hasMore: false,
      additional: {
        page,
        hasCreateHubPermission
      }
    }
  }
}

export const getDestinationHubsAsyncDataSTO = async (search, _loadedOptions, { page = 1, hasCreateHubPermission }) => {
  try {
    const response = await axiosInstance.get('/api/v1/oms/hubs/all', { params: { search_column: 'name', search_query: search, page } })
    const data = response?.data
    const modiFiedData = data?.data?.map(ele => ({
      value: {
        id: ele.id,
        type: ele.type,
        code: ele.hub_code
      },
      label: ele.name,
      address: ele.address
    }))
    const isHasMore = page < data?.meta?.last_page
    if (!isHasMore && hasCreateHubPermission) {
      modiFiedData.push({ value: 'add_new_virtual_destination', label: '+ Add New Destination', type: 'virtual' })
    }
    return {
      options: modiFiedData,
      hasMore: isHasMore,
      additional: {
        page: data?.meta?.current_page + 1,
        hasCreateHubPermission
      }
    }
  } catch (error) {
    console.warn(error)
    // Handle the 403 error or other errors that may occur during the API request
    return {
      options: [],
      hasMore: false,
      additional: {
        page,
        hasCreateHubPermission
      }
    }
  }
}

// export const getCourierPartners = async (search, loadedOptions, { page = 1, ...body }) => {
//   try {
//     const response = await axiosInstance.post(`/api/v1/shipping/shipping_partners/torod`, body)
//     const data = response?.data

//     return {
//       options: data?.data?.map(ele => ({
//         value: ele,
//         label: ele.name
//       })),
//       // hasMore: page < data?.meta?.last_page,
//       additional: {
//         page: page + 1,
//         body
//       }
//     }
//   } catch (error) {
//     // Handle the 403 error or other errors that may occur during the API request
//     return {
//       options: [],
//       hasMore: false,
//       additional: {
//         page
//       }
//     }
//   }
// }

export const getAsyncSellerSalesChannel = async (search = '', _loadedOptions, { page = 1, seller }) => {
  const params = search ? { search_column: 'ssc_name', search_query: search } : null
  if (!seller) {
    return {
      options: [],
      hasMore: false,
      additional: {
        page,
        seller
      }
    }
  }
  try {
    const response = await axiosInstance.get(`/api/v1/sales_channels/sellers/${seller}/sales_channels`, { params: { ...params } })
    const data = response?.data
    return {
      options: data?.data?.map(ele => ({
        value: ele.id,
        label: ele.name,
        logo: ele.sales_channel_logo
      })),
      additional: {
        page,
        seller
      }
    }
  } catch (error) {
    console.warn(error)
    // Handle the 403 error or other errors that may occur during the API request
    return {
      options: [],
      hasMore: false,
      additional: {
        page
      }
    }
  }
}

export const getUserHubsAsync = async (search, _loadedOptions, { page }) => {
  const params = search ? { page, search_column: 'name', search_query: search } : { page }
  const defaultoption = { label: 'All', value: 'all' }
  try {
    const response = await axiosInstance.get('/api/v1/wms/hubs', { params })
    const data = response?.data
    const allUserHubs = data?.data?.map(ele => ({
      value: ele.id,
      label: ele.name
    }))
    return {
      options: (page === 1 ? [defaultoption, ...allUserHubs] : [...allUserHubs]),
      hasMore: data?.meta?.current_page < data?.meta?.last_page,
      additional: {
        page: data?.meta?.current_page + 1
      }
    }
  } catch (error) {
    console.warn(error)
    // Handle the 403 error or other errors that may occur during the API request
    return {
      options: [defaultoption],
      hasMore: false,
      additional: {
        page
      }
    }
  }
}

//getCourier partner
export const getCouriersPartners = async (search, _loadedOptions, { page = 1, ...body }) => {
  if (!body.payment_type) delete body.payment_type
  try {
    const params = { name_filter: search, page }
    const response = await axiosInstance.post('/api/v1/shipping/courier_partners', body, { params })
    const data = response?.data?.data
    const meta = response?.data?.meta
    
    if (body.shipping_partner_tag === 'manual') data.unshift({ id: 'manual', tag: 'manual', name: 'Manual', logo: '', status: 'active' })
    return {
      options: data?.map(ele => ({
        value: ele,
        label: ele.name
      })),
      hasMore: meta?.current_page < meta?.last_page,
      additional: {
        page: meta?.current_page + 1,
        body
      }
    }
  } catch (error) {
    console.warn(error)
    // Handle the 403 error or other errors that may occur during the API request
    return {
      options: [],
      hasMore: false,
      additional: {
        page
      }
    }
  }
}

export const getPackingReasonsForDropdown = async () => {
  try {
    const res = await axiosInstance.get('/api/v1/wms/reasons?reason_type=packing')
    const data = res?.data?.data
    return {
      options: data?.map(item => ({ value: item.id, label: item.title })),
      hasMore: false
    }
  } catch (error) {
    console.warn(error)
    return {
      options: [],
      hasMore: false
    }
  }
}   
export const getAsyncVillages = async (name_filter, _loadedOptions, { page = 1, shipping_partner_tag = 'drb' }) => {
  try {
    const params = { name_filter,  shipping_partner_tag, page }
    const response = await axiosInstance.get('/api/v1/shipping/villages', { params })
    const data = response.data?.data
    return {
      options: data?.map(ele => {
        return {        
          value: ele.id,
          label: ele.name_en,
          ...ele                                              
        }
      }),
      hasMore: page < response.data.meta?.last_page,
      additional: {
        page: page + 1,
        shipping_partner_tag
      }
    }
  } catch (error) {
    console.warn(error)
    // Handle the 403 error or other errors that may occur during the API request
    return {
      options: [],
      hasMore: false,
      additional: {
        page
      }
    }
  }
}

export const getPickersListAsyncData = async (_search, _loadedOptions, { page, hub_id }) => {
  try {
    const response = await axiosInstance.get(`/api/v1/tenant/hubs/${hub_id}/pickers`, { params: { page } })
    const data = response?.data
    return {
      options: data?.data?.map(ele => ({
        value: ele.id,
        label: ele.name
      })),
      hasMore: page < data?.meta?.last_page,
      additional: {
        page: page + 1,
        hub_id
      }
    }
  } catch (error) {
    console.warn(error)
    return {
      options: [],
      hasMore: false,
      additional: {
        page
      }
    }
  }
}
export const getNearbyPointsAsyncData = async (_search, _loadedOptions, body) => {
  try {
    const url = getApiUrl(API_ENDPOINTS.SHIPPING.GET_NEARBY_POINTS)
    const response = await axiosInstance.post(url,body)
    const data = response?.data
    return {
      options: data?.data?.nearby_points?.map(ele => ({
        value: ele.point_id,
        label: `${ele.point_name} (${ele.point_type})`,
        ...ele
      })),
      additional: {
        body
      }
     
      
    }
  } catch (error) {
    console.warn(error)
    return {
      additional: {
        body
      }
    }
  }
}

export const getSellersAsyncData = async (search, _loadedOptions, { page }) => {
  try {
    const response = await axiosInstance.get('/api/v1/tenant/sellers', { params: { search_column: 'name', search_query: search, page, sort: 'name_asc', is_active: true } })
    const data = response?.data
    const options = [{ label: 'All Sellers', value: 'all' }].concat(
      data?.data?.map(ele => ({
        value: ele.id,
        label: ele.name,
        code: ele.code
      }))
    )
    return {
      options,
      hasMore: page < data?.meta?.last_page,
      additional: {
        page: page + 1
      }
    }
  } catch (error) {
    console.warn(error)
    // Handle the 403 error or other errors that may occur during the API request
    console.error('Error fetching sellers:', error)
    return {
      options: [{label: 'All Sellers', value: 'all' }],
      hasMore: false,
      additional: {
        page
      }
    }
  }
}
export const getBoxesAsyncData = async (search, _loadedOptions, { page, hubId }) => {
  try {
    const response = await axiosInstance.get(`/api/v1/wms/hubs/${hubId}/boxes`, { params: { search_column: 'name', search_query: search, page } })
    const data = response?.data
    return {
      options: data?.data?.map(ele => ({
        value: ele.id,
        label: ele.name
      })),
      hasMore: page < data?.meta?.last_page,
      additional: {
        page: page + 1,
        hubId
      }
    }
  } catch (error) {
    console.warn(error)
    // Handle the 403 error or other errors that may occur during the API request
    console.error('Error fetching Boxes:', error)
    return {
      options: [],
      hasMore: false,
      additional: {
        page, 
        hubId
      }
    }
  }
}

export const loadCityMappings = async (name_filter, _loadedOptions, { page = 1, shipping_partner_id, tenant_mapped, omniful_country_id}) => {
  try {
    const params = { name_filter, page, tenant_mapped, omniful_country_id }
    const response = await axiosInstance.get(`/api/v1/shipping/shipping_partners/${shipping_partner_id}/tenant_shipping_partner_cities`, { params })
    const data = response.data?.data

    return {
      options: data?.map((ele) => {
        return {
          value: ele.city_mapping_id,
          label: ele.shipping_partner_city_name,
          ...ele
        }
      }),
      hasMore: page < response.data.meta?.last_page,
      additional: {
        page: page + 1,
        shipping_partner_id,
        tenant_mapped,
        omniful_country_id
      }
    }
  } catch (error) {
    console.warn(error)
    // Handle the 403 error or other errors that may occur during the API request
    return {
      options: [],
      hasMore: false,
      additional: {
        page
      }
    }
  }
}

export const getAsyncParcelShop = async (_name_filter, _loadedOptions, { page = 1, shipping_partner_tag, seller_id, city, country_code, pincode }) => {
  try {
    const params = { page }
    const body = {
      seller_id, 
      drop_details: {
        country_code, 
        pincode,
        city
      }, 
      shipping_partner_tag
    }

    const response = await axiosInstance.post('/api/v1/shipping/parcel_shops', body, { params })

    const data = response.data?.data
    return {
      options: data?.map(ele => {
        return {        
          value: ele.parcel_shop_id,
          label: ele.name,
          ...ele                                              
        }
      }),
      hasMore: page < response.data.meta?.last_page,
      additional: {
        page: page + 1,
        shipping_partner_tag,
        seller_id,
        city,
        country_code,
        pincode
      }
    }
  } catch (error) {
    console.warn(error)
    // Handle the 403 error or other errors that may occur during the API request
    return {
      options: [],
      hasMore: false,
      additional: {
        page
      }
    }
  }
}

export const getReasonsForCancelOrders = async (_search, _loadedOptions, { page = 1, entity_type }) => {
  try {
    const params = { page, entity_type }
    const response = await axiosInstance.get('/api/v1/oms/reasons', { params })
    const data = response?.data.data
    const options = data?.[0].reasons.map(ele => ({
      value: ele,
      label: ele
    }))
    return {
      options: options || [],
      hasMore: page < data?.meta?.last_page,
      additional: {
        page: page + 1,
        entity_type
      }
    }
  } catch (error) {
    console.warn(error)
    // Handle the 403 error or other errors that may occur during the API request
    console.error('Error fetching reasons for order cancel:', error)
    return {
      options: [],
      hasMore: false,
      additional: {
        page
      }
    }
  }
}

// this api return all hubs including virtual hub (virtual + omniful hubs)
export const getOmsHubsAsyncData = async (search, _loadedOptions, { page = 1 }) => {
  try {
    const response = await axiosInstance.get('/api/v1/oms/hubs/all', { params: { search_column: 'name', search_query: search, page } })
    const data = response?.data
    const modiFiedData = data?.data?.map(ele => ({
      value: ele,
      label: ele.name
    }))
    const isHasMore = page < data?.meta?.last_page
    return {
      options: modiFiedData,
      hasMore: isHasMore,
      additional: {
        page: data?.meta?.current_page + 1
      }
    }
  } catch (error) {
    console.warn(error)
    // Handle the 403 error or other errors that may occur during the API request
    return {
      options: [],
      hasMore: false,
      additional: {
        page
      }
    }
  }
}

export const getAsyncSellerShippingChannels = async (search = '', _loadedOptions, { page = 1, seller }) => {

  let params = {page, type:'shipping_only_integration'}
  if (search) {
    params = {...params, search_column: 'ssc_name', search_query: search  }
  }

  try {
    const response = await axiosInstance.get(`/api/v1/sales_channels/sellers/${seller}/sales_channels`, { params })
    const data = response?.data
    if (!data?.data?.length) {
      return {
        options: [],
        hasMore: false,
        additional: {
          page,
          seller
        }
      }
    }
    return {
      options: data?.data?.map(ele => ({
        value: ele.id,
        label: ele.name,
        logo: ele.sales_channel_logo
      })),
      hasMore: page < data?.meta?.last_page,
      additional: {
        page: page + 1,
        seller
      }
    }
  } catch (error) {
    console.warn(error)
    // Handle the 403 error or other errors that may occur during the API request
    return {
      options: [],
      hasMore: false,
      additional: {
        page
      }
    }
  }
}

export const getAsyncShippingEligibleAccountsData = async (search, _loadedOptions, { params, page = 1 }) => {

  const allParams = {...params,  name_filter: search, page }
  try {
    const response = await axiosInstance.get('/api/v1/shipping/partner_shipping_methods', {params: allParams})
    const data = response?.data?.data
    return {
      options: data.map((ele) => ({
        value: ele.id,
        label: `${ele.account_name} (${ele.shipping_partner.name})`,
        logo: ele.shipping_partner.logo,
        ...ele
      })),
      hasMore: response?.data?.meta?.current_page < response?.data?.meta?.last_page,
      additional: {
        page: response?.data?.meta?.current_page + 1,
        params
      }
    }
  } catch (error) {
    console.warn(error)
    // Handle the 403 error or other errors that may occur during the API request
    return {
      options: [],
      hasMore: false,
      additional: {
        params
      }
    }
  }
}
export const getAsyncShippingAccountsAndPartnerData = async (search, _loadedOptions, { params, page = 1 }) => {
  const allParams = {...params,  name_filter: search, page}
  try {
    const response = await axiosInstance.get('/api/v1/shipping/partner_shipping_methods', {params: allParams})
    let data = response?.data?.data
    if (page === 1) data = [{account_name: 'Manual', id: 'manual', shipping_partner:{name:''} }, ...data]
    return {
      options: data.map((ele) => ({
        value: ele.id,
        label: `${ele.account_name} ${ele.shipping_partner.name ? `(${ele.shipping_partner.name})` : ''}`,
      })),
      hasMore: response?.data?.meta?.current_page < response?.data?.meta?.last_page,
      additional: {
        page: response?.data?.meta?.current_page + 1,
        params
      }
    }
  } catch (error) {
    console.warn(error)
    // Handle the 403 error or other errors that may occur during the API request
    return {
      options: [],
      hasMore: false,
      additional: {
        params
      }
    }
  }
}

export const getAllSalesChannel = async (search, _loadedOptions, { page, includeAllData = false }) => {
  try {
    const response = await axiosInstance.get('/api/v2/scs/sales_channels', { params: { search_column: 'name', search_query: search, page } })
    const data = response?.data
    const options = data?.data?.map(ele => ({
      value: ele.tag,
      label: ele.name,
      logo: ele.logo_url,
      ...(includeAllData ? ele : {})
    })) || [];

    return {
      options,
      hasMore: page < data?.meta?.last_page,
      additional: {
        page: page + 1,
        includeAllData
      }
    }
  } catch (error) {
    console.warn(error)
    // Handle the 403 error or other errors that may occur during the API request
    console.error('Error fetching sellers:', error)
    return {
      options: [],
      hasMore: false,
      additional: {
        page,
        includeAllData
      }
    }
  }
}

export const getFreeTags = async (
  search,
  _loadedOptions,
  { page }
) => {
  try {
    const response = await axiosInstance.get(
      '/api/v1/oms/custom_labels',
      { params: { query: search, page }}
    )
    const data = response?.data?.data ?? []
    
    if (search) data.unshift({id: search, name: `${ORDER_FREE_TAGS.ADD_LABELS.label} "${search}"`, tag: 'new_tag'})

    const modifiedData = data?.map((ele) => ({
      value: ele.id,
      label: ele.name,
      tag: ele.tag
    }))

    return {
      options: modifiedData,
      hasMore: page < response?.data?.meta?.last_page,
      additional: {
        page: page + 1
      }
    }
  } catch (error) {
    console.warn(error)
    // Handle the 403 error or other errors that may occur during the API request
    return {
      options: [],
      hasMore: false,
      additional: {
        page
      }
    }
  }
}

export const getFreeTagsForFilter = async (
  search,
  _loadedOptions,
  { page }
) => {
  try {
    const response = await axiosInstance.get(
      '/api/v1/oms/custom_labels',
      { params: { query: search, page }}
    )
    const data = response?.data?.data ?? []
    
    const modifiedData = data?.map((ele) => ({
      value: ele.id,
      label: ele.name,
      tag: ele.tag
    }))

    return {
      options: modifiedData,
      hasMore: page < response?.data?.meta?.last_page,
      additional: {
        page: page + 1
      }
    }
  } catch (error) {
    console.warn(error)
    // Handle the 403 error or other errors that may occur during the API request
    return {
      options: [],
      hasMore: false,
      additional: {
        page
      }
    }
  }
}

export const getAllCustomersAsync = async (
  search,
  _loadedOptions,
  { page, seller_id }
) => {
  try {
    const response = await axiosInstance.get(`${CUSTOMER_PREFIX}/sellers/${seller_id}/customers`, { params: { query: search, page }})
    const data = response?.data?.data ?? []
    
    const modifiedData = data?.map((ele) => ({
      ...ele,
      value: ele.id,
      label: ele.last_name ? `${ele.first_name} ${ele.last_name}` : ele.first_name
    }))

    return {
      options: modifiedData,
      hasMore: page < response?.data?.meta?.last_page,
      additional: {
        page: page + 1,
        seller_id
      }
    }
  } catch (error) {
    console.warn(error)
    // Handle the 403 error or other errors that may occur during the API request
    return {
      options: [],
      hasMore: false,
      additional: {
        page,
        seller_id
      }
    }
  }
}

export const editOrder = ({onSuccess}) => {
  return useApi({
    apiKey: ['edit-order'], 
    isMutation: true,
    apiFn: ({body, order_id}) => {
      const url = getApiUrl(API_ENDPOINTS.ORDERS.EDIT_ORDER, {orderId: order_id})
      return axiosInstance.put(url, body)
    },
    onSuccess: (_, req) => {
      if (onSuccess) onSuccess(req)
    }
  })
}


//async get seller SKUs
export const getAsyncSellerSkus = async (
  search,
  _loadedOptions,
  { seller_id, page, originalItemsData }
  
) => {
  try {
    const response = await axiosInstance.get(
      `/api/v1/products/sellers/${seller_id}/skus`,
      { params: { query: search, type:'simple', page, per_page:20 }}
    )

    const data = response?.data?.data ?? []

    const modifiedData = data?.map((ele) => ({
      id: ele.id,
      name: ele.name,
      sku_code: ele.seller_sku_code,
      image:ele.images?.[0]?.default,
      selling_price:ele.display_selling_price,
      retail_price:ele.display_retail_price,
      disabled: originalItemsData?.some(item => item.seller_sku_id === ele.id),
      ...ele
    }))

    return {
      options: modifiedData,
      hasMore: page < response?.data?.meta?.last_page,
      additional: {
        page: page + 1,
        seller_id
      }
    }
  } catch (error) {
    console.warn(error)
    // Handle the 403 error or other errors that may occur during the API request
    return {
      options: [],
      hasMore: false,
      additional: {
        page
      }
    }
  }
}