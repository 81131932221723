import DropdownWithTitle from '@src/@core/components/ui/dropdown'
import CheckBoxDropdownOption from '@src/@core/components/ui/dropdown/components/checkBoxDropdownOption'
import ErrorNoResultFound from '@src/@core/components/ui/errorNoResultfound'
import NoRecordFound from '@src/@core/components/ui/noRecordFound'
import { ADDITIONAL_FILTER_DROPDOWN_STYLE } from '@src/App.constants'
import { useFilters } from '@src/utility/hooks/useFilters'
import { useQuery } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { DELIVERY_ZONES_STATUS } from '../../constants.hubs'
import { getAllHubsAsyncData, setViewDeliveryZonesScreenData } from '../../store'
import { convertTo12HourFormat, convertTo24HourFormatFromMilitaryTime } from '../../utils/functions'
import { API_KEYS, fetchAllDeliveryZones } from '../HubDeliveryZone/hubDeliveryZone.constant'
import './allDeliveryZones.scss'
import DeliveryZoneCard from './component/DeliveryZoneCard'
import DeliveryZonesMapView from './component/DeliveryZonesMapView'

const AllDeliveryZones = () => {
  const dispatch = useDispatch()
  const viewDeliveryZonesScreen = useSelector(state => state.revampedHubs.viewDeliveryZonesScreenData)
  const {deliveryZonesData, selectedDeliveryZones} = viewDeliveryZonesScreen
  const { filter, setFilter } = useFilters({})
  const [selectedHub, setSelectedHub] = useState(filter.hub)
  // Memoize selectedHubs
  // const selectedHubs = useMemo(() => filter.hub, [filter.hub])

  const { data, isError: isDeliveryZonesError, isFetching: isDeliveryZonesFetching, isSuccess: isDeliveryZonesSuccess} = useQuery({ 
    queryKey: [API_KEYS.FETCH_ALL_DELIVERY_ZONES, filter], 
    queryFn: () => {
      const params = {
        hub_ids: filter.hub?.length ? filter.hub.map(f => f.value) : undefined,
        is_active: filter.status?.value
      }
      return fetchAllDeliveryZones({params})
    },
    select: ({ data }) => {
      if (data?.data?.length) {
        return data.data.map((ele) => {
          return {
            ...ele,
            display_start_time: convertTo12HourFormat(convertTo24HourFormatFromMilitaryTime(ele.start_time)),
            display_end_time: convertTo12HourFormat(convertTo24HourFormatFromMilitaryTime(ele.end_time))
          }
        })
      } else {
        return []
      }
    }
  })

  const filterHandler = ({key, value}) => {
    if (!value) {
      delete filter[key]
      setFilter(filter)
      return
    }
    setFilter({...filter, [key]: value})
  }

  const handleSelectHub = (value) => {
    setSelectedHub(value)
  }

  useEffect(() => {
    const lastSelectedZone = Object.values(selectedDeliveryZones).pop()
    if (lastSelectedZone) {
      document.getElementById(lastSelectedZone.id)?.scrollIntoView({ behavior: 'smooth' })
      const uniqueHubs = {}
      const colors = [
        '#142CDA', // blue
        '#DA1414', // red
        '#DAD214', // yellow
        '#14DA6F', // green
        '#14CEDA',
        '#A614DA',
        '#DA14C6',
        '#DA1473',
        '#14DAC2'
      ]
      Object.values(selectedDeliveryZones).forEach((value, index) => {
        if (!uniqueHubs[value.hub_id]) {
          uniqueHubs[value.hub_id] = {
            label: value.hub_name,
            value: value.hub_id,
            center: {lat: value.hub_address?.latitude || 0, lng: value.hub_address?.longitude},
            color: colors[index % colors.length]
          }
        }
      })
      dispatch(setViewDeliveryZonesScreenData({variableName: 'selectedHubs', value: uniqueHubs}))
    }
  }, [selectedDeliveryZones])

  useEffect(() => {
    if (data?.length) {
      dispatch(setViewDeliveryZonesScreenData({variableName: 'deliveryZonesData', value: data}))
      const newSelectedDeliveryZones = {}
      data.forEach((ele) => {
        if (selectedDeliveryZones[ele.id]) {
          newSelectedDeliveryZones[ele.id] = selectedDeliveryZones[ele.id]
        }
      })
      dispatch(setViewDeliveryZonesScreenData({variableName: 'selectedDeliveryZones', value: newSelectedDeliveryZones}))
    }
    if (isDeliveryZonesSuccess && !data?.length) {
      dispatch(setViewDeliveryZonesScreenData({variableName: 'deliveryZonesData', value: []}))
      dispatch(setViewDeliveryZonesScreenData({variableName: 'selectedDeliveryZones', value: {}}))
    }
    if (isDeliveryZonesError) {
      dispatch(setViewDeliveryZonesScreenData({variableName: 'deliveryZonesData', value: []}))
      dispatch(setViewDeliveryZonesScreenData({variableName: 'selectedDeliveryZones', value: {}}))
    }
  }, [data, isDeliveryZonesError])

  return (
    <div className="p-16px all-delivery-zones">
      <div className="flex-center-between">
        <div className="d-flex gap-16px">
          <DropdownWithTitle
            isAsync 
            isClearable={false} 
            value={selectedHub?.length ? selectedHub : {label: 'All', value: ''}}
            title={'Hub'}
            loadOptionsHandler={getAllHubsAsyncData}
            isMulti
            hideSelectedOptions={false}
            closeMenuOnSelect={false}
            selectOptionHandler={(filterValue) => {
              const values = filterValue?.filter((ele) => ele.value)
              if (values) handleSelectHub(values)
            }}
            onMenuClosed={() => handleSelectHub(filter.hub)}
            externalStyles= {{
              valueContainer: (provided) => ({
                ...provided,
                flexWrap: 'nowrap'
              }),
              option: (provided) => ({
                ...provided,
                cursor: 'pointer',
                fontSize: 12,
                color: 'var(--bs-dark)',
                backgroundColor: '#FFF !important',
                '&:first-child': {
                  padding: 0
                }
              }),
              control: (baseStyles) => ({
                ...baseStyles,
                width: '180px',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                textAlign: 'start',
                border: 'none',
                boxShadow: 'none',
                cursor: 'pointer'
              })
            }}
            additionalComponents={{
              Option: (props) => <CheckBoxDropdownOption 
                filterKey={filter.hub?.value} 
                buttonConfig={{
                  apply: (event) => {
                    filterHandler({key: 'hub', value: selectedHub})
                    event.stopPropagation()
                  },
                  cancel: (event) => {
                    if (selectedHub?.length && filter.hub?.length) {
                      filterHandler({key: 'hub', value: null})
                    } else {
                      handleSelectHub(null)
                    }
                    event.stopPropagation()
                  },
                  disabled: !selectedHub?.length
                }}  
                {...props} 
              />,
              MultiValue: (props) => {
                if (props.selectProps?.inputValue) return null
                return <h2 className="txt-sub-rg text-truncate text-dark m-0 hub-multi-select">{props.getValue().length > 1 ? <div>{props.getValue().length} Hubs Selected</div> : <div className="text-truncate">{props.children}</div>}</h2>
              }
            }}
          />
          <DropdownWithTitle 
            isClearable={!!filter.status} 
            value={filter.status || {label: 'All', value: ''}}
            title={'Status'}
            options={DELIVERY_ZONES_STATUS}
            selectOptionHandler={(value) => filterHandler({key: 'status', value})} 
            externalStyles={ADDITIONAL_FILTER_DROPDOWN_STYLE}
          />
        </div>
        {/* <Button onClick={() => {}}>
          <span className='flex-center-center gap-50'><Plus size={16}/>{t('Create Zone')}</span>
        </Button> */}
      </div>
      
      <div className="py-24px d-flex gap-12px h-100">
        {/* {isDeliveryZonesFetching ? <div className="flex-center-center w-100 h-100"><ComponentSpinner /></div> : <> */}
        {isDeliveryZonesError ? <div className="flex-center-center w-100 h-100"><ErrorNoResultFound /></div> : <>
          {deliveryZonesData?.length === 0 && <div className="flex-center-center w-100 h-100"><NoRecordFound /></div>}
          {deliveryZonesData?.length > 0 && <>
            <div className="width-400 pe-12px d-flex flex-column gap-12px overflow-auto">
              {Object.values(selectedDeliveryZones).map((deliveryZone) => <DeliveryZoneCard 
                key={deliveryZone.id} 
                deliveryZone={deliveryZone} 
                isDeliveryZonesFetching={isDeliveryZonesFetching}
              />)}
              {deliveryZonesData.map((deliveryZone) => !selectedDeliveryZones[deliveryZone.id] && <DeliveryZoneCard 
                key={deliveryZone.id} 
                deliveryZone={deliveryZone} 
                isDeliveryZonesFetching={isDeliveryZonesFetching}
              />)}
            </div>
            <div className="flex-fill rounded-12px overflow-hidden d-flex flex-column map-container">
              <DeliveryZonesMapView/>
            </div>
          </>}
        </>}
        {/* </>} */}
      </div>
    </div>
  )
}

export default AllDeliveryZones