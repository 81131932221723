import classNames from 'classnames'
import PropTypes from 'prop-types'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import './styles.scss'

const CustomTooltip = ({ placement, content, children, isVisible, maxWidth, flip = true, className, style = {}, ...rest }) => {
  const [tooltipVisible, setTooltipVisible] = useState(isVisible)
  const [tooltipPosition, setTooltipPosition] = useState(placement)
  const targetRef = useRef(null)
  const tooltipRef = useRef(null)
  const {t} = useTranslation()

  const adjustTooltipPosition = () => {
    const tooltip = tooltipRef.current
    const tooltipRect = tooltip.getBoundingClientRect()
    const { top, left, width, height } = tooltipRect

    // Calculate tooltip boundaries relative to the viewport
    const tooltipTop = top - window.pageYOffset
    const tooltipLeft = left - window.pageXOffset
    const tooltipRight = tooltipLeft + width
    const tooltipBottom = tooltipTop + height

    // Check if tooltip goes beyond the viewport bounds
    if (tooltipLeft < 0) {
      setTooltipPosition('right')
    } else if (tooltipRight > window.innerWidth) {
      setTooltipPosition('left')
    } else if (tooltipTop < 0) {
      setTooltipPosition('bottom')
    } else if (tooltipBottom > window.innerHeight) {
      setTooltipPosition('top')
    } else {
      setTooltipPosition(placement)
    }
  }

  useEffect(() => {
    if (tooltipVisible && flip) adjustTooltipPosition()
    return () => setTooltipPosition(placement)
  }, [tooltipVisible])

  useEffect(() => {
    if (typeof isVisible !== 'undefined') setTooltipVisible(isVisible)
  }, [isVisible])

  const handleMouseEnter = () => {
    if (typeof isVisible === 'undefined') {
      setTooltipVisible(true)
    }
  }

  const handleMouseLeave = () => {
    if (typeof isVisible === 'undefined') {
      setTooltipVisible(false)
    }
  }

  const tooltipClasses = classNames(`custom-tooltip ${className}`, {
    'custom-tooltip--visible': tooltipVisible,
    [`custom-tooltip--${tooltipPosition}`]: tooltipPosition
  })

  const tooltipStyle = {
    ...style,
    maxWidth,
    '--tooltip-color': style.color,
    zIndex: 100
  }

  return (
    <div
      className="custom-tooltip-container"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      ref={targetRef}
    >
      {children}
      <div ref={tooltipRef} className={tooltipClasses} style={tooltipStyle} {...rest}>
        {t(content)}
      </div>
    </div>
  )
}

CustomTooltip.propTypes = {
  placement: PropTypes.oneOf(['top', 'bottom', 'left', 'right']),
  content: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  isVisible: PropTypes.bool
}

CustomTooltip.defaultProps = {
  placement: 'top'
}

export default CustomTooltip
