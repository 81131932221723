export const STO_STATUS = {
  ALL: {id: 1, name: 'all', key:'all', label:'All'},
  ACCEEPTED: {id: 2, name: 'accepted', key:'accepted', label:'Accepted'},
  CANCELED: {id: 3, name: 'cancelled', key:'cancelled', label:'Cancelled'},
  PENDING_REQUEST: {id: 4, name: 'pending-request', key:'pending', label:'Pending'}
}
export const STO_ORDER_STATUS = (t) => [
  { value: 'new_order', label: t('New Order') },
  { value: 'created', label: t('Created') },
  { value: 'consolidated', label: t('Consolidated') },
  { value: 'picked', label: t('Picked') },
  { value: 'packed', label: t('Packed') },
  { value: 'ready_to_ship', label: t('Ready To Ship') },
  { value: 'shipped', label: t('Shipped') },
  { value: 'delivered', label: t('Delivered') },
  { value: 'on_hold', label: t('On Hold') },
  { value: 'cancelled', label: t('Cancelled') }
]
export const CREATE_BULK_STO_SAMPLE_LINK = 'https://omniful-staging-uploads.s3.eu-central-1.amazonaws.com/orders/bulk-sto/STO+-+Create.csv'

export const REQUEST_TYPE = {
  PO: {id:1, name:'Purchase Order', value:'purchase_order' },
  STO: {id:1, name:'Stock Transfer', value:'stock_transfer_order' }
}

export const STO_MODAL = {
  EDIT: { id: 1, type: 'edit', btn_text: 'Save', modal_heading: 'Edit', disabled: true, name:'EDIT' },
  CREATE: { id: 2, type: 'create', btn_text: 'Create', disabled: false, name:'CREATE'}
}

export const VIRTUAL_HUB = {
  SOURCE: { id: 1, value: 'add_new_virtual_source', name: 'Source', hub_type:'virtual' },
  DESTINATION: { id: 2, value: 'add_new_virtual_destination', name: 'Destination', hub_type:'virtual' }
}

export const SCROLL_DIRECTION = {
  UP: {id:1, name:'UP', value:'up'},
  DOWN:{id:2, name:'DOWN', value:'down'}
}

export const STO_TYPE = {
  STO: 'STO'
}

export const STO_ADD_ITEMS_TYPE = {
  MANUAL : 'MANUAL'
}

export const SHIPMENT_TYPE = {
  CUSTOMER_GENERATED:{key:'CUSTOMER_GENERATED', value:'customer_generated'},
  NOT_REQUIRED:{key:'NOT_REQUIRED', value:'not_required'}
}

export const STO_RECEIVED_STATUS = {
  ORDERED: 'Ordered',
  PARTIAL_RECEIVED: 'Partially Received',
  RECEIVED: 'Received'
}

export const STO_ACTION = {
  CREATE:{key:'create_items'},
  EDIT:{key:'edit_items'},
  ADD:{key:'add_items'}
}

export const STO_SOURCE_STATUS_OPTIONS = [
  { label: 'All', value: 'all' },
  { label: 'New Order', value: 'new_order' },
  { label: 'Consolidated', value: 'consolidated' },
  { label: 'In Picking', value: 'in_picking' },
  { label: 'Picked', value: 'picked' },
  { label: 'Packed', value: 'packed' },
  { label: 'Ready To Ship', value: 'ready_to_ship' },
  { label: 'Shipped', value: 'shipped' },
  { label: 'Delivered', value: 'delivered' },
  { label: 'On Hold', value: 'on_hold' },
  { label: 'Cancelled', value: 'cancelled' },
  { label: 'Partial Return', value: 'partial_return' },
  { label: 'Returned', value: 'returned' },
  { label: 'Return In Progress', value: 'return_in_progress' },
  { label: 'Return To Origin', value: 'return_to_origin' },
  { label: 'Partially Delivered', value: 'partially_delivered' }
]

export const STO_DESTINATION_STATUS_OPTIONS = [
  { label: 'Ordered', value: 'ordered' },
  { label: 'Partially Received', value: 'partially_received' },
  { label: 'Received', value: 'received' }
]

export const STO_TYPES = {
  INT_TO_INT: 'internal_to_internal',
  INT_TO_EXT: 'internal_to_external',
  EXT_TO_INT: 'external_to_internal'
};
  