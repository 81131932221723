import { Status, Wrapper } from '@googlemaps/react-wrapper'
import ComponentSpinner from '@src/@core/components/ui/spinner/Loading-spinner'
import { DEFAULT_MAP_CENTER } from '@src/App.constants'
import { NoMatchesFound } from '@src/assets/data/assets'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import call from '../../../../assets/images/svg/CallLine.svg'
import location from '../../../../assets/images/svg/LocationLine.svg'
import mail from '../../../../assets/images/svg/MailLine.svg'
import { setSelectedHubId } from '../../store'
import { MapComponent } from './MapComponent'

const HubCard = ({ hub, navigate, dispatch, getParticularLocation }) => {
  const { t } = useTranslation()
  const toHubDetails = (hubId) => {
    dispatch(setSelectedHubId(hubId))
    navigate(`/hubs/${hubId}/overview`)
  }

  return (
    <div className="hub-card" onClick={() => getParticularLocation(hub.address.latitude, hub.address.longitude)}>
      <p
        className="card-heading cursor-pointer"
        onClick={() => toHubDetails(hub.id)}
      >
        {hub.hubName}
      </p>
      <div className="card-details-container">
        <p className="card-detail">
          <img src={mail} />
          <div className="d-flex gap-1">
            <span>{t('Hub Code')}:</span>
            <span>{hub.hubCode}</span>
          </div>
        </p>
        <p className="card-detail">
          <img src={location} />
          <span>{hub.hubAddress}</span>
        </p>
        <p className="card-detail">
          <img src={mail} />
          <span>{hub.hubCode}</span>
        </p>
        <p className="card-detail">
          <img src={call} />
          <span>{hub.contactNumber}</span>
        </p>
      </div>
    </div>
  )
}

const MapView = ({ hubsTableData = [], navigate, hubLoading, city }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const [center, setCenter] = useState({lat:DEFAULT_MAP_CENTER.SAUDI_ARBIA.lat, lng: hubsTableData[0]?.address?.longitude || DEFAULT_MAP_CENTER.SAUDI_ARBIA.lng, title: hubsTableData[0]?.hubCountry || DEFAULT_MAP_CENTER.DEFAULT_COUNTRY})
  const zoom = city ? 9 : 3
  // ** Store Vars
  const locations =
    hubsTableData?.length &&
    hubsTableData.map((val) => {
      return {
        lat: val?.address?.latitude,
        lng: val?.address?.longitude,
        title: val?.name
      }
    })
  
  const NoDataAvailable = () => {
    const {t} = useTranslation()
    return (
      <>
        <NoMatchesFound primary_dark={'var(--bs-primary-dark)'} primary_light={'var(--bs-primary-light)'} width={140} height={120} />
        <h4 className='mt-2'>{t('No Matches Found in the Data Universe')}</h4>
      </>
    )
  }

  const mapRender = (status) => {
    switch (status) {
    case Status.LOADING:
      return (
        <div className="d-flex justify-content-center align-content-center">
          <ComponentSpinner /> "{t('Loading')}..."
        </div>
      )
    case Status.FAILURE:
      return <div>{t('Error')}</div>
    default:
      return null
    }
  }

  const getParticularLocation = (lat, lng) => {
    setCenter({ lat: +lat, lng: +lng })
  }

  return (
    <div className="map-view">
      <div className="map-view-card-container">
        
        {hubLoading ? (<div className="d-flex justify-content-center">
          <ComponentSpinner />
        </div>
        ) : hubsTableData.length > 0 ? (
          hubsTableData.map((hub) => (
            <HubCard
              key={hub.id}
              hub={hub}
              navigate={navigate}
              dispatch={dispatch}
              getParticularLocation={getParticularLocation}
            />
          ))
        ) :
          <div className="d-flex flex-column align-items-center justify-content-center mt-5">
            <NoDataAvailable />
          </div>
        }
      </div>
      <div className="w-100 rounded-3 overflow-hidden" style={{height: 'fit-content'}}>
        <Wrapper
          apiKey={import.meta.env.VITE_GOOGLE_MAP_KEY}
          render={mapRender}
          libraries={['places', 'geocoding', 'geometry']}
        >
          <MapComponent getParticularLocation={getParticularLocation} hubs={hubsTableData} city={city} options={{width: '100%', height:'100vh'}} center={{lat:hubsTableData[0]?.address?.latitude || center.lat, lng : hubsTableData[0]?.address?.longitude || center.lng}} zoom={zoom} position={locations ? locations : [{lat: 1241414.23, lng: 2343435.45, title: 'saudi'}]} setLocationName={() => {}} />
        </Wrapper>
      </div>
    </div>
  )
}

export default MapView
