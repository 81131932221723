/* eslint-disable @typescript-eslint/no-explicit-any */
import { axiosInstance } from '@src/network/AxiosInstance';
import { beamManager } from '@src/redux/authentication';
import { checkNotificationPermission, receiveNotificationsFromServiceWorker } from '@src/utility/Utils';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

const notificationPermissionChecker = async ({ user }: { user: any }) => {
  try {
    const notificationPermissionStatus = await checkNotificationPermission();
    if (notificationPermissionStatus.isAllowed) {
      try {
        const response = await axiosInstance.get('/api/v1/oms/configurations/notification');
        const isEnabled = response.data?.data[0]?.configuration_values?.enabled;
        // configuration_values will be null when the user has not set up the settings at least once.
        await beamManager.registerServiceWorker({ tenant_id: user.tenant.id, isEnabled });
        receiveNotificationsFromServiceWorker();
      } catch (error) {
        console.error(error);
      }
    }
  } catch (error) {
    console.error(error);
  }
};

const useNotification = () => {

  const user = useSelector((store: any) => store.auth.userData);

  useEffect(() => {
    if (user?.id) {
      notificationPermissionChecker({ user });
    }
  }, [user]);

};

export default useNotification;