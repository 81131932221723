import Button from '@src/@core/components/ui/button'
import { ExpandableCustomTable } from '@src/@core/components/ui/globalTable/ExpandableCustomTable'
import no_sku_image from '@src/assets/images/omniful/no_sku_image.svg'
import { useMemo } from 'react'
import { X } from 'react-feather'
import { Modal, ModalBody, ModalFooter } from 'reactstrap'


const ItemsListModal = ({ items, isOpen, t, toggle, handleSkuDetailsSidebar, handleBundleSkuDetailsSidebar }) => {

  const handleShowSkuDetails = (row) => {
    if (row.child_order_items?.length > 0) {
      handleBundleSkuDetailsSidebar(row)
    } else {
      handleSkuDetailsSidebar(row)
    }
  }

  const columns = [
    {
      id: 1,
      name: <span className="txt-asst-rg">{t('SKU Details')}</span>,       
      maxWidth: '430px',
      cell: row => {
        const { image, skuName, skuCode } = row

        return <div className='flex-center-start gap-16px pe-30px'>
          <img
            src={image || no_sku_image}
            height={32}
            width={32}
            className='border rounded-4px p-4px border-dark-2'
            style={{ objectFit: 'contain' }}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null
              currentTarget.src = no_sku_image
            }}
          />
          <div className='text-primary max-width-350 width-150-per d-flex flex-column gap-4px'>
            <p title={skuName} className='txt-sub-rg text-truncate cursor-pointer m-0' onClick={() => handleShowSkuDetails(row)}>{skuName}</p>
            <p className='txt-asst-rg text-truncation text-dark-6 m-0'>{skuCode}</p>
          </div>
        </div>
      } 
    },
    {
      id: 2,
      name: (() => <div className='w-100 txt-asst-rg text-end text-truncation pe-8px'>{t('Qty Per Order')}</div>)(),
      key: 'total_qty',
      minWidth: '130px',
      maxWidth: '130px',
      reorder: true,
      cell: ({qty}) => {
        return (
          <div className='w-100 pe-8px'>
            <div className='w-100 txt-sub-rg text-end text-truncation'>{qty}</div>
          </div>
        )
      }
    }
  ]

  const tableData = useMemo(() => {
    return items?.map(item => ({
      ...item,
      ...item.sku,
      skuName: item.sku?.name,
      skuCode: item.sku?.seller_sku_code,
      image: item.sku?.images?.[0]?.url,
      qty: item.display_quantity
    }))
  }, [items])

  const closeModal = () => toggle(p => ({...p, isOpen: false}))

  return <Modal container='__bulk-ship-module' isOpen={isOpen} toggle={closeModal} centered contentClassName="rounded-16px bg-white" modalClassName="modal-max-w-600">
    <div className='flex-start-between p-20px text-dark txt-h1-sb'>
      {t('Items Per Order')}
      <div
        className="h-100 flex-start-start cursor-pointer"
        onClick={closeModal}
      >
        <X size={22} />
      </div>
    </div>
    <ModalBody className='max-height-300 overflow-auto'>
      <div className="">
        <ExpandableCustomTable
          showColumnsTableHeader={false}
          columns={columns || []}
          data={tableData || []}
          TableHeaderComponent={null}
          showPagination={false}
        />
      </div>
    </ModalBody>
    <ModalFooter className="height-80px">
      <Button
        type='button'
        onClick={closeModal}
      >
        {t('Close')}
      </Button>
    </ModalFooter>
  </Modal>
}

export default ItemsListModal