// ** React Imports
import { Suspense, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'

// ** Context Imports
import { bootApiToGetUserDetails } from '@src/redux/authentication'
import { AbilityContext } from '@src/utility/context/Can'
// import { handleVisibilityChange } from '@src/network/RealTime'
import PageTitle from '../page-title/PageTitle'
import ComponentSpinner from '../spinner/Loading-spinner'

const PrivateRoute = ({ children, route }) => {
  // ** Hooks & Vars
  const dispatch = useDispatch()
  const user = JSON.parse(localStorage.getItem('auth'))
  // const hubId = JSON.parse(localStorage.getItem('selectedGlobalHubId'))

  const permissions = useSelector(state => state.auth.permissions)
  const loading = useSelector(state => state.auth.loading)
  const userData = useSelector(state => state.auth.userData)
  const bootApiError = useSelector(state => state.auth.bootApiError)

  const ability = useContext(AbilityContext)

  const pageTitle = route?.title
  // useEffect(() => {
  //   if (!permissions) {
  //     dispatch(getUserProfile)
  //   }
  // }, [])

  // useEffect(() => {
  //   // ! iska kaam ye hai ke boot api ke response aane k baad selected hub ko session storage se match krke set kre store me
  //   // ! if match nhi hota toh default logic kaam krega [0]th index wala set hoga sessionStorage me
  //   // ! onChange: session storage me selectedHub update hoga
  //   // if bootapi response available => 
  //   if (selectedGlobalHubId) {
  //     sessionStorage.setItem("selectedGlobalHubId", selectedGlobalHubId)
  //   }
  // }, [selectedGlobalHubId])

  // document.addEventListener('visibilitychange', handleVisibilityChange)

  if (route) {
    let action = null
    let resource = null
    let restrictedRoute = true
    if (route.meta) {
      action = route.meta.action
      resource = route.meta.resource
      restrictedRoute = route.meta.restricted
    }
    if (!user?.access_token) {
      return <Navigate to="/login" />
    }

    if (user?.access_token && restrictedRoute && !bootApiError) {
      if (!permissions?.length > 0 && !loading && !userData?.name) {
        dispatch(bootApiToGetUserDetails(ability))
        // if (hubId) {
        //   dispatch(getSelectedHubConfiguration(hubId))
        // }
      }

      if (permissions && action && resource && !ability.can(action, resource)) {
        return <Navigate to="/misc/not-authorized" replace />
      }
    }
  }

  // if (!permissions) {
  //   return <Navigate to="/access-control" />
  // }
  // if (user && restrictedRoute && user.role === 'client') {
  //   
  // }
  // if (user && !ability.can(action || 'read', resource)) {
  //   return <Navigate to="/misc/not-authorized" replace />
  // }

  if (permissions) {
    return <Suspense fallback={
      <div className='position-absolute left-0 top-0 right-0 bottom-0 w-100 flex-center-center'>
        <ComponentSpinner />
      </div>
    }>
      <>
        <PageTitle pageTitle={pageTitle} />
        {children}
      </>
    </Suspense>
  }
}

export default PrivateRoute
