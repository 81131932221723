// imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { axiosInstance } from '@src/network/AxiosInstance'
import { getVhOwn, getVhType } from './constants.fleet'
// import CustomToast from '@components/custom-toast/CustomToast'

// Create APIs
/*
 * response -> {data: {}, ...AxiosRelatedKeys}
 * always return response.data //data is the expected object from backend
 * response.data -> {
 *  is_success, data, error
 * }
 *
 * Store state should be like -> {
 *  is_success, data, loading, error
 * }
 */
export const getNationalities = async (search, loadedOptions, { page }) => {
  try {
    const response = await axiosInstance.get('/api/v1/tenant/nationalities', { params: { search_column: 'name', search_query: search, page } })
    const res = response.data
    return {
      options: res?.data?.map(ele => ({
        value: ele.name,
        label: ele.name
      })),
      hasMore: page < res?.meta?.last_page,
      additional: {
        page: page + 1
      }
    }    
  } catch (error) {
    console.error('Error fetching nationality:', error)
    return {
      options: [],
      hasMore: false,
      additional: {
        page
      }
    }
  }
  
}
export const getVehicleOwners = async () => {

  try {
    // const response = await axiosInstance.get(`/api/v1/wms/hubs`, { params: { search_column: 'name', search_query: search, page } })
    const response = await getVhOwn()
    const data = response?.data
    return {
      options: data?.map(ele => ({
        value: ele.value,
        label: ele.label
      })),
      hasMore: false
      // additional: {
      //   page: page + 1
      // }
    }
    // return data
    
  } catch (error) {
    console.error('Error fetching nationality:', error)
    return {
      options: [],
      hasMore: false
      // additional: {
      //   page
      // }
    }
  }
  
}
export const getVehicleTypes = async () => {

  try {
    // const response = await axiosInstance.get(`/api/v1/wms/hubs`, { params: { search_column: 'name', search_query: search, page } })
    const response = await getVhType()
    const data = response?.data
    return {
      options: data?.map(ele => ({
        value: ele.value,
        label: ele.label
      })),
      hasMore: false
      // additional: {
      //   page: page + 1
      // }
    }
    // return data
    
  } catch (error) {
    console.error('Error fetching nationality:', error)
    return {
      options: [],
      hasMore: false
      // additional: {
      //   page
      // }
    }
  }
  
}
export const getDriversFilterOptions = async (search, loadedOptions, { page }) => {
  try {
    const response = await axiosInstance.get('/api/v1/tenant/nationalities', { params: { search_column: 'name', search_query: search, page } })
    const res = response.data
    return {
      options: res?.data?.map(ele => ({
        value: ele.name,
        label: ele.name
      })),
      hasMore: page < res?.meta?.last_page,
      additional: {
        page: page + 1
      }
    }    
  } catch (error) {
    console.error('Error fetching driver:', error)
    return {
      options: [],
      hasMore: false,
      additional: {
        page
      }
    }
  }
    
}
export const getOrderTags = async (search, loadedOptions, { page }) => {
  try {
    const response = await axiosInstance.get('/api/v1/oms/orders/tags', { params: { name: search, page } })
    const res = response.data
    return {
      options: res?.data?.map(ele => ({
        value: ele.name,
        label: ele.name
      })),
      hasMore: page < res?.meta?.last_page,
      additional: {
        page: page + 1
      }
    }    
  } catch (error) {
    console.error('Error fetching tags:', error)
    return {
      options: [],
      hasMore: false,
      additional: {
        page
      }
    }
  }
    
}
export const getCreatedByOptions = async (search, loadedOptions, { page, selectedGlobalHubId }) => {
  try {
    const response = await axiosInstance.get(`/api/v1/tenant/hubs/${selectedGlobalHubId}/users`, { params: { search_column: 'name', search_query: search, page } })
    const res = response.data
    return {
      options: res?.data?.map(ele => ({
        value: ele.id,
        label: ele.name
      })),
      hasMore: page < res?.meta?.last_page,
      additional: {
        page: page + 1
      }
    }    
  } catch (error) {
    console.error('Error fetching driver:', error)
    return {
      options: [],
      hasMore: false,
      additional: {
        page
      }
    }
  }
    
}
// Drivers
export const createNewDriver = createAsyncThunk('/create-new-driver', async ({body}) => {
  const response = await axiosInstance.post('/api/v1/fleet/drivers', body)
  return response.data
})

export const getDrivers = createAsyncThunk('/get-drivers', async ({ selectedGlobalHubId, params }) => {
  const response = await axiosInstance.get('/api/v1/fleet/drivers', { params: { hub_id: selectedGlobalHubId, ...params } })
  return response.data
})

export const getDriverDetails = createAsyncThunk('/get-driver', async ({driverId}) => {
  if (driverId) {
    const response = await axiosInstance.get(`/api/v1/fleet/drivers/${driverId}`)
    return response?.data
  }
})

export const editDriverDetails = createAsyncThunk('/edit-driver-details', async ({driverId, body}) => {
  if (driverId) {
    const response = await axiosInstance.put(`/api/v1/fleet/drivers/${driverId}`, body)
    return response.data
  } 
})

export const deleteDriver = createAsyncThunk('/delete-driver-details', async ({driverId}) => {
  if (driverId) {
    const response = await axiosInstance.delete(`/api/v1/fleet/drivers/${driverId}`)
    return response.data
  }
  
})

// Create and Assign Trip
export const getUnassignedShipmentOrders = createAsyncThunk('/get-unassigned-shipment-orders', async ({ selectedGlobalHubId, params }) => {
  const response = await axiosInstance.get('/api/v1/fleet/drivers/unassigned_shipment_orders', {params: {hub_id: selectedGlobalHubId, ...params}})
  return response?.data
})

export const getNearbyDrivers = createAsyncThunk('/get-nearby-drivers', async ({ selectedGlobalHubId, params}) => {
  const response = await axiosInstance.get(`/api/v1/fleet/drivers/hubs/${selectedGlobalHubId}/nearby_drivers`, {params: {...params, per_page: 10}})
  return response?.data
})
export const getETAInfo = createAsyncThunk('/get-eta-info', async ({ selectedGlobalHubId, params}) => {
  const response = await axiosInstance.get(`/api/v1/fleet/drivers/hubs/${selectedGlobalHubId}/eta`, {params: {...params}})
  return response?.data
})

export const createTrip = createAsyncThunk('/create-trip', async ({ body }) => {
  const response = await axiosInstance.post('/api/v1/fleet/drivers/trips', body)
  return response?.data
})

export const reAssignTrip = createAsyncThunk('/reassign-trip', async ({ body, tripId }) => {
  if (tripId) {
    const response = await axiosInstance.post(`/api/v1/fleet/drivers/trips/${tripId}/assign_driver`, body)
    return response?.data 
  }
  
})
export const cancelTrip = createAsyncThunk('/cancel-trip', async ({id }) => {
  if (id) {
    const response = await axiosInstance.put(`/api/v1/fleet/drivers/trips/${id}`, {id})
    return response?.data 
  }
  
})

// all trips
export const getAllTrips = createAsyncThunk('/get-all-trips', async ({selectedGlobalHubId, params}) => {
  const response = await axiosInstance.get('/api/v1/fleet/drivers/trips', {params: {hub_id: selectedGlobalHubId, ...params}})
  return response?.data
})
// Pending trips
export const getPendingTrips = createAsyncThunk('/get-pending-trips-table-data', async ({selectedGlobalHubId, params}) => {
  const response = await axiosInstance.get('/api/v1/fleet/drivers/trips?status=pending', {params: {hub_id: selectedGlobalHubId, ...params}})
  return response?.data
})
export const getRequestedTrips = createAsyncThunk('/get-requested-trips', async ({selectedGlobalHubId, params}) => {
  const response = await axiosInstance.get('/api/v1/fleet/drivers/trips?status=requested', {params: {hub_id: selectedGlobalHubId, ...params}})
  return response?.data
})
export const getRejectedTrips = createAsyncThunk('/get-rejected-trips', async ({selectedGlobalHubId, params}) => {
  const response = await axiosInstance.get('/api/v1/fleet/drivers/trips?status=rejected', {params: {hub_id: selectedGlobalHubId, ...params}})
  return response?.data
})
export const getAcceptedTrips = createAsyncThunk('/get-accepted-trips', async ({selectedGlobalHubId, params}) => {
  const response = await axiosInstance.get('/api/v1/fleet/drivers/trips?status=accepted', {params: {hub_id: selectedGlobalHubId, ...params}})
  return response?.data
})
// Ongoing trips
export const getOngoingTrips = createAsyncThunk('/get-ongoing-trips', async ({selectedGlobalHubId, params}) => {
  // const response = await axiosInstance.get(`/api/v1/fleet/drivers/trips`, {params: {hub_id: selectedGlobalHubId, ...params}})
  const response = await axiosInstance.get('/api/v1/fleet/drivers/trips?status=ongoing', {params: {hub_id: selectedGlobalHubId, ...params}})
  return response?.data
})
// Completed trips
export const getCompletedTrips = createAsyncThunk('/get-completed-trips', async ({selectedGlobalHubId, params}) => {
  // const response = await axiosInstance.get(`/api/v1/fleet/drivers/trips`, {params: {hub_id: selectedGlobalHubId, ...params}})
  const response = await axiosInstance.get('/api/v1/fleet/drivers/trips?status=completed', {params: {hub_id: selectedGlobalHubId, ...params}})
  return response?.data
})
export const getTrip = createAsyncThunk('/get-trip', async ({tripId}) => {
  if (tripId) {
    const response = await axiosInstance.get(`/api/v1/fleet/drivers/trips/${tripId}`)
    return response?.data
  }
  
})
export const getDriverConfiguration = createAsyncThunk('/get-driver-config', async () => {
  const response = await axiosInstance.get('/api/v1/fleet/drivers/configurations')
  return response?.data
})
export const saveDriverConfiguration = createAsyncThunk('fleet/drivers/put/configurations', async ({body}) => {
  const response = await axiosInstance.put('/api/v1/fleet/drivers/configurations', body)
  return response?.data
})

const initialState = {
  loading: false,
  error: false,
  meta: {},
  hub_co_ordinate: null,
  driverCreation: {
    is_success: false,
    loading: false,
    error: false
  },
  drivers: {
    data: null,
    is_success: false,
    loading: false,
    meta: {}
  },
  driverDetails: {
    data: null,
    is_success: false,
    loading: false,
    error: false
  },
  editDriverDetails: {
    is_success: false,
    loading: false,
    error: false
  },
  driverDeletion: {
    is_success: false,
    loading: false,
    error: false
  },
  driverConfig: {
    isLocal: true,
    data: null,
    is_success: false,
    loading: false,
    error: false
  },
  changeConfig: {
    is_success: false,
    loading: false,
    error: false
  },
  shipmentOrders: {
    data: [],
    loading: false,
    is_success: false,
    error: false,
    meta: {}
  },
  nearbyDrivers: {
    newDrivers: [],
    rejectedDriver: null,
    is_success: false,
    loading: false
  },
  etaInfo: {
    data: null,
    is_success: false,
    loading: false
  },
  assignTrip: {
    is_success: false,
    loading: false,
    is_reassigned: false
  },
  allTrips: {
    data: null,
    is_success: false,
    loading: false,
    error: false
  },
  canceledTrip: {
    loading: false,
    is_success: false
  },
  pendingTrips: {
    data: null,
    is_success: false,
    loading: false,
    error: false
  },
  requestedTrips: {
    data: null,
    is_success: false,
    loading: false,
    error: false
  },
  rejectedTrips: {
    data: null,
    is_success: false,
    loading: false,
    error: false
  },
  acceptedTrips: {
    data: null,
    is_success: false,
    loading: false,
    error: false
  },
  ongoingTrips: {
    data: null,
    is_success: false,
    loading: false,
    error: false
  },
  completedTrips: {
    data: null,
    is_success: false,
    loading: false,
    error: false
  },
  trip: {
    data: null,
    is_success: false,
    loading: false,
    error: false
  }
}

const fleetSlice = createSlice({
  name: 'fleet',
  initialState,
  reducers: {
    setHubsLocation: (state, action) => {
      state.hub_co_ordinate = {...action.payload}
    },
    clearDriversList: (state) => {
      state.drivers = initialState.drivers
    },
    clearSingleDriverDetails: state => {
      state.driverDetails = initialState.driverDetails
    },
    clearDriverCreationStatus: state => {
      state.driverCreation.is_success = false
    },
    clearDriverDeletionStatus: state => {
      state.driverDeletion.is_success = false
    },
    clearTripCancelStatus: state => {
      state.canceledTrip.is_success = false
    },
    clearAssignTripStatus: state => {
      state.assignTrip.is_success = false
      state.assignTrip.is_reassigned = false
    },
    clearTrip: state => {
      state.trip.data = null
    }
  },

  extraReducers: builder => {
    builder.addCase(getDrivers.pending, state => {
      state.drivers.loading = true
      state.drivers.is_success = false
    })
    builder.addCase(getDrivers.fulfilled, (state, action) => {
      state.drivers = {...action.payload}
      state.drivers.loading = false
    })
    builder.addCase(getDrivers.rejected, state => {
      state.drivers = initialState.drivers
    })

    builder.addCase(createNewDriver.pending, state => {
      state.driverCreation.loading = true
      state.driverCreation.is_success = false
    })
    builder.addCase(createNewDriver.fulfilled, (state, action) => {
      state.driverCreation.loading = false
      state.driverCreation = {...action.payload}
    })
    builder.addCase(createNewDriver.rejected, state => {
      state.driverCreation = initialState.driverCreation
    })

    builder.addCase(editDriverDetails.pending, state => {
      state.driverCreation.loading = true
      state.driverCreation.is_success = false
    })
    builder.addCase(editDriverDetails.fulfilled, (state, action) => {
      state.driverCreation.loading = false
      state.driverCreation = {...action.payload}
    })
    builder.addCase(editDriverDetails.rejected, state => {
      state.driverCreation = initialState.driverCreation
    })

    builder.addCase(deleteDriver.pending, state => {
      state.driverDeletion.loading = true
      state.driverDeletion.is_success = false
    })
    builder.addCase(deleteDriver.fulfilled, (state, action) => {
      state.driverDeletion.loading = false
      state.driverDeletion = {...action.payload}
    })
    builder.addCase(deleteDriver.rejected, state => {
      state.driverDeletion = initialState.driverDeletion
    })

    builder.addCase(getDriverDetails.pending, state => {
      state.driverDetails.loading = true
    })
    builder.addCase(getDriverDetails.fulfilled, (state, action) => {
      state.driverDetails.loading = false
      state.driverDetails = {...action.payload}
    })
    builder.addCase(getDriverDetails.rejected, state => {
      state.driverDetails = initialState.driverDetails
    })

    // Delivery Trips
    builder.addCase(getUnassignedShipmentOrders.pending, state => {
      state.shipmentOrders.loading = true
    })
    builder.addCase(getUnassignedShipmentOrders.fulfilled, (state, action) => {
      state.shipmentOrders = {...action.payload}
      state.shipmentOrders.loading = false
    })
    builder.addCase(getUnassignedShipmentOrders.rejected, state => {
      state.shipmentOrders.data = []
      state.shipmentOrders.loading = false
      state.meta = {}
    })

    builder.addCase(getNearbyDrivers.pending, state => {
      state.nearbyDrivers.loading = true
    })
    builder.addCase(getNearbyDrivers.fulfilled, (state, action) => {
      state.nearbyDrivers.newDrivers = action.payload.data?.drivers
      state.nearbyDrivers.rejectedDriver = action.payload.data?.rejected_by_driver
      state.nearbyDrivers.meta = action.payload.meta
      state.nearbyDrivers.is_success = action.payload.is_success
      state.nearbyDrivers.loading = false
    })
    builder.addCase(getNearbyDrivers.rejected, state => {
      state.nearbyDrivers.loading = false
      state.nearbyDrivers = initialState.nearbyDrivers
      state.meta = {}
    })
    builder.addCase(getETAInfo.pending, state => {
      state.etaInfo.loading = true
    })
    builder.addCase(getETAInfo.fulfilled, (state, action) => {
      state.nearbyDrivers.newDrivers = action.payload.data?.drivers
      state.nearbyDrivers.rejectedDriver = action.payload.data?.rejected_by_driver
      state.etaInfo.loading = false
    })
    builder.addCase(getETAInfo.rejected, state => {
      state.etaInfo = initialState.etaInfo
    })

    // New trip
    builder.addCase(createTrip.pending, state => {
      state.assignTrip.loading = true
    })
    builder.addCase(createTrip.fulfilled, (state, action) => {
      state.assignTrip = {...action.payload}
      state.assignTrip.loading = false
      state.assignTrip.is_reassigned = false
    })
    builder.addCase(createTrip.rejected, state => {
      state.assignTrip.loading = false
    })

    // Reassign Trip
    builder.addCase(reAssignTrip.pending, state => {
      state.assignTrip.loading = true
    })
    builder.addCase(reAssignTrip.fulfilled, (state, action) => {
      state.assignTrip = {...action.payload}
      state.assignTrip.loading = false
      state.assignTrip.is_reassigned = true
    })
    builder.addCase(reAssignTrip.rejected, state => {
      state.assignTrip.loading = false
    })

    builder.addCase(cancelTrip.pending, state => {
      state.canceledTrip.loading = true
      state.canceledTrip.is_success = false
    })
    builder.addCase(cancelTrip.fulfilled, (state, action) => {
      state.canceledTrip = {...action.payload}
      state.canceledTrip.loading = false
    })
    builder.addCase(cancelTrip.rejected, state => {
      state.canceledTrip.loading = false
    })

    // all trips
    builder.addCase(getAllTrips.pending, state => {
      state.allTrips.loading = true
    })
    builder.addCase(getAllTrips.fulfilled, (state, action) => {
      state.allTrips = {...action.payload}
      state.allTrips.loading = false
    })
    builder.addCase(getAllTrips.rejected, state => {
      state.allTrips.data = null
      state.allTrips.loading = false
      state.allTrips.meta = {}
    })

    // Requested trips
    builder.addCase(getRequestedTrips.pending, state => {
      state.requestedTrips.loading = true
    })
    builder.addCase(getRequestedTrips.fulfilled, (state, action) => {
      state.requestedTrips = {...action.payload}
      state.requestedTrips.loading = false
    })
    builder.addCase(getRequestedTrips.rejected, state => {
      state.requestedTrips.data = null
      state.requestedTrips.loading = false
      state.requestedTrips.meta = {}
    })

    // Rejected Trips
    builder.addCase(getRejectedTrips.pending, state => {
      state.rejectedTrips.loading = true
    })
    builder.addCase(getRejectedTrips.fulfilled, (state, action) => {
      state.rejectedTrips = {...action.payload}
      state.rejectedTrips.loading = false
    })
    builder.addCase(getRejectedTrips.rejected, state => {
      state.rejectedTrips.data = null
      state.rejectedTrips.loading = false
      state.rejectedTrips.meta = {}
    })
    // Accepted Trips
    builder.addCase(getAcceptedTrips.pending, state => {
      state.acceptedTrips.loading = true
    })
    builder.addCase(getAcceptedTrips.fulfilled, (state, action) => {
      state.acceptedTrips = {...action.payload}
      state.acceptedTrips.loading = false
    })
    builder.addCase(getAcceptedTrips.rejected, state => {
      state.acceptedTrips.data = null
      state.acceptedTrips.loading = false
      state.acceptedTrips.meta = {}
    })
    // Pending trips
    builder.addCase(getPendingTrips.pending, state => {
      state.pendingTrips.loading = true
    })
    builder.addCase(getPendingTrips.fulfilled, (state, action) => {
      state.pendingTrips = {...action.payload}
      state.pendingTrips.loading = false
    })
    builder.addCase(getPendingTrips.rejected, state => {
      state.pendingTrips.data = null
      state.pendingTrips.loading = false
      state.pendingTrips.meta = {}
    })
    // Ongoing trips
    builder.addCase(getOngoingTrips.pending, state => {
      state.ongoingTrips.loading = true
    })
    builder.addCase(getOngoingTrips.fulfilled, (state, action) => {
      state.ongoingTrips = {...action.payload}
      state.ongoingTrips.loading = false
    })
    builder.addCase(getOngoingTrips.rejected, state => {
      state.ongoingTrips.data = null
      state.ongoingTrips.loading = false
      state.ongoingTrips.meta = {}
    })
    // Completed trips
    builder.addCase(getCompletedTrips.pending, state => {
      state.completedTrips.loading = true
    })
    builder.addCase(getCompletedTrips.fulfilled, (state, action) => {
      state.completedTrips = {...action.payload}
      state.completedTrips.loading = false
    })
    builder.addCase(getCompletedTrips.rejected, state => {
      state.completedTrips.data = null
      state.completedTrips.loading = false
      state.completedTrips.meta = {}
    })

    builder.addCase(getTrip.pending, state => {
      state.trip.loading = true
    })
    builder.addCase(getTrip.fulfilled, (state, action) => {
      state.trip = {...action.payload}
      state.trip.loading = false
    })
    builder.addCase(getTrip.rejected, state => {
      state.trip.data = null
      state.trip.loading = false
      state.trip.meta = {}
    })

    builder.addCase(getDriverConfiguration.pending, state => {
      state.driverConfig.loading = true
      state.changeConfig.is_success = false
    })
    builder.addCase(getDriverConfiguration.fulfilled, (state, action) => {
      state.driverConfig = {...action.payload}
      state.driverConfig.loading = false
    })
    builder.addCase(getDriverConfiguration.rejected, state => {
      state.driverConfig.data = null
      state.driverConfig.loading = false
      // state.driverConfig.meta = {}
    })

    builder.addCase(saveDriverConfiguration.pending, state => {
      state.changeConfig.loading = true
      // state.changeConfig.is_success = false
    })
    builder.addCase(saveDriverConfiguration.fulfilled, (state, action) => {
      state.changeConfig = {...action.payload}
      state.changeConfig.loading = false
    })
    builder.addCase(saveDriverConfiguration.rejected, state => {
      state.changeConfig.data = null
      state.changeConfig.loading = false
      // state.driverConfig.meta = {}
    })
  }
})

export const { clearDriversList, clearSingleDriverDetails, clearDriverCreationStatus, clearDriverDeletionStatus, clearTripCancelStatus, clearAssignTripStatus, clearTrip, setHubsLocation } = fleetSlice.actions

export default fleetSlice.reducer