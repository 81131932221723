import { useEffect } from 'react'

const useThemeSetup = (themeData) => {
  useEffect(() => {
    const themeSetup = themeData?.tenant || themeData?.tenant_info

    const root = document.documentElement
    if (!root) return 
    let link = document.querySelector('link[rel~=\'icon\']')

    if (themeSetup?.fav_icon) {   
      if (!link) {
        link = document.createElement('link')
        link.rel = 'icon'
        document.head.appendChild(link)
      }
      link.href = themeSetup.fav_icon
    }

    if (themeSetup?.custom_theme_enabled) {
      Object.entries(themeSetup?.theme || {}).forEach(([key, value]) => {
        root.style.setProperty(`--bs-${key}`, value)
      })
    }

  }, [themeData])

  return useThemeSetup
}

export default useThemeSetup