import { defineAbility } from '@casl/ability'
import { initialAbility } from './initialAbility'

//  Read ability from localStorage
// * Handles auto fetching previous abilities if already logged in user
// ? You can update this if you store user abilities to more secure place
// ! Anyone can update localStorage so be careful and please update this

const ability = defineAbility((can) => {
  initialAbility.map((elem) => {
    can(elem.action, elem.subject)
  })
})

export default ability 

// Previous logic
// const userData = JSON.parse(localStorage.getItem('userData'))
// const existingAbility = userData ? userData.ability : null
// export default new AbilityBuilder(initialAbility)
