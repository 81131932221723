import Button from '@src/@core/components/ui/button'
import { PRINT_MODES } from '@src/App.constants'
import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useReactToPrint } from 'react-to-print'
import { Spinner } from 'reactstrap'
import A4Layout from './a4Layout'
import ThermalLayout from './thermalLayout'

const PrintPosInvoice = (props) => {
  const { printBtnRef, printData, onAfterPrint = () => { } } = props

  const { t } = useTranslation()
  const componentRef = useRef()

  const [printingLoading, setPrintingLoading] = useState(false)

  const InvoiceData = {
    preferences: {
      printLayout: printData.invoice_setting.print_layout || 'a4',
      textSize: printData.invoice_setting.text_size || 'small',
      language: printData.invoice_setting.language || 'en'
    },
    headerSection: {
      company_logo: { id: 'company_logo', label: 'Company Logo', show: printData.invoice_setting.show_logo, value: printData.invoice_setting.logo },
      order_number: { id: 'order_number', label: 'Order Number', show: printData.invoice_setting.show_order_id, value: printData.order_id },
      customer_label: { id: 'customer_label', label: 'Customer Label', show: printData.invoice_setting.show_customer_name, value: printData.customer_details.name },
      customer_phone_number: { id: 'customer_phone_number', label: 'Customer Phone Number', show: printData.invoice_setting.show_customer_number, value: printData.customer_details.phone_number },
      customer_address: { id: 'customer_address', label: 'Customer Address', show: printData.invoice_setting.show_customer_address, value: printData.customer_details.address },
      // Salesman: { id: 'salesman', label: 'Salesman', show: printData.invoice_setting.show_customer_address, value: printData.customer_details.address }
    },
    itemSection: {
      sku_barcode: { id: 'sku_barcode', label: 'SKU Barcode', show: printData.invoice_setting.sku_barcode },
      include_price: { id: 'include_price', label: 'Include Price', show: printData.invoice_setting.show_price },
      total_tax_inclusive: { id: 'total_tax_inclusive', label: 'Total (Tax Inclusive)', show: printData.invoice_setting.total_after_tax },
      total_tax_exclusive: { id: 'total_tax_exclusive', label: 'Total (Tax Exclusive)', show: printData.invoice_setting.total_before_tax },
      tax_amount: { id: 'tax_amount', label: 'Tax Amount', show: printData.invoice_setting.tax_amount },
      tax_percentage: { id: 'tax_percentage', label: 'Tax Percentage', show: printData.invoice_setting.show_tax_percentage },
      discount_amount: { id: 'discount_amount', label: 'Discount Amount', show: printData.invoice_setting.discount_amount }
    },
    footerSection: {
      display_footer_section: { id: 'display_footer_section', label: 'Display Footer Section', show: printData.invoice_setting.display_section_c, value: 'Yes' },
      display_total_items: { id: 'display_total_items', label: 'Display Total Items', show: printData.invoice_setting.display_total_items, value: `${printData.total_item_count} items` },
      hide_discount_if_not_exist: { id: 'hide_discount_if_not_exist', label: 'Hide Discount If Not Exist', show: printData.invoice_setting.hide_discount_if_not_exist, value: 'Yes' },
      sales_note: { id: 'sales_note', label: 'Sales Note', show: printData.invoice_setting.sales_note, value: printData.sales_note || '' },
      comment: { id: 'comment', label: 'Comment', show: printData.invoice_setting.show_comment, value: printData.invoice_setting.comment },
      comment_image: { id: 'comment_image', label: 'Comment Image', show: printData.invoice_setting.show_comment_image, value: printData.invoice_setting.comment_image, url: printData.invoice_setting.comment_image },
      subTotal: printData.sub_total,
      totalDiscounts: printData.discount,
      totalTax: printData.tax_amount,
      totalInclusive: printData.total_with_tax,
      totalItems: printData.total_item_count,
      total: printData.total
    },
    currency: printData.currency,
    items: printData.order_items.map(item => ({
      qty: item.ordered_quantity,
      product_name: item.name,
      sku_barcode: item.sku_code,
      price: item.unit_price,
      discount_amount: item.discount_amount,
      total_tax_inclusive: item.total_price_with_tax,
      total_tax_exclusive: item.total_price,
      tax_amount: item.tax_amount,
      tax_percentage: item.tax_percent,
      tax_lines: item.item_tax.tax_lines,
      compound_tax: item.item_tax.compound_tax
    }))
  }

  // const InvoiceData = {
  //   preferences: {
  //     printLayout: 'a4',
  //     textSize: 'large',
  //     language: 'en'
  //   },
  //   headerSection: {
  //     company_logo: { id: 'company_logo', label: 'Company Logo', show: true, value: printData.invoice_setting.logo },
  //     order_number: { id: 'order_number', label: 'Order Number', show: true, value: printData.order_id },
  //     customer_label: { id: 'customer_label', label: 'Customer Label', show: true, value: printData.billing_details.name },
  //     customer_phone_number: { id: 'customer_phone_number', label: 'Customer Phone Number', show: true, value: printData.billing_details.phone_number },
  //     customer_address: { id: 'customer_address', label: 'Customer Address', show: true, value: printData.billing_details.address }
  //   },
  //   itemSection: {
  //     sku_barcode: { id: 'sku_barcode', label: 'SKU Barcode', show: true },
  //     include_price: { id: 'include_price', label: 'Include Price', show: true },
  //     total_tax_inclusive: { id: 'total_tax_inclusive', label: 'Total (Tax Inclusive)', show: true },
  //     total_tax_exclusive: { id: 'total_tax_exclusive', label: 'Total (Tax Exclusive)', show: true },
  //     tax_amount: { id: 'tax_amount', label: 'Tax Amount', show: true },
  //     tax_percentage: { id: 'tax_percentage', label: 'Tax Percentage', show: true },
  //     discount_amount: { id: 'discount_amount', label: 'Discount Amount', show: true }
  //   },
  //   footerSection: {
  //     display_footer_section: { id: 'display_footer_section', label: 'Display Footer Section', show: true, value: 'Yes' },
  //     display_total_items: { id: 'display_total_items', label: 'Display Total Items', show: true, value: `${printData.total_item_count} items` },
  //     hide_discount_if_not_exist: { id: 'hide_discount_if_not_exist', label: 'Hide Discount If Not Exist', show: true, value: 'Yes' },
  //     sales_note: { id: 'sales_note', label: 'Sales Note', show: true, value: printData.sales_note },
  //     comment: { id: 'comment', label: 'Comment', show: true, value: printData.invoice_setting.comment },
  //     comment_image: { id: 'comment_image', label: 'Comment Image', show: true, value: printData.invoice_setting.comment_image, url: printData.invoice_setting.comment_image },
  //     subTotal: printData.sub_total,
  //     totalDiscounts: printData.discount,
  //     totalTax: printData.tax_amount,
  //     totalInclusive: printData.total_with_tax,
  //     totalItems: printData.total_item_count
  //   },
  //   currency: printData.currency,
  //   items: printData.order_items.map(item => ({
  //     qty: item.ordered_quantity,
  //     product_name: item.name,
  //     sku_barcode: item.sku_code,
  //     price: item.unit_price,
  //     discount_amount: printData.discount,
  //     total_tax_inclusive: item.total_price_with_tax,
  //     total_tax_exclusive: item.total_price,
  //     tax_amount: item.tax_amount,
  //     tax_percentage: item.tax_percent
  //   }))
  // }


  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: `@media print {
      body {
        height: fit-content !important;
        margin: 0;
        padding: 0;
      }
      @page {
        size: ${InvoiceData.preferences.printLayout === PRINT_MODES.A_FOUR.value ? PRINT_MODES.A_FOUR.size : PRINT_MODES.THERMAL.size};
        margin: 0;
        padding: 0;
      }
    }`,
    onBeforeGetContent: () => setPrintingLoading(true),
    onBeforePrint: () => setPrintingLoading(false),
    onAfterPrint: () => onAfterPrint()
  })

  const hideFooterDiscountFiled = InvoiceData.footerSection.hide_discount_if_not_exist.show && InvoiceData.footerSection.totalDiscounts === '0.00'

  return (
    <div className='d-flex gap-1 w-100'>
      {printData && <div >
        {
          InvoiceData.preferences.printLayout === PRINT_MODES.A_FOUR.value
            ? (
              <A4Layout
                ref={componentRef}
                printData={InvoiceData}
                hideFooterDiscountFiled={hideFooterDiscountFiled}
              />
            )
            : (
              <ThermalLayout
                ref={componentRef}
                printData={InvoiceData}
                hideFooterDiscountFiled={hideFooterDiscountFiled}
              />
            )
        }

      </div>}
      <Button
        onClick={handlePrint}
        disabled={printingLoading}
        ref={printBtnRef}
        icon={printingLoading && Spinner}
        iconSize='sm'
      >
        {t('Print Invoice')}
      </Button>
    </div>
  )
}

export default PrintPosInvoice