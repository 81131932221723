const DropPage = ({ width = '53', height = '50' }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 142 92" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.05508 0.810937H140.945V90.8138H1.05508V0.810937Z" fill="white" stroke="#999999" strokeWidth="0.2"/>
      <path d="M127.69 14.0664H99.3452V45.8124H127.69V14.0664Z" fill="#DFDFDF"/>
      <path d="M42.6557 49.2148H14.311V76.4258H42.6557V49.2148Z" fill="#DFDFDF"/>
      <path d="M18.8462 14.0664H14.311V39.0097H18.8462V14.0664Z" fill="#DFDFDF"/>
      <path d="M29.0503 14.0664H24.5151V39.0097H29.0503V14.0664Z" fill="#DFDFDF"/>
      <path d="M117.485 51.4805H112.95V76.4238H117.485V51.4805Z" fill="#DFDFDF"/>
      <path d="M39.2539 14.0664H34.7188V39.0097H39.2539V14.0664Z" fill="#DFDFDF"/>
      <path d="M127.689 51.4805H123.154V76.4238H127.689V51.4805Z" fill="#DFDFDF"/>
      <path d="M55.127 14.0664H50.5918V76.4247H55.127V14.0664Z" fill="#DFDFDF"/>
      <path d="M66.4653 14.0664H61.9302V76.4247H66.4653V14.0664Z" fill="#DFDFDF"/>
      <path d="M77.8032 14.0664H73.2681V76.4247H77.8032V14.0664Z" fill="#DFDFDF"/>
      <path d="M89.1401 14.0664H84.605V76.4247H89.1401V14.0664Z" fill="#DFDFDF"/>
    </svg>

  )
}

export default DropPage