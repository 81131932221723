import classNames from 'classnames'
import { Badge } from 'reactstrap'
import './filterTab.scss'
import { useTranslation } from 'react-i18next'

const FilterTabs = ({ tabDataList, selectedTabId, onTabChange, customBackground = false, customBackgroundColor = false}) => {
  // ** Hooks
  const { t } = useTranslation()
  return (
    <div className={classNames('filter-tab-container', {
      'customBackground ': customBackground,
      'customBackgroundColor ': customBackgroundColor
    })}>
      {
        tabDataList.map((tab, index) => {
          return (
            <button
              key={index}
              className={classNames('btn-tab', {
                active: selectedTabId === tab.id
              })}
              onClick={() => onTabChange(tab)}
            >
              <span>{t(tab.name)}</span>
              {
                tab.tabCount ?
                  <Badge pill className="tab-count">{tab.tabCount}</Badge> : null
              }
            </button>
          )
        })
      }
    </div>
  )
}

export default FilterTabs