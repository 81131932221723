import classNames from 'classnames'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'
import { AsyncPaginate } from 'react-select-async-paginate'
import { InputGroup, InputGroupText, UncontrolledTooltip } from 'reactstrap'
import Close from './Close.svg'
import Dropdown from './DropDown.svg'
import Dropup from './DropUp.svg'
import './styles.scss'

const IsRequiredIcon = (
  <svg width="5" height="5" viewBox="0 0 4 4" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.98852 3.564C1.70052 3.564 1.43652 3.496 1.19652 3.36C0.964516 3.216 0.776516 3.028 0.632516 2.796C0.496516 2.556 0.428516 2.292 0.428516 2.004C0.428516 1.564 0.580516 1.192 0.884516 0.888C1.18852 0.584 1.55652 0.432 1.98852 0.432C2.43652 0.432 2.81252 0.584 3.11652 0.888C3.42052 1.192 3.57252 1.564 3.57252 2.004C3.57252 2.444 3.42052 2.816 3.11652 3.12C2.81252 3.416 2.43652 3.564 1.98852 3.564Z" fill="#C21808" />
  </svg>
)

const DropdownWithTitle = ({
  title,
  options,
  selectOptionHandler,
  defaultValue,
  value,
  isAsync,
  loadOptionsHandler,
  isSearchable = true,
  externalStyles = {},
  additionalComponents = {},
  tooltipId,
  tooltipText = '',
  isRequired,
  onMenuClosed = () => {},
  ...rest
}) => {
  const { t } = useTranslation()
  const [menuOpen, setMenuOpen] = useState(false)
  const customStyles = {
    singleValue: (provided) => ({
      ...provided,
      color: 'var(--bs-dark)',
      fontWeight: '400',
      lineHeight: '18px'

    }),
    container: (provided) => ({
      ...provided,
      position: 'static'
    }),
    valueContainer: (provided) => ({
      ...provided,
      fontSize: '12px',
      cursor: 'pointer',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    }),
    placeholder: (provided, state) => ({
      ...provided,
      position: 'absolute',
      transition: 'top 0.1s, font-size 0.1s',
      fontSize: (state.hasValue || state.selectProps.inputValue) && 12
    }),
    control: (provided) => ({
      ...provided,
      border: 'none',
      boxShadow: 'none',
      textAlign: 'left',
      minWidth: '80px',
      width: 'fit-content',
      minMenuHeight: '0',
      fontSize: '12px',
      '&:hover': {
        cursor: 'pointer'
      }
    }),
    option: (provided) => ({
      ...provided,
      width: '100%',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      textAlign: 'start',
      cursor: 'pointer'
    }),
    menu: (provided) => ({
      ...provided,
      height: 'auto',
      width: '100%',
      left: '50%',
      zIndex: 5,
      transform: 'translate(-50%, 0)',
      color: 'var(--bs-dark)',
      background: 'var(--bs-white) !important'
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: 'var(--bs-dark)',
      padding: 0,
      height: 16,
      width:16,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: 'none'
    }),
    customClearIndicator: provided => ({
      ...provided,
      display: 'grid',
      placeItems: 'center'
    }),
    loadingIndicator: (base) => ({
      ...base,
      display: 'none'
    })
  }

  // options will be [ {label, value}, {label, value} ]
  const onChangeHandler = (option) => {
    selectOptionHandler(option)
  }

  const handleMenuOpen = () => {
    setMenuOpen(true)
  }

  const handleMenuClose = () => {
    setMenuOpen(false)
    onMenuClosed()
  }

  const getOptionLabels = (option) => (
    <div
      className="d-flex align-items-center getOptionLabels"
      title={option.label}
    >
      <span className="text-truncate txt-sub-rg">
        {option.label}
      </span>
    </div>
  )

  const CustomClearIndicator = ({ innerProps, selectProps }) => (
    <div className="custom-clear-indicator me-8px cursor-pointer" {...innerProps}>
      {selectProps.value && (
        <img src={Close} onClick={selectProps.clearValue}/>
      )}
    </div>
  )

  return (
    <div className="DropdownWithTitle" id={tooltipId}>
      <InputGroup className={classNames('dropdown', {'disabled ': rest.isDisabled})}>
        <InputGroupText className={classNames('dropdown-container', {'container-hover': rest.isDisabled})}>
          <h6 className="title me-4px">
            {t(title)}:
            {isRequired && (
              <span className="required_icon"> {IsRequiredIcon} </span>
            )}
          </h6>
          {isAsync ? (
            <AsyncPaginate
              className="select-tag"
              placeholder={t('Select')}
              styles={{...customStyles, ...externalStyles}}
              value={value}
              defaultValue={defaultValue}
              menuIsOpen={menuOpen}
              onMenuOpen={handleMenuOpen}
              onMenuClose={handleMenuClose}
              // getOptionLabel={getOptionLabels}
              // getOptionValue={(option) => option.value}
              onChange={(e) => {
                onChangeHandler(e)
              }}
              components={{
                // ValueContainer: CustomValueContainer,
                ClearIndicator: CustomClearIndicator,
                DropdownIndicator: () => (menuOpen  ?  <img src={Dropup} alt="chevron-up" height="20px" width="20px" className="pb-1px cursor-pointer"/> : <img src={Dropdown} alt="chevron-down" height="20px" width="20px" className="pt-1px cursor-pointer"/>),
                ...additionalComponents
              }}
              theme={(theme) => ({
                ...theme,
                // borderRadius: 0,
                boxShadow: 'none',
                colors: {
                  ...theme.colors,
                  primary: 'var(--bs-primary)',
                  primary25: 'var(--bs-primary-lighter)',
                  primary50: 'var(--bs-primary-lighter)'
                }
              })}
              options={options}
              loadOptions={loadOptionsHandler}
              additional={{
                page: 1
              }}
              {...rest}
            />
          ) : (
            <Select
              className="select-tag"
              placeholder={t('Select')}
              styles={{...customStyles, ...externalStyles}}
              isSearchable={isSearchable}
              value={value}
              defaultValue={defaultValue}
              getOptionLabel={getOptionLabels}
              menuIsOpen={menuOpen}
              onMenuOpen={handleMenuOpen}
              onMenuClose={handleMenuClose}
              getOptionValue={(option) => option.value}
              onChange={(e) => {
                onChangeHandler(e)
              }}
              components={{
                // ValueContainer: CustomValueContainer,
                ClearIndicator: CustomClearIndicator,
                DropdownIndicator: () => (menuOpen ? <img src={Dropup} alt="chevron-up" height="20px" width="20px" className="pb-1px cursor-pointer" /> : <img src={Dropdown} alt="chevron-down" height="20px" width="20px" className="pt-1px cursor-pointer" />),
                ...additionalComponents
              }}
              theme={(theme) => ({
                ...theme,
                // borderRadius: 0,
                boxShadow: 'none',
                colors: {
                  ...theme.colors,
                  primary: 'var(--bs-primary)',
                  primary25: 'var(--bs-primary-lighter)',
                  primary50: 'var(--bs-primary-lighter)'
                }
              })}
              options={options}
              {...rest}
            />
          )}
        </InputGroupText>
      </InputGroup>
      {tooltipId && 
        <UncontrolledTooltip placement="top" target={tooltipId} className="mb-4" offset={[0, 5]}>
          {t(tooltipText)}
        </UncontrolledTooltip>
      }
    </div>
  )
}

export default DropdownWithTitle
