const OmnifulCatalog = ({ iswhite, fillColor, height, width }) => {
  return <svg width={width} height={height} viewBox="0 0 77 77" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M30.2783 25.4088V31.2949H37.113V25.4088H30.2783ZM29.4783 23.6088C28.926 23.6088 28.4783 24.0565 28.4783 24.6088V32.0949C28.4783 32.6472 28.926 33.0949 29.4783 33.0949H37.913C38.4653 33.0949 38.913 32.6472 38.913 32.0949V24.6088C38.913 24.0565 38.4653 23.6088 37.913 23.6088H29.4783Z" fill={iswhite ? 'white' : fillColor}/>
    <path fillRule="evenodd" clipRule="evenodd" d="M34.2174 54.3391L31.4612 59.113H36.9736L34.2174 54.3391ZM35.0834 52.2391C34.6985 51.5725 33.7363 51.5725 33.3514 52.2391L29.2095 59.413C28.8246 60.0797 29.3057 60.913 30.0755 60.913H38.3593C39.1291 60.913 39.6102 60.0797 39.2253 59.4131L35.0834 52.2391Z" fill={iswhite ? 'white' : fillColor}/>
    <path fillRule="evenodd" clipRule="evenodd" d="M33.6957 45.8087C35.583 45.8087 37.113 44.2787 37.113 42.3913C37.113 40.504 35.583 38.974 33.6957 38.974C31.8083 38.974 30.2783 40.504 30.2783 42.3913C30.2783 44.2787 31.8083 45.8087 33.6957 45.8087ZM33.6957 47.6087C36.5771 47.6087 38.913 45.2728 38.913 42.3913C38.913 39.5099 36.5771 37.174 33.6957 37.174C30.8142 37.174 28.4783 39.5099 28.4783 42.3913C28.4783 45.2728 30.8142 47.6087 33.6957 47.6087Z" fill={iswhite ? 'white' : fillColor}/>
    <path fillRule="evenodd" clipRule="evenodd" d="M25.1391 19.2262V66.6001H55.8174V19.2262H25.1391ZM24.7391 16.8262C23.6346 16.8262 22.7391 17.7216 22.7391 18.8262V67.0001C22.7391 68.1047 23.6346 69.0001 24.7391 69.0001H56.2174C57.322 69.0001 58.2174 68.1047 58.2174 67.0001V18.8262C58.2174 17.7216 57.322 16.8262 56.2174 16.8262H24.7391Z" fill={iswhite ? 'white' : fillColor}/>
    <path fillRule="evenodd" clipRule="evenodd" d="M15.8 10C15.8 8.78502 16.785 7.80005 18 7.80005H50.9565C52.1716 7.80005 53.1565 8.78502 53.1565 10V17.8696C53.1565 18.5324 52.6193 19.0696 51.9565 19.0696C51.2938 19.0696 50.7565 18.5324 50.7565 17.8696V10.2H18.2V59.974H22.7391C23.4019 59.974 23.9391 60.5112 23.9391 61.174C23.9391 61.8367 23.4019 62.374 22.7391 62.374H18C16.785 62.374 15.8 61.389 15.8 60.174V10Z" fill={iswhite ? 'white' : fillColor}/>
    <path fillRule="evenodd" clipRule="evenodd" d="M52.5754 9.14885C52.7813 9.77878 52.4376 10.4564 51.8077 10.6623L24.6772 19.5319C24.0473 19.7378 23.3697 19.3941 23.1638 18.7642C22.9578 18.1343 23.3015 17.4566 23.9315 17.2507L51.0619 8.38114C51.6918 8.1752 52.3694 8.51892 52.5754 9.14885Z" fill={iswhite ? 'white' : fillColor}/>
    <path fillRule="evenodd" clipRule="evenodd" d="M40.8435 26.7392C40.8435 26.0764 41.3807 25.5392 42.0435 25.5392H51.4348C52.0975 25.5392 52.6348 26.0764 52.6348 26.7392C52.6348 27.4019 52.0975 27.9392 51.4348 27.9392H42.0435C41.3807 27.9392 40.8435 27.4019 40.8435 26.7392ZM40.8435 30.3914C40.8435 29.7286 41.3807 29.1914 42.0435 29.1914H47.2609C47.9236 29.1914 48.4609 29.7286 48.4609 30.3914C48.4609 31.0541 47.9236 31.5914 47.2609 31.5914H42.0435C41.3807 31.5914 40.8435 31.0541 40.8435 30.3914Z" fill={iswhite ? 'white' : fillColor}/>
    <path fillRule="evenodd" clipRule="evenodd" d="M40.8435 40.3044C40.8435 39.6416 41.3807 39.1044 42.0435 39.1044H51.4348C52.0975 39.1044 52.6348 39.6416 52.6348 40.3044C52.6348 40.9671 52.0975 41.5044 51.4348 41.5044H42.0435C41.3807 41.5044 40.8435 40.9671 40.8435 40.3044ZM40.8435 43.9565C40.8435 43.2938 41.3807 42.7565 42.0435 42.7565H47.2609C47.9236 42.7565 48.4609 43.2938 48.4609 43.9565C48.4609 44.6193 47.9236 45.1565 47.2609 45.1565H42.0435C41.3807 45.1565 40.8435 44.6193 40.8435 43.9565Z" fill={iswhite ? 'white' : fillColor}/>
    <path fillRule="evenodd" clipRule="evenodd" d="M40.8435 53.8696C40.8435 53.2068 41.3807 52.6696 42.0435 52.6696H51.4348C52.0975 52.6696 52.6348 53.2068 52.6348 53.8696C52.6348 54.5323 52.0975 55.0696 51.4348 55.0696H42.0435C41.3807 55.0696 40.8435 54.5323 40.8435 53.8696ZM40.8435 57.5217C40.8435 56.859 41.3807 56.3217 42.0435 56.3217H47.2609C47.9236 56.3217 48.4609 56.859 48.4609 57.5217C48.4609 58.1845 47.9236 58.7217 47.2609 58.7217H42.0435C41.3807 58.7217 40.8435 58.1845 40.8435 57.5217Z" fill={iswhite ? 'white' : fillColor}/>
  </svg>
}
export default OmnifulCatalog