// ** React Imports
import { useEffect, useState } from 'react'
import { Outlet, useLocation } from 'react-router-dom'

// ** Custom Hooks
import { useSkin } from '@hooks/useSkin'

// ** Third Party Components
import classnames from 'classnames'

const RerenderComponent = ({ children }) => {
  const Component = ({ children }) => {
    return children
  }
  const location = useLocation()

  return <Component key={location.key}>
    {children}
  </Component>
}

const BlankLayout = () => {
  // ** States
  const [isMounted, setIsMounted] = useState(false)

  // ** Hooks
  const { skin } = useSkin()

  useEffect(() => {
    setIsMounted(true)
    return () => setIsMounted(false)
  }, [])

  if (!isMounted) {
    return null
  }

  return (
    <div
      className={classnames('blank-page', {
        'dark-layout': skin === 'dark'
      })}
    >
      <div className='app-content content'>
        <div className='content-wrapper'>
          <div className='content-body'>
            <RerenderComponent>
              <Outlet />
            </RerenderComponent>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BlankLayout
