import { useMemo } from 'react';
import { Check } from 'react-feather';
import { SHIPMENT_STATUS_UPDATE_ENTITY } from '../../sales.constant';

/**
 * Renders the shipment count header component.
 *
 * @param {Object} props - The component props.
 * @param {Array} props.selectedData - The selected data.
 * @param {Object} props.entity - The entity object.
 * @return {JSX.Element} The rendered shipment count header component.
 */

const ShipmentCountHeader = ({ selectedData, entity }) => {

  const getShippingPartnerTag = (order) => {
    if (entity.key === SHIPMENT_STATUS_UPDATE_ENTITY.ORDER.key) {
      return order.shippingPartnerTag;
    }
    if (entity.key === SHIPMENT_STATUS_UPDATE_ENTITY.SHIPMENT_ORDER.key) {
      return order.shipment_details.shipping_partner.tag;
    }
    return order.shipping_partner_tag;
  };
  
  const { manualDispatchCount, shippingIntegrationCount } = useMemo(() => {
    return selectedData.reduce((acc, order) => {
      const shippingPartnerTag = getShippingPartnerTag(order);
  
      if (shippingPartnerTag === 'manual') {
        acc.manualDispatchCount++;
      } else {
        acc.shippingIntegrationCount++;
      }
  
      return acc;
    }, { shippingIntegrationCount: 0, manualDispatchCount: 0 });
  }, [selectedData]);

  return (
    <>
      {selectedData.length > 0 && <div className="w-100 mb-1 bg-white flex-center-start gap-16px text-dark px-16px py-14px border border-primary-global bg-primary-lighter-global rounded-8px txt-body-rg">
        <div className='flex-center-start gap-8px'>
          <Check size={20} color="var(--bs-primary)" />
          <span className='text-primary-global' >{`${selectedData.length} ${selectedData.length === 1 ? entity.label : `${entity.label}s`} Selected`}</span>
        </div>

        {manualDispatchCount > 0 && (
          <>
            <div className='border-start border-primary-global height-20px'></div>
            <div className='d-flex gap-8px'>
              <span>{`${entity.label}s via Manual Dispatch:`}</span>
              <span className='fw-bolder'>{manualDispatchCount}</span>
            </div>
          </>
        )}

        {shippingIntegrationCount > 0 && (
          <>
            <div className='border-start border-primary-global height-20px'></div>
            <div className='d-flex gap-8px'>
              <span>{`${entity.label}s via Shipping Partner Integration:`}</span>
              <span className='fw-bolder'>{shippingIntegrationCount}</span>
            </div>
          </>
        )}
      </div>}
    </>
  )
}

export default ShipmentCountHeader
