// ** Reactstrap Imports
import { Form, ModalBody, ModalHeader, Spinner, UncontrolledTooltip } from 'reactstrap'

import Button from '@src/@core/components/ui/button'
import FloatingDropDown from '@src/@core/components/ui/floating-dropdown'
import InputField from '@src/@core/components/ui/input-field'
import PhoneNumberDropdown from '@src/@core/components/ui/phone-number'
import SideSheet from '@src/@core/components/ui/sideSheet'
import SidesheetFooter from '@src/@core/components/ui/sidesheet-footer'
import ComponentSpinner from '@src/@core/components/ui/spinner/Loading-spinner'
import { REGEX_PATTERN } from '@src/App.constants'
import abilityMap from '@src/assets/data/abilityMapping/abilityMapping'
import FilledInfo from '@src/assets/images/icons/FilledInfo'
import ability from '@src/configs/acl/ability'
import { Countries } from '@src/views/hubs/utils/countries'
import { SALESMAN_MODAL_TYPE } from '@src/views/sales/constant/orders.constants'
import { createSalesman, editSalesman, getCitiesAsyncData, getCountriesAsyncData, getStatesAsync, getStatesCitiesAsync } from '@src/views/sales/store/store'
import classNames from 'classnames'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import './CreateSalemen.scss'

const CreateSalesman = ({ openCreateSalesmanSidebar, setOpenCreateSalesmanSidebar, salesmanDetails, isEditSalesmanEnabled, setIsEditSalesmanEnabled }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const loading = useSelector((store) => store.sales.loading)
  const selectedGlobalHubId = useSelector((state) => state.auth.selectedGlobalHubId)
  const address = useSelector((store) => store.sales.salesmanAddress?.data)


  const handleCreateSalesmanSidebar = () => {
    setOpenCreateSalesmanSidebar({ open: false, type: SALESMAN_MODAL_TYPE.CREATE_SALESMAN.key })
  }

  const isSalesmanCreationAllowed = ability.can(abilityMap.salesman.create.action, abilityMap.salesman.create.resource)

  const {
    control,
    handleSubmit,
    register,
    watch,
    setValue,
    getValues,
    reset,
    clearErrors,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: {
      first_name: '',
      last_name: '',
      email: '',
      salesman_phone_number: {
        countryCode: {
          name: 'Saudi Arabia',
          flag: '🇸🇦',
          country_code: 'SA',
          calling_code: '+966',
          value: 'Saudi Arabia (SA)',
          label: 'Saudi Arabia (+966)',
          maxPhoneNumberLength: 9,
        },
        contactNumber: '',
      },
      salesman_address1: '',
      salesman_address2: '',
      salesman_country: '',
      salesman_state: '',
      salesman_city: '',
      salesman_zip: '',
    },
    mode: 'onChange',
    // context: {
    //     single: orderType?.value === 'single'
    // }
  })
  const onSubmit = (data) => {
    if (SALESMAN_MODAL_TYPE[openCreateSalesmanSidebar.type].key === SALESMAN_MODAL_TYPE.EDIT_SALESMAN.key) {
      let id = salesmanDetails?.id
      let body = {
        first_name: data.first_name,
        last_name: data?.last_name,
        email: data.email || undefined,
        phone: data.salesman_phone_number.contactNumber,
        country_calling_code: data.salesman_phone_number.countryCode.calling_code,
        country_code: data.salesman_phone_number.countryCode.country_code,
        employee_id: data.employeeId,

        address: {
          address_line1: data?.salesman_address1,
          address_line2: data?.salesman_address2,
          city_id: data?.salesman_city?.value,
          country_id: data?.salesman_country?.value,
          state_id: data?.salesman_state?.value,
          postal_code: data?.salesman_zip || null,
        },
      }

      dispatch(editSalesman({ hubId: selectedGlobalHubId, salesmanId: id, body }))
     
    } else {
      let body = {
        first_name: data.first_name,
        last_name: data?.last_name,
        email: data.email || undefined,

        phone: data.salesman_phone_number.contactNumber,
        country_calling_code: data.salesman_phone_number.countryCode.calling_code,
        country_code: data.salesman_phone_number.countryCode.country_code,
        employee_id: data?.employeeId,
        address: {
          address_line1: data?.salesman_address1,
          address_line2: data?.salesman_address2,
          city_id: data?.salesman_city?.value,
          country_id: data?.salesman_country?.value,
          state_id: data?.salesman_state?.value,
          postal_code: data?.salesman_zip || undefined,
        },
      }

      dispatch(createSalesman({ hubId: selectedGlobalHubId, body }))
      // reset()
    }
  }

  const handleSidebarClosed = () => {
    reset()
    handleCreateSalesmanSidebar()
  }
  const handleSidebarOpened = () => {
    if (SALESMAN_MODAL_TYPE[openCreateSalesmanSidebar.type].key === SALESMAN_MODAL_TYPE.EDIT_SALESMAN.key) {
      const salesmanCountryCodeObj = Countries.find((country) => country.calling_code === salesmanDetails.country_calling_code)
      setValue('first_name', salesmanDetails.first_name)
      setValue('last_name', salesmanDetails?.last_name)
      setValue('email', salesmanDetails?.email)
      setValue('employeeId', salesmanDetails?.employee_id)
      if (salesmanDetails.phone) {
        setValue('salesman_phone_number.countryCode', salesmanCountryCodeObj)
        setValue('salesman_phone_number.contactNumber', address?.phone)
      }
      if (address?.address) {
        setValue('salesman_address1', address?.address?.address_line1)
        setValue('salesman_address2', address?.address?.address_line2)
        setValue('salesman_city', address?.address?.city?.name ? { label: address?.address?.city?.name, value: address?.address?.city?.id } : null
          // { shouldValidate: true }
        )
        setValue(
          'salesman_country',
          address?.address?.country?.name ?
            { label: address?.address?.country?.name, value: address?.address?.country?.id, code: address?.address?.country?.code } : null
          // { shouldValidate: true }
        )
        setValue('salesman_state', address?.address?.state?.name ? { label: address?.address?.state?.name, value: address?.address?.state?.id } : null)
        setValue('salesman_zip', address?.address?.postal_code)
      }
    }
  }
  useEffect(() => {
    if (address || salesmanDetails) {
      handleSidebarOpened()
    }
  }, [address, salesmanDetails])

  useEffect(() => {
    if (watch('email') && errors.salesman_phone_number) clearErrors('salesman_phone_number')
    if (watch('salesman_phone_number.contactNumber') && errors.email?.type === 'required') clearErrors('email')
    
    if (!isCityRequired && errors.salesman_city?.type === 'required') clearErrors('salesman_city')
    if (!isCountryRequired && errors.salesman_country) clearErrors('salesman_country')
    
    if(!watch('salesman_zip')&&errors.salesman_country)clearErrors('salesman_country')
    if(!watch('salesman_zip')&&errors.salesman_city)clearErrors('salesman_city')
    
    if (!watch('salesman_country') && errors.salesman_country)clearErrors('salesman_city')
    
  }, [watch('email'), watch('salesman_phone_number.contactNumber'),
    watch('salesman_address1'),
    watch('salesman_address2'),
    watch('salesman_country'),
    watch('salesman_zip'),
   
  ])

  // console.log(watch('salesman_country'),'watch(\'salesman_country\')')

  const isPhoneRequired = !!(!watch('email') || watch('salesman_phone_number.contactNumber'))
  const isEmailRequired = !!(!watch('salesman_phone_number.contactNumber') || watch('email'))

  const isCountryRequired =!!(watch('salesman_address1')||watch('salesman_address2')|| watch('salesman_zip'))
  const isCityRequired = !!(watch('salesman_address1') || watch('salesman_address2')|| watch('salesman_zip'))


  return (
    <SideSheet
      modalClassName='modal-slide-in sidebar-todo-modal create-customer-sidesheet'
      isOpen={openCreateSalesmanSidebar.open}
      toggle={handleCreateSalesmanSidebar}
      onClosed={handleSidebarClosed}
      onOpened={handleSidebarOpened}
    >
      <ModalHeader toggle={handleCreateSalesmanSidebar}>{t(SALESMAN_MODAL_TYPE[openCreateSalesmanSidebar.type].headerTitle)}</ModalHeader>
      <ModalBody className='modal-body-data py-0 '>
        {SALESMAN_MODAL_TYPE[openCreateSalesmanSidebar.type].key === SALESMAN_MODAL_TYPE.EDIT_SALESMAN.key && loading.getSalesmanAddress ?<ComponentSpinner/>:
          <>
            <div className='d-flex mx-1 my-2 p-12px rounded-8px gap-10px info-header text-dark txt-body-rg'>
              <FilledInfo height={22} width={22} color={'var(--bs-primary)'} />
              <span className='flex-center-start'>{t(`An email or phone number is required to ${SALESMAN_MODAL_TYPE[openCreateSalesmanSidebar.type].infoText}`)}.</span>
            </div>
      
            <Form onSubmit={handleSubmit(onSubmit)}>
              {/* {SALESMAN_MODAL_TYPE[openCreateSalesmanSidebar.type].key === SALESMAN_MODAL_TYPE.EDIT_SALESMAN.key && loading.getSalesmanAddress ? <div className='d-flex flex-column gap-18px px-1 mt-auto'><ComponentSpinner /> </div>: */}
              <>
                <div className='d-flex flex-column gap-18px px-1'>
                  <InputField
                    errors={errors}
                    {...register('first_name', {
                      required: t('This field is mandatory'),
                    })}
                    isClearable
                    label='First Name'
                    isRequired
                  />
                  <InputField errors={errors} {...register('last_name')}
                    // isRequired
                    isClearable label='Last Name' />
              
                  <InputField
                    errors={errors}
                    {...register('email', {
                      required: watch('salesman_phone_number.contactNumber') ? false : 'Please enter either email or contact number',
                      pattern: {
                        value: REGEX_PATTERN.EMAIL_VALIDATION,
                        message: t('Enter a valid email address'),
                      },
                    })}
                    isClearable
                    label='Email'
                    isRequired={isEmailRequired}
                
                  />
                  <PhoneNumberDropdown
                    name='salesman_phone_number'
                    contactNumber={watch('salesman_phone_number.contactNumber')}
                    countryCode={watch('salesman_phone_number.countryCode')}
                    control={control}
                    errors={errors}
                    register={register}
                    isClearable
                    label='Contact Number'
                    isRequired={isPhoneRequired}
                    setError={setError}
                    clearErrors={clearErrors}

                    customValidation={(val) => {
                      if (watch('email') || val.length) {
                        return true
                      }
                      return 'Please enter either email or contact number'
                    }}
                  />
                  <InputField errors={errors} {...register('employeeId')} isClearable label='Employee ID' />
                </div>

                <div className='d-flex flex-column gap-18px px-1 mt-18px mb-5'>
                  <InputField errors={errors} {...register('salesman_address1')} isClearable label='Address line 1' />

                  <InputField errors={errors} {...register('salesman_address2')} isClearable label='Address line 2' />

                  <FloatingDropDown
                    name='salesman_country'
                    isAsync
                    control={control}
                    loadOptions={getCountriesAsyncData}
                    isTooltipError
                    errors={errors}
                    value={watch('salesman_country')}
                    isClearable
                    title='Select Country'
                    isRequired={isCountryRequired}
                    clearErrors={clearErrors}
                    validationSchema={{
                      salesman_country: {
                        required: isCountryRequired ? t('This field is mandatory')  : {},
                      }
                    }}
                
                    onChangeFunc={() => {
                      if (watch('salesman_city') || watch('salesman_state')) {
                        setValue('salesman_city', null)
                        setValue('salesman_state', null)
                      }
                    }}
                    infoText={!errors.salesman_country ? 'Type at least 3 characters to search for a country' : null}
                  />

                  <FloatingDropDown
                    name='salesman_state'
                    isAsync
                    control={control}
                    value={getValues('salesman_state')}
                    loadOptions={getStatesAsync}
                    errors={errors}
                    isClearable
                    isTooltipError
                    isDisabled={!watch('salesman_country')}
                    title='Select State/Region'
                    cacheUniqs={[getValues('salesman_state')?[getValues('salesman_city')]: getValues('salesman_country')]}
                    additional={{
                      page: 1,
                      country: getValues('salesman_country')?getValues('salesman_country'):null
                    }}
                    onChangeFunc={() => {
                      if (watch('salesman_city')) {
                        setValue('salesman_city', null)
                      }
                    }}

                  />
                  <FloatingDropDown
                    name='salesman_city'
                    isAsync
                    control={control}
                    isDisabled={!watch('salesman_country')}
                    loadOptions={watch('salesman_state') ? getStatesCitiesAsync : getCitiesAsyncData}
                    isTooltipError
                    errors={errors}
                    value={watch('salesman_city')}
                    isClearable
                    clearErrors={clearErrors}
                    title='Select City'
                    isRequired={isCityRequired||watch('salesman_country')}

                    validationSchema={{
                      salesman_city: {
                        required: (isCityRequired||watch('salesman_country') )&& t('This field is mandatory')
                      }
                    }}
               
                    cacheUniqs={watch('salesman_state') ? [getValues('salesman_state')] : [getValues('salesman_country')]}
                    additional={{
                      page: 1,
                      state: watch('salesman_state'),
                      country_id: getValues('salesman_country')?.value,
                    }}

                  />
                  <div className='mb-4'>
                    <InputField errors={errors} {...register('salesman_zip')} isClearable label='Zip' type='number' />
                  </div>
                </div>

                <SidesheetFooter>
                  <div id='salesmanCreationAllowed'>
                    <Button type='submit' disabled={loading.createSalesman || loading.editSalesman || !isSalesmanCreationAllowed}>
                      {(loading.createSalesman || loading.editSalesman) && <Spinner size='sm' />}
                      <span className={classNames({ 'ms-50': loading.createSalesman })}>
                        {SALESMAN_MODAL_TYPE[openCreateSalesmanSidebar.type].key === SALESMAN_MODAL_TYPE.EDIT_SALESMAN.key
                          ? t('Save')
                          : SALESMAN_MODAL_TYPE[openCreateSalesmanSidebar.type].key === SALESMAN_MODAL_TYPE.CREATE_SALESMAN.key
                            ? t('Create')
                            : ''}
                      </span>
                    </Button>
                    {!isSalesmanCreationAllowed && (
                      <UncontrolledTooltip offset={[0, 5]} target='customerCreationAllowed'>
                        {t('You are not authorised to perform this action')}
                      </UncontrolledTooltip>
                    )}
                  </div>
                </SidesheetFooter>
              </>
              
            </Form>
          </>
        }
      </ModalBody>
    </SideSheet>
  )
}

export default CreateSalesman
