import { Table } from 'reactstrap'
import './refundDetailTable.scss'
import { Minus, Plus } from 'react-feather'
import no_sku_image from '@src/assets/images/omniful/no_sku_image.svg'

const RefundDetailTableRow = (props) => {
  const {item, skuTableData, setSkuTableData, isNextButtonPressed} = props
  const handleRefundedQty = (seller_sku_code, quantity) => {
    setSkuTableData((prev) => {
      return {...prev, [seller_sku_code]: {...prev[seller_sku_code], refunded_quantity: prev[seller_sku_code].refunded_quantity + quantity, refund_price: (prev[seller_sku_code].refunded_quantity + quantity) * prev[seller_sku_code].unit_price }}
    })
  }
  return (
    <tr>
      <td>
        <div className="d-flex align-items-center gap-50 sku-container">
          <div className="sku-image">
            <img
              onError={({ currentTarget }) => {
                currentTarget.onerror = null
                currentTarget.src = no_sku_image
              }}
              src={item?.seller_sku?.images?.[0]?.default || no_sku_image}
              alt={'no img'}
            />
          </div>
          <div>
            <div className="sku-name txt-sub-rg">{item.seller_sku.name || '-'}</div>
            <div className="sku-code">SKU code: {item.seller_sku_code || '-'}</div>
          </div>
        </div>
      </td>
      <td><div className="returned qty-cell">{item.return_quantity}</div></td>
      <td><div className="passed qty-cell">{item.pass_quantity}</div></td>
      <td><div className="failed qty-cell">{item.fail_quantity}</div></td>
      <td><div className="unit-price qty-cell">{item.unit_price.toFixed(2)}</div></td>
      <td>
        <div className="qty-input-container d-flex align-items-center justify-content-center">
          <div className="qty-input">
            <button
              type="button"
              className={skuTableData[item?.seller_sku_code]?.refunded_quantity === 0 || isNextButtonPressed ? 'text-white' : 'bg-primary text-white'}
              style={{ borderRadius: '4px 0 0 4px' }}
              onClick={() => handleRefundedQty(item?.seller_sku_code, -1)}
              disabled={skuTableData[item?.seller_sku_code]?.refunded_quantity === 0 || isNextButtonPressed}
            >
              <Minus />
            </button>
            <input
              className="border-0 ps-1"
              type="text"
              pattern="[0-9]*"
              value={skuTableData[item?.seller_sku_code]?.refunded_quantity}
              readOnly
            />
            <button
              type="button"
              className={skuTableData[item?.seller_sku_code]?.refunded_quantity === item.return_quantity || isNextButtonPressed ? 'text-white' : 'bg-primary text-white'}
              style={{ borderRadius: '0 4px 4px 0' }}
              onClick={() => handleRefundedQty(item?.seller_sku_code, 1)}
              disabled = {skuTableData[item?.seller_sku_code]?.refunded_quantity === item.return_quantity || isNextButtonPressed}
            >
              <Plus />
            </button>
          </div>
        </div>
      </td>
      <td><div className="refund-price qty-cell">{skuTableData[item?.seller_sku_code]?.refund_price.toFixed(2)}</div></td>
    </tr>
  )
}
const RefundDetailTable = (props) => {
  const {returnOrderDetail, skuTableData, setSkuTableData, isNextButtonPressed} = props
  return (
    <div className="refund-detail-table-container">
      <Table>
        <thead>
          <th className="col-sku">SKU</th>
          <th className="col-returned-qty">Returned Qty</th>
          <th className="col-passed-qty">Passed Qty</th>
          <th className="col-Failed-qty">Failed Qty</th>
          <th className="col-unit-price">Unit Price</th>
          <th className="col-refund-approved-qty">Refund Approved Qty</th>
          <th className="col-refund-price">Refund Price</th>
        </thead>
        <tbody>
          {returnOrderDetail.order_items?.map((item) => <RefundDetailTableRow key={item.seller_sku_code} item={item} skuTableData={skuTableData} setSkuTableData={setSkuTableData} isNextButtonPressed={isNextButtonPressed}/>)}
        </tbody>
      </Table>
    </div>
  )
}
export default RefundDetailTable
