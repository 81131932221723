import { ORDER_STATUS } from '@src/views/sales/constant/orders.constants';
import React from 'react';
import { Info } from 'react-feather';

interface iHubReassignmentDetails {
  previous_hub_name: string
}

interface iHub {
  name: string
}

interface iOrderDetails {
  hub: iHub
  hub_reassignment_details: iHubReassignmentDetails,
  status: string
  assigned_hub: iHub
}

interface iHubReassignedInfoProps {
  orderDetails: iOrderDetails
}

const ReassignedHubInfo: React.FC<iHubReassignedInfoProps> = ({ orderDetails }) => {

  const assignedHub = orderDetails.status === ORDER_STATUS.ON_HOLD.name ? orderDetails.assigned_hub?.name : orderDetails.hub.name;
  const hubReassignmentDetails = orderDetails.hub_reassignment_details.previous_hub_name;

  return (
    <div className='mb-24px mx-0'>
      <div className='text-dark p-8px txt-sub-rg flex-center-start rounded-4px bg-primary-lighter-global'>
        <div className='flex-center-start'>
          <div className='flex-center-start gap-50'>
            <span className='flex-center-center'>
              <Info size={14} color='var(--bs-primary)'/>
            </span>
            <span>{`The order has been reassigned from ${hubReassignmentDetails} to ${assignedHub}.`}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReassignedHubInfo;
