import CustomLabel from '@src/@core/components/ui/badge/CustomLabel'
import abilityMap from '@src/assets/data/abilityMapping/abilityMapping'
import ability from '@src/configs/acl/ability'
import classNames from 'classnames'
import { Info } from 'react-feather'
import { useSelector } from 'react-redux'
import { UncontrolledTooltip } from 'reactstrap'

const AttributeItem = ({ attribute, variations, selectedVariationId, showAllVariations }) => {
  return (
    <div className='d-flex p-1 align-items-center w-75'>
      <p className='w-25 m-0 p-0 txt-body-rg text-dark-6'>{attribute?.name || '-'}</p>
      <div className='w-75 d-flex gap-1 align-items-center'>
        {showAllVariations
          ? variations?.map((variation, index) => {
            return <CustomLabel key={index} title={variation?.name || '-'} className={classNames('m-0 px-1 rounded-3', {
              'bg-primary-lighter': variation?.id === selectedVariationId,
              'bg-danger': variation?.id !== selectedVariationId,
              'bg-primary': variation?.id === selectedVariationId,
              'bg-dark-3': variation?.id !== selectedVariationId
            })}/>
          })
          : <CustomLabel title={attribute?.sku_attribute_variation?.name} className='ms-1 me-0 my-0 gap-8px text-dark bg-dark-1'/>
        }
      </div>
    </div>
  )
}

const SimpleSkuInfo = ({ t, sku, isPosEnabled }) => {
  const productDetails = useSelector(state => state.inventory.product)
  const simpleSkuDetail = useSelector(state => state.catalog.sku)
  const getSourceDetails = (sku) => {
    if (sku.sku_upsert_source_details?.source === 'sheet_import') {
      return <a href={sku.sku_upsert_source_details.file_link}>{t('Sheet')}</a>
    } else {
      return 'Manual'
    }
  }

  const sourceDetails = sku.sku_upsert_source_details?.source ? getSourceDetails(sku) : '-'

  return (
    <div className='w-100 d-flex flex-column gap-2'>
      {ability.can(abilityMap.product.view_price.action, abilityMap.product.view_price.resource) && <div>
        <div className='simple-sku-desc-title txt-sub-rg text-dark w-100'>
          {t('Price Details')}
        </div>
        <div className='d-flex gap-1 flex-column'>
          <div className='txt-sub-rg text-dark w-100 d-flex gap-5'>
            <div className='w-100 simple-sku-info-content'>
              <p className='txt-body-rg text-dark-6'>
                {t('Retail Price')}
              </p>
              <p className='txt-body-md text-dark'>
                {sku.retail_price >= 0 ? `${sku.display_retail_price}` : '-'}
              </p>
              <p className='txt-body-rg text-dark-6'>
                {isPosEnabled ? t('Tax Category') : t('Tax Percentage')}
              </p>
              <p className='text-dark txt-body-md'>
                {isPosEnabled ? sku.tax_category?.name : sku.tax_percentage?.selling_price}
              </p>
            </div>
            <div className='simple-sku-info-content w-100'>
              <p className='txt-body-rg text-dark-6 d-flex gap-8px align-items-center'>
                {t('Selling Price')}
                <span id='selling-price-info' className='cursor-pointer'><Info size={14} color='var(--bs-dark-6)' /></span>
                <UncontrolledTooltip
                  placement="bottom"
                  target={'selling-price-info'}
                  autohide={false}
                  offset={[0, 5]}
                >
                  <span className='text-white'> { sku.tax_inclusive?.selling_price ? t('It includes Tax') : t('It excludes Tax')}</span>
                </UncontrolledTooltip>
              </p>
              <p className='text-dark txt-body-md'>
                {sku.selling_price >= 0 ? <div className='d-flex gap-8px align-items-center flex-wrap'>
                  <div>
                    {sku.display_selling_price} </div>
                </div> : '-'}
              </p>

              <p className='txt-body-rg text-dark-6'>
                {t('Wholesale Price')}
              </p>
              <p className='txt-body-md text-dark'>
                {sku.wholesale_price >= 0 ? `${sku.display_whole_sale_price}` : '-'}
              </p>
              
            </div>
          </div>
        </div>
      </div>}

      <div>
        <div className='simple-sku-desc-title txt-sub-rg text-dark w-100'>
          {t('Dimension Details')}
        </div>
        <div className='d-flex gap-1 flex-column'>
          <div className='txt-sub-rg text-dark w-100 d-flex gap-5'>
            <div className='w-100 simple-sku-info-content'>
              <p className='txt-body-rg text-dark-6'>
                {t('SKU Length')}
              </p>
              <p className='txt-body-md text-dark'>
                {sku.dimensions?.length?.length >= 0 ? sku.dimensions.length.display_length : '-' }
              </p>
              <p className='txt-body-rg text-dark-6'>
                {t('SKU Width')}
              </p>
              <p className='text-dark txt-body-md'>
                {sku.dimensions?.breadth?.breadth >= 0 ? sku.dimensions.breadth.display_breadth : '-' }
              </p>
            </div>
            <div className='simple-sku-info-content w-100'>
              <p className='txt-body-rg text-dark-6'>
                {t('SKU Height')}
              </p>
              <p className='text-dark txt-body-md'>
                {sku.dimensions?.height?.height >= 0 ? sku.dimensions.height.display_height : '-' }
              </p>
            </div>
          </div>
        </div>
      </div>

      {sku?.attributes?.length && <div>
        <div className='simple-sku-desc-title txt-sub-rg text-dark w-100'>
          {t('Attribute Details')}
        </div>
        <div className='py-1'>
          {
            sku?.attributes?.map((attribute) => {
              const allAttributes = productDetails?.attributes
              return <AttributeItem key={attribute?.id} selectedVariationId={sku?.attributes?.find(attr => attr?.id === attribute?.id)?.sku_attribute_variation?.id}
                attribute={attribute}
                variations={allAttributes?.find(attr => attr?.id === attribute?.id)?.sku_attribute_variation} />
            })
          }
        </div>
      </div>}

      <div>
        <div className='simple-sku-desc-title txt-sub-rg text-dark w-100'>
          {t('Additional Details')}
        </div>
        <div className='d-flex gap-1 flex-column'>
          <div className='txt-sub-rg text-dark w-100 d-flex gap-5'>
            <div className='w-100 simple-sku-info-content'>
              <p className='txt-body-rg text-dark-6'>
                {t('Brand')}
              </p>
              <p className='txt-body-md text-dark'>
                {sku.brand || '-'}
              </p>
              <p className='txt-body-rg text-dark-6'>
                {t('Manufacturer')}
              </p>
              <p className='text-dark txt-body-md'>
                {sku.manufacturer || '-'}
              </p>
            </div>
            <div className='simple-sku-info-content w-100'>
              <p className='txt-body-rg text-dark-6'>
                {t('Country of origin')}
              </p>
              <p className='text-dark txt-body-md'>
                {sku.country_of_origin || '-'}
              </p>
              <p className='txt-body-rg text-dark-6'>
                {t('HS Code')}
              </p>
              <p className='text-dark txt-body-md'>
                {sku.hs_code || '-'}
              </p>      
            </div>
          </div>
        </div>
      </div>

      {sku.id && <div>
        <div className='simple-sku-desc-title txt-sub-rg text-dark w-100'>
          {t('Activity Logs')}
        </div>
        <div className='d-flex gap-1 flex-column'>
          <div className='txt-sub-rg text-dark w-100 d-flex gap-5'>
           
            <div className='w-100 simple-sku-info-content'>
              <p className='txt-body-rg text-dark-6'>
                {t('Created At')}
              </p>
              <p className='txt-body-md text-dark'>
                {sku.created_at || '-'}
              </p>    
              {simpleSkuDetail.id && <>
                <p className='txt-body-rg text-dark-6'>
                  {t('Updated By')}
                </p>
                <p className='txt-body-md text-dark'>
                  {sku.updated_by_user || '-'}
                </p>
              </>}
            </div>
            <div className='simple-sku-info-content w-100'>
              {simpleSkuDetail.id && <>
                <p className='txt-body-rg text-dark-6'>
                  {t('Updated Via')}
                </p>
                <p className='text-dark txt-body-md'>
                  {sourceDetails}
                </p>
                <p className='txt-body-rg text-dark-6'>
                  {t('Updated At')}
                </p>
                <p className='txt-body-md text-dark'>
                  {sku.updated_at || '-'}
                </p>              
              </>}
            </div>
          </div>
        </div>
      </div>}
    </div>
  )
}

export default SimpleSkuInfo
