import { ExpandableCustomTable } from '@src/@core/components/ui/globalTable/ExpandableCustomTable'
import SideSheet from '@src/@core/components/ui/sideSheet'
import { useTranslation } from 'react-i18next'
import PerfectScrollbar from 'react-perfect-scrollbar'

const SerialisedBarcodes = ({ nonSerialisedData }) => {
  const { data } = nonSerialisedData
  const { t } = useTranslation()

  const mapData = data.map(el => ({barcode:el}))

  const columns = [
    {
      id: 1,
      name: t('S.No.'),
      key: 'batched_serial_number',
      width: '60px',
      cell: (_, index) => <div className='text-center w-100'>{index + 1}</div>
    },
    {
      id: 2,
      name: t('Barcode'),
      key: 'batched_serialised_barcodes',
      minWidth: '220px',
      cell: (row) => <div className='txt-sub-rg'>
        {row.barcode || '-'}
      </div>
    }
  ]

  return <section className='p-24px'>
    <ExpandableCustomTable
      columns={columns}
      data={mapData || []}
      useReactPaginate={false}
      showPagination={false}
      showColumnsTableHeader={false}
    />
  </section>
}

const NonSerialisedBarcodeSidesheet = (props) => {

  const { nonSerialisedSidesheet, setNonSerialiseSidesheet, nonSerialisedData, setNonSerialisedData } = props

  return (
    <SideSheet
      isOpen={nonSerialisedSidesheet}
      toggle={() => setNonSerialiseSidesheet(false)}
      onClosed={() => setNonSerialisedData({})}
      unmountOnClose={true}
      modalClassName="modal-slide-in sidebar-todo-modal"
      contentClassName="p-0 bg-white"
      size="sm"
      title='Barcodes'
    >
      <PerfectScrollbar>
        <SerialisedBarcodes nonSerialisedData={nonSerialisedData} />
      </PerfectScrollbar>
    </SideSheet>
  )
}

export default NonSerialisedBarcodeSidesheet