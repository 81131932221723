import Button from '@src/@core/components/ui/button'
import CustomToast from '@src/@core/components/ui/custom-toast/CustomToast'
import InputField from '@src/@core/components/ui/input-field'
import Select from '@src/@core/components/ui/select'
import SingleDateFilter from '@src/@core/components/ui/single-date-filter'
import { TENANT_TYPE } from '@src/App.constants'
import { API_ENDPOINTS } from '@src/api.urls'
import abilityMap from '@src/assets/data/abilityMapping/abilityMapping'
import ability from '@src/configs/acl/ability'
import { axiosInstance } from '@src/network/AxiosInstance'
import { getApiUrl } from '@src/utility/Utils'
import { getAsyncAdhocActivities, getSellersAsyncData } from '@src/views/settings/store'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { format, parse } from 'date-fns'
import PropTypes from 'prop-types'
import { X } from 'react-feather'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Modal } from 'reactstrap'
import { UPDATE_AD_HOC_USAGE_FORM_DEFAULT_VALUE, UPDATE_AD_HOC_USAGE_FORM_FIELD_NAME, UPDATE_AD_HOC_USAGE_FORM_VALIDATION_SCHEMA } from '../../billing.constants'
import '../../scss/style.scss'

const UpdateAdHocUsage = ({ singleAdHocData, sellerObj = '', name, type, id, ...rest }) => {
  const { t } = useTranslation()
  const { register, handleSubmit, watch, setValue, formState: { errors }, control, reset } = useForm({ mode: 'onChange', defaultValues: UPDATE_AD_HOC_USAGE_FORM_DEFAULT_VALUE })
  const queryClient = useQueryClient()
  const selectedHubId = useSelector(store => store.auth.selectedGlobalHubId)
  const userHubs = useSelector(store => store.auth.userHubs)
  const selectedHub = userHubs.find(hub => hub.id === selectedHubId)

  const tenantType = useSelector(state => state.auth.userData.tenant.type)
  const isSellerViewAllowed = ability.can(abilityMap.seller.view.action, abilityMap.seller.view.resource)

  const { mutate: createAdHocUsage, isPending: createPending } = useMutation({
    mutationKey: ['create-ad-hoc-usage'],
    mutationFn: (body) => {
      const url = getApiUrl(API_ENDPOINTS.BILLINGS.CREATE_AD_HOC_USAGE)
      return axiosInstance.post(url, body)
    },
    onSuccess: () => {
      CustomToast('Usage Added successfully', { my_type: 'success' })
      queryClient.invalidateQueries({ queryKey: ['fetch-ad-hoc-usages'] })
      rest.toggle()
    }
  })

  const submitFormHandler = (data) => {
    const parsedDate = parse(data[UPDATE_AD_HOC_USAGE_FORM_FIELD_NAME.DATE]?.value.split(' ')[0], 'dd-MM-yyyy', new Date())
    const formattedDate = format(parsedDate, 'yyyy-MM-dd')
    const body = {
      adhoc_id: data[UPDATE_AD_HOC_USAGE_FORM_FIELD_NAME.AD_HOC].value,
      usage: data[UPDATE_AD_HOC_USAGE_FORM_FIELD_NAME.HOURS],
      activity_date: formattedDate,
      seller: {
        id: data[UPDATE_AD_HOC_USAGE_FORM_FIELD_NAME.SELLER]?.value || sellerObj.id,
        name: data[UPDATE_AD_HOC_USAGE_FORM_FIELD_NAME.SELLER]?.label || sellerObj.name
      },
      hub: {
        id: +selectedHub?.id,
        name: selectedHub?.name
      },
      entity: {
        type,
        name,
        id
      }
    }
    createAdHocUsage(body)
  }

  const handleCloseModal = () => {
    reset()
  }

  return (
    <Modal
      {...rest}
      className='update-ad-hoc-usage-modal'
      centered
      onClosed={handleCloseModal}
    >
      <form onSubmit={handleSubmit(submitFormHandler)} className='rounded-16px overflow-hidden bg-white rounded-12px px-20px py-16px d-flex flex-column gap-24px'>
        <div className='modal-header  bg-white '>
          <div className='modal-title d-flex flex-column gap-8px'>
            <div className='txt-h2-md text-dark'>{t('Add Usage')}</div>
            <div className='txt-sub-rg text-dark-5'>{singleAdHocData?.description}</div>
          </div>
          <span
            className='bg-white cursor-pointer'
            onClick={rest.toggle}
          >
            <X size={18} className='text-dark-6' />
          </span>
        </div>
        <div className='d-flex flex-column gap-2 w-100 bg-white'>
          { (!type && isSellerViewAllowed && tenantType === TENANT_TYPE.FULFILLMENT_CENTRE) && 
          <div className='w-100'>
            <Controller
              name={UPDATE_AD_HOC_USAGE_FORM_FIELD_NAME.SELLER}
              control={control}
              rules={{ required: 'This field is required' }}
              render={({ field }) => (
                <Select
                  {...field}
                  isAsync
                  loadOptions={getSellersAsyncData}
                  label='Select Seller'
                  isRequired
                  errors={errors}
                  styles={{
                    menuList: (base) => ({
                      ...base,
                      maxHeight: '124px',
                      overflowY: 'auto',
                    }),
                  }}
                />
              )}
            />
          </div>
          }
          <div className='w-100'>
            <Controller
              name={UPDATE_AD_HOC_USAGE_FORM_FIELD_NAME.AD_HOC}
              control={control}
              rules={{ required: 'This field is required' }}
              render={({ field }) => (
                <Select
                  {...field}
                  isAsync
                  isRequired
                  loadOptions={getAsyncAdhocActivities}
                  label='Select Activity'
                  errors={errors}
                  additional={{
                    page: 1
                  }}
                  styles={{
                    menuList: (base) => ({
                      ...base,
                      maxHeight: '124px',
                      overflowY: 'auto',
                    }),
                  }}
                />
              )}
            />
          </div>
          <div className='w-100 flex-start-between'>
            <div style={{width: type ? '100%' : '49%' }}>
              <InputField
                label='Usage Qty'
                type='number'
                {...register(
                  UPDATE_AD_HOC_USAGE_FORM_FIELD_NAME.HOURS,
                  UPDATE_AD_HOC_USAGE_FORM_VALIDATION_SCHEMA[UPDATE_AD_HOC_USAGE_FORM_FIELD_NAME.HOURS]
                )}
                errors={errors}
                isRequired 
              />
            </div>
            { !type && 
            <div style={{width: '49%'}}>
              <SingleDateFilter
                placeholder='YYYY/MM/DD'
                value={watch(UPDATE_AD_HOC_USAGE_FORM_FIELD_NAME.DATE)}
                onChange={(value) => {
                  setValue(UPDATE_AD_HOC_USAGE_FORM_FIELD_NAME.DATE, value)
                }}
                maxDate={new Date()}
              />
            </div>
            }
          </div>

        </div>

        <div className='d-flex justify-content-end w-100%'>
          <Button disabled={createPending}>{t('Add Usage')}</Button>
        </div>
      </form>

      {/* <ModalFooter className='rounded-16px'></ModalFooter> */}
    </Modal>
  )
}

export default UpdateAdHocUsage

UpdateAdHocUsage.prototype = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  singleAdHocData: PropTypes.object
}