import Button from '@src/@core/components/ui/button'
import { OMNIFUL_EMAILS } from '@src/App.constants'
import data_cannot_be_fetched from '@src/assets/images/svg/data_cannot_be_fetched.svg'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { SHIPPING_ENTITY } from '../constant/orders.constants'
import { getShippingDetails } from '../store/store'

const ShipmentNotCreated = ({orderID}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const handleRefreshButtonClick = () => {
    dispatch(getShippingDetails({params: {entity_id: orderID, entity_name: SHIPPING_ENTITY.FORWARD_ORDER.value}}))
  }
  return (
    <div className="shipmentNotCreatedContainer">
      <div className="image-container">
        <img src={data_cannot_be_fetched} alt='No Data Image' height={200} width={200}/>
      </div>
      <div>
        <div className="shipmentNotCreatedHeading">{t('Unable to load details')}</div>
        <div className="shipmentNotCreatedSubHeading">
          {t(`Please contact us at ${OMNIFUL_EMAILS.SUPPORT_EMAIL} or `)}
          <span onClick={() => { window.FreshworksWidget('open') }} className="text-primary cursor-pointer">{t('raise a ticket')}.</span>
        </div>
      </div>
      <div className="button-container"><Button className='w-100 flex-center' onClick={handleRefreshButtonClick}>{t('Refresh')}</Button></div>
    </div>
  )
}
export default ShipmentNotCreated