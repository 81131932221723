import { ExpandableCustomTable } from '@src/@core/components/ui/globalTable/ExpandableCustomTable'
import { ALL_COLUMN_NAME_MAPPING } from '@src/@core/components/ui/globalTable/globalTable.constant'
import SideSheet from '@src/@core/components/ui/sideSheet'
import { useTranslation } from 'react-i18next'
import ReactPerfectScrollBar from 'react-perfect-scrollbar'

const SKUBarcodeModal = ({ data, toggleSidebar, modalOpen  }) => {
  const {t} = useTranslation()

  const barcodeColumns = [
    {
      id: 1,
      name: <div className="w-100 text-center">{t('S. No.')}</div>,
      key: 'serialNumber',
      width: '100px',
      selector: row => row,
      cell: (_, index) => <div className="w-100 text-center">{index + 1}</div>
    },
    {
      id: 2,
      name: t('Barcode'),
      width: '300px',
      key: 'barcode',
      selector: row => row.barcodeValue
    }
  ]
  return (
    <SideSheet 
      title="Barcodes" 
      isOpen={modalOpen} 
      toggle={() => toggleSidebar()} 
      contentClassName="p-0" 
      modalClassName="modal-slide-in sidebar-todo-modal"
    >
      <ReactPerfectScrollBar>
        <div className="w-100 mt-2 px-2">
          <ExpandableCustomTable 
            showColumnsTableHeader={false} 
            data={data} 
            columns={barcodeColumns} 
            columnName={ALL_COLUMN_NAME_MAPPING.SKU_BARCODES_DETAILS}
            showPagination={false} 
          />
        </div>
      </ReactPerfectScrollBar>
    </SideSheet>
  )
}

export default SKUBarcodeModal