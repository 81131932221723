import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SHIPPING_ENTITY } from '../constant/orders.constants'
import { EDIT_MODAL_TYPE } from '../sales.constant'
import { clearSingleOrderDetailsData, getShippingDetails, resetSuccess } from '../store/store'
import CreateShipment from './CreateShipment'
import './CreateShipment.scss'

const Shipment = (props) => {
  const { sellerId } = props
  const [isEditDetailsModalOpen, setIsEditDetailsModalOpen] = useState({ open: false, key: EDIT_MODAL_TYPE.CUSTOMER_DETAILS.key })
  const dispatch = useDispatch()
  const success = useSelector((store) => store.sales.success)
  const shippingDetailsData = useSelector((store) => store.sales.shippingDetailsData)
  const sellerSalesChannelId = shippingDetailsData?.seller_sales_channel_id

  useEffect(() => {
    if (success.editCustomerAndShippingDetails) {
      setIsEditDetailsModalOpen(prev => ({ ...prev, open: false }))
      dispatch(clearSingleOrderDetailsData())
      dispatch(resetSuccess())
      dispatch(getShippingDetails({ params: { entity_id: sellerId, entity_name: SHIPPING_ENTITY.FORWARD_ORDER.value } }))
    }
  }, [success.editCustomerAndShippingDetails])

  const handleEditDetailsModal = (key) => {
    setIsEditDetailsModalOpen({ open: true, key })
  }


  return (
    <div className='createShipment pb-1'>
      <CreateShipment
        orderID={sellerId}
        sellerSalesChannelId={sellerSalesChannelId}
        handleEditDetailsModal={handleEditDetailsModal}
        isEditDetailsModalOpen={isEditDetailsModalOpen}
        setIsEditDetailsModalOpen={setIsEditDetailsModalOpen}
      />
    </div>
  )
}

export default Shipment