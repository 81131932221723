import { FILE_REJECTED_REASON, FILE_TYPES, MAX_FILE_SIZE_ALLOWED_IN_BYTES, MIN_FILE_SIZE_ALLOWED_IN_BYTES, ONE_MB_IN_BYTES } from '@src/App.constants'
import { BulkUploadSvg } from '@src/assets/data/assets'
import checkCircle from '@src/assets/images/icons/check_circle.svg'
import classnames from 'classnames'
import { Fragment, useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { X } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { Card, CardBody, Col, Progress, Row } from 'reactstrap'
import CustomToast from '../custom-toast/CustomToast'
import './styles.scss'

let ID

const BulkUpload = ({
  fileChangeHandler,
  fileLogo,
  setUploadId,
  url,
  progress,
  setUploadProgress,
  hasBorder = true,
  isContentCenter = true,
  setIsFileExists = () => { },
  fileFormat = { [FILE_TYPES.CSV.type]: FILE_TYPES.CSV.extensions },
  upload_illustration,
  isFileUploading = false,
  title = '',
  isSampleFile,
  isTriggerRemoveFile = false,
  minFileSizeInBytes = MIN_FILE_SIZE_ALLOWED_IN_BYTES,
  maxFileSizeInBytes = MAX_FILE_SIZE_ALLOWED_IN_BYTES,
  hasSampleFile = true
}) => {
  const [fileAdd, setFileAdd] = useState({})
  const { t } = useTranslation()
  const [isUploaded, setIsUploaded] = useState(false)
  const validFileFormat = Object.keys(fileFormat)

  const { getRootProps, getInputProps } = useDropzone({
    accept: fileFormat,
    multiple: false,
    minSize: minFileSizeInBytes,
    maxSize: maxFileSizeInBytes,
    onDrop: result => {
      if (result.length && validFileFormat.includes(result[0].type)) {
        setFileAdd(result)
        setIsFileExists(result)
        fileChangeHandler(result)
      } 
    },
    onDropRejected: (...result) => {
      const errorCode = result[0][0].errors[0].code
      const getFileSizeInMB = (sizeInBytes) => (sizeInBytes / ONE_MB_IN_BYTES).toFixed(2)
        
      const errorMessages = {
        [FILE_REJECTED_REASON.FILE_INVALID_TYPE]: 'Please upload a file in a supported format.',
        [FILE_REJECTED_REASON.FILE_TOO_LARGE]: `Please upload a file of less than ${getFileSizeInMB(maxFileSizeInBytes)} Mb.`,
        [FILE_REJECTED_REASON.FILE_TOO_SMALL]: `Please upload a file of greater than ${getFileSizeInMB(minFileSizeInBytes)} Mb.`
      }
        
      const errorMessage = errorMessages[errorCode] || 'An unknown error occurred. Please try again.'
      CustomToast(errorMessage, { my_type: 'error', audioRequired: false })
    }
  })

  const addAgain = () => {
    setFileAdd({})
    setIsFileExists(null)
    setUploadId('')
    setUploadProgress(0)
    setIsUploaded(false)
  }

  useEffect(() => {
    if (progress === 100) {
      ID = setTimeout(() => {
        setIsUploaded(true)
      }, 2000)
    }
  }, [progress])

  useEffect(() => {
    if (isTriggerRemoveFile) {
      addAgain()
    }
  }, [isTriggerRemoveFile])

  return (
    <div className="main-div w-100">
      {!fileAdd.length ?
        <Fragment>
          <Row className='bulk-upload'>
            <Col sm='12'>
              <Card>
                <CardBody className={classnames({
                  'container-border': hasBorder,
                  'd-flex flex-column': upload_illustration
                })}>
                  {upload_illustration ?
                    <div className="mb-20px">
                      <BulkUploadSvg primary="var(--bs-primary)" width={140} height={124} />
                    </div>
                    : null}
                  <Row>
                    <Col sm='12'>
                      <div {...getRootProps({ className: 'dropzone' })}>
                        <input {...getInputProps()} />
                        <div className={classnames({
                          'sample-upload d-flex flex-column align-items-center': isContentCenter,
                          'sample-upload d-flex flex-column align-items-start': !isContentCenter
                        })}>
                          <h5>
                            {t('Drag your file here or')}{' '}
                            <a href='/' onClick={e => e.preventDefault()}>
                              {t('Browse')}
                            </a>{' '}
                          </h5>
                          <p className='text-dark-6 txt-sub-rg m-0 mt-4px'>
                            {title ? t(title) : t('Acceptable file format is .csv')}
                          </p>
                        </div>
                      </div>
                      {hasSampleFile && <div className={classnames('txt-body-rg text-dark-6 mt-12px', { 'text-center': isSampleFile })}>
                        <p>
                          {t('For reference')}{' '}
                          <a href={url}>
                            {t('Download a sample file')}
                          </a>
                        </p>
                      </div>}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Fragment> :
        <div className="d-flex align-items-center justify-content-center flex-column file_main_div min-width-300 max-width-500">
          <div className="d-flex align-items-center justify-content-between upload_container_div">
            <div className="h-100 d-flex align-items-center">
              <img src={fileLogo} alt="no file logo" width="36" height="36" />
            </div>
            <div className="w-100 d-flex flex-column justify-content-between upload_file_details text-truncate gap-4px">
              <div className="d-flex gap-1 align-items-center">
                <div className="file-desc file_title w-75 text-truncate">{fileAdd[0]?.name}</div>
                {/* <div className="file_decription">
                                    {fileRowObj?.length} {t("record")} <span className="txt-sub-rg">({Math.round(fileAdd[0]?.size / 1000)} Kb)</span>
                                </div> */}
              </div>
              {isUploaded && <div className='d-flex gap-2px align-items-center'>
                <div><img src={checkCircle} width='70%' /></div>
                <div className='txt-sub-rg text-dark-6'>{t('File uploaded successfully.')}</div>
              </div>}
              {!isUploaded && <div className="progressbar">
                <Progress color="success" value={progress} className="height-4px" />
              </div>}
            </div>
            <div className="cross-icon h-100 d-flex align-items-center cursor-pointer">
              {!isFileUploading && <X size={16} onClick={addAgain} />}
            </div>
          </div>
        </div>
      }
    </div>
  )
}

export default BulkUpload