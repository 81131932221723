import { INPUT_TYPES, TEXT_AREA } from '@src/App.constants'
import { RequiredIcon } from '@src/assets/images/icons/RequiredIcon'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Eye, EyeOff, Info } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { UncontrolledTooltip } from 'reactstrap'
import CustomTooltip from '../tooltip'
import './styles.scss'
import { ErrorIcon } from '/src/assets/images/icons/ErrorIcon'
import { InfoIcon } from '/src/assets/images/icons/InfoIcon'
import { SuccessIcon1 } from '/src/assets/images/icons/SuccessIcon1.jsx'

const InputField = React.forwardRef(
  (
    {
      label,
      isRequired = false,
      errors = null,
      name,
      infoText = '',
      startAdornment = null,
      endAdornment = null,
      isSuccess = false,
      isClearable = false,
      isTooltipError = false,
      disabled = false,
      width = '100%',
      tooltipId,
      type = '',
      tooltipText,
      tooltipPlacement,
      tooltipClassName,
      hideErrorText = false,
      disabledErrorIcon = false,
      ...props
    },
    ref
  ) => {
    const id = React.useRef(Date.now())
    const [inputType, setInputType] = useState(type)
    let isNested = false
    let nestedError
    if (name?.includes('.')) {
      isNested = true
      nestedError = errors
      for (const key of name.split('.')) {
        nestedError = nestedError?.[key]
      }
    }
    const { t } = useTranslation()
    const handleToggleShowPassword = (type) => {
      setInputType(type)
    } 
    useEffect(() => {
      if (type) {
        setInputType(type)
      }
    }, [type])
    return (
      <div className="omniful-floating-input">
        <div className="d-flex align-items-center gap-1">
          <div className={classNames('omniful-floating-input-wrapper',
            { 'invalid-input': (errors?.[name] || nestedError) && !disabled,
              'success-state': isSuccess,
              'disabled-state': disabled,
              'textarea-wrapper' : type === 'textarea'
            }
          )}
          style={{width}}
          >
          
            {startAdornment}
            {type === 'textarea' ?
              <textarea 
                id={id.current} 
                name={name}
                placeholder=" "
                disabled={disabled}
                {...props}
                ref={ref}
              /> :
              <input
                id={id.current}
                type={isClearable ? 'search' : inputType}
                autoComplete="new-password"
                className={classNames({'is-required' : isRequired})}
                placeholder=" "
                name={name}
                disabled={disabled}
                onKeyPress={(e) => {
                // Allow only numeric input
                  if (type === 'number' && (!/^\d*\.?\d*$/.test(e.key))) {
                    e.preventDefault()
                  }
                }}
                {...props}
                ref={ref} // Pass the ref to the input element
              />}  
            <label htmlFor={id.current} className={classNames({'textarea-label' : type === TEXT_AREA, 'label-with-startAdornment': !!startAdornment})}>
              {t(label)}
              {isRequired && (
                <span className="required-icon"> <RequiredIcon/> </span>
              )}
            </label>
            {type === INPUT_TYPES.PASSWORD &&
         <span className="cursor-pointer">
           {type === INPUT_TYPES.PASSWORD ? inputType === INPUT_TYPES.PASSWORD ?
             <EyeOff size={16} onClick={() => handleToggleShowPassword(INPUT_TYPES.TEXT)} />  :
             <Eye size={16} onClick={() => handleToggleShowPassword(INPUT_TYPES.PASSWORD)} /> : null}
         </span>}
            {endAdornment && (isClearable || type === INPUT_TYPES.PASSWORD) && <span className="vertical-divider"></span>}
            {endAdornment}
            {name &&
              isTooltipError &&
              (errors?.[name] || nestedError) &&
              !disabled && (
              <CustomTooltip
                placement="top"
                className="bg-danger border-danger error-text text-white"
                flip={false}
                isVisible={
                  name &&
                    isTooltipError &&
                    (errors?.[name] || nestedError) &&
                    !disabled
                }
                content={isNested ? nestedError?.message : errors?.[name]?.message}
              >
                <span
                  id="__errror_icon_input"
                  className={classNames('error-icon', 
                    {'icon-visible' : isTooltipError && (errors?.[name] || nestedError) && !disabled})}
                >
                  {!disabledErrorIcon &&  <ErrorIcon/>}
                </span>
              </CustomTooltip>
            )}

            {isSuccess && <span className="success-icon"> <SuccessIcon1/> </span>}
          </div>
          {/* } */}

          {tooltipId && <> <div className="w-20px h-20px"><Info size={16} id={tooltipId} /></div>
            <UncontrolledTooltip trigger="hover" offset={[0, 5]} placement={tooltipPlacement || 'bottom'} target={tooltipId} className={tooltipClassName || ''}>
              {t(tooltipText || label)}
            </UncontrolledTooltip>
          </>}
        </div>

        {(errors?.[name] || nestedError) && !disabled && !isTooltipError && !hideErrorText && (
          <div className="d-flex align-items-sm-center error-state">
            <span className="info-icon"> {!disabledErrorIcon &&  <ErrorIcon/>} </span>
            <span className="error-text">
              {isNested ? nestedError?.message : errors?.[name]?.message}
            </span>
          </div>
        )}

        {!((errors?.[name] || nestedError) && !disabled) && infoText && (
          <>
            <span className="info-icon"> <InfoIcon/> </span>
            <span className="info-text">{t(infoText)}</span>
          </>
        )}
      </div>
    )
  }
)

InputField.propTypes = {
  label: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
  errors: PropTypes.object,
  name: PropTypes.string.isRequired,
  infoText: PropTypes.string,
  isSuccess: PropTypes.bool,
  isClearable: PropTypes.bool,
  disabled: PropTypes.bool,
  isTooltipError: PropTypes.bool,
  width: PropTypes.string,
  hideErrorText: PropTypes.bool,
}

export default InputField

