
import CustomToast from '@src/@core/components/ui/custom-toast/CustomToast'
import { useApi } from '@src/configs/react-query/useApi'
import { axiosInstance } from '@src/network/AxiosInstance'
import { setBinDisabledGrnTableData, setScannedSkuDetails, updateEnforcedFailUploadIds } from '@src/views/inventory/store'
import { BATCH_STATUS, SERIALISATION_OPTION } from '../../constant'

export const useGetUpdatedSkuDetails = ({
  sku_details,
  isMaxShelfLifeEnforced,
  isShelfLifeBreached,
  updateNonSerialsedNonBatchedBinDisabledSkuDetails,
  updateEnforcedSerialisedBatchedBinsabledSkuDetails,
  updateSerializedBatchedBinDisabledSkuDetails,
  updateNonSerializedBatchedBinDisabledSkuDetails,
  updateNonSerialisedBatchedEnforcedMarkAsFail,
  grnResponse, 
  tableData,
  dispatch,
  selectedGlobalHubId
}) => {
  const {mutate:getUpdatedSkuDetails} = useApi({
    isMutation:true,
    apiKey:['get-Updated-Sku-Details-by-barcode-non-serialised-skus'],
    apiFn:() => {
      return axiosInstance.get(`/api/v1/wms/hubs/${selectedGlobalHubId}/grns/${grnResponse?.id}/skus/${sku_details?.seller_sku_id}`)
    },
    onSuccess:(data) => {
      const previousSkuDetails = tableData.find(item => (item.batch?.id ? item.seller_sku_id === sku_details?.seller_sku_id && item.batch?.id === sku_details?.batch?.id : item.seller_sku_id === sku_details?.seller_sku_id))
      if (sku_details?.sku_config?.is_batching_enable === false) {
        dispatch(setScannedSkuDetails({
          data:{
            tenant_id: sku_details?.tenant_id,
            seller_id: sku_details?.seller_id,
            seller_sku_id: sku_details?.seller_sku_id,
            seller_sku_code: sku_details?.seller_sku_code,
            images: sku_details?.images,
            barcodes: sku_details?.barcodes || [],
            name: sku_details?.name,
            weight:sku_details?.weight,
            package_type: sku_details?.package_type,
            child_sku: sku_details?.package_level_skus?.[0],
            serialisation_status: sku_details?.sku_config?.is_serialisation_enable === null ? 'undefined' : sku_details?.sku_config?.is_serialisation_enable === true ? SERIALISATION_OPTION.serialised.id : SERIALISATION_OPTION.non_serialised.id,
            batch_status: sku_details?.sku_config?.is_batching_enable === null ? 'undefined' : BATCH_STATUS.NOT_BATCHED,
            is_configuration_editable: sku_details?.sku_config?.is_configuration_editable,
            dimensions: sku_details?.dimensions,
            pass_qty: previousSkuDetails ? previousSkuDetails.pass_qty : 0,
            fail_qty: previousSkuDetails ? previousSkuDetails.fail_qty : 0,
            reasons: previousSkuDetails ? previousSkuDetails.reasons : [],
            pass_barcodes: previousSkuDetails ? previousSkuDetails.pass_barcodes : [],
            fail_barcodes: previousSkuDetails ? previousSkuDetails.fail_barcodes : [],
            grn_details:data?.data?.grn_details,
            scanned_qty: previousSkuDetails ? (previousSkuDetails.pass_qty + previousSkuDetails.fail_qty) : 0
          }}
        ))
      } else if (sku_details?.sku_config?.is_batching_enable === true) {
        dispatch(setScannedSkuDetails({
          data: {
            tenant_id: sku_details?.tenant_id,
            seller_id: sku_details?.seller_id,
            seller_sku_id: sku_details?.seller_sku_id,
            seller_sku_code: sku_details?.seller_sku_code,
            images: sku_details?.images,
            barcodes: sku_details?.barcodes || [],
            name: sku_details?.name,
            weight:sku_details?.weight,
            package_type: sku_details?.package_type,
            child_sku: sku_details?.package_level_skus?.[0],
            serialisation_status: sku_details?.sku_config?.is_serialisation_enable === null ? 'undefined' : sku_details?.sku_config?.is_serialisation_enable === true ? SERIALISATION_OPTION.serialised.id : SERIALISATION_OPTION.non_serialised.id,
            batch_status: BATCH_STATUS.BATCHED,
            is_configuration_editable: sku_details?.sku_config?.is_configuration_editable,
            dimensions: sku_details?.dimensions,
            pass_qty: sku_details?.is_gtin_enabled ? (previousSkuDetails?.pass_qty || 0) : 0,
            fail_qty: sku_details?.is_gtin_enabled ? (previousSkuDetails?.fail_qty || 0) : 0,
            pass_barcodes:sku_details?.is_gtin_enabled && sku_details?.sku_config?.is_serialisation_enable ? previousSkuDetails?.pass_barcodes : [],
            batch:sku_details?.is_gtin_enabled ? sku_details.batch : {},
            fail_barcodes: sku_details?.is_gtin_enabled && sku_details?.sku_config?.is_serialisation_enable ? previousSkuDetails?.fail_barcodes : [],
            grn_details:data?.data?.grn_details,
            scanned_qty: sku_details?.is_gtin_enabled ? ((previousSkuDetails?.pass_qty || 0) + (previousSkuDetails?.fail_qty || 0)) : 0,
            min_shelf_life: sku_details?.sku_config?.min_shelf_life,
            gtin_number: sku_details?.gtin_number,
            gtin_serialisation_number: sku_details?.gtin_serialisation_number,
            is_gtin_enabled: sku_details?.is_gtin_enabled,
            reasons:previousSkuDetails?.reasons || []
          }}
        ))
      } else {
        dispatch(setScannedSkuDetails({
          data: {
            tenant_id: sku_details?.tenant_id,
            seller_id: sku_details?.seller_id,
            seller_sku_id: sku_details?.seller_sku_id,
            seller_sku_code: sku_details?.seller_sku_code,
            images: sku_details?.images,
            barcodes: sku_details?.barcodes || [],
            name: sku_details?.name,
            weight:sku_details?.weight,
            package_type: sku_details?.package_type,
            child_sku: sku_details?.package_level_skus?.[0],
            serialisation_status: undefined,
            batch_status: undefined,
            is_configuration_editable: sku_details?.sku_config?.is_configuration_editable,
            dimensions: sku_details?.dimensions,
            pass_qty: 0,
            fail_qty: 0,
            pass_barcodes:[],
            fail_barcodes:[],
            grn_details:[],
            scanned_qty: 0
          }}
        ))
      }

      if (sku_details?.sku_config?.is_batching_enable !== null && sku_details?.sku_config?.is_serialisation_enable !== null) {
        if (!sku_details?.sku_config?.is_batching_enable && !sku_details?.sku_config?.is_serialisation_enable) {
          updateNonSerialsedNonBatchedBinDisabledSkuDetails({passQty:data?.data?.grn_details?.[0]?.pass_quantity || 0, reasons:data?.data?.grn_details?.[0]?.reasons})
        }
        if (sku_details?.is_gtin_enabled && sku_details.sku_config?.is_batching_enable) {
          if (sku_details?.sku_config?.is_serialisation_enable) {
            if (isMaxShelfLifeEnforced && isShelfLifeBreached) {
              updateEnforcedSerialisedBatchedBinsabledSkuDetails()
            } else {
              updateSerializedBatchedBinDisabledSkuDetails()
            }
          } else {
            if (isMaxShelfLifeEnforced && isShelfLifeBreached) {
              updateNonSerialisedBatchedEnforcedMarkAsFail()
            } else {
              updateNonSerializedBatchedBinDisabledSkuDetails()
            }
          }
        } 
      }
    }
  })

  return getUpdatedSkuDetails
}

export const useNonSerialisedNonBatchedSkuScan = ({
  sku_details, isOverReceiveModalOpen, setIsOverReceiveModalOpen, setIsSkuNotExistModalOpen, grnResponse, tableData, displayGrnDetails, dispatch, selectedGlobalHubId
}) => {
  const {mutate: updateNonSerialsedNonBatchedBinDisabledSkuDetails} = useApi({
    isMutation:true,
    apiKey:['update-Bin-Disabled-Sku-Details-by-barcode-non-serialised-skus'],
    apiFn:({passQty, reasons}) => {
      const reasonsToSend = Array.isArray(reasons) && reasons?.length ? 
        reasons.map((reason) => ({
          ...reason,
          images:[],
          upload_ids:[]
        }))
        : []

      const skuInTable = tableData.find(item => item.seller_sku_id === sku_details?.seller_sku_id)

      const body = {
        pass_quantity: (parseInt(passQty) + 1),
        seller_sku_code: sku_details?.seller_sku_code,
        over_receive: isOverReceiveModalOpen || !!skuInTable?.allow_over_receive,
        return_order_id: undefined,
        reasons:reasonsToSend
      }
      return axiosInstance.post(`/api/v1/wms/hubs/${selectedGlobalHubId}/grns/${grnResponse?.id}`, body)
    },
    onSuccess:(data) => {
      if (data?.data?.is_over_receive || data?.data?.is_sku_not_exist) {
        if (data?.data?.is_over_receive) {
          setIsOverReceiveModalOpen(true)
        } else {
          setIsSkuNotExistModalOpen(true)
        }
      } else {  
        const isSkuPresentInTable = tableData.find(item => item.seller_sku_id === sku_details?.seller_sku_id)
        if (!displayGrnDetails?.is_purchase_order && !displayGrnDetails?.is_sto && !isSkuPresentInTable) {
          dispatch(setScannedSkuDetails({
            data: {
              ...sku_details,
              pass_qty: 1,
              reasons:[],
              scanned_qty: 1
            }
          }))
  
          const dataToPush = {
            id: sku_details?.id,
            name: sku_details?.name,
            code: sku_details?.seller_sku_code,
            image: sku_details?.images?.[0]?.url,
            is_serialised: sku_details?.sku_config?.is_serialisation_enable,
            seller_sku_id: sku_details?.seller_sku_id,
            pass_qty: 1,
            fail_qty: 0,
            total_qty: 0,
            scanned_qty: 1,
            purchase_order_id: sku_details?.purchase_order_id,
            seller_id: sku_details?.seller_id,
            reasons:[]
          }
          const updateTableData = [...tableData, dataToPush]
          dispatch(setBinDisabledGrnTableData(updateTableData))
        } else {
          dispatch(setScannedSkuDetails({
            data: {
              ...sku_details,
              pass_qty:sku_details?.pass_qty + 1,
              scanned_qty:sku_details?.scanned_qty + 1
            }
          }))

          const updateTableData = tableData.map((item) => {
            return item.seller_sku_id === sku_details?.seller_sku_id ? { ...item, pass_qty: item.pass_qty + 1, scanned_qty: item.scanned_qty + 1 } : item
          })
          dispatch(setBinDisabledGrnTableData(updateTableData))
        }
        CustomToast('1 Item passed successfully', { my_type: 'success', duration:2000 })
      }
    }
  })

  return updateNonSerialsedNonBatchedBinDisabledSkuDetails
}

export const useNonSerialisedNonBatchedSkuScanFromModal = ({
  sku_details, isOverReceiveModalOpen, isSkuNotExistModalOpen, setIsOverReceiveModalOpen, setIsSkuNotExistModalOpen, grnResponse, tableData, dispatch, selectedGlobalHubId
}) => {
  const { mutate: updateNonSerialisedNonBatchedBinDisabledSkuDetailsFromModal, isPending:nonSerialisedNonBatchedloadingState } = useApi({
    isMutation: true,
    apiKey: ['update-over-or-not-exist-Bin-Disabled-Sku-Details-by-barcode-non-serialised-skus'],
    apiFn: () => {
      const reasonsToSend = Array.isArray(sku_details?.reasons) && sku_details?.reasons?.length ? 
        sku_details.reasons.map((reason) => ({
          ...reason,
          images:[],
          upload_ids:[]
        }))
        : []

      const body = {
        pass_quantity: (parseInt(sku_details?.pass_qty) + 1),
        seller_sku_code: sku_details?.seller_sku_code,
        over_receive: isOverReceiveModalOpen || isSkuNotExistModalOpen,
        return_order_id: undefined,
        reasons: reasonsToSend
      }
      return axiosInstance.post(`/api/v1/wms/hubs/${selectedGlobalHubId}/grns/${grnResponse?.id}`, body)
    },
    onSuccess: () => {
      if (isSkuNotExistModalOpen) {
        const isSkuPresentInTable = tableData.find(item => item.seller_sku_id === sku_details?.seller_sku_id) 
        if (isSkuPresentInTable) {
          dispatch(setScannedSkuDetails({
            data: {
              ...sku_details,
              pass_qty:sku_details?.pass_qty + 1,
              scanned_qty:sku_details?.scanned_qty + 1
            }
          }))
  
          const updateTableData = tableData.map((item) => {
            return item.seller_sku_id === sku_details?.seller_sku_id ? { ...item, pass_qty: item.pass_qty + 1, scanned_qty: item.scanned_qty + 1 } : item
          })
          dispatch(setBinDisabledGrnTableData(updateTableData))
        } else {
          dispatch(setScannedSkuDetails({
            data: {
              ...sku_details,
              pass_qty: 1,
              scanned_qty: 1,
              reasons:[]
            }
          }))
  
          const dataToPush = {
            id: sku_details?.id,
            name: sku_details?.name,
            code: sku_details?.seller_sku_code,
            image: sku_details?.images?.[0]?.url,
            is_serialised: sku_details?.sku_config?.is_serialisation_enable,
            seller_sku_id: sku_details?.seller_sku_id,
            pass_qty: 1,
            scanned_qty: 1,
            fail_qty: 0,
            total_qty: sku_details?.quantity || 0,
            purchase_order_id: sku_details?.purchase_order_id,
            seller_id: sku_details?.seller_id,
            reasons:[]
          }
          const updateTableData = [...tableData, dataToPush]
          dispatch(setBinDisabledGrnTableData(updateTableData))
        }
      }

      if (isOverReceiveModalOpen) {

        dispatch(setScannedSkuDetails({
          data: {
            ...sku_details,
            pass_qty:sku_details?.pass_qty + 1,
            scanned_qty:sku_details?.scanned_qty + 1
          }
        }))

        const updateTableData = tableData.map((item) => {
          return item.seller_sku_id === sku_details?.seller_sku_id ? { ...item, pass_qty: item.pass_qty + 1, scanned_qty: item.scanned_qty + 1, allow_over_receive: true } : item
        })
        dispatch(setBinDisabledGrnTableData(updateTableData))
      }
      
      CustomToast('1 Item passed successfully', { my_type: 'success', duration:2000 })
      setIsSkuNotExistModalOpen(false)
      setIsOverReceiveModalOpen(false)
    }
  })

  return {updateNonSerialisedNonBatchedBinDisabledSkuDetailsFromModal, nonSerialisedNonBatchedloadingState}
}

export const nonSerialisedBatchedSkuScan = ({
  sku_details, isOverReceiveModalOpen, returnGrnResponse, setIsOverReceiveModalOpen, setIsSkuNotExistModalOpen, grnResponse, tableData, displayGrnDetails, dispatch, selectedGlobalHubId, scannedBarcode
}) => {
  const { mutate: updateNonSerializedBatchedBinDisabledSkuDetails} = useApi({
    isMutation: true,
    apiKey: ['update-Bin-Disabled-Sku-Details-on-changing-the-qty-manually'],
    apiFn: () => {
      const scannedQty = sku_details?.scanned_qty + 1
      const reasonsToSend = Array.isArray(sku_details?.reasons) && sku_details?.reasons?.length ? 
        sku_details.reasons.map((reason) => ({
          ...reason,
          images:[],
          upload_ids:[]
        }))
        : []

      const skuInTable = tableData.find(item => item.seller_sku_id === sku_details?.seller_sku_id)

      const body = {
        pass_quantity: parseInt(scannedQty - sku_details?.fail_qty),
        seller_sku_code: sku_details?.seller_sku_code,
        over_receive: isOverReceiveModalOpen || !!skuInTable?.allow_over_receive,
        batch_id: sku_details?.batch?.id,
        reasons: reasonsToSend,
        return_order_id: returnGrnResponse?.return_order_detail?.id,
        non_serialised_gtin_barcode: scannedBarcode
      }
      const grn_id = grnResponse?.id || returnGrnResponse?.id
      return axiosInstance.post(`/api/v1/wms/hubs/${selectedGlobalHubId}/grns/${grn_id}`, body)
    },
    onSuccess: (data) => {
      const scannedQty = sku_details?.scanned_qty + 1
      const changedQuantity = scannedQty - sku_details?.fail_qty

      if (data?.data?.is_over_receive || data?.data?.is_sku_not_exist) {
        if (data?.data?.is_over_receive) {
          setIsOverReceiveModalOpen(true)
        } else {
          setIsSkuNotExistModalOpen(true)
        }
      } else {
        if (sku_details?.batch_status === BATCH_STATUS.BATCHED) {
          const isPresentInTable = tableData.find(item => (item.batch?.id ? item.seller_sku_id === sku_details?.seller_sku_id && item.batch?.id === sku_details?.batch?.id : item.seller_sku_id === sku_details?.seller_sku_id))
          if (!displayGrnDetails?.is_purchase_order && !displayGrnDetails?.is_sto && !isPresentInTable) {
            dispatch(setScannedSkuDetails({
              data: {
                ...sku_details,
                pass_qty: changedQuantity,
                batch: sku_details?.batch,
                scanned_qty: changedQuantity,
                reasons: []
              }
            }))

            const dataToPush = {
              id: sku_details?.seller_sku_id,
              name: sku_details?.name,
              code: sku_details?.seller_sku_code,
              image: sku_details?.images?.[0]?.url,
              is_serialised: !!(sku_details?.serialisation_status === SERIALISATION_OPTION.serialised.id),
              seller_sku_id: sku_details?.seller_sku_id,
              pass_qty: changedQuantity,
              fail_qty: 0,
              total_qty: sku_details?.quantity || 0,
              purchase_order_id: sku_details?.purchase_order_id,
              seller_id: sku_details?.seller_id,
              reasons: [],
              scanned_qty: changedQuantity,
              batch: sku_details?.batch
            }
            const updateTableData = [...tableData, dataToPush]
            dispatch(setBinDisabledGrnTableData(updateTableData))
          } else {
            if (isPresentInTable) {
              if (isPresentInTable?.batch?.id) {
                dispatch(setScannedSkuDetails({
                  data: {
                    ...sku_details,
                    pass_qty: changedQuantity,
                    batch: sku_details?.batch,
                    scanned_qty: changedQuantity + sku_details?.fail_qty
                  }
                }))

                const updateTableData = tableData.map((item) => {
                  return item.seller_sku_id === sku_details.seller_sku_id && item.batch?.id === sku_details?.batch?.id ? {
                    ...item,
                    pass_qty: changedQuantity,
                    batch: sku_details?.batch,
                    scanned_qty: changedQuantity + sku_details?.fail_qty
                  } : item
                })
                dispatch(setBinDisabledGrnTableData(updateTableData))
              } else if (!isPresentInTable?.batch?.id) {
                dispatch(setScannedSkuDetails({
                  data: {
                    ...sku_details,
                    pass_qty: changedQuantity,
                    batch: sku_details?.batch,
                    scanned_qty: changedQuantity + sku_details?.fail_qty
                  }
                }))

                const updateTableData = tableData.map((item) => {
                  return item.seller_sku_id === sku_details.seller_sku_id ? {
                    ...item,
                    pass_qty: changedQuantity,
                    batch: sku_details?.batch,
                    scanned_qty: changedQuantity + sku_details?.fail_qty
                  } : item
                })
                dispatch(setBinDisabledGrnTableData(updateTableData))
              }
            } else {
              dispatch(setScannedSkuDetails({
                data: {
                  ...sku_details,
                  pass_qty: changedQuantity,
                  batch: sku_details?.batch,
                  scanned_qty: changedQuantity,
                  reasons: []
                }
              }))

              const dataToPush = {
                id: sku_details?.seller_sku_id,
                name: sku_details?.name,
                code: sku_details?.seller_sku_code,
                image: sku_details?.images?.[0]?.url,
                is_serialised: !!(sku_details?.serialisation_status === SERIALISATION_OPTION.serialised.id),
                seller_sku_id: sku_details?.seller_sku_id,
                pass_qty: changedQuantity,
                fail_qty: 0,
                total_qty: sku_details?.quantity || 0,
                purchase_order_id: sku_details?.purchase_order_id,
                seller_id: sku_details?.seller_id,
                reasons: [],
                scanned_qty: changedQuantity,
                batch: sku_details?.batch
              }
              const updateTableData = [...tableData, dataToPush]
              dispatch(setBinDisabledGrnTableData(updateTableData))
            }
          }
        } else {
          const isPresentInTable = tableData.find(item => item.seller_sku_id === sku_details?.seller_sku_id)
          if (!displayGrnDetails?.is_purchase_order && !displayGrnDetails?.is_sto && !isPresentInTable) {
            dispatch(setScannedSkuDetails({
              data: {
                ...sku_details,
                pass_qty: changedQuantity,
                scanned_qty: changedQuantity,
                reasons: []
              }
            }))

            const dataToPush = {
              id: sku_details?.seller_sku_id,
              name: sku_details?.name,
              code: sku_details?.seller_sku_code,
              image: sku_details?.images?.[0]?.url,
              is_serialised: !!(sku_details?.serialisation_status === SERIALISATION_OPTION.serialised.id),
              seller_sku_id: sku_details?.seller_sku_id,
              pass_qty: changedQuantity,
              fail_qty: 0,
              total_qty: sku_details?.quantity || 0,
              purchase_order_id: sku_details?.purchase_order_id,
              seller_id: sku_details?.seller_id,
              reasons: [],
              scanned_qty: changedQuantity
            }
            const updateTableData = [...tableData, dataToPush]
            dispatch(setBinDisabledGrnTableData(updateTableData))
          } else {
            dispatch(setScannedSkuDetails({
              data: {
                ...sku_details,
                pass_qty: changedQuantity,
                scanned_qty: changedQuantity + sku_details?.fail_qty
              }
            }))
  
            const updateTableData = tableData.map((item) => {
              return item.seller_sku_id === sku_details.seller_sku_id ? {
                ...item,
                pass_qty: changedQuantity,
                scanned_qty: changedQuantity + sku_details?.fail_qty
              } : item
            })
            dispatch(setBinDisabledGrnTableData(updateTableData))
          }
        }
        CustomToast('1 Item passed successfully', { my_type: 'success', duration:2000 })
      }
    }
  })

  return updateNonSerializedBatchedBinDisabledSkuDetails
}

export const nonSerialisedBatchedSkuScanFromModal = ({
  skuDetails, isOverReceiveModalOpen, isSkuNotPartModalOpen, setIsSkuNotPartModalOpen, setIsOverReceiveModalOpen, grnResponse, tableData, displayGrnDetails, returnGrnResponse, selectedGlobalHubId, dispatch, scannedBarcode
}) => {
  const { mutate: updateNonSerializedBatchedBinDisabledSkuDetailsFromModal, isPending:nonSerialisedBatchedloadingState} = useApi({
    isMutation: true,
    apiKey: ['update-Bin-Disabled-Sku-Details-on-changing-the-qty-manually-from-modal'],
    apiFn: () => {
      const scannedQty = skuDetails?.scanned_qty + 1
      const changedQuantity = scannedQty - skuDetails?.fail_qty

      const reasonsToSend = Array.isArray(skuDetails?.reasons) && skuDetails?.reasons?.length ? 
        skuDetails.reasons.map((reason) => ({
          ...reason,
          images:[],
          upload_ids:[]
        }))
        : []

      const body = {
        pass_quantity: parseInt(changedQuantity),
        seller_sku_code: skuDetails?.seller_sku_code,
        over_receive: isOverReceiveModalOpen || isSkuNotPartModalOpen,
        batch_id: skuDetails?.batch?.id,
        reasons: reasonsToSend,
        return_order_id: returnGrnResponse?.return_order_detail?.id,
        non_serialised_gtin_barcode: scannedBarcode
      }
      const grn_id = grnResponse?.id || returnGrnResponse?.id
      return axiosInstance.post(`/api/v1/wms/hubs/${selectedGlobalHubId}/grns/${grn_id}`, body)
    },
    onSuccess: () => {
      const scannedQty = skuDetails?.scanned_qty + 1
      const changedQuantity = scannedQty - skuDetails?.fail_qty
      if (isSkuNotPartModalOpen) {
        if (skuDetails?.batch_status === BATCH_STATUS.BATCHED) {
          const isPresentInTable = tableData.find(item => (item.batch?.id ? item.seller_sku_id === skuDetails?.seller_sku_id && item.batch?.id === skuDetails?.batch?.id : item.seller_sku_id === skuDetails?.seller_sku_id))
          if (isPresentInTable) {
            if (isPresentInTable?.batch?.id) {
              dispatch(setScannedSkuDetails({
                data: {
                  ...skuDetails,
                  pass_qty: changedQuantity,
                  batch: skuDetails?.batch,
                  scanned_qty: changedQuantity + skuDetails?.fail_qty
                }
              }))

              const updateTableData = tableData.map((item) => {
                return item.seller_sku_id === skuDetails.seller_sku_id && item.batch?.id === skuDetails?.batch?.id ? {
                  ...item,
                  pass_qty: changedQuantity,
                  batch: skuDetails?.batch,
                  scanned_qty: changedQuantity + skuDetails?.fail_qty
                } : item
              })
              dispatch(setBinDisabledGrnTableData(updateTableData))
            } else if (!isPresentInTable?.batch?.id) {
              dispatch(setScannedSkuDetails({
                data: {
                  ...skuDetails,
                  pass_qty: changedQuantity,
                  batch: skuDetails?.batch,
                  scanned_qty: changedQuantity + skuDetails?.fail_qty
                }
              }))

              const updateTableData = tableData.map((item) => {
                return item.seller_sku_id === skuDetails.seller_sku_id ? {
                  ...item,
                  pass_qty: changedQuantity,
                  batch: skuDetails?.batch,
                  scanned_qty: changedQuantity + skuDetails?.fail_qty
                } : item
              })
              dispatch(setBinDisabledGrnTableData(updateTableData))
            }
          } else {
            dispatch(setScannedSkuDetails({
              data: {
                ...skuDetails,
                pass_qty: changedQuantity,
                batch: skuDetails?.batch,
                scanned_qty: changedQuantity,
                reasons: []
              }
            }))

            const dataToPush = {
              id: skuDetails?.seller_sku_id,
              name: skuDetails?.name,
              code: skuDetails?.seller_sku_code,
              image: skuDetails?.images?.[0]?.url,
              is_serialised: !!(skuDetails?.serialisation_status === SERIALISATION_OPTION.serialised.id),
              seller_sku_id: skuDetails?.seller_sku_id,
              pass_qty: changedQuantity,
              fail_qty: 0,
              total_qty: skuDetails?.quantity || 0,
              purchase_order_id: skuDetails?.purchase_order_id,
              seller_id: skuDetails?.seller_id,
              reasons: [],
              scanned_qty: changedQuantity,
              batch: skuDetails?.batch
            }
            const updateTableData = [...tableData, dataToPush]
            dispatch(setBinDisabledGrnTableData(updateTableData))
          }
        } else {
          const isPresentInTable = tableData.find(item => item.seller_sku_id === skuDetails?.seller_sku_id)
          if (isPresentInTable) {
            dispatch(setScannedSkuDetails({
              data: {
                ...skuDetails,
                pass_qty: changedQuantity,
                scanned_qty: changedQuantity + skuDetails?.fail_qty
              }
            }))

            const updateTableData = tableData.map((item) => {
              return item.seller_sku_id === skuDetails.seller_sku_id ? {
                ...item,
                pass_qty: changedQuantity,
                scanned_qty: changedQuantity + skuDetails?.fail_qty
              } : item
            })
            dispatch(setBinDisabledGrnTableData(updateTableData))
          } else {
            dispatch(setScannedSkuDetails({
              data: {
                ...skuDetails,
                pass_qty: changedQuantity,
                scanned_qty: changedQuantity,
                reasons: []
              }
            }))

            const dataToPush = {
              id: skuDetails?.seller_sku_id,
              name: skuDetails?.name,
              code: skuDetails?.seller_sku_code,
              image: skuDetails?.images?.[0]?.url,
              is_serialised: !!(skuDetails?.serialisation_status === SERIALISATION_OPTION.serialised.id),
              seller_sku_id: skuDetails?.seller_sku_id,
              pass_qty: changedQuantity,
              fail_qty: 0,
              total_qty: skuDetails?.quantity || 0,
              purchase_order_id: skuDetails?.purchase_order_id,
              seller_id: skuDetails?.seller_id,
              reasons: [],
              scanned_qty: changedQuantity
            }
            const updateTableData = [...tableData, dataToPush]
            dispatch(setBinDisabledGrnTableData(updateTableData))
          }
        }
      }

      if (isOverReceiveModalOpen) {
        if (skuDetails?.batch_status === BATCH_STATUS.BATCHED) {
          const isPresentInTable = tableData.find(item => (item.batch?.id ? item.seller_sku_id === skuDetails?.seller_sku_id && item.batch?.id === skuDetails?.batch?.id : item.seller_sku_id === skuDetails?.seller_sku_id))
          if (!displayGrnDetails?.is_purchase_order && !displayGrnDetails?.is_sto && !isPresentInTable) {
            dispatch(setScannedSkuDetails({
              data: {
                ...skuDetails,
                pass_qty: changedQuantity,
                batch: skuDetails?.batch,
                scanned_qty: changedQuantity,
                reasons: []
              }
            }))

            const dataToPush = {
              id: skuDetails?.seller_sku_id,
              name: skuDetails?.name,
              code: skuDetails?.seller_sku_code,
              image: skuDetails?.images?.[0]?.url,
              is_serialised: !!(skuDetails?.serialisation_status === SERIALISATION_OPTION.serialised.id),
              seller_sku_id: skuDetails?.seller_sku_id,
              pass_qty: changedQuantity,
              fail_qty: 0,
              total_qty: skuDetails?.quantity || 0,
              purchase_order_id: skuDetails?.purchase_order_id,
              seller_id: skuDetails?.seller_id,
              reasons: [],
              scanned_qty: changedQuantity,
              batch: skuDetails?.batch,
              allow_over_receive: true
            }
            const updateTableData = [...tableData, dataToPush]
            dispatch(setBinDisabledGrnTableData(updateTableData))
          } else {
            if (isPresentInTable) {
              if (isPresentInTable?.batch?.id) {
                dispatch(setScannedSkuDetails({
                  data: {
                    ...skuDetails,
                    pass_qty: changedQuantity,
                    batch: skuDetails?.batch,
                    scanned_qty: changedQuantity + skuDetails?.fail_qty
                  }
                }))

                const updateTableData = tableData.map((item) => {
                  return item.seller_sku_id === skuDetails.seller_sku_id && item.batch?.id === skuDetails?.batch?.id ? {
                    ...item,
                    pass_qty: changedQuantity,
                    batch: skuDetails?.batch,
                    scanned_qty: changedQuantity + skuDetails?.fail_qty,
                    allow_over_receive: true
                  } : item
                })
                dispatch(setBinDisabledGrnTableData(updateTableData))
              } else if (!isPresentInTable?.batch?.id) {
                dispatch(setScannedSkuDetails({
                  data: {
                    ...skuDetails,
                    pass_qty: changedQuantity,
                    batch: skuDetails?.batch,
                    scanned_qty: changedQuantity + skuDetails?.fail_qty
                  }
                }))

                const updateTableData = tableData.map((item) => {
                  return item.seller_sku_id === skuDetails.seller_sku_id ? {
                    ...item,
                    pass_qty: changedQuantity,
                    batch: skuDetails?.batch,
                    scanned_qty: changedQuantity + skuDetails?.fail_qty,
                    allow_over_receive: true
                  } : item
                })
                dispatch(setBinDisabledGrnTableData(updateTableData))
              }
            } else {
              dispatch(setScannedSkuDetails({
                data: {
                  ...skuDetails,
                  pass_qty: changedQuantity,
                  batch: skuDetails?.batch,
                  scanned_qty: changedQuantity,
                  reasons: []
                }
              }))

              const dataToPush = {
                id: skuDetails?.seller_sku_id,
                name: skuDetails?.name,
                code: skuDetails?.seller_sku_code,
                image: skuDetails?.images?.[0]?.url,
                is_serialised: !!(skuDetails?.serialisation_status === SERIALISATION_OPTION.serialised.id),
                seller_sku_id: skuDetails?.seller_sku_id,
                pass_qty: changedQuantity,
                fail_qty: 0,
                total_qty: skuDetails?.quantity || 0,
                purchase_order_id: skuDetails?.purchase_order_id,
                seller_id: skuDetails?.seller_id,
                reasons: [],
                scanned_qty: changedQuantity,
                batch: skuDetails?.batch,
                allow_over_receive: true
              }
              const updateTableData = [...tableData, dataToPush]
              dispatch(setBinDisabledGrnTableData(updateTableData))
            }
          }
        } else {
          dispatch(setScannedSkuDetails({
            data: {
              ...skuDetails,
              pass_qty: changedQuantity,
              scanned_qty: changedQuantity + skuDetails?.fail_qty
            }
          }))

          const updateTableData = tableData.map((item) => {
            return item.seller_sku_id === skuDetails.seller_sku_id ? {
              ...item,
              pass_qty: changedQuantity,
              scanned_qty: changedQuantity + skuDetails?.fail_qty,
              allow_over_receive: true
            } : item
          })
          dispatch(setBinDisabledGrnTableData(updateTableData))
        }
      }
      
      CustomToast('1 Item passed successfully', { my_type: 'success', duration:2000 })
      
      setIsSkuNotPartModalOpen(false)
      setIsOverReceiveModalOpen(false)
    }
  })

  return {updateNonSerializedBatchedBinDisabledSkuDetailsFromModal, nonSerialisedBatchedloadingState}
} 

export const useEnforcedFailNonSerialisedBatchedSkuScan = ({
  skuDetails, isOverReceiveModalOpen, setIsOverReceiveModalOpen, setIsSkuNotPartModalOpen, tableData, enforcedFailReason, enforcedFailUploadIds, returnGrnResponse, selectedGlobalHubId, grnResponse, displayGrnDetails, dispatch, scannedBarcode
}) => {
  const {mutate: updateNonSerialisedBatchedEnforcedMarkAsFail} = useApi({
    isMutation:true,
    apiKey:['enforced-mark-as-fail'],
    apiFn: () => {
      const scannedQty = skuDetails?.scanned_qty + 1

      if (scannedQty < skuDetails?.fail_qty) {
        CustomToast('Scanned quantity cannot be less than failed quantity.', { my_type: 'error', audioRequired: true })
        dispatch(setScannedSkuDetails({
          data: {
            ...skuDetails,
            scanned_qty: skuDetails?.pass_qty + skuDetails?.fail_qty
          }
        }))
        return
      }

      const reasons = [
        {
          id:enforcedFailReason.id,
          quantity: scannedQty,
          images:[],
          upload_ids: enforcedFailUploadIds || []
        }
      ]
      const body = {
        batch_id:skuDetails?.batch?.id,
        seller_sku_code:skuDetails?.seller_sku_code,
        over_receive: isOverReceiveModalOpen,
        reasons,
        pass_quantity:0,
        non_serialised_gtin_barcode: scannedBarcode
      }
      const grn_id = grnResponse?.id || returnGrnResponse?.id
      return axiosInstance.post(`/api/v1/wms/hubs/${selectedGlobalHubId}/grns/${grn_id}`, body)
    },
    onSuccess: (data) => {
      const scannedQty = skuDetails?.scanned_qty + 1
      const changedQuantity = scannedQty - skuDetails?.pass_qty

      if (data?.data?.is_over_receive || data?.data?.is_sku_not_exist) {
        if (data?.data?.is_over_receive) {
          setIsOverReceiveModalOpen(true)
        } else {
          setIsSkuNotPartModalOpen(true)
        }
      } else {
        dispatch(updateEnforcedFailUploadIds([]))
        if (skuDetails?.batch_status === BATCH_STATUS.BATCHED) {
          const isPresentInTable = tableData.find(item => (item.batch?.id ? item.seller_sku_id === skuDetails?.seller_sku_id && item.batch?.id === skuDetails?.batch?.id : item.seller_sku_id === skuDetails?.seller_sku_id))
          if (!displayGrnDetails?.is_purchase_order && !displayGrnDetails?.is_sto && !isPresentInTable) {
            dispatch(setScannedSkuDetails({
              data: {
                ...skuDetails,
                pass_qty: 0,
                batch: skuDetails?.batch,
                fail_qty: changedQuantity,
                scanned_qty: changedQuantity,
                reasons: []
              }
            }))

            const dataToPush = {
              id: skuDetails?.seller_sku_id,
              name: skuDetails?.name,
              code: skuDetails?.seller_sku_code,
              image: skuDetails?.images?.[0]?.url,
              is_serialised: !!(skuDetails?.serialisation_status === SERIALISATION_OPTION.serialised.id),
              seller_sku_id: skuDetails?.seller_sku_id,
              pass_qty: 0,
              fail_qty: changedQuantity,
              total_qty: skuDetails?.quantity || 0,
              purchase_order_id: skuDetails?.purchase_order_id,
              seller_id: skuDetails?.seller_id,
              reasons: [],
              scanned_qty: changedQuantity,
              batch: skuDetails?.batch
            }
            const updateTableData = [...tableData, dataToPush]
            dispatch(setBinDisabledGrnTableData(updateTableData))
          } else {
            if (isPresentInTable) {
              if (isPresentInTable?.batch?.id) {
                dispatch(setScannedSkuDetails({
                  data: {
                    ...skuDetails,
                    pass_qty: 0,
                    fail_qty:changedQuantity,
                    batch: skuDetails?.batch,
                    scanned_qty: changedQuantity + skuDetails?.pass_qty
                  }
                }))

                const updateTableData = tableData.map((item) => {
                  return item.seller_sku_id === skuDetails.seller_sku_id && item.batch?.id === skuDetails?.batch?.id ? {
                    ...item,
                    pass_qty: 0,
                    fail_qty:changedQuantity,
                    batch: skuDetails?.batch,
                    scanned_qty: changedQuantity + skuDetails?.pass_qty
                  } : item
                })
                dispatch(setBinDisabledGrnTableData(updateTableData))
              } else if (!isPresentInTable?.batch?.id) {
                dispatch(setScannedSkuDetails({
                  data: {
                    ...skuDetails,
                    pass_qty: 0,
                    fail_qty:changedQuantity,
                    batch: skuDetails?.batch,
                    scanned_qty: changedQuantity + skuDetails?.pass_qty
                  }
                }))

                const updateTableData = tableData.map((item) => {
                  return item.seller_sku_id === skuDetails.seller_sku_id ? {
                    ...item,
                    pass_qty: 0,
                    fail_qty:changedQuantity,
                    batch: skuDetails?.batch,
                    scanned_qty: changedQuantity + skuDetails?.pass_qty
                  } : item
                })
                dispatch(setBinDisabledGrnTableData(updateTableData))
              }
            } else {
              dispatch(setScannedSkuDetails({
                data: {
                  ...skuDetails,
                  pass_qty: 0,
                  fail_qty:changedQuantity,
                  batch: skuDetails?.batch,
                  scanned_qty: changedQuantity,
                  reasons: []
                }
              }))

              const dataToPush = {
                id: skuDetails?.seller_sku_id,
                name: skuDetails?.name,
                code: skuDetails?.seller_sku_code,
                image: skuDetails?.images?.[0]?.url,
                is_serialised: !!(skuDetails?.serialisation_status === SERIALISATION_OPTION.serialised.id),
                seller_sku_id: skuDetails?.seller_sku_id,
                pass_qty: 0,
                fail_qty: changedQuantity,
                total_qty: skuDetails?.quantity || 0,
                purchase_order_id: skuDetails?.purchase_order_id,
                seller_id: skuDetails?.seller_id,
                reasons: [],
                scanned_qty: changedQuantity,
                batch: skuDetails?.batch
              }
              const updateTableData = [...tableData, dataToPush]
              dispatch(setBinDisabledGrnTableData(updateTableData))
            }
          }
        }

        CustomToast('1 Item failed successfully', { my_type: 'success', duration:2000 })
      }
    }
  })

  return updateNonSerialisedBatchedEnforcedMarkAsFail
}

export const useEnforcedFailNonSerialisedBatchedSkuScanFromModal = ({
  skuDetails, isOverReceiveModalOpen, setIsOverReceiveModalOpen, setIsSkuNotPartModalOpen, isSkuNotPartModalOpen, tableData, enforcedFailReason, enforcedFailUploadIds, returnGrnResponse, selectedGlobalHubId, grnResponse, displayGrnDetails, dispatch, scannedBarcode
}) => {
  const { mutate: updateNonSerialisedBatchedEnforcedMarkAsFailModalOpen, isPending:enforcedFailNonSerialisedBatchedloadingState } = useApi({
    isMutation: true,
    apiKey: ['enforcedMarkAsFailModalOpen'],
    apiFn: () => {
      const scannedQty = skuDetails?.scanned_qty + 1
      const changedQuantity = scannedQty - skuDetails?.pass_qty

      const reasons = [
        {
          id:enforcedFailReason.id,
          quantity: parseInt(changedQuantity),
          images:[],
          upload_ids: enforcedFailUploadIds || []
        }
      ]
      const body = {
        batch_id:skuDetails?.batch?.id,
        seller_sku_code:skuDetails?.seller_sku_code,
        over_receive: isOverReceiveModalOpen,
        reasons,
        pass_quantity:0,
        non_serialised_gtin_barcode: scannedBarcode
      }
      const grn_id = grnResponse?.id || returnGrnResponse?.id
      return axiosInstance.post(`/api/v1/wms/hubs/${selectedGlobalHubId}/grns/${grn_id}`, body)
    },
    onSuccess: () => {
      const scannedQty = skuDetails?.scanned_qty + 1
      const changedQuantity = scannedQty - skuDetails?.pass_qty

      dispatch(updateEnforcedFailUploadIds([]))
      if (isSkuNotPartModalOpen) {
        if (skuDetails?.batch_status === BATCH_STATUS.BATCHED) {
          const isPresentInTable = tableData.find(item => (item.batch?.id ? item.seller_sku_id === skuDetails?.seller_sku_id && item.batch?.id === skuDetails?.batch?.id : item.seller_sku_id === skuDetails?.seller_sku_id))
          if (isPresentInTable) {
            if (isPresentInTable?.batch?.id) {
              dispatch(setScannedSkuDetails({
                data: {
                  ...skuDetails,
                  pass_qty: 0,
                  fail_qty:changedQuantity,
                  batch: skuDetails?.batch,
                  scanned_qty: changedQuantity + skuDetails?.pass_qty
                }
              }))

              const updateTableData = tableData.map((item) => {
                return item.seller_sku_id === skuDetails.seller_sku_id && item.batch?.id === skuDetails?.batch?.id ? {
                  ...item,
                  pass_qty: 0,
                  fail_qty:changedQuantity,
                  batch: skuDetails?.batch,
                  scanned_qty: changedQuantity + skuDetails?.pass_qty
                } : item
              })
              dispatch(setBinDisabledGrnTableData(updateTableData))
            } else if (!isPresentInTable?.batch?.id) {
              dispatch(setScannedSkuDetails({
                data: {
                  ...skuDetails,
                  pass_qty: 0,
                  fail_qty:changedQuantity,
                  batch: skuDetails?.batch,
                  scanned_qty: changedQuantity + skuDetails?.pass_qty
                }
              }))

              const updateTableData = tableData.map((item) => {
                return item.seller_sku_id === skuDetails.seller_sku_id ? {
                  ...item,
                  pass_qty: 0,
                  fail_qty:changedQuantity,
                  batch: skuDetails?.batch,
                  scanned_qty: changedQuantity + skuDetails?.pass_qty
                } : item
              })
              dispatch(setBinDisabledGrnTableData(updateTableData))
            }
          } else {
            dispatch(setScannedSkuDetails({
              data: {
                ...skuDetails,
                pass_qty: 0,
                fail_qty:changedQuantity,
                batch: skuDetails?.batch,
                scanned_qty: changedQuantity,
                reasons: []
              }
            }))

            const dataToPush = {
              id: skuDetails?.seller_sku_id,
              name: skuDetails?.name,
              code: skuDetails?.seller_sku_code,
              image: skuDetails?.images?.[0]?.url,
              is_serialised: !!(skuDetails?.serialisation_status === SERIALISATION_OPTION.serialised.id),
              seller_sku_id: skuDetails?.seller_sku_id,
              pass_qty: 0,
              fail_qty: changedQuantity,
              total_qty: skuDetails?.quantity || 0,
              purchase_order_id: skuDetails?.purchase_order_id,
              seller_id: skuDetails?.seller_id,
              reasons: [],
              scanned_qty: changedQuantity,
              batch: skuDetails?.batch
            }
            const updateTableData = [...tableData, dataToPush]
            dispatch(setBinDisabledGrnTableData(updateTableData))
          }
        }
      }

      if (isOverReceiveModalOpen) {
        if (skuDetails?.batch_status === BATCH_STATUS.BATCHED) {
          const isPresentInTable = tableData.find(item => (item.batch?.id ? item.seller_sku_id === skuDetails?.seller_sku_id && item.batch?.id === skuDetails?.batch?.id : item.seller_sku_id === skuDetails?.seller_sku_id))
          if (!displayGrnDetails?.is_purchase_order && !displayGrnDetails?.is_sto && !isPresentInTable) {
            dispatch(setScannedSkuDetails({
              data: {
                ...skuDetails,
                pass_qty: 0,
                fail_qty:changedQuantity,
                batch: skuDetails?.batch,
                scanned_qty: changedQuantity,
                reasons: []
              }
            }))

            const dataToPush = {
              id: skuDetails?.seller_sku_id,
              name: skuDetails?.name,
              code: skuDetails?.seller_sku_code,
              image: skuDetails?.images?.[0]?.url,
              is_serialised: !!(skuDetails?.serialisation_status === SERIALISATION_OPTION.serialised.id),
              seller_sku_id: skuDetails?.seller_sku_id,
              pass_qty: 0,
              fail_qty: changedQuantity,
              total_qty: skuDetails?.quantity || 0,
              purchase_order_id: skuDetails?.purchase_order_id,
              seller_id: skuDetails?.seller_id,
              reasons: [],
              scanned_qty: changedQuantity,
              batch: skuDetails?.batch,
              allow_over_receive: true
            }
            const updateTableData = [...tableData, dataToPush]
            dispatch(setBinDisabledGrnTableData(updateTableData))
          } else {
            if (isPresentInTable) {
              if (isPresentInTable?.batch?.id) {
                dispatch(setScannedSkuDetails({
                  data: {
                    ...skuDetails,
                    pass_qty: 0,
                    fail_qty:changedQuantity,
                    batch: skuDetails?.batch,
                    scanned_qty: changedQuantity + skuDetails?.pass_qty
                  }
                }))

                const updateTableData = tableData.map((item) => {
                  return item.seller_sku_id === skuDetails.seller_sku_id && item.batch?.id === skuDetails?.batch?.id ? {
                    ...item,
                    pass_qty: 0,
                    fail_qty:changedQuantity,
                    batch: skuDetails?.batch,
                    scanned_qty: changedQuantity + skuDetails?.pass_qty,
                    allow_over_receive: true
                  } : item
                })
                dispatch(setBinDisabledGrnTableData(updateTableData))
              } else if (!isPresentInTable?.batch?.id) {
                dispatch(setScannedSkuDetails({
                  data: {
                    ...skuDetails,
                    pass_qty: 0,
                    fail_qty:changedQuantity,
                    batch: skuDetails?.batch,
                    scanned_qty: changedQuantity + skuDetails?.pass_qty
                  }
                }))

                const updateTableData = tableData.map((item) => {
                  return item.seller_sku_id === skuDetails.seller_sku_id ? {
                    ...item,
                    pass_qty: 0,
                    fail_qty:changedQuantity,
                    batch: skuDetails?.batch,
                    scanned_qty: changedQuantity + skuDetails?.pass_qty,
                    allow_over_receive: true
                  } : item
                })
                dispatch(setBinDisabledGrnTableData(updateTableData))
              }
            } else {
              dispatch(setScannedSkuDetails({
                data: {
                  ...skuDetails,
                  pass_qty: 0,
                  fail_qty:changedQuantity,
                  batch: skuDetails?.batch,
                  scanned_qty: changedQuantity,
                  reasons: []
                }
              }))

              const dataToPush = {
                id: skuDetails?.seller_sku_id,
                name: skuDetails?.name,
                code: skuDetails?.seller_sku_code,
                image: skuDetails?.images?.[0]?.url,
                is_serialised: !!(skuDetails?.serialisation_status === SERIALISATION_OPTION.serialised.id),
                seller_sku_id: skuDetails?.seller_sku_id,
                pass_qty: 0,
                fail_qty: changedQuantity,
                total_qty: skuDetails?.quantity || 0,
                purchase_order_id: skuDetails?.purchase_order_id,
                seller_id: skuDetails?.seller_id,
                reasons: [],
                scanned_qty: changedQuantity,
                batch: skuDetails?.batch,
                allow_over_receive: true
              }
              const updateTableData = [...tableData, dataToPush]
              dispatch(setBinDisabledGrnTableData(updateTableData))
            }
          }
        }
      }
      
      CustomToast('1 Item failed successfully', { my_type: 'success', duration:2000 })
      setIsSkuNotPartModalOpen(false)
      setIsOverReceiveModalOpen(false)
    }
  })

  return {updateNonSerialisedBatchedEnforcedMarkAsFailModalOpen, enforcedFailNonSerialisedBatchedloadingState}
}

export const useSerialisedBatchedSkuScan = ({
  sku_details, isOverReceiveModalOpen, scannedBarcode, returnGrnResponse, setIsOverReceiveModalOpen, setIsSkuNotExistModalOpen, grnResponse, tableData, displayGrnDetails, dispatch, selectedGlobalHubId
}) => {
  
  const { mutate: updateSerializedBatchedBinDisabledSkuDetails } = useApi({
    isMutation: true,
    apiKey: ['update-no-over-but-exist-Bin-Disabled-Sku-Details-serialised-skus'],
    apiFn: () => {
      const isPresentInPassBarcodes = sku_details?.pass_barcodes?.includes(scannedBarcode)
      const isPresentInFailBarocdes = sku_details?.fail_barcodes?.includes(scannedBarcode)
    
      if (isPresentInPassBarcodes || isPresentInFailBarocdes) {
        CustomToast('The scanned Barcode is already in use. Please add a different Barcode.', { my_type: 'error', audioRequired: true })
        return
      }
    
      let skuInTable
      if (sku_details?.batch_status === BATCH_STATUS.BATCHED) {
        skuInTable = tableData.find(item => item.seller_sku_id === sku_details?.seller_sku_id && item.batch?.id === sku_details?.batch?.id)
      } else {
        skuInTable = tableData.find(item => item.seller_sku_id === sku_details?.seller_sku_id)
      }
      
      const body = {
        pass_quantity: (parseInt(sku_details?.pass_qty) + 1),
        sku_barcodes: Array.isArray(sku_details?.pass_barcodes) && sku_details?.pass_barcodes?.length ? [...sku_details.pass_barcodes, scannedBarcode] : [scannedBarcode],
        seller_sku_code: sku_details?.seller_sku_code,
        batch_id: sku_details?.batch_status === BATCH_STATUS.BATCHED ? sku_details?.batch?.id : undefined,
        over_receive: isOverReceiveModalOpen || !!skuInTable?.allow_over_receive,
        reasons: Array.isArray(sku_details?.reasons) && sku_details?.reasons?.length ? sku_details?.reasons : undefined,
        return_order_id: returnGrnResponse?.return_order_detail?.id
      }
      const grn_id = grnResponse?.id || returnGrnResponse?.id
      return axiosInstance.post(`/api/v1/wms/hubs/${selectedGlobalHubId}/grns/${grn_id}`, body)
    },
    onSuccess: (data) => {
      if (data?.data?.is_over_receive || data?.data?.is_sku_not_exist) {
        if (data?.data?.is_over_receive) {
          setIsOverReceiveModalOpen(true)
        } else {
          setIsSkuNotExistModalOpen(true)
        }
      } else {
        if (sku_details?.batch_status === BATCH_STATUS.NOT_BATCHED && sku_details?.serialisation_status === SERIALISATION_OPTION.serialised.id) {
          const isSkuPresentInTable = tableData.find(item => item.seller_sku_id === sku_details?.seller_sku_id)
          if (!displayGrnDetails?.is_purchase_order && !displayGrnDetails?.is_sto && !isSkuPresentInTable) {
            dispatch(setScannedSkuDetails({
              data: {
                ...sku_details,
                pass_qty: 1,
                pass_barcodes: [scannedBarcode],
                fail_barcodes: []
              }
            }))

            const dataToPush = {
              id: sku_details?.seller_sku_id,
              name: sku_details?.name,
              code: sku_details?.seller_sku_code,
              image: sku_details?.images?.[0]?.url,
              is_serialised: sku_details?.serialisation_status === SERIALISATION_OPTION.serialised.id,
              seller_sku_id: sku_details?.seller_sku_id,
              pass_qty: 1,
              fail_qty: 0,
              total_qty: sku_details?.quantity || 0,
              purchase_order_id: sku_details?.purchase_order_id,
              seller_id: sku_details?.seller_id,
              pass_barcodes: [scannedBarcode],
              fail_barcodes: []
            }
            const updateTableData = [...tableData, dataToPush]
            dispatch(setBinDisabledGrnTableData(updateTableData))
          } else {
            dispatch(setScannedSkuDetails({
              data: {
                ...sku_details,
                pass_qty: (parseInt(sku_details?.pass_qty) + 1),
                pass_barcodes: Array.isArray(sku_details?.pass_barcodes) && sku_details?.pass_barcodes?.length ? [...sku_details.pass_barcodes, scannedBarcode] : [scannedBarcode]
              }
            }))

            const updateTableData = tableData.map((item) => {
              return item.seller_sku_id === sku_details?.seller_sku_id ? {
                ...item,
                pass_qty: (parseInt(item?.pass_qty) + 1),
                pass_barcodes: Array.isArray(item?.pass_barcodes) && item?.pass_barcodes?.length ? [...item.pass_barcodes, scannedBarcode] : [scannedBarcode]
              } : item
            })
            dispatch(setBinDisabledGrnTableData(updateTableData))
          }
        } else if (sku_details?.batch_status === BATCH_STATUS.BATCHED && sku_details?.serialisation_status === SERIALISATION_OPTION.serialised.id) {
          const isBatchedSkuPresentInTable = tableData.find(item => (item.batch?.id ? item.seller_sku_id === sku_details?.seller_sku_id && item.batch?.id === sku_details?.batch?.id : item.seller_sku_id === sku_details?.seller_sku_id))

          if (!displayGrnDetails?.is_purchase_order && !displayGrnDetails?.is_sto && !isBatchedSkuPresentInTable) {
            dispatch(setScannedSkuDetails({
              data: {
                ...sku_details,
                pass_qty: 1,
                pass_barcodes: [scannedBarcode],
                batch: sku_details?.batch,
                fail_barcodes: []
              }
            }))

            const dataToPush = {
              id: sku_details?.seller_sku_id,
              name: sku_details?.name,
              code: sku_details?.seller_sku_code,
              image: sku_details?.images?.[0]?.url,
              is_serialised: sku_details?.serialisation_status === SERIALISATION_OPTION.serialised.id,
              seller_sku_id: sku_details?.seller_sku_id,
              pass_qty: 1,
              fail_qty: 0,
              total_qty: sku_details?.quantity || 0,
              purchase_order_id: sku_details?.purchase_order_id,
              seller_id: sku_details?.seller_id,
              pass_barcodes: [scannedBarcode],
              fail_barcodes: [],
              batch: sku_details?.batch
            }
            const updateTableData = [...tableData, dataToPush]
            dispatch(setBinDisabledGrnTableData(updateTableData))
          } else {
            if (isBatchedSkuPresentInTable) {
              if (isBatchedSkuPresentInTable.batch?.id) {
                dispatch(setScannedSkuDetails({
                  data: {
                    ...sku_details,
                    pass_qty: (parseInt(sku_details?.pass_qty) + 1),
                    pass_barcodes: Array.isArray(sku_details?.pass_barcodes) && sku_details?.pass_barcodes?.length ? [...sku_details.pass_barcodes, scannedBarcode] : [scannedBarcode],
                    batch: sku_details?.batch
                  }
                }))

                const updateTableData = tableData.map((item) => {
                  return item.seller_sku_id === sku_details?.seller_sku_id && item.batch?.id === sku_details?.batch?.id ? {
                    ...item,
                    pass_qty: (parseInt(item?.pass_qty) + 1),
                    pass_barcodes: Array.isArray(item?.pass_barcodes) && item?.pass_barcodes?.length ? [...item.pass_barcodes, scannedBarcode] : [scannedBarcode],
                    batch: sku_details?.batch
                  } : item
                })
                dispatch(setBinDisabledGrnTableData(updateTableData))
              } else if (!isBatchedSkuPresentInTable.batch?.id) {
                dispatch(setScannedSkuDetails({
                  data: {
                    ...sku_details,
                    pass_qty: (parseInt(sku_details?.pass_qty) + 1),
                    pass_barcodes: Array.isArray(sku_details?.pass_barcodes) && sku_details?.pass_barcodes?.length ? [...sku_details.pass_barcodes, scannedBarcode] : [scannedBarcode],
                    batch: sku_details?.batch
                  }
                }))

                const updateTableData = tableData.map((item) => {
                  return item.seller_sku_id === sku_details?.seller_sku_id ? {
                    ...item,
                    pass_qty: (parseInt(item?.pass_qty) + 1),
                    pass_barcodes: Array.isArray(item?.pass_barcodes) && item?.pass_barcodes?.length ? [...item.pass_barcodes, scannedBarcode] : [scannedBarcode],
                    batch: sku_details?.batch
                  } : item
                })
                dispatch(setBinDisabledGrnTableData(updateTableData))
              }
            } else {
              dispatch(setScannedSkuDetails({
                data: {
                  ...sku_details,
                  pass_qty: 1,
                  pass_barcodes: [scannedBarcode],
                  batch: sku_details?.batch,
                  fail_barcodes: []
                }
              }))

              const dataToPush = {
                id: sku_details?.seller_sku_id,
                name: sku_details?.name,
                code: sku_details?.seller_sku_code,
                image: sku_details?.images?.[0]?.url,
                is_serialised: sku_details?.serialisation_status === SERIALISATION_OPTION.serialised.id,
                seller_sku_id: sku_details?.seller_sku_id,
                pass_qty: 1,
                fail_qty: 0,
                total_qty: sku_details?.quantity || 0,
                purchase_order_id: sku_details?.purchase_order_id,
                seller_id: sku_details?.seller_id,
                pass_barcodes: [scannedBarcode],
                fail_barcodes: [],
                batch: sku_details?.batch
              }
              const updateTableData = [...tableData, dataToPush]
              dispatch(setBinDisabledGrnTableData(updateTableData))
            }
          }
        }
        CustomToast('1 Item passed successfully', { my_type: 'success', duration:2000 })
      }
    }
  })

  return updateSerializedBatchedBinDisabledSkuDetails
}

export const useSerialisedBatchedSkuScanFromModal = ({
  skuDetails, isOverItemModalOpen, isSkuNotPartModalOpen, scannedBarcode, setOverItemModalOpen, setSkuNotPartModalOpen, tableData, returnGrnResponse, grnResponse, displayGrnDetails, dispatch, selectedGlobalHubId
}) => {
  const { mutate: updateBinDisabledSerialisedBatchedSkuDetailsFromModal, isPending:serialisedBatchedloadingState } = useApi({
    isMutation: true,
    apiKey: ['update-over-or-not-exist-Bin-Disabled-Sku-Details-for-modal-serialised-skus'],
    apiFn: () => {
      const body = {
        pass_quantity: (parseInt(skuDetails?.pass_qty) + 1),
        sku_barcodes: Array.isArray(skuDetails?.pass_barcodes) && skuDetails?.pass_barcodes?.length ? [...skuDetails.pass_barcodes, scannedBarcode] : [scannedBarcode],
        seller_sku_code: skuDetails?.seller_sku_code,
        batch_id: skuDetails?.batch_status === BATCH_STATUS.BATCHED ? skuDetails?.batch?.id : undefined,
        over_receive: isOverItemModalOpen || isSkuNotPartModalOpen,
        reasons: Array.isArray(skuDetails?.reasons) && skuDetails?.reasons?.length ? skuDetails?.reasons : undefined,
        return_order_id: returnGrnResponse?.return_order_detail?.id
      }
      const grn_id = grnResponse?.id || returnGrnResponse?.id
      return axiosInstance.post(`/api/v1/wms/hubs/${selectedGlobalHubId}/grns/${grn_id}`, body)
    },
    onSuccess: () => {
      if (isSkuNotPartModalOpen) {
        if (skuDetails?.batch_status === BATCH_STATUS.NOT_BATCHED && skuDetails?.serialisation_status === SERIALISATION_OPTION.serialised.id) {

          const isPresentInTable = tableData.find(item => item.seller_sku_id === skuDetails?.seller_sku_id)

          if (isPresentInTable) {
            dispatch(setScannedSkuDetails({
              data: {
                ...skuDetails,
                pass_qty: (parseInt(skuDetails?.pass_qty) + 1),
                pass_barcodes: Array.isArray(skuDetails?.pass_barcodes) && skuDetails?.pass_barcodes?.length ? [...skuDetails.pass_barcodes, scannedBarcode] : [scannedBarcode]
              }
            }))
  
            const updateTableData = tableData.map((item) => {
              return item.seller_sku_id === skuDetails?.seller_sku_id ? {
                ...item,
                pass_qty: (parseInt(item?.pass_qty) + 1),
                pass_barcodes: Array.isArray(item?.pass_barcodes) && item?.pass_barcodes?.length ? [...item.pass_barcodes, scannedBarcode] : [scannedBarcode]
              } : item
            })
            dispatch(setBinDisabledGrnTableData(updateTableData))
          } else {
            dispatch(setScannedSkuDetails({
              data: {
                ...skuDetails,
                pass_qty: 1,
                pass_barcodes: [scannedBarcode],
                fail_barcodes: []
              }
            }))

            const dataToPush = {
              id: skuDetails?.seller_sku_id,
              name: skuDetails?.name,
              code: skuDetails?.seller_sku_code,
              image: skuDetails?.images?.[0]?.url,
              is_serialised: skuDetails?.serialisation_status === SERIALISATION_OPTION.serialised.id,
              seller_sku_id: skuDetails?.seller_sku_id,
              pass_qty: 1,
              fail_qty: 0,
              total_qty: skuDetails?.quantity || 0,
              purchase_order_id: skuDetails?.purchase_order_id,
              seller_id: skuDetails?.seller_id,
              pass_barcodes: [scannedBarcode],
              fail_barcodes: []
            }
            const updateTableData = [...tableData, dataToPush]
            dispatch(setBinDisabledGrnTableData(updateTableData))
          }
        } else if (skuDetails?.batch_status === BATCH_STATUS.BATCHED && skuDetails?.serialisation_status === SERIALISATION_OPTION.serialised.id) {
          const isBatchedSkuPresentInTable = tableData.find(item => (item.batch?.id ? item.seller_sku_id === skuDetails?.seller_sku_id && item.batch?.id === skuDetails?.batch?.id : item.seller_sku_id === skuDetails?.seller_sku_id))

          if (isBatchedSkuPresentInTable) {
            if (isBatchedSkuPresentInTable.batch?.id) {
              dispatch(setScannedSkuDetails({
                data: {
                  ...skuDetails,
                  pass_qty: (parseInt(skuDetails?.pass_qty) + 1),
                  pass_barcodes: Array.isArray(skuDetails?.pass_barcodes) && skuDetails?.pass_barcodes?.length ? [...skuDetails.pass_barcodes, scannedBarcode] : [scannedBarcode],
                  batch: skuDetails?.batch
                }
              }))

              const updateTableData = tableData.map((item) => {
                return item.seller_sku_id === skuDetails?.seller_sku_id && item.batch?.id === skuDetails?.batch?.id ? {
                  ...item,
                  pass_qty: (parseInt(item?.pass_qty) + 1),
                  pass_barcodes: Array.isArray(item?.pass_barcodes) && item?.pass_barcodes?.length ? [...item.pass_barcodes, scannedBarcode] : [scannedBarcode],
                  batch: skuDetails?.batch
                } : item
              })
              dispatch(setBinDisabledGrnTableData(updateTableData))
            } else if (!isBatchedSkuPresentInTable.batch?.id) {
              dispatch(setScannedSkuDetails({
                data: {
                  ...skuDetails,
                  pass_qty: (parseInt(skuDetails?.pass_qty) + 1),
                  pass_barcodes: Array.isArray(skuDetails?.pass_barcodes) && skuDetails?.pass_barcodes?.length ? [...skuDetails.pass_barcodes, scannedBarcode] : [scannedBarcode],
                  batch: skuDetails?.batch
                }
              }))

              const updateTableData = tableData.map((item) => {
                return item.seller_sku_id === skuDetails?.seller_sku_id ? {
                  ...item,
                  pass_qty: (parseInt(item?.pass_qty) + 1),
                  pass_barcodes: Array.isArray(item?.pass_barcodes) && item?.pass_barcodes?.length ? [...item.pass_barcodes, scannedBarcode] : [scannedBarcode],
                  batch: skuDetails?.batch
                } : item
              })
              dispatch(setBinDisabledGrnTableData(updateTableData))
            }
          } else {
            dispatch(setScannedSkuDetails({
              data: {
                ...skuDetails,
                pass_qty: 1,
                pass_barcodes: [scannedBarcode],
                batch: skuDetails?.batch,
                fail_barcodes: []
              }
            }))

            const dataToPush = {
              id: skuDetails?.seller_sku_id,
              name: skuDetails?.name,
              code: skuDetails?.seller_sku_code,
              image: skuDetails?.images?.[0]?.url,
              is_serialised: skuDetails?.serialisation_status === SERIALISATION_OPTION.serialised.id,
              seller_sku_id: skuDetails?.seller_sku_id,
              pass_qty: 1,
              fail_qty: 0,
              total_qty: skuDetails?.quantity || 0,
              purchase_order_id: skuDetails?.purchase_order_id,
              seller_id: skuDetails?.seller_id,
              pass_barcodes: [scannedBarcode],
              fail_barcodes: [],
              batch: skuDetails?.batch
            }
            const updateTableData = [...tableData, dataToPush]
            dispatch(setBinDisabledGrnTableData(updateTableData))
          }
        }
      }

      if (isOverItemModalOpen) {
        if (skuDetails?.batch_status === BATCH_STATUS.NOT_BATCHED && skuDetails?.serialisation_status === SERIALISATION_OPTION.serialised.id) {
          dispatch(setScannedSkuDetails({
            data: {
              ...skuDetails,
              pass_qty: (parseInt(skuDetails?.pass_qty) + 1),
              pass_barcodes: Array.isArray(skuDetails?.pass_barcodes) && skuDetails?.pass_barcodes?.length ? [...skuDetails.pass_barcodes, scannedBarcode] : [scannedBarcode]
            }
          }))

          const updateTableData = tableData.map((item) => {
            return item.seller_sku_id === skuDetails?.seller_sku_id ? {
              ...item,
              pass_qty: (parseInt(item?.pass_qty) + 1),
              pass_barcodes: Array.isArray(item?.pass_barcodes) && item?.pass_barcodes?.length ? [...item.pass_barcodes, scannedBarcode] : [scannedBarcode],
              allow_over_receive: true
            } : item
          })
          dispatch(setBinDisabledGrnTableData(updateTableData))
        } else if (skuDetails?.batch_status === BATCH_STATUS.BATCHED && skuDetails?.serialisation_status === SERIALISATION_OPTION.serialised.id) {
          const isBatchedSkuPresentInTable = tableData.find(item => (item.batch?.id ? item.seller_sku_id === skuDetails?.seller_sku_id && item.batch?.id === skuDetails?.batch?.id : item.seller_sku_id === skuDetails?.seller_sku_id))

          if (!displayGrnDetails?.is_purchase_order && !displayGrnDetails?.is_sto && !isBatchedSkuPresentInTable) {
            dispatch(setScannedSkuDetails({
              data: {
                ...skuDetails,
                pass_qty: 1,
                pass_barcodes: [scannedBarcode],
                batch: skuDetails?.batch,
                fail_barcodes: []
              }
            }))

            const dataToPush = {
              id: skuDetails?.seller_sku_id,
              name: skuDetails?.name,
              code: skuDetails?.seller_sku_code,
              image: skuDetails?.images?.[0]?.url,
              is_serialised: skuDetails?.serialisation_status === SERIALISATION_OPTION.serialised.id,
              seller_sku_id: skuDetails?.seller_sku_id,
              pass_qty: 1,
              fail_qty: 0,
              total_qty: skuDetails?.quantity || 0,
              purchase_order_id: skuDetails?.purchase_order_id,
              seller_id: skuDetails?.seller_id,
              pass_barcodes: [scannedBarcode],
              fail_barcodes: [],
              batch: skuDetails?.batch,
              allow_over_receive: true
            }
            const updateTableData = [...tableData, dataToPush]
            dispatch(setBinDisabledGrnTableData(updateTableData))
          } else {
            if (isBatchedSkuPresentInTable) {
              if (isBatchedSkuPresentInTable.batch?.id) {
                dispatch(setScannedSkuDetails({
                  data: {
                    ...skuDetails,
                    pass_qty: (parseInt(skuDetails?.pass_qty) + 1),
                    pass_barcodes: Array.isArray(skuDetails?.pass_barcodes) && skuDetails?.pass_barcodes?.length ? [...skuDetails.pass_barcodes, scannedBarcode] : [scannedBarcode],
                    batch: skuDetails?.batch
                  }
                }))

                const updateTableData = tableData.map((item) => {
                  return item.seller_sku_id === skuDetails?.seller_sku_id && item.batch?.id === skuDetails?.batch?.id ? {
                    ...item,
                    pass_qty: (parseInt(item?.pass_qty) + 1),
                    pass_barcodes: Array.isArray(item?.pass_barcodes) && item?.pass_barcodes?.length ? [...item.pass_barcodes, scannedBarcode] : [scannedBarcode],
                    batch: skuDetails?.batch,
                    allow_over_receive: true
                  } : item
                })
                dispatch(setBinDisabledGrnTableData(updateTableData))
              } else if (!isBatchedSkuPresentInTable.batch?.id) {
                dispatch(setScannedSkuDetails({
                  data: {
                    ...skuDetails,
                    pass_qty: (parseInt(skuDetails?.pass_qty) + 1),
                    pass_barcodes: Array.isArray(skuDetails?.pass_barcodes) && skuDetails?.pass_barcodes?.length ? [...skuDetails.pass_barcodes, scannedBarcode] : [scannedBarcode],
                    batch: skuDetails?.batch,
                  }
                }))

                const updateTableData = tableData.map((item) => {
                  return item.seller_sku_id === skuDetails?.seller_sku_id ? {
                    ...item,
                    pass_qty: (parseInt(item?.pass_qty) + 1),
                    pass_barcodes: Array.isArray(item?.pass_barcodes) && item?.pass_barcodes?.length ? [...item.pass_barcodes, scannedBarcode] : [scannedBarcode],
                    batch: skuDetails?.batch,
                    allow_over_receive: true
                  } : item
                })
                dispatch(setBinDisabledGrnTableData(updateTableData))
              }
            } else {
              dispatch(setScannedSkuDetails({
                data: {
                  ...skuDetails,
                  pass_qty: 1,
                  pass_barcodes: [scannedBarcode],
                  batch: skuDetails?.batch,
                  fail_barcodes: []
                }
              }))

              const dataToPush = {
                id: skuDetails?.seller_sku_id,
                name: skuDetails?.name,
                code: skuDetails?.seller_sku_code,
                image: skuDetails?.images?.[0]?.url,
                is_serialised: skuDetails?.serialisation_status === SERIALISATION_OPTION.serialised.id,
                seller_sku_id: skuDetails?.seller_sku_id,
                pass_qty: 1,
                fail_qty: 0,
                total_qty: skuDetails?.quantity || 0,
                purchase_order_id: skuDetails?.purchase_order_id,
                seller_id: skuDetails?.seller_id,
                pass_barcodes: [scannedBarcode],
                fail_barcodes: [],
                batch: skuDetails?.batch,
                allow_over_receive: true
              }
              const updateTableData = [...tableData, dataToPush]
              dispatch(setBinDisabledGrnTableData(updateTableData))
            }
          }
        }
      }
      CustomToast('1 Item passed successfully', { my_type: 'success', duration:2000 })
      setOverItemModalOpen(false)
      setSkuNotPartModalOpen(false)
    }
  })

  return {updateBinDisabledSerialisedBatchedSkuDetailsFromModal, serialisedBatchedloadingState}
}

export const useEnforcedFailSerialisedBatchedSkuScan = ({
  sku_details, isOverReceiveModalOpen, setIsOverReceiveModalOpen, setIsSkuNotExistModalOpen, scannedBarcode, grnResponse, tableData, displayGrnDetails, enforcedFailUploadIds, enforcedFailReason, returnGrnResponse, dispatch, selectedGlobalHubId
}) => {
  const {mutate: updateEnforcedSerialisedBatchedBinsabledSkuDetails} = useApi({
    isMutation:true,
    apiKey: ['enforced-serialised-failed'],
    apiFn: () => {
      const isPresentInPassBarcodes = sku_details?.pass_barcodes?.includes(scannedBarcode)
      const isPresentInFailBarocdes = sku_details?.fail_barcodes?.includes(scannedBarcode)
      if (isPresentInPassBarcodes || isPresentInFailBarocdes) {
        CustomToast('The scanned Barcode is already in use. Please add a different Barcode.', { my_type: 'error', audioRequired: true })
        return
      }
      
      let skuInTable
      if (sku_details?.batch_status === BATCH_STATUS.BATCHED) {
        skuInTable = tableData.find(item => item.seller_sku_id === sku_details?.seller_sku_id && item.batch?.id === sku_details?.batch?.id)
      } else {
        skuInTable = tableData.find(item => item.seller_sku_id === sku_details?.seller_sku_id)
      }

      const reasons = [{
        id: enforcedFailReason.id,
        quantity: (parseInt(sku_details?.fail_qty) + 1),
        upload_ids: enforcedFailUploadIds || [],
        barcodes: Array.isArray(sku_details?.fail_barcodes) && sku_details?.fail_barcodes?.length ? [...sku_details.fail_barcodes, scannedBarcode] : [scannedBarcode],
      }]

      const body = {
        pass_quantity: 0,
        sku_barcodes: [],
        seller_sku_code: sku_details?.seller_sku_code,
        batch_id: sku_details?.batch_status === BATCH_STATUS.BATCHED ? sku_details?.batch?.id : undefined,
        over_receive: isOverReceiveModalOpen || !!skuInTable?.allow_over_receive,
        reasons
      }
      const grn_id = grnResponse?.id || returnGrnResponse?.id
      return axiosInstance.post(`/api/v1/wms/hubs/${selectedGlobalHubId}/grns/${grn_id}`, body)
      
    },
    onSuccess: (data) => {
      if (data?.data?.is_over_receive || data?.data?.is_sku_not_exist) {
        if (data?.data?.is_over_receive) {
          setIsOverReceiveModalOpen(true)
        } else {
          setIsSkuNotExistModalOpen(true)
        }
      } else {
        dispatch(updateEnforcedFailUploadIds([]))
        if (sku_details?.batch_status === BATCH_STATUS.BATCHED && sku_details?.serialisation_status === SERIALISATION_OPTION.serialised.id) {
          const isBatchedSkuPresentInTable = tableData.find(item => (item.batch?.id ? item.seller_sku_id === sku_details?.seller_sku_id && item.batch?.id === sku_details?.batch?.id : item.seller_sku_id === sku_details?.seller_sku_id))

          if (!displayGrnDetails?.is_purchase_order && !displayGrnDetails?.is_sto && !isBatchedSkuPresentInTable) {
            dispatch(setScannedSkuDetails({
              data: {
                ...sku_details,
                pass_qty: 0,
                fail_qty: 1,
                fail_barcodes:[scannedBarcode],
                pass_barcodes: [],
                batch: sku_details?.batch
              }
            }))

            const dataToPush = {
              id: sku_details?.seller_sku_id,
              name: sku_details?.name,
              code: sku_details?.seller_sku_code,
              image: sku_details?.images?.[0]?.url,
              is_serialised: sku_details?.serialisation_status === SERIALISATION_OPTION.serialised.id,
              seller_sku_id: sku_details?.seller_sku_id,
              pass_qty: 0,
              fail_qty: 1,
              total_qty: sku_details?.quantity || 0,
              purchase_order_id: sku_details?.purchase_order_id,
              seller_id: sku_details?.seller_id,
              pass_barcodes: [],
              fail_barcodes: [scannedBarcode],
              batch: sku_details?.batch
            }
            const updateTableData = [...tableData, dataToPush]
            dispatch(setBinDisabledGrnTableData(updateTableData))
          } else {
            if (isBatchedSkuPresentInTable) {
              if (isBatchedSkuPresentInTable.batch?.id) {
                dispatch(setScannedSkuDetails({
                  data: {
                    ...sku_details,
                    pass_qty: 0,
                    fail_qty:(parseInt(sku_details?.fail_qty) + 1),
                    pass_barcodes: [],
                    fail_barcodes:Array.isArray(sku_details?.fail_barcodes) && sku_details?.fail_barcodes?.length ? [...sku_details.fail_barcodes, scannedBarcode] : [scannedBarcode],
                    batch: sku_details?.batch
                  }
                }))

                const updateTableData = tableData.map((item) => {
                  return item.seller_sku_id === sku_details?.seller_sku_id && item.batch?.id === sku_details?.batch?.id ? {
                    ...item,
                    pass_qty: 0,
                    fail_qty:(parseInt(item?.fail_qty) + 1),
                    pass_barcodes:[],
                    fail_barcodes: Array.isArray(item?.fail_barcodes) && item?.fail_barcodes?.length ? [...item.fail_barcodes, scannedBarcode] : [scannedBarcode],
                    batch: sku_details?.batch
                  } : item
                })
                dispatch(setBinDisabledGrnTableData(updateTableData))
              } else if (!isBatchedSkuPresentInTable.batch?.id) {
                dispatch(setScannedSkuDetails({
                  data: {
                    ...sku_details,
                    pass_qty: 0,
                    fail_qty:(parseInt(sku_details?.fail_qty) + 1),
                    pass_barcodes:[],
                    fail_barcodes: Array.isArray(sku_details?.fail_barcodes) && sku_details?.fail_barcodes?.length ? [...sku_details.fail_barcodes, scannedBarcode] : [scannedBarcode],
                    batch: sku_details?.batch
                  }
                }))

                const updateTableData = tableData.map((item) => {
                  return item.seller_sku_id === sku_details?.seller_sku_id ? {
                    ...item,
                    pass_qty: 0,
                    fail_qty: (parseInt(item?.fail_qty) + 1),
                    pass_barcodes:[],
                    fail_barcodes: Array.isArray(item?.fail_barcodes) && item?.fail_barcodes?.length ? [...item.fail_barcodes, scannedBarcode] : [scannedBarcode],
                    batch: sku_details?.batch
                  } : item
                })
                dispatch(setBinDisabledGrnTableData(updateTableData))
              }
            } else {
              dispatch(setScannedSkuDetails({
                data: {
                  ...sku_details,
                  pass_qty: 0,
                  fail_qty:1,
                  pass_barcodes: [],
                  batch: sku_details?.batch,
                  fail_barcodes: [scannedBarcode]
                }
              }))

              const dataToPush = {
                id: sku_details?.seller_sku_id,
                name: sku_details?.name,
                code: sku_details?.seller_sku_code,
                image: sku_details?.images?.[0]?.url,
                is_serialised: sku_details?.serialisation_status === SERIALISATION_OPTION.serialised.id,
                seller_sku_id: sku_details?.seller_sku_id,
                pass_qty: 0,
                fail_qty: 1,
                total_qty: sku_details?.quantity || 0,
                purchase_order_id: sku_details?.purchase_order_id,
                seller_id: sku_details?.seller_id,
                pass_barcodes: [],
                fail_barcodes: [scannedBarcode],
                batch: sku_details?.batch
              }
              const updateTableData = [...tableData, dataToPush]
              dispatch(setBinDisabledGrnTableData(updateTableData))
            }
          }
        }
        CustomToast('1 Item failed successfully', { my_type: 'success', duration:2000 })
      }
    }
  })

  return updateEnforcedSerialisedBatchedBinsabledSkuDetails
}

export const useEnforcedFailSerialisedBatchedSkuScanFromModal = ({
  skuDetails, isOverItemModalOpen, scannedBarcode, isSkuNotPartModalOpen, setOverItemModalOpen, setSkuNotPartModalOpen, grnResponse, tableData, displayGrnDetails, enforcedFailUploadIds, enforcedFailReason, returnGrnResponse, dispatch, selectedGlobalHubId
}) => {
  const {mutate: updateEnforcedFailSerialisedBatchedFailedModal, isPending:enforcedFailSerialisedBatchedloadingState} = useApi({
    isMutation:true,
    apiKey: ['enforced-serialised-failed-modal'],
    apiFn: () => {
      const reasons = [{
        id: enforcedFailReason.id,
        quantity: (parseInt(skuDetails?.fail_qty) + 1),
        upload_ids: enforcedFailUploadIds || [],
        barcodes: Array.isArray(skuDetails?.fail_barcodes) && skuDetails?.fail_barcodes?.length ? [...skuDetails.fail_barcodes, scannedBarcode] : [scannedBarcode],
      }]

      const body = {
        pass_quantity: 0,
        sku_barcodes: [],
        seller_sku_code: skuDetails?.seller_sku_code,
        batch_id: skuDetails?.batch_status === BATCH_STATUS.BATCHED ? skuDetails?.batch?.id : undefined,
        over_receive: isOverItemModalOpen,
        reasons
      }
      const grn_id = grnResponse?.id || returnGrnResponse?.id
      return axiosInstance.post(`/api/v1/wms/hubs/${selectedGlobalHubId}/grns/${grn_id}`, body)
      
    },
    onSuccess: () => {
      dispatch(updateEnforcedFailUploadIds([]))
      if (isSkuNotPartModalOpen) {
        if (skuDetails?.batch_status === BATCH_STATUS.BATCHED && skuDetails?.serialisation_status === SERIALISATION_OPTION.serialised.id) {
          const isBatchedSkuPresentInTable = tableData.find(item => (item.batch?.id ? item.seller_sku_id === skuDetails?.seller_sku_id && item.batch?.id === skuDetails?.batch?.id : item.seller_sku_id === skuDetails?.seller_sku_id))

          if (isBatchedSkuPresentInTable) {
            if (isBatchedSkuPresentInTable.batch?.id) {
              dispatch(setScannedSkuDetails({
                data: {
                  ...skuDetails,
                  pass_qty: 0,
                  fail_qty: (parseInt(skuDetails?.fail_qty) + 1),
                  pass_barcodes:[],
                  fail_barcodes: Array.isArray(skuDetails?.fail_barcodes) && skuDetails?.fail_barcodes?.length ? [...skuDetails.fail_barcodes, scannedBarcode] : [scannedBarcode],
                  batch: skuDetails?.batch
                }
              }))

              const updateTableData = tableData.map((item) => {
                return item.seller_sku_id === skuDetails?.seller_sku_id && item.batch?.id === skuDetails?.batch?.id ? {
                  ...item,
                  pass_qty: 0,
                  fail_qty: (parseInt(item?.fail_qty) + 1),
                  pass_barcodes: [],
                  fail_barcodes: Array.isArray(item?.fail_barcodes) && item?.fail_barcodes?.length ? [...item.fail_barcodes, scannedBarcode] : [scannedBarcode],
                  batch: skuDetails?.batch
                } : item
              })
              dispatch(setBinDisabledGrnTableData(updateTableData))
            } else if (!isBatchedSkuPresentInTable.batch?.id) {
              dispatch(setScannedSkuDetails({
                data: {
                  ...skuDetails,
                  pass_qty: 0,
                  fail_qty: (parseInt(skuDetails?.fail_qty) + 1),
                  pass_barcodes:[],
                  fail_barcodes: Array.isArray(skuDetails?.fail_barcodes) && skuDetails?.fail_barcodes?.length ? [...skuDetails.fail_barcodes, scannedBarcode] : [scannedBarcode],
                  batch: skuDetails?.batch
                }
              }))

              const updateTableData = tableData.map((item) => {
                return item.seller_sku_id === skuDetails?.seller_sku_id ? {
                  ...item,
                  pass_qty: 0,
                  fail_qty: (parseInt(item?.fail_qty) + 1),
                  pass_barcodes: [],
                  fail_barcodes: Array.isArray(item?.fail_barcodes) && item?.fail_barcodes?.length ? [...item.fail_barcodes, scannedBarcode] : [scannedBarcode],
                  batch: skuDetails?.batch
                } : item
              })
              dispatch(setBinDisabledGrnTableData(updateTableData))
            }
          } else {
            dispatch(setScannedSkuDetails({
              data: {
                ...skuDetails,
                pass_qty: 0,
                fail_qty:1,
                pass_barcodes: [],
                batch: skuDetails?.batch,
                fail_barcodes: [scannedBarcode]
              }
            }))

            const dataToPush = {
              id: skuDetails?.seller_sku_id,
              name: skuDetails?.name,
              code: skuDetails?.seller_sku_code,
              image: skuDetails?.images?.[0]?.url,
              is_serialised: skuDetails?.serialisation_status === SERIALISATION_OPTION.serialised.id,
              seller_sku_id: skuDetails?.seller_sku_id,
              pass_qty: 0,
              fail_qty: 1,
              total_qty: skuDetails?.quantity || 0,
              purchase_order_id: skuDetails?.purchase_order_id,
              seller_id: skuDetails?.seller_id,
              pass_barcodes: [],
              fail_barcodes: [scannedBarcode],
              batch: skuDetails?.batch
            }
            const updateTableData = [...tableData, dataToPush]
            dispatch(setBinDisabledGrnTableData(updateTableData))
          }
        }
      }

      if (isOverItemModalOpen) {
        if (skuDetails?.batch_status === BATCH_STATUS.BATCHED && skuDetails?.serialisation_status === SERIALISATION_OPTION.serialised.id) {
          const isBatchedSkuPresentInTable = tableData.find(item => (item.batch?.id ? item.seller_sku_id === skuDetails?.seller_sku_id && item.batch?.id === skuDetails?.batch?.id : item.seller_sku_id === skuDetails?.seller_sku_id))

          if (!displayGrnDetails?.is_purchase_order && !displayGrnDetails?.is_sto && !isBatchedSkuPresentInTable) {
            dispatch(setScannedSkuDetails({
              data: {
                ...skuDetails,
                pass_qty: 0,
                fail_qty: 1,
                pass_barcodes: [],
                batch: skuDetails?.batch,
                fail_barcodes: [scannedBarcode]
              }
            }))

            const dataToPush = {
              id: skuDetails?.seller_sku_id,
              name: skuDetails?.name,
              code: skuDetails?.seller_sku_code,
              image: skuDetails?.images?.[0]?.url,
              is_serialised: skuDetails?.serialisation_status === SERIALISATION_OPTION.serialised.id,
              seller_sku_id: skuDetails?.seller_sku_id,
              pass_qty: 0,
              fail_qty: 1,
              total_qty: skuDetails?.quantity || 0,
              purchase_order_id: skuDetails?.purchase_order_id,
              seller_id: skuDetails?.seller_id,
              pass_barcodes: [],
              fail_barcodes: [scannedBarcode],
              batch: skuDetails?.batch,
              allow_over_receive: true
            }
            const updateTableData = [...tableData, dataToPush]
            dispatch(setBinDisabledGrnTableData(updateTableData))
          } else {
            if (isBatchedSkuPresentInTable) {
              if (isBatchedSkuPresentInTable.batch?.id) {
                dispatch(setScannedSkuDetails({
                  data: {
                    ...skuDetails,
                    pass_qty: 0,
                    fail_qty: (parseInt(skuDetails?.fail_qty) + 1),
                    pass_barcodes: [],
                    fail_barcodes: Array.isArray(skuDetails?.fail_barcodes) && skuDetails?.fail_barcodes?.length ? [...skuDetails.fail_barcodes, scannedBarcode] : [scannedBarcode],
                    batch: skuDetails?.batch
                  }
                }))

                const updateTableData = tableData.map((item) => {
                  return item.seller_sku_id === skuDetails?.seller_sku_id && item.batch?.id === skuDetails?.batch?.id ? {
                    ...item,
                    pass_qty: 0,
                    fail_qty: (parseInt(item?.fail_qty) + 1),
                    pass_barcodes: [],
                    fail_barcodes: Array.isArray(item?.fail_barcodes) && item?.fail_barcodes?.length ? [...item.fail_barcodes, scannedBarcode] : [scannedBarcode],
                    batch: skuDetails?.batch,
                    allow_over_receive: true
                  } : item
                })
                dispatch(setBinDisabledGrnTableData(updateTableData))
              } else if (!isBatchedSkuPresentInTable.batch?.id) {
                dispatch(setScannedSkuDetails({
                  data: {
                    ...skuDetails,
                    pass_qty: 0,
                    fail_qty: (parseInt(skuDetails?.fail_qty) + 1),
                    pass_barcodes: [],
                    fail_barcodes: Array.isArray(skuDetails?.fail_barcodes) && skuDetails?.fail_barcodes?.length ? [...skuDetails.fail_barcodes, scannedBarcode] : [scannedBarcode],
                    batch: skuDetails?.batch,
                  }
                }))

                const updateTableData = tableData.map((item) => {
                  return item.seller_sku_id === skuDetails?.seller_sku_id ? {
                    ...item,
                    pass_qty: 0,
                    fail_qty: (parseInt(item?.fail_qty) + 1),
                    pass_barcodes: [],
                    fail_barcodes: Array.isArray(item?.fail_barcodes) && item?.fail_barcodes?.length ? [...item.fail_barcodes, scannedBarcode] : [scannedBarcode],
                    batch: skuDetails?.batch,
                    allow_over_receive: true
                  } : item
                })
                dispatch(setBinDisabledGrnTableData(updateTableData))
              }
            } else {
              dispatch(setScannedSkuDetails({
                data: {
                  ...skuDetails,
                  pass_qty: 0,
                  fail_qty:1,
                  pass_barcodes: [],
                  batch: skuDetails?.batch,
                  fail_barcodes: [scannedBarcode]
                }
              }))

              const dataToPush = {
                id: skuDetails?.seller_sku_id,
                name: skuDetails?.name,
                code: skuDetails?.seller_sku_code,
                image: skuDetails?.images?.[0]?.url,
                is_serialised: skuDetails?.serialisation_status === SERIALISATION_OPTION.serialised.id,
                seller_sku_id: skuDetails?.seller_sku_id,
                pass_qty: 0,
                fail_qty: 1,
                total_qty: skuDetails?.quantity || 0,
                purchase_order_id: skuDetails?.purchase_order_id,
                seller_id: skuDetails?.seller_id,
                pass_barcodes: [],
                fail_barcodes: [scannedBarcode],
                batch: skuDetails?.batch,
                allow_over_receive: true
              }
              const updateTableData = [...tableData, dataToPush]
              dispatch(setBinDisabledGrnTableData(updateTableData))
            }
          }
        }
      }
      CustomToast('1 Item failed successfully', { my_type: 'success', duration:2000 })
      setOverItemModalOpen(false)
      setSkuNotPartModalOpen(false)
    }
  })

  return {updateEnforcedFailSerialisedBatchedFailedModal, enforcedFailSerialisedBatchedloadingState}
}