import React from 'react'
import ClosedPallet from './ClosedPallet'
import OpenedPallet from './OpenedPallet'
import SkuDetailsAndQc from './SkuDetailsAndQc'

const Pallet = React.forwardRef(({ sku_details, selectedTabId, searchBarcode, grnType }, ref) => {

  if (!sku_details.child_data || (sku_details.child_data && (sku_details.child_data.openedAssignedQty + sku_details.child_data.closedAssignedQty) >= sku_details.child_data.maxQty)) {
    return <SkuDetailsAndQc sku_details={sku_details} selectedTabId={selectedTabId} ref={ref} searchBarcode={searchBarcode} grnType={grnType}/>
  } else {
    const maxQty = sku_details.openedQty * sku_details.child_sku?.quantity
    return <div>
      {!!sku_details.closedQty && sku_details.closedAssignedQty < sku_details.closedQty && <ClosedPallet sku_details={sku_details} selectedTabId={selectedTabId} ref={ref} searchBarcode={searchBarcode} />}
      {!!sku_details.openedQty && sku_details.openedAssignedQty < sku_details.openedQty && <OpenedPallet sku_details={sku_details} maxQty={maxQty} child_data={sku_details.child_data} selectedTabId={selectedTabId} ref={ref} searchBarcode={searchBarcode} />}
    </div>
  }
})

export default Pallet