import FloatingDropDown from '@src/@core/components/ui/floating-dropdown'
import InputField from '@src/@core/components/ui/input-field'
import MultiInputValue from '@src/@core/components/ui/multi-value-input'
import { TENANT_TYPE } from '@src/App.constants'
import abilityMap from '@src/assets/data/abilityMapping/abilityMapping'
import ability from '@src/configs/acl/ability'
import {
  ASSEMBLED_KIT_FIELDS_NAME,
  ASSEMBLED_KIT_FORM_VALIDATION_SCHEMA,
  CATALOG_FLOATING_YES_AND_NO_OPTIONS,
  LABELS_ADDITIONAL_OPTIONS
} from '@src/views/catalog/catalog.constants'
import { getSellersAsync, getSkusLabels } from '@src/views/catalog/store'
import { getTaxCategoriesData } from '@src/views/pos/pos.apis'
import classNames from 'classnames'
import { useEffect, useRef, useState } from 'react'
import { Edit2 } from 'react-feather'
import { useSelector } from 'react-redux'
import { components } from 'react-select'
import AddSkuLabelModal from '../single-sku-create/AddSkuLabelModal'

export default function AssembledDetailsSection({
  errors,
  register,
  Vector,
  t,
  tenantType,
  selectedSeller,
  watch,
  isEdit,
  singleSkuLoading,
  bundleCreationLoading,
  updateBundleSkusRes,
  imageGettingUpload,
  setSellerChangedWarningModal,
  setValue,
  bundleSkus,
  setStoreChangedSeller,
  setIsSellerChangeWarningVisibled,
  setBarcodeData,
  barcodeData,
  clearErrors,
  control,
  isPosEnabled
}) {

  const [isOpenAddSkuLabelModal, setIsOpenAddSkuLabelModal] = useState(false)
  const [label, setLabel] = useState('')
  const [isDropDownOpen, setIsDropDownOpen] = useState(false)
  const [isLabelEdit, setIsLabelEdit] = useState(false)
  const [labelId, setLabelId] = useState(null)
  const initialLabelValue = useRef(label)
  const [isLabelSuccess, setIsLabelSuccess] = useState(false)
  const isSellerViewAllowed = ability.can(abilityMap.seller.view.action, abilityMap.seller.view.resource)
  const globalSeller = useSelector(state => state.auth.globalSeller)

  useEffect(() => {
    if (isLabelSuccess) {
      setValue(ASSEMBLED_KIT_FIELDS_NAME.LABELS, [...watch(ASSEMBLED_KIT_FIELDS_NAME.LABELS), {label, value: label}])
      setLabel('')
    }
  }, [isLabelSuccess])

  const [barcodeInput, setBarcodeInput] = useState('')
  const sellerValueChangeHandler = (selectedSellerData) => {
    if (bundleSkus.length && (selectedSellerData.value !== selectedSeller.value)) {
      setSellerChangedWarningModal(true)
      setStoreChangedSeller(selectedSellerData)
      setIsSellerChangeWarningVisibled(true)
      return
    }
    setValue(ASSEMBLED_KIT_FIELDS_NAME.SELLER, selectedSellerData)
  }

  const handleBarcodeKey = (e) => {
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault()
      if (barcodeInput) {
        setBarcodeData([...barcodeData, { barcode: barcodeInput, isEditable: true }])
      }
      setBarcodeInput('')
    }
  }

  const blurHandler = () => {
    if (barcodeData.length || barcodeInput) {
      clearErrors(ASSEMBLED_KIT_FIELDS_NAME.BARCODES, undefined)
    }
    if (barcodeInput) {
      setBarcodeData([...barcodeData, { barcode: barcodeInput, isEditable: true }])
    }
    setBarcodeInput('')

  }

  const handleBarcodeValue = (e) => {
    let { value } = e.target
    value = value.trim()
    setBarcodeInput(value)
  }
  const handleBarcodeRemove = (ind) => {
    setBarcodeData(barcodeData.filter((ele, index) => index !== ind))
  }

  const barcodeInputValidator = () => {
    if (!barcodeData.length && !barcodeInput) {
      return 'This field is required.'
    }
    return undefined
  }

  const taxValidator = () => {
    if (Number(watch(ASSEMBLED_KIT_FIELDS_NAME.TAX_PERCENTAGE)) > 100 || watch(ASSEMBLED_KIT_FIELDS_NAME.TAX_PERCENTAGE).length > 4) {
      return 'Please provide valid tax percentage.'
    }
    return undefined
  }

  const toggleAddLabelModal = () => {
    if (isLabelEdit) {
      setLabelId(null)
      setIsLabelEdit(false)
    }
    setIsOpenAddSkuLabelModal(p => !p)
  }

  const handleSkuLabelChange = (e) => {
    const filteredOptions = e.filter(option => option.value !== LABELS_ADDITIONAL_OPTIONS.ADD_LABEL.value)
    const matchedOption = e.find(option => option.value === LABELS_ADDITIONAL_OPTIONS.ADD_LABEL.value)

    if (matchedOption?.value === LABELS_ADDITIONAL_OPTIONS.ADD_LABEL.value) {
      toggleAddLabelModal()
      setValue(ASSEMBLED_KIT_FIELDS_NAME.LABELS, filteredOptions)
    } else {
      setValue(ASSEMBLED_KIT_FIELDS_NAME.LABELS, e)
    }
  }

  const CustomDropdownOption = (props) => {
    const { data: { label, color, value, labelId } } = props
    const { Option } = components

    const handleClick = (event) => {
      event.stopPropagation()
      setIsDropDownOpen(p => !p)
      setLabel(label)
      initialLabelValue.current = label
      setIsLabelEdit(true)
      setLabelId(labelId)
      toggleAddLabelModal()
    }

    return (
      <Option key={value} {...props}>
        <div className="custom-option flex-center-between" title={label}>
          <div className='d-flex gap-20px align-items-center w-50'>
            <div className={classNames('cursor-pointer text-truncate', { 'rounded px-4px py-2px': color })}>
              {label}
            </div>
          </div>
          {value !== LABELS_ADDITIONAL_OPTIONS.ADD_LABEL.value ?
            <div className="edit-icon bg-white px-4px py-2px rounded-4px" title="Edit" onClick={handleClick}>
              <Edit2 size={12} fill="var(--bs-dark)" />
            </div> : null
          }
        </div>
      </Option>
    )
  }

  return (
    <div className="d-flex flex-column gap-30px w-100 bundle-detail-input-fields">
      {tenantType === TENANT_TYPE.FULFILLMENT_CENTRE && isSellerViewAllowed && (
        <div className="two-col-section d-flex gap-1 w-100">
          <FloatingDropDown
            isAsync
            isRequired
            width="100%"
            title="Select Seller"
            name={ASSEMBLED_KIT_FIELDS_NAME.SELLER}
            value={selectedSeller}
            loadOptions={getSellersAsync}
            onChangeFunc={(e) => sellerValueChangeHandler(e)}
            errors={errors}
            disabled={
              isEdit ||
              bundleCreationLoading ||
              updateBundleSkusRes.loading ||
              imageGettingUpload
            }
            validationSchema={{
              seller:
                ASSEMBLED_KIT_FORM_VALIDATION_SCHEMA[
                  ASSEMBLED_KIT_FIELDS_NAME.SELLER
                ]
            }}
          />
          <div className="w-100"></div>
        </div>
      )}
      {/* <div>
      </div> */}
      <div className="two-col-section d-flex gap-1 w-100">
        <InputField
          width="100%"
          label="Assembled Kit Name"
          name={ASSEMBLED_KIT_FIELDS_NAME.SKU_NAME}
          isRequired
          isClearable
          errors={errors}
          disabled={
            (isEdit && singleSkuLoading) ||
            bundleCreationLoading ||
            updateBundleSkusRes.loading ||
            imageGettingUpload
          }
          maxLength="3000"
          {...register(
            ASSEMBLED_KIT_FIELDS_NAME.SKU_NAME,
            ASSEMBLED_KIT_FORM_VALIDATION_SCHEMA[ASSEMBLED_KIT_FIELDS_NAME.SKU_NAME]
          )}
        />
        <InputField
          width="100%"
          name={ASSEMBLED_KIT_FIELDS_NAME.SKU_CODE}
          label={'SKU Code'}
          isRequired
          isClearable
          register={register}
          errors={errors}
          disabled={
            isEdit ||
            bundleCreationLoading ||
            updateBundleSkusRes.loading ||
            imageGettingUpload
          }
          {...register(
            ASSEMBLED_KIT_FIELDS_NAME.SKU_CODE,
            ASSEMBLED_KIT_FORM_VALIDATION_SCHEMA[ASSEMBLED_KIT_FIELDS_NAME.SKU_CODE]
          )}
        />
      </div>
      <div className='w-100'>
        <FloatingDropDown
          name={ASSEMBLED_KIT_FIELDS_NAME.LABELS}
          control={control}
          title='Labels'
          isAsync
          isMulti
          modifiedStyles={{
            option: (provided, state) => ({
              ...provided,
              cursor: 'pointer',
              fontSize: 12,
              color: state.isSelected ? '#FFF' : state.data.value === LABELS_ADDITIONAL_OPTIONS.ADD_LABEL.value ? 'var(--bs-primary)' : 'var(--bs-dark)'
            })
          }}
          loadOptions={getSkusLabels}
          value={watch(ASSEMBLED_KIT_FIELDS_NAME.LABELS)}
          cacheUniqs={[selectedSeller?.value, label]}
          additional={{ sellerId: selectedSeller?.value, page: 1 }}
          onChangeFunc={(e) => {
            handleSkuLabelChange(e)
          }}
          additionalComponents={{
            Option: CustomDropdownOption,
            NoOptionsMessage: () => <div className='d-flex align-items-start py-8px px-12px txt-sub-rg text-primary bg-primary-lighter cursor-pointer' onClick={toggleAddLabelModal}>
              {LABELS_ADDITIONAL_OPTIONS.ADD_LABEL.label}
            </div>
          }}
          menuIsOpen={isDropDownOpen}
          onMenuOpenFunc={() => {
            setIsDropDownOpen(true)
          }}
          onMenuCloseFunc={() => {
            setIsDropDownOpen(false)
          }}
        />
        <AddSkuLabelModal isOpen={isOpenAddSkuLabelModal} toggle={toggleAddLabelModal} label={label} setLabel={setLabel} sellerId={selectedSeller?.value} labelId={labelId} isEdit={isLabelEdit} isButtonDisabled={initialLabelValue.current === label} setIsLabelSuccess={setIsLabelSuccess} />
      </div>
      <div className='two-col-section d-flex flex-1 gap-1 w-100'>
        <div className='w-100'>
          <MultiInputValue
            {...register(ASSEMBLED_KIT_FIELDS_NAME.BARCODES, { validate: barcodeInputValidator })}
            width='100%'
            label='Barcodes'
            name={ASSEMBLED_KIT_FIELDS_NAME.BARCODES}
            onKeyDown={(e) => handleBarcodeKey(e)}
            onChange={(e) => handleBarcodeValue(e)}
            value={barcodeInput}
            valueArray={barcodeData}
            valueCanRemove={!isEdit}
            valueRemoveHandler={handleBarcodeRemove}
            disabled={(isEdit && singleSkuLoading) || bundleCreationLoading || updateBundleSkusRes.loading || imageGettingUpload}
            onBlur={blurHandler}
            errors={errors}
            isRequired
            style={{ minWidth: '50px' }}
          />
        </div>
      </div>
      <div className="d-flex flex-column gap-6px">
        <InputField
          width="100%"
          label="Write description here"
          className="text-area"
          type="textarea"
          name={ASSEMBLED_KIT_FIELDS_NAME.DESCRIPTION}
          value={watch(ASSEMBLED_KIT_FIELDS_NAME.DESCRIPTION)}
          disabled={
            (isEdit && singleSkuLoading) ||
            bundleCreationLoading ||
            updateBundleSkusRes.loading ||
            imageGettingUpload
          }
          maxLength="3000"
          {...register(ASSEMBLED_KIT_FIELDS_NAME.DESCRIPTION)}
        />
        <div className="d-flex gap-4px align-items-center">
          <img src={Vector} />
          <div className="text-dark-5 txt-asst-rg">
            {t('Maximum 3000 Characters')}
          </div>
        </div>
      </div>
      <div>
        <div className='txt-h3-sb text-dark pb-2'>{t('Price Details')}</div>
        <div className='d-flex gap-16px'>
          <div className='w-100'>
            <FloatingDropDown
              width='100%'
              title='Selling Price Includes Tax'
              value={watch(ASSEMBLED_KIT_FIELDS_NAME.SELLING_PRICE_INCLUDES_TAX)}
              name={ASSEMBLED_KIT_FIELDS_NAME.SELLING_PRICE_INCLUDES_TAX}
              control={control}
              // isClearable
              options={CATALOG_FLOATING_YES_AND_NO_OPTIONS}
              disabled={(isEdit && singleSkuLoading) || bundleCreationLoading || updateBundleSkusRes.loading || imageGettingUpload}
            />
          </div>

          <div className='w-100'>
            {isPosEnabled ?
              <FloatingDropDown
                width='100%'
                title='Tax Category'
                value={watch(ASSEMBLED_KIT_FIELDS_NAME.TAX_CATEGORY)}
                name={ASSEMBLED_KIT_FIELDS_NAME.TAX_CATEGORY}
                control={control}
                // isClearable
                isAsync
                loadOptions={getTaxCategoriesData}
                disabled={(isEdit && singleSkuLoading) || bundleCreationLoading || updateBundleSkusRes.loading || imageGettingUpload}
                additional={{
                  page: 1,
                  seller_id: globalSeller.seller_id
                }}
              /> :
              <InputField
                width='100%'
                label='Tax Percentage'
                name={ASSEMBLED_KIT_FIELDS_NAME.TAX_PERCENTAGE}
                isClearable
                type='number'
                errors={errors}
                {...register(ASSEMBLED_KIT_FIELDS_NAME.TAX_PERCENTAGE, { validate: taxValidator })}
                disabled={(isEdit && singleSkuLoading) || bundleCreationLoading || updateBundleSkusRes.loading || imageGettingUpload}
              />
            }
          </div>
        </div>
      </div>
    </div>
  )
}
