import { OrderDetailsRow } from '@src/views/sales/sales.utils';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from 'reactstrap';

const DestinationHubDetailsCard = ({ shipping_address, destination_hub = {} }) => {

  const { t } = useTranslation();

  const [hasRemarksExpanded, setRemarksExpanded] = useState(false);

  const addressDetailsData = [
    {
      key: 'hub_name',
      label: 'Hub Name',
      value: destination_hub.name,
      omit: !destination_hub.name,
    },
    {
      key: 'address',
      label: 'Address',
      value: shipping_address.display_address,
      omit: !shipping_address.display_address
    },
    {
      key: 'city',
      label: 'City',
      value: shipping_address.display_city,
      omit: !shipping_address.display_city
    },
    {
      key: 'state_country',
      label: 'State/Country',
      value: shipping_address.display_state_country,
      omit: !shipping_address.display_state_country
    },
    {
      key: 'phone_number',
      label: 'Phone No.',
      value: destination_hub.phone,
      omit: !destination_hub.phone
    },
  ]

  return (
    <Card className="details-card w-100 h-100 bg-white rounded-4">
      <div className="card-header d-flex justify-content-between py-12px px-16px rounded-top-4 text-secondary txt-h3-md">
        <span>{t('Destination Hub')}</span>
      </div>
      <div className='d-flex flex-column p-16px gap-16px'>
        {addressDetailsData
          .filter(detail => !detail.omit)
          .map(detail => (
            <OrderDetailsRow
              key={detail.key}
              entityNameColSpan={4}
              entityValueColSpan={8}
              entityName={detail.label}
              entityValue={detail.value || '-'}
            />
          ))}
      </div>
    </Card>
  );
};

export default DestinationHubDetailsCard;
