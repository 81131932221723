import VerticalMenuNavItems from '@src/@core/layouts/components/menu/vertical-menu/VerticalNavMenuItems'
import { useRTL } from '@src/utility/hooks/useRTL'
import classNames from 'classnames'
import { ChevronLeft, ChevronRight } from 'react-feather'
import { useTranslation } from 'react-i18next'
// import { useSelector } from "react-redux"
import { Button } from 'reactstrap'

const SecondaryMenu = props => {
  // ** Store Vars
  // const dispatch = useDispatch()
  // const store = useSelector(state => state)
  // const secondaryNavCollapsed = store.layout.secondaryNavCollapsed
  const [isRtl] = useRTL()
  const { t } = useTranslation()
  // const isFeatureNotificationBannerVisible = useSelector(store => store.auth.isFeatureNotificationBannerVisible)
  const {
    secMenuData,
    secondaryNavCollapsed,
    setSecondaryNavCollapsed,
    ...rest
  } = props
  const Icon = () => {
    if (isRtl) {
      return !secondaryNavCollapsed ? <ChevronRight size={16} color="white" /> : <ChevronLeft size={16} color="white" />
    } else {
      return !secondaryNavCollapsed ? <ChevronLeft size={16} color="white" /> : <ChevronRight size={16} color="white" />
    }
  }
  return (
    <div
      className={classNames('secondary-menu', {
        'sec-menu-width': secondaryNavCollapsed
      })}
    >
      <div className="secondary-header">
        {/* <h2 className={`${sideBarCollapsed ? "ps-1" : "visHideSettings" }`}>{secMenuData.title}</h2> */}
        <h1 className="ps-1 overflow-hidden">{t(secMenuData.title)}</h1>
        <Button
          color="light"
          onClick={() => setSecondaryNavCollapsed(prev => !prev)}
          className="collapse-btn p-0 d-flex justify-content-center align-items-center"
        >
          <Icon size={7} />
        </Button>
      </div>
      <div className={classNames('secondary-items-container'
        // {"with-banner": isFeatureNotificationBannerVisible}
      )}>
        <VerticalMenuNavItems items={secMenuData.children} {...rest} />
      </div>
    </div>
  )
}

export default SecondaryMenu
