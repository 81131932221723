import { MarkerClusterer } from '@googlemaps/markerclusterer'
import React, { useEffect, useRef, useState } from 'react'
import { DEFAULT_MAP_CENTER } from '@src/App.constants'

export function MapComponent({getParticularLocation, hubs, center = DEFAULT_MAP_CENTER.SAUDI_ARBIA, zoom, options, position, setLocationName, city}) {
    
  const ref = useRef()
  const [map, setMap] = useState()
  useEffect(() => {
    if (hubs.length) {
      getParticularLocation(hubs[0].address.latitude, hubs[0].address.longitude)
    }
    setLocationName?.(city)
  }, [city, hubs])

  useEffect(() => {
    if (ref.current && !map) {
      setMap(new window.google.maps.Map(ref.current, {}))
    }
    // Add some markers to the map.
  }, [ref, map])
 
  useEffect(() => {
    const map = new window.google.maps.Map(ref.current, {
      center,
      zoom
    })
        
    const markers = position?.map(({ lat = DEFAULT_MAP_CENTER.SAUDI_ARBIA.lat, lng = DEFAULT_MAP_CENTER.SAUDI_ARBIA.lng}) => {
      lat = Number(lat)
      lng = Number(lng)
      const marker = new window.google.maps.Marker({
        title: city?.[1]?.formatted_address,
        position:{lat, lng}
      })
      return marker
    })

    const mcOptions = {
      styles: [
        {
          height: 53,
          url: 'https://github.com/googlearchive/js-marker-clusterer/blob/gh-pages/images/heart30.png',
          width: 53,
          background:'#7367f0'
        }
      ]
    }
    new MarkerClusterer({ map, markers, mcOptions })

  }, [center, zoom])
  // useEffect(() => {
  //     if (map) {
  //         ["click", "idle"].forEach((eventName) => google.maps.event.clearListeners(map, eventName))
  //         if (onClick) {
  //             map.addListener("click", onClick)
  //         }
  //         if (onIdle) {
  //             map.addListener("idle", () => onIdle(map))
  //         }
  //     }
  // }, [map, onClick, onIdle])

  return (
    <>
      <div ref={ref} className="map" style={options} />
      {React.Children.map((child) => {
        if (React.isValidElement(child)) {
          // set the map prop on the child component
          // @ts-ignore
          return React.cloneElement(child, { map })
        }
      })}
    </>
  )
}