import { ExpandableCustomTable } from '@src/@core/components/ui/globalTable/ExpandableCustomTable'
import SideSheet from '@src/@core/components/ui/sideSheet'
import { axiosInstance } from '@src/network/AxiosInstance'
import { useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { ModalBody } from 'reactstrap'
const getWeightedAverageCostData = async({params}) => {
  const {skuCode, sellerId} = params
  const weightedAverageCostData = await axiosInstance(`/api/v1/products/sellers/${sellerId}/skus/sku_code/${skuCode}/weighted_average_cost`)
  return weightedAverageCostData.data
}
const WeightedAverageCostModal = ({
  isOpen,
  toggle,
  sku
}) => {
  if (!isOpen) {
    return
  }
  const {t} = useTranslation()
  const { data: weightedAverageCostData, isFetching, isError, isSuccess } = useQuery({ 
    queryKey: ['fetch-weighted-average-cost'], 
    queryFn: () => {
      const params = {
        skuCode: sku.seller_sku_code,
        sellerId: sku.seller_id
      }
      return getWeightedAverageCostData({params})
    }
  })

  const tableColumns = [
    {
      id: 1,
      name: t('S. No.'),
      minWidth: '60px',
      maxWidth: '60px',
      cell: (_, index) => (
        <div className="w-100 text-center">
          {index + 1}
        </div>
      )
    },
    {
      id: 2,
      name: t('Hub'),
      selector: (row) => row.hub_name
    },
    {
      id: 3,
      name: <div className='w-100 text-end'>{t('Average Cost')}</div>,
      cell: (row) => <div className='w-100 text-end'>{row.weighted_average_cost}</div>
    }
  ]

  return (
    <SideSheet
      modalClassName='modal-slide-in child-detail-sku-modal'
      size='sm'
      isOpen={isOpen}
      toggle={toggle}
      title= {
        <div className='d-flex flex-column gap-50'>
          <span className='txt-h2-md'>{t('Weighted Average Cost')}</span>
          <span className='txt-sub-md text-dark-5 text-truncate max-width-500'>{t('SKU Name')}: {sku.name}</span>
        </div>
      }
    >
      <ModalBody className='p-24px'>
        <ExpandableCustomTable
          loading={isFetching}
          error={isError}
          success={isSuccess}
          data={weightedAverageCostData?.data || []}
          columns={tableColumns}
          useReactPaginate={false}
          showPagination={false}
          showColumnsTableHeader={false}
        />  
      </ModalBody>
    </SideSheet>
  )
}

export default WeightedAverageCostModal
