import CustomTooltip from '@src/@core/components/ui/tooltip'
import { useOnClickOutside } from '@src/utility/hooks/useOnClickOutside'
import { useRef, useState } from 'react'
import { ChevronRight } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap'
import omnifulApp from '../../../../assets/images/svg/omniful_apps.svg'
import QrcodeModal from '../../../components/ui/app-download-qr-modal/ModalQRCode'
import { omnifulApps } from './constant.navbar'
import './styles.scss'

const OmnifulApps = () => {
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState(false)
  const [isVisible,setIsVisible] = useState(false)
  const [qrData, setQrData] = useState({
    appName: '',
    appURL: '',
    icon: '',
    availability: '',
    iconWidth: '',
    iconHeight: ''
  })
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const isPosEnabled = useSelector(store => store.auth.userData?.user_plan?.is_pos_enabled)

  const handleOpenPopup = (app) => {
    setIsOpen(true)
    setQrData({
      appName: app.appName,
      appURL: app.appURL,
      icon: app.icon,
      availability: app.availability,
      iconWidth: app.iconWidth,
      iconHeight: app.iconHeight
    })
  }

  const handleClosePopup = () => {
    setIsOpen(false)
  }

  const menuToggle = () => {
    setIsMenuOpen(prev => !prev)
    setIsVisible(false)
  }

  const dropdownRef = useRef()

  const onBlurHandler = () => {
    if (isMenuOpen) setIsMenuOpen(false)
  }

  useOnClickOutside(dropdownRef, onBlurHandler)

  return (
    <div ref={dropdownRef}>
      <Dropdown isOpen={isMenuOpen} toggle={menuToggle} tag="li" className="dropdown-user download-app-dropdown nav-item app-download-dropdown-wrapper width-40px">
        <CustomTooltip
          placement="bottom"
          className="bg-dark border-dark error-text text-white custom-tool-tip"
          flip={false}
          content='Available Apps'
          isVisible={isVisible}
        >
          <DropdownToggle 
            onMouseEnter={() => setIsVisible(true)}
            onMouseLeave={() => setIsVisible(false)}
            tag='button'
            data-toggle='dropdown'
            className="nav-link dropdown-user-link omniful-app-box"
          >
            <div className="width-22px">
              <img src={omnifulApp} alt="omniful-apps" height="100%" width="100%" />
            </div>
          </DropdownToggle>
        </CustomTooltip>
        <DropdownMenu className='custom-app-dropdown-menu' placement="center">
          {omnifulApps({enabled: {PICKER_PLUS: true,POINT_OF_SALE: isPosEnabled}}).map((app, index) => {
            return app.isEnabled && <DropdownItem key={index} className='w-100 custom-dropdown-item flex-center-between' onClick={() => handleOpenPopup(app)}>
              <div>
                <p className='txt-body-md m-0 text-dark'>{t(app.appName)}</p>
                <p className='txt-asst-rg m-0 text-dark-6'>{t(app.desc)}</p>
              </div>
              <ChevronRight className='txt-body-md text-dark' />
            </DropdownItem>
          })}
        </DropdownMenu>
      </Dropdown>
      <QrcodeModal modalOpen={isOpen} toggle={handleClosePopup} qrData={qrData} />
    </div>
  )
}

export default OmnifulApps