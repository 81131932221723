import { wrapCreateBrowserRouter } from '@sentry/react'
import BlankLayout from '@src/@core/layouts/BlankLayout.jsx'
import { getRoutes } from '@src/router/routes'
import { retry } from '@src/utility/retry'
import FallBack from '@src/views/home/Fallback'
import { lazy, useEffect, useMemo } from 'react'

import AxiosInterceptors from '@src/@core/components/ui/axios-interceptor'
import { useLayout } from '@src/utility/hooks/useLayout'
import { Outlet, RouterProvider, createBrowserRouter } from 'react-router-dom'
import useNotification from '../utility/hooks/useNotification'

const Authentication = lazy(() => retry(() => import('@src/pages/authentication')))

const CoreWrapper = () => {

  return (
    <AxiosInterceptors>
      <Outlet />
    </AxiosInterceptors>
  )
}

const Router = () => {
  
  const { layout } = useLayout()
  const modulesRoutes = getRoutes(layout)

  useNotification()

  useEffect(() => {
    const minWidth = 1024
    const viewport = document.querySelector('meta[name="viewport"]')
    if (window.innerWidth < minWidth) {
      viewport.setAttribute('content', 'width=device-width,initial-scale=0.3,maximum-scale=1')
    } 
  }, [])
  
  const allRoutes = useMemo(() => [
    {
      path: '/',
      element: <CoreWrapper />,
      errorElement: <FallBack />,
      children: [
        {
          path: '/login',
          element: <Authentication />,
          title: 'Login',
          meta: {
            layout: 'blank',
            publicRoute: true,
            restricted: true
          }
        },
        ...modulesRoutes,
        {
          path: '*',
          element: <BlankLayout />,
          children: [{ path: '*', element: <Authentication /> }]
        }
      ]
    }
  ], []) 

  const sentryCreateBrowserRouter = wrapCreateBrowserRouter(
    createBrowserRouter
  )
  
  const BrowserRouter = sentryCreateBrowserRouter(allRoutes)

  return <RouterProvider router={BrowserRouter}>
    <CoreWrapper />
  </RouterProvider>
}

export default Router