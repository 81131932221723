import EditIcon from '@src/assets/images/icons/EditIcon'
import { CheckCircle, Plus, Slash } from 'react-feather'

export const PURCHASE_ROUTES = {
  PURCHASE_ORDER: '/purchases/purchase-order',
  ALL_SUPPLIERS: '/purchases/all-suppliers',
  SELLERS_AND_SUPPLIERS: '/purchases/sellers-and-suppliers',
  SELLER: '/purchases/sellers'
}

export const PURCHASE_ORDER_SIDE_BAR_VIEW = {
  SAVE: 'Save',
  PRINT: 'Print',
  HISTORY: 'History',
  DISCARD: 'Discard',
  CREATE: 'Create'
}

export const SELLERS_AND_SUPPLIERS_VIEW = {
  SELLER_TO_SUPPLIER: 'Seller To Supplier',
  SUPPLIER_TO_SELLER: 'Supplier To Seller'
}

export const CREATE_SELLER_AND_SUPPLIER = {
  CREATE_NEW: { id: 1, name: 'Create New' },
  CREATE_NEW_SUPPLIER: { id: 2, name: 'Create Supplier' },
  SELLER_SUPPLIER_MAP: { id: 3, name: 'Create New Seller’s Supplier list' },
  EDIT: {id: 4, name: 'Edit Supplier'}
}

export const PURCHASES_TABLE_NAMES = {
  PO_SHIPMENTS: 'po_shipments'
}

export const PO_SAMPLE_SHEETS = {
  PO_BULK_UPLOAD_WITH_SHIPMENT: 'https://omniful-staging-uploads.s3.eu-central-1.amazonaws.com/wms/purchase_order/purchase_order_items/po+shipment+sheet.csv',
  PO_BULK_UPLOAD_WITHOUT_SHIPMENT: 'https://omniful-staging-uploads.s3.eu-central-1.amazonaws.com/wms/purchase_order/purchase_order_items/sample_purchase_order_items.csv'
}

export const PURCHASE_ORDER_TYPES = {
  STANDARD: 'standard',
  SHIPMENT: 'shipment'
}

export const PURCHASE_ORDER_TABS = {
  ORDERS: { id: 'purchase_orders', name: 'Orders' },
  SHIPMENTS: { id: 'po_shipments', name: 'Shipments' }
}

export const FILTER_KEYS = {
  SELLER_ID: 'seller_id',
  SUPPLIER_ID: 'supplier_id',
  GATE_ENTRY_STATUS: 'gate_entry_status',
  ORDER_STATUS: 'order_status',
  CREATED: 'created'
}

export const PO_TABS = {
  ITEM_DETAILS: { id: 'ITEM_DETAILS', name: 'Item Details' },
  SHIPMENT_DETAILS: { id: 'SHIPMENT_DETAILS', name: 'Shipment Details' },
  GATE_ENTRY: { id: 'GATE_ENTRY', name: 'Gate Entry Details' },
  GRN: { id: 'GRN', name: 'GRN Details' },
  PUTAWAY: { id: 'PUTAWAY', name: 'Putaway Details' },
  PURCHASE_ORDER_LOGS: { id: 'PURCHASE_ORDER_LOGS', name: 'Purchase Order Log' }
}


export const PURCHASE_ORDER_TAB_LIST = (isShipmentVisible, isGateEntryVisible, locationInventoryEnabled) => {
  if (locationInventoryEnabled) {
    if (isShipmentVisible && isGateEntryVisible) {
      return [
        { id: PO_TABS.ITEM_DETAILS.id, name: PO_TABS.ITEM_DETAILS.name },
        { id: PO_TABS.SHIPMENT_DETAILS.id, name: PO_TABS.SHIPMENT_DETAILS.name },
        { id: PO_TABS.GATE_ENTRY.id, name: PO_TABS.GATE_ENTRY.name },
        { id: PO_TABS.GRN.id, name: PO_TABS.GRN.name },
        { id: PO_TABS.PUTAWAY.id, name: PO_TABS.PUTAWAY.name }
      ]
    } else if (isGateEntryVisible) {
      return [
        { id: PO_TABS.ITEM_DETAILS.id, name: PO_TABS.ITEM_DETAILS.name },
        { id: PO_TABS.GATE_ENTRY.id, name: PO_TABS.GATE_ENTRY.name },
        { id: PO_TABS.GRN.id, name: PO_TABS.GRN.name },
        { id: PO_TABS.PUTAWAY.id, name: PO_TABS.PUTAWAY.name }
      ]
    } else if (isShipmentVisible) {
      return [
        { id: PO_TABS.ITEM_DETAILS.id, name: PO_TABS.ITEM_DETAILS.name },
        { id: PO_TABS.SHIPMENT_DETAILS.id, name: PO_TABS.SHIPMENT_DETAILS.name },
        { id: PO_TABS.GRN.id, name: PO_TABS.GRN.name },
        { id: PO_TABS.PUTAWAY.id, name: PO_TABS.PUTAWAY.name }
      ]
    } else {
      return [
        { id: PO_TABS.ITEM_DETAILS.id, name: PO_TABS.ITEM_DETAILS.name },
        { id: PO_TABS.GRN.id, name: PO_TABS.GRN.name },
        { id: PO_TABS.PUTAWAY.id, name: PO_TABS.PUTAWAY.name }
      ]
    } 
  } else {
    if (isShipmentVisible) {
      return [
        { id: PO_TABS.ITEM_DETAILS.id, name: PO_TABS.ITEM_DETAILS.name },
        { id: PO_TABS.SHIPMENT_DETAILS.id, name: PO_TABS.SHIPMENT_DETAILS.name },
        { id: PO_TABS.PURCHASE_ORDER_LOGS.id, name: PO_TABS.PURCHASE_ORDER_LOGS.name }
      ]
    } else {
      return [{ id: PO_TABS.ITEM_DETAILS.id, name: PO_TABS.ITEM_DETAILS.name }, { id: PO_TABS.PURCHASE_ORDER_LOGS.id, name: PO_TABS.PURCHASE_ORDER_LOGS.name }]
    }
  }
  
}

export const GATE_ENTRY_STATUS = {
  COMPLETE: 'Complete',
  PENDING: 'Pending',
  COMPLETE_STATUS: 'gate_entry_complete',
  PENDING_STATUS: 'gate_entry_pending'
}

export const CONFIG_STATUS = {
  GATE_ENTRY: 'gate_entry'
}

export const GATE_ENTRY_STATUS_OPTIONS = [
  { id: 1, value: GATE_ENTRY_STATUS.COMPLETE_STATUS, label: GATE_ENTRY_STATUS.COMPLETE },
  { id: 2, value: GATE_ENTRY_STATUS.PENDING_STATUS, label: GATE_ENTRY_STATUS.PENDING }
]

export const ENTITY_TYPES = {
  PO: 'po',
  PO_SHIPMENT: 'po_shipment',
  STO: 'sto'
}

export const PACKAGE_TYPE = {
  UNIT: 'unit',
  PALLET: 'pallet',
  CASE_PACK: 'case_pack',
  EA: 'ea'
}

export const ORDER_STATUS = {
  ORDERED: 'ordered',
  PARTIAL_RECEIVED: 'partial received',
  RECEIVED: 'received',
  CLOSED: 'closed', 
  CANCELLED: 'cancelled'
}

export const ORDER_STATUS_OPTIONS = [
  { id: 1, label: 'Ordered', value: 'ordered'},
  { id: 2, label: 'Partially Received', value: 'partial_received'},
  { id: 3, label: 'Received', value: 'received' },
  { id: 4, label: 'Closed', value: 'closed' }
]

export const SUPPLIER_FORM_DEFAULT_STATE = {
  name: '',
  email: '',
  seller_id: '',
  contact_person: '',
  contact_number: '',
  contactDetails: {
    contactNumber: '',
    countryCode: ''
  },
  addressLine1: '',
  addressLine2: '',
  postcode: '',
  country: '',
  state: null,
  city: ''
}


export const GATE_ENTRIES_STATUS = {
  PENDING: { id: 1, label: 'Pending', value: 'pending' },
  COMPLETED: { id: 2, label: 'Completed', value: 'completed' },
  CLOSED: { id: 3, label: 'Closed', value: 'closed' }
}

export const PO_LOGS_ACTIONS = {
  CREATE: {value: 'create', id: 1, icon: Plus},
  EDIT: {value: 'edit', id: 2, icon: EditIcon},
  ACCEPT: {value: 'recieved', id: 3, icon: CheckCircle},
  CLOSE: {value: 'closed', id: 4, icon: Slash}
}