import { ExpandableCustomTable } from '@src/@core/components/ui/globalTable/ExpandableCustomTable'
import { ALL_COLUMN_NAME_MAPPING } from '@src/@core/components/ui/globalTable/globalTable.constant'
import SideSheet from '@src/@core/components/ui/sideSheet'
import no_sku_image from '@src/assets/images/omniful/no_sku_image.svg'
import { useTranslation } from 'react-i18next'
import PerfectScrollbar from 'react-perfect-scrollbar'

const BatchDeatilsSideSheet = ({ data, toggleSidebar, modalOpen }) => {
  const { t } = useTranslation()

  const batchColumns = [
    {
      id: 1,
      name: t('S. No.'),
      key: 'serialNumber',
      width: '60px',
      selector: row => row,
      cell: (_, index) => <div className="w-100 text-center">{index + 1}</div>
    },
    {
      id: 2,
      name: t('Batch Number'),
      key: 'batch_number',
      minWidth: '200px',
      cell: row => <div className="d-flex flex-column gap-4px">
        <span className="d-inline">{row.number || '-'}</span>
        <span className="d-inline text-dark-6">{row.expiry_date}</span>
      </div>
    },
    {
      id: 3,
      name: <div className="w-100 text-end text-truncate" title={t('Order Qty')}>{t('Order Qty')}</div>,
      width: '90px',
      key: 'order_qty',
      cell: row => <div className="w-100 text-end">{row.ordered_quantity}</div>
    },
    {
      id: 3,
      name: <div className="w-100 text-end text-truncate" title={t('Picked Qty')}>{t('Picked Qty')}</div>,
      width: '90px',
      key: 'picked_qty',
      cell: row => <div className="w-100 text-end">{row.picked_quantity}</div>
    },
    {
      id: 3,
      name: <div className="w-100 text-end text-truncate" title={t('Packed Qty')}>{t('Packed Qty')}</div>,
      width: '100px',
      key: 'packed_qty',
      cell: row => <div className="w-100 text-end">{row.packed_quantity}</div>
    }
  ]
  return (
    <SideSheet
      title="Batches"
      isOpen={modalOpen}
      toggle={() => toggleSidebar({})}
      unmountOnClose={true}
      contentClassName="p-0"
      modalClassName="modal-slide-in sidebar-todo-modal"
    >
      <PerfectScrollbar>
        
        {data.batches?.length > 0 && <div className="w-100 mt-2 px-2">
          <div className="d-flex gap-16px card-detail mb-2">
            <div className="px-20px py-14px border border-dark-2 rounded-8px bg-primary-lighter-global img-container">
              <img
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null
                  currentTarget.src = no_sku_image
                }}
                src={
                  data.seller_sku?.images?.[0]?.default
                    ? data.seller_sku?.images?.[0]?.default
                    : no_sku_image
                }
                alt={data.seller_sku?.name}
                width={48}
                height={48}
              />
            </div>
            <div className="d-flex flex-column gap-8px">
              <div className="txt-h3-md text-dark">{data.seller_sku?.name}</div>
              <div className="txt-body-rg text-dark-6">{data.seller_sku_code}</div>
            </div>
          </div>
          <ExpandableCustomTable
            showColumnsTableHeader={false}
            data={data.batches}
            columns={batchColumns}
            columnName={ALL_COLUMN_NAME_MAPPING.SKU_BATCH_DETAILS}
            showPagination={false}
          />
        </div>}
      </PerfectScrollbar>
    </SideSheet>
  )
}

export default BatchDeatilsSideSheet