import Button from '@src/@core/components/ui/button'
import { AlertTriangle } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { Modal } from 'reactstrap'

const BinEnabledAllowShelfLifeModal = (props) => {
  const { t } = useTranslation()
  const {isOpen, toggle, isMaxShelfLifeEnforced } = props

  return (
    <>
      <Modal
        isOpen={isOpen}
        className='max-shelf-life-grn-warning-modal-wrapper w-100'
        centered
      >
        <div className='flex-center-start gap-16px'>
          <div className='p-10px rounded-8px bg-warning-light flex-center-center'>
            <AlertTriangle 
              size={38} 
              color='var(--bs-warning)' 
              fill='var(--bs-warning)'
              strokeWidth={1.5}
              stroke="var(--bs-warning-light)"
            />
          </div>
          <div className='text-dark txt-h1-sb'>
            {t('The scanned item is below the acceptable shelf life threshold.')}
          </div>
        </div>
        <div className='text-dark-6 txt-body-rg pb-16px'>
          {isMaxShelfLifeEnforced ? 
            t('Since the remaining days until expiry are less than the minimum required shelf life for receiving, the items of this batch must be marked as failed.')
            : 
            t('The remaining days until expiry are less than the minimum required shelf life for receiving. Please mark the items of this batch as pass or fail accordingly.')}
        </div>
        <div className='flex-center-end w-100'>
          <Button onClick={toggle}>{t('Confirm')}</Button>
        </div>
      </Modal>
    </>
  )
}

export default BinEnabledAllowShelfLifeModal