import Button from '@src/@core/components/ui/button'
import ComponentSpinner from '@src/@core/components/ui/spinner/Loading-spinner'
import { API_ENDPOINTS, getApiUrl } from '@src/api.urls'
import { TENANT_TYPE } from '@src/App.constants'
import { useApi } from '@src/configs/react-query/useApi'
import { axiosInstance } from '@src/network/AxiosInstance'
import PrintPosInvoice from '@src/views/pos/pages/print-invoice/printPosInvoice'
import { OrderDetailsRow } from '@src/views/sales/sales.utils'
import classNames from 'classnames'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Card, Spinner } from 'reactstrap'
import OrderDetailsTable from '../../ordersForApproval/OrderDetailsTable'
import './ItemDetails.scss'

const PosItemDetails = () => {
  const { t } = useTranslation()
  const {singleOrderDetailsData } = useSelector((state) => state.sales)
  const sales_loading = useSelector((state) => state.sales.loading)
  const { loading } = useSelector((state) => state.returns)
  const tenantType = useSelector(store => store.auth.userData.tenant.type)
  const orderDetails = singleOrderDetailsData?.data
  const [posInvoiceData, setPosInvoiceData] = useState(null)
  const printRef = useRef(null)

  const { mutate: getPosInvoiceData, isPending } = useApi({
    isMutation: true,
    apiKey: ['get-pos-print-data'],
    apiFn: ({ orderId }) => {
      const url = getApiUrl(API_ENDPOINTS.ORDERS.GET_INVOICE_DATA, { orderId })
      return axiosInstance.get(url, { params: { source: 'pos' } })
    },
    onSuccess: async ({ data }) => {
      setPosInvoiceData(data)
    }
  })

  const isCustomerDetailVisible = useMemo(() => {
    return (orderDetails?.customer?.first_name || orderDetails?.customer?.mobile || orderDetails?.customer?.email)
  }, [orderDetails])

  const handlePrint = () => {
    printRef.current.click()
  }

  useEffect(() => {
    if (posInvoiceData) handlePrint()
  }, [posInvoiceData])

  return <div className="mb-2 order-item-detail w-100">
    {orderDetails && !sales_loading.getSingleOrderDetails ? <div>
      <div className="flex-center-between gap-16px">
        <div></div>
        {/* <div className="status-wrapper bg-white rounded-8px d-flex align-items-center gap-12px flex-grow-1">
          <div className="status-label rounded-start text-secondary txt-h3-md py-8px px-16px">{t('Status')}</div>
          <div className="d-flex gap-8px pe-12px">
            {orderDetails.status && <CustomLabel title={orderDetails.status} className="order-status-tag text-uppercase" />}
          </div>
        </div> */}
        <div>
          <Button
            className='w-100 flex-center-center'
            onClick={() => { getPosInvoiceData({ orderId: orderDetails.id }) }}
            disabled={isPending}
            icon={isPending && Spinner}
            iconSize='sm'
          >
            {t('Print Invoice')}
          </Button>
        </div>
      </div>
      <div className="d-flex my-24px gap-24px">
        <Card className={classNames('details-card bg-white w-50 rounded-4', {'w-100': !isCustomerDetailVisible})}>
          <div className="card-header py-12px px-16px rounded-top-4 text-secondary txt-h3-md">
            <span>{t('Basic Details')}</span>
          </div>
          <div className={classNames('basic-details-row-wrapper p-16px', {'d-flex flex-column gap-16px': isCustomerDetailVisible})}>
            <OrderDetailsRow entityNameColSpan={4}  entityValueColSpan={8} entityName='Created At' entityValue={orderDetails.order_created_at || '-'} />
            <OrderDetailsRow entityNameColSpan={4}  entityValueColSpan={8} entityName='Ordered Quantity' entityValue={orderDetails.ordered_quantity || '-'} />
            <OrderDetailsRow entityNameColSpan={4}  entityValueColSpan={8} entityName='Number of Items' entityValue={orderDetails.total_item_count || '-'} />
            {orderDetails.payment_method && <OrderDetailsRow entityNameColSpan={4}  entityValueColSpan={8} entityName='Payment Status' entityValue={
              <span className="flex-center-start gap-12px">
                {orderDetails.payment_method}
              </span>}
            />}
            {orderDetails.invoice.payment_mode && <OrderDetailsRow entityNameColSpan={4}  entityValueColSpan={8} entityName='Payment Mode' entityValue={orderDetails.invoice.payment_mode} />}
            <OrderDetailsRow entityNameColSpan={4}  entityValueColSpan={8} entityName='Hub Name' entityValue={orderDetails.hub.name || '-'} />
            {tenantType === TENANT_TYPE.FULFILLMENT_CENTRE && <OrderDetailsRow entityNameColSpan={4}  entityValueColSpan={8} entityName='Seller Name' entityValue={orderDetails.seller_name || '-'} />}
            <OrderDetailsRow entityNameColSpan={4}  entityValueColSpan={8} entityName='Source' entityValue={orderDetails.display_source} />
            {orderDetails.order_note && <OrderDetailsRow entityNameColSpan={4}  entityValueColSpan={8} entityName='Note' entityValue={orderDetails.order_note} />}
          </div>
        </Card>
        {isCustomerDetailVisible && <Card className="details-card bg-white w-50 rounded-4">
          <div className="card-header d-flex justify-content-between py-12px px-16px rounded-top-4 text-secondary txt-h3-md">
            <span>{t('Customer Details')}</span>
          </div>
          <div className='d-flex flex-column p-16px gap-16px'>
            <OrderDetailsRow entityNameColSpan={4}  entityValueColSpan={8} entityName='Customer Name' entityValue={<span> {orderDetails.customer?.first_name || '-'} {orderDetails.customer?.last_name}</span>} />
            <OrderDetailsRow entityNameColSpan={4}  entityValueColSpan={8} entityName='Mobile Number' entityValue={orderDetails.customer?.mobile || '-'} />
            <OrderDetailsRow entityNameColSpan={4}  entityValueColSpan={8} entityName='Email ID' entityValue={orderDetails.customer?.email || '-'} />
          </div>
        </Card>}
      </div>
      <OrderDetailsTable orderDetails={orderDetails} />
    </div> : <ComponentSpinner className='m-5' />}
    <div className='d-none'>
      {posInvoiceData && <PrintPosInvoice printBtnRef={printRef} printData={posInvoiceData} onAfterPrint={() => setPosInvoiceData(null)} />}
    </div>
  </div>
}

export default PosItemDetails