import Button from '@src/@core/components/ui/button'
import CustomToast from '@src/@core/components/ui/custom-toast/CustomToast'
import { WarningLogo } from '@src/assets/images/omniful/warning'
import { axiosInstance } from '@src/network/AxiosInstance'
import { useMutation } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { Modal, Spinner } from 'reactstrap'

const DeleteSkuTag = ({isOpen, toggle, data, setSkuTagDeleted}) => {
  const { t } = useTranslation()

  const { mutate: deleteSkuTag, isPending: isDeleteSkuTagPending } = useMutation({
    mutationKey: ['delete-sku-tag'],
    mutationFn: ({ skuTagId }) => axiosInstance.delete(`/api/v1/products/labels/${skuTagId}`),
    onSuccess: () => {
      setSkuTagDeleted(true)
      CustomToast('Sku tag deleted successfully', { my_type: 'success' })
      toggle()
    }
  })

  return (
    <Modal centered isOpen={isOpen} toggle={toggle} onClosed={() => setSkuTagDeleted(false)}>
      <div className="d-flex flex-column gap-12px p-24px">
        <div className="d-flex justify-content-center content-logo">
          <WarningLogo size="120" />
        </div>
        <div className="d-flex flex-column align-items-start gap-12px">
          <div className="txt-h1-sb text-dark">
            {t('Are you sure you want to delete the SKU Tag ')} {data?.label} {t(' ?')}
          </div>
          <div className="txt-body-rg text-dark">{t('Deleting this SKU Tag will remove it from all the SKUs where it is currently applied.')}</div>
          <div className="flex-center-end w-100">
            <div className="d-flex gap-12px">
              <Button ofStyle='noBackground' disabled={isDeleteSkuTagPending} onClick={toggle}>{t('Cancel')}</Button>
              <Button disabled={isDeleteSkuTagPending} onClick={() => deleteSkuTag({ skuTagId: data?.value})}>{isDeleteSkuTagPending && <Spinner size="sm" className="text-white" />}{t('Okay')}</Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default DeleteSkuTag