import { PAGINATION_PER_PAGE_VALUES } from '@src/App.constants'
import { useOnClickOutside } from '@src/utility/hooks/useOnClickOutside'
import { useEffect, useMemo, useRef, useState } from 'react'
import DataTable from 'react-data-table-component'
import { ChevronDown, ChevronLeft, ChevronRight, ChevronUp } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { Card, Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap'
import TableHeader from './TableHeader'
import './styles.scss'
// import sortingIcon from '@src/assets/images/icons/Sorting.js'
// import { useTranslation } from 'react-i18next'
// import { useRTL } from '@src/utility/hooks/useRTL'
import { useRTL } from '@src/utility/hooks/useRTL'

import { ErrorState, NoMatchesFound, NoRecordFound } from '@src/assets/data/assets'
import useLocalStorageTableColumns from '@src/utility/hooks/useTableColumn'
import classNames from 'classnames'
import CheckBox from '../../new-checkbox'
import ProgressLoader from '../../progressLoader/index'
import SortIcon from '/src/assets/images/svg/Sort.svg'
// method that returns the react component with filter icon
//------------------------------------------------------- Information about parameters---------------------------------------------------------------------------

// data: an array of objects in the format specified in initial config for the table   (required)

// columns: array of objects with all the column headers information specified (zeroth level config) (required)

// (optional)
// [tableFilters, setTableFilters]: an useState() responsible for communicating which filter is applied to a particular column, to the parent component.
// The tableFilters state is created in the parent component in which the table is being used.
// Format for tableFilters: [{ id: '0000' }], here id is the id given to each column in initial config, and the bit string
// is a string with the length of number of options passed to that column, 0 implies the ith filter is inactive now, and 1 indicates
// the ith filter is active

// handlePagination, meta: is responsible for pagination. (optional)

// selectable: a boolean attribute responsible for making table selectable (optional)

// (optional)
// ExpandableRowsComponent: if the table has to be made expandable then expandableRowsComponenet is passed, a custom react component
// ExpandabaleRowsComponent({data}): it recieves data as parameter, which signifies the data for each row

// handleSelectedRowsChange : is a method passed by parent component, will receive the data for selected rows as a prop ({ selectedRows })

//  Note: For more info, find a readme attached in the github repo (if not existing yet, will be there soon).
//---------------------------------------------------------------------------------------------------------------------------------------------------------------

// ** Custom Checkbox Component
const CustomCheckbox = ({isPartial, ...rest}) => {
  const { name, checked, columnName } = rest
  
  const generateRandomId = () => {
    return 'id-' + Math.random().toString(36).substr(2, 9);
  }

  const determineCheckedState = () => {
    if (name === 'select-all-rows') {
      return isPartial || checked
    }
    return checked
  }
  const determinePartialCheckedState = () => {
    return name === 'select-all-rows' ? isPartial : undefined
  }

  return (
    <CheckBox 
      {...rest} 
      id={columnName || generateRandomId()}
      checked={determineCheckedState()} 
      name={name}
      partialChecked={determinePartialCheckedState()}
    />
  )
}
const NoDataComponentWhenSearchOrFilterApplied = () => {
  const {t} = useTranslation()
  return (
    <>
      <NoMatchesFound primary_dark={'var(--bs-primary-dark)'} primary_light={'var(--bs-primary-light)'} width={140} height={120} />
      <h4 className='mt-2'>{t('No Matches Found in the Data Universe')}</h4>
    </>
  )
}
const NoDataComponentWhenSearchOrFilterAppliedNotApplied = () => {
  const {t} = useTranslation()
  return (
    <>
      <NoRecordFound primary='var(--bs-primary)' primary_dark='var(--bs-primary-dark)' width={160} height={160}/>
      <h4 className='mt-2'>{t('No Record Is Present to Display')}</h4>
    </>
  )
}
const NoDataComponentWhenError = () => {
  const {t} = useTranslation()
  return (
    <>
      <ErrorState primary='var(--bs-primary)' primary_dark='var(--bs-primary-dark)' primary_light='var(--bs-primary-light)' width={160} height={120}/>
      <h4 className='mt-2'>{t('Oops, It Seems There Is An Issue In the Request')}.</h4>
      <p>{t('Please verify the provided information and retry')}.</p>
    </>
  )
}
const CustomNoDataComponent = ({error, success, isFilterOrSearchApplied}) => {
  return (
    <div className='d-flex flex-column align-items-center my-5'>
      { error && <NoDataComponentWhenError /> }
      { success && isFilterOrSearchApplied && <NoDataComponentWhenSearchOrFilterApplied /> }
      {success && !isFilterOrSearchApplied && <NoDataComponentWhenSearchOrFilterAppliedNotApplied />}

    </div>
  )
}

const ProgressComponent = () => {
  const {t} = useTranslation()

  return (
    <div className='d-flex flex-column align-items-center mx-4 mb-4 position-relative'>
      <ProgressLoader dimensions={170}/>
      <h4>{t('Hang Tight, We\'re Fetching Your Data')}</h4>
    </div>
  )
}
const SortingIcon = () => {
  return (
    <span className="flex-center-start">
      <img src={SortIcon} />
    </span>
  )
}

const CustomPaginate = props => {
  const { t } = useTranslation()
  const paginationDropdownRef = useRef(null)
  const { currentPage, onChangePage, paginationComponentOptions: { meta, handlePagination } } = props
  const { current_page, last_page, 
    // total,
    per_page
  } = meta
  const [tempItemsPerPage, setTempItemsPerPage] = useState(20)
  const [openPaginationDropdown, setOpenPaginationDropdown] = useState(false)
  const [isRtl] = useRTL()

  useOnClickOutside(paginationDropdownRef, () => {
    setOpenPaginationDropdown(false)
  })
  useEffect(() => {
    if (currentPage !== current_page) onChangePage(current_page)
    setTempItemsPerPage(per_page)
  }, [current_page])

  const handleOnPaginationDropdownClick = () => {
    setOpenPaginationDropdown(prev => !prev)
  }

  const handleOnPaginationDropdownItemClick = perPage => {
    handlePagination({ selected: 1, per_page: perPage })
    setTempItemsPerPage(perPage)
  }

  return (
    <div className="custom-paginate d-flex justify-content-between align-items-center">
      <div className="items-per-page d-flex align-items-center">
        <div className="no-of-items d-flex justify-content-between align-items-center" ref={paginationDropdownRef}>
          <Dropdown isOpen={openPaginationDropdown} toggle={() => {}}>
            <DropdownToggle
              className="btn-sm border p-0 bg-white me-1 pagination-menu d-flex"
              color="flat-dark"
              onClick={handleOnPaginationDropdownClick}
            >
              <div className="pagination-dropdown-button d-flex justify-content-between align-items-center">
                <div>{tempItemsPerPage}</div>
                {openPaginationDropdown ? <ChevronUp size={14} /> : <ChevronDown size={14} />}
              </div>
            </DropdownToggle>
            <DropdownMenu className="pagination-dropdown-menu">
              {PAGINATION_PER_PAGE_VALUES.map((perPage, idx) => (
                <div className="pagination-dropdown-item d-flex align-items-center cursor-pointer" key={idx}>
                  <input type="radio" onChange={() => handleOnPaginationDropdownItemClick(perPage)} checked={per_page === perPage} />
                  <div onClick={() => handleOnPaginationDropdownItemClick(perPage)}>{perPage}</div>
                </div>
              ))}
            </DropdownMenu>
          </Dropdown>
        </div>
        <div className="text">{t('Items per page')}</div>
      </div>
      <div className="move-prev-next d-flex align-items-center">
        <div className="pages pe-1">
          {currentPage} {t('of')} {last_page} {t('Pages')}
        </div>
        <div className="prev-next d-flex ms-1 me-1 align-items-center">
          <button
            disabled={current_page === 1}
            className={'ms-1 me-1 chevron-icons'}
            onClick={() => {
              handlePagination({ selected: currentPage - 1, per_page })
            }}
          >
            {isRtl ? <ChevronRight/> : <ChevronLeft />}
          </button>
          <div className="page-count d-flex justify-content-center align-items-center">
            <div color='var(--bs-primary)'>{current_page}</div>
          </div>
          <button
            disabled={current_page === last_page}
            className={'ms-1 me-1 chevron-icons '}
            onClick={() => {
              handlePagination({ selected: (currentPage || 0) + 1, per_page })
            }}
          >
            {isRtl ? <ChevronLeft/> : <ChevronRight/>}
          </button>
        </div>
      </div>
    </div>
  )
}
const CustomPagination = props => {
  return <CustomPaginate {...props} />
}

export const ExpandableCustomTable = ({
  loading = false,
  error = false,
  success = true,
  isFilterOrSearchApplied = false,
  data = [],
  columns,
  TableHeaderComponent = false,
  handlePagination = false,
  meta = {},
  selectable = false,
  handleSelectedRowsChange = false,
  customStyles = {},
  // useReactPaginate = true,
  showPagination = true,
  expandableRows = false,
  expandableRowsComponent,
  showColumnsTableHeader = true,
  showColumnsDropdown = true,
  searchcolumnsList,
  handleQueryParams,
  search_column_query,
  handleRefreshTable,
  handleSort,
  columnName,
  defaultSortFieldId,
  defaultSortAsc,
  tableTitle = null,
  CustomFooter = null,
  isActionColumnSticky = true,
  conditionalRowStyles = [],
  hideTableSearch = false,
  ...props
}) => {
  const hasTableHeaderVisible = Boolean(showColumnsTableHeader && (searchcolumnsList || handleRefreshTable || showColumnsDropdown || TableHeaderComponent))
  // const {t} = useTranslation()

  const transformedColumn = useMemo(() => {
    return columns.map((column) => {
      return {
        ...column,
        name: (column.name && typeof column.name === 'string') ? <div title={column.name} className='h-100 w-100 flex-center-start px-10px custom-table-header-cell'>
          <span className='text-truncate'>{column.name}</span>
        </div> : <div className='h-100 w-100 flex-center-start px-10px'>{column.name}</div>
      }
    })
  }, [columns])

  const tableHasActionColumn = isActionColumnSticky && columns.find((column) => {
    if (typeof column.name === 'string' && column.name && !column.omit) {
      return column.name.toLowerCase().includes('action')
    } else if (!column.name && !column.omit) {
      return true
    }
    return false
  })

  const {sortedColumn, setSortedColumn} = useLocalStorageTableColumns(columnName, transformedColumn)
  const externalTableStyles = useMemo(() => {
    return {
      headCells: {
        style: {
          color: 'var(--bs-dark)',
          fontSize: '14px',
          fontWeight: '500',
          lineHeight: '20px !important'
        }
      },
      cells: {
        style: {
          fontSize: '12px',
          fontWeight: 400,
          color: 'var(--bs-dark)',
          lineHeight: '18px'
        }
      },
      expanderButton: {
        style: {
          color: 'var(--bs-primary)',
          backgroundColor: 'transparent',
          '&:hover:enabled': {
            cursor: 'pointer',
            color: 'var(--bs-primary)',
            backgroundColor: 'var(--bs-primary-lighter) !important'
          },
          '&:disabled': {
            cursor: 'not-allowed',
            color: 'var(--bs-primary-light)'
          },
          '&:focus': {
            backgroundColor: 'transparent !important'
          }
        }
      },
      ...customStyles
    }
  }, [customStyles])

  const [columnsToShow, setColumnsToShow] = useState(() => (showColumnsDropdown ? sortedColumn : transformedColumn))

  useEffect(() => {
    setColumnsToShow(showColumnsDropdown ? sortedColumn : transformedColumn)
  }, [columns])

  const [isPartial, setIsPartial] = useState(false)
  const onSelectedRowsChange = (data) => {
    if (!data.allSelected && data.selectedCount > 0) {
      setIsPartial(true)
    } else {
      setIsPartial(false)
    }
    handleSelectedRowsChange(data)
  }

  const handleColumnSort = (selectedColumn, sortDirection) => {
    handleSort({field:selectedColumn.key, order:sortDirection})
  }
  
  const handleColumnOrderChange = useMemo(() => {
    return (reArrangedColumns) => {
      for (let i = 0; i < reArrangedColumns.length; i++) {
        if (!columnsToShow[i].reorder && reArrangedColumns[i].key !== columnsToShow[i].key) {
          setColumnsToShow([...columnsToShow])
          return
        }
      }
      setSortedColumn(columnName, reArrangedColumns)
    }
  }, [])

  const rowStyles = [
    {
      when: row => row.stripeIndex % 2 === 0,
      style: {
        backgroundColor: 'var(--bs-white)'
      }
    },
    {
      when: row => row.stripeIndex % 2 === 1,
      style: {
        backgroundColor: 'var(--bs-light-1)'
      }
    }
  ]

  const indexedData = Array.isArray(data) ? data.map((item, index) => ({...item, stripeIndex:index})) : []

  return (
    <Card id='table-container-wrapper' className={classNames({'table-has-action-column': tableHasActionColumn, 'hide-border-bottom': (!TableHeaderComponent && !showPagination)})}>
      <TableHeader
        loading={loading}
        columns={transformedColumn}
        columnsList={columnsToShow}
        setColumnsList={setColumnsToShow}
        TableHeaderComponent={TableHeaderComponent}
        defaultColumns={transformedColumn}
        showColumnsTableHeader={showColumnsTableHeader}
        showColumnsDropdown={showColumnsDropdown}
        meta={meta}
        searchcolumnsList={searchcolumnsList}
        search_column_query={search_column_query}
        handleQueryParams={handleQueryParams}
        handleRefreshTable={handleRefreshTable}
        columnName={columnName}
        setSortedColumn={setSortedColumn}
        tableTitle={tableTitle}
        hideTableSearch={hideTableSearch}
      />
      <div className={classNames('react-dataTable expandable-custom-table', {'top-radius' : !hasTableHeaderVisible, 'bottom-radius': !(showPagination && meta), 'rounded-16px': !hasTableHeaderVisible && !(showPagination && meta)})}>
        {selectable ? (
          <DataTable
            persistTableHead
            progressPending={loading}
            progressComponent={null} 
            noDataComponent={null}
            pagination={showPagination && meta}
            paginationServer={!!handlePagination}
            noHeader
            selectableRows={true}
            expandableRows={expandableRows}
            expandableRowsComponent={expandableRowsComponent}
            selectableRowsComponent={CustomCheckbox}
            selectableRowsComponentProps={{isPartial, columnName}}
            onSelectedRowsChange={onSelectedRowsChange}
            onColumnOrderChange={handleColumnOrderChange}
            data={indexedData}
            className={`react-dataTable common_table ${isPartial ? 'header-checkbox-partial' : ''}`}
            columns={columnsToShow}
            sortIcon={<SortingIcon />}
            onSort={handleColumnSort}
            sortServer={true}
            defaultSortFieldId={defaultSortFieldId}
            defaultSortAsc={defaultSortAsc}
            paginationComponent={handlePagination ? CustomPagination : (CustomFooter ? CustomFooter : null)}
            paginationComponentOptions={{meta, handlePagination}}
            customStyles={externalTableStyles}
            fixedFooter
            {...props}
            conditionalRowStyles={[...rowStyles, ...(conditionalRowStyles ? conditionalRowStyles : [])]}
          />
        ) : (
          <DataTable
            persistTableHead
            progressPending={loading}
            progressComponent={null} 
            noDataComponent={null}
            pagination={showPagination && meta}
            paginationServer={!!handlePagination}
            noHeader
            data={indexedData}
            onColumnOrderChange={handleColumnOrderChange}
            expandableRows={expandableRows}
            expandableRowsComponent={expandableRowsComponent}
            columns={columnsToShow}
            className="react-dataTable common_table"
            sortIcon={<SortingIcon />}
            onSort={handleColumnSort}
            sortServer={true}
            defaultSortFieldId={defaultSortFieldId}
            defaultSortAsc={defaultSortAsc}
            paginationComponent={handlePagination ? CustomPagination : (CustomFooter ? CustomFooter : null)}
            paginationComponentOptions={{meta, handlePagination}}
            customStyles={externalTableStyles}
            fixedFooter
            {...props}
            conditionalRowStyles={[...rowStyles, ...(conditionalRowStyles ? conditionalRowStyles : [])]}
          />
        )}
        {loading && <ProgressComponent/>}
        {!loading && indexedData.length === 0 && <CustomNoDataComponent success={success} error={error} isFilterOrSearchApplied={isFilterOrSearchApplied}/>}
      </div>
    </Card>
  )
}
