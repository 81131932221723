import SideSheet from '@src/@core/components/ui/sideSheet'
import { useRef } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { ModalBody, ModalHeader } from 'reactstrap'
import { ASSEMBLED, ASSEMBLED_KIT_DEFAULT_VALUE, ASSEMBLED_KIT_FIELDS_NAME, BUNDLE, BUNDLE_SKU_DEFAULT_VALUE, BUNDLE_SKU_FIELDS_NAME, SIMPLE, SIMPLE_SKU_DEFAULT_VALUE, SIMPLE_SKU_FIELDS_NAME } from '../../catalog.constants'
import { getSku } from '../../store'
import CreateAssembled from '../assembledSkus/CreateAssembled'
import CreateBundle from '../bundlesku/CreateBundle'
import SingleSkuCreate from '../single-sku-create/SingleSkuCreate'

const actionDefineHandler = (isEdit, skuType) => {
  if (isEdit) {
    switch (skuType) {
    case BUNDLE:
      return 'Edit Kit'
    case ASSEMBLED:
      return 'Edit Assembled Kit'
    default:
      return 'Edit SKU'
    }
  } else {
    switch (skuType) {
    case BUNDLE:
      return 'Create Kit'
    case ASSEMBLED:
      return 'Create Assembled Kit'
    default:
      return 'Create SKU'
    }
  }
}

const skuDefaultValueHandler = (skuType, initialSeller) => {
  switch (skuType) {
  case BUNDLE:
    return {
      ...BUNDLE_SKU_DEFAULT_VALUE,
      [BUNDLE_SKU_FIELDS_NAME.SELLER]: initialSeller
    }
  case ASSEMBLED:
    return {
      ...ASSEMBLED_KIT_DEFAULT_VALUE,
      [ASSEMBLED_KIT_FIELDS_NAME.SELLER]: initialSeller
    }
  default:
    return {
      ...SIMPLE_SKU_DEFAULT_VALUE,
      [SIMPLE_SKU_FIELDS_NAME.SELLER]: initialSeller
    }
  }
}

const CreateSku = ({
  isModalOpen,
  setIsModalOpen,
  selectedSeller: initialSeller,
  isEdit,
  setIsEdit,
  setDataTodEdit,
  dataToEdit,
  setSearchParams,
  skuType = '',
  setFilter,
  filter,
  productConfiguration
}) => {

  const resetRef = useRef(null)
  const {
    handleSubmit,
    register,
    watch,
    setValue,
    reset,
    control,
    clearErrors,
    getValues,
    formState: { errors }
  } = useForm({
    mode: 'onChange',
    defaultValues: skuDefaultValueHandler(skuType, initialSeller)
  })
  const dispatch = useDispatch()
  const { t } = useTranslation()

  // for pos
  const isPosEnabled = useSelector(store => store.auth.userData?.user_plan?.is_pos_enabled)

  const onModalClosed = () => {
    resetRef.current()
  }
  const onModalOpened = () => {
    setValue(BUNDLE_SKU_FIELDS_NAME.SELLER, initialSeller)
    if (isEdit) {
      dispatch(getSku({ params: {sku_id: dataToEdit.id, sellerId: initialSeller.value }, isPosEnabled }))
    }
  }

  const toggle = () => {
    setIsModalOpen((p) => !p)
  }

  const modalHeading = actionDefineHandler(isEdit, skuType)

  return (
    <div>
      <SideSheet
        modalClassName='modal-slide-in create-sku-modal'
        size='lg'
        isOpen={isModalOpen}
        toggle={toggle}
        onClosed={onModalClosed}
        onOpened={onModalOpened}
      >
        <ModalHeader toggle={toggle}>
          <h2>{t(modalHeading)}</h2>
        </ModalHeader>
        <ModalBody>
          {skuType === BUNDLE ? <CreateBundle
            resetRef={resetRef}
            selectedSeller={initialSeller}
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            isEdit={isEdit}
            setIsEdit={setIsEdit}
            dataToEdit={dataToEdit}
            setDataTodEdit={setDataTodEdit}
            setSearchParams={setSearchParams}
            handleSubmit={handleSubmit}
            register={register}
            watch={watch}
            reset={reset}
            control={control}
            errors={errors}
            setValue={setValue}
            skuType={skuType}
            setFilter={setFilter}
            filter={filter}
          /> : skuType === SIMPLE ?
            <SingleSkuCreate
              resetRef={resetRef}
              selectedSeller={initialSeller}
              isModalOpen={isModalOpen}
              setIsModalOpen={setIsModalOpen}
              isEdit={isEdit}
              setIsEdit={setIsEdit}
              dataToEdit={dataToEdit}
              setDataTodEdit={setDataTodEdit}
              setSearchParams={setSearchParams}
              handleSubmit={handleSubmit}
              register={register}
              watch={watch}
              reset={reset}
              control={control}
              errors={errors}
              setValue={setValue}
              skuType={skuType}
              clearErrors={clearErrors}
              setFilter={setFilter}
              filter={filter}
              getValues={getValues}
              productConfiguration={productConfiguration?.data}
            /> :
            <CreateAssembled
              resetRef={resetRef}
              selectedSeller={initialSeller}
              isModalOpen={isModalOpen}
              setIsModalOpen={setIsModalOpen}
              isEdit={isEdit}
              setIsEdit={setIsEdit}
              dataToEdit={dataToEdit}
              setDataTodEdit={setDataTodEdit}
              setSearchParams={setSearchParams}
              handleSubmit={handleSubmit}
              register={register}
              watch={watch}
              reset={reset}
              control={control}
              errors={errors}
              setValue={setValue}
              skuType={skuType}
              setFilter={setFilter}
              filter={filter}
              clearErrors={clearErrors}
            />}
        </ModalBody>
      </SideSheet>
    </div>
  )
}

export default CreateSku
