import CustomTag from '@src/@core/components/ui/badge/CustomTag'
import { MoreHorizontal } from 'react-feather'
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap'
import './styles.scss'
// import PerfectScrollbar from 'react-perfect-scrollbar' 

const OrderTagsMapper = ({ tagsArray, customIcon, containerBody, viewItems = 3, toggleProps={} }) => {
  const CustomIcon = customIcon
  return (
    <div className='order-tags-mapper d-flex gap-6px align-items-center flex-wrap'>
      {tagsArray?.length ?
        <>
          {Array.from({ length: Math.min(viewItems, tagsArray.length) }, (_, index) => {
            const tag = tagsArray[index]
            return (
              <CustomTag key={tag.name} style={{ color: tag.color || 'var(--bs-dark)', backgroundColor: tag.color ? `${tag.color}1A` : 'var(--bs-dark-2)' }} title={tag.name} />
            )
          })}
          {tagsArray.length > viewItems ?
            <UncontrolledDropdown className="order-tags-dropdown">
              <DropdownToggle
                tag="span"
                className="cursor-pointer"
                {...toggleProps}
              >
                {CustomIcon ? <CustomIcon/> : <span className="rounded-12px px-12px py-4px flex-center-center text-dark bg-light-viewItems">
                  <MoreHorizontal size={16} />
                </span>}
              </DropdownToggle>
              <DropdownMenu className='bg-white' container={containerBody ? 'body' : ''}>
                {/* <PerfectScrollbar> */}
                {tagsArray.slice(viewItems, tagsArray.length).map((tag,idx) => {
                  return (
                    <DropdownItem key={idx}  text className="d-flex justify-content-left ps-8px w-100">
                      <CustomTag style={{ color: tag.color || 'var(--bs-dark)', backgroundColor: tag.color ? `${tag.color}1A` : 'var(--bs-dark-2)'  }} title={tag.name} />
                    </DropdownItem>)
                })}
                {/* </PerfectScrollbar> */}
              </DropdownMenu>
            </UncontrolledDropdown>
            : null
          }
        </>
        : '-'
      }
    </div>
  )
}

export default OrderTagsMapper