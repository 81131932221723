import Button from '@src/@core/components/ui/button'
import FloatingDropDown from '@src/@core/components/ui/floating-dropdown'
import QuantityButton from '@src/@core/components/ui/quantity-button'
import Toggle from '@src/@core/components/ui/toggle-button'
import { PRINT_PAGE_STYLE } from '@src/App.constants'
import { generateBarcode } from '@src/views/inventory/store'
import { useEffect, useRef, useState } from 'react'
import Barcode from 'react-barcode'
import { X } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useReactToPrint } from 'react-to-print'
import { Modal, ModalBody, Spinner } from 'reactstrap'
import { BATCH_STATUS, SERIALISATION_OPTION } from '../../constant'
import './printBulkSkuBarcode.scss'

const PrintBulkSkuBarcode = ({ qty, setQty, skuDetails, batchDetails, handlefocusOmnifulSKUBarcode, handlefocusQCBinBarcode}) => {
  const [isPrintMultiBarcodeModalOpen, setIsPrintMultiBarcodeModalOpen] =
        useState(false)
  const {t} = useTranslation()
  const [isBatchDetailsRemove, setIsBatchDetailRemove] = useState(false)
  const multiSkuBarcodeValue = useSelector(store => store.inventory.grn.generatedBarcode)
  const loadingState = useSelector(store => store.inventory.loadingState)
  const selectedGlobalHubID = useSelector(
    (store) => store.auth.selectedGlobalHubId
  )
  const [selectedBarcode, setSelectedBarcode] = useState({ label: skuDetails?.barcodes?.[0], value: skuDetails?.barcodes?.[0] })
  const [barcodesToPrint, setBarcodesToPrint] = useState([])

  const multiSkuBarcodeRef = useRef()

  const dispatch = useDispatch()

  const handlePrintMultiBarcode = useReactToPrint({
    content: () => multiSkuBarcodeRef.current,
    pageStyle: `@page {size: 378px ${skuDetails?.batch_status === BATCH_STATUS.BATCHED ? '270px' : '220px'
    }; margin: auto 0}`,
    onAfterPrint: () => {
      if (skuDetails?.serialisation_status === SERIALISATION_OPTION.serialised.id) {
        handlefocusOmnifulSKUBarcode()
      } else {
        handlefocusQCBinBarcode()
      }
    }
  })

  const handlePrintMultiSkuBarcode = async () => {
    if (skuDetails?.serialisation_status === SERIALISATION_OPTION.serialised.id) {
      const body = {
        seller_id: skuDetails.seller_id,
        seller_sku_id: skuDetails.seller_sku_id,
        quantity: qty,
        hub_id: selectedGlobalHubID
      }
      if (skuDetails?.batch_status === BATCH_STATUS.BATCHED) {
        body.batch_id = batchDetails?.id
      }
      dispatch(generateBarcode(body))

    } else {
      const barcodes = new Array(qty).fill(selectedBarcode.value)
      setBarcodesToPrint(barcodes)
    }
  }
  const togglePrintBarcodeModal = () => {
    setIsPrintMultiBarcodeModalOpen((pre) => !pre)
  }
  const handleShowBatchDetails = () => {
    setIsBatchDetailRemove((pre) => !pre)
  }
  const decreaseQuantity = () => {
    if (qty >= 1) {
      setQty(qty - 1)
    }
  }
  const increaseQuantity = () => {
    setQty(qty + 1)
  }

  const onChange = (e) => {
    const number = e.target.value.trim()
    const value = parseInt(number)
    if (e.nativeEvent.data === '+') {
      if (value >= 100) {
        setQty(100)
      } else {
        setQty(qty + 1)
      }
      return
    } else if (e.nativeEvent.data === '-') {
      if (qty >= 1) {
        setQty(qty - 1)
      }
      return
    }
    if (/^(0|[1-9]\d*)$/.test(value) || e.target.value === '') {
      if (value > 100) {
        setQty(100)
      } else {
        setQty(value || 0)
      }
    }
  }

  const handleOnClosed = () => {
    if (skuDetails?.serialisation_status === SERIALISATION_OPTION.non_serialised.id) {
      setQty(1)
    }
    setSelectedBarcode({ label: skuDetails?.barcodes?.[0], value: skuDetails?.barcodes?.[0] })
    setBarcodesToPrint([])
    setIsBatchDetailRemove(false)
  }

  useEffect(() => {
    if (multiSkuBarcodeValue) {
      setBarcodesToPrint(multiSkuBarcodeValue?.barcodes)
    }
  }, [multiSkuBarcodeValue])

  useEffect(() => {
    if (barcodesToPrint?.length) {
      handlePrintMultiBarcode()
      setIsPrintMultiBarcodeModalOpen(false)
    }
  }, [barcodesToPrint])

  return (
    <div className="d-flex flex-grow-1">
      <Button
        onClick={togglePrintBarcodeModal}
        className="flex-grow-1 justify-content-center"
        ofStyle="outlined"
      >
        {t('Print Barcodes')}
      </Button>
      <Modal
        isOpen={isPrintMultiBarcodeModalOpen}
        centered
        toggle={togglePrintBarcodeModal}
        contentClassName="rounded-12px bg-white print-bulk-modal"
        onClosed={handleOnClosed}
      >
        <ModalBody className="d-flex flex-column py-16px px-20px ">
          <div className="d-flex justify-content-between align-items-center">
            <div className="txt-body-md text-dark">{t('Print Barcodes')}</div>
            <X
              onClick={togglePrintBarcodeModal}
              size={16}
              className="text-dark cursor-pointer"
            />
          </div>
          <div className="d-flex flex-column gap-24px mt-24px">
            {skuDetails?.serialisation_status === SERIALISATION_OPTION.non_serialised.id &&
                            <FloatingDropDown
                              title='Barcode to be printed'
                              options={skuDetails?.barcodes?.map(barcode => ({ label: barcode, value: barcode }))}
                              value={selectedBarcode}
                              onChangeFunc={(barcode) => setSelectedBarcode(barcode)}
                            />
            }
            <div className="text-dark-6 txt-sub-rg">
              {t('Print up to 100 barcodes by entering the quantity')}
            </div>
            <QuantityButton
              minQuantityEnabled={qty <= 0}
              maxQuantityEnabled={qty >= 100}
              decreaseQuantity={decreaseQuantity}
              increaseQuantity={increaseQuantity}
              quantity={qty}
              onChange={onChange}
            />
            {skuDetails?.batch_status === BATCH_STATUS.BATCHED && (
              <div className="d-flex justify-content-between">
                <div className="text-dark">{t('Remove Batch Details')}</div>
                <Toggle
                  ofStyle="success"
                  ofSize="compressed"
                  onClick={handleShowBatchDetails}
                  checked={isBatchDetailsRemove}
                  activeText="Yes"
                  inactiveText="No"
                  inactiveColor='var(--bs-dark-2)'
                />
              </div>
            )}
            <div className="d-flex">
              <Button
                onClick={handlePrintMultiSkuBarcode}
                className="flex-grow-1 justify-content-center"
                disabled={loadingState.generateBarcode || qty === 0}
              >
                {loadingState.generateBarcode && <Spinner size="sm" />}
                <span className="align-middle ms-25">{t('Print Barcode')}</span>
              </Button>
              <div className='d-none'>
                <div className='d-flex flex-column align-items-center justify-content-center' ref={multiSkuBarcodeRef}>
                  <style>{ PRINT_PAGE_STYLE }</style>
                  {barcodesToPrint && barcodesToPrint.map(skuBarcode => <>
                    <Barcode value={skuBarcode} />
                    {(skuDetails?.batch_status === BATCH_STATUS.BATCHED && !isBatchDetailsRemove) && <>
                      <div className='text-dark'>{t('Batch ID')}: {batchDetails?.value?.batch_num}</div>
                      <div className='text-dark'>{t('Expiry Date')}: {batchDetails?.value?.expiry_date}</div>
                    </>}</>)}
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  )
}

export default PrintBulkSkuBarcode
