import SidesheetHeader from '@src/@core/components/ui/sidesheet-header'
import { Modal, ModalBody } from 'reactstrap'
import './preview.scss'

const Preview = (props) => {
  const { isOpen, toggle, previewingAttachment } = props
  const file_url = previewingAttachment?.file_url
  const name = previewingAttachment?.name
  const fileType = previewingAttachment?.type
  const isImage = fileType?.startsWith('image')
  return (
    <Modal
      className={'preview-attachment-modal'}
      contentClassName="rounded-12px overflow-hidden"
      centered
      toggle={toggle}
      isOpen={isOpen}
    >
      {isImage && <SidesheetHeader title="Preview" hasClearButton clearButtonHandler={toggle}/>}
      <ModalBody className="p-0 w-100 h-100">
        {
          isImage ? <div className="image-container"><img
            src={file_url}
            alt={name}
            className="border-0 w-100 h-100 object-fit-contain"
          /></div> : <iframe
            src={`${file_url}#navpanes=0`}
            title={name}
            className="border-0 w-100 d-flex flex-fill"
            style={{height: '80vh'}}
          />
        }
      </ModalBody>
    </Modal>
  )
}

export default Preview