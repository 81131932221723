import { useState } from 'react'
import { ChevronDown } from 'react-feather'
import ItemToggleBar from './ItemToggleBar'
import SkuDetailsAndQc from './SkuDetailsAndQc'

const ClosedCasepack = ({ sku_details, selectedTabId, qcBinBarcodeRef, searchBarcode }) => {
  const [isExpanded, setIsExpanded] = useState(false)

  const toggleExpanded = () => setIsExpanded(!isExpanded)

  if (!isExpanded) {
    return <ItemToggleBar sku_details={sku_details} isExpanded={isExpanded} toggleExpanded={toggleExpanded} itemOpened={false} mode='closed' />
  } else {
    return <div className='bg-light-1 pt-8px pb-24px px-12px rounded-12px mb-1 border border-1px border-dark-2'>
      <div className='flex-center-between mb-12px'>
        <div className='text-primary cursor-pointer flex-center-start gap-4px' onClick={toggleExpanded}>
          <ChevronDown size={20} />
          <div className='txt-asst-md'>Collapse</div>
        </div>
        <div className='txt-asst-md text-dark-5'>
          {`Closed (${sku_details.closedQty})`}
        </div>
      </div>
      <SkuDetailsAndQc sku_details={sku_details} selectedTabId={selectedTabId} qcBinBarcodeRef={qcBinBarcodeRef} searchBarcode={searchBarcode} />
    </div>
  }

}

export default ClosedCasepack