import FloatingDropDown from '@src/@core/components/ui/floating-dropdown'
import { GET_CURRENCIES } from '@src/redux/authentication'
import { ASSEMBLED_KIT_FIELDS_NAME, CURRENCY } from '../../catalog.constants'
export function AssembledCurrencies({
  t,
  totalRetailPrice,
  bundleCreationLoading,
  imageGettingUpload,
  retailPriceRef,
  skuRetailPrice,
  skuRetailPriceHandler,
  watch,
  control,
  isEdit,
  singleSkuLoading,
  totalSellingPrice,
  sellingPriceRef,
  skuSellingPrice,
  skuSellingPriceHandler,
  updateBundleSkusRes
}) {
  return (
    <div className="d-flex justify-content-end assembled-pricing w-100">
      <div className="d-flex gap-2 flex-column w-100">
        <div className="d-flex gap-5 align-items-center justify-content-end">
          <div className="text-end txt-h3-md d-flex flex-column justify-content-center align-items-end sku-retail-price-text text-dark-6">
            {t('Assembled kit Retail Price')}
          </div>
          <div className="txt-h3-sb total-retail-price d-flex gap-8px align-items-center justify-content-end">
            {totalRetailPrice >= 0 ? (
              <input
                disabled={
                  bundleCreationLoading ||
                  updateBundleSkusRes.loading ||
                  imageGettingUpload
                }
                type="number"
                ref={retailPriceRef}
                className="text-end price-input bg-transparent text-dark txt-h3-sb "
                value={
                  skuRetailPrice === 0 || skuRetailPrice > 0
                    ? Number(skuRetailPrice)
                    : Number(totalRetailPrice).toFixed(totalRetailPrice ? 2 : 0)
                }
                onChange={(e) => skuRetailPriceHandler(e)}
                onWheel={(e) => e.target.blur()}
              />
            ) : (
              '-'
            )}
            <div>
              {
                <FloatingDropDown
                  isAsync
                  width="100px"
                  name={ASSEMBLED_KIT_FIELDS_NAME.CURRENCY}
                  value={watch(ASSEMBLED_KIT_FIELDS_NAME.CURRENCY)}
                  control={control}
                  loadOptions={GET_CURRENCIES}
                  disabled={
                    (isEdit && singleSkuLoading) ||
                    bundleCreationLoading ||
                    updateBundleSkusRes.loading ||
                    imageGettingUpload
                  }
                  defaultValue={CURRENCY.SAR}
                  additional={{
                    page: 1
                  }}
                />
              }
            </div>
          </div>
        </div>
        <div className="d-flex gap-5 align-items-center justify-content-end">
          <div className="text-end txt-h1-sb d-flex flex-column justify-content-center align-items-end sku-retail-price-text text-dark">
            {t('Assembled kit Selling Price')}
          </div>
          <div className="txt-h3-sb total-selling-price d-flex gap-8px align-items-center justify-content-end">
            {totalSellingPrice >= 0 ? (
              <input
                disabled={
                  bundleCreationLoading ||
                  updateBundleSkusRes.loading ||
                  imageGettingUpload
                }
                type="number"
                ref={sellingPriceRef}
                className="text-end price-input bg-transparent text-dark txt-h3-sb "
                value={
                  skuSellingPrice === 0 || skuSellingPrice > 0
                    ? Number(skuSellingPrice)
                    : Number(totalSellingPrice).toFixed(
                      totalSellingPrice ? 2 : 0
                    )
                }
                onChange={(e) => skuSellingPriceHandler(e)}
                onWheel={(e) => e.target.blur()}
              />
            ) : (
              '-'
            )}
            <div>
              {
                <FloatingDropDown
                  isAsync
                  width="100px"
                  name={ASSEMBLED_KIT_FIELDS_NAME.CURRENCY}
                  value={watch(ASSEMBLED_KIT_FIELDS_NAME.CURRENCY)}
                  control={control}
                  loadOptions={GET_CURRENCIES}
                  disabled={
                    (isEdit && singleSkuLoading) ||
                    bundleCreationLoading ||
                    updateBundleSkusRes.loading ||
                    imageGettingUpload
                  }
                  defaultValue={CURRENCY.SAR}
                  additional={{
                    page: 1
                  }}
                />
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
