// ** React Imports
import { NavLink } from 'react-router-dom'

// ** Third Party Components
import classnames from 'classnames'
import { useTranslation } from 'react-i18next'

// ** Reactstrap Imports
import { navLinkComparisonResolver } from '@src/@core/layouts/utils'
import { useState } from 'react'
import { Badge } from 'reactstrap'

const VerticalNavMenuLink = ({ item, secondaryMenuActiveItem }) => {
  // ** Conditional Link Tag, if item has newTab or externalLink props use <a> tag else use NavLink
  const [isHovered,setIsHovered] = useState(false);
  const LinkTag = item.externalLink ? 'a' : NavLink

  // ** Hooks
  const { t } = useTranslation()

  const active = item.navLink === secondaryMenuActiveItem?.navLink || navLinkComparisonResolver(item.navLink, secondaryMenuActiveItem?.navLink)

  return (
    <li
      title={t(item.title)}
      className={classnames('margins secondary-menu-nav-link ', {
        'nav-item': !item.children,
        // 'ps-3': item.isChild,
        'main-heading': !item.isChild,
        // margins: item.isChild,
        // margins: item.isChild,
        disabled: item.disabled,
        active
      })}
      onMouseOver={() => setIsHovered(true)}
      onMouseOut={() => setIsHovered(false)}
      style={{ background: secondaryMenuActiveItem?.id === item.id ? 'rgba(84, 104, 250, 0.06)' : 'white' }}
    >
      <LinkTag
        // className="d-flex align-items-center"
        target={item.newTab ? '_blank' : undefined}
        /*eslint-disable */
        {...(item.externalLink === true
          ? {
            href: item.navLink || '/'
          }
          : {
            to: item.navLink || '/',
            className: ({ isActive }) => {
              if (isActive && !item.disabled) {
                return 'd-flex align-items-center active'
              }
            }
          })}
        onClick={e => {
          // setSecondaryMenuActiveItem(item)
          if (item.navLink.length === 0 || item.navLink === '#' || item.disabled === true) {
            e.preventDefault()
          }
        }}
      >
        {item.icon ? <item.icon fillColor={isHovered?'#222' : '#666'} iswhite={active} height={14} width={15} /> : null}
        <span className={`menu-item text-truncate ${secondaryMenuActiveItem?.id === item.id ? 'no-link' : 'inner-links'}`}>{t(item.title)}</span>
        {item.badge && item.badgeText ? (
          <Badge className="custom-badge" color={item.badge}>
            {item.badgeText}
          </Badge>
        ) : null}
      </LinkTag>
    </li>
  )
}

export default VerticalNavMenuLink
