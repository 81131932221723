import { format, parse } from 'date-fns'
import { useState } from 'react'
import { Calendar } from 'react-date-range'
import { useTranslation } from 'react-i18next'
import './styles.scss'

const DatePicker = ({ selectedFilter, handleSetSelectedOption, selectedOptions }) => {
  const { filterKey } = selectedFilter
  const { t } = useTranslation()
  const [date, setDate] = useState(() => (selectedOptions?.[filterKey] ? parse(selectedOptions[filterKey].value, 'yyyy-MM-dd', new Date()) : new Date()))

  const handleApplyDateFilter = (selectedDate) => {
    setDate(selectedDate)
    const formattedDate = format(selectedDate, 'yyyy-MM-dd')
    handleSetSelectedOption({ filterKey, option: { label: formattedDate, value: formattedDate } })
  }

  return (
    <div className='date-picker-wrapper m-auto'>
      <div className='py-4px px-10px rounded-4px mt-12px mx-10px flex-center-center current-date'>
        <div className='current-date-label txt-asst-rg'>
          {t('Selected Date is ')} {format(date, 'yyyy-MM-dd')}
        </div>
      </div>
      <div className='calendar-wrapper'>
        <Calendar
          date={date}
          onChange={(selectedDate) => handleApplyDateFilter(selectedDate)}
          color="var(--bs-primary)"
          className='calendar d-inline-block mx-12px mb-10px'
        />
      </div>
    </div >
  )
}

export default DatePicker