
const SearchIcon = ({ isOpen, inputIsFocused, searchVal, isHoveredInput, isHoveredSelectTag }) => {
  let color
  if (isOpen) {
    color = '#495AD9'
  } else if (inputIsFocused || searchVal || isHoveredInput) {
    color = 'black'
  } else if (isHoveredSelectTag) {
    color = '#5468FA'
  }  else {
    color = '#666666'
  }
  return (
    <svg width="18" height="19" viewBox="0 0 18 19" fill={color} xmlns="http://www.w3.org/2000/svg">
      <path d="M16.35 18.425L10.325 12.4C9.825 12.8333 9.24201 13.1708 8.57603 13.4125C7.91004 13.6541 7.20137 13.775 6.45 13.775C4.6473 13.775 3.12163 13.15 1.87298 11.9C0.624325 10.65 0 9.14164 0 7.37498C0 5.60831 0.625 4.09998 1.875 2.84998C3.125 1.59998 4.6375 0.974976 6.4125 0.974976C8.1875 0.974976 9.69583 1.59998 10.9375 2.84998C12.1792 4.09998 12.8 5.60956 12.8 7.37873C12.8 8.09289 12.6833 8.78331 12.45 9.44998C12.2167 10.1166 11.8667 10.7416 11.4 11.325L17.475 17.35C17.625 17.4907 17.7 17.6634 17.7 17.868C17.7 18.0727 17.6174 18.2576 17.4522 18.4228C17.3007 18.5743 17.1148 18.65 16.8945 18.65C16.6741 18.65 16.4926 18.575 16.35 18.425ZM6.425 12.275C7.77917 12.275 8.93021 11.7958 9.87813 10.8375C10.826 9.87914 11.3 8.72498 11.3 7.37498C11.3 6.02498 10.826 4.87081 9.87813 3.91248C8.93021 2.95414 7.77917 2.47498 6.425 2.47498C5.05695 2.47498 3.8941 2.95414 2.93645 3.91248C1.97882 4.87081 1.5 6.02498 1.5 7.37498C1.5 8.72498 1.97882 9.87914 2.93645 10.8375C3.8941 11.7958 5.05695 12.275 6.425 12.275Z" />
    </svg>
  )
}

export default SearchIcon
