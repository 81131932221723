import { forwardRef } from 'react'
import Select from 'react-select'
import { Input } from 'reactstrap'
import { editSkuWeightStyles, editSkuWeightTheme } from './overRideSkuWeight'

const EditSkuWeight = forwardRef(({
  defaultValue,
  weightUnitsOptions = [{value:'kg', label:'kg'}, {value:'g', label:'g'}],
  defaultValueSelected = [{value:'kg', label:'kg'}],
  handleWeight,
  handleUnit,
  handleUpdateWeight
}, ref) => {
  return (
    <div ref={ref} className='border border-dark-2 rounded-4px flex-center-center bg-white max-width-120px'>
      <Input
        className='max-width-60px border-0 txt-asst-md box-shadow-0'
        defaultValue={defaultValue}
        onChange={handleWeight}
        onKeyDown={handleUpdateWeight}
      />
      <div className='width-1px border border-dark-2 height-20px'></div>
      <Select
        options={weightUnitsOptions}
        defaultValue={defaultValueSelected}
        className='width-60px border-0 txt-asst-md'
        onChange={handleUnit}
        onKeyDown={handleUpdateWeight}
        isSearchable={false}
        styles={editSkuWeightStyles}
        theme={editSkuWeightTheme}
      />
    </div>
  )
}
)
export default EditSkuWeight