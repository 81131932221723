const Invoices = ({ height, fillColor, width, iswhite }) => {
  const color = iswhite ? 'white' : fillColor
  return (
    <svg width={width} height={height} viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M4.05269 8.22847H15.8077V7.17578H3.96497C3.43203 7.17578 3 7.60781 3 8.14075V25.7416C3 26.5131 3.86041 26.9726 4.50158 26.5436L7.91256 24.2614L11.7622 26.8371C12.0869 27.0544 12.5106 27.0544 12.8354 26.8371L16.685 24.2614L20.096 26.5436C20.7371 26.9726 21.5975 26.5131 21.5975 25.7416V12.9656H20.5448V25.5774L17.2216 23.3538C16.8968 23.1365 16.4731 23.1365 16.1484 23.3538L12.2988 25.9295L8.44917 23.3538C8.12442 23.1365 7.70069 23.1365 7.37594 23.3538L4.05269 25.5774V8.22847Z" fill={color}/>
      <path fillRule="evenodd" clipRule="evenodd" d="M21.0712 12.3515C23.639 12.3515 25.7206 10.2699 25.7206 7.70207C25.7206 5.13429 23.639 3.05269 21.0712 3.05269C18.5034 3.05269 16.4218 5.13429 16.4218 7.70207C16.4218 10.2699 18.5034 12.3515 21.0712 12.3515ZM21.0712 13.4041C24.2204 13.4041 26.7733 10.8512 26.7733 7.70207C26.7733 4.55291 24.2204 2 21.0712 2C17.922 2 15.3691 4.55291 15.3691 7.70207C15.3691 10.8512 17.922 13.4041 21.0712 13.4041Z" fill={color}/>
      <path d="M22.8257 8.76323C22.8257 8.99824 22.7653 9.21914 22.6444 9.42595C22.5284 9.63275 22.3544 9.80666 22.1224 9.94766C21.8953 10.0887 21.6246 10.1733 21.3104 10.2015V10.7725H20.8392V10.2015C20.3849 10.1592 20.0175 10.0205 19.7372 9.78551C19.4569 9.5505 19.3167 9.24029 19.3167 8.85489H20.2012C20.2157 9.03819 20.2761 9.19094 20.3824 9.31315C20.4888 9.43065 20.641 9.50585 20.8392 9.53875V7.98772C20.5154 7.90781 20.2519 7.82791 20.0489 7.74801C19.8459 7.66341 19.672 7.5318 19.527 7.3532C19.3868 7.1746 19.3167 6.93019 19.3167 6.61998C19.3167 6.22987 19.4569 5.90792 19.7372 5.65411C20.0175 5.3956 20.3849 5.2452 20.8392 5.2029V4.63184H21.3104V5.2029C21.7358 5.2405 22.0765 5.3721 22.3327 5.59771C22.5937 5.81861 22.7411 6.12412 22.7749 6.51423H21.8904C21.8759 6.36853 21.8179 6.23927 21.7164 6.12647C21.6149 6.00897 21.4796 5.92907 21.3104 5.88677V7.4096C21.6343 7.4848 21.8977 7.56471 22.1007 7.64931C22.3037 7.72921 22.4753 7.85846 22.6154 8.03707C22.7556 8.21097 22.8257 8.45303 22.8257 8.76323ZM20.1577 6.57768C20.1577 6.77039 20.2157 6.92079 20.3317 7.02889C20.4525 7.137 20.6217 7.22395 20.8392 7.28975V5.86562C20.6314 5.88912 20.4646 5.96197 20.3389 6.08417C20.2181 6.20637 20.1577 6.37088 20.1577 6.57768ZM21.3104 9.53875C21.5231 9.50585 21.6898 9.4236 21.8107 9.292C21.9315 9.15569 21.9919 8.99354 21.9919 8.80553C21.9919 8.61753 21.9315 8.47183 21.8107 8.36842C21.6947 8.26032 21.5279 8.17337 21.3104 8.10757V9.53875Z" fill={color}/>
      <path fillRule="evenodd" clipRule="evenodd" d="M6.50897 12.0884C6.50897 11.7977 6.74462 11.562 7.03532 11.562H12.7374C13.0281 11.562 13.2637 11.7977 13.2637 12.0884C13.2637 12.379 13.0281 12.6147 12.7374 12.6147H7.03532C6.74462 12.6147 6.50897 12.379 6.50897 12.0884ZM6.50897 16.0359C6.50897 15.7453 6.74462 15.5096 7.03532 15.5096H17.3429C17.6336 15.5096 17.8693 15.7453 17.8693 16.0359C17.8693 16.3266 17.6336 16.5623 17.3429 16.5623H7.03532C6.74462 16.5623 6.50897 16.3266 6.50897 16.0359ZM6.50897 19.9835C6.50897 19.6928 6.74462 19.4572 7.03532 19.4572H17.3429C17.6336 19.4572 17.8693 19.6928 17.8693 19.9835C17.8693 20.2742 17.6336 20.5099 17.3429 20.5099H7.03532C6.74462 20.5099 6.50897 20.2742 6.50897 19.9835Z" fill={color}/>
    </svg>
  )
}
export default Invoices