// ** Reactstrap Imports
import Button from '@src/@core/components/ui/button'
import { WarningLogo } from '@src/assets/images/omniful/warning'
import { Info } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { Modal, ModalBody } from 'reactstrap'

const CityMappingConfirmationPopUp = (prop) => {
  const { isOpen, setIsOpen, addCityMapping, watch, isCityEditing, setIsCityEditing, setValue } = prop
  const { t } = useTranslation()

  const handleProceed = () => {
    addCityMapping({
      omniful_city_id: `${watch('city')?.value}`,
      city_mapping_id: `${watch('mapped_city')?.value}`,
      mapping_direction: 'system_to_courier',
      omniful_city_name: `${watch('city')?.label}`,
      omniful_country_name: `${watch('country')?.label}`
    })
    setIsOpen(false)
  }
  const handleTakeMeBack = () => {
    if (isCityEditing) {
      setIsCityEditing(false)
    }
    setValue('mapped_city', null)
    setIsOpen((prev) => !prev)
  }

  return (
    <>
      <Modal isOpen={isOpen} centered className="city-mapping-confirmation-pop-up width-400">
        <ModalBody className="m-0 p-0">
          <div className="content-body p-16px">

            <div className="d-flex justify-content-center content-logo">
              <WarningLogo />
            </div>

            <div className="content-text d-flex flex-column align-items-start mt-24px">
              <div className="txt-body-sb text-dark">{t('Confirm Delivery City Mapping')}</div>
              <div className="d-flex align-items-center my-12px p-12px rounded-8px gap-10px bg-primary-lighter text-dark txt-body-rg w-100">
                <Info fill='var(--bs-primary)' color="white" size={18} />
                <span className="txt-sub-rg">{t('New mapped city will be used for further orders')}.</span>
              </div>
              <div className="d-flex flex-column gap-2px txt-sub-rg">
                <p className="m-0">Are you sure you want to map <span className="txt-sub-md text-dark">{watch('city')?.label}</span> to <span className="txt-sub-md text-dark">{watch('mapped_city')?.label}</span> ?</p>
              </div>
            </div>

            <div className="d-flex gap-12px mt-16px">
              <Button
                className="d-flex w-100 justify-content-center"
                onClick={handleTakeMeBack}
                ofStyle="outlined"
              >
                {t('Take Me Back')}
              </Button>
              <Button onClick={handleProceed} className="d-flex w-100 justify-content-center">
                {t('Proceed')}
              </Button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  )
}

export default CityMappingConfirmationPopUp
