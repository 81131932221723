import Button from '@src/@core/components/ui/button'
import CustomToast from '@src/@core/components/ui/custom-toast/CustomToast'
import { ExpandableCustomTable } from '@src/@core/components/ui/globalTable/ExpandableCustomTable'
import { ALL_COLUMN_NAME_MAPPING } from '@src/@core/components/ui/globalTable/globalTable.constant'
import InputField from '@src/@core/components/ui/input-field'
import SideSheet from '@src/@core/components/ui/sideSheet'
import classNames from 'classnames'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

const PackageDetailsSideSheet = ({ isSideSheetOpen, handleCloseSideSheet, packageWeight, packageData, setPackageData, setIsCustom }) => {
  const { t } = useTranslation()

  const [currentPackageData, setCurrentPackageData] = useState([])
  const [localeIsCustom, setLocaleIsCustom] = useState({length:false, breadth:false, height:false})

  const handleChangePackageData = (e, name, id) => {
    const currentIndex = currentPackageData.findIndex((item) => item.id === id)
    const updatedPackageData = [...currentPackageData]
    if (name === 'weight') {

      const sumOfWeight = updatedPackageData.reduce((sum, item, index) => {
        return index === currentIndex ? sum : sum + +item.weight
      }, 0)

      if (+e.target.value > +packageWeight - sumOfWeight) {
        const newPackageData = currentPackageData.map((item) => {
          const itemErrors = {...item.errors}
          const newItem = {...item}
          if (item.id === id) {
            itemErrors.weight = { message: 'Weight  Exceeded' }
            newItem.weight = e.target.value
          }
          return { ...newItem,  errors: itemErrors }
        })
        setCurrentPackageData(newPackageData)
        return
        
      }
      updatedPackageData[currentIndex] = { ...updatedPackageData[currentIndex], [name]: e.target.value }
      setCurrentPackageData(updatedPackageData)
    } else {
      setLocaleIsCustom(pre => ({...pre, [name]:true}))
      updatedPackageData[currentIndex] = { ...updatedPackageData[currentIndex], [name]: e.target.value }
      setCurrentPackageData(updatedPackageData)
    }
    // Clear the error for the current field if a valid value is entered
    if (updatedPackageData[currentIndex].errors) {
      delete updatedPackageData[currentIndex].errors[name]
      if (Object.keys(updatedPackageData[currentIndex].errors).length === 0) {
        delete updatedPackageData[currentIndex].errors
      }
    }
    

    setCurrentPackageData(updatedPackageData)
  }
  console.log({ currentPackageData })
  
  const handleSavePackageDetails = () => {
   
    setLocaleIsCustom({length:false, breadth:false, height:false})
    const newPackageData = currentPackageData.map((item) => {
      const itemErrors = {...item.errors}
      if (!item.length) itemErrors.length = { message: 'This field is required' }
      if (!item.breadth) itemErrors.breadth = { message: 'This field is required' }
      if (!item.height) itemErrors.height = { message: 'This field is required' }
      if (!item.weight) itemErrors.weight = { message: 'This field is required' }
      return { ...item, errors: itemErrors }
    })

    setCurrentPackageData(newPackageData)

    const hasErrors = newPackageData.some((item) => Object.keys(item.errors).length > 0)

    if (!hasErrors) {
      const currentTotalWeight = currentPackageData.reduce((sum, item) => {
        return  sum + parseFloat(item.weight)
      }, 0) 
      if (parseFloat(currentTotalWeight.toFixed(2)) !== parseFloat(packageWeight)) {
        CustomToast('The sum of all individual package weights must equal the total order weight.', { my_type: 'error', audioRequired: false })
        return
      }
      const formattedData = newPackageData.map((item) => ({
        dimensions: {
          length: +item.length,
          breadth: +item.breadth,
          height: +item.height
        },
        weight: {
          value: parseFloat(item.weight),
          uom: 'kg'
        }
      }))
      setPackageData(formattedData)
      setCurrentPackageData([])
      setIsCustom(localeIsCustom)
      handleCloseSideSheet()
    }
  }
  const handleCancel = () => {
    setCurrentPackageData([])
    handleCloseSideSheet()
  }
  const handleSetDefaultState = () => {
    const formattedData = packageData.map((item, index) => ({ id: index, ...item.dimensions, weight: item.weight.value }))
    setCurrentPackageData(formattedData)
  }
  const handleCloseButton = () => {
    handleCloseSideSheet()
  }
  //table columns
  const columns = [
    {
      id: 1,
      name: (
        <div className='w-100 text-center' title={t('S. No.')}>
          {t('S. No.')}
        </div>
      ),
      width: '64px',
      key: 'serial_number_for_unconfig_hubs',
      cell: (_, index) => <div className='w-100 text-center'>{index + 1}</div>
    },
    {
      id: 2,
      name: t('Length (in cm)'),
      width: '200px',
      key: 'length',
      cell: (row) => (
        <InputField
          name='length'
          value={row.length}
          errors={row.errors}
          type='number'
          onChange={(e) => handleChangePackageData(e, 'length', row.id)}
          isTooltipError
          label=''
          placeholder='Enter Value'
        />
      )
    },
    {
      id: 3,
      name: t('Width (in cm)'),
      minWidth: '200px',
      key: 'breadth',
      cell: (row) => (
        <InputField
          name='breadth'
          value={row.breadth}
          errors={row.errors}
          type='number'
          onChange={(e) => handleChangePackageData(e, 'breadth', row.id)}
          isTooltipError
          label=''
          placeholder='Enter Value'
        />
      )
    },
    {
      id: 4,
      name: t('Height (in cm)'),
      width: '200px',
      key: 'height',
      cell: (row) => (
        <InputField
          name='height'
          value={row.height}
          errors={row.errors}
          type='number'
          onChange={(e) => handleChangePackageData(e, 'height', row.id)}
          isTooltipError
          label=''
          placeholder='Enter Value'
        />
      )
    },
    {
      id: 5,
      name: t('Weight (in KG)'),
      width: '200px',
      key: 'weight',
      cell: (row) => (
        <div  className={classNames({'mt-22px' : !!row?.errors?.weight?.message})}>
          <InputField
            name='weight'
            value={row.weight}
            errors={row.errors}
            type='number'
            onChange={(e) => handleChangePackageData(e, 'weight', row.id)}
            label=''
            placeholder='Enter Value'
          />
        </div>
      )
    }
  ]

  return (
    <SideSheet
      isOpen={isSideSheetOpen}
      toggle={handleCloseButton}
      onOpened={handleSetDefaultState}
      unmountOnClose={true}
      modalClassName='modal-slide-in sidebar-todo-modal package-details-side-sheet'
      contentClassName='p-0 bg-white'
      size='md'
      title='Package Details'
    >
      <div className='sidesheet-body'>
        <ExpandableCustomTable
          data={currentPackageData || []}
          columns={columns}
          columnName={ALL_COLUMN_NAME_MAPPING.PACKAGE_DETAILS}
          useReactPaginate={false}
          showPagination={false}
          showColumnsTableHeader={false}
        />
        <div className='bg-light-3 d-flex justify-content-end p-1 border-dark-2 rounded-bottom-4 mt-n4 weight-footer gap-4'>
          <div className='text-dark-6'>Total Weight</div>
          <div className='text-dark pe-2'>{packageWeight} KG</div>
        </div>
      </div>
      <div className='d-flex justify-content-end gap-1 p-1 position-fixed sidesheet-Footer fixed-bottom bg-white'>
        <Button ofStyle='outlined' ofType='compact' onClick={handleCancel}>
          Cancel
        </Button>
        <Button type='button'  ofType='compact' onClick={handleSavePackageDetails}>
          Save
        </Button>
      </div>
    </SideSheet>
  )
}

export default PackageDetailsSideSheet
