import Button from '@src/@core/components/ui/button'
import CustomToast from '@src/@core/components/ui/custom-toast/CustomToast'
import SideSheetCloseButton from '@src/@core/components/ui/page-header/sideSheetCloseButton'
import WarningModalWithImage from '@src/@core/components/ui/warning-modal-with-image/WarningModalWithImage'
import abilityMap from '@src/assets/data/abilityMapping/abilityMapping'
import { PageNotFound } from '@src/components/pageNotFound/pageNotFound'
import { AbilityContext } from '@src/utility/context/Can'
import { ORDER_DETAILS_BUTTON_TYPE } from '@src/views/sales/sales.constant'
import { clearInvoiceData, getOrderLogs, getSingleOrderDetails, resetSuccess, syncOrderStatus } from '@src/views/sales/store/store'
import classNames from 'classnames'
import { useContext, useEffect, useState } from 'react'
import { RefreshCcw } from 'react-feather'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { ModalBody, UncontrolledTooltip } from 'reactstrap'
import './orderDetails.scss'
import ItemDetails from './orderDetailsTabPages/ItemDetails'
import OrderLog from './orderDetailsTabPages/OrderLog'
import PosItemDetails from './orderDetailsTabPages/posItemDetails'
import WmsOrderDetails from './orderDetailsTabPages/wmsOrderDetails/WmsOrderDetails'
import { OrderDetailsTabNames, OrderDetailsTabs } from './orderDetailsTabs/OrderDetailsTabs'

const OrderDetails = ({setShipmentSlider, sellerId, setIsModalOpen, handleGetParentData}) => {

  const dispatch = useDispatch()
  const ability = useContext(AbilityContext)
  const {t} = useTranslation()
  const {singleOrderDetailsData} = useSelector((store) => store.sales)
  const orderDetails = singleOrderDetailsData?.data
  const error = useSelector((store) => store.sales.error)
  const syncOrderStatusLoading = useSelector(store => store.sales.loading.syncOrderStatus)
  const syncOrderStatusSuccess = useSelector(store => store.sales.success.syncOrderStatus)
  const selectedGlobalHubId = useSelector(store => store.auth.selectedGlobalHubId)
  const userHubs = useSelector(state => state.auth.userHubs) || []
  const is_external_hub = userHubs.find(hub => hub.id === selectedGlobalHubId)?.is_external_hub
  const [isSyncConfirmationPopupOpen, setSyncConfirmationPopupOpen] = useState(false)
  const userPlan = useSelector(state => state.auth.userData.user_plan) || {}

  const isOnlyPosEnabled = (() => {   
    const enabledPlans = Object.values(userPlan).filter(plan => plan)
    if (enabledPlans.length > 1) return false
    else { return userPlan.is_pos_enabled }
  })()

  const TABS_DATA = {
    ITEM_DETAILS: { name: t('Item Details'), component: ItemDetails, is_visible: true },
    WMS_DETAILS: {name: t('Hub Operation Details'), component:WmsOrderDetails, is_visible: !is_external_hub && orderDetails?.show_hub_operation_details},
    ORDER_LOG: { 
      name: t('Order Log'), 
      component: OrderLog, 
      is_visible: ability.can(abilityMap.order.view_log.action, abilityMap.order.view_log.resource) 
    }
  }

  const TABS_COMPONENTS = {
    [TABS_DATA.ITEM_DETAILS.name]: isOnlyPosEnabled ? PosItemDetails : ItemDetails,
    [TABS_DATA.WMS_DETAILS.name]:WmsOrderDetails,
    [TABS_DATA.ORDER_LOG.name]: OrderLog
  }

  const [activeTab, setActiveTab] = useState(TABS_DATA.ITEM_DETAILS.name)

  const toggleTab = (tab) => {
    dispatch(clearInvoiceData())
    setActiveTab(tab === activeTab ? activeTab : tab)
  }
    
  const handleSyncConfirmationPopup = () => {
    setSyncConfirmationPopupOpen(!isSyncConfirmationPopupOpen)
  }

  const handleOrderSync = () => {
    const params = {order_id: orderDetails?.id}
    dispatch(syncOrderStatus(params))
  }

  const handleToastSuccessMessage = (data) => {
    const {items_added, items_removed} = data
    if (items_added && items_removed) {
      return `${items_added} new item(s) added and ${items_removed} item(s) removed from this order.`
    } else if (items_added) {
      return `${items_added} new item(s) added to this order.`
    } else if (items_removed) {
      return `${items_removed} item(s) removed from this order.`
    } else {
      return ''
    }    
  }

  const destroyCustomToast = () => {
    toast.dismiss()
  }

  useEffect(() => {
    if (syncOrderStatusSuccess) {
      handleSyncConfirmationPopup()
      CustomToast(`Order Synced Successfully.\n${handleToastSuccessMessage(syncOrderStatusSuccess.data)}`, {
        my_type: 'success',
        duration: Infinity,
        handleCloseToast: destroyCustomToast
      })
      if (activeTab === TABS_DATA.ORDER_LOG.name) dispatch(getOrderLogs(sellerId))
      dispatch(getSingleOrderDetails(orderDetails?.id))
      dispatch(resetSuccess())
      dispatch(clearInvoiceData())
    }
  }, [syncOrderStatusSuccess])

  const isSyncOrderPresent = orderDetails?.buttons?.find(action => action.key === ORDER_DETAILS_BUTTON_TYPE.SYNC_BUTTON.key)
  const storeName = orderDetails?.sales_channel?.store_name

  return (<>
    <div className="order-details-sidebar-wrapper">
      <div className="orderDetails">
        <div className="d-flex justify-content-between align-items-start" >
          <div className="d-flex flex-column align-items-start">
            <div className="modal-title txt-h1-md text-dark mb-2px d-flex gap-1">{t('Order Details')}
              {isSyncOrderPresent && <span className="flex-center-start gap-12px">
                <Button onClick={handleSyncConfirmationPopup} disabled={syncOrderStatusLoading} id="sync_order_status" ofStyle="outlined" ofType="compressed" className="rounded-12px txt-sub-md px-12px">
                  <RefreshCcw className={classNames({'sync-rotate-icon': syncOrderStatusLoading })} size={10} />
                  {t('Sync Order')}
                </Button>
                <UncontrolledTooltip placement="top" target='sync_order_status' offset={[0, 5]} trigger="hover" autoFocus={false}>
                  {`Fetch order details from ${storeName}.`}
                </UncontrolledTooltip>
              </span>}
            </div>
            <div className="fw-bold sellerOrderId d-flex align-items-center mb-25">
              <span> {t('Order ID')}: {orderDetails?.seller_sales_channel_order_id}
                        &nbsp;
                <span>
                  {orderDetails?.order_alias && `(${orderDetails?.order_alias})`}
                </span>
              </span>
            </div>
          </div>
          <SideSheetCloseButton clearButtonHandler={() => setIsModalOpen(false)}/>
        </div>
        {!!orderDetails && <div className="d-flex justify-content-between">
          <div>
            <OrderDetailsTabNames TABS_DATA={TABS_DATA} activeTab={activeTab} toggleTab={toggleTab} />
          </div>
        </div>}
      </div>
      <ModalBody className="orderDetailsModalBody pt-1 w-100">
        {error.getSingleOrderDetails ? <PageNotFound /> : <div className="w-100">
          <OrderDetailsTabs
            TABS_COMPONENTS={TABS_COMPONENTS}
            activeTab={activeTab}
            setShipmentSlider={setShipmentSlider}
            sellerId={sellerId}
            setIsModalOpen={setIsModalOpen}
            hubId={orderDetails?.hub_id}
            handleGetParentData={handleGetParentData}
          />
        </div>}
      </ModalBody>
    </div>
    <WarningModalWithImage
      key='sync_order_modal_liveorders_sidesheet'
      closeModalHandler={handleSyncConfirmationPopup}
      secondaryButtonHandler={handleOrderSync}
      primaryButtonHandler={handleSyncConfirmationPopup}
      secondaryButtonText={t('Yes, Update')}
      primaryButtonText={t('Cancel')}
      title={`${'Are you sure you want to Sync the order'}?`}
      content={`${t('Syncing the order will update it with the latest changes, except for any modifications to the shipping address')}.`}
      isOpen={isSyncConfirmationPopupOpen}
      isButtonVerticallyAlign={false}
      secondaryButtonProps={{
        loading: syncOrderStatusLoading
      }}
    />
  </>
  )
}

export default OrderDetails