import defaultImg from '@src/assets/images/catalog/bundlesku/default-img.svg'
import error_img from '@src/assets/images/catalog/bundlesku/error_img.svg'
import { BUNDLE } from '@src/views/catalog/catalog.constants'
import SimpleSkuDetailModal from '@src/views/catalog/components/simple-sku-detail-modal/SimpleSkuDetailModal'
import SkuChildDetailModal from '@src/views/catalog/components/sku-child-detail-modal/SkuChildDetailModal'
import { cleanupBundleChildSku, cleanupSingleSku } from '@src/views/catalog/store'
import { PACKAGE_TYPE } from '@src/views/Purchases/purchase.constants'
import { showSkuTypeTag } from '@src/views/sales/sales.utils'
import classNames from 'classnames'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
// import { ExpandableCustomTable } from "../globalTable/ExpandableCustomTable/index"
import { ExpandableCustomTable } from '@src/@core/components/ui/globalTable/ExpandableCustomTable'
import SideSheet from '@src/@core/components/ui/sideSheet'
import SKUDetailsCol from '@src/@core/components/ui/sku-details'
import './styles.scss'

const BundleSkuDetailsSideSheet = ({
  bundleSkuForDetails,
  toggleBundleSkuModal,
  ...rest
}) => {
  const [skuForDetails, setSkuForDetails] = useState({})
  const [openSkuDetailsSidebar, setOpenSkuDetailsSidebar] = useState(false)
  const [openSkuChildDetailsSidebar, setOpenSkuChildDetailsSidebar] = useState(false)
  const dispatch = useDispatch()
  const {t} = useTranslation()

  const handleSkuDetailsSidebar = (data) => {
    if (data) {
      setSkuForDetails(data)
      setOpenSkuDetailsSidebar(true)
    } else {
      setOpenSkuDetailsSidebar(false)
    }
  }
  
  const columns = [
    {
      id: 1,
      name: t('S. No.'),
      key: 'serial_no',
      width: '64px',
      reorder: false,
      cell: (row, index) => (
        <div className="w-100 text-center">{row.serialNumber || index + 1}</div>
      )
    },
    {
      id: 2,
      name: <span className="txt-body-md" title={t('SKU Details')}>{t('SKU Details')}</span>,
      width: '400px',
      reorder: true,
      cell: (row) => {
        return (
          <SKUDetailsCol
            skuDetailData={{
              isValidSku: true,
              id: row.id,
              image: {
                src: row.images?.[0]?.default,
                alt: row.name
              },
              details: {
                name: row.name,
                handleSkuDetails: () => handleSkuDetailsSidebar(row),
                skuCode: row.seller_sku_code,
                columnKey: 'sku_details'
              },
              customTag: {
                isCustomTagPresent: row.package_type === PACKAGE_TYPE.CASE_PACK || row.package_type === PACKAGE_TYPE.PALLET,
                className: classNames({
                  'bg-brown-light text-brown': row.package_type === PACKAGE_TYPE.PALLET,
                  'bg-info-light text-info': row.package_type === PACKAGE_TYPE.CASE_PACK
                }),
                title: showSkuTypeTag(row.packageType)
              }
            }}
          />
        )
      }
    },
    {
      id: 3,
      name: (
        <div className="txt-body-md w-100 text-end text-truncate" title={t('Qty Per Kit')}>
          {t('Qty Per Kit')}
        </div>
      ),
      key: 'qty_per_kit',
      minWidth: '120px',
      maxWidth: '120px',
      reorder: true,
      cell: (row) => {
        const { quantity } = row
        return (
          <div className="w-100">
            <div className="w-100 txt-sub-rg text-end text-truncation">
              {quantity}
            </div>
          </div>
        )
      }
    },
    {
      id: 4,
      name: (
        <div className="txt-body-md w-100 text-end text-truncate" title={t('Picked Qty')}>
          {t('Picked Qty')}
        </div>
      ),
      key: 'picked_qty',
      minWidth: '120px',
      maxWidth: '120px',
      reorder: true,
      cell: (row) => {
        const { picked_quantity } = row
        return (
          <div className="w-100">
            <div className="w-100 txt-sub-rg text-end text-truncation">
              {picked_quantity}
            </div>
          </div>
        )
      }
    },
    {
      id: 5,
      name: (
        <div className="txt-body-md w-100 text-end text-truncate" title={t('Packed Qty')}>
          {t('Packed Qty')}
        </div>
      ),
      key: 'total_qty',
      minWidth: '130px',
      maxWidth: '130px',
      reorder: true,
      cell: (row) => {
        const { packed_quantity } = row
        return (
          <div className="w-100">
            <div className="w-100 txt-sub-rg text-end text-truncation">
              {packed_quantity}
            </div>
          </div>
        )
      }
    }
  ]

  const onModalClosed = () => {
    dispatch(cleanupSingleSku())
    dispatch(cleanupBundleChildSku())
  }

  const handleBundleSkuDetailsSidebar = () => {
    setOpenSkuChildDetailsSidebar(!openSkuChildDetailsSidebar)
    setSkuForDetails(bundleSkuForDetails)
  }

  return (
    <SideSheet
      modalClassName="modal-slide-in order-details-bundle-sku-detail-modal"
      size="md"
      toggle={toggleBundleSkuModal}
      onClosed={onModalClosed}
      title="Kit Overview"
      {...rest}
    >
      <div className="m-24px overflow-auto">
        <div className="flex-center-start gap-24px">
          <div className="border rounded-8px">
            <img
              className="rounded-8px"
              src={
                bundleSkuForDetails.image || defaultImg
              }
              onError={({ currentTarget }) => {
                currentTarget.onerror = null
                currentTarget.src = error_img
              }}
              alt="bundle-sku-image"
              width={99}
              height={99}
            />
          </div>
          <div className="width-600"> 
            <div className="text-primary txt-h3-md cursor-pointer text-wrap" onClick={handleBundleSkuDetailsSidebar}>
              {bundleSkuForDetails.name}
            </div> 
            <div className="txt-h3-rg dark-6 mt-12px">
              {bundleSkuForDetails.seller_sku_code}
            </div> 
          </div>
        </div>
        <div className="mt-30px">
          <ExpandableCustomTable
            data={bundleSkuForDetails?.child_skus || []} 
            columns={columns}
            showPagination={false}
            showColumnsTableHeader={false}
          />
        </div> 
      </div>       

      <SimpleSkuDetailModal
        isOpen={openSkuDetailsSidebar}
        skuForDetails={skuForDetails}
        toggleSimpleSkuModal={() => handleSkuDetailsSidebar(false)}
      />

      <SkuChildDetailModal
        skuForDetails={skuForDetails}
        toggle={handleBundleSkuDetailsSidebar}
        isOpen={openSkuChildDetailsSidebar}
        skuType={BUNDLE}
      />
    </SideSheet>
  )
}

export default BundleSkuDetailsSideSheet