export const SuccessIcon1 = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.01683 10.0334L10.7335 5.31671L9.96683 4.56671L6.01683 8.51671L4.01683 6.51671L3.26683 7.26671L6.01683 10.0334ZM7.00016 13.6667C6.08905 13.6667 5.22794 13.4917 4.41683 13.1417C3.60572 12.7917 2.89738 12.3139 2.29183 11.7084C1.68627 11.1028 1.2085 10.3945 0.858496 9.58337C0.508496 8.77226 0.333496 7.91115 0.333496 7.00004C0.333496 6.07782 0.508496 5.21115 0.858496 4.40004C1.2085 3.58893 1.68627 2.88337 2.29183 2.28337C2.89738 1.68337 3.60572 1.20837 4.41683 0.858374C5.22794 0.508374 6.08905 0.333374 7.00016 0.333374C7.92238 0.333374 8.78905 0.508374 9.60016 0.858374C10.4113 1.20837 11.1168 1.68337 11.7168 2.28337C12.3168 2.88337 12.7918 3.58893 13.1418 4.40004C13.4918 5.21115 13.6668 6.07782 13.6668 7.00004C13.6668 7.91115 13.4918 8.77226 13.1418 9.58337C12.7918 10.3945 12.3168 11.1028 11.7168 11.7084C11.1168 12.3139 10.4113 12.7917 9.60016 13.1417C8.78905 13.4917 7.92238 13.6667 7.00016 13.6667Z"
        fill="#067603"
      />
    </svg>
  )
}
