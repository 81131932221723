import Button from '@src/@core/components/ui/button'
import FloatingDropDown from '@src/@core/components/ui/floating-dropdown'
import InputField from '@src/@core/components/ui/input-field'
import PhoneNumberDropdown from '@src/@core/components/ui/phone-number'
import SideSheet from '@src/@core/components/ui/sideSheet'
import { REGEX_PATTERN } from '@src/App.constants'
import { Countries } from '@src/views/hubs/utils/countries'
import { SHIPPING_ENTITY } from '@src/views/sales/constant/orders.constants'
import { EDIT_MODAL_TYPE } from '@src/views/sales/sales.constant'
import { editCustomerAndShippingDetails, editShipmentOrderDetails, getCitiesAsyncData, getCountriesAsyncData, getStatesAsync, getStatesCitiesAsync } from '@src/views/sales/store/store'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Form, ModalBody, Spinner } from 'reactstrap'
import SidesheetFooter from '../../../../../@core/components/ui/sidesheet-footer'
import './styles.scss'

const EditCustomerAndShippingDetails = ({ isModalOpen, setIsModalOpen, editDetails, order_id, entity_name = '' }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const loading = useSelector((state) => state.sales.loading)
  const editModalCustomerOrRecepientType = [EDIT_MODAL_TYPE.CUSTOMER_DETAILS.key, EDIT_MODAL_TYPE.RECEPIENT_DETAILS.key]
  const { register, control, watch, reset, handleSubmit, setValue, setError, clearErrors, formState: { errors, isDirty }} = useForm({
    defaultValues: {
      first_name: '',
      last_name: '',
      email: '',
      mobile: {
        countryCode: '',
        contactNumber: ''
      },
      address1: '',
      address2: '',
      city: null,
      country: null,
      state: null,
      zip:''
    },
    mode: 'onChange'
  })
  
  const handleOnOpened = () => {
    if (editModalCustomerOrRecepientType.includes(isModalOpen.key)) {
      const mobileDetails = editDetails.mobile.split('-')
      const countryData = mobileDetails.length === 2 ? Countries.find(country => country.calling_code === mobileDetails[0]) : ''
      reset({
        first_name: editDetails.first_name || '',
        last_name: editDetails.last_name || '',
        email: editDetails.email || '',
        mobile: {
          contactNumber: mobileDetails[1] || mobileDetails[0] || '',
          countryCode:countryData || ''
        }
      })
    } else {
      const mobileDetails = editDetails.phone.split('-')
      const countryData = mobileDetails.length === 2 ? Countries.find(country => country.calling_code === mobileDetails[0]) : ''
      reset({
        mobile: {
          contactNumber: mobileDetails[1] || mobileDetails[0] || '',
          countryCode:countryData || ''
        },
        first_name: editDetails.first_name || '',
        last_name: editDetails.last_name || '',
        email: editDetails.email || '',
        address1:editDetails.address1 || '',
        address2:editDetails.address2 || '',
        country: editDetails.omniful_country ? {label: editDetails.omniful_country, value: editDetails.omniful_country_id, code:editDetails.country_code, currenyCode:editDetails.country_currency_code} : null,
        state:editDetails.omniful_state ? {label: editDetails.omniful_state, value: editDetails.omniful_state_id, code:editDetails.state_code} : null,
        city:editDetails.omniful_city ? {label: editDetails.omniful_city, value: editDetails.omniful_city_id} : null,
        zip: editDetails.zip || ''
      })
    }
  }
  const handleOnClosed = () => {
    reset()
  }
  const handleEditSubmit = (data) => {
    let body = {}
    if (editModalCustomerOrRecepientType.includes(isModalOpen.key)) {
      body = {
        customer: {
          first_name: data.first_name,
          last_name: data.last_name,
          email: data.email || undefined
        },
        edit_entity_type:EDIT_MODAL_TYPE.CUSTOMER_DETAILS.value
      }
      if (data.mobile.contactNumber) {
        body = {
          ...body,
          customer: {
            ...body.customer,
            mobile: data.mobile.contactNumber,
            country_calling_code: data.mobile.countryCode.calling_code,
            country_code: data.mobile.countryCode.country_code
          }
        }
      }      
    } else {
      body = {
        shipping_address: {
          first_name: data.first_name,
          last_name: data.last_name,
          address1: data.address1,
          address2: data.address2,
          zip: data.zip || undefined,
          email: data.email || undefined,
          country_currency_code: data.country?.currenyCode,
          omniful_city: data.city?.value ? {
            id:data.city.value,
            name:data.city.label
          } : {},
          omniful_state: data.state?.value ? {
            id:data.state.value,
            name:data.state.label,
            code:data.state.code
          } : {},
          omniful_country: data.country?.value ? {
            id:data.country.value,
            name: data.country.label,
            code:data.country.code
          } : {}
        },
        edit_entity_type:EDIT_MODAL_TYPE.SHIPPING_DETAILS.value
      }
      if (data.mobile.contactNumber) {
        body = {
          ...body,
          shipping_address: {
            ...body.shipping_address,
            mobile: data.mobile.contactNumber,
            country_calling_code: data.mobile.countryCode.calling_code,
            country_code: data.mobile.countryCode.country_code
          }
        }
      }   
    }
    if (entity_name === SHIPPING_ENTITY.SHIPMENT_ORDER.value) {
      dispatch(editShipmentOrderDetails({shipment_order_id:order_id, body}))
    } else {
      dispatch(editCustomerAndShippingDetails({order_id, body}))
    }
  }

  return (
    <SideSheet
      isOpen={isModalOpen.open}
      toggle={() => { setIsModalOpen(prev => ({ ...prev, open: false })) }}
      modalClassName='modal-slide-in edit-customer-and-shipping-details-side-sheet'
      size='sm'
      onOpened={handleOnOpened}
      onClosed={handleOnClosed}
      title={EDIT_MODAL_TYPE[isModalOpen.key].title}
    >
      <ModalBody className='mt-32px p-0'>
        <Form onSubmit={handleSubmit(handleEditSubmit)}>
          <div className="customer-and-shipping-details"> 
            <div className='px-24px d-flex flex-column gap-24px'>
              <InputField
                name='first_name'
                errors={errors}
                {...register('first_name')}
                isClearable
                label='First Name'
              />
              <InputField
                name='last_name'
                {...register('last_name')}
                errors={errors}
                isClearable
                label='Last Name'
              />
              {editModalCustomerOrRecepientType.includes(isModalOpen.key) && <PhoneNumberDropdown
                name='mobile'
                control={control}
                errors={errors}
                register={register}
                isClearable
                label='Contact Number'
                contactNumber={watch('mobile.contactNumber')}
                countryCode={watch('mobile.countryCode')}
                setError={setError}
                clearErrors={clearErrors}
              />}
              <InputField
                errors={errors}
                {...register('email', {
                  pattern:{
                    value: REGEX_PATTERN.EMAIL_VALIDATION,
                    message: t('Enter a valid email address')
                  }
                })}
                isClearable
                label='Email ID'
              />
            </div>
            {!editModalCustomerOrRecepientType.includes(isModalOpen.key) && <div className='px-24px d-flex flex-column gap-24px mt-24px'>
              <InputField
                errors={errors}
                {...register('address1')}
                isClearable
                label='Address line 1'
              />
              <InputField
                errors={errors}
                {...register('address2')}
                isClearable
                label='Address line 2'
              />
              <div className='row'>
                <div className='col-6'>
                  <FloatingDropDown
                    name='country'
                    isAsync
                    control={control}
                    defaultValue={watch('country')}
                    value={watch('country')}
                    loadOptions={getCountriesAsyncData}
                    errors={errors}
                    isClearable
                    title='Country'
                    onChangeFunc={() => {
                      if (watch('state') || watch('city')) {
                        setValue('state', null)
                        setValue('city', null)
                      }
                    }}
                  />
                </div>
                <div className='col-6'>
                  <FloatingDropDown
                    name='state'
                    isAsync
                    control={control}
                    defaultValue={watch('state')}
                    value={watch('state')}
                    isDisabled={!watch('country')}
                    loadOptions={getStatesAsync}
                    errors={errors}
                    isClearable
                    title='State/Region'
                    cacheUniqs={[watch('country')]}
                    additional={{
                      page: 1,
                      country: watch('country')
                    }}
                    onChangeFunc={() => {
                      if (watch('city')) {
                        setValue('city', null)
                      }
                    }}
                  />
                </div>
              </div>
              <div className='row'>
                <div className='col-6'>
                  <FloatingDropDown
                    name='city'
                    isAsync
                    control={control}
                    defaultValue={watch('city')}
                    value={watch('city')}
                    loadOptions={watch('state') ? getStatesCitiesAsync : getCitiesAsyncData}
                    errors={errors}
                    isClearable
                    isDisabled={!watch('country')}
                    title='City'
                    cacheUniqs={watch('state') ? [watch('state')] : [watch('country')]}
                    additional={{
                      page: 1,
                      state: watch('state'),
                      country_id: watch('country')?.value
                    }}
                  />
                </div>
                <div className='col-6'>
                  <InputField
                    name='zip'
                    errors={errors}
                    {...register('zip', {
                      pattern: {
                        value: /^[a-zA-Z0-9]+$/,
                        message: 'only alphanumeric characters allowed'
                      }
                    })}
                    isClearable
                    label='Zip Code'
                  />
                </div>
              </div>
              <PhoneNumberDropdown
                name='mobile'
                control={control}
                errors={errors}
                register={register}
                isClearable
                label='Contact Number'
                contactNumber={watch('mobile.contactNumber')}
                countryCode={watch('mobile.countryCode')}
                setError={setError}
                clearErrors={clearErrors}
              />
            </div>}
          </div>  
          <SidesheetFooter>
            <Button className='save-details-button' type='submit' disabled={loading.editCustomerAndShippingDetails || loading.editShipmentOrderDetails || !isDirty}>{(loading.editCustomerAndShippingDetails || loading.editShipmentOrderDetails) && <Spinner size="sm" className="me-25" />}{t('Save Details')}</Button>
          </SidesheetFooter>
        </Form>
      </ModalBody>
    </SideSheet>
  )
}

export default EditCustomerAndShippingDetails