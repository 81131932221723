// ** React Imports
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

// ** Store & Actions
import { handleContentWidth, handleMenuCollapsed, handleMenuHidden, toggleSecondaryNav } from '@store/layout'
import { useDispatch, useSelector } from 'react-redux'

// ** Third Party Components
import classnames from 'classnames'
import { ArrowUp } from 'react-feather'

// ** Reactstrap Imports
import { Button, Navbar } from 'reactstrap'

// ** Configs
import themeConfig from '@configs/themeConfig'

// ** Custom Components

import Customizer from '@src/@core/components/ui/customizer'
import ScrollToTop from '@src/@core/components/ui/scrolltop'
// import FooterComponent from './components/footer'
import SidebarComponent from './components/menu/vertical-menu'
import NavbarComponent from './components/navbar'

// ** Custom Hooks
import { useFooterType } from '@hooks/useFooterType'
import { useLayout } from '@hooks/useLayout'
import { useNavbarColor } from '@hooks/useNavbarColor'
import { useNavbarType } from '@hooks/useNavbarType'
import { useRTL } from '@hooks/useRTL'
import { useSkin } from '@hooks/useSkin'

// ** Styles
import '@styles/base/core/menu/menu-types/vertical-menu.scss'
import '@styles/base/core/menu/menu-types/vertical-overlay-menu.scss'
// import Banner from './components/banner/Banner'
// import { HUBS_CONFIGURATION } from '@src/App.constants'
// import abilityMap from '@src/assets/data/abilityMapping/abilityMapping'
// import { AbilityContext } from '@src/utility/context/Can'


const VerticalLayout = props => {
  // ** Props
  const { menu, navbar, children, menuData } = props

  // ** Hooks
  const [isRtl, setIsRtl] = useRTL()
  const { skin, setSkin } = useSkin()
  const { navbarType, setNavbarType } = useNavbarType()
  const { footerType, setFooterType } = useFooterType()
  const { navbarColor, setNavbarColor } = useNavbarColor()
  const { layout, setLayout, setLastLayout } = useLayout()
  // const navigate = useNavigate()
  // const ability = useContext(AbilityContext)

  // ** States
  const [isMounted, setIsMounted] = useState(false)
  const [menuVisibility, setMenuVisibility] = useState(false)
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  // ** Vars
  const dispatch = useDispatch()
  const layoutStore = useSelector(state => state.layout)
  // const permissions = useSelector(state => state.auth.permissions)
  // const isPOVisible = permissions.find(el => el.action === 'view' && el.)
  // const selectedHubConfiguration = useSelector(state => state.auth.selectedHubConfiguration)
  // const locationHubConfig = selectedHubConfiguration?.find(el => el.configuration_type === HUBS_CONFIGURATION.LOCATION_INVENTORY.value)
  // const isLocationInventoryEnabled = locationHubConfig?.configuration_values?.enabled

  // ** Update Window Width
  const handleWindowWidth = () => {
    setWindowWidth(window.innerWidth)
  }
  // const pathName = '/reports'
  // const handleBannerAction = () => {
  //   navigate(pathName)
  // }
  
  // ** Vars
  const location = useLocation()
  const isHidden = layoutStore.menuHidden
  const contentWidth = layoutStore.contentWidth
  const menuCollapsed = layoutStore.menuCollapsed
  const hasSecondaryMenu = layoutStore.hasSecondaryMenu
  const isLayoutReady = layoutStore.isLayoutReady
  const secondaryNavCollapsed = layoutStore.secondaryNavCollapsed

  // ** Toggles Menu Collapsed
  const setMenuCollapsed = val => dispatch(handleMenuCollapsed(val))

  // ** Handles Content Width
  const setContentWidth = val => dispatch(handleContentWidth(val))

  // ** Handles Content Width
  const setIsHidden = val => dispatch(handleMenuHidden(val))

  //** This function will detect the Route Change and will hide the menu on menu item click
  useEffect(() => {
    if (menuVisibility && windowWidth < 1200) {
      setMenuVisibility(false)
    }
  }, [location])

  //** Sets Window Size & Layout Props
  useEffect(() => {
    if (window !== undefined) {
      window.addEventListener('resize', handleWindowWidth)
    }
  }, [windowWidth])

  //** ComponentDidMount
  useEffect(() => {
    setIsMounted(true)
    return () => setIsMounted(false)
  }, [])

  // ** Vars
  const footerClasses = {
    static: 'footer-static',
    sticky: 'footer-fixed',
    hidden: 'footer-hidden'
  }

  const navbarWrapperClasses = {
    floating: 'navbar-floating',
    sticky: 'navbar-sticky',
    static: 'navbar-static',
    hidden: 'navbar-hidden'
  }

  const navbarClasses = {
    floating: contentWidth === 'boxed' ? 'floating-nav container-xxl' : 'floating-nav',
    sticky: 'fixed-top',
    static: 'navbar-static-top',
    hidden: 'd-none'
  }

  const bgColorCondition = navbarColor !== '' && navbarColor !== 'light' && navbarColor !== 'white'

  const handleToggleSidebar = (prop) => {
    if (typeof prop !== 'function') {
      dispatch(toggleSecondaryNav(prop))
    } else {
      const result = prop(secondaryNavCollapsed)
      dispatch(toggleSecondaryNav(result))
    }
  }

  if (!isMounted) {
    return null
  }
  return (
    <div
      className={classnames(
        `wrapper vertical-layout ${navbarWrapperClasses[navbarType] || 'navbar-floating'} ${footerClasses[footerType] || 'footer-static'}`,
        {
          // Modern Menu
          'vertical-menu-modern': true,
          'menu-collapsed': true,
          'menu-expanded': true,
          'has-secondary-menu': hasSecondaryMenu,
          'sec-menu-expanded': hasSecondaryMenu && !secondaryNavCollapsed,
          'sec-menu-collapsed': hasSecondaryMenu && secondaryNavCollapsed

          // Overlay Menu
          // 'vertical-overlay-menu': windowWidth < 1200,
          // 'menu-hide': !menuVisibility && windowWidth < 1200,
          // 'menu-open': menuVisibility && windowWidth < 1200
        }
      )}
      {...(isHidden ? { 'data-col': '1-column' } : {})}
    >
      {isLayoutReady &&
        <>
          {!isHidden ? (
            <SidebarComponent
              skin={skin}
              menu={menu}
              menuData={menuData}
              hasSecondaryMenu={hasSecondaryMenu}
              menuCollapsed={menuCollapsed}
              menuVisibility={menuVisibility}
              setMenuCollapsed={setMenuCollapsed}
              secondaryNavCollapsed={secondaryNavCollapsed}
              setSecondaryNavCollapsed={handleToggleSidebar}
              setMenuVisibility={setMenuVisibility}
            />
          ) : null}
          <div className='position-fixed top-0 left-0' style={{ zIndex: '99'}}>
            <Navbar
              expand="lg"
              container={false}
              light={skin !== 'dark'}
              dark={skin === 'dark' || bgColorCondition}
              color={bgColorCondition ? navbarColor : undefined}
              className={classnames(`position-relative header-navbar navbar align-items-center ${navbarClasses[navbarType] || 'floating-nav'} navbar-border`)}
            >
              <div className="navbar-container d-flex content">
                {navbar ? (
                  navbar({ skin, setSkin, setMenuVisibility })
                ) : (
                  <NavbarComponent setMenuVisibility={setMenuVisibility} skin={skin} setSkin={setSkin} />
                )}
              </div>
            </Navbar>
            {/* {ability.can(abilityMap.report.view.action, abilityMap.report.view.resource) &&
              <Banner text='For the next 2 months, you can download reports from any timeframe up to 90 days; afterwards, only the last 90 days will be available. Please download the required reports within this period.'
                action={handleBannerAction} 
                actionText='Go to Reports' 
              />
            } */}
          </div>
        </>
      }
      {children}

      {/* Vertical Nav Menu Overlay */}
      <div
        className={classnames('sidenav-overlay', {
          show: menuVisibility
        })}
        onClick={() => setMenuVisibility(false)}
      ></div>
      {/* Vertical Nav Menu Overlay */}

      {themeConfig.layout.customizer === true ? (
        <Customizer
          skin={skin}
          isRtl={isRtl}
          layout={layout}
          setSkin={setSkin}
          setIsRtl={setIsRtl}
          isHidden={isHidden}
          setLayout={setLayout}
          footerType={footerType}
          navbarType={navbarType}
          setIsHidden={setIsHidden}
          themeConfig={themeConfig}
          navbarColor={navbarColor}
          contentWidth={contentWidth}
          setFooterType={setFooterType}
          setNavbarType={setNavbarType}
          setLastLayout={setLastLayout}
          menuCollapsed={menuCollapsed}
          setNavbarColor={setNavbarColor}
          setContentWidth={setContentWidth}
          setMenuCollapsed={setMenuCollapsed}
        />
      ) : null}
      {/* <footer
        className={classnames(`footer footer-light ${footerClasses[footerType] || 'footer-static'}`, {
          'd-none': footerType === 'hidden'
        })}
      >
        {footer ? footer : <FooterComponent footerType={footerType} footerClasses={footerClasses} />}
      </footer> */}

      {themeConfig.layout.scrollTop === true ? (
        <div className="scroll-to-top">
          <ScrollToTop showOffset={300} className="scroll-top d-block">
            <Button className="btn-icon" color="primary">
              <ArrowUp size={14} />
            </Button>
          </ScrollToTop>
        </div>
      ) : null}
    </div>
  )
}

export default VerticalLayout
