// ** React Imports
import { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'

// ** Third Party Components
import classnames from 'classnames'
import { useTranslation } from 'react-i18next'

// ** Reactstrap Imports
import { Badge, Collapse } from 'reactstrap'

// ** Vertical Menu Items Component
import VerticalNavMenuItems from './VerticalNavMenuItems'

// ** Utils
import { hasActiveChild, removeChildren } from '@layouts/utils'
import { ChevronDown, ChevronUp } from 'react-feather'
// import { useHighlightSidebarItem } from '@src/utility/hooks/useHighlightSidebarItem'
import ability from '@src/configs/acl/ability'

const VerticalNavMenuGroup = ({ item, groupOpen, menuHover, activeItem, parentItem, groupActive, setGroupOpen, menuCollapsed, setGroupActive, currentActiveGroup, setCurrentActiveGroup, ...rest }) => {
  // console.log(
  //   item,
  //   groupOpen,
  //   menuHover,
  //   activeItem,
  //   parentItem,
  //   groupActive,
  //   setGroupOpen,
  //   menuCollapsed,
  //   setGroupActive,
  //   currentActiveGroup,
  //   setCurrentActiveGroup,
  //   'consoled'
  // )
  // const { secondaryMenuActiveItem } = rest
  // const { setActiveItem } = rest
  // console.log(groupOpen, 'groupOpen')
  // console.log('activeItem: ', activeItem, 'groupActive: ', groupActive, 'currnetActiveGroup: ', currentActiveGroup)
  // ** Hooks
  const [isHovered, setIsHovered] = useState(false);
  const { t } = useTranslation()
  const location = useLocation()
  const [groupOpenLocalState, setGroupOpenLocalState] = useState(true)
  const checkIsGroupActive = groupActive.includes(item.id)
  const checkIsGroupOpen = groupOpen.includes(item.id)
  const isGroupCurrentlyActive = currentActiveGroup.includes(item.id)

  // ** Current Val
  const currentURL = useLocation().pathname
  // const purchaseOrderRef = useRef(null)
  // const purchaseOrderBannerCondition = item.id === 'purchases' && location.state === 'po-banner'
  // const [tooltipState, setToolTipState] = useState(false)
  // const removeHighlightCallback = () => {
  //   window.history.replaceState({ state: null }, '', '/purchases/purchase-order')
  //   setToolTipState(false)
  // }
  // const { addHighlight, removeHighlight } = useHighlightSidebarItem(purchaseOrderRef, removeHighlightCallback)

  // ** Toggle Open Group
  const toggleOpenGroup = (item, parent) => {
    let openGroup = groupOpen
    const activeGroup = groupActive
    if (isGroupCurrentlyActive) setGroupOpenLocalState(p => !p)
    else setGroupOpenLocalState(true)

    // ** If Group is already open and clicked, close the group
    if (openGroup.includes(item.id)) {
      openGroup.splice(openGroup.indexOf(item.id), 1)

      // ** If clicked Group has open group children, Also remove those children to close those groups
      if (item.children) {
        removeChildren(item.children, openGroup, groupActive)
      }
    } else if (activeGroup.includes(item.id) || isGroupCurrentlyActive) {
      // ** If Group clicked is Active Group

      // ** If Active group is closed and clicked again, we should open active group else close active group
      if (!activeGroup.includes(item.id) && isGroupCurrentlyActive) {
        activeGroup.push(item.id)
      } else {
        activeGroup.splice(activeGroup.indexOf(item.id), 1)
      }
      
      // ** Update Active Group
      setGroupActive([...activeGroup])
    } else if (parent) {
      // ** If Group clicked is the child of a open group, first remove all the open groups under that parent
      if (parent.children) {
        removeChildren(parent.children, openGroup, groupActive)
      }
      
      // ** After removing all the open groups under that parent, add the clicked group to open group array
      if (!openGroup.includes(item.id)) {
        openGroup.push(item.id)
      }
    } else {
      // ** If clicked on another group that is not active or open, create openGroup array from scratch

      // ** Empty Open Group array
      openGroup = []

      // ** Push current clicked group item to Open Group array
      if (!openGroup.includes(item.id)) {
        openGroup.push(item.id)
      }
    }
    // console.log(openGroup, 'openGroup')
    setGroupOpen([...openGroup])
  }

  // ** On Group Item Click
  const onCollapseClick = (e, item) => {
    toggleOpenGroup(item, parentItem)
    // setActiveItem(item)
    e.preventDefault()
  }

  // ** Checks url & updates active item
  useEffect(() => {
    if (hasActiveChild(item, currentURL)) {
      if (!checkIsGroupActive) groupActive.push(item.id)
    } else {
      const index = groupActive.indexOf(item.id)
      if (index > -1) groupActive.splice(index, 1)
    }
    setGroupActive([...groupActive])
    setCurrentActiveGroup([...groupActive])
    setGroupOpen([])
  }, [location])

  // ** Returns condition to add open class
  const openClassCondition = id => {
    if ((menuCollapsed && menuHover) || menuCollapsed === false) {
      if (groupActive.includes(id) || groupOpen.includes(id)) {
        return true
      }
    } else if (groupActive.includes(id) && menuCollapsed && menuHover === false) {
      return false
    } else {
      return null
    }
  }
  // console.log(rest, 'rest', item)
  // console.log(item, item?.children)
  const navigate = useNavigate()
  // const { setSecondaryMenuActiveItem } = rest

  // useEffect(() => {
  //   if (!!purchaseOrderRef.current && purchaseOrderBannerCondition) {
  //     addHighlight()
  //     setTimeout(() => {
  //       setToolTipState(true)
  //     }, 500)
  //   }
  // }, [purchaseOrderRef.current, purchaseOrderBannerCondition])

  const getActiveChild = (item) => {
    for (const child of item) {
      if ((!child.action && !child.resource) || ability.can(child.action, child.resource)) {
        return child
      }
    }
  }
  
  return (
    <ul
      className={classnames('nav-item has-sub overflow-hidden', {
        open: openClassCondition(item.id),
        'menu-collapsed-open': checkIsGroupActive,
        'sidebar-group-active': checkIsGroupActive || checkIsGroupOpen || isGroupCurrentlyActive
      })}
      onMouseOver={() => setIsHovered(true)}
      onMouseOut={() => setIsHovered(false)}
    >
      <Link
        id={item.id}
        // ref={purchaseOrderRef}
        title={t(item.title)}
        className={classnames('d-flex align-items-center secondary-menu-group-header py-0 px-0 bg-white', {
          'active-group': isGroupCurrentlyActive
          // item.children && groupActive && groupActive.includes(item.id)
        })}
        to="/"
        onClick={e => {
          if (item?.children?.length > 0) {
            const activeChild = getActiveChild(item.children)
            navigate(activeChild.navLink)
            // setSecondaryMenuActiveItem(activeChild)
          }
          // if (purchaseOrderRef.current && purchaseOrderBannerCondition) { 
          //   removeHighlight()
          // }
          onCollapseClick(e, item)
        }}
      >
        {item.icon ? <item.icon iswhite={false} height={20} width={20} fillColor={isHovered || isGroupCurrentlyActive?'#222':'#666'}/> : null}
        <span className="menu-title text-truncate" style={{color: isHovered || isGroupCurrentlyActive?'#222':'#666'}}>{t(item.title)}</span>
        {item.children &&
          (groupOpenLocalState && ((groupActive && checkIsGroupActive) || (groupOpen && checkIsGroupOpen)) ? (
            <ChevronUp size={16} className="ms-auto me-4px"/>
          ) : (
            <ChevronDown size={16} className="ms-auto me-4px"/>
          ))}
        {item.badge && item.badgeText ? (
          // <Badge className="ms-auto me-1" color={item.badge} pill>
          //   {item.badgeText}
          // </Badge>
          <Badge color="light-success" className='custom-badge' >{item.badgeText}</Badge>
          // <Badge className='ms-auto me-1' color={item.badge} pill>
          //   {item.badgeText}
          // </Badge>
        ) : null}
      </Link>
      {/* {purchaseOrderBannerCondition && item.id === 'purchases' && <UncontrolledTooltip boundary="scrollParent" innerClassName='text-start' popperClassName='ms-6px' autohide isOpen={tooltipState} placement="right" target='purchases'>
      <p className='txt-sub-md text-white m-0 mb-4px'>Purchases has been moved to Inventory tab.</p>
      <p className='txt-asst-rg text-dark-5 m-0'>Now, conveniently access all inventory related information from a single place!</p>
      </UncontrolledTooltip>} */}
      {/* Render Child Recursively Through VerticalNavMenuItems Component */}
      {/* <li className='menu-content'> */}
      <Collapse isOpen={groupOpenLocalState && ((groupActive && checkIsGroupActive) || (groupOpen && checkIsGroupOpen))}>
        <VerticalNavMenuItems
          {...rest}
          items={item.children}
          groupActive={groupActive}
          setGroupActive={setGroupActive}
          currentActiveGroup={currentActiveGroup}
          setCurrentActiveGroup={setCurrentActiveGroup}
          groupOpen={groupOpen}
          setGroupOpen={setGroupOpen}
          parentItem={item}
          menuCollapsed={menuCollapsed}
          menuHover={menuHover}
          activeItem={activeItem}
        />
      </Collapse>
      {/* </li> */}
    </ul>
  )
}

export default VerticalNavMenuGroup
