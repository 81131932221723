import { useSelector } from 'react-redux'

export const getStoredColumns = (userId) => {
  return JSON.parse(localStorage.getItem(userId)) || {}
}

const useLocalStorageTableColumns = (columnName, columns) => {
  const userId = useSelector(state => state.auth.userData?.id)
  const storedColumns = getStoredColumns(userId)
  const screenWiseStoredColumn = storedColumns[columnName]

  const setSortedColumn = (columnName, columns) => {
    const columnValueToSet = {}
    columns.forEach(element => {
      columnValueToSet[element.key] = {id: element.id, omit: !!element.omit}
    })
    if (columnName) {
      const finalValueToSet = {...storedColumns, [columnName]: columnValueToSet}
      localStorage.setItem(userId, JSON.stringify(finalValueToSet))
    }
    return columns
  }
  
  const getSortedColumns = (columnName, columns) => {
    const serializedInitialColumnValue = {}
    columns.forEach(element => {
      serializedInitialColumnValue[element.key] = element
    })
    let allColumnPresent = true
    const arrangedColumn = []
    for (const property in screenWiseStoredColumn) {
      if (!serializedInitialColumnValue[property]) {
        allColumnPresent = false
        break
      }
      arrangedColumn.push({...serializedInitialColumnValue[property], omit: serializedInitialColumnValue[property]?.hasOwnProperty('omit') ? serializedInitialColumnValue[property].omit : !!screenWiseStoredColumn?.[property]?.omit})
    }
    if (allColumnPresent && (Object.keys(screenWiseStoredColumn).length === columns.length)) {
      return arrangedColumn
    } else {
      return setSortedColumn(columnName, columns)
    }
  }

  const sortedColumn = screenWiseStoredColumn ? getSortedColumns(columnName, columns) : setSortedColumn(columnName, columns)
  return {sortedColumn, setSortedColumn}
}
export default useLocalStorageTableColumns
