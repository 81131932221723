import Stepper from '@src/@core/components/ui/NewStepper'
import PageHeader from '@src/@core/components/ui/page-header'
import { useEffect, useState } from 'react'
import returnGrnDetailSkeleton from '../../../../../assets/images/inventory/icons/returnGrnDetailSkeleton.svg'
// import IdentifyBin from "./component/IdentifyBin"
// import "./style.scss"
import './createReturnOrderProcessing.scss'
// import GrnSourceIdentify from "./component/grnSourceIdentify"
import { useForm } from 'react-hook-form'
// import { GRN_STEPS, SERIALISATION_OPTION } from "./constant"
import IdentifyBin from '@src/views/inventory/components/inventoryOperations/grn-revamped/component/IdentifyBin'
import {
  clearBinDisabledGrnTableData,
  clearDataAfterSKUScanned,
  clearGrnFailBinId,
  clearGrnPassBinId,
  clearGrnStore,
  // completeGRN,
  setLastScannedBin,
  updateGtinSkuDetails,
  updateIsGtinChanged
} from '@src/views/inventory/store'
import {
  RETURN_ORDER_PROCESSING_STEPS
} from '@src/views/returns/returns.constants'
import { useDispatch, useSelector } from 'react-redux'
import ReturnGrnSourceIdentify from './components/returnGrnSourceIdentify'
// import SkuScan from "./component/skuScan"
// import grnDetailSkeleton from '../../../../../assets/images/inventory/icons/grnDetailEmpty.svg'
// import NewGrnDetails from "./component/newGrnDetails"
import Button from '@src/@core/components/ui/button'
import CustomToast from '@src/@core/components/ui/custom-toast/CustomToast'
import { useApi } from '@src/configs/react-query/useApi'
import CompleteGRNModal from '@src/views/inventory/components/inventoryOperations/grn-revamped/component/completeGRNModal'
import NewGrnDetails from '@src/views/inventory/components/inventoryOperations/grn-revamped/component/newGrnDetails'
import { SERIALISATION_OPTION } from '@src/views/inventory/components/inventoryOperations/grn-revamped/constant'
import { GRN_TYPES } from '@src/views/inventory/inventory.constants'
import {
  clearCreateReturnGrnResponse,
  clearSingleReturnOrderDetail,
  getReturnOrderDetail,
  resetSuccess
} from '@src/views/returns/store'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { Navigate, useNavigate } from 'react-router-dom'
import { Spinner } from 'reactstrap'
import { axiosInstance } from '../../../../../network/AxiosInstance'
import QualityCheck from './components/qualityCheck'
// import { clearGrnFailBinId, clearGrnPassBinId, getDisplayGrnDetail, clearDataAfterSKUScanned, clearGrnStore, setLastScannedBin, completeGRN, clearStoDetails } from "@src/views/inventory/store"
// import { Spinner } from "reactstrap"
// import BulkUploadSerialisedSkus from "./component/bulkUploadSerialisedSkus"
// import { clearEditPoData, clearShipmentsOfSinglePo } from "@src/views/Purchases/store"
// import Button from "@src/@core/components/button"
// import CompleteGRNModal from "./component/completeGRNModal"
// import { useNavigate } from "react-router-dom"

const CreateNewReturnOrderProcessing = () => {
  const {
    control,
    register,
    formState: { errors },
    watch,
    reset,
    setValue,
    setError,
    clearErrors
  } = useForm({
    mode: 'onChange'
  })
  const selectedGlobalHubId = useSelector(store => store.auth.selectedGlobalHubId)
  const returnGrnResponse = useSelector((state) => state.returns.createReturnGrnResponse?.data)
  const processNextReturnOrderLoading = useSelector((state) => state.returns.loading.processNextReturnOrder)
  const createReturnGrnLoading = useSelector((state) => state.returns.loading.createReturnGrn)
  const processNextReturnOrderSuccess = useSelector((state) => state.returns.success.processNextReturnOrder)
  const [isNextOrderProcess, setIsNextOrderProcess] = useState(false)
  const [isQuantityRemaining, setIsQuantityRemaining] = useState(false)
  const configurations = useSelector(store => store.auth.selectedHubConfiguration)
  const isBinDisabled = !configurations?.find(item => item.configuration_type === 'bin')?.configuration_values?.enabled
  const { t } = useTranslation()

  const pageHeaderProps = {
    title: 'Return Order Processing',
    breadcrumbsData: [
      { title: 'Returns' },
      { title: 'Return Order Processing' }
    ]
  }
  const steps = ['Return Order Details', 'QC']

  const [currentStep, setCurrentStep] = useState(
    isBinDisabled ? RETURN_ORDER_PROCESSING_STEPS.QC.id : RETURN_ORDER_PROCESSING_STEPS.RETURN_GRN_DETAILS.id
  )
  const [qty, setQty] = useState(1)
  const [totalItemQtyToScan, setTotalItemQtyToScan] = useState(1)

  const navigate = useNavigate()

  //   const [bulkUploadSkuSidesheetState, setBulkUploadSkuSidesheetState] = useState(false)
  const [isCompleteGRNModalOpen, setIsCompleteGRNModalOpen] = useState(false)
  // const completeGRNResponse = useSelector(
  //   (store) => store.inventory.grn.completeGRNResponse
  // )
  //   const loadingState = useSelector(store => store.inventory.loadingState)
  const sku_details = useSelector(
    (store) => store.inventory.grn.sku_details?.data
  )
  const addSkuInGrnBinResponse = useSelector(
    (store) => store.inventory.grn.addSkuInGrnBinResponse
  )
  const processNextReturnOrderResponse = useSelector(store => store.returns.processNextReturnOrderResponse)
  const dispatch = useDispatch()
  const handleNextStep = () => {
    // dispatch(getDisplayGrnDetail({ grn_id: grnResponse.id }))
    setCurrentStep(RETURN_ORDER_PROCESSING_STEPS.QC.id)
    dispatch(clearGrnPassBinId())
    dispatch(clearGrnFailBinId())
    setIsNextOrderProcess(false)
  }

  const {mutate:getGrnDetails, isPending} = useApi({
    isMutation: true,
    apiKey: ['get-grn-details'],
    apiFn: () => {
      const grn_id = returnGrnResponse.id
      return axiosInstance.put(`/api/v1/wms/hubs/${selectedGlobalHubId}/grns/${grn_id}/complete`, {is_source_modal: false})
    },
    onSuccess: ({data}) => {
      if (data?.remaining_quantity === 0) {
        reset()
        CustomToast('Return Process Completed', {my_type : 'success'})
        if (isBinDisabled) {
          navigate('/order/return/return-order-processing')
        } else {
          setCurrentStep(1)
          navigate('/order/return/return-order-processing/create-return-order-processing')
        }
        dispatch(clearGrnStore())
        dispatch(clearCreateReturnGrnResponse())
        dispatch(clearSingleReturnOrderDetail())
      } else if (data?.remaining_quantity > 0) {
        setIsQuantityRemaining(data?.is_quantity_remaining)
        setIsCompleteGRNModalOpen(true)
      }
    }
  })

  const {mutate:completeFinalGRN, isPending:isCompleteGrnPending} = useApi({
    isMutation: true,
    apiKey: ['complete-final-grn'],
    apiFn: (id) => axiosInstance.put(`/api/v1/wms/hubs/${selectedGlobalHubId}/grns/${id}/complete`, {is_source_modal: true}),
    onSuccess: () => {
      setIsCompleteGRNModalOpen(false)
      reset()
      if (isBinDisabled) {
        navigate('/order/return/return-order-processing')
      } else {
        setCurrentStep(1)
        navigate('/order/return/return-order-processing/create-return-order-processing')
      }
      CustomToast('Return Process Completed', {my_type : 'success'})
      dispatch(clearGrnStore())
      dispatch(clearCreateReturnGrnResponse())
      dispatch(clearSingleReturnOrderDetail())
    }
  })

  const handleCompleteGRN = () => {
    completeFinalGRN(returnGrnResponse.id)
    // dispatch(completeGRN({ grn_id: returnGrnResponse.id }))
  }

  const toggleCompleteGrnModal = () => {
    setIsCompleteGRNModalOpen((pre) => !pre)
  }

  //   useEffect(() => {

  //     if (displayGrnDetails?.id) {
  //       setCurrentStep(prev => prev + 1)
  //     }

  //   }, [displayGrnDetails])

  useEffect(() => {
    // if (completeGRNResponse?.is_success) {
    //   setIsCompleteGRNModalOpen(false)
    //   reset()
    //   setCurrentStep(1)
    //   CustomToast('Return Process Completed', {my_type : 'success'})
    //   navigate(
    //     "/order/return/return-order-processing/create-return-order-processing"
    //   )
    // }
    return () => {
      reset()
      if(!isBinDisabled) {
        setCurrentStep(1)
      }
      dispatch(clearGrnStore())
      dispatch(clearCreateReturnGrnResponse())
      dispatch(clearSingleReturnOrderDetail())
      dispatch(updateGtinSkuDetails({}))
      dispatch(updateIsGtinChanged(false))
    }
  }, [])

  useEffect(() => {
    if (addSkuInGrnBinResponse.is_success) {
      if (
        !(
          addSkuInGrnBinResponse.data.is_sku_not_exist ||
          addSkuInGrnBinResponse.data.is_over_receive
        )
      ) {
        if (
          !(
            sku_details?.serialisation_status ===
              SERIALISATION_OPTION.serialised.id && qty >= 2
          )
        ) {
          dispatch(setLastScannedBin(watch('scanned-bin')))
          dispatch(clearDataAfterSKUScanned())
          dispatch(updateGtinSkuDetails({}))
          dispatch(updateIsGtinChanged(false))
          setTotalItemQtyToScan(1)
          reset()
          setValue('search_barcode', '')
          setValue('search_sku', '')
        }
      }
    }
  }, [addSkuInGrnBinResponse])
  //   // useEffect(() => {
  //   //   return () => {
  //   //     reset()
  //   //     setCurrentStep(1)
  //   //     dispatch(clearGrnStore())
  //   //     dispatch(clearEditPoData())
  //   //     dispatch(clearStoDetail())
  //   //   }
  //   // }, [])

  useEffect(() => {
    if (processNextReturnOrderSuccess && !isBinDisabled) {
      dispatch(getReturnOrderDetail(processNextReturnOrderResponse.oms_return_order_id))
      dispatch(resetSuccess())
    }
  }, [processNextReturnOrderSuccess])

  
  useEffect(() => {

    return () => {
      dispatch(clearBinDisabledGrnTableData())
    }
  }, [])

  if (isBinDisabled && !returnGrnResponse?.id) {
    return <Navigate to='/order/return/return-order-processing' />
  }

  return (
    <div className="return-grn-container">
      <div className="d-flex justify-content-between align-items-center">
        <PageHeader {...pageHeaderProps} />
        {returnGrnResponse && currentStep === 2 && !isBinDisabled && (
          <Button
            onClick={getGrnDetails}
            className='me-28px flex-center-start gap-8px'
            ofStyle="outlined"
            disabled={isPending}
          >
            {isPending ? <Spinner size='sm'/> : null} 
            <span>{t('Complete Process')}</span>
          </Button>
        )}
      </div>
      <div className="border-top py-24px px-40px">
        {isBinDisabled ? null : <div className="d-flex justify-content-center">
          <Stepper steps={steps} currentStep={currentStep} />
        </div>}
        <div className="d-flex gap-40px ">
          <div className="d-flex flex-column gap-24px details-section mt-32px" style={{width: isBinDisabled ? '40%' : '50%'}}>
            {((RETURN_ORDER_PROCESSING_STEPS.RETURN_GRN_DETAILS.id ===
              currentStep) || isNextOrderProcess) && !isBinDisabled && (
              <>
                <ReturnGrnSourceIdentify
                  control={control}
                  register={register}
                  errors={errors}
                  reset={reset}
                  setValue={setValue}
                  watch={watch}
                  isBarcodePrint={
                    watch('pass_bin_barcode') || watch('pass_bin_barcode')
                  }
                  isNextOrderProcess={isNextOrderProcess}
                  setIsNextOrderProcess={setIsNextOrderProcess}
                />
                {(createReturnGrnLoading || processNextReturnOrderLoading) && (
                  <div className="d-flex justify-content-center">
                    {' '}
                    <Spinner className="text-primary width-40px height-40px" />
                  </div>
                )}
                {
                  returnGrnResponse?.grn_id && isBinDisabled && !isNextOrderProcess ? 
                    <Button onClick={handleNextStep}>
                      {t('Start QC')}
                    </Button>
                    : null
                }
                {returnGrnResponse?.grn_id && !isNextOrderProcess && !isBinDisabled && (
                  <IdentifyBin
                    control={control}
                    type={GRN_TYPES.RETURN}
                    setValue={setValue}
                    watch={watch}
                    onClick={handleNextStep}
                    grnResponse={returnGrnResponse}
                  />
                )}
              </>
            )}
            {RETURN_ORDER_PROCESSING_STEPS.QC.id === currentStep && !isNextOrderProcess && (
              <div className="flex-grow-1" style={{width: isBinDisabled ? '90%' : '100%'}}>
                <QualityCheck
                  returnGrnResponse={returnGrnResponse}
                  control={control}
                  reset={reset}
                  setValue={setValue}
                  watch={watch}
                  errors={errors}
                  setCurrentStep={setCurrentStep}
                  qty={qty}
                  setQty={setQty}
                  totalItemQtyToScan={totalItemQtyToScan}
                  setTotalItemQtyToScan={setTotalItemQtyToScan}
                  register={register}
                  setError={setError}
                  clearErrors={clearErrors}
                />
              </div>
            )}
          </div>
          <div
            className={classNames('flex-grow-1', {
              'mt-18px' : !returnGrnResponse,
              'mt-40px pt-38px': isNextOrderProcess,
              'bin-identification': returnGrnResponse && currentStep !== RETURN_ORDER_PROCESSING_STEPS.QC.id,
              'mt-10px' : currentStep === RETURN_ORDER_PROCESSING_STEPS.QC.id
            })}
            style={{width: isBinDisabled ? '60%' : '50%'}}
          >
            {returnGrnResponse ? (
              <NewGrnDetails
                grnDetails={returnGrnResponse}
                type={isBinDisabled ? GRN_TYPES.BIN_DISABLED : GRN_TYPES.RETURN}
                setCurrentStep={setCurrentStep}
                currentStep={currentStep}
                isNextOrderProcess={isNextOrderProcess}
                setIsNextOrderProcess={setIsNextOrderProcess}
                getGrnDetails={getGrnDetails}
                isPending={isPending}
                isReturnOrderProcessing={true}
                isReturnOrder={true}
              />
            ) : (
              <img
                className="w-100"
                src={returnGrnDetailSkeleton}
                alt="empty state"
              />
            )}
          </div>
        </div>
      </div>
      {/* <BulkUploadSerialisedSkus
        bulkUploadSkuSidesheetState={bulkUploadSkuSidesheetState}
        setBulkUploadSkuSidesheetState={setBulkUploadSkuSidesheetState}
            />*/}
      <CompleteGRNModal
        isOpen={isCompleteGRNModalOpen}
        toggle={toggleCompleteGrnModal}
        handleCompleteGRN={handleCompleteGRN}
        isQuantityRemaining={isQuantityRemaining}
        type={GRN_TYPES.RETURN}
        isCompleteGrnPending={isCompleteGrnPending}
      />
    </div>
  )
}

export default CreateNewReturnOrderProcessing
