import { useRTL } from '@src/utility/hooks/useRTL'
import { memo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import './styles.scss'
import { handleFreshdeskScript } from '@src/redux/layout'
import { getFreshDeskAuthToken } from '@src/redux/authentication'
import { useTranslation } from 'react-i18next'
import { HelpCircle } from 'react-feather'

const Freshdesk = () => {
  // const [showWidget, setShowWidget] = useState(false)

  const [isRTL] = useRTL()
  const userData = useSelector((state) => state.auth.userData)
  const freshDeskAuthData = useSelector((state) => state.auth.freshDeskAuthData)
  const isFreshdeskScriptAdded = useSelector((state) => state.layout.isFreshdeskScriptAdded)
  const { t } = useTranslation()
 
  const user = JSON.parse(localStorage.getItem('auth'))
  const dispatch = useDispatch()
  // const fromIds = [151000156456, 151000156457, 151000156458]

  useEffect(() => {
    if (!isFreshdeskScriptAdded && user?.access_token && freshDeskAuthData) {
      // Create the script element
      const script = document.createElement('script')
      // Set the script attributes
      script.async = true
      script.src = 'https://widget.freshworks.com/widgets/151000004659.js'
      window.fwSettings = {
        widget_id: 151000004659
      }

      // Append the script element to the body
      document.body.appendChild(script)
      window.FreshworksWidget('hide', 'launcher')
      dispatch(handleFreshdeskScript())
    }
  }, [])

  useEffect(() => {
    if (user?.access_token && isFreshdeskScriptAdded) {
      window.FreshworksWidget('boot')
      window.FreshworksWidget('hide', 'launcher')
      // window.fwSettings = {
      //   ...window.fwSettings,
      //   locale: isRTL ? "ar" : "en"
      // }

      if (userData && window.FreshworksWidget) {
        // window.FreshworksWidget("setLabels", {
        //   ar: {
        //     banner: "مهلا، مرحبا بكم في الدعم الشامل",
        //     launcher: "يساعد",
        //     contact_form: {
        //       title: "رفع تذكرة",
        //       submit: "يُقدِّم",
        //       confirmation: "شكرا لك على ملاحظاتك."
        //     }
        //   }
        // })
        window.FreshworksWidget('prefill', 'ticketForm', {
          name: userData.name,
          email: userData.email,
          type: 'Question',
          custom_fields: {
            cf_workspace: window.location.hostname
          }
        })
        window.FreshworksWidget('disable', 'ticketForm', ['name', 'email'])
        window.FreshworksWidget('hide', 'ticketForm', ['custom_fields.cf_workspace'])

        const authenticateCallback = async () => {
          const response = await getFreshDeskAuthToken()
          window.FreshworksWidget('authenticate', {
            token: response.access_token
          })
        }

        window.FreshworksWidget('authenticate', {
          token: freshDeskAuthData?.access_token,
          callback: authenticateCallback
        })
      }
    }
    return () => {
      window.FreshworksWidget('destroy')
    }
  }, [isRTL, isFreshdeskScriptAdded])

  function toggleWidget() {
    window.FreshworksWidget('open')
  }

  return (
    <>
      {isRTL && <style>{'#freshworks-frame-wrapper { left: 30px; right: auto !important;'}</style>}
      <button
        className="freshdesk-button"
        onClick={toggleWidget}
      >
        <HelpCircle className="me-50" size={20} />
        {t('Support')}
      </button>
    </>
  )
}

export default memo(Freshdesk)
