import Button from '@src/@core/components/ui/button'
import CustomSearchDropDown from '@src/@core/components/ui/custom-search-dropDown'
import DropdownWithTitle from '@src/@core/components/ui/dropdown'
import SwitchButton from '@src/@core/components/ui/switch-buttons'
import { ADDITIONAL_FILTER_DROPDOWN_STYLE } from '@src/App.constants'
import abilityMap from '@src/assets/data/abilityMapping/abilityMapping'
import RefreshTableIcon from '@src/assets/images/svg/table/refreshTableIcon'
import ability from '@src/configs/acl/ability'
import { useFilters, usePagination, useSearch, useSorting } from '@src/utility/hooks/useFilters'
import { SORT_ORDER } from '@src/views/sales/constant/orders.constants'
import classNames from 'classnames'
import { useContext, useEffect, useState } from 'react'
import { Plus } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { HUBS_VIEW_OPTIONS, HUB_SORT_ORDER } from '../../constants.hubs'
import { getAllCitiesAsyncData, getHubs } from '../../store'
import '../../styles/hubs.scss'
import { UseLocationContext } from '../../utils/contexts/useLocationContext'
import MapView from './MapView'
import TabularView from './TabularView'

const HubsList = () => {
  const dispatch = useDispatch()
  const { navigate, setSearchParams, searchParams } =
    useContext(UseLocationContext)

  const {
    is_success: isGetHubsSuccess,
    loading: hubLoading,
    error,
    data: hubCollection = [],
    meta = {}
  } = useSelector((state) => state.revampedHubs.hubCollection)
  const TableColumnsHeader = [{ id: 'name', name: 'Hub Name' }]
  const [hubsTableData, setHubsTableData] = useState()
  const {sort, setSort} = useSorting()
  const { searchQuery, setSearchQuery } = useSearch({ column: TableColumnsHeader[0] })
  const { filter, setFilter } = useFilters({})
  const { pagination, setPagination } = usePagination()
  const hubsViewId = searchParams.get('view') || HUBS_VIEW_OPTIONS.table.id
  const { t } = useTranslation()
  const isHubCreationAllowed =  ability.can(abilityMap.hub.create.action, abilityMap.hub.create.resource)

  const filterHandler = ({ filterkey, filterValue }) => {
    if (!filterValue) {
      delete filter[filterkey]
      setFilter({})
      return
    }
    setFilter({...filter, [filterkey]: filterValue})
  }

  const handlePagination = page => {
    const pagination = { page: page.selected, per_page: page.per_page ? page.per_page : 10 }
    setPagination(pagination)
  }

  const handleSearchItem = ({ searchVal, selectedColumn = null }) => {
    const searchQuery = searchVal
      ? { column: selectedColumn, query: searchVal }
      : null
    setSearchQuery(searchQuery)
  }

  useEffect(() => {
    const searchParams = searchQuery.query ? { search_column: searchQuery.column.id, search_query: searchQuery.query } : {}
    const sortParams = sort.order ? { sort: sort.order === SORT_ORDER.ASC ? HUB_SORT_ORDER.ASC : HUB_SORT_ORDER.DESC } : {}
    const filterParams = filter.city_id ? {
      city_id: filter.city_id.value,
      city_name: filter.city_id.label
    } : {}
    const params = {
      ...searchParams,
      ...sortParams,
      ...filterParams,
      ...pagination
    }
    if (hubLoading) return
    dispatch(getHubs(params))
  }, [])

  useEffect(() => {
    if (isGetHubsSuccess) {
      const hubsTableDataMapping = hubCollection.map((hubData) => ({
        hubCode: hubData.code,
        hubName: hubData.name,
        hubAddress: `${hubData.address?.address_line1} ${hubData.address?.address_line2} ${hubData.address?.city?.name}, ${hubData.address?.country?.name}`,
        hubEmail: hubData.email,
        contactNumber: hubData.phone_number,
        hubCity: hubData.address?.city?.name,
        hubCountry: hubData.address?.country?.name,
        ...hubData
      }))
      setHubsTableData(hubsTableDataMapping)
    }
  }, [hubCollection])

  const handleViewChange = (view) => {
    if (view && view.id) {
      setSearchParams({ view: view.id })
    }
  }

  const toCreateHub = () => {
    navigate('/hubs', { state: { hubCreate: true } })
  }

  const handleGetTableRefreshedData = () => {
    const searchParams = searchQuery.query ? { search_column: searchQuery.column.id, search_query: searchQuery.query } : {}
    const sortParams = sort.order ? { sort: sort.order === SORT_ORDER.ASC ? HUB_SORT_ORDER.ASC : HUB_SORT_ORDER.DESC } : {}
    const filterParams = filter.city_id ? {
      city_id: filter.city_id.value,
      city_name: filter.city_id.label
    } : {}
    if (hubLoading) return
    const params = {
      ...searchParams,
      ...sortParams,
      ...filterParams,
      ...pagination
    }
    dispatch(getHubs(params))
  }

  console.log({hubsViewId})

  return (
    <div className="p-1 d-flex flex-column view-all-hubs-screen">
      <div className="d-flex justify-content-between pb-24px">
        <DropdownWithTitle
          isAsync
          isClearable={!!filter?.city_id}
          value={(() => {
            if (!filter?.city_id) return {label: 'All', value: ''}
            return filter?.city_id
          })()}
          title="City"
          loadOptionsHandler={getAllCitiesAsyncData}
          selectOptionHandler={(value) => filterHandler({ filterkey: 'city_id', filterValue: value })} 
          externalStyles={ADDITIONAL_FILTER_DROPDOWN_STYLE}
        />
        <SwitchButton
          selectedButton={Object.values(HUBS_VIEW_OPTIONS)?.find(view => +view.id === +hubsViewId)}
          onChange={handleViewChange}
          buttons={Object.values(HUBS_VIEW_OPTIONS)}
        />
      </div>
      {+hubsViewId === +HUBS_VIEW_OPTIONS.map.id  && <div className="w-100 d-flex justify-content-between pb-24px">
        <div>
          <CustomSearchDropDown
            columnsList={TableColumnsHeader}
            search_column_query={searchQuery}
            handleQueryParams={handleSearchItem}
          />
        </div>
        <div className="d-flex align-items-center gap-1">
          <button className="flex-center-center width-40px height-40px border border-dark-2 bg-light-2 rounded-8px" onClick={handleGetTableRefreshedData} disabled={hubLoading}>
            <RefreshTableIcon className={classNames({'hub-view-refresh-button': hubLoading})}/>
          </button>
          {isHubCreationAllowed && <div className="d-flex gap-1 ps-14px border-start border-primary-lighter">
            <Button onClick={toCreateHub} icon={Plus}>
              {t('Create Hub')}
            </Button> 
          </div>}
        </div>
      </div>}
      {+hubsViewId === +HUBS_VIEW_OPTIONS.table.id ? (
        <TabularView
          hubsTableData={hubsTableData}
          meta={meta}
          navigate={navigate}
          setSearchParams={setSearchParams}
          hubLoading={hubLoading}
          success={isGetHubsSuccess}
          error={error}
          setSearchQuery={setSearchQuery}
          searchQuery={searchQuery}
          TableColumnsHeader={TableColumnsHeader}
          sort={sort}
          setSort={setSort}
          setFilter={setFilter}
          filter={filter}
          handlePagination={handlePagination}
          pagination={pagination}
        />
      ) : null}
      {+hubsViewId === +HUBS_VIEW_OPTIONS.map.id ? (
        <MapView
          city={filter.city_id?.label}
          hubsTableData={hubsTableData}
          meta={meta}
          navigate={navigate}
          setSearchParams={setSearchParams}
          hubLoading={hubLoading}
        />
      ) : null}
    </div>
  )
}

export default HubsList