import DropdownWithTitle from '@src/@core/components/ui/dropdown'
import { PICKLIST_STATUS } from '@src/views/sales/picking-wave/constants'
import {
  getPicklistsOfStatus,
  setPicklistStatus
} from '@src/views/sales/store/store'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { components } from 'react-select'
const ZoneFilter = (props) => {
  const { wave, hub_id } = props
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { selectedPicklistStatus, selectedZone } = useSelector((state) => state.sales)
  const handleSeletPicklistStatus = (picklistStatus) => {
    dispatch(setPicklistStatus(picklistStatus))
    dispatch(
      getPicklistsOfStatus({
        type: picklistStatus?.value,
        waveId: wave?.waveId,
        zone: selectedZone?.value?.id,
        hub_id
      })
    )
  }
  const CustomValueContainer = ({ children, ...props }) => {
    const { ValueContainer, Placeholder } = components

    return (
      <ValueContainer {...props}>
        <Placeholder {...props} isFocused={props.isFocused}>
          {props.selectProps.placeholder}
        </Placeholder>
        {React.Children.map(children, (child) => {
          return child && child.type !== Placeholder ? child : null
        })}
      </ValueContainer>
    )
  }
  return (
    <DropdownWithTitle
      name="Picklist Status"
      options={Object.values(PICKLIST_STATUS)}
      value={(() => {
        if (!selectedPicklistStatus) return { value: '', label:'All'}
        return selectedPicklistStatus
      })()}
      isClearable={!!selectedPicklistStatus}
      title={
        t('Picklist Status')
      }
      onChange={handleSeletPicklistStatus}
    />
  )
}
export default ZoneFilter
