import QuantityButton from '@src/@core/components/ui/quantity-button'
import no_sku_image from '@src/assets/images/omniful/no_sku_image.svg'
import {
  removeBundleSkuFromIndex,
  updateQtyOfBundleSkuAtIndex
} from '@src/views/catalog/store'
import { Trash2 } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { REMOVE } from '../../catalog.constants'

export default function SkuDetailsRow({
  idx,
  itemDetails = {},
  disabled,
  setSkuRetailPrice,
  setSkuSellingPrice,
  warningModalRef,
  setWarningValueChangeModal,
  setStoreIndex,
  setQuantityChangeType,
  skuRetailPrice,
  skuSellingPrice,
  setIsSkuDetailsChange,
  setSkuDetailChangedType,
  setStoreDeletedSkuData,
  isDisabled,
  currency
}) {
  const { t } = useTranslation()
  const qty = itemDetails.quantity
  const unitPrice = itemDetails.cost
  const sellingPrice = itemDetails.selling_price || 0
  const retailPrice = itemDetails.retail_price || 0

  const dispatch = useDispatch()

  if (qty === 0) {
    dispatch(updateQtyOfBundleSkuAtIndex({ idx, value: qty + 1 }))
  }

  const decreaseQuantity = () => {
    if (qty > 0) {
      if (
        (warningModalRef.current === 0 && skuSellingPrice) ||
        (warningModalRef.current === 0 && skuRetailPrice)
      ) {
        setQuantityChangeType('dec')
        setWarningValueChangeModal(true)
        setStoreIndex({ idx, qty })
        return
      }
      dispatch(updateQtyOfBundleSkuAtIndex({ idx, value: qty - 1 }))
      setSkuRetailPrice(null)
      setSkuSellingPrice(null)
    }
  }

  const increaseQuantity = () => {
    if (skuSellingPrice || skuRetailPrice) {
      setQuantityChangeType('inc')
      setWarningValueChangeModal(true)
      setStoreIndex({ idx, qty })
      return
    }
    dispatch(updateQtyOfBundleSkuAtIndex({ idx, value: qty + 1 }))
    setSkuRetailPrice(null)
    setSkuSellingPrice(null)
  }

  const onChange = (e) => {
    const number = e.target.value.trim()
    const value = parseInt(number)
    if (e.nativeEvent.data === '+') {
      if (skuSellingPrice || skuRetailPrice) {
        setQuantityChangeType('inc')
        setWarningValueChangeModal(true)
        setStoreIndex({ idx, qty })
        return
      }
      dispatch(updateQtyOfBundleSkuAtIndex({ idx, value: qty + 1 }))
      setSkuSellingPrice(null)
      setSkuRetailPrice(null)
      return
    } else if (e.nativeEvent.data === '-') {
      if (qty > 0) {
        if (skuSellingPrice || skuRetailPrice) {
          setQuantityChangeType('dec')
          setWarningValueChangeModal(true)
          setStoreIndex({ idx, qty })
          return
        }
        dispatch(updateQtyOfBundleSkuAtIndex({ idx, value: qty - 1 }))
        setSkuRetailPrice(null)
        setSkuSellingPrice(null)
      }
      return
    }
    if (/^[1-9]\d*$/.test(value) || e.target.value === '') {
      dispatch(updateQtyOfBundleSkuAtIndex({ idx, value }))
    }
  }

  return (
    <>
      <tr>
        <td className='p-10px trash-icon-td'>
          <div className='trash-icon cursor-pointer'>
            <Trash2
              className='trash-enabled'
              onClick={() => {
                setSkuDetailChangedType(REMOVE)
                if (skuSellingPrice || skuRetailPrice) {
                  setWarningValueChangeModal(true)
                  setIsSkuDetailsChange(true)
                  setStoreDeletedSkuData({ idx, disabled })
                  return
                }
                if (!disabled) dispatch(removeBundleSkuFromIndex(idx))
                setSkuRetailPrice(null)
                setSkuSellingPrice(null)
              }}
            />
          </div>
        </td>
        <td>
          <div>{idx + 1}</div>
        </td>
        <td className='p-10px sku-column-min-width'>
          <div className='d-flex gap-8px'>
            <div className='border border-dark-2 rounded-2 min-height-48px min-width-48px overflow-hidden flex-center-center'>
              <img
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null
                  currentTarget.src = no_sku_image
                }}
                src={itemDetails.image || no_sku_image}
                alt="SKU image"
                className="h-full w-full image-with-cover"
                width={48}
                height={48}
              />
            </div>
            <div className='d-flex flex-column'>
              <span
                className='txt-body-md max-width-200 text-truncate'
              >
                {itemDetails.name}
              </span>
              <span className='mt-4px txt-asst-rg'>
                {t('SKU Code')}: {itemDetails.seller_sku_code}
              </span>
            </div>
          </div>
        </td>
        <td className='flex-center-center flex-column gap-8px bundle-create-quantity-button'>
          <QuantityButton
            decreaseQuantity={decreaseQuantity}
            increaseQuantity={increaseQuantity}
            quantity={qty}
            minQuantityEnabled={qty <= 1}
            maxQuantityEnabled={false}
            onChange={onChange}
            disabled={disabled || isDisabled}
          />
          <span className='txt-asst-rg text-dark-6'>
            {t('Cost Price/Unit:')} {unitPrice || 0} {currency}
          </span>
        </td>

        {/* Retail Price */}
        <td>
          <div className='d-flex flex-column justify-content-start align-items-end mrp-wrapper-value'>
            <div className='d-flex gap-2px align-items-center'>
              <div className='mrp text-dark text-end'>
                {retailPrice
                  ? ((+qty || 0) * +retailPrice).toFixed(+qty ? 2 : 0)
                  : 0}
              </div>
              <div className='text-dark'>{currency}</div>
            </div>
          </div>
        </td>
        {/* Selling Price */}
        <td>
          <div className='d-flex flex-column  justify-content-start align-items-end mrp-wrapper-value'>
            <div className='d-flex gap-2px align-items-center'>
              <div className='mrp text-dark text-end'>
                {sellingPrice
                  ? ((+qty || 0) * +sellingPrice).toFixed(+qty ? 2 : 0)
                  : 0}
              </div>
              <div className='text-dark'>
                {currency}
              </div>
            </div>
          </div>
        </td>
      </tr>
    </>
  )
}
