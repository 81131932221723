import SideSheet from '@src/@core/components/ui/sideSheet'
import Shipment from '@src/views/sales/create'
import { clearShippingDetailData, clearSingleOrderDetailsData } from '@src/views/sales/store/store'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { ModalBody } from 'reactstrap'
import { clearGetPrioritisedShippingPartners } from '../../../store/store'

const CreateShipmentSideSheet = (props) => {
  const { sellerId, shipmentSlider, setShipmentSlider } = props
  const dispatch = useDispatch()
  const {t} = useTranslation()

  const handleCloseScrolll = () => {
    setShipmentSlider(false)
  }

  const handleSideBarClosed = () => {
    dispatch(clearShippingDetailData())
    dispatch(clearGetPrioritisedShippingPartners())
    dispatch(clearSingleOrderDetailsData())
  }
    
  return (
    <SideSheet
      isOpen={shipmentSlider}
      toggle={handleCloseScrolll}
      onClosed={handleSideBarClosed}
      modalClassName="modal-slide-in sidebar-todo-modal"
      contentClassName="p-0"
      unmountOnClose={true}
      size="lg"
      title={t('Create Shipment')}
    >
      <ModalBody className="py-2 m-0 overflow-auto">
        <div className="mb-2 h-100 w-100">
          <Shipment sellerId={sellerId} />
        </div>
      </ModalBody>
    </SideSheet>
  )
}
export default memo(CreateShipmentSideSheet)