const LiveOrder = ({ iswhite, fillColor, height, width }) => {
  const color = iswhite ? 'white' : fillColor
  return (
    <svg width={width} height={height} viewBox="0 0 77 77" fill={'none'} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M36.4211 28.5162V15C36.4211 12.7909 34.6302 11 32.4211 11H12C9.79086 11 8 12.7909 8 15V35.4211C8 37.6302 9.79086 39.4211 12 39.4211H26.1418"
        stroke={color}
        strokeWidth="2.4"
      />
      <path
        d="M18.8013 11.252V24.1748C18.8013 24.6071 19.3128 24.8357 19.6348 24.5473L21.86 22.5546C22.0499 22.3846 22.3373 22.3846 22.5271 22.5546L24.7523 24.5473C25.0743 24.8357 25.5859 24.6071 25.5859 24.1748V11.252"
        stroke={color}
        strokeWidth="2.4"
      />
      <circle cx="46.4211" cy="46.2632" r="20.3789" stroke={color} strokeWidth="2.4" />
      <circle cx="46.7759" cy="46.3295" r="2.77661" stroke={color} strokeWidth="2.4" />
      <path
        d="M41.1457 40.8947C39.7398 42.3124 38.8708 44.2672 38.8708 46.4258C38.8708 48.5844 39.7398 50.5392 41.1457 51.9569M52.2131 51.9569C53.619 50.5392 54.488 48.5844 54.488 46.4258C54.488 44.2672 53.619 42.3124 52.2131 40.8947"
        stroke={color}
        strokeWidth="2.4"
        strokeLinecap="round"
      />
      <path
        d="M37.9459 37.3158C35.7024 39.6091 34.3158 42.7713 34.3158 46.2632C34.3158 49.755 35.7024 52.9172 37.9459 55.2105M55.6058 55.2105C57.8492 52.9172 59.2359 49.755 59.2359 46.2632C59.2359 42.7713 57.8492 39.6091 55.6058 37.3158"
        stroke={color}
        strokeWidth="2.4"
        strokeLinecap="round"
      />
    </svg>
  )
}
export default LiveOrder