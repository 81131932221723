import CustomToast from '@src/@core/components/ui/custom-toast/CustomToast'
import InputField from '@src/@core/components/ui/input-field'
import SkuItemSkeleton from '@src/@core/components/ui/skeleton/sku-item'
import { KEYBOARD_KEYS } from '@src/App.constants'
import no_sku_image from '@src/assets/images/omniful/no_sku_image.svg'
import { useApi } from '@src/configs/react-query/useApi'
import { axiosInstance } from '@src/network/AxiosInstance'
import { debounceAction } from '@src/utility/Utils'
import { clearAddItemInGrnBinResponse, clearBinStatus, clearGetSkusByNameAndCodeResponse, clearLastScannedSKUComplete, clearScannedSkuDetails, getSkusByNameAndCode, setBinDisabledGrnTableData, setScannedSkuDetails, updateGtinSkuDetails, updateIsShelfLifeBreached } from '@src/views/inventory/store'
import classNames from 'classnames'
import { forwardRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import PerfectScrollBar from 'react-perfect-scrollbar'
import { useDispatch, useSelector } from 'react-redux'
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledTooltip } from 'reactstrap'
import { BATCH_STATUS, SERIALISATION_OPTION } from '../../constant'
import GtinWarningModal from '../gtinWarningModal'
import OverReceiveItemModal from './overReceiveItemModal'
import SkuNotPartOfOrderModal from './skuNotPartOfOrderModal'


const SearchSkuByCode = forwardRef(({ register, watch, setValue }, ref) => {

  const {
    grnResponse, 
    loading, 
    getSkusByNameAndCodeResponse, 
    configurations, 
    tableData, 
    sku_details, 
    displayGrnDetails, 
    singleGrnDetail,
    gtinSkuDetails,
    userData
  } = useSelector(store => ({
    grnResponse: store.inventory.grn.grnResponse,
    loading: store.inventory.loadingState,
    getSkusByNameAndCodeResponse: store.inventory.grn.getSkusByNameAndCodeResponse,
    configurations: store.auth.selectedHubConfiguration,
    tableData: store.inventory.binDisabledGrn.tableData,
    sku_details: store.inventory.grn.sku_details?.data,
    displayGrnDetails: store.inventory.grn.displayGrnDetails,
    singleGrnDetail: store.inventory.grn.singleGrnDetail,
    gtinSkuDetails: store.inventory.gtinSkuDetails,
    userData: store.auth.userData
  }))
  const [showAddItemsDropdown, setShowAddItemsDropdown] = useState(false)
  const dispatch = useDispatch()
  const isBinDisabled = !configurations?.find(item => item.configuration_type === 'bin')?.configuration_values?.enabled
  const [mainSkuDetails, setMainSkuDetails] = useState({})
  const [isOverReceiveModalOpen, setIsOverReceiveModalOpen] = useState(false)
  const [isSkuNotExistModalOpen, setIsSkuNotExistModalOpen] = useState(false)
  const entityID = singleGrnDetail?.is_stock_transfer_order ? singleGrnDetail.entity_id : singleGrnDetail?.is_purchase_order && !singleGrnDetail.is_po_over_receive ? singleGrnDetail.entity_id : undefined
  const entityType = singleGrnDetail?.is_stock_transfer_order ? singleGrnDetail.entity_type : singleGrnDetail?.is_purchase_order && !singleGrnDetail.is_po_over_receive ? singleGrnDetail.entity_type : undefined
  const [isGtinModalOpen, setIsGtinModalOpen] = useState(false)
  const [tempSkuDetails, setTempSkuDetails] = useState({})
  const isTenantIdFour = userData.tenant.id === 4

  const { mutate: updateBinDisabledSkuDetails } = useApi({
    isMutation: true,
    apiKey: ['update-no-over-but-exist-Bin-Disabled-Sku-Details-non-serialised-skus'],
    apiFn: ({passQty, reasons}) => {

      const reasonsToSend = Array.isArray(reasons) && reasons?.length ? 
        reasons.map((reason) => ({
          ...reason,
          images:[],
          upload_ids:[]
        }))
        : []

      const body = {
        pass_quantity: (parseInt(passQty) + 1),
        seller_sku_code: mainSkuDetails?.sku?.seller_sku_code,
        over_receive: isOverReceiveModalOpen,
        return_order_id: undefined,
        reasons: reasonsToSend
      }
      return axiosInstance.post(`/api/v1/wms/hubs/${grnResponse?.hub_id}/grns/${grnResponse?.id}`, body)
    },
    onSuccess: (data) => {
      if (data?.data?.is_over_receive || data?.data?.is_sku_not_exist) {
        if (data?.data?.is_over_receive) {
          setIsOverReceiveModalOpen(true)
        } else {
          setIsSkuNotExistModalOpen(true)
        }
      } else {

        const isSkuPresentInTable = tableData.find(item => item.seller_sku_id === sku_details?.seller_sku_id)
        if (!displayGrnDetails?.is_purchase_order && !displayGrnDetails?.is_sto && !isSkuPresentInTable) {
          dispatch(setScannedSkuDetails({
            data: {
              ...sku_details,
              pass_qty: 1,
              reasons:[],
              scanned_qty: 1
            }
          }))
  
          const dataToPush = {
            id: sku_details?.id,
            name: sku_details?.name,
            code: sku_details?.seller_sku_code,
            image: sku_details?.images?.[0]?.url,
            is_serialised: sku_details?.sku_config?.is_serialisation_enable,
            seller_sku_id: sku_details?.seller_sku_id,
            pass_qty: 1,
            fail_qty: 0,
            total_qty: 0,
            scanned_qty: 1,
            purchase_order_id: sku_details?.purchase_order_id,
            seller_id: sku_details?.seller_id,
            reasons:[]
          }
          const updateTableData = [...tableData, dataToPush]
          dispatch(setBinDisabledGrnTableData(updateTableData))
        } else {
          dispatch(setScannedSkuDetails({
            data: {
              ...sku_details,
              pass_qty:sku_details?.pass_qty + 1,
              scanned_qty:sku_details?.scanned_qty + 1
            }
          }))

          const updateTableData = tableData.map((item) => {
            return item.seller_sku_id === sku_details?.seller_sku_id ? { ...item, pass_qty: item.pass_qty + 1, scanned_qty: item.scanned_qty + 1 } : item
          })
          dispatch(setBinDisabledGrnTableData(updateTableData))
        }
        CustomToast('1 Item passed successfully', { my_type: 'success', duration:2000 })
      }
    }
  })

  const { mutate: updateBinDisabledSkuDetailsFromModal, isPending:loadingState } = useApi({
    isMutation: true,
    apiKey: ['update-over-or-not-exist-Bin-Disabled-Sku-Details-for-modal-non-serialised-skus'],
    apiFn: () => {

      const reasonsToSend = Array.isArray(sku_details?.reasons) && sku_details?.reasons?.length ? 
        sku_details.reasons.map((reason) => ({
          ...reason,
          images:[],
          upload_ids:[]
        }))
        : []

      const body = {
        pass_quantity: (parseInt(sku_details?.pass_qty) + 1),
        seller_sku_code: mainSkuDetails?.sku?.seller_sku_code,
        over_receive: isOverReceiveModalOpen || isSkuNotExistModalOpen,
        return_order_id: undefined,
        reasons: reasonsToSend
      }
      return axiosInstance.post(`/api/v1/wms/hubs/${grnResponse?.hub_id}/grns/${grnResponse?.id}`, body)
    },
    onSuccess: () => {
      if (isSkuNotExistModalOpen) {
        const isSkuPresentInTable = tableData.find(item => item.seller_sku_id === sku_details?.seller_sku_id) 
        if (isSkuPresentInTable) {
          dispatch(setScannedSkuDetails({
            data: {
              ...sku_details,
              pass_qty:sku_details?.pass_qty + 1,
              scanned_qty:sku_details?.scanned_qty + 1
            }
          }))
  
          const updateTableData = tableData.map((item) => {
            return item.seller_sku_id === sku_details?.seller_sku_id ? { ...item, pass_qty: item.pass_qty + 1, scanned_qty: item.scanned_qty + 1 } : item
          })
          dispatch(setBinDisabledGrnTableData(updateTableData))
        } else {
          dispatch(setScannedSkuDetails({
            data: {
              ...sku_details,
              pass_qty: 1,
              scanned_qty: 1,
              reasons:[]
            }
          }))
  
          const dataToPush = {
            id: sku_details?.id,
            name: sku_details?.name,
            code: sku_details?.seller_sku_code,
            image: sku_details?.images?.[0]?.url,
            is_serialised: sku_details?.sku_config?.is_serialisation_enable,
            seller_sku_id: sku_details?.seller_sku_id,
            pass_qty: 1,
            scanned_qty: 1,
            fail_qty: 0,
            total_qty: sku_details?.quantity || 0,
            purchase_order_id: sku_details?.purchase_order_id,
            seller_id: sku_details?.seller_id,
            reasons:[]
          }
          const updateTableData = [...tableData, dataToPush]
          dispatch(setBinDisabledGrnTableData(updateTableData))
        }
      }

      if (isOverReceiveModalOpen) {

        dispatch(setScannedSkuDetails({
          data: {
            ...sku_details,
            pass_qty:sku_details?.pass_qty + 1,
            scanned_qty:sku_details?.scanned_qty + 1
          }
        }))

        const updateTableData = tableData.map((item) => {
          return item.seller_sku_id === sku_details?.seller_sku_id ? { ...item, pass_qty: item.pass_qty + 1, scanned_qty: item.scanned_qty + 1 } : item
        })
        dispatch(setBinDisabledGrnTableData(updateTableData))
      }
      CustomToast('1 Item passed successfully', { my_type: 'success', duration:2000 })
      setIsSkuNotExistModalOpen(false)
      setIsOverReceiveModalOpen(false)
    }
  })

  const { mutate: getUpdatedSkuDetails } = useApi({
    isMutation: true,
    apiKey: ['get-Updated-Sku-Details-search-sku-by-code'],
    apiFn: (seller_sku_id) => {
      return axiosInstance.get(`/api/v1/wms/hubs/${grnResponse?.hub_id}/grns/${grnResponse?.id}/skus/${seller_sku_id}`)
    },
    onSuccess: (data) => {
      if (mainSkuDetails?.config?.is_batching_enable === false) {
        const previousSkuDetails = tableData?.find(item => item.seller_sku_id === mainSkuDetails?.sku?.id)
        dispatch(setScannedSkuDetails({
          data: {
            tenant_id: mainSkuDetails?.sku?.tenant_id,
            seller_id: mainSkuDetails?.sku?.seller_id,
            seller_sku_id: mainSkuDetails?.sku?.id,
            seller_sku_code: mainSkuDetails?.sku?.seller_sku_code,
            images: mainSkuDetails?.sku?.images,
            barcodes: mainSkuDetails?.sku?.barcodes || [],
            name: mainSkuDetails?.sku?.name,
            weight: mainSkuDetails?.sku?.weight,
            package_type: mainSkuDetails?.sku?.package_type,
            child_sku: mainSkuDetails?.sku?.package_level_skus?.[0],
            serialisation_status: mainSkuDetails?.config?.is_serialisation_enable === null ? 'undefined' : mainSkuDetails?.config?.is_serialisation_enable === true ? SERIALISATION_OPTION.serialised.id : SERIALISATION_OPTION.non_serialised.id,
            batch_status: mainSkuDetails.config.is_batching_enable === null ? 'undefined' : BATCH_STATUS.NOT_BATCHED,
            is_configuration_editable: mainSkuDetails?.config?.is_configuration_editable,
            dimensions: mainSkuDetails?.sku?.dimensions,
            pass_qty: previousSkuDetails ? previousSkuDetails.pass_qty : 0,
            fail_qty: previousSkuDetails ? previousSkuDetails.fail_qty : 0,
            reasons: previousSkuDetails ? previousSkuDetails.reasons : [],
            pass_barcodes: previousSkuDetails ? previousSkuDetails.pass_barcodes : [],
            fail_barcodes: previousSkuDetails ? previousSkuDetails.fail_barcodes : [],
            grn_details:data?.data?.grn_details,
            scanned_qty: previousSkuDetails ? (previousSkuDetails.pass_qty + previousSkuDetails.fail_qty) : 0
          }
        }
        ))
      } else if (mainSkuDetails?.config?.is_batching_enable === true) {
        dispatch(setScannedSkuDetails({
          data: {
            tenant_id: mainSkuDetails?.sku?.tenant_id,
            seller_id: mainSkuDetails?.sku?.seller_id,
            seller_sku_id: mainSkuDetails?.sku?.id,
            seller_sku_code: mainSkuDetails?.sku?.seller_sku_code,
            images: mainSkuDetails?.sku?.images,
            barcodes: mainSkuDetails?.sku?.barcodes || [],
            name: mainSkuDetails?.sku?.name,
            weight: mainSkuDetails?.sku?.weight,
            package_type: mainSkuDetails?.sku?.package_type,
            child_sku: mainSkuDetails?.sku?.package_level_skus?.[0],
            serialisation_status: mainSkuDetails?.config?.is_serialisation_enable === null ? 'undefined' : mainSkuDetails?.config?.is_serialisation_enable === true ? SERIALISATION_OPTION.serialised.id : SERIALISATION_OPTION.non_serialised.id,
            batch_status: BATCH_STATUS.BATCHED,
            is_configuration_editable: mainSkuDetails?.config?.is_configuration_editable,
            dimensions: mainSkuDetails?.sku?.dimensions,
            pass_qty: 0,
            fail_qty: 0,
            pass_barcodes: [],
            batch: {},
            fail_barcodes:[],
            grn_details: data?.data?.grn_details,
            scanned_qty: 0,
            min_shelf_life: mainSkuDetails?.config?.min_shelf_life
          }
        }
        ))
      } else {
        dispatch(setScannedSkuDetails({
          data: {
            tenant_id: mainSkuDetails?.sku?.tenant_id,
            seller_id: mainSkuDetails?.sku?.seller_id,
            seller_sku_id: mainSkuDetails?.sku?.id,
            seller_sku_code: mainSkuDetails?.sku?.seller_sku_code,
            images: mainSkuDetails?.sku?.images,
            barcodes: mainSkuDetails?.sku?.barcodes || [],
            name: mainSkuDetails?.sku?.name,
            weight: mainSkuDetails?.sku?.weight,
            package_type: mainSkuDetails?.sku?.package_type,
            child_sku: mainSkuDetails?.sku?.package_level_skus?.[0],
            serialisation_status: undefined,
            batch_status: undefined,
            is_configuration_editable: mainSkuDetails?.config?.is_configuration_editable,
            dimensions: mainSkuDetails?.sku?.dimensions,
            pass_qty: 0,
            fail_qty: 0,
            pass_barcodes: [],
            fail_barcodes:[],
            grn_details: [],
            scanned_qty: 0
          }
        }
        ))
      }

      if (mainSkuDetails?.config?.is_batching_enable !== null && mainSkuDetails?.config?.is_serialisation_enable !== null) {
        if (!mainSkuDetails?.config?.is_batching_enable && !mainSkuDetails?.config?.is_serialisation_enable) {
          updateBinDisabledSkuDetails({passQty:data?.data?.grn_details?.[0]?.pass_quantity || 0, reasons:data?.data?.grn_details?.[0]?.reasons})
        }
      }
    }
  })

  const { t } = useTranslation()

  const isLastPage = getSkusByNameAndCodeResponse?.meta.current_page === getSkusByNameAndCodeResponse?.meta.last_page

  const handleShowAddItemsDropdown = (data) => {
    setShowAddItemsDropdown(data)
  }

  const debouncedDispatch = debounceAction(payload => dispatch(getSkusByNameAndCode(
    { 
      query: payload, 
      seller_id: grnResponse.seller_id, 
      params:
      { 
        grn_id: grnResponse?.id,
        entity_id: entityID,
        entity_type:entityType 
      }
    }
  )), 500)

  const handleLoadMoreSku = () => {
    dispatch(getSkusByNameAndCode(
      { 
        query: watch('search_sku'), 
        seller_id: grnResponse.seller_id, 
        params: 
        { 
          grn_id: grnResponse?.id, 
          page: getSkusByNameAndCodeResponse?.meta.current_page + 1, per_page: 20,
          entity_id: entityID,
          entity_type:entityType 
        } 
      }))
  }

  function handleScroll(container) {
    const { scrollTop, clientHeight, scrollHeight } = container
    if (
      scrollHeight - scrollTop === clientHeight &&
      clientHeight !== 0 &&
      scrollTop !== 0 &&
      !isLastPage &&
      loading.getSkusByNameAndCode
    ) {
      handleLoadMoreSku()
    }
  }

  const handleChangeSearchSku = (e) => {
    const value = e.target.value.trim()
    const event = {
      target: { ...e, name: 'search_sku' }
    }
    setValue('selected_batch', null)
    setValue('scanned-bin', '')
    setValue('omniful-sku-barcode', '')
    dispatch(clearAddItemInGrnBinResponse())
    dispatch(clearLastScannedSKUComplete())
    dispatch(clearBinStatus())
    dispatch(clearGetSkusByNameAndCodeResponse())
    dispatch(clearScannedSkuDetails())
    // registeredSearchSku.onChange(event)
    if (value) {
      debouncedDispatch(value)
      handleShowAddItemsDropdown(true)
    } else {
      handleShowAddItemsDropdown(false)
    }
  }
  const handleSelectSku = skuDetails => {
    if (gtinSkuDetails?.id && gtinSkuDetails.is_gtin_enabled && gtinSkuDetails.batch_status === BATCH_STATUS.BATCHED && gtinSkuDetails.serialisation_status === SERIALISATION_OPTION.non_serialised.id && !tempSkuDetails?.sku?.id) {
      setTempSkuDetails(skuDetails)
      setIsGtinModalOpen(true)
      return
    }

    dispatch(updateIsShelfLifeBreached(false))
    if (isBinDisabled) {
      setMainSkuDetails(skuDetails)
      getUpdatedSkuDetails(skuDetails.sku.id)
    } else {
      let batchDetails
      const initialBatch = skuDetails.sku.batch || {}

      if (skuDetails.sku.is_gtin_picking_enabled && skuDetails.sku.batch?.id) {
        batchDetails = {
          id:initialBatch.id,
          label:initialBatch.external_batch_id,
          external_batch_id:initialBatch.external_batch_id,
          expiry_date:initialBatch.expiry_date,
          value: {batch_num:initialBatch.external_batch_id, expiry_date:initialBatch.expiry_date}
        }
      }

      dispatch(setScannedSkuDetails({
        data: {
          tenant_id: skuDetails.sku.tenant_id,
          seller_id: skuDetails.sku.seller_id,
          seller_sku_id: skuDetails.sku.id,
          seller_sku_code: skuDetails.sku.seller_sku_code,
          images: skuDetails.sku.images,
          barcodes: skuDetails.sku.barcodes || [],
          name: skuDetails.sku.name,
          weight: skuDetails.sku.weight,
          package_type: skuDetails.sku.package_type,
          child_sku: skuDetails.sku.package_level_skus?.[0],
          serialisation_status: skuDetails.config.is_serialisation_enable === null ? 'undefined' : skuDetails.config.is_serialisation_enable === true ? SERIALISATION_OPTION.serialised.id : SERIALISATION_OPTION.non_serialised.id,
          batch_status: skuDetails.config.is_batching_enable === null ? 'undefined' : skuDetails.config.is_batching_enable === true ? BATCH_STATUS.BATCHED : BATCH_STATUS.NOT_BATCHED,
          is_configuration_editable: skuDetails.config.is_configuration_editable,
          dimensions: skuDetails.sku.dimensions,
          is_gtin_enabled: skuDetails.sku.is_gtin_picking_enabled && batchDetails?.id,
          batch: skuDetails.sku.is_gtin_enabled && batchDetails?.id ? batchDetails : null,
          sourceType:true,
          min_shelf_life: skuDetails.config.min_shelf_life
        }
      }))
    }
    setValue('search_sku', skuDetails.sku.seller_sku_code)
    handleShowAddItemsDropdown(false)
    setTempSkuDetails({})
  }
  const handleSelectSkuOnKeyDown = (e, skuDetails) => {
    if (e.key === KEYBOARD_KEYS.ENTER) {
      handleSelectSku(skuDetails)
    }
  }

  const handleChangeQty = () => {
    setIsOverReceiveModalOpen(p => !p)
  }

  const handleSaveAnyway = () => {
    updateBinDisabledSkuDetailsFromModal()
  }

  const handleSkipSku = () => {
    setValue('search_sku', '')
    setValue('search_barcode', '')
    setValue('selected_batch', null)
    setIsSkuNotExistModalOpen(false)
    dispatch(clearScannedSkuDetails())
  }

  const handleGtinGoBack = () => {
    ref.current?.focus()
    setTempSkuDetails({})
    setIsGtinModalOpen(false)
  }

  const handleGtinSkip = async () => {
    await dispatch(updateGtinSkuDetails({}))
    handleSelectSku(tempSkuDetails)
    setIsGtinModalOpen(false)
  }

  const registeredSearchSku = register('search_sku', { onChange: handleChangeSearchSku })

  return (
    <>
      <div className='search-sku-code'>
        <InputField
          placeholder={t('Search Name/SKU Code')}
          name='search_sku'
          isClearable
          {...registeredSearchSku}
          // ref={(e) => {
          //   register('search_sku').ref(e)
          //   ref.current = e
          // }}
          // onChange={handleChangeSearchSku}
        />
        {showAddItemsDropdown && <Dropdown
          isOpen={showAddItemsDropdown}
          toggle={() => handleShowAddItemsDropdown(false)
          }
          className="p-0 m-0"
        >
          <DropdownToggle className="invisible p-0"></DropdownToggle>
          {loading.getSkusByNameAndCode ? (
            <SkuItemSkeleton width="100%" />
          ) : (
            <>
              {getSkusByNameAndCodeResponse?.data.length ? (
                <DropdownMenu className='px-12px w-100 dropdown-menu-search-sku'>
                  <PerfectScrollBar onYReachEnd={handleScroll}>
                    {getSkusByNameAndCodeResponse?.data.map((skuDetails, id) => {
                      return (
                        <>
                          <button
                            className={classNames('py-12px d-flex gap-12px align-items-center btn btn-link text-dark w-100 text-start search-sku-item', {
                              'border-bottom': id < getSkusByNameAndCodeResponse.data.length - 1,
                              'gtin-sku': skuDetails.sku.is_gtin_picking_enabled && !isTenantIdFour
                            })}
                            onClick={() => skuDetails.sku.is_gtin_picking_enabled && !isTenantIdFour ? null : handleSelectSku(skuDetails)}
                            onKeyDown={(e) => handleSelectSkuOnKeyDown(e, skuDetails)}
                            key={id}
                            id={`gtin${skuDetails.sku.id}sku`}
                          >
                            <img src={skuDetails.sku.images?.[0]?.default || no_sku_image} onError={({ currentTarget }) => {
                              currentTarget.onerror = null
                              currentTarget.src = no_sku_image
                            }}
                            alt="no img" className="height-50px width-50 align-self-center rounded-8px" />
                            <div className='d-flex flex-column gap-2px'>
                              <div className={classNames('txt-asst-md', {
                                'text-dark': !skuDetails.sku.is_gtin_picking_enabled || isTenantIdFour,
                                'text-dark-4': skuDetails.sku.is_gtin_picking_enabled && !isTenantIdFour
                              })}>{skuDetails.sku.name}</div>
                              <div className={classNames('txt-asst-rg', {
                                'text-dark-6': !skuDetails.sku.is_gtin_picking_enabled || isTenantIdFour,
                                'text-dark-4': skuDetails.sku.is_gtin_picking_enabled && !isTenantIdFour
                              })}>{t('SKU Code')}: {skuDetails.sku.seller_sku_code} {skuDetails.sku.weight.display_weight && `• ${skuDetails.sku.weight.display_weight}`}</div>
                            </div>
                          </button>
                          {skuDetails.sku.is_gtin_picking_enabled && !isTenantIdFour ? <UncontrolledTooltip
                            placement="bottom"
                            target={`gtin${skuDetails.sku.id}sku`}
                            offset={[0, 5]}
                          >
                            {t('GTIN SKUs can only be processed via barcode scanning.')}
                          </UncontrolledTooltip> : null}
                        </>)
                    })}
                  </PerfectScrollBar>
                </DropdownMenu>
              ) : getSkusByNameAndCodeResponse?.data.length === 0 &&
              (
                <DropdownMenu className='w-100'>
                  <DropdownItem
                    className="sku-cards w-100"
                  >
                    <div className="text-center">No record found !</div>
                  </DropdownItem>
                </DropdownMenu>
              )}
            </>
          )}
        </Dropdown>}
      </div>
      <OverReceiveItemModal
        isOpen={isOverReceiveModalOpen}
        toggle={() => setIsOverReceiveModalOpen(p => !p)}
        handleChangeQuantity={handleChangeQty}
        handleSaveAnyway={handleSaveAnyway}
        loading={loadingState}
      />

      <SkuNotPartOfOrderModal
        isOpen={isSkuNotExistModalOpen}
        toggle={() => setIsSkuNotExistModalOpen(p => !p)}
        handleSaveAnyway={handleSaveAnyway}
        handleSkipThisSku={handleSkipSku}
        loading={loadingState}
      />

      <GtinWarningModal
        isOpen={isGtinModalOpen}
        handleGoBack={handleGtinGoBack}
        handleSkip={handleGtinSkip}
      />
    </>
  )
})

export default SearchSkuByCode