// import { Truck, ShoppingBag, Circle, FileText } from 'react-feather'
// import Omniful from '../../assets/images/omniful/omniful-logo.svg'
import Home from '../../assets/images/icons/primary-navbar/Home'
import Hub from '../../assets/images/icons/primary-navbar/Hub'
import Inventory from '../../assets/images/icons/primary-navbar/Inventory'
import Sales from '../../assets/images/icons/primary-navbar/Sales'
import Settings from '../../assets/images/icons/primary-navbar/Settings'
import Catalog from '../../assets/images/icons/primary-navbar/catalog'
import Purchase from '../../assets/images/icons/primary-navbar/purchase'
// import Logout from '@src/assets/images/icons/primary-navbar/Logout'
// import HelpCentre from '@src/assets/images/icons/primary-navbar/HelpCentre'
import LiveOrder from '@src/assets/images/icons/primary-navbar/secondary-navbar/LiveOrder'
import PendingOrder from '@src/assets/images/icons/primary-navbar/secondary-navbar/PendingOrder'
// import Shipment from '@src/assets/images/icons/primary-navbar/secondary-navbar/Shipment'
// import Returns from "@src/assets/images/icons/primary-navbar/secondary-navbar/Returns"
import InventoryOperations from '@src/assets/images/icons/primary-navbar/secondary-navbar/InventoryOperations'
import InventoryReports from '@src/assets/images/icons/primary-navbar/secondary-navbar/InventoryReports'
import OmnifulCatalog from '@src/assets/images/icons/primary-navbar/secondary-navbar/OmnifulCatalog'
// import BulkImporting from '@src/assets/images/icons/primary-navbar/secondary-navbar/BulkImporting'
import AppsAndIntegration from '@src/assets/images/icons/primary-navbar/secondary-navbar/AppsAndIntegration'
import MangaeInvoice from '@src/assets/images/icons/primary-navbar/secondary-navbar/ManageInvoice'
import UserProfile from '@src/assets/images/icons/primary-navbar/secondary-navbar/UserProfile'
import UserSetting from '@src/assets/images/icons/primary-navbar/secondary-navbar/UserSetting'
// import OmnifulAppSettings from "@src/assets/images/icons/primary-navbar/secondary-navbar/OmnifulAppSettings"
import AdHocActivities from '@src/assets/images/icons/primary-navbar/secondary-navbar/AdHocActivities'
import CatalogSettings from '@src/assets/images/icons/primary-navbar/secondary-navbar/CatalogSettings'
import OrderSettings from '@src/assets/images/icons/primary-navbar/secondary-navbar/OrderSettings'
import RoleSettings from '@src/assets/images/icons/primary-navbar/secondary-navbar/RoleSettings'
import ThreeBillingPL from '@src/assets/images/icons/primary-navbar/secondary-navbar/ThreeBillingPL'
// import Location from '@src/assets/images/icons/primary-navbar/secondary-navbar/Location'
// import Bin from '@src/assets/images/icons/primary-navbar/secondary-navbar/Bin'
import HubAssets from '@src/assets/images/icons/primary-navbar/secondary-navbar/HubAssets'
import HubSetup from '@src/assets/images/icons/primary-navbar/secondary-navbar/HubSetup'
// import HubOverview from '@src/assets/images/icons/primary-navbar/secondary-navbar/HubOverview.js'
// import HubLayout from '@src/assets/images/icons/primary-navbar/secondary-navbar/HubLayout.js'
// import Zones from '@src/assets/images/icons/primary-navbar/secondary-navbar/Zones.js'
import abilityMap from '@src/assets/data/abilityMapping/abilityMapping'
import Return from '@src/assets/images/icons/primary-navbar/Return'
import CreateOrder from '@src/assets/images/icons/primary-navbar/secondary-navbar/CreateOrder'
import CreatePickingWave from '@src/assets/images/icons/primary-navbar/secondary-navbar/CreatePickingWave'
import ManifestList from '@src/assets/images/icons/primary-navbar/secondary-navbar/ManifestList'
import SalesChannel from '@src/assets/images/icons/primary-navbar/secondary-navbar/SalesChannel'
import Shipments from '@src/assets/images/icons/primary-navbar/secondary-navbar/Shipments'
import StockTransferOrder from '@src/assets/images/icons/primary-navbar/secondary-navbar/StockTransferOrder'
import CreateReturnOrder from '@src/assets/images/icons/primary-navbar/secondary-navbar/returns/CreateReturnOrder'
import CreateReturnRequest from '@src/assets/images/icons/primary-navbar/secondary-navbar/returns/CreateReturnRequest'
import ReturnGateEntry from '@src/assets/images/icons/primary-navbar/secondary-navbar/returns/ReturnGateEntry'
import ReturnOrder from '@src/assets/images/icons/primary-navbar/secondary-navbar/returns/ReturnOrder'
import ReturnOrderProcessing from '@src/assets/images/icons/primary-navbar/secondary-navbar/returns/ReturnOrderProcessing'
import RerurnRequest from '@src/assets/images/icons/primary-navbar/secondary-navbar/returns/ReturnRequest'
// import ManageInvoice from "@src/views/settings/manage-invoice"
// import SellersAndSuppliers from "@src/assets/images/icons/primary-navbar/secondary-navbar/SellersAndSuppliers"
// import Audit from "@src/assets/images/icons/primary-navbar/secondary-navbar/Audit"
// import StockMovement from "@src/assets/images/icons/primary-navbar/secondary-navbar/StockMovement"
// import LocationInventory from "@src/views/inventory/components/inventoryReports/totalInventory/LocationInventory"
import Analytics from '../../assets/images/icons/primary-navbar/Analytics'
// import Fleet from '@src/assets/images/icons/primary-navbar/secondary-navbar/Fleet'
// import Drivers from '@src/assets/images/icons/primary-navbar/secondary-navbar/Drivers'
// import DeliveryTrip from '@src/assets/images/icons/primary-navbar/secondary-navbar/DeliveryTrip'
// import DriversAppConfiguration from '@src/assets/images/icons/primary-navbar/secondary-navbar/DriversAppConfiguration'
import Reports from '@src/assets/images/icons/primary-navbar/Reports'
import CustomerList from '@src/assets/images/icons/primary-navbar/secondary-navbar/CustomerList'
import Invoices from '@src/assets/images/icons/primary-navbar/secondary-navbar/Invoices'
import ShipmentOrders from '@src/assets/images/icons/primary-navbar/secondary-navbar/ShipmentOrders'
import { ROUTE_PATHS as BILLING_ROUTE_PATHS } from '@src/views/settings/billing/billing.constants'
import CompanySettings from '../../assets/images/icons/primary-navbar/secondary-navbar/CompanySettings'
// import DriversAppConfiguration from '@src/assets/images/icons/primary-navbar/secondary-navbar/DriversAppConfiguration.js'
import AutomationRule from '@src/assets/images/icons/primary-navbar/secondary-navbar/AutomationRule'
import BulkShip from '@src/assets/images/icons/primary-navbar/secondary-navbar/BulkShip'
import CityMapping from '@src/assets/images/icons/primary-navbar/secondary-navbar/CityMapping'
import POSLogo from '@src/assets/images/icons/primary-navbar/secondary-navbar/Pos'
import SalesmenList from '@src/assets/images/icons/primary-navbar/secondary-navbar/SalesmenList'
import SkuConfigurationsSvg from '@src/assets/images/icons/primary-navbar/secondary-navbar/SkuConfigurations'
import { BULK_SHIP_PATH_NAMES } from '@src/views/bulk-ship/bulk-ship.constants'


export default [
  // {
  //   id: 'landing_page',
  //   title: 'Landing Page',
  //   icon: () => <img src={Omniful} style={{ width: '40px', height: '40px', margin: '0px' }} />,
  //   navLink: '/'
  // },
  {
    id: 'home',
    title: 'Home',
    // icon: <img src={Home} style={{ width: '28px', height: '28px', margin: '0px', padding: "0px" }} />,
    icon: Home,
    navLink: '/'
  },
  {
    id: 'pos',
    title: 'POS',
    icon: POSLogo,
    navLink: '/pos',
    action: abilityMap.custom.pos_view.action,
    resource: abilityMap.custom.pos_view.resource,
    children: [
      {
        id: 'registers',
        title: 'Registers',
        navLink: '/pos/registers',
        action: abilityMap.custom.pos_view.action,
        resource: abilityMap.custom.pos_view.resource
      },
      {
        id: 'register-close', 
        title: 'Close Register',
        navLink: '/pos/registers/:id/close',
        isChild: true,
        action: abilityMap.alwaysHidden.hidden.action,
        resource: abilityMap.alwaysHidden.hidden.resource
      },
      {
        id: 'register-open', 
        title: 'Open Register',
        navLink: '/pos/registers/:id/open',
        isChild: true,
        action: abilityMap.alwaysHidden.hidden.action,
        resource: abilityMap.alwaysHidden.hidden.resource
      },
      {
        id: 'register-sell', 
        title: 'Sell',
        navLink: '/pos/registers/:id/sell',
        isChild: true,
        action: abilityMap.alwaysHidden.hidden.action,
        resource: abilityMap.alwaysHidden.hidden.resource
      },
      {
        id: 'cash-management', 
        title: 'Cash Management',
        navLink: '/pos/cash-management',
        isChild: true,
        action: abilityMap.alwaysHidden.hidden.action,
        resource: abilityMap.alwaysHidden.hidden.resource
      },
      {
        id: 'orders', 
        title: 'Orders',
        navLink: '/pos/orders',
        isChild: true,
        action: abilityMap.alwaysHidden.hidden.action,
        resource: abilityMap.alwaysHidden.hidden.resource
      },
      {
        id: 'order-detail',
        title: 'Order Detail',
        navLink: '/pos/orders/:id/details',
        isChild: true,
        action: abilityMap.alwaysHidden.hidden.action,
        resource: abilityMap.alwaysHidden.hidden.resource
      }
    ]
  },
  {
    id: 'orders',
    title: 'Orders',
    icon: Sales,
    // icon: <img src={Sales} style={{ width: '28px', height: '28px', margin: '0px' }} />,
    // icon: <DollarSign size={12} style={{ width: '25px', height: '25px', margin: '0px' }} />,
    navLink: '/sales/live-orders',
    children: [
      {
        id: 'live-orders',
        title: 'Live Orders',
        icon: LiveOrder,
        navLink: '/sales/live-orders',
        action: abilityMap.order.view.action,
        resource: abilityMap.order.view.resource
      },
      {
        id: 'packing',
        title: 'Packing',
        navLink: '/sales/live-orders/packing',
        isChild: true,
        action: abilityMap.alwaysHidden.hidden.action,
        resource: abilityMap.alwaysHidden.hidden.resource
      },
      {
        id: 'pending-actions',
        title: 'Pending Actions',
        icon: PendingOrder,
        navLink: '/sales/pending-actions',
        action: abilityMap.order.view_on_hold.action,
        resource: abilityMap.order.view_on_hold.resource
      },
      {
        id: 'stock-transfer-requests',
        title: 'Stock Transfer Requests',
        icon: StockTransferOrder,
        navLink: '/sales/stock-transfer-requests',
        action: abilityMap.sto_requests_view.view.action,
        resource: abilityMap.sto_requests_view.view.resource
      },
      {
        id: 'manifests',
        title: 'Manifests',
        icon: ManifestList,
        navLink: '/sales/manifest',
        action: abilityMap.custom.manifest_view.action,
        resource: abilityMap.custom.manifest_view.resource
      },
      // ,
      // {
      //   id: 'create-shipment',
      //   title: 'Shipment',
      //   icon: (iswhite, height, width) => <Shipment height={height} width={width} iswhite={iswhite} />,
      //   navLink: '/sales/create-shipment'
      // }

      // {
      //   id: 'returns-orders',
      //   title: 'Returns',
      //   icon: (iswhite, height, width) => <Returns height={height} width={width} iswhite={iswhite} />,
      //   navLink: '/sales/returns'
      // },
      // {
      //   id: 'create',
      //   title: 'Create',
      //   icon: (iswhite, height, width) => <Create height={height} width={width} iswhite={iswhite} />,
      //   navLink: '/sales/create',
      //   children: [
      {
        index: true,
        id: 'create-order',
        title: 'Create Order',
        // isChild: true,
        navLink: '/sales/create/order',
        icon: CreateOrder,
        action: abilityMap.order.create.action,
        resource: abilityMap.order.create.resource
      },
      {
        // index: true,
        id: 'picking-wave',
        title: 'Create Picking Wave',
        // isChild: true,
        icon: CreatePickingWave,
        navLink: '/sales/create/picking-wave',
        action: abilityMap.picking_wave_view.view.action,
        resource: abilityMap.picking_wave_view.view.resource
      },
      {
        id: 'shipments',
        title: 'Shipments',
        icon: Shipments,
        navLink: '/sales/shipments/forward-shipment',
        children: [
          {
            id: 'forward-shipment',
            title: 'Forward Shipment',
            isChild: true,
            navLink: '/sales/shipments/forward-shipment',
            action: abilityMap.shipment.view.action,
            resource: abilityMap.shipment.view.resource
          },
          {
            id: 'return-shipment',
            title: 'Return Shipment',
            isChild: true,
            navLink: '/sales/shipments/return-shipment',
            action: abilityMap.shipment.view.action,
            resource: abilityMap.shipment.view.resource
          },
          {
            id: 'shipments-tracking',
            title: 'Shipments Tracking',
            isChild: true,
            navLink: '/sales/shipments/shipments-tracking',
            action: abilityMap.custom.shipments_tracking_view.action,
            resource: abilityMap.custom.shipments_tracking_view.resource
          }
        ]
      },
      {
        id: 'tax-invoices',
        title: 'Tax Invoices',
        icon: Invoices,
        navLink: '/sales/tax-invoices',
        action: abilityMap.custom.tax_invoices.action,
        resource: abilityMap.custom.tax_invoices.resource
      },
      {
        id: 'customers',
        title: 'Customers',
        icon: CustomerList,
        navLink: '/sales/customers',
        action: abilityMap.customer.view.action,
        resource: abilityMap.customer.view.resource
      },
      {
        id: 'salesmen',
        title: 'Salesmen',
        icon: SalesmenList,
        navLink: '/sales/salesmen',
        action: abilityMap.salesman.view.action,
        resource: abilityMap.salesman.view.resource
      },
      {
        id: 'shipments-orders',
        title: 'Shipment Orders',
        icon: ShipmentOrders,
        navLink: '/sales/shipments-orders/order-status',
        children: [
          {
            id: 'order_status',
            title: 'Order Status',
            isChild: true,
            navLink: '/sales/shipments-orders/order-status',
            action: abilityMap.shipment_order.view.action,
            resource: abilityMap.shipment_order.view.resource
          },
          {
            id: 'create-shipment-orders',
            title: 'Create Shipment Order',
            isChild: true,
            navLink: '/sales/shipments-orders/create-shipment-order',
            action: abilityMap.shipment_order.add.action,
            resource: abilityMap.shipment_order.add.resource
          },
          {
            id: 'pickup-locations',
            title: 'Locations',
            isChild: true,
            navLink: '/sales/shipments-orders/locations',
            action: abilityMap.pickup_location.view.action,
            resource: abilityMap.pickup_location.view.resource
          }
        ]
      },
      {
        id: 'bulk-ship',
        title: 'Bulk Ship',
        icon: BulkShip,
        navLink: BULK_SHIP_PATH_NAMES.CLUSTERS,
        action: abilityMap.custom.bulk_ship.action,
        resource: abilityMap.custom.bulk_ship.resource
      },
      {
        id: 'bulk-ship',
        title: 'Process Bulk Ship',
        isChild: true,
        navLink: BULK_SHIP_PATH_NAMES.PROCESS_CLUSTER,
        action: abilityMap.alwaysHidden.hidden.action,
        resource: abilityMap.alwaysHidden.hidden.resource
      }
    ]
  },
  // {
  //   id: 'hubs',
  //   title: 'Hub Setup',
  //   icon: (iswhite, height, width) => <Hub height={height} width={width} iswhite={iswhite} />,
  //   // icon: <img src={Hub} style={{ width: '28px', height: '28px', margin: '0px' }} />,
  //   // icon: <Home style={{ width: '25px', height: '25px', margin: '0px' }} />,
  //   navLink: '/hubs',
  //   action: abilityMap.hub.view.action,
  //   resource: abilityMap.hub.view.resource,
  //   children: [
  //     {
  //       id: 'location',
  //       title: 'Location',
  //       icon: (iswhite, height, width) => <Location height={height} width={width} iswhite={iswhite} />,
  //       navLink: '/hubs/:hubId/locations',
  //       action: abilityMap.hub_location.view.action,
  //       resource: abilityMap.hub_location.view.resource
  //     },
  //     {
  //       id: 'bins',
  //       title: 'Bins',
  //       icon: (iswhite, height, width) => <Bin height={height} width={width} iswhite={iswhite} />,
  //       navLink: '/hubs/:hubId/bins',
  //       action: abilityMap.hub_location.view_bin.action,
  //       resource: abilityMap.hub_location.view_bin.resource
  //     }
  //     // {
  //     //   id: 'consolidation',
  //     //   title: 'Consolidation Area',
  //     //   icon: (iswhite, height, width) => <Truck height={height} width={width} iswhite={iswhite} />,
  //     //   navLink: '/hubs/:hubId/consolidation-area'
  //     // }
  //     // {
  //     //   id: 'stations',
  //     //   title: 'Stations',
  //     //   icon: <Truck size={12} />,
  //     //   navLink: '/hubs/:hubId/stations'
  //     // },
  //     // {
  //     //   id: 'shipping',
  //     //   title: 'Shipping Settings',
  //     //   icon: <Truck size={12} />,
  //     //   navLink: '/hubs/:hubId/shipping-setting'
  //     // },
  //     // {
  //     //   id: 'saleschannel',
  //     //   title: 'Sales Channel Mapping',
  //     //   icon: <Truck size={12} />,
  //     //   navLink: '/hubs/:hubId/sales-channel'
  //     // },

  //     // removed hub users
  //     // {
  //     //   id: 'hubuser',
  //     //   title: 'Hub Users',
  //     //   icon: <Truck size={12} />,
  //     //   navLink: '/hubs/:hubId/users'
  //     // }
  //   ]
  // },
  {
    id: 'rerturn-orders',
    title: 'Returns',
    icon: Return,
    navLink: '/order/return/return-request',
    children: [
      {
        id: 'return-request',
        title: 'Return Requests',
        icon: RerurnRequest,
        navLink: '/order/return/return-request',
        action: abilityMap.return.view_request.action,
        resource: abilityMap.return.view_request.resource
      },
      {
        id: 'crate-return-request',
        title: 'Create Return Request',
        icon: CreateReturnRequest,
        navLink: '/orders/return/create-return-request',
        action: abilityMap.return.create_request.action,
        resource: abilityMap.return.create_request.resource
      },
      {
        id: 'return-order',
        title: 'Return Orders',
        icon: ReturnOrder,
        navLink: '/order/return/return-order',
        action: abilityMap.return.view_order.action,
        resource: abilityMap.return.view_order.resource
      },
      {
        id: 'create-return-order',
        title: 'Create Return Order',
        icon: CreateReturnOrder,
        navLink: '/order/return/create-return-order',
        action: abilityMap.return.create_order.action,
        resource: abilityMap.return.create_order.resource
      },
      {
        id: 'return-gate-entry',
        title: 'Return Gate Entry',
        icon: ReturnGateEntry,
        navLink: '/order/return/return-gate-entry',
        action: abilityMap.custom.gate_entry_view.action,
        resource: abilityMap.custom.gate_entry_view.resource
      },
      {
        id: 'return-order-processing',
        title: 'Return Order Processing',
        icon: ReturnOrderProcessing,
        navLink: '/order/return/return-order-processing',
        action: abilityMap.custom.grn_view.action,
        resource: abilityMap.custom.grn_view.resource
      },
      {
        id: 'new-return-order-prcessing',
        title: 'New GRN',
        navLink: '/order/return/return-order-processing/create-return-order-processing',
        isChild: true,
        action: abilityMap.alwaysHidden.hidden.action,
        resource: abilityMap.alwaysHidden.hidden.resource
      }
      // {
      //   id: 'return-order-processing',
      //   title: 'Return Order Processing',
      //   icon: (iswhite, height, width) => <ReturnOrderProcessing height={height} width={width} iswhite={iswhite} />,
      //   navLink: '/order/return/return-order-processing',
      //   action: abilityMap.return.qc_order.action,
      //   resource: abilityMap.return.qc_order.resource
      // }
    ]
  },
  {
    id: 'analytics',
    title: 'Analytics',
    icon: Analytics,
    navLink: '/analytics/hub',
    children: [
      {
        id: 'analytics-hub',
        title: 'Hub',
        navLink: '/analytics/hub',
        action: abilityMap.analytics_hub_dashboard.view.action,
        resource: abilityMap.analytics_hub_dashboard.view.resource
      },
      {
        id: 'analytics-seller',
        title: 'Seller',
        navLink: '/analytics/seller',
        action: abilityMap.analytics_seller_dashboard.view.action,
        resource: abilityMap.analytics_seller_dashboard.view.resource
      },
      {
        id: 'analytics-shipping',
        title: 'Shipping',
        navLink: '/analytics/shipping',
        action: abilityMap.analytics_shipping_dashboard.view.action,
        resource: abilityMap.analytics_shipping_dashboard.view.resource
      },
    ]
  },
  {
    id: 'hubs',
    title: 'Hubs',
    icon: Hub,
    // icon: <img src={Hub} style={{ width: '28px', height: '28px', margin: '0px' }} />,
    // icon: <Home style={{ width: '25px', height: '25px', margin: '0px' }} />,
    navLink: '/hubs',
    action: abilityMap.hub.view.action,
    resource: abilityMap.hub.view.resource,
    children: [
      {
        id: 'hub-setup',
        title: 'Hub Setup',
        icon: HubSetup,
        navLink: '/hubs/:hubId/overview',
        action: abilityMap.hub.view.action,
        resource: abilityMap.hub.view.resource,
        children: [
          {
            id: 'overview',
            title: 'Overview',
            // icon: (iswhite, height, width) => <HubOverview height={height} width={width} iswhite={iswhite} />,
            isChild: true,
            navLink: '/hubs/:hubId/overview',
            action: abilityMap.hub.view.action,
            resource: abilityMap.hub.view.resource
          },
          // {
          //   id: 'layout',
          //   title: 'Layout',
          //   // icon: (iswhite, height, width) => <HubLayout height={height} width={width} iswhite={iswhite} />,
          //   isChild: true,
          //   navLink: '/hubs/:hubId/layout',
          //   action: abilityMap.hub_location.view.action,
          //   resource: abilityMap.hub_location.view.resource
          // },
          {
            id: 'locations',
            title: 'Locations',
            // icon: (iswhite, height, width) => <HubLayout height={height} width={width} iswhite={iswhite} />,
            isChild: true,
            navLink: '/hubs/:hubId/location-layout',
            action: abilityMap.hub_locations_zones_view.view.action,
            resource: abilityMap.hub_locations_zones_view.view.resource
          },
          {
            id: 'zones',
            title: 'Zones',
            // icon: (iswhite, height, width) => <Zones height={height} width={width} iswhite={iswhite} />,
            isChild: true,
            navLink: '/hubs/:hubId/zones',
            action: abilityMap.hub_locations_zones_view.view.action,
            resource: abilityMap.hub_locations_zones_view.view.resource
          },
          {
            id: 'delivery-zone',
            title: 'Delivery Zones',
            // icon: (iswhite, height, width) => <Zones height={height} width={width} iswhite={iswhite} />,
            isChild: true,
            navLink: '/hubs/:hubId/delivery-zone',
            action: abilityMap.delivery_zone.view.action,
            resource: abilityMap.delivery_zone.view.resource
          }
        ]
      },
      {
        id: 'assets',
        title: 'Hub Assets',
        icon: HubAssets,
        navLink: '/hubs/:hubId/bins',
        children: [
          {
            id: 'bins',
            title: 'Bins',
            isChild: true,
            // icon: (iswhite, height, width) => <Bin height={height} width={width} iswhite={iswhite} />,
            navLink: '/hubs/:hubId/bins',
            action: abilityMap.custom.hub_bin.action,
            resource: abilityMap.custom.hub_bin.resource
          },
          {
            id: 'carts',
            title: 'Carts',
            // icon: (iswhite, height, width) => <Bin height={height} width={width} iswhite={iswhite} />,
            isChild: true,
            navLink: '/hubs/:hubId/carts',
            action: abilityMap.hub_assets_view.view.action,
            resource: abilityMap.hub_assets_view.view.resource
          },
          {
            id: 'pallets',
            title: 'Pallets',
            // icon: (iswhite, height, width) => <Bin height={height} width={width} iswhite={iswhite} />,
            isChild: true,
            navLink: '/hubs/:hubId/pallets',
            action: abilityMap.custom.hub_bin.action,
            resource: abilityMap.custom.hub_bin.resource
          },
          {
            id: 'boxes',
            title: 'Boxes',
            isChild: true,
            navLink: '/hubs/:hubId/boxes',
            action: abilityMap.hub_assets_view.view.action,
            resource: abilityMap.hub_assets_view.view.resource
          }
        ]
      }
      // {
      //   id: 'deliveryzone',
      //   title: 'Delivery Zone Mapping',
      //   icon: (iswhite, height, width) => <HubAssets height={height} width={width} iswhite={iswhite} />,
      //   navLink: '/hubs/:hubId/delivery-zone',
      //   action: abilityMap.hub_location.view_bin.action,
      //   resource: abilityMap.hub_location.view_bin.resource
      // }
      // {
      //   id: 'consolidation',
      //   title: 'Consolidation Area',
      //   icon: (iswhite, height, width) => <Truck height={height} width={width} iswhite={iswhite} />,
      //   navLink: '/hubs/:hubId/consolidation-area'
      // }
      // {
      //   id: 'stations',
      //   title: 'Stations',
      //   icon: <Truck size={12} />,
      //   navLink: '/hubs/:hubId/stations'
      // },
      // {
      //   id: 'shipping',
      //   title: 'Shipping Settings',
      //   icon: <Truck size={12} />,
      //   navLink: '/hubs/:hubId/shipping-setting'
      // },
      // {
      //   id: 'saleschannel',
      //   title: 'Sales Channel Mapping',
      //   icon: <Truck size={12} />,
      //   navLink: '/hubs/:hubId/sales-channel'
      // },

      // removed hub users
      // {
      //   id: 'hubuser',
      //   title: 'Hub Users',
      //   icon: <Truck size={12} />,
      //   navLink: '/hubs/:hubId/users'
      // }
    ]
  },
  // {
  //   id: 'hubs',
  //   title: 'Hub Details',
  //   icon: (iswhite, height, width) => <Hub height={height} width={width} iswhite={iswhite} />,
  //   // icon: <img src={Hub} style={{ width: '28px', height: '28px', margin: '0px' }} />,
  //   // icon: <Home style={{ width: '25px', height: '25px', margin: '0px' }} />,
  //   navLink: '/hubs',
  //   action: abilityMap.hub.view.action,
  //   resource: abilityMap.hub.view.resource,
  //   children: [
  //     {
  //       id: 'location',
  //       title: 'Location',
  //       icon: (iswhite, height, width) => <Location height={height} width={width} iswhite={iswhite} />,
  //       navLink: '/hubs/:hubId/locations',
  //       action: abilityMap.hub_location.view.action,
  //       resource: abilityMap.hub_location.view.resource
  //     },
  //     {
  //       id: 'bins',
  //       title: 'Bins',
  //       icon: (iswhite, height, width) => <Bin height={height} width={width} iswhite={iswhite} />,
  //       navLink: '/hubs/:hubId/bins',
  //       action: abilityMap.hub_location.view_bin.action,
  //       resource: abilityMap.hub_location.view_bin.resource
  //     }
  //     // {
  //     //   id: 'consolidation',
  //     //   title: 'Consolidation Area',
  //     //   icon: (iswhite, height, width) => <Truck height={height} width={width} iswhite={iswhite} />,
  //     //   navLink: '/hubs/:hubId/consolidation-area'
  //     // }
  //     // {
  //     //   id: 'stations',
  //     //   title: 'Stations',
  //     //   icon: <Truck size={12} />,
  //     //   navLink: '/hubs/:hubId/stations'
  //     // },
  //     // {
  //     //   id: 'shipping',
  //     //   title: 'Shipping Settings',
  //     //   icon: <Truck size={12} />,
  //     //   navLink: '/hubs/:hubId/shipping-setting'
  //     // },
  //     // {
  //     //   id: 'saleschannel',
  //     //   title: 'Sales Channel Mapping',
  //     //   icon: <Truck size={12} />,
  //     //   navLink: '/hubs/:hubId/sales-channel'
  //     // },

  //     // removed hub users
  //     // {
  //     //   id: 'hubuser',
  //     //   title: 'Hub Users',
  //     //   icon: <Truck size={12} />,
  //     //   navLink: '/hubs/:hubId/users'
  //     // }
  //   ]
  // },
  {
    id: 'catalog',
    title: 'Catalog',
    icon: Catalog,
    navLink: '/catalog/skus',
    children: [
      {
        id: 'products',
        title: 'Seller Catalog',
        navLink: '/catalog/skus',
        icon: OmnifulCatalog,
        children: [
          {
            id: 'sku',
            title: 'SKUs',
            navLink: '/catalog/skus',
            isChild: true,
            action: abilityMap.product.view.action,
            resource: abilityMap.product.view.resource
          },
          {
            id: 'kits',
            title: 'Kits',
            navLink: '/catalog/kits',
            isChild: true,
            action: abilityMap.custom.kits.action,
            resource: abilityMap.custom.kits.resource
          },
          // {
          //   id: 'assembled-kits',
          //   title: 'Assembled Kits',
          //   navLink: '/catalog/assembled-kits',
          //   isChild: true,
          //   action: abilityMap.product.view.action,
          //   resource: abilityMap.product.view.resource
          // },
          {
            id: 'catalog-comparision',
            title: 'Catalog Comparison',
            navLink: '/catalog/catalog-comparision',
            isChild: true,
            action: abilityMap.catalog.compare.action,
            resource: abilityMap.catalog.compare.resource
          },
          {
            id: 'catalog-comparision',
            title: 'Catalog Comparison',
            navLink: '/catalog/catalog-comparision/catalogs-compared',
            isChild: true,
            action: abilityMap.alwaysHidden.hidden.action,
            resource: abilityMap.alwaysHidden.hidden.resource
          },
          // {
          //   id: 'category-tree',
          //   title: 'Category Tree',
          //   navLink: '/catalog/category-tree',
          //   isChild: true,
          //   action: abilityMap.product.view.action,
          //   resource: abilityMap.product.view.resource
          // },
          {
            id: 'barcode-config',
            title: 'Embedded Barcode Configuration',
            navLink: '/catalog/barcode-config',
            isChild: true,
            action: abilityMap.catalog.view_configuration_product.action,
            resource: abilityMap.catalog.view_configuration_product.resource

          }
        ]
      },
      {
        id: 'sales-channel-listing',
        title: 'Sales Channel Listing',
        icon: SalesChannel,
        navLink: '/catalog/sales-channel-listing',
        action: abilityMap.custom.sales_channel_listing.action,
        resource: abilityMap.custom.sales_channel_listing.resource
      }
      // {
      //   id: 'attributes',
      //   title: 'Attributes',
      //   navLink: '/catalog/attributes/individual',
      //   icon: (iswhite, height, width) => <Attributes height={height} width={width} iswhite={iswhite} />,
      //   children: [
      //     {
      //       id: 'individual',
      //       title: 'Individual',
      //       // icon: <Truck size={12} />,
      //       isChild: true,
      //       navLink: '/catalog/attributes/individual',
      //       action: abilityMap.product.view.action,
      //       resource: abilityMap.product.view.resource
      //     },
      //     {
      //       id: 'groups',
      //       title: 'Groups',
      //       // icon: <Truck size={12} />,
      //       isChild: true,
      //       navLink: '/catalog/attributes/groups',
      //       action: abilityMap.product.view.action,
      //       resource: abilityMap.product.view.resource
      //     }
      //   ]
      // }
    ]
  },
  {
    id: 'inventory',
    title: 'Inventory',
    icon: Inventory,
    navLink: '/inventory/inventory-reports/total-inventory',
    children: [
      {
        id: 'inventory-overview',
        title: 'Inventory Overview',
        navLink: '/inventory/inventory-reports',
        icon: InventoryReports,
        children: [
          {
            id: 'total-inventory',
            title: 'Total Inventory',
            // icon: <Truck size={12} />,
            isChild: true,
            navLink: '/inventory/inventory-reports/total-inventory',
            action: abilityMap.inventory.view.action,
            resource: abilityMap.inventory.view.resource
          },
          {
            id: 'location-inventory',
            title: 'Location Inventory',
            // icon: <Truck size={12} />,
            isChild: true,
            navLink: '/inventory/inventory-reports/location-inventory',
            action: abilityMap.custom.inventory_view.action,
            resource: abilityMap.custom.inventory_view.resource
          },
          {
            id: 'sku-locations',
            title: 'SKU Locations',
            // icon: <Truck size={12} />,
            isChild: true,
            navLink: '/inventory/inventory-reports/sku-locations',
            action: abilityMap.custom.sku_locations_view.action,
            resource: abilityMap.custom.sku_locations_view.resource
          },
          {
            id: 'inventory-batches',
            title: 'Batch Inventory',
            // icon: <Truck size={12} />,
            isChild: true,
            navLink: '/inventory/inventory-reports/batches',
            action: abilityMap.custom.inventory_view.action,
            resource: abilityMap.custom.inventory_view.resource
          },
          {
            id: 'inventory-demand-forecasting',
            title: 'Demand Forecasting',
            isChild: true,
            navLink: '/inventory/inventory-reports/demand-forecasting',
            action: abilityMap.forecast.generate.action,
            resource: abilityMap.forecast.generate.resource
          }
        ]
      },
      {
        id: 'purchases',
        title: 'Purchases',
        icon: Purchase,
        navLink: '/purchases/purchase-order',
        // action: abilityMap.purchase_order.view.action,
        // resource: abilityMap.purchase_order.view.resource,
        children: [
          {
            id: 'purchase-order',
            title: 'Purchase Orders',
            isChild: true,
            // icon: (iswhite, height, width) => <PurchaseOrder height={height} width={width} iswhite={iswhite} />,
            navLink: '/purchases/purchase-order',
            action: abilityMap.purchase_orders_view.view.action,
            resource: abilityMap.purchase_orders_view.view.resource
          },
          {
            id: 'all-suppliers',
            title: 'Suppliers',
            isChild: true,
            // icon: (iswhite, height, width) => <AllSuppliers height={height} width={width} iswhite={iswhite} />,
            navLink: '/purchases/all-suppliers',
            action: abilityMap.supplier.view.action,
            resource: abilityMap.supplier.view.resource
          },
          {
            id: 'sellers',
            title: 'Sellers',
            isChild: true,
            // icon: (iswhite, height, width) => <Seller height={height} width={width} iswhite={iswhite} />,
            // icon: <Truck size={12} />,
            // isChild: true,
            navLink: '/purchases/sellers',
            action: abilityMap.custom.seller_view.action,
            resource: abilityMap.custom.seller_view.resource
          }
          // {
          //   id: 'sellers-and-suppliers',
          //   title: 'Sellers And Suppliers',
          //   icon: <Truck size={12} />,
          //   navLink: '/purchases/sellers-and-suppliers'
          // }
        ]
      },
      {
        id: 'inventory-operations',
        title: 'Inventory Operations',
        navLink: '/inventory/inventory-operations',
        icon: InventoryOperations,
        action: abilityMap.inventory_operations_view.view.action,
        resource: abilityMap.inventory_operations_view.view.resource,
        children: [
          {
            id: 'gate-entry',
            title: 'Gate Entry',
            // icon: <Truck size={12} />,
            isChild: true,
            navLink: '/inventory/inventory-operations/gate-entry',
            action: abilityMap.custom.gate_entry_view.action,
            resource: abilityMap.custom.gate_entry_view.resource
          },
          {
            id: 'grn',
            title: 'GRN',
            // icon: <Truck size={12} />,
            isChild: true,
            navLink: '/inventory/inventory-operations/grn',
            action: abilityMap.custom.grn_view.action,
            resource: abilityMap.custom.grn_view.resource
          },
          {
            id: 'new-grn',
            title: 'New GRN',
            navLink: '/inventory/inventory-operations/grn/new-grn',
            isChild: true,
            action: abilityMap.alwaysHidden.hidden.action,
            resource: abilityMap.alwaysHidden.hidden.resource
          },
          {
            id: 'putaway',
            title: 'Putaway',
            // icon: <Truck size={12} />,
            isChild: true,
            navLink: '/inventory/inventory-operations/putaway',
            action: abilityMap.custom.put_away_view.action,
            resource: abilityMap.custom.put_away_view.resource
          },
          {
            id: 'pending putaway',
            title: 'Pending Putaway',
            // icon: <Truck size={12} />,
            isChild: true,
            navLink: '/inventory/inventory-operations/pending-putaway',
            action: abilityMap.put_away.view.action,
            resource: abilityMap.put_away.view.resource
          },
          {
            id: 'cycle count',
            title: 'Cycle Count',
            isChild: true,
            navLink: '/inventory/inventory-operations/cycle-count',
            action: abilityMap.cycle_count.view.action,
            resource: abilityMap.cycle_count.view.resource
          },
          {
            id: 'cycle count',
            title: 'Cycle Count',
            isChild: true,
            navLink: '/inventory/inventory-operations/cycle-count/cycle-count-details',
            action: abilityMap.alwaysHidden.hidden.action,
            resource: abilityMap.alwaysHidden.hidden.resource
          },
          // {
          //   id: 'assembly',
          //   title: 'Assembly',
          //   isChild: true,
          //   navLink: '/inventory/inventory-operations/assembly',
          //   action: abilityMap.custom.assembly_view.action,
          //   resource: abilityMap.custom.assembly_view.resource
          // },
          {
            index: true,
            id: 'ad-hoc-usage',
            title: 'Ad Hoc Usage',
            isChild: true,
            navLink: '/inventory/inventory-operations/ad-hoc-usage',
            action: abilityMap.adhoc_usage.view.action,
            resource: abilityMap.adhoc_usage.view.resource
          },
          // {
          //   id: 'audit',
          //   title: 'Audit',
          //   // icon: <Truck size={12} />,
          //   isChild: true,
          //   navLink: '/inventory/inventory-operations/audit'
          // },
          // {
          //   id: 'stock-movement',
          //   title: 'Stock Movement',
          //   // icon: <Truck size={12} />,
          //   isChild: true,
          //   navLink: '/inventory/inventory-operations/stock-movement'
          // }
          {
            id: 'stock-transfer',
            title: 'Stock Transfer',
            isChild: true,
            navLink: '/inventory/stock-transfer',
            action: abilityMap.custom.stock_transfer_view.action,
            resource: abilityMap.custom.stock_transfer_view.resource
          }
        ]
      }
      // {
      //   id: 'bulk-importing',
      //   title: 'Bulk Importing',
      //   navLink: '/inventory/bulk-importing',
      //   icon: (iswhite, height, width) => <BulkImporting height={height} width={width} iswhite={iswhite} />,
      //   children: [
      //     {
      //       id: 'import-hub-quantities',
      //       title: 'Import Hub Quantities',
      //       navLink: '/inventory/bulk-importing/import-hub-quantities',
      //       // icon: <Truck size={12} />
      //       isChild: true
      //     },
      //     {
      //       id: 'import-product-buffer',
      //       title: 'Import Product Buffer',
      //       navLink: '/inventory/bulk-importing/import-product-buffer',
      //       // icon: <Truck size={12} />
      //       isChild: true
      //     }
      //   ]
      // }
    ]
  },
  // {
  //   id: 'fleet',
  //   title: 'Fleet',
  //   icon: (iswhite, height, width) => <Fleet height={height} width={width} iswhite={iswhite} />,
  //   navLink: '/fleet',
  //   children: [

  //     {
  //       id: 'delivery-trip',
  //       title: 'Delivery Trip',
  //       navLink: '/fleet/create-trips',
  //       icon: (iswhite, height, width) => <DeliveryTrip height={height} width={width} iswhite={iswhite} />,
  //       children: [
  //         {
  //           id: 'create-and-assign',
  //           title: 'Create Trip',
  //           navLink: '/fleet/create-trips',
  //           isChild: true,
  //           action: abilityMap.fleet.view_trip.action,
  //           resource: abilityMap.fleet.view_trip.resource
  //         },
  //         {
  //           id: 'pending-trips',
  //           title: 'Pending Trips',
  //           navLink: '/fleet/pending-trips',
  //           isChild: true,
  //           action: abilityMap.fleet.view_trip.action,
  //           resource: abilityMap.fleet.view_trip.resource
  //         },
  //         {
  //           id: 'live-trips',
  //           title: 'Live Trips',
  //           navLink: '/fleet/live-trips',
  //           isChild: true,
  //           action: abilityMap.fleet.view_trip.action,
  //           resource: abilityMap.fleet.view_trip.resource
  //         },
  //         {
  //           id: 'ongoing-completed-all',
  //           title: 'All Trips',
  //           navLink: '/fleet/all-trips',
  //           isChild: true,
  //           action: abilityMap.fleet.view_trip.action,
  //           resource: abilityMap.fleet.view_trip.resource
  //         }
  //       ]
  //     },
  //     {
  //       id: 'all-drivers',
  //       title: 'Drivers',
  //       navLink: '/fleet/all-drivers',
  //       icon: (iswhite, height, width) => <Drivers height={height} width={width} iswhite={iswhite} />,
  //       action: abilityMap.fleet.view_driver.action,
  //       resource: abilityMap.fleet.view_driver.resource
  //     },
  //     {
  //       id: 'configuration',
  //       title: 'Configuration',
  //       navLink: '/fleet/drivers-app',
  //       icon: (iswhite, height, width) => <DriversAppConfiguration height={height} width={width} iswhite={iswhite} />,
  //       action: abilityMap.fleet.view_configuration.action,
  //       resource: abilityMap.fleet.view_configuration.resource,
  //       children: [
  //         {
  //           id: 'drivers-app',
  //           title: `Delivery App`,
  //           navLink: '/fleet/drivers-app',
  //           isChild: true
  //         }
  //       ]
  //     }
  //   ]
  // },
  {
    id: 'reports',
    title: 'Reports',
    icon: Reports,
    action: abilityMap.report.view.action,
    resource: abilityMap.report.view.resource,
    navLink: '/reports'
  },
  {
    id: 'settings',
    title: 'Settings',
    icon: Settings,
    // icon: <img src={Settings} style={{ width: '28px', height: '28px', margin: '0px' }} />,
    // icon: <Settings style={{ width: '25px', height: '25px', margin: '0px' }} />,
    navLink: '/settings/sales-channel',
    children: [
      {
        id: 'sales-channel',
        title: 'Apps & Integration',
        icon: AppsAndIntegration,
        navLink: '/settings/sales-channel',
        children: [
          {
            id: 'sales-channel',
            title: 'Sales Channel Apps',
            isChild: true,
            navLink: '/settings/sales-channel',
            action: abilityMap.sales_channel_app.view.action,
            resource: abilityMap.sales_channel_app.view.resource
          },
          {
            id: 'shipping-apps',
            title: 'Shipping Apps',
            isChild: true,
            navLink: '/settings/shipping-partners',
            action: abilityMap.shipping_app.view.action,
            resource: abilityMap.shipping_app.view.resource
          },
          {
            id: 'custom-apps',
            title: 'Custom Apps',
            isChild: true,
            navLink: '/settings/custom-apps',
            action: abilityMap.custom_app.view.action,
            resource: abilityMap.custom_app.view.resource
          },
          {
            id: 'tax-authority',
            title: 'Tax Authority',
            isChild: true,
            navLink: '/settings/tax-authority',
            action: abilityMap.tax_authority.view.action,
            resource: abilityMap.tax_authority.view.resource
          }
        ]
      },
      {
        id: 'user-profile',
        title: 'User Profile',
        navLink: '/settings/user-profile/edit-profile',
        icon: UserProfile,
        children: [
          // {
          //   index: true,
          //   id: 'edit-profile',
          //   title: 'Edit Profile',
          //   // icon: <Truck size={12} />,
          //   isChild: true,
          //   navLink: '/settings/user-profile/edit-profile'
          // },
          {
            id: 'change-password',
            title: 'Change Password',
            isChild: true,
            navLink: '/settings/user-profile/change-password'
          }
        ]
      },
      {
        id: 'order-settings',
        title: 'Order Settings',
        icon: OrderSettings,
        navLink: '/settings/order-settings',
        children: [
          {
            index: true,
            id: 'oms-settings',
            title: 'OMS Settings',
            // icon: <Truck size={12} />,
            isChild: true,
            navLink: '/settings/order-settings/oms-settings',
            action: abilityMap.configuration.view.action,
            resource: abilityMap.configuration.view.resource
          },
          {
            index: true,
            id: 'order-tags',
            title: 'Order Tags',
            // icon: <Truck size={12} />,
            isChild: true,
            navLink: '/settings/order-settings/order-tags',
            action: abilityMap.order.view_tag.action,
            resource: abilityMap.order.view_tag.resource
          },
          {
            index: true,
            id: 'shipping-automation',
            title: 'Shipping Automation',
            isChild: true,
            navLink: '/settings/order-settings/shipping-automation',
            action: abilityMap.shipping_rule.view.action,
            resource: abilityMap.shipping_rule.view.resource
          }
          // {
          //   id: 'delivery-zone',
          //   title: 'Delivery Zone',
          //   // icon: <Truck size={12} />,
          //   isChild: true,
          //   navLink: '/settings/order-settings/delivery-zone'
          // }
        ]
      },
      {
        id: 'catalog-settings',
        title: 'Catalog Settings',
        icon: CatalogSettings,
        navLink: '/settings/catalog-settings',
        children: [
          {
            index: true,
            id: 'default-skus-settings',
            title: 'Bulk SKU Configuration',
            isChild: true,
            navLink: '/settings/order-settings/default-skus-settings',
            action: abilityMap.configuration.view.action,
            resource: abilityMap.configuration.view.resource
          }
        ]
      },
      {
        id: 'billings',
        title: '3PL Billing',
        icon: ThreeBillingPL,
        navLink: BILLING_ROUTE_PATHS.BILLING_PROFILE_MODULE,
        children: [
          {
            index: true,
            id: 'billing-profile',
            title: 'Billing Profile',
            isChild: true,
            navLink: BILLING_ROUTE_PATHS.BILLING_PROFILE,
            action: abilityMap.billing_profile.view.action,
            resource: abilityMap.billing_profile.view.resource
          },
          {
            id: 'billing-profile',
            title: 'Create Billing Profile',
            navLink: BILLING_ROUTE_PATHS.BILLING_PROFILE_CREATE,
            isChild: true,
            action: abilityMap.alwaysHidden.hidden.action,
            resource: abilityMap.alwaysHidden.hidden.resource
          },
          {
            id: 'billing-profile',
            title: 'View Billing Profile',
            navLink: BILLING_ROUTE_PATHS.VIEW_BILLING_PROFILE,
            isChild: true,
            action: abilityMap.alwaysHidden.hidden.action,
            resource: abilityMap.alwaysHidden.hidden.resource
          },
          {
            index: true,
            id: 'contacts',
            title: 'Contracts',
            isChild: true,
            navLink: BILLING_ROUTE_PATHS.CONTRACTS,
            action: abilityMap.contract.view.action,
            resource: abilityMap.contract.view.resource
          },
          {
            index: true,
            id: 'contacts',
            title: 'View Contract',
            isChild: true,
            navLink: BILLING_ROUTE_PATHS.VIEW_CONTRACT,
            action: abilityMap.alwaysHidden.hidden.action,
            resource: abilityMap.alwaysHidden.hidden.resource
          },
          // {
          //   index: true,
          //   id: 'contacts',
          //   title: 'Edit Contract',
          //   isChild: true,
          //   navLink: BILLING_ROUTE_PATHS.EDIT_CONTRACT,
          //   action: abilityMap.alwaysHidden.hidden.action,
          //   resource: abilityMap.alwaysHidden.hidden.resource
          // },
          {
            index: true,
            id: 'bills',
            title: 'Bills',
            isChild: true,
            navLink: BILLING_ROUTE_PATHS.BILLS,
            action: abilityMap.bill.view.action,
            resource: abilityMap.bill.view.resource
          },
          {
            index: true,
            id: 'bills',
            title: 'View Bill',
            isChild: true,
            navLink: BILLING_ROUTE_PATHS.VIEW_BILL,
            action: abilityMap.alwaysHidden.hidden.action,
            resource: abilityMap.alwaysHidden.hidden.resource
          }
          // {
          //   index: true,
          //   id: 'ad-hoc-usage',
          //   title: 'Ad Hoc Usage',
          //   isChild: true,
          //   navLink: BILLING_ROUTE_PATHS.AD_HOC_USAGE,
          //   action: abilityMap.adhoc_usage.view.action,
          //   resource: abilityMap.adhoc_usage.view.resource
          // }
          // {
          //   index: true,
          //   id: 'ad-hoc-activities',
          //   title: 'Ad Hoc Activities',
          //   isChild: true,
          //   navLink: BILLING_ROUTE_PATHS.AD_HOC_ACTIVITIES,
          //   action: abilityMap.configuration.view.action,
          //   resource: abilityMap.configuration.view.resource
          // }
        ]
      },
      {
        id: 'automation-rules',
        title: 'Automation Rules',
        icon: AutomationRule,
        navLink: '/settings/automation-rules',
        children: [
          {
            index: true,
            id: 'packaging-automation',
            title: 'Packaging Automation',
            isChild: true,
            navLink: '/settings/automation-rules/packaging-automation',
            action: abilityMap.automation_rules.packaging.view.action,
            resource: abilityMap.automation_rules.packaging.view.resource
          },
          {
            id: 'hub-routing-automation',
            title: 'Hub Routing Automation',
            isChild: true,
            navLink: '/settings/automation-rules/hub-routing-automation',
            action: abilityMap.automation_rules.hub_routing.view.action,
            resource: abilityMap.automation_rules.hub_routing.view.resource
          }
        ]
      },
      {
        id: 'adhoc-activities',
        title: 'Ad Hoc Activities',
        icon: AdHocActivities,
        navLink: BILLING_ROUTE_PATHS.AD_HOC_ACTIVITIES,
        action: abilityMap.adhoc.view.action,
        resource: abilityMap.adhoc.view.resource
      },
      {
        id: 'sku-configurations',
        title: 'Hub SKU Configurations',
        icon: SkuConfigurationsSvg,
        navLink: '/settings/sku-configurations',
        action: abilityMap.sku_configurtion.view.action,
        resource: abilityMap.sku_configurtion.view.resource
      },
      {
        id: 'user-settings',
        title: 'User Settings',
        icon: UserSetting,
        navLink: '/settings/user-settings',
        action: abilityMap.user.view.action,
        resource: abilityMap.user.view.resource
      },
      {
        id: 'role-settings',
        title: 'Role Settings',
        icon: RoleSettings,
        navLink: '/settings/role-settings',
        action: abilityMap.role.view.action,
        resource: abilityMap.role.view.resource
      },
      {
        id: 'manage-invoice',
        title: 'Manage Invoice',
        icon: MangaeInvoice,
        navLink: '/settings/manage-invoice',
        action: abilityMap.custom.manage_invoice.action,
        resource: abilityMap.custom.manage_invoice.resource
      },
      {
        id: 'company-settings',
        title: 'Company Settings',
        icon: CompanySettings,
        navLink: '/settings/company-settings',
        action: abilityMap.tenant.view.action,
        resource: abilityMap.tenant.view.resource
      },
      {
        id: 'city-mapping',
        title: 'City Mapping',
        icon: CityMapping,
        navLink: '/settings/city-mapping',
        children: [
          {
            index: true,
            id: 'system-cities',
            title: 'System Cities',
            isChild: true,
            navLink: '/settings/city-mapping/system-cities',
            action: abilityMap.tenant_city_mapping.view.action,
            resource: abilityMap.tenant_city_mapping.view.resource
          },
          {
            index: true,
            id: 'shipping-partner-cities',
            title: 'Shipping Partner Cities',
            isChild: true,
            navLink: '/settings/city-mapping/shipping-partner-cities',
            action: abilityMap.tenant_city_mapping.view.action,
            resource: abilityMap.tenant_city_mapping.view.resource
          }
        ]
      },
      {
        id: 'pos-settings',
        title: 'POS Settings',
        icon: POSLogo,
        navLink: '/settings/pos-settings',
        action: abilityMap.pos_setting.set.action,
        resource: abilityMap.pos_setting.set.resource
      }
    ]
  }

  // {
  //   id: 'help',
  //   title: 'Help Centre',
  //   // icon: <Circle size={12} style={{ width: '25px', height: '25px', margin: '0px' }} />,
  //   icon: (iswhite, height, width) => <HelpCentre iswhite={iswhite} height={height} width={width} />
  // }

  // {
  //   id: 'logout',
  //   title: 'Logout',
  //   // icon: <Circle size={12} style={{ width: '25px', height: '25px', margin: '0px' }} />,
  //   icon: (iswhite, height, width) => <Logout iswhite={iswhite} height={height} width={width} />
  // }

  // {
  //   id: 'AbilityCheck',
  //   title: 'AbilityCheck',
  //   // icon: <Circle size={12} style={{ width: '25px', height: '25px', margin: '0px' }} />,
  //   icon: () => <Circle size={12} style={{ width: '25px', height: '25px', margin: '0px' }} />,
  //   action: 'picker',
  //   resource: 'picking',
  //   navLink: '/ability'
  // }
]
