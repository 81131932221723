import FloatingDropDown from '@src/@core/components/ui/floating-dropdown'
import InputField from '@src/@core/components/ui/input-field'
import { GET_CURRENCIES } from '@src/redux/authentication'
import { getTaxCategoriesData } from '@src/views/pos/pos.apis'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import {
  CATALOG_FLOATING_YES_AND_NO_OPTIONS,
  CATALOG_UNIT_OF_MEASUREMENT,
  SIMPLE_SKU_FIELDS_NAME
} from '../../catalog.constants'

const SingleSkuMetaData = ({
  errors,
  watch,
  control,
  register,
  isEdit,
  singleSkuLoading,
  updateBundleSkusRes,
  bundleCreationLoading,
  imageGettingUpload,
  setValue,
  singleSkuDetail,
  clearErrors,
  isPosEnabled
}) => {
  const { t } = useTranslation()
  const globalSeller = useSelector(state => state.auth.globalSeller)

  const taxValidator = () => {
    if (Number(watch(SIMPLE_SKU_FIELDS_NAME.TAX_PERCENTAGE)) > 100 || watch(SIMPLE_SKU_FIELDS_NAME.TAX_PERCENTAGE).length > 4) {
      return 'Please provide valid tax percentage.'
    }
    return undefined
  }

  const gtinValidator = () => {
    if (watch(SIMPLE_SKU_FIELDS_NAME.IS_GTIN_PICKING_ENABLED)?.value && !watch(SIMPLE_SKU_FIELDS_NAME.GTIN)) {
      return 'This field is required.'
    }
  }

  useEffect(() => {
    clearErrors(SIMPLE_SKU_FIELDS_NAME.GTIN)
    if (!watch(SIMPLE_SKU_FIELDS_NAME.IS_GTIN_PICKING_ENABLED)?.value) {
      setValue(SIMPLE_SKU_FIELDS_NAME.GTIN, '')
    } else {
      if (singleSkuDetail.id) {
        setValue(SIMPLE_SKU_FIELDS_NAME.GTIN, singleSkuDetail.gtin)
      }
    }
  }, [watch(SIMPLE_SKU_FIELDS_NAME.IS_GTIN_PICKING_ENABLED)])
  return (
    <div className='d-flex gap-3 flex-column mt-10px additional-details'>
      <div className='d-flex flex-column gap-2'>
        <div className='txt-h3-sb text-dark'>{t('Price Details')}</div>
        <div className='d-flex gap-16px'>
          <div className='w-100'>
            <InputField
              width='100%'
              label='Retail Price'
              name={SIMPLE_SKU_FIELDS_NAME.RETAIL_PRICE}
              isClearable
              type='number'
              disabled={(isEdit && singleSkuLoading) || bundleCreationLoading || updateBundleSkusRes.loading || imageGettingUpload}
              {...register(
                SIMPLE_SKU_FIELDS_NAME.RETAIL_PRICE
              )}
            />
          </div>
          <div className='w-100'>
            <InputField
              width='100%'
              label='Selling Price'
              name={SIMPLE_SKU_FIELDS_NAME.SELLING_PRICE}
              isClearable
              type='number'
              disabled={(isEdit && singleSkuLoading) || bundleCreationLoading || updateBundleSkusRes.loading || imageGettingUpload}
              {...register(
                SIMPLE_SKU_FIELDS_NAME.SELLING_PRICE
              )}
            />
          </div>
          <div className='w-100'>
            <FloatingDropDown
              width='100%'
              title='Price Includes Tax'
              value={watch(SIMPLE_SKU_FIELDS_NAME.SELLING_PRICE_INCLUDES_TAX)}
              name={SIMPLE_SKU_FIELDS_NAME.SELLING_PRICE_INCLUDES_TAX}
              control={control}
              // isClearable
              options={CATALOG_FLOATING_YES_AND_NO_OPTIONS}
              disabled={(isEdit && singleSkuLoading) || bundleCreationLoading || updateBundleSkusRes.loading || imageGettingUpload}
            />
          </div>
        </div>
        <div className='d-flex gap-16px'>
          <div className='w-100'>
            <InputField
              width='100%'
              label='Wholesale Price'
              name={SIMPLE_SKU_FIELDS_NAME.WHOLESALE_PRICE}
              isClearable
              type='number'
              disabled={(isEdit && singleSkuLoading) || bundleCreationLoading || updateBundleSkusRes.loading || imageGettingUpload}
              {...register(
                SIMPLE_SKU_FIELDS_NAME.WHOLESALE_PRICE
              )}
            />
          </div>
          <div className='w-100'>
            {isPosEnabled ?
              <FloatingDropDown
                width='100%'
                title='Tax Category'
                value={watch(SIMPLE_SKU_FIELDS_NAME.TAX_CATEGORY)}
                name={SIMPLE_SKU_FIELDS_NAME.TAX_CATEGORY}
                control={control}
                isClearable
                isAsync
                loadOptions={getTaxCategoriesData}
                disabled={(isEdit && singleSkuLoading) || bundleCreationLoading || updateBundleSkusRes.loading || imageGettingUpload}
                additional={{
                  page: 1,
                  seller_id: globalSeller.seller_id
                }}
              /> :
              <InputField
                width='100%'
                label='Tax Percentage'
                name={SIMPLE_SKU_FIELDS_NAME.TAX_PERCENTAGE}
                isClearable
                type='number'
                errors={errors}
                {...register(SIMPLE_SKU_FIELDS_NAME.TAX_PERCENTAGE, { validate: taxValidator })}
                disabled={(isEdit && singleSkuLoading) || bundleCreationLoading || updateBundleSkusRes.loading || imageGettingUpload}
              />
            }
          </div>
          <div className='w-100'>
            <FloatingDropDown
              isAsync
              width='100%'
              title='Currency'
              name={SIMPLE_SKU_FIELDS_NAME.PRICE_UNIT}
              value={watch(SIMPLE_SKU_FIELDS_NAME.PRICE_UNIT)}
              control={control}
              loadOptions={GET_CURRENCIES}
              disabled={(isEdit && singleSkuLoading) || bundleCreationLoading || updateBundleSkusRes.loading || imageGettingUpload}
              additional={{
                page: 1
              }}
            />
          </div>
        </div>
      </div>

      <div className='d-flex flex-column gap-2'>
        <div className='txt-h3-sb text-dark'>{t('Dimension Details')}</div>
        <div className='d-flex gap-16px'>
          <div className='w-100'>
            <InputField
              width='100%'
              label='SKU Length (In cm)'
              type='number'
              name={SIMPLE_SKU_FIELDS_NAME.SKU_LENGTH}
              isClearable
              disabled={(isEdit && singleSkuLoading) || bundleCreationLoading || updateBundleSkusRes.loading || imageGettingUpload}
              {...register(SIMPLE_SKU_FIELDS_NAME.SKU_LENGTH)}
            />
          </div>
          <div className='w-100'>
            <InputField
              width='100%'
              label='SKU width (In cm)'
              type='number'
              name={SIMPLE_SKU_FIELDS_NAME.SKU_WIDTH}
              isClearable
              disabled={(isEdit && singleSkuLoading) || bundleCreationLoading || updateBundleSkusRes.loading || imageGettingUpload}
              {...register(SIMPLE_SKU_FIELDS_NAME.SKU_WIDTH)}
            />
          </div>
          <div className='w-100'>
            <InputField
              width='100%'
              label='SKU height (In cm)'
              type='number'
              name={SIMPLE_SKU_FIELDS_NAME.SKU_HEIGHT}
              isClearable
              disabled={(isEdit && singleSkuLoading) || bundleCreationLoading || updateBundleSkusRes.loading || imageGettingUpload}
              {...register(SIMPLE_SKU_FIELDS_NAME.SKU_HEIGHT)}
            />
          </div>
        </div>
        <div className='d-flex gap-16px'>
          <div className='w-100'>
            <InputField
              width='100%'
              label='SKU weight'
              type='number'
              name={SIMPLE_SKU_FIELDS_NAME.SKU_WEIGHT}
              isClearable
              disabled={(isEdit && singleSkuLoading) || bundleCreationLoading || updateBundleSkusRes.loading || imageGettingUpload}
              {...register(SIMPLE_SKU_FIELDS_NAME.SKU_WEIGHT)}
            />
          </div>
          <div className='w-100'>
            <FloatingDropDown
              width='100%'
              title='SKU Weight Unit'
              name={SIMPLE_SKU_FIELDS_NAME.WEIGHT_UOM}
              value={watch(SIMPLE_SKU_FIELDS_NAME.WEIGHT_UOM)}
              control={control}
              disabled={(isEdit && singleSkuLoading) || bundleCreationLoading || updateBundleSkusRes.loading || imageGettingUpload}
              options={Object.values(CATALOG_UNIT_OF_MEASUREMENT).filter(
                (ele) => ele.label === CATALOG_UNIT_OF_MEASUREMENT.G.label ||
                  ele.label === CATALOG_UNIT_OF_MEASUREMENT.LBS.label ||
                  ele.label === CATALOG_UNIT_OF_MEASUREMENT.KG.label
              )}
              // isClearable
            />
          </div>
          <div className='w-100'></div>
        </div>
      </div>

      <div className='d-flex flex-column gap-2'>
        <div className='txt-h3-sb text-dark'>{t('Additional Details')}</div>
        <div className='d-flex gap-16px'>
          <div className='w-100'>
            <InputField
              width='100%'
              label='Brand'
              name={SIMPLE_SKU_FIELDS_NAME.BRAND}
              isClearable
              disabled={(isEdit && singleSkuLoading) || bundleCreationLoading || updateBundleSkusRes.loading || imageGettingUpload}
              {...register(SIMPLE_SKU_FIELDS_NAME.BRAND)}
            />
          </div>
          <div className='w-100'>
            <InputField
              width='100%'
              label='Manufacturer'
              name={SIMPLE_SKU_FIELDS_NAME.MANUFACTURER}
              isClearable
              disabled={(isEdit && singleSkuLoading) || bundleCreationLoading || updateBundleSkusRes.loading || imageGettingUpload}
              {...register(SIMPLE_SKU_FIELDS_NAME.MANUFACTURER)}
            />
          </div>
          <div className='w-100'>
            <InputField
              width='100%'
              label='Country of Origin'
              name={SIMPLE_SKU_FIELDS_NAME.COUNTRY_OF_ORIGIN}
              isClearable
              disabled={(isEdit && singleSkuLoading) || bundleCreationLoading || updateBundleSkusRes.loading || imageGettingUpload}
              {...register(SIMPLE_SKU_FIELDS_NAME.COUNTRY_OF_ORIGIN)}
            />
          </div>
        </div>
        <div className='d-flex gap-16px'>
          <div className='w-100'>
            <InputField
              width='100%'
              label='HS Code'
              name={SIMPLE_SKU_FIELDS_NAME.HS_CODE}
              isClearable
              errors={errors}
              disabled={(isEdit && singleSkuLoading) || bundleCreationLoading || updateBundleSkusRes.loading || imageGettingUpload}
              {...register(SIMPLE_SKU_FIELDS_NAME.HS_CODE)}
            />
          </div>
          <div className='w-100'></div>
          <div className='w-100'></div>
          {/* <div className='w-100'>
            <FloatingDropDown
              width='100%'
              title='Is GTIN Enabled'
              value={watch(SIMPLE_SKU_FIELDS_NAME.IS_GTIN_PICKING_ENABLED)}
              name={SIMPLE_SKU_FIELDS_NAME.IS_GTIN_PICKING_ENABLED}
              control={control}
              // isClearable
              options={CATALOG_FLOATING_YES_AND_NO_OPTIONS}
              disabled={(isEdit && singleSkuLoading) || bundleCreationLoading || updateBundleSkusRes.loading || imageGettingUpload}
            />
          </div>
          <div className='w-100'>
            <InputField
              width='100%'
              label='GTIN'
              name={SIMPLE_SKU_FIELDS_NAME.GTIN}
              isClearable
              isRequired={watch(SIMPLE_SKU_FIELDS_NAME.IS_GTIN_PICKING_ENABLED)?.value}
              errors={errors}
              disabled={(isEdit && singleSkuLoading) || bundleCreationLoading || updateBundleSkusRes.loading || imageGettingUpload ||  !(watch(SIMPLE_SKU_FIELDS_NAME.IS_GTIN_PICKING_ENABLED)?.value)}
              {...register(SIMPLE_SKU_FIELDS_NAME.GTIN, {validate: gtinValidator})}
            />
          </div> */}
        </div>
      </div>
    </div>
  )
}

export default SingleSkuMetaData
