type iSVGprops = {
  height: number,
  width: number,
  iswhite?: boolean,
  fillColor?: string
}
const SVG = ({ height, width, iswhite, fillColor }: iSVGprops) => {
  const color = iswhite ? 'white' : fillColor;
  return <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M15.375 2.81998C12.169 2.81998 9.57001 5.41897 9.57001 8.62498C9.57001 11.831 12.169 14.43 15.375 14.43C18.581 14.43 21.18 11.831 21.18 8.62498C21.18 5.41897 18.581 2.81998 15.375 2.81998ZM8.79669 7.67998C9.25547 4.45763 12.0259 1.97998 15.375 1.97998C19.0449 1.97998 22.02 4.95505 22.02 8.62498C22.02 12.2949 19.0449 15.27 15.375 15.27C15.239 15.27 15.104 15.2659 14.97 15.2578V18.45C14.97 19.6761 13.9761 20.67 12.75 20.67H4.20001C2.97394 20.67 1.98001 19.6761 1.98001 18.45V17.55C1.98001 17.318 2.16805 17.13 2.40001 17.13H3.78001V8.09998C3.78001 7.86802 3.96805 7.67998 4.20001 7.67998H8.79669ZM8.73082 8.51998H4.62001V17.13H10.95C11.182 17.13 11.37 17.318 11.37 17.55V18.45C11.37 19.2121 11.9879 19.83 12.75 19.83C13.5122 19.83 14.13 19.2121 14.13 18.45V15.1535C13.4661 15.0277 12.8372 14.8031 12.2591 14.4956C12.2158 14.6815 12.0491 14.82 11.85 14.82H6.90001C6.66805 14.82 6.48001 14.6319 6.48001 14.4C6.48001 14.168 6.66805 13.98 6.90001 13.98H11.4398C10.5884 13.3532 9.89182 12.5283 9.41821 11.5732C9.34546 11.6336 9.25198 11.67 9.15001 11.67H6.90001C6.66805 11.67 6.48001 11.4819 6.48001 11.25C6.48001 11.018 6.66805 10.83 6.90001 10.83H9.1046C8.86197 10.14 8.73001 9.3979 8.73001 8.62498C8.73001 8.58992 8.73028 8.55492 8.73082 8.51998ZM11.0109 19.83C10.7099 19.4511 10.53 18.9715 10.53 18.45V17.97H2.82001V18.45C2.82001 19.2121 3.43786 19.83 4.20001 19.83H11.0109Z" fill={color}/>
    <path fillRule="evenodd" clipRule="evenodd" d="M15.3301 4.43213C15.562 4.43213 15.7501 4.62017 15.7501 4.85213V5.31335C16.3098 5.38642 16.7252 5.6082 17.0103 5.92496C17.3531 6.30584 17.4601 6.77178 17.4601 7.13221C17.4601 7.36417 17.2721 7.55221 17.0401 7.55221C16.8082 7.55221 16.6201 7.36417 16.6201 7.13221C16.6201 6.92262 16.5562 6.67603 16.3859 6.48689C16.2252 6.30829 15.9215 6.12716 15.3301 6.12716C14.7535 6.12716 14.4475 6.32209 14.2798 6.51614C14.099 6.72539 14.04 6.97946 14.04 7.13221C14.04 7.28495 14.099 7.53902 14.2798 7.74828C14.4475 7.94233 14.7535 8.13726 15.3301 8.13726C15.6686 8.13726 16.1764 8.21901 16.6149 8.48212C17.0754 8.75838 17.4601 9.24046 17.4601 9.9823C17.4601 10.7242 17.0754 11.2062 16.6149 11.4825C16.3342 11.6509 16.025 11.7451 15.7501 11.791V12.2624C15.7501 12.4943 15.562 12.6824 15.3301 12.6824C15.0981 12.6824 14.9101 12.4943 14.9101 12.2624V11.791C14.6351 11.7451 14.3259 11.6509 14.0452 11.4825C13.5848 11.2062 13.2 10.7242 13.2 9.9823C13.2 9.75035 13.3881 9.5623 13.62 9.5623C13.852 9.5623 14.04 9.75035 14.04 9.9823C14.04 10.3805 14.2253 10.6109 14.4774 10.7622C14.7514 10.9266 15.0986 10.9874 15.3301 10.9874C15.5615 10.9874 15.9088 10.9266 16.1828 10.7622C16.4349 10.6109 16.6201 10.3805 16.6201 9.9823C16.6201 9.58411 16.4349 9.35366 16.1828 9.20241C15.9088 9.038 15.5615 8.97726 15.3301 8.97726C14.5386 8.97726 13.9896 8.69717 13.6442 8.29745C13.312 7.91293 13.2 7.45448 13.2 7.13221C13.2 6.80994 13.312 6.35148 13.6442 5.96697C13.9248 5.64222 14.3398 5.39643 14.9101 5.31566V4.85213C14.9101 4.62017 15.0981 4.43213 15.3301 4.43213Z" fill={color}/>
  </svg>;    
};
export default SVG;