import Button from '@src/@core/components/ui/button'
import { AlertTriangle } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { Modal, ModalBody } from 'reactstrap'

const BatchSelectWarningModal = ({isOpen, toggle, handleDiscardProcess}) => {

  const {t} = useTranslation()

  return (
    <Modal
      isOpen={isOpen}
      centered
      toggle={() => {}}
      contentClassName='rounded-12px'
    >
      <ModalBody className="d-flex flex-column py-16px px-20px ">
        <div className="d-flex flex-column gap-8px mb-32px align-items-center">
          <AlertTriangle style={{ strokeWidth: 1.5, color: '#FF991F' }} size={80} fill="currentColor" strokeColor="white" stroke="white" />
          <div className='txt-h3-sb text-dark align-self-start'>
            {t('Quantity Mismatch!')}
          </div>
          <div className='txt-body-md text-dark-6 align-self-start'>{t('The selected quantity in Batch does not match the ordered quantity.')}</div>
        </div>
        <div className='d-flex gap-20px justify-content-end'>
          <Button
            onClick={handleDiscardProcess}
            ofStyle='noBackground'
          >
            {t('Discard')}
          </Button>
          <Button
            onClick={toggle}
          >
            {t('Edit Batches')}
          </Button>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default BatchSelectWarningModal