import classNames from 'classnames'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import PerfectScrollBar from 'react-perfect-scrollbar'
import { ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap'
import ChevronClose from './ChevronClose'
import ChevronOpenIcon from './ChevronOpen'
import Close from './Close.svg'
import SearchIcon from './Search'
import ShortcutChip from './Shortcut_Chip.svg'
import './styles.scss'

const CustomSearchDropDown = ({ width, columnsList = [], search_column_query = { column: null, query: null }, handleQueryParams }) => {
  const { t } = useTranslation()
  const inputRef = useRef(null)
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const [searchVal, setSearchVal] = useState(search_column_query.query ? search_column_query.query : '')
  const [selectedColumn, setSelectedColumn] = useState(search_column_query.column ? search_column_query.column : columnsList[0])
  const hasMultipleColumns = columnsList.length > 1
  const toggle = () => { if (hasMultipleColumns) setIsDropdownOpen((prevState) => !prevState) }

  // Detect the user's operating system
  const isMac = window.navigator.platform.indexOf('Mac') !== -1

  useEffect(() => {
    const handleKeyPress = (event) => {
      if ((event.ctrlKey || event.metaKey) && event.key === 'k') {
        event.preventDefault()
        inputRef.current.focus()
      }
    }
    document.addEventListener('keydown', handleKeyPress)

    return () => {
      document.removeEventListener('keydown', handleKeyPress)
    }
  }, [])

  const handleSelectedColumn = (columnName) => {
    if (searchVal) setSearchVal('')
    setSelectedColumn(columnName)
  }

  return (
    <>
      <div className='custom-search-dropdown-main-wrapper' style={{ width }}>
        <div className='w-100 custom-search-dropdown d-flex align-items-center gap-12px'>
          <ButtonDropdown isOpen={hasMultipleColumns && isDropdownOpen} toggle={toggle} className={classNames('d-flex align-items-center dropdown-button', {'dropdown-focus-state': isDropdownOpen, 'has-multiple-columns': hasMultipleColumns})}>
            <DropdownToggle className={classNames('dropdown-toogle-container', { 'pe-12px': hasMultipleColumns })}>
              <div className='search-icon'> <SearchIcon /> </div>
              {hasMultipleColumns && <div className='dropdown-icon'>
                {isDropdownOpen ? <ChevronOpenIcon /> : <ChevronClose />}
              </div>}
            </DropdownToggle>
            <DropdownMenu>
              <PerfectScrollBar onWheel={(e) => e.stopPropagation()} >
                <div className='menu overflow-auto'>
                  {columnsList.map((column) => (
                    <DropdownItem key={column.id} onClick={() => handleSelectedColumn(column)}
                      className={classNames('d-flex justify-content-center ps-0 pe-0 align-items-center m-0',
                        { 'selected ': column.id === selectedColumn.id })}
                    >
                      <div className='w-100 overflow-hidden text-truncate'>
                        {t(column.name)}
                      </div>
                    </DropdownItem>
                  ))}
                </div>
              </PerfectScrollBar>
            </DropdownMenu>
            {hasMultipleColumns && <div className="vertical-line"></div>}
          </ButtonDropdown>
          <form className='input-field d-flex align-items-center flex-fill' onSubmit={(e) => {
            e.preventDefault()
            columnsList.length < 1 ? handleQueryParams(searchVal) : handleQueryParams({ selectedColumn, searchVal })
          }}>
            <input
              ref={inputRef}
              placeholder={`${(columnsList.length < 1)
                ? t('Search Here') : t(`Search in ${selectedColumn.name}`)}`
              }
              value={searchVal}
              type="search"
              onChange={(e) => {
                setSearchVal(e.target.value)
                if (!e.target.value) setSearchVal('')
              }}
            />
            <div className='search-clear-button cursor-pointer'>
              {searchVal ? <div className='clear-icon-caontainer me-16px'>
                <img src={Close} alt='clear-icon' height={16} width={16} onClick={() => {
                  setSearchVal('')
                  if (search_column_query.query) columnsList.length < 1 ? handleQueryParams('') : handleQueryParams({ selectedColumn, searchVal: '' })
                }} />
              </div> : <div className='shortcut-icon-container'> {
                isMac ?
                  <img className='shortcut-icon bg-white txt-sub-rg text-dark rounded-4px' src={ShortcutChip} alt="shortcut-icon" height={16} width={30}/> :
                  <span className='bg-white w-100 txt-sub-rg text-dark px-4px py-2px rounded-4px'>Ctrl + k</span>
              }
              </div>
              }
            </div>
          </form>
        </div>
      </div>
    </>
  )
}
export default CustomSearchDropDown
