import Button from '@src/@core/components/ui/button'
import SidesheetFooter from '@src/@core/components/ui/sidesheet-footer'
import { FILE_TYPES } from '@src/App.constants'
import { AllAttatchmentsRemovedIllustration } from '@src/assets/data/assets'
import { ATTACHMENTS_SIDESHEET_VIEW } from '@src/views/sales/sales.constant'
import classNames from 'classnames'
import { saveAs } from 'file-saver'
import JSZip from 'jszip'
import { useState } from 'react'
import { Download, Paperclip, Trash2 } from 'react-feather'
import { useFieldArray } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Spinner } from 'reactstrap'
import AddDescription from './addDescription'
import FileIcon from './file-icon'
import Preview from './preview/preview'
import image_fallback from '@src/assets/images/omniful/image_fallback.svg'

const EmptyState = () => {
  const { t } = useTranslation()
  return (
    <div className='border border-light-4 rounded-8px flex-center-center attachments-empty-state'>
      <div className='d-flex flex-column gap-24px width-fit-content align-items-center'>
        <div>
          <AllAttatchmentsRemovedIllustration />
        </div>
        <div className='d-flex flex-column gap-4px align-items-center'>
          <div className='txt-body-md text-dark'>{t('All Attachments Removed')}</div>
          <div className='txt-sub-rg text-dark-6'>{t('Click')} <span className='txt-sub-md'>{t('\'Save Changes\'')}</span> {t('to confirm the updates')}.</div> :
        </div>
      </div>
    </div>
  )
}

const ExistingAttachments = ({ formObject, handleChangeCurrentView, handleUploadFiles, isUploading }) => {
  const { t } = useTranslation()
  const { remove: removeExistingFile } = useFieldArray({ control: formObject.control, name: 'existingFiles' })
  const [previewingAttachment, setPreviewingAttachment] = useState(null)
  const [isDownloading, setDownloading] = useState(false)
  const [isDescriptionPopupOpen, setDescriptionPopupOpen] = useState(false)
  const [selectedAttachment, setSelectedAttachment] = useState(null)
  const existingFilesInDirtyFields = formObject.formState.dirtyFields.existingFiles
  const isExistingFilesDirty = existingFilesInDirtyFields?.some(file => !!file.description)

  // handlers
  const handleTogglePreview = (attachment) => {
    if (previewingAttachment) {
      setPreviewingAttachment(null)
    } else {
      setPreviewingAttachment(attachment)
    }
  }

  const handleSingleDownload = async (item) => {
    try {
      const res = await fetch(item.file_url)
      if (res.status >= 400) {
        throw new Error('Not allowed to download this file.');
      } else if (!res.ok) {
        throw new Error(`Failed to download file: ${res.statusText}`);
      }
      const blob = await res.blob() // Get the response and return it as a blob
      saveAs(blob, item.file?.name) // Save the file using FileSaver.js
    } catch (error) {
      console.error('Download error:', error); // Log the error for debugging
    } finally {
      setDownloading(false); // Reset the downloading state
    }
  }

  const handleDownloadAll = async () => {
    
    const files = formObject.watch('existingFiles') || []

    setDownloading(true)
    if (files.length < 6) {
      files.forEach(item => {
        handleSingleDownload(item)
      })
      return
    }
    const zip = new JSZip();
    for (const item of files) {
      try {
        const response = await fetch(item.file_url)
        const blob = await response.blob()
        zip.file(item.name, blob);
      } catch (error) {
        console.error(`Failed to fetch file: ${item.name}`, error);
      }
    }
    zip.generateAsync({ type: 'blob' })
      .then((content) => {
      // Use FileSaver.js to download the ZIP file
        saveAs(content, 'attachments.zip');
      })
      .catch((error) => {
        console.error('Failed to generate ZIP file', error)
      })
      .finally(() => {
        setDownloading(false)
      })
  }

  const handleRemoveFile = (removeItemIndex) => {
    removeExistingFile(removeItemIndex)
  }

  const handleToggleDescription = (item, index) => {
    if (isDescriptionPopupOpen) {
      setDescriptionPopupOpen(prev => !prev)
    } else {
      item.index = index
      setSelectedAttachment(item)
      setDescriptionPopupOpen(prev => !prev)
    }
  }

  const handleAddDescription = (index, description) => {
    formObject.setValue(`existingFiles.${index}.description`, description, { shouldDirty: true })
  }

  const handleRemoveDescription = (index) => {
    formObject.setValue(`existingFiles.${index}.description`, '', { shouldDirty: true })
  }

  return (
    <div className='attachments-main-div d-flex flex-column gap-8px'>
      <div className='flex-center-between'>
        <div className='txt-body-md text-dark'>{t('Attachments')}</div>
        <div>
          <Button icon={Paperclip} iconSize={16} className='py-8px px-12px d-flex gap-4px shadow-none' ofStyle='noBackground' onClick={() => handleChangeCurrentView(ATTACHMENTS_SIDESHEET_VIEW.UPLOAD)}>{t('Add Attachments')}</Button>
        </div>
      </div>
      {formObject.watch('existingFiles')?.length > 0 ?
        ( <div className='d-flex flex-column gap-12px'>
          {formObject.watch('existingFiles')?.map((item, index) => { 
            const fileType = item.file.type.split('/')[0] // Get the file type (image, video, text, etc.)
            const fileName = item.file.name
            const fileUrl = item.preview_url || item.file_url
            const isPreviewAble = item.file.type.startsWith('image/') || item.file.type === FILE_TYPES.PDF.type
            return (
              <div key={index} className="rounded-8px border border-dark-2 bg-white p-10px flex-center-between upload_file_details">
                <div className="flex-center-start gap-10px width-80-per">
                  {fileType === 'image' ?
                    <div className='file-icon-wrapper flex-shrink-0 rounded-4px border border-dark-2'>
                      <img
                        src={fileUrl || image_fallback}
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null
                          currentTarget.src = image_fallback
                        }}
                        alt={fileName}
                        className='rounded-4px object-fit-contain w-100 h-100'
                      />
                    </div>
                    :
                    <div className="rounded-4px bg-light-2 flex-center-center file-icon-wrapper flex-shrink-0 rounded-4px border border-dark-2">
                      <FileIcon fileType={fileType} size={24} />
                    </div>
                  }
                  <div className={classNames('d-flex flex-column width-90-per', {
                    'gap-4px': item.description,
                    'gap-12px': !item.description
                  })}>
                    <div className='d-flex flex-column gap-2px w-100'>
                      <div className="txt-sub-md text-dark w-100 text-truncate file_title"
                        onClick={() => {
                          const properties = { name: fileName, type: fileType, file_url: fileUrl }
                          if (isPreviewAble) {
                            handleTogglePreview(properties)
                          } else {
                            handleSingleDownload(item)
                          }
                        }}
                      >
                        {fileName}
                      </div>
                      {item.description && <div className="txt-sub-rg text-dark-6 w-100 text-truncate">{item.description}</div>}
                    </div>
                    {item.description ?
                      <div className='flex-center-start gap-16px'>
                        <Button ofType="compressed" ofStyle="noBackground" className="txt-sub-md p-0 shadow-none" onClick={() => handleToggleDescription(item, index)}>
                          {t('Edit')}
                        </Button>
                        <Button ofType="compressed" ofStyle="noBackground" className="txt-sub-md p-0 shadow-none" onClick={() => handleRemoveDescription(index, '')}>
                          {t('Remove')}
                        </Button>
                      </div> :
                      <div>
                        <Button ofType="compressed" ofStyle="noBackground" className="txt-sub-md p-0 shadow-none" onClick={() => handleToggleDescription(item, index)}>
                          { t('Add Description')}
                        </Button>
                      </div>
                    }
                  </div>
                </div>
                <div className="flex-center-end gap-16px flex-shrink-0">
                  <Download 
                    size={16} color="var(--bs-primary)"
                    className="cursor-pointer"
                    onClick={() => handleSingleDownload(item)}
                  />
                  <Trash2 
                    size={16} color="var(--bs-danger)" 
                    className="cursor-pointer"
                    onClick={() => handleRemoveFile(index)}
                  />
                </div>
              </div>
            )
          })}
        </div>) :
        <EmptyState />
      }

      <SidesheetFooter>
        <div className="flex-center-end gap-12px">
          {!!formObject.watch('existingFiles')?.length && <Button ofStyle="outlined" onClick={handleDownloadAll} disabled={isDownloading || isUploading} icon={isDownloading ? Spinner : null} iconSize="sm">
            {t('Download All')}
          </Button>}
          {isExistingFilesDirty && <Button onClick={handleUploadFiles} disabled={isUploading} icon={isUploading ? Spinner : null} iconSize="sm">
            {t('Save Changes')}
          </Button>}
        </div>
      </SidesheetFooter>

      <Preview 
        isOpen={!!previewingAttachment} 
        toggle={handleTogglePreview} 
        previewingAttachment={previewingAttachment}
      />

      <AddDescription 
        isOpen={isDescriptionPopupOpen}
        toggle={handleToggleDescription}
        index={selectedAttachment?.index}
        description={selectedAttachment?.description}
        handleDescription={handleAddDescription} 
      />
    </div>
  )
}

export default ExistingAttachments