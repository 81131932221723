// ** Vertical Menu Components
import VerticalNavMenuGroup from './VerticalNavMenuGroup'
import VerticalNavMenuLink from './VerticalNavMenuLink'
import VerticalNavMenuSectionHeader from './VerticalNavMenuSectionHeader'

// ** Utils
import {
  canViewMenuGroup,
  canViewMenuItem,
  resolveVerticalNavMenuItemComponent as resolveNavItemComponent
} from '@layouts/utils'
import { TENANT_TYPE, selfTenantExcludedRouteIds } from '@src/App.constants'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'


const VerticalMenuNavItems = props => {
  const params = useParams()
  const hubId = useSelector(state => state.revampedHubs.selectedHubId)
  // const selectedHubConfiguration = useSelector(state => state.auth.selectedHubConfiguration)
  // const locationHubConfig = selectedHubConfiguration?.find(el => el.configuration_type === HUBS_CONFIGURATION.LOCATION_INVENTORY.value)
  // const isLocationInventoryEnabled = locationHubConfig?.configuration_values?.enabled
  // const { p } = params
  const tenantType = useSelector((state) => state.auth.userData?.tenant.type)

  // ** Components Object
  const Components = {
    VerticalNavMenuLink,
    VerticalNavMenuGroup,
    VerticalNavMenuSectionHeader
  }
  // ** Render Nav Menu Items
  const RenderNavItems = props.items.map((item, index) => {
    // if (!isLocationInventoryEnabled && item.id === 'inventory-operations') {
    //   return
    // }

    if (tenantType === TENANT_TYPE.SELF_USAGE && selfTenantExcludedRouteIds.includes(item.id)) {
      return
    }    

    const TagName = Components[resolveNavItemComponent(item)]
    // ** secondaryMenu dyanamic check
    const copiedItemObject = { ...item }
    Object.keys(params).map(key => {
      copiedItemObject.navLink = copiedItemObject.navLink.replace(`:${key}`, params[key])
      if (key === 'hubId') copiedItemObject.navLink = copiedItemObject.navLink.replace(`:${key}`, hubId)
    })
    if (item.children) {
      return (
        canViewMenuGroup(item) && <TagName item={copiedItemObject} index={index} key={copiedItemObject.id} {...props} />
      )
    }
    return (
      canViewMenuItem(item) && <TagName key={copiedItemObject.id || item.header} item={copiedItemObject} {...props} />
    )
  })

  return RenderNavItems
}

export default VerticalMenuNavItems
