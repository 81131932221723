import { HUBS_CONFIGURATION } from '@src/App.constants'
import abilityMap from '@src/assets/data/abilityMapping/abilityMapping'
import clickCollect from '@src/assets/images/hubs/click_collect.svg'
import fastDelivery from '@src/assets/images/hubs/fast_delivery.svg'
import scheduledDelivery from '@src/assets/images/hubs/scheduled_delivery.svg'
import Hub from '@src/assets/images/icons/primary-navbar/Hub'
import Config from '@src/assets/images/icons/primary-navbar/secondary-navbar/hubs/Config'
import Details from '@src/assets/images/icons/primary-navbar/secondary-navbar/hubs/Details'
import WorkingHour from '@src/assets/images/icons/primary-navbar/secondary-navbar/hubs/WorkingHour'
import { currencyOptionHub } from '@src/utility/Utils'
import { Edit, MoreVertical, Plus } from 'react-feather'
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown
} from 'reactstrap'
import sample_barcode_1_img from '../../assets/images/hub-location-configuration/sample_barcode_1.svg'
import sample_barcode_2_img from '../../assets/images/hub-location-configuration/sample_barcode_2.svg'
import sample_barcode_3_img from '../../assets/images/hub-location-configuration/sample_barcode_3.svg'
import { FIRST_INDEX } from '../settings/settings.constants'
import AllDeliveryZones from './pages/AllDeliveryZones'
import HubsList from './pages/AllHubs/HubsList'
import {
  cleanupCreateAisle,
  cleanupCreateFloor,
  cleanupCreateLocation,
  cleanupCreateRack,
  cleanupCreateShelf,
  cleanupEditAisleResponse,
  cleanupEditFloorResponse,
  cleanupEditLocationResponse,
  cleanupEditRackResponse,
  cleanupEditShelfResponse,
  cleanupParticularAisleResponse,
  cleanupParticularFloorResponse,
  cleanupParticularLocationResponse,
  cleanupParticularRackResponse,
  cleanupParticularShelfResponse,
  createAisle,
  createFloor,
  createLocation,
  createRack,
  createShelf,
  editSingleAisle,
  editSingleFloor,
  editSingleLocation,
  editSingleRack,
  editSingleShelf,
  getAisles,
  getAislesAsync,
  getFloors,
  getFloorsAsync,
  getLocations,
  getLocationsAsync,
  getParticularAisle,
  getParticularFloor,
  getParticularLocation,
  getParticularRack,
  getParticularShelf,
  getRacks,
  getRacksAsync,
  getShelves,
  getShelvesAsync
} from './store'

const MapIcon = (color) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.2 14L5.81667 12.45L2.83333 13.6333C2.64444 13.7333 2.45833 13.7278 2.275 13.6167C2.09167 13.5056 2 13.3389 2 13.1167V3.81667C2 3.67222 2.04167 3.54444 2.125 3.43333C2.20833 3.32222 2.31667 3.23889 2.45 3.18333L5.81667 2L10.2 3.53333L13.1667 2.35C13.3556 2.26111 13.5417 2.26944 13.725 2.375C13.9083 2.48056 14 2.64444 14 2.86667V12.2833C14 12.4056 13.9583 12.5111 13.875 12.6C13.7917 12.6889 13.6889 12.7556 13.5667 12.8L10.2 14ZM9.63333 12.75V4.33333L6.36667 3.23333V11.65L9.63333 12.75Z"
      fill={color || 'black'}
    />
  </svg>
)

const TableIcon = (color) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2 14V2H14V14H2ZM3 5.66667H13V3H3V5.66667ZM6.66667 9.33333H9.33333V6.66667H6.66667V9.33333ZM6.66667 13H9.33333V10.3333H6.66667V13ZM3 9.33333H5.66667V6.66667H3V9.33333ZM10.3333 9.33333H13V6.66667H10.3333V9.33333ZM3 13H5.66667V10.3333H3V13ZM10.3333 13H13V10.3333H10.3333V13Z"
      fill={color || 'black'}
    />
  </svg>
)

export const ROUTES = {
  allHubs: '/hubs',
  createHub: '/hubs/create'
}

function validateEmail(email) {
  const regex = /^[\w\-.]+@[\w\-.]+(\.\w{2,3})+$/
  return regex.test(email) || 'Enter valid email'
}

export const GET_HUB_CREATE_BASIC_DETAILS_FIELDS = () => ({
  defaultValues: {
    hubName: '',
    warehouseType: '',
    hubEmail: '',
    contactNumber: '',
    currency: currencyOptionHub[FIRST_INDEX],
    contactDetails: {
      countryCode: {
        name: 'Saudi Arabia',
        flag: '🇸🇦',
        country_code: 'SA',
        calling_code: '+966',
        value: 'Saudi Arabia (SA)',
        label: 'Saudi Arabia (+966)',
        maxPhoneNumberLength: 9
      },
      contactNumber: ''
    },
    hubCode: '',
    PosId: '',
    clickCollect: false,
    addressLine1: '',
    addressLine2: '',
    country: '',
    state: '',
    city: '',
    pincode: '',
    latitude: '',
    longitude: '',
    isPosEnabled: false
  },
  validation: {
    hubName: { required: 'Please enter hub name' },
    warehouseType: { required: 'Please select warehouse type' },
    hubEmail: {
      validate: {
        required: (fieldValue) => !!fieldValue || 'Please enter hub Email ID',
        email: (fieldValue) => validateEmail(fieldValue)
      }
    },
    contactDetails: { contactNumber: { required: 'Please enter Contact Number' } },
    hubCode: {
      validate: {
        required: (fieldValue) => !!fieldValue || 'Please enter hub code',
        alphaNumeric: (fieldValue) => !!fieldValue?.match(/^[a-z0-9\s-]+$/i)?.length || 'Hub Code can only contain alphabets, numerics, and hyphens'
      }
    },
    currency: { required: 'Please select currency' },
    isPosEnabled: {},
    PosId: {
      validate: (value, formValues) => {
        if (!formValues.isPosEnabled) return true
        return value?.length || 'POS ID is required'
      }
    },
    clickCollect: {},
    addressLine1: { required: 'Please enter address' },
    addressLine2: { required: 'Please enter address' },
    country: { required: 'Please select country' },
    city: { required: 'Please select city' }  }
})

export const MULTI_ORDER_PICKING_TYPE = {
  COMPLETE_PICKING: '1',
  PARTIAL_PICKING: '3'
}

export const HUB_CONFIGURATION_TYPES = {
  PICKING: 'picking',
  LOCATION_INVENTORY: 'location_inventory',
  GATE_ENTRY: 'gate_entry',
  PURCHASE_ORDER: 'purchase_order',
  STOCK_TRANSFER_ORDER: 'stock_transfer_order',
  INPUT_CONFIG: 'input_configuration',
  CYCLE_COUNT_CONFIG: 'cycle_count_configuration',
  PACKING_CONFIG: 'packing_configuration',
  ADJUST_INVENTORY: 'adjust_inventory',
  PUT_AWAY: 'put_away',
  BIN_CONFIG: 'bin',
  ACCEPTABLE_SHELF_LIFE: 'acceptable_shelf_life_validation',
  SCHEDULE_ORDER: 'schedule_order',
}

export const GET_FORMATTED_CONFIG_DATA = (data = {}) => [
  {
    configuration_type: 'picking',
    configuration_values: {
      picker_capacity: +data?.picker_capacity || undefined,
      cart_capacity: +data?.cart_capacity || undefined,
      multi_order_picking_type: data?.multi_order_picking_allowed ? +data?.multi_order_picking_type : undefined,
      multi_order_picking_allowed: !!data?.multi_order_picking_allowed || false,
      single_order_picking_allowed:
        !!data?.single_order_picking_allowed || false,
      location_scan_enabled: !!data?.scan_location_enabled || false,
      bin_scan_enabled: !!data?.scan_bin_enabled || false,
      manual_barcode_allowed: !!data?.type_manual_barcode_allowed || false,
      enter_quantity_manually: !!data?.type_enter_quantity_manually || false,
      single_order_tags: data?.single_order_tags?.map(tag => ({
        id: tag.value,
        name: tag.label,
        color: tag.color
      })),
      single_piece_picking_cart_association: !!data?.single_piece_picking_cart_association,
      multi_piece_picking_cart_association: !!data?.multi_piece_picking_cart_association
    }
  },
  {
    configuration_type: HUBS_CONFIGURATION.LOCATION_INVENTORY.value,
    configuration_values: {
      enabled: data?.location_inventory_enabled || false
    }

  },
  {
    configuration_type: HUBS_CONFIGURATION.BIN_CONFIG.value,
    configuration_values: {
      enabled: data?.bin_config_enabled || false
    }
  },
  {
    configuration_type: 'gate_entry',
    configuration_values: {
      enabled: (data?.location_inventory_enabled && data?.gate_entry_enabled) || false
    }
  },
  {
    configuration_type: 'stock_transfer_order',
    configuration_values: {
      enabled: data?.stock_transfer_order || false
    }
  },
  {
    configuration_type: 'purchase_order',
    configuration_values: {
      enabled: data?.purchase_order_enabled || false,
      over_receive_allowed: data?.over_receive_allowed || false
    }
  },
  {
    configuration_type: 'acceptable_shelf_life_validation',
    configuration_values: {
      enabled: data?.acceptable_shelf_life_validation || false
    }
  },
  // {
  //   configuration_type: "input_configuration",
  //   configuration_values: {
  //     enter_quantity_manually: data?.enter_quantity_manually || false
  //   }
  // },
  {
    configuration_type: 'cycle_count_configuration',
    configuration_values: {
      blind_count: data?.blind_count || false,
      update_inventory: data?.update_inventory || false,
      manual_barcode_allowed: data?.cycle_count_manual_barcode_allowed || false,
      enter_quantity_manually: data?.cycle_count_enter_quantity_manually || false
    }
  },
  {
    configuration_type: 'packing_configuration',
    configuration_values: {
      qc_enabled: data?.qc_enabled || false
    }
  },
  {
    configuration_type: 'adjust_inventory',
    configuration_values: {
      manual_barcode_allowed: data?.adjust_inventory_manual_barcode_allowed || false,
      enter_quantity_manually: data?.adjust_inventory_enter_quantity_manually || false
    }
  },
  {
    configuration_type: 'put_away',
    configuration_values: {
      manual_barcode_allowed: data?.putaway_manual_barcode_allowed || false,
      enter_quantity_manually: data?.putaway_enter_quantity_manually || false
    }
  },
  {
    configuration_type: 'schedule_order',
    configuration_values: {
      enabled: data?.schedule_order_allowed || false,
      time:{
        hour: `${isNaN(+data?.schedule_order_hours) ? '' : +data.schedule_order_hours}`,
        minute: `${isNaN(+data?.schedule_order_minutes) ? '' : +data.schedule_order_minutes}`
      }
    }
  }
]

export const GET_FORMATTED_EDIT_CONFIG_DATA = (data = {}) => [
  {
    configuration_type: 'picking',
    configuration_values: {
      picker_capacity: +data?.picker_capacity || undefined,
      cart_capacity: +data?.cart_capacity || undefined,
      location_scan_enabled: !!data?.scan_location_enabled || false,
      bin_scan_enabled: !!data?.scan_bin_enabled || false,
      manual_barcode_allowed: !!data?.type_manual_barcode_allowed || false,
      enter_quantity_manually: !!data?.type_enter_quantity_manually || false,
      single_piece_picking_cart_association: !!data?.single_piece_picking_cart_association,
      multi_piece_picking_cart_association: !!data?.multi_piece_picking_cart_association,
      single_order_tags: (data?.single_order_tags || []).map(tag => ({color: tag.color, name: tag.label, id: tag.value})),
      single_order_picking_allowed: !!data?.single_order_picking_allowed || false,
      multi_order_picking_allowed: !!data?.multi_order_picking_allowed || false,
      multi_order_picking_type: data?.multi_order_picking_allowed ? +data?.multi_order_picking_type : undefined
    }
  },
  {
    configuration_type: HUBS_CONFIGURATION.LOCATION_INVENTORY.value,
    configuration_values: {
      enabled: data?.location_inventory_enabled || false
    }
  },
  {
    configuration_type: 'gate_entry',
    configuration_values: {
      enabled: data?.gate_entry_enabled || false
    }
  },
  {
    configuration_type: 'purchase_order',
    configuration_values: {
      enabled: data?.purchase_order_enabled || false,
      over_receive_allowed: data?.over_receive_allowed || false
    }
  },
  {
    configuration_type: 'stock_transfer_order',
    configuration_values: {
      enabled: data?.stock_transfer_order || false
    }
  },
  {
    configuration_type: 'acceptable_shelf_life_validation',
    configuration_values: {
      enabled: data?.acceptable_shelf_life_validation || false
    }
  },
  // {
  //   configuration_type: "input_configuration",
  //   configuration_values: {
  //     enter_quantity_manually: data?.enter_quantity_manually || false
  //   }
  // },
  {
    configuration_type: 'cycle_count_configuration',
    configuration_values: {
      blind_count: data?.blind_count || false,
      update_inventory: data?.update_inventory || false,
      manual_barcode_allowed: data?.cycle_count_manual_barcode_allowed || false,
      enter_quantity_manually: data?.cycle_count_enter_quantity_manually || false
    }
  },
  {
    configuration_type: 'packing_configuration',
    configuration_values: {
      qc_enabled: data?.qc_enabled || false
    }
  },
  {
    configuration_type: 'adjust_inventory',
    configuration_values: {
      manual_barcode_allowed: data?.adjust_inventory_manual_barcode_allowed || false,
      enter_quantity_manually: data?.adjust_inventory_enter_quantity_manually || false
    }
  },
  {
    configuration_type: 'put_away',
    configuration_values: {
      manual_barcode_allowed: data?.putaway_manual_barcode_allowed || false,
      enter_quantity_manually: data?.putaway_enter_quantity_manually || false
    }
  },
  {
    configuration_type: 'schedule_order',
    configuration_values: {
      enabled: data?.schedule_order_allowed || false,
      time:{
        hour: `${isNaN(+data?.schedule_order_hours) ? '' : +data.schedule_order_hours}`,
        minute: `${isNaN(+data?.schedule_order_minutes) ? '' : +data.schedule_order_minutes}`
      }
    }
  }
]

export const GET_FORMATTED_BASIC_DETAILS_DATA = (data = {}) => ({
  name: data.hubName, //"Omniful Pvt LTD 7",
  type: data.warehouseType?.value, //"retail",
  email: data.hubEmail, //"omniful@gmail.com",
  phone_number: data.contactDetails.contactNumber, //"7044063694",
  code: data.hubCode, //"OMNI3456786 7",
  currency: {
    name: data.currency?.name,
    code: data.currency?.value,
    display_name: data.currency?.label
  },
  country_calling_code: data.contactDetails.countryCode?.calling_code, //"+91",
  pos_id: data.isPosEnabled ? data.PosId : undefined, //"7",
  // is_click_and_collect: data.clickCollect, //true,
  country_code: data.contactDetails.countryCode?.country_code, //"IN",
  is_active: true, //true,
  address: {
    address_line1: data.addressLine1, //"Gulati House",
    address_line2: data.addressLine2, //"Gurgoan"
    country_id: +data.country?.value, //8,
    state_id: +data.state?.value || undefined, //92,
    state_name: data.state?.label, //"Haryana",
    city_id: +data.city?.value, //8029,
    city_name: data.city?.label, //"Gurgoan",
    postal_code: data.pincode, //"1234",
    latitude: +data.latitude, //333,
    longitude: +data.longitude, //33,
    country: {
      name: data.country.label,
      code: data.country.code
    }
  }
})

export const GET_FORMATTED_WORKING_HOURS = (data = {}) => {
  const filteredDayIds = Object.keys(data).filter(
    (dayId) => data[dayId].isWorkingDay
  )
  const newWorkingHours = {}
  for (const dayId of filteredDayIds) {
    newWorkingHours[dayId] = data[dayId].value
  }
  return newWorkingHours
}

export const DAYS = {
  saturday: { day: 'Saturday', id: 1 },
  sunday: { day: 'Sunday', id: 2 },
  monday: { day: 'Monday', id: 3 },
  tuesday: { day: 'Tuesday', id: 4 },
  wednesday: { day: 'Wednesday', id: 5 },
  thursday: { day: 'Thursday', id: 6 },
  friday: { day: 'Friday', id: 7 }
}

export const GET_WORKING_HOURS = () => ({
  [DAYS.saturday.id]: {
    ...DAYS.saturday,
    isWorkingDay: true,
    is24Hrs: false,
    value: [{ start_time: '', end_time: '' }]
  },
  [DAYS.sunday.id]: {
    ...DAYS.sunday,
    isWorkingDay: true,
    is24Hrs: false,
    value: [{ start_time: '', end_time: '' }]
  },
  [DAYS.monday.id]: {
    ...DAYS.monday,
    isWorkingDay: true,
    is24Hrs: false,
    value: [{ start_time: '', end_time: '' }]
  },
  [DAYS.tuesday.id]: {
    ...DAYS.tuesday,
    isWorkingDay: true,
    is24Hrs: false,
    value: [{ start_time: '', end_time: '' }]
  },
  [DAYS.wednesday.id]: {
    ...DAYS.wednesday,
    isWorkingDay: true,
    is24Hrs: false,
    value: [{ start_time: '', end_time: '' }]
  },
  [DAYS.thursday.id]: {
    ...DAYS.thursday,
    isWorkingDay: true,
    is24Hrs: false,
    value: [{ start_time: '', end_time: '' }]
  },
  [DAYS.friday.id]: {
    ...DAYS.friday,
    isWorkingDay: true,
    is24Hrs: false,
    value: [{ start_time: '', end_time: '' }]
  }
})


export const VIEWS = {
  TOP_VIEW: 'TOP',
  FRONT_VIEW: 'FRONT',
  SIDE_VIEW: 'SIDE'
}

// NOTE: Keep the ids in natural numbers format ONLY
export const CONFIG_TO_ID_MAP = {
  custom_prefix_enabled: 1,
  floor_enabled: 2,
  hall_enabled: 3,
  aisle_enabled: 4,
  rack_enabled: 5,
  shelf_enabled: 6,
  location_enabled: 7,
  bin_enabled: 8
}

export const HUB_DETAILS_COLUMNS = ({ startId, t }) => [
  // {
  //   id: 1,
  //   name: "S. No.",
  //   minWidth: "55px",
  //   maxWidth: "55px",
  //   cell: (row) => {
  //     return (
  //       <span style={{ width: "100%", textAlign: "left" }}>{row.sno}</span>
  //     )
  //   }
  // },
  {
    id: CONFIG_TO_ID_MAP.floor_enabled,
    name: t('Floor Name'),
    cell: (row) => {
      return (
        <span style={{ width: '100%', textAlign: 'left' }}>
          {row.floorName || '-'}
        </span>
      )
    }
  },
  {
    id: CONFIG_TO_ID_MAP.aisle_enabled,
    name:
      +startId === CONFIG_TO_ID_MAP.aisle_enabled
        ? t('Aisle Name')
        : t('Aisles'),
    cell: (row) => {
      return (
        <span style={{ width: '100%', textAlign: 'left' }}>
          {+startId === CONFIG_TO_ID_MAP.aisle_enabled
            ? row.aisleName || '-'
            : row.aisles || '-'}
        </span>
      )
    }
  },
  {
    id: CONFIG_TO_ID_MAP.rack_enabled,
    name:
      +startId === CONFIG_TO_ID_MAP.rack_enabled ? t('Rack Name') : t('Racks'),
    cell: (row) => {
      return (
        <span style={{ width: '100%', textAlign: 'left' }}>
          {+startId === CONFIG_TO_ID_MAP.rack_enabled
            ? row.rackName || '-'
            : row.rack || '-'}
        </span>
      )
    }
  },
  {
    id: CONFIG_TO_ID_MAP.shelf_enabled,
    name:
      +startId === CONFIG_TO_ID_MAP.shelf_enabled
        ? t('Shelf Name')
        : t('Shelves'),
    cell: (row) => {
      return (
        <span style={{ width: '100%', textAlign: 'left' }}>
          {+startId === CONFIG_TO_ID_MAP.shelf_enabled
            ? row.shelfName || '-'
            : row.shelf || '-'}
        </span>
      )
    }
  },
  {
    id: CONFIG_TO_ID_MAP.location_enabled,
    name:
      +startId === CONFIG_TO_ID_MAP.location_enabled
        ? t('Location Name')
        : t('Locations'),
    cell: (row) => {
      return (
        <span style={{ width: '100%', textAlign: 'left' }}>
          {+startId === CONFIG_TO_ID_MAP.location_enabled
            ? row.locationName || '-'
            : row.location || '-'}
        </span>
      )
    }
  }
]

export const ID_TO_PAGE_DATA_MAP = {
  [CONFIG_TO_ID_MAP.floor_enabled]: {
    id: CONFIG_TO_ID_MAP.floor_enabled,
    tabName: 'Floors',
    storeKey: 'floors',
    columns: ({ enabledConfigIds, ability, t }) => [
      {
        id: 101,
        name: t('S. No.'),
        minWidth: '65px',
        maxWidth: '65px',
        cell: (row) => {
          return (
            <span style={{ width: '100%', textAlign: 'left' }}>{row.sno}</span>
          )
        }
      },
      ...HUB_DETAILS_COLUMNS({
        startId: CONFIG_TO_ID_MAP.floor_enabled,
        t
      }).filter(
        (col) => col.id >= CONFIG_TO_ID_MAP.floor_enabled &&
          enabledConfigIds?.includes(col.id)
      ),
      ...(ability.can(
        abilityMap.hub_location.edit.action,
        abilityMap.hub_location.edit.resource
      )
        ? [
          {
            id: 201,
            // name: "S. No.",
            minWidth: '55px',
            maxWidth: '55px',
            cell: (row) => {
              return (
                <div
                  className="d-flex w-100 justify-content-center"
                  style={{ cursor: 'pointer' }}
                >
                  <UncontrolledDropdown>
                    <DropdownToggle className="pe-1 more_vert" tag="span">
                      <MoreVertical size={20} />
                    </DropdownToggle>
                    <DropdownMenu end container="body">
                      <DropdownItem
                        className="w-100"
                        onClick={() => row.toggleEditModal(row)}
                      >
                        <Edit size={15} />
                        <span className="align-middle ms-50">
                          {t('Edit')}
                        </span>
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div>
              )
            }
          }
        ]
        : [])
    ],
    getData: getFloors,
    createNewButton: {
      text: 'Create Floor'
    }
  },
  [CONFIG_TO_ID_MAP.aisle_enabled]: {
    id: CONFIG_TO_ID_MAP.aisle_enabled,
    tabName: 'Aisles',
    storeKey: 'aisles',
    columns: ({ enabledConfigIds, ability, t }) => [
      {
        id: 101,
        name: t('S. No.'),
        minWidth: '65px',
        maxWidth: '65px',
        cell: (row) => {
          return (
            <span style={{ width: '100%', textAlign: 'left' }}>{row.sno}</span>
          )
        }
      },
      ...HUB_DETAILS_COLUMNS({
        startId: CONFIG_TO_ID_MAP.aisle_enabled,
        t
      }).filter(
        (col) => col.id >= CONFIG_TO_ID_MAP.aisle_enabled &&
          enabledConfigIds?.includes(col.id)
      ),
      ...(ability.can(
        abilityMap.hub_location.edit.action,
        abilityMap.hub_location.edit.resource
      )
        ? [
          {
            id: 201,
            // name: "S. No.",
            minWidth: '55px',
            maxWidth: '55px',
            cell: (row) => {
              return (
                <div
                  className="d-flex w-100 justify-content-center"
                  style={{ cursor: 'pointer' }}
                >
                  <UncontrolledDropdown>
                    <DropdownToggle className="pe-1 more_vert" tag="span">
                      <MoreVertical size={20} />
                    </DropdownToggle>
                    <DropdownMenu end container="body">
                      <DropdownItem
                        className="w-100"
                        onClick={() => row.toggleEditModal(row)}
                      >
                        <Edit size={15} />
                        <span className="align-middle ms-50">
                          {t('Edit')}
                        </span>
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div>
              )
            }
          }
        ]
        : [])
    ],
    getData: getAisles,
    createNewButton: {
      text: 'Create Aisle'
    }
  },
  [CONFIG_TO_ID_MAP.rack_enabled]: {
    id: CONFIG_TO_ID_MAP.rack_enabled,
    tabName: 'Racks',
    storeKey: 'racks',
    columns: ({ enabledConfigIds, ability, t }) => [
      {
        id: 101,
        name: t('S. No.'),
        minWidth: '65px',
        maxWidth: '65px',
        cell: (row) => {
          return (
            <span style={{ width: '100%', textAlign: 'left' }}>{row.sno}</span>
          )
        }
      },
      ...HUB_DETAILS_COLUMNS({
        startId: CONFIG_TO_ID_MAP.rack_enabled,
        t
      }).filter(
        (col) => col.id >= CONFIG_TO_ID_MAP.rack_enabled &&
          enabledConfigIds?.includes(col.id)
      ),
      ...(ability.can(
        abilityMap.hub_location.edit.action,
        abilityMap.hub_location.edit.resource
      )
        ? [
          {
            id: 201,
            // name: "S. No.",
            minWidth: '55px',
            maxWidth: '55px',
            cell: (row) => {
              return (
                <div
                  className="d-flex w-100 justify-content-center"
                  style={{ cursor: 'pointer' }}
                >
                  <UncontrolledDropdown>
                    <DropdownToggle className="pe-1 more_vert" tag="span">
                      <MoreVertical size={20} />
                    </DropdownToggle>
                    <DropdownMenu end container="body">
                      <DropdownItem
                        className="w-100"
                        onClick={() => row.toggleEditModal(row)}
                      >
                        <Edit size={15} />
                        <span className="align-middle ms-50">
                          {t('Edit')}
                        </span>
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div>
              )
            }
          }
        ]
        : [])
    ],
    getData: getRacks,
    createNewButton: {
      text: 'Create Rack'
    }
  },
  [CONFIG_TO_ID_MAP.shelf_enabled]: {
    id: CONFIG_TO_ID_MAP.shelf_enabled,
    tabName: 'Shelves',
    storeKey: 'shelves',
    columns: ({ enabledConfigIds, ability, t }) => [
      {
        id: 101,
        name: t('S. No.'),
        minWidth: '65px',
        maxWidth: '65px',
        cell: (row) => {
          return (
            <span style={{ width: '100%', textAlign: 'left' }}>{row.sno}</span>
          )
        }
      },
      ...HUB_DETAILS_COLUMNS({
        startId: CONFIG_TO_ID_MAP.shelf_enabled,
        t
      }).filter(
        (col) => col.id >= CONFIG_TO_ID_MAP.shelf_enabled &&
          enabledConfigIds?.includes(col.id)
      ),
      ...(ability.can(
        abilityMap.hub_location.edit.action,
        abilityMap.hub_location.edit.resource
      )
        ? [
          {
            id: 201,
            // name: "S. No.",
            minWidth: '55px',
            maxWidth: '55px',
            cell: (row) => {
              return (
                <div
                  className="d-flex w-100 justify-content-center"
                  style={{ cursor: 'pointer' }}
                >
                  <UncontrolledDropdown>
                    <DropdownToggle className="pe-1 more_vert" tag="span">
                      <MoreVertical size={20} />
                    </DropdownToggle>
                    <DropdownMenu end container="body">
                      <DropdownItem
                        className="w-100"
                        onClick={() => row.toggleEditModal(row)}
                      >
                        <Edit size={15} />
                        <span className="align-middle ms-50">
                          {t('Edit')}
                        </span>
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div>
              )
            }
          }
        ]
        : [])
    ],
    getData: getShelves,
    createNewButton: {
      text: 'Create Shelf'
    }
  },
  [CONFIG_TO_ID_MAP.location_enabled]: {
    id: CONFIG_TO_ID_MAP.location_enabled,
    tabName: 'Locations',
    storeKey: 'locations',
    columns: ({ enabledConfigIds, t, ability }) => [
      {
        id: 101,
        name: t('S. No.'),
        minWidth: '65px',
        maxWidth: '65px',
        cell: (row) => {
          return (
            <span style={{ width: '100%', textAlign: 'left' }}>{row.sno}</span>
          )
        }
      },
      ...HUB_DETAILS_COLUMNS({
        startId: CONFIG_TO_ID_MAP.location_enabled,
        t
      }).filter(
        (col) => col.id >= CONFIG_TO_ID_MAP.location_enabled &&
          enabledConfigIds?.includes(col.id)
      ),
      ...(CONFIG_TO_ID_MAP.location_enabled
        ? [
          {
            id: 102,
            name: t('Location Type'),
            cell: (row) => {
              return (
                <span style={{ width: '100%', textAlign: 'left' }}>
                  {row.type}
                </span>
              )
            }
          },
          {
            id: 103,
            name: t('Location Barcode'),
            cell: (row) => {
              return (
                <span
                  onClick={() => row.showBarcode(row.barcode)}
                  className="cursor-pointer"
                  style={{
                    width: '100%',
                    textAlign: 'left',
                    color: '#5468fa'
                  }}
                >
                  {row.barcode}
                </span>
              )
            }
          }
        ]
        : []),
      ...(ability.can(
        abilityMap.hub_location.edit.action,
        abilityMap.hub_location.edit.resource
      )
        ? [
          {
            id: 201,
            // name: "S. No.",
            minWidth: '55px',
            maxWidth: '55px',
            cell: (row) => {
              return (
                <div
                  className="d-flex w-100 justify-content-center"
                  style={{ cursor: 'pointer' }}
                >
                  <UncontrolledDropdown>
                    <DropdownToggle className="pe-1 more_vert" tag="span">
                      <MoreVertical size={20} />
                    </DropdownToggle>
                    <DropdownMenu end container="body">
                      <DropdownItem
                        className="w-100"
                        onClick={() => row.toggleEditModal(row)}
                      >
                        <Edit size={15} />
                        <span className="align-middle ms-50">
                          {t('Edit')}
                        </span>
                      </DropdownItem> 
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div>
              )
            }
          }
        ]
        : [])
    ],
    getData: getLocations,
    createNewButton: {
      text: 'Create Location'
    }
  },
  [CONFIG_TO_ID_MAP.custom_prefix_enabled]: {
    id: CONFIG_TO_ID_MAP.custom_prefix_enabled,
    tabName: 'Prefix',
    storeKey: 'prefix',
    columns: ({ enabledConfigIds, ability, t }) => [
      {
        id: 101,
        name: t('S. No.'),
        minWidth: '65px',
        maxWidth: '65px',
        cell: (row) => {
          return (
            <span style={{ width: '100%', textAlign: 'left' }}>{row.sno}</span>
          )
        }
      },
      ...HUB_DETAILS_COLUMNS({
        startId: CONFIG_TO_ID_MAP.custom_prefix_enabled,
        t
      }).filter(
        (col) => col.id >= CONFIG_TO_ID_MAP.custom_prefix_enabled &&
          enabledConfigIds?.includes(col.id)
      ),
      ...(ability.can(
        abilityMap.hub_location.edit.action,
        abilityMap.hub_location.edit.resource
      )
        ? [
          {
            id: 201,
            // name: "S. No.",
            minWidth: '55px',
            maxWidth: '55px',
            cell: (row) => {
              return (
                <div
                  className="d-flex w-100 justify-content-center"
                  style={{ cursor: 'pointer' }}
                >
                  <UncontrolledDropdown>
                    <DropdownToggle className="pe-1 more_vert" tag="span">
                      <MoreVertical size={20} />
                    </DropdownToggle>
                    <DropdownMenu end container="body">
                      <DropdownItem
                        className="w-100"
                        onClick={() => row.toggleEditModal(row)}
                      >
                        <Edit size={15} />
                        <span className="align-middle ms-50">
                          {t('Edit')}
                        </span>
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div>
              )
            }
          }
        ]
        : [])
    ],
    getData: getFloors,
    createNewButton: {
      text: 'Create Prefix'
    }
  },
  [CONFIG_TO_ID_MAP.bin_enabled]: {
    id: CONFIG_TO_ID_MAP.bin_enabled,
    tabName: 'Bin',
    storeKey: 'bin',
    columns: ({ enabledConfigIds, ability, t }) => [
      {
        id: 101,
        name: t('S. No.'),
        minWidth: '65px',
        maxWidth: '65px',
        cell: (row) => {
          return (
            <span style={{ width: '100%', textAlign: 'left' }}>{row.sno}</span>
          )
        }
      },
      ...HUB_DETAILS_COLUMNS({
        startId: CONFIG_TO_ID_MAP.bin_enabled,
        t
      }).filter(
        (col) => col.id >= CONFIG_TO_ID_MAP.bin_enabled &&
          enabledConfigIds?.includes(col.id)
      ),
      ...(ability.can(
        abilityMap.hub_location.edit.action,
        abilityMap.hub_location.edit.resource
      )
        ? [
          {
            id: 201,
            // name: "S. No.",
            minWidth: '55px',
            maxWidth: '55px',
            cell: (row) => {
              return (
                <div
                  className="d-flex w-100 justify-content-center"
                  style={{ cursor: 'pointer' }}
                >
                  <UncontrolledDropdown>
                    <DropdownToggle className="pe-1 more_vert" tag="span">
                      <MoreVertical size={20} />
                    </DropdownToggle>
                    <DropdownMenu end container="body">
                      <DropdownItem
                        className="w-100"
                        onClick={() => row.toggleEditModal(row)}
                      >
                        <Edit size={15} />
                        <span className="align-middle ms-50">
                          {t('Edit')}
                        </span>
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div>
              )
            }
          }
        ]
        : [])
    ],
    getData: getFloors,
    createNewButton: {
      text: 'Create Bin'
    }
  },
  [CONFIG_TO_ID_MAP.hall_enabled]: {
    id: CONFIG_TO_ID_MAP.hall_enabled,
    tabName: 'Halls',
    storeKey: 'halls',
    columns: ({ enabledConfigIds, ability, t }) => [
      {
        id: 101,
        name: t('S. No.'),
        minWidth: '65px',
        maxWidth: '65px',
        cell: (row) => {
          return (
            <span style={{ width: '100%', textAlign: 'left' }}>{row.sno}</span>
          )
        }
      },
      ...HUB_DETAILS_COLUMNS({
        startId: CONFIG_TO_ID_MAP.hall_enabled,
        t
      }).filter(
        (col) => col.id >= CONFIG_TO_ID_MAP.hall_enabled &&
          enabledConfigIds?.includes(col.id)
      ),
      ...(ability.can(
        abilityMap.hub_location.edit.action,
        abilityMap.hub_location.edit.resource
      )
        ? [
          {
            id: 201,
            // name: "S. No.",
            minWidth: '55px',
            maxWidth: '55px',
            cell: (row) => {
              return (
                <div
                  className="d-flex w-100 justify-content-center"
                  style={{ cursor: 'pointer' }}
                >
                  <UncontrolledDropdown>
                    <DropdownToggle className="pe-1 more_vert" tag="span">
                      <MoreVertical size={20} />
                    </DropdownToggle>
                    <DropdownMenu end container="body">
                      <DropdownItem
                        className="w-100"
                        onClick={() => row.toggleEditModal(row)}
                      >
                        <Edit size={15} />
                        <span className="align-middle ms-50">
                          {t('Edit')}
                        </span>
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div>
              )
            }
          }
        ]
        : [])
    ],
    getData: getFloors,
    createNewButton: {
      text: 'Create Hall'
    }
  }
}

export const ID_TO_MODAL_CUSTOMIZED_MAP = {
  1: {
    id: 1,
    entityName: 'Prefix',
    createResponseKey: 'floorCreateResponse',
    particularResponseKey: 'particularFloorResponse',
    editResponseKey: 'editSingleFloorResponse',
    cleanup: cleanupCreateFloor,
    cleanupGetParticularEntity: cleanupParticularFloorResponse,
    cleanupEditResponse: cleanupEditFloorResponse,
    filterKey: 'custom_prefix_id',
    getData: getFloorsAsync,
    getSingleEntity: getParticularFloor,
    createRequest: createFloor,
    editRequest: editSingleFloor
  },
  2: {
    id: 2,
    entityName: 'Floor',
    createResponseKey: 'floorCreateResponse',
    particularResponseKey: 'particularFloorResponse',
    editResponseKey: 'editSingleFloorResponse',
    cleanup: cleanupCreateFloor,
    cleanupGetParticularEntity: cleanupParticularFloorResponse,
    cleanupEditResponse: cleanupEditFloorResponse,
    filterKey: 'floor_id',
    getData: getFloorsAsync,
    getSingleEntity: getParticularFloor,
    createRequest: createFloor,
    editRequest: editSingleFloor
  },
  3: {
    id: 3,
    entityName: 'Hall',
    createResponseKey: 'aisleCreateResponse',
    particularResponseKey: 'particularAisleResponse',
    editResponseKey: 'editSingleAisleResponse',
    cleanup: cleanupCreateAisle,
    cleanupGetParticularEntity: cleanupParticularAisleResponse,
    cleanupEditResponse: cleanupEditAisleResponse,
    filterKey: 'hall_id',
    getData: getAislesAsync,
    getSingleEntity: getParticularAisle,
    createRequest: createAisle,
    editRequest: editSingleAisle
  },
  4: {
    id: 4,
    entityName: 'Aisle',
    createResponseKey: 'aisleCreateResponse',
    particularResponseKey: 'particularAisleResponse',
    editResponseKey: 'editSingleAisleResponse',
    cleanup: cleanupCreateAisle,
    cleanupGetParticularEntity: cleanupParticularAisleResponse,
    cleanupEditResponse: cleanupEditAisleResponse,
    filterKey: 'aisle_id',
    getData: getAislesAsync,
    getSingleEntity: getParticularAisle,
    createRequest: createAisle,
    editRequest: editSingleAisle
  },
  5: {
    id: 5,
    entityName: 'Rack',
    createResponseKey: 'rackCreateResponse',
    particularResponseKey: 'particularRackResponse',
    editResponseKey: 'editSingleRackResponse',
    cleanup: cleanupCreateRack,
    cleanupGetParticularEntity: cleanupParticularRackResponse,
    cleanupEditResponse: cleanupEditRackResponse,
    filterKey: 'rack_id',
    getData: getRacksAsync,
    getSingleEntity: getParticularRack,
    createRequest: createRack,
    editRequest: editSingleRack
  },
  6: {
    id: 6,
    entityName: 'Shelf',
    createResponseKey: 'shelfCreateResponse',
    particularResponseKey: 'particularShelfResponse',
    editResponseKey: 'editSingleShelfResponse',
    cleanup: cleanupCreateShelf,
    cleanupGetParticularEntity: cleanupParticularShelfResponse,
    cleanupEditResponse: cleanupEditShelfResponse,
    filterKey: 'shelf_id',
    getData: getShelvesAsync,
    getSingleEntity: getParticularShelf,
    createRequest: createShelf,
    editRequest: editSingleShelf
  },
  7: {
    id: 7,
    entityName: 'Location',
    createResponseKey: 'locationsCreateResponse',
    particularResponseKey: 'particularLocationResponse',
    editResponseKey: 'editSingleLocationResponse',
    cleanup: cleanupCreateLocation,
    cleanupGetParticularEntity: cleanupParticularLocationResponse,
    cleanupEditResponse: cleanupEditLocationResponse,
    filterKey: 'location_id',
    getData: getLocationsAsync,
    getSingleEntity: getParticularLocation,
    createRequest: createLocation,
    editRequest: editSingleLocation
  },
  8: {
    id: 8,
    entityName: 'Bin',
    createResponseKey: 'locationsCreateResponse',
    particularResponseKey: 'particularLocationResponse',
    editResponseKey: 'editSingleLocationResponse',
    cleanup: cleanupCreateLocation,
    cleanupGetParticularEntity: cleanupParticularLocationResponse,
    cleanupEditResponse: cleanupEditLocationResponse,
    filterKey: 'bin_id',
    getData: getLocationsAsync,
    getSingleEntity: getParticularLocation,
    createRequest: createLocation,
    editRequest: editSingleLocation
  }
}

export const LOCATION_TYPE_OPTIONS = {
  picking: {
    label: 'Picking',
    value: 'picking'
  },
  consolidation: {
    label: 'Consolidation',
    value: 'consolidation'
  },
  backstorage: {
    label: 'Backstorage',
    value: 'backstorage'
  },
  scrap: {
    label: 'Scrap',
    value: 'scrap'
  },
  return_to_sender: {
    label: 'Return to Sender',
    value: 'return_to_sender'
  },
  review: {
    label: 'Review',
    value: 'review'
  }
}

export const PAGE_HEADER_PROPS = {
  hubDetails: {
    breadcrumbIcon: Hub,
    title: 'Layout',
    breadcrumbsData: [{ title: 'Hubs' }, { title: 'Hub Setup' }]
  },
  createStandardLayout: {
    breadcrumbIcon: Hub,
    title: 'Standard Hub Layout Setup',
    breadcrumbsData: [{ title: 'Hub Setup' }, { title: 'Hub Setup' }]
  },
  createCustomisedLayout: {
    breadcrumbIcon: Hub,
    title: 'Customised Hub Layout Setup',
    breadcrumbsData: [{ title: 'Hub Setup' }, { title: 'Hub Setup' }]
  }
}

export const LAYOUT_CREATE_TYPES = {
  standard: {
    key: 'standard',
    pageHeaderProps: PAGE_HEADER_PROPS.createStandardLayout
  },
  customised: {
    key: 'customised',
    pageHeaderProps: PAGE_HEADER_PROPS.createCustomisedLayout
  }
}

export const ABOUT_CUSTOMISED_LAYOUT_DETAILS = {
  floor: {
    id: CONFIG_TO_ID_MAP.floor_enabled,
    heading: 'example of floor',
    description:
      'A Floor refers to the physical surface or ground on which products, equipment, and operations are conducted, providing the foundation for storage, movement, and handling activities.',
    image: '/assets/images/hubs/floor.webp'
  },
  hall: {
    id: CONFIG_TO_ID_MAP.hall_enabled,
    heading: 'example of Hall',
    description:
      'A hall is a specific section in a warehouse where goods are stored. It serves as an organizational unit for efficient inventory management and order fulfillment.',
    image: '/assets/images/hubs/hall.webp'
  },
  aisle: {
    id: CONFIG_TO_ID_MAP.aisle_enabled,
    heading: 'example of Aisle',
    description:
      'An Aisle refers to the narrow space or pathway between storage racks or shelves. Aisles are designed to facilitate movement, allowing for easy access to products, equipment, and inventory within the warehouse.',
    image: '/assets/images/hubs/aisle.webp'
  },
  rack: {
    id: CONFIG_TO_ID_MAP.rack_enabled,
    heading: 'example of Rack',
    description:
      'A Rack refers to a structure or framework consisting of shelves or compartments used for storing goods or products. Racks are designed to maximise vertical storage space and provide organised storage solutions, enabling efficient inventory management and easy access to items within the warehouse.',
    image: '/assets/images/hubs/rack.webp'
  },
  shelf: {
    id: CONFIG_TO_ID_MAP.shelf_enabled,
    heading: 'example of Shelf',
    description:
      'A Shelf refers to a flat, horizontal surface within a rack or storage system where goods or products are placed for storage. Shelves provide individual compartments or levels for organising and storing items, allowing for efficient space utilisation and easy retrieval of goods in the warehouse.',
    image: '/assets/images/hubs/shelve.webp'
  },
  location: {
    id: CONFIG_TO_ID_MAP.location_enabled,
    heading: 'example of Location',
    description:
      'A Location refers to a specific designated area where goods are stored, identified by a unique code or identifier.',
    image: '/assets/images/hubs/location.webp'
  },
  bin: {
    id: CONFIG_TO_ID_MAP.bin_enabled,
    heading: 'example of Bin',
    description:
      'A "bin" is a designated storage location within a warehouse where items are organised and stored.',
    image: '/assets/images/hubs/bins.webp'
  }
  
}

export const CUSTOMISED_CONFIG_DETAILS = {
  floor: {
    fig: 'Fig: Floor representation',
    heading: 'Do you have Floors?',
    name: 'floor_enabled',
    image: '/assets/images/hubs/floor.webp'
  },
  aisle: {
    fig: 'Fig: Aisle representation',
    heading: 'Do you have Aisles?',
    name: 'aisle_enabled',
    image: '/assets/images/hubs/aisle.webp'
  },
  rack: {
    fig: 'Fig: Rack representation',
    heading: 'Do you have Racks?',
    name: 'rack_enabled',
    image: '/assets/images/hubs/rack.webp'
  },
  shelf: {
    fig: 'Fig: Shelf representation',
    heading: 'Do you have Shelves?',
    name: 'shelf_enabled',
    image: '/assets/images/hubs/shelve.webp'
  },
  location: {
    fig: 'Fig: Location representation',
    heading: 'Do you have Location?',
    name: 'location_enabled',
    image: '/assets/images/hubs/location.webp'
  }
}

export const WAREHOUSE_TYPES_OPTIONS = {
  retail: { label: 'Retail', value: 'retail' },
  warehouse: { label: 'Warehouse', value: 'warehouse' },
  dark_store: {label: 'Dark Store', value: 'dark_store'}
}

export const HUBS_VIEW_OPTIONS = {
  table: { id: 1, text: 'Table View', icon: TableIcon },
  map: { id: 2, text: 'Map View', icon: MapIcon }
}

export const GET_HUB_EDIT_STEPS = ({ dispatchHubEditProgress }) => ({
  hubBasicDetails: {
    id: 2,
    title: 'Basic Details & Address',
    subTitle: 'Name, Contact details & Address etc',
    icon: Details,
    onClick: ({ id }) => dispatchHubEditProgress({ type: 'SET_ACTIVE_ID', payload: id })
  },
  workingHours: {
    id: 3,
    title: 'Working Hours',
    subTitle: 'Days and Time of each working day',
    icon: WorkingHour,
    onClick: ({ id }) => dispatchHubEditProgress({ type: 'SET_ACTIVE_ID', payload: id })
  },
  configDetails: {
    id: 4,
    title: 'Configuration Details',
    subTitle: 'Configure Hub Operations',
    icon: Config,
    onClick: ({ id }) => dispatchHubEditProgress({ type: 'SET_ACTIVE_ID', payload: id })
  }
})

export const BIN_TYPE = {
  GRN: 'grn',
  CONSOLIDATED: 'order',
  PUTAWAY: 'put_away',
  CART: 'order',
  ADJUSTMENT_INVENTORY: 'inventory_adjustment'
}

export const DELIVERY_TYPES = {
  FAST_DELIVERY: {icon: fastDelivery, type: 'fast', text: 'Fast Delivery', colorCode: '#0082FB'},
  SCHEDULED_DELIVERY: {icon: scheduledDelivery, type: 'scheduled', text: 'Scheduled Delivery', colorCode: '#D55FFF'},
  CLICK_COLLECT: {icon: clickCollect, type: 'collect', text: 'Click & Collect', colorCode: '#FFD600'}
}

export const DELIVERY_AREA_TYPES = {
  SYSTEM_GENERATED: {type: 'ETA Based', key:'eta_based'},
  CUSTOM: {type: 'Custom Map', key:'custom'},
  CITY: {type: 'City', key:'city'},
  AERIAL: {type: 'Aerial', key:'aerial'}
}

export const ZONE_STATE = {
  EDITING: 'EDITING',
  CREATING: 'CREATING',
  CREATED: 'CREATED'
}

export const CART_TYPES = {
  SINGLE_BIN_CART: 'single_bin_cart',
  MULTI_BIN_CART: 'multi_bin_cart'
}

export const BARCODE_EXAMPLES = [
  {
    description: 'Here location barcode is made up of 4 elements. RY is prefix for Riyadh city warehouse, F01 is for first floor, A01 is for first aisle & L01 is for Location 1.',
    barcode: sample_barcode_1_img
  },
  {
    description: 'Here location barcode is made up of elements. H01 is for first hall, A01 is for first aisle & L01 is for Location 1.',
    barcode: sample_barcode_2_img
  },
  {
    description: 'Here location barcode is made up of 2 elements. F01 is for the first floor & Fruits & Vegetable is for the location.',
    barcode: sample_barcode_3_img
  }
]
export const GET_LOCATIONS_ACTIONS = ({t, openCreateBulkLocationsModal, openCreateSingleLocationModal}) => ({
  CREATE_SINGLE_LOCATION: {
    id: 3,
    text: t('Single Location'),
    icon: Plus,
    onClick: openCreateSingleLocationModal
  },
  CREATE_MULTIPLE_LOCATIONS: {
    id: 2,
    text: t('Bulk Creation by Sheet'),
    icon: Plus,
    onClick: openCreateBulkLocationsModal
  }
})

export const CENTIMETERS_PER_INCH = 2.54
export const RESOLUTION = 96
export const MAX_SHIFTS = 3

export const BATCH_SIZE = 500
export const BIN_BATCH_SIZE = 500

export const USER_KNOW_THE_CONFIGURATION = 3
export const USER_NOT_KNOW_THE_CONFIGURATION = 4
export const PROGRESS_BAR_MAX = 33.3
export const PROGRESS_BAR_MIN = 25
export const RESET_PROGRESS_VALUE = 99.8
export const MAX_PROGRESSBAR_REACHED = 99.8
export const COMPLETED_PROGRESS_VALUE = 100

export const LOCATION_CREATION_STATUS = {
  PENDING: 'PENDING',
  SUCCESS: 'SUCCESS',
  FAILURE: 'FAILURE',
  NOT_SET: 'NOT_SET'
}

export const LOCATION_CONFIGURATION = 'location_configuration'
export const FLOOR_ENABLED = 'floor_enabled'
export const HUB_LOCATION_MAPPING = 'hub_location_mapping'
export const LOCATION_ENABLED = 'location_enabled'

export const DELAY_TIME_FOR_SUCCESS_ANIMATION = 3000  //write in milisecond

export const SPECIAL_CHARACTERS = [
  '~', '!', '@', '#', '$', '%', '^', '&', '*', 
  '(', ')', '_', '+', '{', '}', '[', ']', '|', 
  '\\', ':', ';', '\'', '"', '<', '>', ',', '.', 
  '?', '/', '-', '='
]
export const BARCODE_PAGE_TYPE = [
  {
    label:'Default', value:'Default'
  },
  {
    label:'Custom', value:'Custom'
  }
]
export const BARCODE_PAGE_TYPE_DEFAULT_LABEL = 'Default'

export const REASON_MAX_LENGTH = 20

export const REASON_CONFIG = {
  picklist_picking: {
    title: 'Picking',
    subtitle:'Reasons for non-picked items'
  },
  damaged_item_picking: {
    title: 'Adjust Inventory',
    subtitle:'Reasons for inventory adjustment'
  },
  grn_items: {
    title: 'GRN',
    subtitle:'Reasons for marking items QC failed during GRN'
  },
  packing: {
    title: 'Packing',
    subtitle:'Reasons for marking items QC failed'
  },
  picking_in_packing: {
    title: 'Packing',
    subtitle:'Reasons for availability of items for picking'
  }
}

export const PALLET_CAPACITY = [
  {id: 1, label: 'Large', value: 'large' },
  {id: 2, label: 'Medium', value: 'medium' },
  {id: 3, label: 'Small', value: 'small' }
]

export const HUB_ASSESTS = {
  PALLET : 'pallet'
}
export const LARGE = 'Large'
export const MEDIUM = 'Medium'
export const SMALL = 'Small'

export const LOCATIONS_PRINT_FORMAT = {
  BARCODE: 'barcode',
  QRCODE: 'qrcode'
}

export const HUB_SORT_ORDER = {
  ASC: 'code_asc',
  DESC: 'code_desc'
}

export const CREATE_SINGLE_LOCATION_FIELD_NAMES = {
  PRIORITY: 'priority',
  WEIGHT: 'weight',
  LENGTH: 'length',
  WIDTH: 'width',
  HEIGHT: 'height',
  LOCATION_TYPE: 'location_type',
  ZONE_TYPE: 'zone_type',
  LOCATION_ATTRIBUTES: 'location_attributes',
  LOCATION_TAGS: 'location_tags',
  LOCATION_VOLUME: 'volume',
  LOCATION_CUSTOM_BARCODE: 'location_custom_barcode'
}


export const CREATE_SINGLE_LOCATION_FIELD_DEFAULT_VALUE = {
  [CREATE_SINGLE_LOCATION_FIELD_NAMES.PRIORITY]: '',
  [CREATE_SINGLE_LOCATION_FIELD_NAMES.WEIGHT]: '',
  [CREATE_SINGLE_LOCATION_FIELD_NAMES.LENGTH]: '',
  [CREATE_SINGLE_LOCATION_FIELD_NAMES.WIDTH]: '',
  [CREATE_SINGLE_LOCATION_FIELD_NAMES.HEIGHT]: '',
  [CREATE_SINGLE_LOCATION_FIELD_NAMES.LOCATION_TYPE]: null,
  [CREATE_SINGLE_LOCATION_FIELD_NAMES.ZONE_TYPE]: null,
  [CREATE_SINGLE_LOCATION_FIELD_NAMES.LOCATION_ATTRIBUTES]: [],
  [CREATE_SINGLE_LOCATION_FIELD_NAMES.LOCATION_TAGS]: null,
  [CREATE_SINGLE_LOCATION_FIELD_NAMES.LOCATION_VOLUME]: '',
  [CREATE_SINGLE_LOCATION_FIELD_NAMES.LOCATION_CUSTOM_BARCODE]: ''
}

export const LOCATION_TAGS_OPTIONS = {
  A: {label: 'A', value: 'A'},
  B: {label: 'B', value: 'B'},
  C: {label: 'C', value: 'C'}
}

export const LOCATION_ATTRIBUTES_ADDITIONAL_OPTIONS = {
  ADD_ATTRIBUTE: {label: 'Create', value:'add_attribute'}
}

export const HUBS_TABS = {
  ALL_HUBS: { name: 'All', key: 'all_hubs', id: 'all_hubs', component: HubsList},
  ALL_DELIVERY_ZONES: { name: 'Delivery Zones', key: 'all_delivery_zones', id:'all_delivery_zones', component: AllDeliveryZones }
}

export const DELIVERY_ZONES_STATUS = [{label: 'Active', value: true}, {label: 'Inactive', value: false}]
