import { NoRecordFound as NoRecordFounds } from '@src/assets/data/assets'
import { useTranslation } from 'react-i18next'
const NoRecordFound = (props) => {
  const {
    width = 160,
    height = 160,
    message = 'No Record Is Present to Display'
  } = props
  const {t} = useTranslation()
  return (
    <div className='d-flex flex-column align-items-center'>
      <NoRecordFounds primary={'var(--bs-primary)'} primary_dark={'var(--bs-primary-dark)'}  width={width} height={height} />
      <h4 className='mt-2'>{t(message)}</h4>
    </div>
  )
}
export default NoRecordFound