import Button from '@src/@core/components/ui/button'
import InputField from '@src/@core/components/ui/input-field'
import { clearBatchResponse, createBatch, editBatch, setScannedSkuDetails } from '@src/views/inventory/store'
import { format, parse } from 'date-fns'
import { useEffect, useRef } from 'react'
import { X } from 'react-feather'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Modal, ModalBody, Spinner } from 'reactstrap'
import { SERIALISATION_OPTION } from '../../constant'
import DatePickerInput from './datePickerInput'

const CreateBatchModal = ({ isOpen, toggle, sku_details, isEdit = false, handleGetAllBatches }) => {

  const { register, control, setValue, reset, watch, formState: { errors, isDirty }, handleSubmit } = useForm({
    defaultValues: {
      external_batch_id: '',
      manufacturing_date: null,
      expiry_date: null
    },
    mode: 'onChange'
  })
  const configurations = useSelector(store => store.auth.selectedHubConfiguration)
  const isBinDisabled = !configurations?.find(item => item.configuration_type === 'bin')?.configuration_values?.enabled

  const { t } = useTranslation()
  const enterBarcodeNumberRef = useRef()
  const createBatchResponse = useSelector(store => store.inventory.createBatchResponse)
  const editBatchResponse = useSelector(store => store.inventory.editBatchResponse)
  const loadingState = useSelector(store => store.inventory.loadingState)
  const grnResponse = useSelector(store => store.inventory.grn.grnResponse)
  const returnGrnResponse = useSelector((state) => state.returns.createReturnGrnResponse?.data)
  const selectedGlobalHubID = useSelector(
    (store) => store.auth.selectedGlobalHubId
  )
  const dispatch = useDispatch()

  const handleChangeDate = () => {
    const expiryDate = watch('expiry_date')
    const manufacturingDate = watch('manufacturing_date')
    
    if (expiryDate && expiryDate[0] < manufacturingDate?.[0]) setValue('expiry_date', null)
  }

  const onSubmit = (data) => {
    if (!isEdit) {
      const body = {
        expiry_date: format(data.expiry_date[0], 'yyyy-MM-dd'),
        external_batch_id: data.external_batch_id,
        manufacture_date: format(data.manufacturing_date[0], 'yyyy-MM-dd'),
        seller_sku_id: sku_details.seller_sku_id,
        hub_id: selectedGlobalHubID,
        grn_id: grnResponse?.id || returnGrnResponse?.id
      }
      dispatch(createBatch(body))
    } else {
      const body = {
        expiry_date: format(data.expiry_date[0], 'yyyy-MM-dd'),
        external_batch_id: data.external_batch_id,
        manufacture_date: format(data.manufacturing_date[0], 'yyyy-MM-dd'),
        batch_id: sku_details?.batch?.id
      }
      dispatch(editBatch(body))
    }
  }

  useEffect(() => {
    if (createBatchResponse.is_success || editBatchResponse.is_success) {

      if (createBatchResponse.data && isBinDisabled) {
        if (sku_details?.serialisation_status === SERIALISATION_OPTION.non_serialised.id) {
          dispatch(setScannedSkuDetails({
            data: {
              ...sku_details,
              batch: {
                id: createBatchResponse.data?.id,
                label: createBatchResponse.data?.external_batch_id,
                expiry_date: createBatchResponse.data?.expiry_date
              },
              pass_qty: 0,
              reasons: [],
              fail_qty: 0,
              scanned_qty: 0
            }
          }))
        } else if (sku_details?.serialisation_status === SERIALISATION_OPTION.serialised.id) {
          dispatch(setScannedSkuDetails({
            data: {
              ...sku_details,
              batch: {
                id: createBatchResponse.data?.id,
                label: createBatchResponse.data?.external_batch_id,
                expiry_date: createBatchResponse.data?.expiry_date
              },
              pass_barcodes: [],
              pass_qty: 0,
              reasons: [],
              fail_qty: 0,
              fail_barcodes: []
            }
          }))
        }
      }
      reset()
      toggle()
      dispatch(clearBatchResponse())

      if (editBatchResponse.is_success && handleGetAllBatches) {
        handleGetAllBatches()
      }
    }
  }, [createBatchResponse, editBatchResponse])

  const onPopupOpenFocus = () => {
    // for edit
    if (isEdit && sku_details?.batch) {
      const manufacturingDate = sku_details.batch.manufacture_date ? [parse(sku_details.batch.manufacture_date, 'dd-MM-yyyy', new Date())] : null
      let expiryDate = sku_details.batch.expiry_date ? [parse(sku_details.batch.expiry_date, 'dd-MM-yyyy', new Date())] : null

      if (expiryDate && expiryDate[0] < manufacturingDate?.[0]) expiryDate = null 

      reset({
        external_batch_id: sku_details.batch.external_batch_id || '',
        manufacturing_date: manufacturingDate,
        expiry_date: expiryDate
      })
      // setValue('external_batch_id', sku_details.batch.external_batch_id)
      // setValue('manufacturing_date', [parse(sku_details.batch.manufacture_date, 'dd-MM-yyyy', new Date())])
      // setValue('expiry_date', [parse(sku_details.batch.expiry_date, 'dd-MM-yyyy', new Date())])
    }
    // common
    if (enterBarcodeNumberRef.current) enterBarcodeNumberRef.current?.focus()
  }

  return (
    <Modal
      isOpen={isOpen}
      centered
      onOpened={onPopupOpenFocus}
      toggle={() => {
        toggle()
        reset()
      }}

      contentClassName="rounded-12px"
    >
      <ModalBody className="d-flex flex-column gap-24px py-16px px-20px ">
        <div className="d-flex justify-content-between align-items-center">
          <div className="txt-h3-md text-dark">{isEdit ? t('Edit Batch') : t('Create Batch')}</div>
          <X onClick={() => {
            toggle()
            reset()
          }} size={16} className="text-dark cursor-pointer" />
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="d-flex flex-column gap-14px">
            <InputField
              errors={errors}
              {...register('external_batch_id', {
                required: 'This field is required'
              })}
              ref={(e) => {
                register('external_batch_id').ref(e)
                enterBarcodeNumberRef.current = e
              }}
              isClearable
              label="Enter Batch Number"
              isRequired
            // value={watch('external_batch_id')}
            />
            <div className="d-flex gap-14px mb-20px">
              <DatePickerInput className='flex-grow-1'
                validationSchema={{ manufacturing_date: { required: 'This field is required' } }}
                errors={errors}
                control={control}
                setValue={setValue}
                watch={watch}
                name='manufacturing_date'
                title='Manufacturing Date'
                isClearable={true}
                onChange={handleChangeDate}
                isRequired
              />
              <DatePickerInput className='flex-grow-1'
                validationSchema={{ expiry_date: { required: 'This field is required' } }}
                errors={errors}
                control={control}
                setValue={setValue}
                watch={watch}
                name='expiry_date'
                title='Expiry Date'
                isClearable={true}
                isRequired
                customOptions={{ minDate: watch('manufacturing_date')?.[0] }}
              />
            </div>
          </div>
          <Button
            type='submit'
            className="w-100 justify-content-center"
            disabled={loadingState.createBatch || loadingState.editBatch || !isDirty || !watch('expiry_date') || !watch('external_batch_id') || !watch('manufacturing_date')}
          >
            {(loadingState.createBatch || loadingState.editBatch) && <Spinner size="sm" />}
            <span className="align-middle ms-25">{t('Save')}</span>
          </Button>
        </form>
      </ModalBody>
    </Modal>
  )
}

export default CreateBatchModal
