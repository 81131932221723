import CustomLabel from '@src/@core/components/ui/badge/CustomLabel'
import DOMPurify from 'dompurify'
import { useState } from 'react'
import { Plus } from 'react-feather'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap'
import { ASSEMBLED, BUNDLE, MAX_SINGLE_SKU_DESC_LENGTH } from '../../catalog.constants'

const MINIMUM_VISIBLE_BARCODE = 2
const MINIMUM_VISIBLE_LABEL = 3

const SkuDetail = ({ t, sku, skuType, barcodeModalOpen, setBarcodeList, setBarcodeModalOpen }) => {
  const [showFullDesc, setShowFullDesc] = useState(false)


  const toggleBarcodeModal = (barcodes) => {
    if (!barcodeModalOpen) setBarcodeList(barcodes ? barcodes?.map(barcode => ({ barcodeValue: barcode })) : [])
    setBarcodeModalOpen(p => !p)
  }

  const getSourceDetails = (sku) => {
    if (sku.sku_upsert_source_details?.source === 'sheet_import') {
      return <a href={sku.sku_upsert_source_details.file_link}>{t('Sheet')}</a>
    } else {
      return 'Manual'
    }
  }

  const sourceDetails = sku.sku_upsert_source_details?.source ? getSourceDetails(sku) : '-'

  return (
    <div className='w-100 d-flex flex-column gap-2' id='simple-sku-details'>
      <div className='child-sku-details'>
        <p className='txt-body-rg text-dark-6'>{skuType === BUNDLE ? t('Kit Name') : t('Assembled Kit Name')}</p>
        <p className='text-dark txt-body-md  text-truncate' title={sku.name}>
          {sku.name || '-'}
        </p>
        <p className='txt-body-rg text-dark-6'>{t('SKU Code')}</p>
        <p className='text-dark txt-body-md  text-truncate'>
          {sku.seller_sku_code || '-'}
        </p>
        {skuType === ASSEMBLED && <p className='txt-body-rg text-dark-6'>{t('Barcode(s)')}</p>}
        {skuType === ASSEMBLED && <p className='text-dark txt-body-md  text-truncate'>
          {sku.barcodes ? <div className='d-flex gap-8px align-items-center'>
            {sku.barcodes.length <= MINIMUM_VISIBLE_BARCODE ? <div className='d-flex gap-8px align-items-center'>
              {sku.barcodes.map((ele, index) => {
                return <CustomLabel key={index} title={ele} className='text-dark-6 bg-dark-1' />
              })}
            </div> :
              <div className='d-flex gap-8px align-items-center'>
                {sku.barcodes.map((ele, index) => {
                  if (index < MINIMUM_VISIBLE_BARCODE) {
                    return <CustomLabel key={index} title={ele} className='text-dark-6 bg-dark-1' />
                  }
                })}
                <div className='d-flex align-items-center gap-4px border border-primary remaining-barcodes cursor-pointer' onClick={() => toggleBarcodeModal(sku.barcodes)}>
                  <Plus size={16} className='text-primary' />
                  <span className='text-primary txt-body-rg'>{sku.barcodes.length - MINIMUM_VISIBLE_BARCODE}</span>
                </div>
              </div>}
          </div> : '-'}
        </p>}
        <p className='txt-body-rg text-dark-6'>{t('SKU Tags')}</p>
        <p className='text-truncate labels'>
          {sku.labels ? <div className='d-flex gap-8px align-items-center'>
            {sku.labels?.length <= MINIMUM_VISIBLE_LABEL ? <div className='d-flex gap-8px align-items-center'>
              {sku.labels.map((ele, index) => {
                return ele ? <CustomLabel key={index} title={ele.name} className='text-dark bg-dark-1' /> : '-'
              })}
            </div> :
              <div className='d-flex gap-8px align-items-center sku-barcodes'>
                {sku.labels?.map((ele, index) => {
                  if (index < MINIMUM_VISIBLE_LABEL) {
                    return ele ? <CustomLabel key={index} title={ele.name} className='text-dark bg-dark-1' /> : '-'
                  }
                })}
                {sku.labels?.length > MINIMUM_VISIBLE_LABEL && (
                  <UncontrolledDropdown direction="bottom-right">
                    <DropdownToggle className="cursor-pointer p-0 border-0">
                      <div className="d-flex w-fit justify-end align-items-center gap-4px border border-primary rounded-8px px-6px cursor-pointer">
                        <Plus size={16} color="var(--bs-primary)" />
                        <span className="text-primary txt-body-rg">{sku.labels.length - MINIMUM_VISIBLE_LABEL}</span>
                      </div>
                    </DropdownToggle>
                    <DropdownMenu container="simple-sku-details">
                      <PerfectScrollbar className="max-height-200">
                        {sku.labels.slice(MINIMUM_VISIBLE_LABEL)?.map((item, index) => (
                          <DropdownItem key={index} text className="d-flex justify-content-left px-8px py-4px w-100">
                            <div className="txt-sub-rg text-dark d-inline-block text-truncate width-100">{item.name}</div>
                          </DropdownItem>)
                        )}
                      </PerfectScrollbar>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                )}
              </div>}
          </div> : '-'}
        </p>
      </div>
      {
        sku.created_at && <div className='child-sku-details'>
          <p className='txt-body-rg text-dark-6'>{t('Created At')}</p>
          <p className='text-dark txt-body-md  text-truncate'>
            {sku.created_at}
          </p>
        </div>
      }
      {
        sku.updated_by_user && <div className='child-sku-details'>
          <p className='txt-body-rg text-dark-6'>{t('Updated By')}</p>
          <p className='text-dark txt-body-md  text-truncate'>
            {sku.updated_by_user}
          </p>
        </div>
      }
      {
        sku.updated_at && <div className='child-sku-details'>
          <p className='txt-body-rg text-dark-6'>{t('Updated At')}</p>
          <p className='text-dark txt-body-md  text-truncate'>
            {sku.updated_at}
          </p>
        </div>
      }
      
      <div className='child-sku-details'>
        <p className='txt-body-rg text-dark-6'>{t('Updated Via')}</p>
        <p className='text-dark txt-body-md'>
          {sourceDetails}
        </p>
      </div>
      
      {sku.description && <div>
        <div className='child-sku-desc-title txt-sub-rg text-dark'>{t('Description')}</div>
        <div className='txt-sub-rg text-dark child-sku-desc-content'>
          {!showFullDesc && (sku.description.length <= MAX_SINGLE_SKU_DESC_LENGTH ? <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(sku.description) }}></div> : <div>
            <span className='simple-sku-show-less-text' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(`${sku.description.slice(0, 395)}...`) }}></span>
            <span className='txt-sub-md text-primary cursor-pointer' onClick={() => setShowFullDesc(true)}>{t(' Show full')}</span>
          </div>)}
          {showFullDesc && (
            <div>
              <span className='simple-sku-show-less-text' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(sku.description) }}></span><span className='txt-sub-md text-primary cursor-pointer' onClick={() => setShowFullDesc(false)}>{t(' Show less')}</span>
            </div>
          )}
        </div>
      </div>}

    </div>
  )
}

export default SkuDetail
