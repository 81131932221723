import Button from '@src/@core/components/ui/button'
import { createSplitOrder, updateSplitOrder } from '@src/views/sales/store/store'
import { AlertTriangle } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Modal, ModalBody } from 'reactstrap'

const SplitOrderPopUp = (props) => {
  const { splitOrderPopUp, handleClosePopUp, order_id, isSplitOrderCreated } = props
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const handleAction = () => {
    handleClosePopUp()
    if (isSplitOrderCreated) {
      dispatch(updateSplitOrder({order_id, body:splitOrderPopUp.body}))
    } else {
      dispatch(createSplitOrder({body:splitOrderPopUp.body}))
    }
  }

  return (
    <Modal
      isOpen={splitOrderPopUp.isOpen}
      centered
      className="width-350 height-250 rounded-4px"
    >
      <ModalBody className='p-16px pt-24px'>
        <div className="d-flex justify-content-center">
          <AlertTriangle color="var(--bs-warning)" size={70} fill="var(--bs-warning)" stroke="white" strokeWidth={1.5} />
        </div>
        <div className="mt-24px">
          <div className="txt-body-sb text-dark">
            {t(splitOrderPopUp.title)}
          </div>
          <div className="mt-12px txt-body-rg">
            {t(splitOrderPopUp.description)}
          </div>
        </div>
        <div className='d-flex gap-12px mt-24px'>
          <Button className="w-50 d-flex justify-content-center" ofStyle="outlined" onClick={handleClosePopUp} >
            {t('Take me back')}
          </Button>
          <Button className="w-50 d-flex justify-content-center" onClick={handleAction} >
            {t(splitOrderPopUp.buttonName)}
          </Button>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default SplitOrderPopUp