

import Button from '@src/@core/components/ui/button'
import CustomToast from '@src/@core/components/ui/custom-toast/CustomToast'
import { ExpandableCustomTable } from '@src/@core/components/ui/globalTable/ExpandableCustomTable'
import { ALL_COLUMN_NAME_MAPPING } from '@src/@core/components/ui/globalTable/globalTable.constant'
import PageHeader from '@src/@core/components/ui/page-header'
import { SORTING_ORDER } from '@src/App.constants'
import abilityMap from '@src/assets/data/abilityMapping/abilityMapping'
import Orders from '@src/assets/images/icons/primary-navbar/Sales'
import { AbilityContext } from '@src/utility/context/Can'
import { usePagination, useSearch, useSorting } from '@src/utility/hooks/useFilters'
import { SALESMAN_MODAL_TYPE } from '@src/views/sales/constant/orders.constants'
import { useContext, useEffect, useState } from 'react'
import { MoreVertical } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap'
import { getSalesmanAddress, getSalesmen, resetSuccess } from '../store/store'
import CreateSalesman from './components/CreateSalemen'
import SalesmenDetails from './components/salesmenDetails'
import './styles.scss'

const dropdownOptions = [
  { id: 'name', name: 'Salesman Name' },
  { id: 'employeeid', name: 'Employee ID' },
  { id: 'phone', name: 'Contact Number' },
  { id: 'email', name: 'Email' }
]


const SalesMen = () => {
  const pageHeaderProps = {
    breadcrumbIcon: Orders,
    title: 'Salesmen',
    breadcrumbsData: [{ title: 'Orders' }]
  }
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const ability = useContext(AbilityContext)
  const salesmanDetail = useSelector(store => store.sales.salesPersons)
  const  salesmanMetaData = salesmanDetail?.meta || {}
 

  const {
    createSalesmanSuccess,
    editSalesmanSuccess,
    getSalesmanSuccess,
    error,
    loading,
  } = useSelector(store => {
    return {
      createSalesmanSuccess: store.sales.success.createSalesman,
      editSalesmanSuccess: store.sales.success.editSalesman,
      getSalesmanSuccess: store.sales.success.getSalesmen,
      error: store.sales.error,
      loading: store.sales.loading,
    }
  })

  const [openCreateSalesmanSidebar,setOpenCreateSalesmanSidebar] = useState({ open: false, type: SALESMAN_MODAL_TYPE.CREATE_SALESMAN.key })
  const [isEditSalesmanEnabled, setIsEditSalesmanEnabled] = useState(false)
  const [salesmanTableData, setSalesmanTableData] = useState([])
  const [openSalesmanDetailsSidebar, setOpenSalesmanDetailsSidebar] = useState(false)
  const [selectedSalesman, setSelectedSalesman] = useState({})
  

  // custom hooks
  const { sort, setSort } = useSorting()
  const { pagination, setPagination } = usePagination()
  const { searchQuery, setSearchQuery } = useSearch({ column: dropdownOptions[0] })
  const selectedGlobalHubId = useSelector(state => state.auth.selectedGlobalHubId)
  const salesmenAddresses = useSelector(store => store.sales.salesmanAddress?.data)
  
  const handleCreateUserSidebar = () => {
    setOpenCreateSalesmanSidebar({open : true, type : SALESMAN_MODAL_TYPE.CREATE_SALESMAN.key})
  }

  const actions = {
    CREATE_CUSTOMER: {
      id: 'createSalesman',
      text: 'Create Salesman',
      onClick: handleCreateUserSidebar
    },
   
  }

  const handleViewSalesmanDetails = (row) => {
    setOpenSalesmanDetailsSidebar(true)
    setSelectedSalesman(row)
  }
  
  const handleEditSalesmanDetailsModal = (row) => {
    // console.log(row,'open row')
 
    setSelectedSalesman(row)
    setIsEditSalesmanEnabled(true)
    dispatch(getSalesmanAddress({ hub_id: selectedGlobalHubId, sales_person_id: row?.id }))
    // setOpenSalesmanDetailsSidebar(true)
    setOpenCreateSalesmanSidebar({open : true, type : SALESMAN_MODAL_TYPE.EDIT_SALESMAN.key})
    
  }


  const handlePagination = page => {
    const pagination = { page: page.selected, per_page: page.per_page ? page.per_page : 20 }
    setPagination(pagination)
  }

  const handleGetAllSalesmen = () => {
    const searchParams = searchQuery.query ? {search_column:searchQuery.column.id, search_query: searchQuery.query } : {}
   
    dispatch(getSalesmen({hubId:selectedGlobalHubId, ...searchParams, ...pagination}))
  }

  const handleSort = (sortField) => {
    setSort(sortField)
  }

  const handleSearchItem = ({ searchVal, selectedColumn = null }) => {
    const searchQuery = searchVal ? { column: selectedColumn, query: searchVal } : null
    setSearchQuery(searchQuery)
  }
 

  const TableColumns = [
    {
      id: 1,
      name: t('S. No.'),
      key: 'srNo',
      minWidth: '50px',
      maxWidth: '60px',
      cell: (row) => {
        return (
          <div className="w-100 text-center">
            {row.serialNumber}
          </div>
        )
      }
    },
    {
      id: 2,
      name: t('Salesman Name'),
      minWidth: '200px',
      key: 'customerName',
      cell: row => <span className='text-primary cursor-pointer' onClick={() => { handleViewSalesmanDetails(row) }}>{`${row.first_name} ${row.last_name}`}</span> 
    },
    {
      id: 3,
      name: t('Email ID'),
      key: 'email',
      reorder: true,
      minWidth: '200px',
      selector: row => row.email || '-'
    },
    {
      id: 4,
      name: t('Contact Number'),
      key: 'contactNo',
      reorder: true,
      minWidth: '200px',
      selector: row => row.phone? `${row.country_calling_code}-${row.phone}` : '-'
    },
    {
      id: 5,
      name: t('Employee ID'),
      key: 'empolyeeId',
      reorder: true,
      minWidth: '200px',
      selector: row => row.employee_id || '-'
    },
    
    {
      id: 6,
      name: t('Action'),
      width: '70px',
      key: 'actions',
      allowOverflow: true,
      omit:!ability.can(
        abilityMap.salesman.edit.action,
        abilityMap.salesman.edit.resource
      ),
      cell: (row) => {
        return (
          <div className="d-flex justify-content-center align-items-center w-100 cursor-pointer">
            <UncontrolledDropdown>
              <DropdownToggle className="pe-1 more_vert" tag="span">
                <MoreVertical size={20} />
              </DropdownToggle>
              {(ability.can(abilityMap.salesman.edit.action, abilityMap.salesman.edit.resource)) &&
                <DropdownMenu end container="body">
                
                  <DropdownItem className="w-100 text-dark" onClick={() => { handleEditSalesmanDetailsModal(row) }}>
                    <span className="align-middle ms-50">{t('Edit')}</span>
                  </DropdownItem>
                  
                  
                </DropdownMenu>}
            </UncontrolledDropdown>
          </div>
        )
      }
    }
  ]
  const defaultSortColumn = TableColumns.find((column) => column.key === sort.field) || {}

  const handleCreateNewSalesman = () => {
    setOpenCreateSalesmanSidebar({open: true, type:  SALESMAN_MODAL_TYPE.CREATE_SALESMAN.key})
    
  }
  useEffect(() => {
    const newTableData = []
    if (salesmanDetail?.data?.length) {
     
      salesmanDetail?.data?.map((salesman, index) => {
        
        newTableData.push({
          ...salesman,
          serialNumber: ((salesmanDetail?.meta.current_page - 1) * salesmanDetail?.meta.per_page) + index + 1
        })
      })
    }
    setSalesmanTableData(newTableData)
  
  }, [salesmanDetail])

  useEffect(() => {
    
    if (createSalesmanSuccess) {
      handleGetAllSalesmen()
      dispatch(resetSuccess())
      setOpenCreateSalesmanSidebar(prev => ({ ...prev, open: false }))
      CustomToast('Salesman created successfully', { my_type: 'success' })
    }
    
    if (editSalesmanSuccess) {
      handleGetAllSalesmen()
      dispatch(resetSuccess())
      setOpenCreateSalesmanSidebar(prev => ({ ...prev, open: false }))
      CustomToast('Salesman edit successfully', { my_type: 'success' })
    }

  }, [createSalesmanSuccess, editSalesmanSuccess])

  useEffect(() => {
    handleGetAllSalesmen()
   
  }, [])


  return (
    <div className="all-customers-page-wrapper">
      <div className="customers-container">
        <div className='page-header-container'>
          <PageHeader
            {...pageHeaderProps}
          />
        </div>
        
        <div className="p-1">
          <ExpandableCustomTable
            loading={loading.getSalesmen}
            error={error.getSalesmen}
            success={getSalesmanSuccess}
            data={salesmanTableData}
            columns={TableColumns}
            columnName={ALL_COLUMN_NAME_MAPPING.SALESMEN}
            searchcolumnsList={dropdownOptions}
            search_column_query={searchQuery}
            handleQueryParams={handleSearchItem}
            meta={salesmanMetaData || {}}
            useReactPaginate={false}
            handlePagination={handlePagination}
            handleRefreshTable={handleGetAllSalesmen}
            showColumnsTableHeader={true}
            handleSort={handleSort}
            defaultSortFieldId={defaultSortColumn.id}
            defaultSortAsc={sort.order === SORTING_ORDER.ASCENDING}
            TableHeaderComponent={ability.can(
              abilityMap.salesman.create.action,
              abilityMap.salesman.create.resource
            ) &&
          
            <div className="d-flex gap-1 ps-14px border-start border-primary-lighter customer-bulk-action-wrapper ">
              <Button  width="100%" onClick={handleCreateNewSalesman} type="button" className='bg-primary border-primary-lighter' >
                {t('Create Salesman')}
              </Button>
            </div>
            }
            
          />
        </div>
      </div>
     
      <CreateSalesman
        openCreateSalesmanSidebar={openCreateSalesmanSidebar}
        setOpenCreateSalesmanSidebar={setOpenCreateSalesmanSidebar}
        setIsEditSalesmanEnabled={setIsEditSalesmanEnabled}
        handleGetAllSalesmen={handleGetAllSalesmen}
        isEditSalesmanEnabled={isEditSalesmanEnabled}
        salesmanDetails={selectedSalesman}
        address={salesmenAddresses}
      />
      <SalesmenDetails
        openSalesmanDetailsSidebar={openSalesmanDetailsSidebar}
        setOpenSalesmanDetailsSidebar={setOpenSalesmanDetailsSidebar}
        salesmanDetails={selectedSalesman}
        handleGetAllSalesmen={handleGetAllSalesmen}
        isEditSalesmanEnabled={isEditSalesmanEnabled}
        setIsEditSalesmanEnabled={setIsEditSalesmanEnabled}
        handleEditSalesmanDetailsModal={handleEditSalesmanDetailsModal}
        setSelectedSalesman={setSelectedSalesman}
        setOpenCreateSalesmanSidebar={setOpenCreateSalesmanSidebar}
      />
      
    </div>
  )
}

export default SalesMen