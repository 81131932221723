function getFormattedDate() {
  const today = new Date()
  const dd = String(today.getDate()).padStart(2, '0')
  const mm = String(today.getMonth() + 1).padStart(2, '0') // January is 0!
  const yyyy = today.getFullYear()

  return `${dd  }-${  mm  }-${  yyyy}`
}

export const AD_HOC_ACTIVITIES_TABS = {
  ACTIVITIES: {label: 'Activities', value: 'activities'},
  USAGE: {label: 'Usage', value: 'usage'}
}

export const CREATE_PROFILE_TABS = {
  LIVE: {label: 'Live', value: 'live'},
  DRAFTS: {label: 'Drafts', value: 'drafts'}
}

export const UPDATE_AD_HOC_USAGE_FORM_FIELD_NAME = {
  HOURS: 'hours',
  DATE: 'date',
  AD_HOC: 'ad_hoc',
  SELLER: 'seller'
}

export const UPDATE_AD_HOC_USAGE_FORM_DEFAULT_VALUE = {
  [UPDATE_AD_HOC_USAGE_FORM_FIELD_NAME.HOURS]: '',
  [UPDATE_AD_HOC_USAGE_FORM_FIELD_NAME.DATE]: { label: getFormattedDate(), value: getFormattedDate() },
  [UPDATE_AD_HOC_USAGE_FORM_FIELD_NAME.AD_HOC]: null,
  [UPDATE_AD_HOC_USAGE_FORM_FIELD_NAME.SELLER]: null
}

export const UPDATE_AD_HOC_USAGE_FORM_VALIDATION_SCHEMA = {
  [UPDATE_AD_HOC_USAGE_FORM_FIELD_NAME.HOURS]: { required: 'This field is required', valueAsNumber: true },
  [UPDATE_AD_HOC_USAGE_FORM_FIELD_NAME.DATE]: { required: 'This field is required' },
  [UPDATE_AD_HOC_USAGE_FORM_FIELD_NAME.AD_HOC]: { required: 'This field is required' },
  [UPDATE_AD_HOC_USAGE_FORM_FIELD_NAME.SELLER]: { required: 'This field is required' }
}

export const ADD_AD_HOC_FORM_FIELD_NAME = {
  NAME: 'name',
  DESCRIPTION: 'description',
  UNIT: 'unit'
}

export const ADD_AD_HOC_FORM_DEFAULT_VALUE = {
  [ADD_AD_HOC_FORM_FIELD_NAME.NAME]: '',
  [ADD_AD_HOC_FORM_FIELD_NAME.DESCRIPTION]: '',
  [ADD_AD_HOC_FORM_FIELD_NAME.UNIT]: ''
}

export const ADD_AD_HOC_VALIDATION_SCHEMA = {
  [ADD_AD_HOC_FORM_FIELD_NAME.NAME]: { required: 'This field is required' },
  [ADD_AD_HOC_FORM_FIELD_NAME.DESCRIPTION]: { required: 'This field is required' },
  [ADD_AD_HOC_FORM_FIELD_NAME.UNIT]: { required: 'This field is required' }
}

export const ROUTE_PATHS = {
  BILLING: '/settings/billing/:loc?',
  
  BILLING_PROFILE: '/settings/billing/profile',
  // To be used only in modules.js
  BILLING_PROFILE_MODULE: '/settings/billing/profile/:loc?',
  BILLING_PROFILE_CREATE: '/settings/billing/profile/bp-create',
  VIEW_BILLING_PROFILE: '/settings/billing/profile/view-bp',
  
  CONTRACTS: '/settings/billing/contracts',
  // To be used only in modules.js
  SINGLE_CONTRACTS_MODULE: '/settings/billing/contracts/:loc?',
  VIEW_CONTRACT: '/settings/billing/contracts/view-contract',
  EDIT_CONTRACT: '/settings/billing/contracts/edit-contract',


  BILLS: '/settings/billing/bills',
  SINGLE_BILLS_MODULE: '/settings/billing/bills/:loc?',
  VIEW_BILL: '/settings/billing/bills/view-bill',
  AD_HOC_USAGE: '/settings/billing/ad-hoc-usage',
  AD_HOC_ACTIVITIES: '/settings/ad-hoc-activities'
}

export const FEES_TYPES = {
  RECCURING_FEES: 'Reccuring Fees',
  RECEIVING_FEES: 'Receiving Fee',
  STORAGE_FEES: 'Storage Fees',
  FULFILMENT_KEYS: 'Fulfilment Fees',
  RETURN_FEES: 'Return Fees'
}

export const ADDITIONAL_FILTER_DROPDOWN_STYLE = {
  control: (baseStyles) => ({
    ...baseStyles,
    width: '150px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    textAlign: 'start',
    border: 'none',
    boxShadow: 'none'
  })
}

export const ADHOC_INITIAL_DATA = {
  name: 'Adhoc Fees',
  rules: []
}

export const SHIPPING_INITIAL_DATA = {
  name: 'Shipping Fees',
  rules: []
}

export const BILLING_PROFILE_DETAILS_FORM_FIELD_NAME = {
  BILLING_PROFILE_NAME: 'name',
  BILLING_PROFILE_CURRENCY: 'currency'
}

export const BASIC_DETAILS_INITIAL_DATA = {
  name: 'Basic Details',
  [BILLING_PROFILE_DETAILS_FORM_FIELD_NAME.BILLING_PROFILE_NAME]: '',
  [BILLING_PROFILE_DETAILS_FORM_FIELD_NAME.BILLING_PROFILE_CURRENCY]: ''
}

export const CONTRACTS_TABS = [
  { id: 'live', name: 'Live' },
  { id: 'terminated', name: 'Terminated' }
]

export const BILL_STATUS = {
  DRAFT: { id: 'draft', name: 'Draft' },
  FINALISED: { id: 'finalised', name: 'Finalised' },
  PAID: { id: 'paid', name: 'Paid' },
  OVERDUE: { id: 'due', name: 'Overdue' },
  REJECTED: { id: 'rejected', name: 'Rejected' }
}

export const BILL_STATUS_MAP = {
  draft: { id: 'draft', name: 'Draft', badgeClassNames: 'bg-dark-1 text-dark' },
  finalised: { id: 'finalised', name: 'Finalised', badgeClassNames: 'bg-info-light text-info' },
  paid: { id: 'paid', name: 'Paid', badgeClassNames: 'bg-success-light text-success' },
  overdue: { id: 'due', name: 'Overdue', badgeClassNames: 'bg-danger-light text-danger' },
  rejected: { id: 'rejected', name: 'Rejected', badgeClassNames: 'bg-brown-light text-brown' }
}

export const getBadgeClassnamesForBillStatus = (billStatus) => {
  switch (billStatus) {
  case BILL_STATUS.DRAFT.id: return 'bg-dark-1 text-dark'
  case BILL_STATUS.FINALISED.id: return 'bg-info-light text-info'
  case BILL_STATUS.PAID.id: return 'bg-success-light text-success'
  case BILL_STATUS.OVERDUE.id: return 'bg-danger-light text-danger'
  case BILL_STATUS.REJECTED.id: return 'bg-brown-light text-brown'
  default: return 'bg-purple-light text-purple'
  }
}

export const COST_RANGE_INITIAL_DATA = {
  operator: 'range',
  from: '',
  to: '',
  value: ''
}

export const LOWER_RANGE_DROPDOWN_OPTIONS = {
  range: 'Greater Than Equal To',
  more_than: 'Greater Than',
  less_than: 'Less Than'
}

export const BILLING_STATUS = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive'
}

export const COST_RANGE_ERRORS = {
  INVALID_RANGE: { key: 'invalid_range', error_message: 'Lower range value cannot be equal or greater than the upper range value.'},
  OVERLAPPING_RANGE: { key: 'overlapping_range', error_message: 'Some ranges are overlapping!'}
}