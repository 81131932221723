import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import './styles.scss'

const Toggle = React.forwardRef(({ id, name, ofSize, disabled, activeText = 'Active', inactiveText = 'Inactive', activeColor, inactiveColor, ...rest }, ref) => {
  const inputId = id || Date.now()

  return (
    <div className='omniful-toggle-component-wrapper'>
      <label
        className={classNames(`toggle-wrapper cursor-pointer size-${ofSize}`,
          {'disabled ': disabled, 'cursor-default ': disabled, 'active ': rest.checked, 'inactive ': !rest.checked})}
        style={{ background: rest.checked ? activeColor : inactiveColor }}
        htmlFor={`${name}_${inputId}`}
      >
        <input
          id={`${name}_${inputId}`}
          name={name}
          type="checkbox"
          disabled={disabled}
          {...rest}
          ref={ref}
        />
        <span className='switch-text w-100'>{rest.checked ? activeText : inactiveText}</span>
        <div className="slider"></div>
      </label>
    </div >
  )
})

Toggle.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  ofSize: PropTypes.oneOf(['regular', 'compressed']),
  disabled: PropTypes.bool
}

Toggle.defaultProps = {
  ofSize: 'regular',
  disabled: false
}

export default Toggle