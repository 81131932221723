const SVG = ({ iswhite, fillColor, height, width }) => {
  const color = iswhite ? 'white' : fillColor
  return (
    <svg width={width} height={height} viewBox="0 0 77 77" fill={color} xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={color}
        d="M19.2588 22.5379H41.376V20.3081H19.0995C17.956 20.3081 17.0291 21.2351 17.0291 22.3786V58.4274H19.2588V22.5379ZM53.9582 32.8904V62.8518C53.9582 66.8029 50.7461 70 46.7911 70C42.8361 70 39.624 66.8029 39.624 62.8518V58.4274H41.8538V62.8518C41.8538 65.5649 44.061 67.7702 46.7911 67.7702C49.5212 67.7702 51.7284 65.5649 51.7284 62.8518V32.8904H53.9582Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={color}
        d="M10 59.329C10 58.1855 10.927 57.2585 12.0705 57.2585H40.7389C41.3546 57.2585 41.8538 57.7577 41.8538 58.3734C41.8538 58.9892 41.3546 59.4883 40.7389 59.4883H12.2298V62.1959C12.2298 65.2745 14.7255 67.7703 17.8042 67.7703H46.6319C47.2476 67.7703 47.7467 68.2694 47.7467 68.8852C47.7467 69.5009 47.2476 70.0001 46.6319 70.0001H17.8042C13.494 70.0001 10 66.506 10 62.1959V59.329Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={color}
        d="M25.4491 32.5718C25.9769 32.5718 26.4047 32.1439 26.4047 31.6162C26.4047 31.0884 25.9769 30.6606 25.4491 30.6606C24.9213 30.6606 24.4935 31.0884 24.4935 31.6162C24.4935 32.1439 24.9213 32.5718 25.4491 32.5718ZM25.4491 34.8015C27.2083 34.8015 28.6345 33.3754 28.6345 31.6162C28.6345 29.8569 27.2083 28.4308 25.4491 28.4308C23.6899 28.4308 22.2637 29.8569 22.2637 31.6162C22.2637 33.3754 23.6899 34.8015 25.4491 34.8015Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={color}
        d="M25.4491 42.1279C25.9769 42.1279 26.4047 41.7001 26.4047 41.1723C26.4047 40.6445 25.9769 40.2167 25.4491 40.2167C24.9213 40.2167 24.4935 40.6445 24.4935 41.1723C24.4935 41.7001 24.9213 42.1279 25.4491 42.1279ZM25.4491 44.3577C27.2083 44.3577 28.6345 42.9316 28.6345 41.1723C28.6345 39.4131 27.2083 37.9869 25.4491 37.9869C23.6899 37.9869 22.2637 39.4131 22.2637 41.1723C22.2637 42.9316 23.6899 44.3577 25.4491 44.3577Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={color}
        d="M25.4491 51.6841C25.9769 51.6841 26.4047 51.2562 26.4047 50.7285C26.4047 50.2007 25.9769 49.7729 25.4491 49.7729C24.9213 49.7729 24.4935 50.2007 24.4935 50.7285C24.4935 51.2562 24.9213 51.6841 25.4491 51.6841ZM25.4491 53.9138C27.2083 53.9138 28.6345 52.4877 28.6345 50.7285C28.6345 48.9692 27.2083 47.5431 25.4491 47.5431C23.6899 47.5431 22.2637 48.9692 22.2637 50.7285C22.2637 52.4877 23.6899 53.9138 25.4491 53.9138Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={color}
        d="M30.0679 31.2976C30.0679 30.6819 30.3428 30.1827 30.6819 30.1827H39.4538C39.793 30.1827 40.0679 30.6819 40.0679 31.2976C40.0679 31.9134 39.793 32.4125 39.4538 32.4125H30.6819C30.3428 32.4125 30.0679 31.9134 30.0679 31.2976Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={color}
        d="M30.0679 41.1724C30.0679 40.5566 30.567 40.0575 31.1828 40.0575H47.1096C47.7254 40.0575 48.2245 40.5566 48.2245 41.1724C48.2245 41.7881 47.7254 42.2873 47.1096 42.2873H31.1828C30.567 42.2873 30.0679 41.7881 30.0679 41.1724Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={color}
        d="M30.0679 50.7285C30.0679 50.1128 30.567 49.6136 31.1828 49.6136H47.1096C47.7254 49.6136 48.2245 50.1128 48.2245 50.7285C48.2245 51.3443 47.7254 51.8434 47.1096 51.8434H31.1828C30.567 51.8434 30.0679 51.3443 30.0679 50.7285Z"
      />
      <path
        d="M66.7773 13.2584L65.2999 7.6784C65.1939 7.27833 64.8347 7 64.4243 7H40.5757C40.1653 7 39.8061 7.27833 39.7001 7.6784L38.2227 13.2584C38.0686 13.8399 38 14.3676 38 14.9696V16.9626C38 18.6289 39.1103 20.0374 40.6233 20.4772V30.5137C40.6233 32.4361 42.1733 34 44.0784 34H60.9216C62.8267 34 64.3766 32.4361 64.3766 30.5137V20.4772C65.8897 20.0374 67 18.6289 67 16.9626V14.9696C67 14.3676 66.9314 13.8399 66.7773 13.2584ZM41.2716 8.82888H63.7284L64.9136 13.3048H40.0864L41.2716 8.82888ZM59.75 16.9626C59.75 17.971 58.9369 18.7915 57.9375 18.7915C56.9381 18.7915 56.125 17.971 56.125 16.9626V15.1337H59.75V16.9626ZM45.25 16.9626V15.1337H48.875V16.962V16.9626C48.875 17.971 48.0619 18.7915 47.0625 18.7915C46.0631 18.7915 45.25 17.971 45.25 16.9626ZM50.6875 16.9626V15.1337H54.3125V16.9626C54.3125 17.971 53.4994 18.7915 52.5 18.7915C51.5006 18.7915 50.6875 17.971 50.6875 16.9626ZM39.8125 16.9626V15.1337H43.4375V16.962V16.9626C43.4375 17.971 42.6244 18.7915 41.625 18.7915C40.6256 18.7915 39.8125 17.971 39.8125 16.9626ZM54.5033 32.1712H50.4967V25.8664C50.4967 25.3091 50.9461 24.8556 51.4984 24.8556H53.5017C54.054 24.8556 54.5034 25.3091 54.5034 25.8664V32.1712H54.5033ZM60.9216 32.1712H56.3158V25.8664C56.3158 24.3006 55.0534 23.0267 53.5016 23.0267H51.4984C49.9466 23.0267 48.6842 24.3005 48.6842 25.8664V32.1712H44.0784C43.1726 32.1712 42.4358 31.4276 42.4358 30.5138V20.5275C43.1871 20.3536 43.8503 19.944 44.3438 19.3796C45.0085 20.14 45.9809 20.6204 47.0625 20.6204C48.1441 20.6204 49.1165 20.14 49.7812 19.3795C50.446 20.14 51.4185 20.6204 52.5 20.6204C53.5815 20.6204 54.554 20.14 55.2188 19.3795C55.8835 20.14 56.856 20.6204 57.9375 20.6204C59.019 20.6204 59.9915 20.14 60.6562 19.3796C61.1497 19.944 61.8129 20.3536 62.5641 20.5275V30.5138C62.5641 31.4276 61.8273 32.1712 60.9216 32.1712ZM63.375 18.7915C62.3756 18.7915 61.5625 17.971 61.5625 16.9626V15.1337H65.1875V16.9626C65.1875 17.971 64.3744 18.7915 63.375 18.7915Z"
        fill={color}
      />
    </svg>
  )
}

export default SVG