import Button from '@src/@core/components/ui/button'
import SidesheetFooter from '@src/@core/components/ui/sidesheet-footer'
import { FILE_TYPES } from '@src/App.constants'
import image_fallback from '@src/assets/images/omniful/image_fallback.svg'
import classNames from 'classnames'
import { saveAs } from 'file-saver'
import JSZip from 'jszip'
import { useState } from 'react'
import { Download } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { Spinner } from 'reactstrap'
import FileIcon from './file-icon'
import Preview from './preview/preview'

const PreviewAttachments = (props) => {
  const {addedAttachments} = props

  const {t} = useTranslation()
  const [isDownloading, setDownloading] = useState(false)
  const [previewingAttachment, setPreviewingAttachment] = useState(null)

  const handleSingleDownload = (item) => {
    fetch(item.file_url)
      .then(res => res.blob()) // Gets the response and returns it as a blob
      .then(blob => {
        saveAs(blob, item.name)
      })
      .finally(() => {
        setDownloading(false)
      })
  }

  const handleDownloadAll = async() => {
    setDownloading(true)
    if (addedAttachments.length < 6) {
      addedAttachments.forEach(item => {
        handleSingleDownload(item)
      })
      return
    }
    const zip = new JSZip();
    for (const item of addedAttachments) {
      try {
        const response = await fetch(item.file_url)
        const blob = await response.blob()
        zip.file(item.name, blob);
      } catch (error) {
        console.error(`Failed to fetch file: ${item.name}`, error);
      }
    }
    zip.generateAsync({ type: 'blob' })
      .then((content) => {
      // Use FileSaver.js to download the ZIP file
        saveAs(content, 'attachments.zip');
      })
      .catch((error) => {
        console.error('Failed to generate ZIP file', error)
      })
      .finally(() => {
        setDownloading(false)
      })
  }

  const handleTogglePreview = (attachment) => {
    if (previewingAttachment) {
      setPreviewingAttachment(null)
    } else {
      setPreviewingAttachment(attachment)
    }
  }

  return (
    <div className="attachments-main-div w-100 d-flex flex-column gap-20px"> 
      <div className='d-flex flex-column gap-24px'>
        {
          addedAttachments?.map((item, index) => {
            const fileType = item.file.type.split('/')[0] // Get the file type (image, video, text, etc.)
            const isPreviewAble = item.file.type.startsWith('image/') || item.file.type === FILE_TYPES.PDF.type
            const fileName = item.file.name
            const fileUrl = item.file_url
            
            return (
              <div key={index} className={classNames('rounded-8px border border-dark-2 bg-white p-10px flex-center-between upload_file_details', {'border-danger-light': item.error})}>
                <div className="flex-center-start gap-10px width-80-per">
                  {fileType === 'image' ?
                    <div className='file-icon-wrapper flex-shrink-0 rounded-4px border border-dark-2'>
                      <img
                        src={fileUrl || image_fallback}
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null
                          currentTarget.src = image_fallback
                        }}
                        alt={fileName}
                        className='rounded-4px object-fit-contain w-100 h-100'
                      />
                    </div>
                    :
                    <div className="rounded-4px bg-light-2 flex-center-center file-icon-wrapper flex-shrink-0 rounded-4px border border-dark-2">
                      <FileIcon fileType={fileType} size={24} />
                    </div>
                  }
                  <div className={classNames('d-flex flex-column width-90-per', {
                    'gap-4px': item.description,
                    'gap-12px': !item.description
                  })}>
                    <div className='d-flex flex-column gap-2px w-100'>
                      <div
                        className="txt-body-md text-dark w-100 text-truncate file_title"
                        onClick={() => {
                          const properties = { name: fileName, type: fileType, file_url: fileUrl }
                          if (isPreviewAble) {
                            handleTogglePreview(properties)
                          } else {
                            handleSingleDownload(item)
                          }
                        }}
                      >
                        {fileName}
                      </div>
                      {item.description && <div className="txt-sub-rg text-dark-6 w-100 text-truncate">{item.description}</div>}
                    </div>
                  </div>
                </div>
                <Download 
                  size={16} color="var(--bs-primary)"
                  className="cursor-pointer"
                  onClick={() => handleSingleDownload(item)}
                />
              </div>
            )
          })
        }
      </div>   
      <SidesheetFooter>
        <div className="flex-center-end gap-12px">
          {<Button ofStyle="outlined" onClick={handleDownloadAll} disabled={isDownloading} icon={isDownloading ? Spinner : null} iconSize="sm">
            {t('Download All')}
          </Button>}
        </div>
      </SidesheetFooter>  
      <Preview 
        isOpen={!!previewingAttachment} 
        toggle={handleTogglePreview} 
        previewingAttachment={previewingAttachment}
      />
    </div>
  )
}

export default PreviewAttachments