import { ChevronUp } from 'react-feather/dist'

const ChevronOpenIcon = ({ isOpen, inputIsFocused, searchVal, isHoveredInput, isHoveredSelectTag}) => {
  let color
  if (isOpen) {
    color = '#495AD9'
  } else if (inputIsFocused || searchVal || isHoveredInput) {
    color = 'black'
  }  else if (isHoveredSelectTag) {
    color = '#5468FA'
  }   else {
    color = '#666666'
  }

  // else if (!isOpen || selectedColumn) {
  //     color = '#495AD9'
  //   }
  // else if (selectedColumn) {
  // color = 'red'
  //   } 
  return (
    <ChevronUp size={16} color= {color} />     
  )
}

export default ChevronOpenIcon
