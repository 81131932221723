export const BASE_PATH = '/pos'
export const PATH_NAME = {
  REGISTERS: `${BASE_PATH}/registers`,
  REGISTER_OPEN: `${BASE_PATH}/registers/:id/open`,
  REGISTER_CLOSE: `${BASE_PATH}/registers/:id/close`,
  REGISTER_SELL: `${BASE_PATH}/registers/:id/sell`,
  CASH_MANAGEMENT: `${BASE_PATH}/cash-management`,
  ORDERS: `${BASE_PATH}/orders`,
  ORDERS_DETAILS: `${BASE_PATH}/orders/:id/details`
}

export const REGISTER_STATUS = {
  NEW : {label: 'New', value: 'new'},
  OPEN: {label: 'Opened', value: 'open'},
  CLOSED: {label: 'Closed', value: 'closed'}
}

export const TRANSACTION_TYPES = {
  ADD: { label: 'Add', value: 'add' },
  WITHDRAW: { label: 'Withdraw', value: 'withdraw' }
}

export const PAYMENT_OPTIONS = {
  CARD: {label: 'Card', value: 'card'},
  CASH: { label: 'Cash', value: 'cash' },
  POST_PAY: { label: 'Post Pay', value: 'post_pay' }
}
export const SALES_TYPES = {
  RETAIL: { label: 'Retail', value: 'retail' },
  WHOLESALE: { label: 'Wholesale', value: 'wholesale' }
}

export const DISCOUNT_TYPES = {
  AMOUNT: { label: 'Amount', value: 'amount' },
  PERCENT: { label: 'Percent', value: 'percentage' }
}

export const CART_STATUS = {
  IN_PROCESSING: {label: 'In Processing', value: 'in_processing'},
  PAUSE: {label: 'Pause', value: 'pause'},
  CANCELLED: {label: 'Cancelled', value: 'cancelled'},
  COMPLETED: {label: 'Completed', value: 'completed'}
}

export const CUSTOMER_UPDATE_TYPE = {
  ADD: { label: 'Add', value: 'add' },
  DELETE: { label: 'Delete', value: 'delete' }
}

export const CONVERT_PATH_WITH_PARAMS = ({pathName, params}) => {
  Object.keys(params).forEach(key => {
    const value = params[key]
    pathName = pathName.replace(new RegExp(`/${value}(\\/|$)`, 'g'), `/:${key}$1`)
  })
  return pathName
}

export const DENOMINATION = [
  {
    id:1,
    value:500,
    currency:'SAR',
    count:0,
    label:'500',
    amount:0
  },
  {
    id:2,
    value:200,
    currency:'SAR',
    count:0,
    label:'200',
    amount:0
  },
  {
    id:3,
    value:100,
    currency:'SAR',
    count:0,
    label:'100',
    amount:0
  },
  {
    id:4,
    value:50,
    currency:'SAR',
    count:0,
    label:'50',
    amount:0
  },
  {
    id:5,
    value:20,
    currency:'SAR',
    count:0,
    label:'20',
    amount:0
  },
  {
    id:6,
    value:10,
    currency:'SAR',
    count:0,
    label:'10',
    amount:0
  },
  {
    id:7,
    value:5,
    currency:'SAR',
    count:0,
    label:'5',
    amount:0
  },
  {
    id:8,
    value:1,
    currency:'SAR',
    count:0,
    label:'1',
    amount:0
  },
  {
    id:9,
    value:0.5,
    currency:'SAR',
    count:0,
    label:'1/2',
    amount:0
  },
  {
    id:10,
    value:0.25,
    currency:'SAR',
    count:0,
    label:'1/4',
    amount:0
  }
]

export const REGISTER_TYPES = {
  SALE:{label:'Sale', value:'sale'},
  WHOLE_SALE:{label:'Whole Sale', value:'whole_sale'}
}

export const POS = {label:'POS', value:'pos'}

export const ACTIONS = {
  ADD: { label: 'Add', value: 'add' },
  DELETE: { label: 'Delete', value: 'delete' },
  CONTINUE: { label: 'Continue', value: 'continue' }
}