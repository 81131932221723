import Button from '@src/@core/components/ui/button'
import FloatingDropDown from '@src/@core/components/ui/floating-dropdown'
import InputField from '@src/@core/components/ui/input-field'
import SideSheet from '@src/@core/components/ui/sideSheet'
import SidesheetFooter from '@src/@core/components/ui/sidesheet-footer'
import { LARGE, MEDIUM, SMALL } from '@src/views/hubs/constants.hubs'
import { clearCreateBoxesResponse, createBoxes, getBoxes } from '@src/views/hubs/store'
import classNames from 'classnames'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Col, Form, Row, Spinner } from 'reactstrap'
import '../../../styles/sidesheet.scss'
import './styles.scss'

const CreateBoxes = ({ openBoxModal, setOpenBoxModal, hubId: hubIdProp }) => {

  const { t } = useTranslation()

  const { control, register, handleSubmit, reset, formState: { errors }, watch } = useForm({
    defaultValues: {
      capacity: null,
      box_length: '',
      box_width: '',
      box_height: '',
      box_name: ''
    },
    mode: 'onChange'
  })

  const dispatch = useDispatch()
  const hubId =  hubIdProp || useSelector((state) => state.revampedHubs.selectedHubId)
  const { is_success, loading } = useSelector((state) => state.revampedHubs.createBoxesResponse)

  const handleCloseSideBar = () => {
    setOpenBoxModal(!openBoxModal)
    reset()
  }

  const options = [
    { label: t(LARGE), value: 'large' },
    { label: t(MEDIUM), value: 'medium' },
    { label: t(SMALL), value: 'small' }
  ]

  const handleFormSubmit = (data) => {
    const body = {
      capacity_type: data.capacity.value,
      name: data.box_name,
      capacity: `${data.box_length} x ${data.box_width} x ${data.box_height}`
    }
    dispatch(createBoxes({ hubId, body }))
  }

  useEffect(() => {
    if (is_success) {
      dispatch(clearCreateBoxesResponse())
      setOpenBoxModal(false)
      dispatch(getBoxes({ hubId }))
      reset()
    }
  }, [is_success])

  return (
    <div>
      <SideSheet
        size="md" isOpen={openBoxModal}
        toggle={handleCloseSideBar}
        title="Add Box"
        modalClassName="modal-slide-in sidebar-todo-modal hubs-sidesheet-styling">

        <Form onSubmit={handleSubmit(handleFormSubmit)}>
          <div className="create-boxes-modal-body">
            <div className="mb-30px">
              <InputField
                errors={errors}
                {...register('box_name', {
                  required: t('This field is required')
                })}
                isClearable
                label={t('Box Name')}
                isRequired
              />
            </div>
            <div className="mb-30px">
              <FloatingDropDown
                name="capacity"
                control={control}
                options={options}
                isClearable
                defaultValue={watch('capacity')}
                title={'Box Capacity'}
                isRequired
                isTooltipError
                errors={errors}
                validationSchema={{ capacity: { required: t('Box Capacity is required') } }}
              />
            </div>
            <Row className='my-30px w-555px' >
              <Col className="col col-4">
                <InputField
                  errors={errors}
                  type='number'
                  {...register('box_length', {
                    required: t('Box Length is required'),
                    min: { value: 1, message: 'Length cannot be 0'}
                  })}
                  isClearable
                  label={'Box Length (In cm)'}
                  isRequired
                  width={'170px'}
                />
              </Col>
              <Col className="col col-4">
                <InputField
                  errors={errors}
                  type='number'
                  {...register('box_width', {
                    required: t('Box Width is required'),
                    min: { value: 1, message: 'Width cannot be 0'}
                  })}
                  isClearable
                  label={'Box Width (In cm)'}
                  isRequired
                  width={'170px'}
                />
              </Col>
              <Col className="col col-4">
                <InputField
                  errors={errors}
                  type='number'
                  {...register('box_height', {
                    required: t('Box height is required'),
                    min: { value: 1, message: 'Height cannot be 0'}
                  })}
                  isClearable
                  label={'Box Height (In cm)'}
                  isRequired
                  width={'170px'}
                />
              </Col>
            </Row>

            <SidesheetFooter>
              <Button type='submit' disabled={loading}>
                {loading && <Spinner size="sm" /> }
                <span className={classNames({'ms-50' : loading})}>{t('Create')}</span>
              </Button>
            </SidesheetFooter>
          </div>
        </Form>
      </SideSheet>
    </div>
  )
}

export default CreateBoxes
