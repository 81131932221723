import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

const CustomLabel = (props) => {
  const { title, className, ...rest } = props
  const { t } = useTranslation()
  return (
    <div className={`width-fit-content rounded-4px txt-sub-md p-0 text-break ${className}`}  {...rest}>
      <div className="px-4px py-2px">
        {t(title)}
      </div>
    </div>
  )
}

CustomLabel.propTypes = {
  title: PropTypes.string.isRequired,
  className: PropTypes.string
}

CustomLabel.defaultProps = {
  className: 'text-dark bg-dark-1'
}

export default CustomLabel