import React, { createContext } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'

export const UseLocationContext = createContext() 

const UseLocationContextProvider = ({ children }) => {
  
  const location = useLocation()
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()

  return (
    <UseLocationContext.Provider value={{location, navigate, searchParams, setSearchParams}}>
      {children}
    </UseLocationContext.Provider>
  )
}

export default UseLocationContextProvider