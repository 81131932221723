import Stepper from '@src/@core/components/ui/NewStepper'
import Button from '@src/@core/components/ui/button'
import CustomToast from '@src/@core/components/ui/custom-toast/CustomToast'
import PageHeader from '@src/@core/components/ui/page-header'
import no_sku_image from '@src/assets/images/omniful/no_sku_image.svg'
import { useApi } from '@src/configs/react-query/useApi'
import { clearEditPoData, clearShipmentsOfSinglePo } from '@src/views/Purchases/store'
import { GRN_TYPES } from '@src/views/inventory/inventory.constants'
import { clearBinDisabledGrnTableData, clearDisplayGrnDetails, clearGrnFailBinId, clearGrnPassBinId, clearGrnStore, clearSingleGrnDetails, clearStoDetails, getDisplayGrnDetail, updateGtinSkuDetails, updateIsGtinChanged } from '@src/views/inventory/store'
import { clearCreateReturnGrnResponse, clearSingleReturnOrderDetail, getReturnOrderDetail, resetSuccess } from '@src/views/returns/store'
import { useEffect, useRef, useState } from 'react'
import { Check } from 'react-feather'
import { useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, useNavigate } from 'react-router-dom'
import { Spinner } from 'reactstrap'
import grnDetailSkeleton from '../../../../../assets/images/inventory/icons/grnDetailEmpty.svg'
import { axiosInstance } from '../../../../../network/AxiosInstance'
import IdentifyBin from './component/IdentifyBin'
import BulkUploadSerialisedSkus from './component/bulkUploadSerialisedSkus'
import CompleteGRNModal from './component/completeGRNModal'
import GrnSourceIdentify from './component/grnSourceIdentify'
import NewGrnDetails from './component/newGrnDetails'
import OffTypeSystemReturnQc from './component/offSystemReturnQc'
import SkuScan from './component/skuScan'
import { GRN_STEPS } from './constant'
import './style.scss'

const CreateNewGRN = () => {

  const {
    control,
    register,
    formState: { errors },
    watch,
    reset,
    setValue,
    setError,
    clearErrors
  } = useForm({
    mode: 'onChange'
  })

  const pageHeaderProps = {
    title: 'New GRN',
    breadcrumbsData: [
      { title: 'Inventory' },
      { title: 'Inventory Operations' },
      { title: 'GRN' }
    ]
  }

  const configurations = useSelector(store => store.auth.selectedHubConfiguration)
  const isBinDisabled = !configurations?.find(item => item.configuration_type === 'bin')?.configuration_values?.enabled
  const steps = ['GRN details & Bins', 'QC']

  const searchBarcode = useWatch({
    control,
    name: 'search_barcode'
  })

  const skuScanBarcodeRef = useRef()
  const [currentStep, setCurrentStep] = useState(isBinDisabled ? 2 : 1)

  const navigate = useNavigate()

  const {t} = useTranslation()

  const [bulkUploadSkuSidesheetState, setBulkUploadSkuSidesheetState] = useState(false)
  const [isCompleteGRNModalOpen, setIsCompleteGRNModalOpen] = useState(false)

  const grnResponse = useSelector(store => store.inventory.grn.grnResponse)
  // const completeGRNResponse = useSelector(store => store.inventory.grn.completeGRNResponse)
  const lastScannedSkuComplete = useSelector(store => store.inventory.grn.lastScannedSkuComplete)
  const loadingState = useSelector(store => store.inventory.loadingState)
  const displayGrnDetails = useSelector(store => store.inventory.grn.displayGrnDetails)
  const returnGrnResponse = useSelector((state) => state.returns.createReturnGrnResponse?.data)
  const createReturnGrnLoading = useSelector((state) => state.returns.loading.createReturnGrn)
  const processNextReturnOrderSuccess = useSelector((state) => state.returns.success.processNextReturnOrder)
  const processNextReturnOrderResponse = useSelector(store => store.returns.processNextReturnOrderResponse)
  const [isNextOrderProcess, setIsNextOrderProcess] = useState(false)
  const selectedGlobalHubId = useSelector(store => store.auth.selectedGlobalHubId)
  const [isQuantityRemaining, setIsQuantityRemaining] = useState(false)

  const dispatch = useDispatch()
  const handleNextStep = () => {
    dispatch(getDisplayGrnDetail({ grn_id: grnResponse.id }))
    dispatch(clearGrnPassBinId())
    dispatch(clearGrnFailBinId())
  }

  const handleReturnNextStep = () => {
    setCurrentStep(GRN_STEPS.GRN_QC)
    dispatch(clearGrnPassBinId())
    dispatch(clearGrnFailBinId())
    setIsNextOrderProcess(false)
  }

  const {mutate:getGrnDetails, isPending} = useApi({
    isMutation: true,
    apiKey: ['get-grn-details'],
    apiFn: () => {
      const grn_id = grnResponse.id || returnGrnResponse.id
      return axiosInstance.put(`/api/v1/wms/hubs/${selectedGlobalHubId}/grns/${grn_id}/complete`, {is_source_modal: false})
    },
    onSuccess: ({data}) => {
      if (data?.remaining_quantity === 0) {
        CustomToast('GRN completed successfully', { my_type: 'success' })
        reset()
        if (!isBinDisabled) {
          setCurrentStep(1)
        }
        dispatch(clearShipmentsOfSinglePo())
        dispatch(clearEditPoData())
        dispatch(clearStoDetails())
        dispatch(clearGrnStore())
        dispatch(clearCreateReturnGrnResponse())
        dispatch(clearSingleReturnOrderDetail())
        dispatch(clearSingleGrnDetails())
        dispatch(clearDisplayGrnDetails())
        navigate('/inventory/inventory-operations/grn')
      } else if (data?.remaining_quantity > 0) {
        setIsQuantityRemaining(data?.is_quantity_remaining)
        setIsCompleteGRNModalOpen(true)
      }
    }
  })

  const {mutate:completeFinalGRN, isPending:isCompleteGrnPending} = useApi({
    isMutation: true,
    apiKey: ['complete-final-grn'],
    apiFn: (id) => axiosInstance.put(`/api/v1/wms/hubs/${selectedGlobalHubId}/grns/${id}/complete`, {is_source_modal: true}),
    onSuccess: () => {
      CustomToast('GRN completed successfully', { my_type: 'success' })
      setIsCompleteGRNModalOpen(false)
      reset()
      if (!isBinDisabled) {
        setCurrentStep(1)
      }
      dispatch(clearShipmentsOfSinglePo())
      dispatch(clearEditPoData())
      dispatch(clearStoDetails())
      dispatch(clearGrnStore())
      dispatch(clearSingleGrnDetails())
      dispatch(clearDisplayGrnDetails())
      dispatch(clearCreateReturnGrnResponse())
      dispatch(clearSingleReturnOrderDetail())
      navigate('/inventory/inventory-operations/grn')
    }
  })
  
  const handleCompleteGRN = () => {
    if (grnResponse.id) {
      completeFinalGRN(grnResponse.id)
      // dispatch(completeGRN({ grn_id: grnResponse.id }))
    }
    if (returnGrnResponse?.id) {
      completeFinalGRN(returnGrnResponse.id)
      // dispatch(completeGRN({ grn_id: returnGrnResponse.id }))
    }
  }

  const toggleCompleteGrnModal = () => {
    setIsCompleteGRNModalOpen(pre => !pre)
  }

  useEffect(() => {

    if (displayGrnDetails?.id && !isBinDisabled) {
      setCurrentStep(prev => prev + 1)
    }

    if (displayGrnDetails?.id && isBinDisabled) {
      setCurrentStep(2)
    }

  }, [displayGrnDetails])

  useEffect(() => {
    return () => {
      reset()
      if (!isBinDisabled) {
        setCurrentStep(1)
      }
      dispatch(clearShipmentsOfSinglePo())
      dispatch(clearEditPoData())
      dispatch(clearStoDetails())
      dispatch(clearGrnStore())
      dispatch(clearSingleGrnDetails())
      dispatch(clearDisplayGrnDetails())
      dispatch(clearCreateReturnGrnResponse())
      dispatch(clearSingleReturnOrderDetail())
      dispatch(clearBinDisabledGrnTableData())
      dispatch(updateGtinSkuDetails({}))
      dispatch(updateIsGtinChanged(false))
    }
  }, [])

  useEffect(() => {
    if (!grnResponse.id) {
      setValue('pass_bin_barcode', '')
      setValue('fail_bin_barcode', '')
    }
  }, [grnResponse])

  useEffect(() => {
    if (processNextReturnOrderSuccess && !isBinDisabled) {
      dispatch(getReturnOrderDetail(processNextReturnOrderResponse.oms_return_order_id))
      dispatch(resetSuccess())
    }
  }, [processNextReturnOrderSuccess])

  if(isBinDisabled && !grnResponse?.id && !returnGrnResponse?.id) {
    return <Navigate to='/inventory/inventory-operations/grn' />
  }

  return (
    <div className="grn-container">
      <div className="d-flex justify-content-between align-items-center">
        <PageHeader {...pageHeaderProps} />
        {((grnResponse.id || returnGrnResponse?.grn_id) && currentStep === GRN_STEPS.GRN_QC) && !isBinDisabled &&
        <Button disabled={isPending} onClick={getGrnDetails} className='me-28px flex-center-start gap-8px' ofStyle='outlined'>
          {isPending ? <Spinner size='sm'/> : null} 
          <span>{t('Complete GRN')}</span>
        </Button>}
      </div>
      <div className="border-top py-24px px-40px">
        {!isBinDisabled && <div className="d-flex justify-content-center">
          <Stepper steps={steps} currentStep={currentStep} />
        </div>}
        <div className="d-flex gap-40px mt-32px">
          <div className=" d-flex flex-column gap-36px details-section w-50">
            {(GRN_STEPS.GRN_AND_BIN_DETAILS === currentStep || isNextOrderProcess) && !isBinDisabled && (
              <>
                <GrnSourceIdentify
                  control={control}
                  register={register}
                  errors={errors}
                  reset={reset}
                  setValue={setValue}
                  watch={watch}
                  isBarcodePrint={watch('pass_bin_barcode') || watch('pass_bin_barcode')}
                  isNextOrderProcess={isNextOrderProcess}
                  setIsNextOrderProcess={setIsNextOrderProcess}
                />
                
                {
                  (loadingState.createNewGrnForGateEntry || loadingState.createNewGrnForPo || loadingState.createNewGrnForSto || loadingState.createNewGrnForSellerAndSupplier || createReturnGrnLoading) && <div className="d-flex justify-content-center"> <Spinner className="text-primary width-40px height-40px" /></div>
                }

                {grnResponse.grn_id && !isBinDisabled && <IdentifyBin
                  control={control}
                  setValue={setValue}
                  watch={watch}
                  onClick={handleNextStep}
                  grnResponse={grnResponse}
                  loadingState={loadingState}
                />}

                {returnGrnResponse?.grn_id && !isBinDisabled && !isNextOrderProcess && (
                  <IdentifyBin
                    control={control}
                    type={GRN_TYPES.RETURN}
                    setValue={setValue}
                    watch={watch}
                    onClick={handleReturnNextStep}
                    grnResponse={returnGrnResponse}
                  />
                )}
              </>
            )}

            {grnResponse.id && GRN_STEPS.GRN_QC === currentStep && (
              <SkuScan ref={skuScanBarcodeRef} control={control} reset={reset} setValue={setValue} watch={watch} errors={errors} setCurrentStep={setCurrentStep} register={register} searchBarcode={searchBarcode} 
                grnType={displayGrnDetails?.id ? GRN_TYPES.STANDARD : returnGrnResponse ? GRN_TYPES.RETURN : null}
              />
            )}

            {returnGrnResponse?.id && GRN_STEPS.GRN_QC === currentStep && !isNextOrderProcess && (
              <OffTypeSystemReturnQc control={control} reset={reset} setValue={setValue} watch={watch} errors={errors} register={register} setError={setError} clearErrors={clearErrors} />
            )}

            {
              lastScannedSkuComplete &&
              <div className="d-flex flex-column gap-32px align-items-center">
                <div className="py-24px px-28px max-width-300 border border-dark-2 bg-primary-lighter align-items-center d-flex flex-column gap-12px rounded-8px">
                  <div className='position-relative'>
                    <img
                      src={lastScannedSkuComplete.images?.[0]?.default || lastScannedSkuComplete.images?.[0]?.url || no_sku_image}
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null
                        currentTarget.src = no_sku_image
                      }}
                      width={104}
                      height={104}
                      alt="no img"
                      className="rounded-8px bg-white"
                    />
                    <Check className='bg-primary text-white rounded-circle position-absolute top-0 start-100 translate-middle' size={34} />
                  </div>
                  <div className="text-dark txt-h3-md">{lastScannedSkuComplete.name}</div>
                </div>
                <div className="text-dark txt-h1-sb">{t('Scan Next Item!')}</div>
              </div>
            }

          </div>
          <div className="flex-grow-1" style={{width: isBinDisabled ? '75%' : '50%'}}>
            {displayGrnDetails?.id 
              ? <NewGrnDetails grnDetails={displayGrnDetails} currentStep={currentStep} type={isBinDisabled ? GRN_TYPES.BIN_DISABLED : GRN_TYPES.STANDARD} getGrnDetails={getGrnDetails} isPending={isPending}/> 
              : returnGrnResponse 
                ? <NewGrnDetails
                  grnDetails={returnGrnResponse}
                  type={isBinDisabled ? GRN_TYPES.BIN_DISABLED : GRN_TYPES.RETURN}
                  setCurrentStep={setCurrentStep}
                  currentStep={currentStep}
                  isNextOrderProcess={isNextOrderProcess}
                  setIsNextOrderProcess={setIsNextOrderProcess}
                  getGrnDetails={getGrnDetails} 
                  isPending={isPending}
                  isReturnOrder={isBinDisabled}
                />
                : <img className="w-100" src={grnDetailSkeleton} alt="empty state" />}
          </div>
        </div>
      </div>

      <BulkUploadSerialisedSkus
        bulkUploadSkuSidesheetState={bulkUploadSkuSidesheetState}
        setBulkUploadSkuSidesheetState={setBulkUploadSkuSidesheetState}
      />

      <CompleteGRNModal 
        isOpen={isCompleteGRNModalOpen} 
        toggle={toggleCompleteGrnModal} 
        handleCompleteGRN={handleCompleteGRN} 
        isQuantityRemaining={isQuantityRemaining} 
        isCompleteGrnPending={isCompleteGrnPending}
      />
    </div>
  )
}

export default CreateNewGRN
