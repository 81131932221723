// import { useSelector } from "react-redux"
// import { GRN_SOURCE_TYPE } from "../../constant"
// import GrnWithGateEntry from "../grnWithGateEntry"
// import GrnWithPOAndSto from "../grnWithPOAndSto"
// import GrnWithSellerAndSupplier from "../grnWithSellerAndSupplier"
import ReturnGrnWithGateEntry from './returnGrnWithReturnGateEntry'

const ReturnGrnSourceIdentify = ({ control, errors, isBarcodePrint, watch, setValue, register, reset, isNextOrderProcess, setIsNextOrderProcess }) => {
  return (
    <div className="d-flex flex-column gap-24px">
      <ReturnGrnWithGateEntry
        control={control}
        register={register}
        errors={errors}
        reset={reset}
        isBarcodePrint={isBarcodePrint}
        watch={watch}
        setValue={setValue}
        isNextOrderProcess={isNextOrderProcess}
        setIsNextOrderProcess={setIsNextOrderProcess}
      />
    </div>
  )
}

export default ReturnGrnSourceIdentify
