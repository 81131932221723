const FrontFile = ({width = '70', height = '100', color}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 185 119" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M164.429 0.753906H20.438V118.668H164.429V0.753906Z" fill="white"/>
      <path d="M0.385903 14.6678C0.196903 13.3048 1.25591 12.0898 2.63191 12.0898H182.261C183.627 12.0898 184.683 13.2889 184.51 14.6449L171.484 116.685C171.339 117.818 170.376 118.666 169.235 118.666H16.7439C15.6119 118.666 14.6529 117.831 14.4979 116.709L0.385903 14.6678Z" fill={color} />
    </svg>
    
  )
}

export default FrontFile