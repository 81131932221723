import PropTypes from 'prop-types'
import DataTable from 'react-data-table-component'
import './styles.scss'
const CustomDataTable = (props) => {
  const {
    columns,
    data,
    ...rest 
  } = props
  return (
    <div className="custom-data-table-container">
      <DataTable
        columns={columns}
        data={data}
        striped
        responsive
        {...rest}
      />
    </div>
  )
}
export default CustomDataTable

// ** PropTypes
CustomDataTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    selector: PropTypes.func
  }))
}