export const RequiredIcon = () => {
  return (
    <svg
      width="5"
      height="5"
      viewBox="0 0 4 4"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.98852 3.564C1.70052 3.564 1.43652 3.496 1.19652 3.36C0.964516 3.216 0.776516 3.028 0.632516 2.796C0.496516 2.556 0.428516 2.292 0.428516 2.004C0.428516 1.564 0.580516 1.192 0.884516 0.888C1.18852 0.584 1.55652 0.432 1.98852 0.432C2.43652 0.432 2.81252 0.584 3.11652 0.888C3.42052 1.192 3.57252 1.564 3.57252 2.004C3.57252 2.444 3.42052 2.816 3.11652 3.12C2.81252 3.416 2.43652 3.564 1.98852 3.564Z"
        fill="#C21808"
      />
    </svg>
  )
}