
const SVG = ({ iswhite, fillColor, height, width }) => {
  const color = iswhite ? 'white' : fillColor
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M9.70943 4.39419C8.9033 5.05242 8.27346 6.07207 8.27346 7.55654C8.27346 8.70301 8.93154 9.94804 9.83714 11.0789C10.6274 12.0658 11.5604 12.909 12.258 13.445C12.964 12.9337 13.9056 12.118 14.6965 11.1429C15.6015 10.027 16.2527 8.77249 16.2253 7.56718C16.1895 5.99202 15.5521 4.97244 14.7628 4.33805C13.9572 3.69058 12.9718 3.4286 12.2493 3.4286C11.5379 3.4286 10.5317 3.72282 9.70943 4.39419ZM9.11803 3.6699C10.1082 2.86137 11.3237 2.49353 12.2493 2.49353C13.1639 2.49353 14.3612 2.81572 15.3486 3.6092C16.3521 4.41577 17.118 5.69392 17.1601 7.54591C17.1951 9.0823 16.3781 10.5539 15.4227 11.7319C14.4587 12.9204 13.2959 13.8815 12.5101 14.4094C12.3479 14.5184 12.1351 14.515 11.9765 14.401C11.2071 13.8481 10.0636 12.8577 9.10726 11.6634C8.16145 10.4823 7.33839 9.02717 7.33839 7.55654C7.33839 5.76966 8.11173 4.49157 9.11803 3.6699ZM12.1696 5.76528C11.2664 5.76528 10.5341 6.49751 10.5341 7.40076C10.5341 8.30402 11.2664 9.03625 12.1696 9.03625H12.2511C13.1543 9.03625 13.8866 8.30402 13.8866 7.40076C13.8866 6.49751 13.1543 5.76528 12.2511 5.76528H12.1696ZM9.59907 7.40076C9.59907 5.98109 10.7499 4.83021 12.1696 4.83021H12.2511C13.6707 4.83021 14.8216 5.98109 14.8216 7.40076C14.8216 8.82044 13.6707 9.97131 12.2511 9.97131H12.1696C10.7499 9.97131 9.59907 8.82044 9.59907 7.40076Z" fill={color}/>
      <path fillRule="evenodd" clipRule="evenodd" d="M5.63526 11.3287C5.49158 11.3287 5.36523 11.4238 5.32541 11.5618L2.81861 20.2521C2.75913 20.4583 2.91387 20.664 3.12846 20.664H20.9763C21.1909 20.664 21.3456 20.4583 21.2861 20.2521L18.7793 11.5619C18.7395 11.4238 18.6131 11.3287 18.4695 11.3287H17.1414C16.8832 11.3287 16.6738 11.1194 16.6738 10.8612C16.6738 10.603 16.8832 10.3937 17.1414 10.3937H18.4695C19.0298 10.3937 19.5225 10.7643 19.6778 11.3027L22.1846 19.9929C22.4165 20.797 21.8131 21.599 20.9763 21.599H3.12846C2.29164 21.599 1.68824 20.797 1.92018 19.9929L4.42698 11.3027C4.58227 10.7643 5.07497 10.3937 5.63526 10.3937H7.36161C7.61982 10.3937 7.82914 10.603 7.82914 10.8612C7.82914 11.1194 7.61982 11.3287 7.36161 11.3287H5.63526Z" fill={color}/>
      <path fillRule="evenodd" clipRule="evenodd" d="M9.33662 14.4889C8.96762 14.4889 8.66849 14.788 8.66849 15.157C8.66849 15.526 8.96762 15.8251 9.33662 15.8251H16.5949C17.6167 15.8251 18.445 16.6535 18.445 17.6752C18.445 18.0081 18.3571 18.3204 18.2033 18.5902H21.3351C21.5933 18.5902 21.8026 18.7995 21.8026 19.0578C21.8026 19.316 21.5933 19.5253 21.3351 19.5253H13.6324C13.127 19.5253 12.7174 19.9349 12.7174 20.4403V21.1316C12.7174 21.3898 12.508 21.5991 12.2498 21.5991C11.9916 21.5991 11.7823 21.3898 11.7823 21.1316V20.4403C11.7823 19.4185 12.6106 18.5902 13.6324 18.5902H16.5949C17.1003 18.5902 17.5099 18.1806 17.5099 17.6752C17.5099 17.1699 17.1003 16.7602 16.5949 16.7602H9.33662C8.4512 16.7602 7.73343 16.0424 7.73343 15.157C7.73343 14.2716 8.4512 13.5538 9.33662 13.5538H12.2498C12.508 13.5538 12.7174 13.7632 12.7174 14.0214C12.7174 14.2796 12.508 14.4889 12.2498 14.4889H9.33662Z" fill={color}/>
    </svg> 
  )
}

export default SVG