import { Helmet } from 'react-helmet-async'

const PageTitle = ({ pageTitle }) => {
  return (
    <Helmet>
      <title>{pageTitle}</title>
    </Helmet>
  )
}

export default PageTitle