import classNames from 'classnames'
import { Info } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { UncontrolledTooltip } from 'reactstrap'
import './style.scss'

const ToggleTabs = ({ tabDataList, selectedTabId, onTabChange, isDisabled = false}) => {
  // ** Hooks
  const { t } = useTranslation()
  return (
    <div className={classNames('d-flex gap-8px border bg-light-3 rounded-12px p-6px border-dark-2 w-fit-content toggle-container', {
      'opacity-50': isDisabled
    })}>
      {tabDataList.map((tab, index) => {
        return (
          <button
            type="button"
            key={index}
            disabled={isDisabled}
            className={classNames(
              'text-dark border-0 rounded-8px gap-4px py-6px px-12px position-relative top-2px d-flex align-items-center border-bottom-2px',
              {
                'active ': selectedTabId === tab.id,
                'bg-light-3': selectedTabId !== tab.id,
                'text-white': selectedTabId === tab.id,
                tab: !tab.isDisabled && !(selectedTabId === tab.id)
              }
            )}
            onClick={() => {
              if (!tab.isDisabled) onTabChange(tab)
            }}
          >
            {tab.isDisabled && <>
              <Info id={`tab-info-${tab.id}`} className="text-muted" size={12} />
              <UncontrolledTooltip offset={[0, 5]} placement="top" target={`tab-info-${tab.id}`}>{tab.info}</UncontrolledTooltip>
            </>}
            <span className={classNames({ 'text-muted': tab.isDisabled })}>
              {t(tab.name)}
            </span>

          </button>
        )
      })}
    </div>
  )
}

export default ToggleTabs


// code example :

// const [selectedTabId, setSelectedTabId] = useState(Object.values(CONFIG_BASE_GRN_OPTION).find(option => !option.isDisabled).id)
// const tabChangeHandler = (tab) => {
//     setSelectedTabId(tab.id)
// }
/* <ToggleTabs tabDataList={Object.values(CONFIG_BASE_GRN_OPTION)} selectedTabId={selectedTabId} onTabChange={tabChangeHandler}/> */