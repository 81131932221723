import QuantityButton from '@src/@core/components/ui/quantity-button'
import SkuItemSkeleton from '@src/@core/components/ui/skeleton/sku-item'
import { Plus, Trash2 } from 'react-feather'
import PerfectScrollBar from 'react-perfect-scrollbar'
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Table
} from 'reactstrap'
import { ADD } from '../../catalog.constants'
import SkuDetailsRow from './SkuDetailsRow'
import SkuDropdownItem from './SkuDropdownItem'
export function AddSkuSection({
  t,
  bundleSkus,
  setSkuRetailPrice,
  skuRetailPrice,
  skuSellingPrice,
  setSkuSellingPrice,
  setWarningValueChangeModal,
  warningModalRef,
  warningValueChangeModal,
  setStoreIndex,
  storeIndex,
  quantityChangeType,
  setQuantityChangeType,
  storeSkuDetailsUpdatedData,
  setStoreSkuDetailsUpdatedData,
  isSkuDetailsChange,
  setIsSkuDetailsChange,
  setSkuDetailChangedType,
  skuDetailChangedType,
  bundleCreationLoading,
  imageGettingUpload,
  setStoreDeletedSkuData,
  MAX_SKU_CAN_ADD_IN_BUNDLE_AND_ASSEMBLE,
  inputRef,
  debouncedDispatch,
  handleShowAddItemsDropdown,
  dispatch,
  clearSellerSkuList,
  showAddItemsDropdown,
  editSkuClassName,
  sellerSkusListFromStore,
  classNames,
  no_sku_image,
  setBundleSkus,
  updateBundleSkusRes,
  loading
}) {
  return (
    <Table borderless>
      <thead className="bg-primary-lighter text-dark">
        <tr className="bg-primary-lighter">
          <th className="trash-icon-column p-10px"></th>
          <th className="bundle-table-sno-width p-10px">{t('S. No.')}</th>
          <th className="ps-10px">{t('SKU Name')}</th>
          <th className="text-center">{t('Quantity')}</th>
          <th className="text-end">{t('Retail Price')}</th>
          <th className="text-end">{t('Selling Price')}</th>
        </tr>
      </thead>
      <tbody>
        {bundleSkus.map((item, idx) => (
          <SkuDetailsRow
            idx={idx}
            key={idx}
            itemDetails={item}
            setSkuRetailPrice={setSkuRetailPrice}
            skuRetailPrice={skuRetailPrice}
            skuSellingPrice={skuSellingPrice}
            setSkuSellingPrice={setSkuSellingPrice}
            setWarningValueChangeModal={setWarningValueChangeModal}
            warningModalRef={warningModalRef}
            warningValueChangeModal={warningValueChangeModal}
            setStoreIndex={setStoreIndex}
            storeIndex={storeIndex}
            quantityChangeType={quantityChangeType}
            setQuantityChangeType={setQuantityChangeType}
            storeSkuDetailsUpdatedData={storeSkuDetailsUpdatedData}
            setStoreSkuDetailsUpdatedData={setStoreSkuDetailsUpdatedData}
            isSkuDetailsChange={isSkuDetailsChange}
            setIsSkuDetailsChange={setIsSkuDetailsChange}
            setSkuDetailChangedType={setSkuDetailChangedType}
            skuDetailChangedType={skuDetailChangedType}
            isDisabled={
              bundleCreationLoading ||
              updateBundleSkusRes.loading ||
              imageGettingUpload
            }
            setStoreDeletedSkuData={setStoreDeletedSkuData}
            currency={item.currency}
          />
        ))}
        {bundleSkus.length < MAX_SKU_CAN_ADD_IN_BUNDLE_AND_ASSEMBLE && (
          <tr>
            <td className="p-10px">
              <div className="trash-icon">
                <Trash2 color="var(--bs-danger-light)" />
              </div>
            </td>
            <td>
              <div>{bundleSkus.length + 1}</div>
            </td>
            <td className="sku-name-column">
              <div className="d-flex gap-8px">
                <div className="d-flex justify-content-center align-items-center add-container-dotted">
                  <div
                    className="h-100 w-100 d-flex align-items-center justify-content-center cursor-pointer"
                    onClick={() => {
                      inputRef.current.focus()
                    }}
                  >
                    <Plus color="var(--bs-primary)" />
                  </div>
                </div>
                <div className="d-flex flex-column">
                  <span className="txt-body-md">
                    <input
                      ref={inputRef}
                      placeholder={t('SKU Name')}
                      className="borderless-input"
                      onKeyUp={(e) => {
                        if (e.target.value) debouncedDispatch(e.target.value)
                      }}
                      onChange={(e) => {
                        if (e.target.value) {
                          handleShowAddItemsDropdown(true)
                        } else {
                          dispatch(clearSellerSkuList())
                          handleShowAddItemsDropdown(false)
                        }
                      }}
                      disabled={
                        bundleCreationLoading ||
                        updateBundleSkusRes.loading ||
                        imageGettingUpload
                      }
                    />
                  </span>
                  <span className="txt-asst-rg p-2px">{t('SKU Code')}</span>
                </div>
              </div>
              <Dropdown
                isOpen={showAddItemsDropdown}
                toggle={() => {
                  handleShowAddItemsDropdown(!showAddItemsDropdown)
                }}
                className="dropdown-button h-0 create-bundle-dropdown"
              >
                {showAddItemsDropdown && (
                  <DropdownToggle className="w-0 h-0 p-0 m-0"></DropdownToggle>
                )}
                {loading.getSellerSkus ? (
                  <SkuItemSkeleton
                    width="400px"
                    editSkuClassName={editSkuClassName}
                  />
                ) : (
                  <>
                    {sellerSkusListFromStore?.length ? (
                      <DropdownMenu
                        className={classNames(
                          'max-height-250 overflow-scroll width-400',
                          {
                            'dropdown-menu-wrapper': editSkuClassName
                          }
                        )}
                      >
                        <PerfectScrollBar>
                          {showAddItemsDropdown &&
                            sellerSkusListFromStore
                              .filter(
                                (skuFromStore) => bundleSkus.findIndex(
                                  (bundleSku) => bundleSku.id === skuFromStore.id
                                ) === -1
                              )
                              .map((skuDetails, id) => {
                                return (
                                  <div
                                    onClick={() => {
                                      setSkuDetailChangedType(ADD)
                                      if (skuSellingPrice || skuRetailPrice) {
                                        setWarningValueChangeModal(true)
                                        setIsSkuDetailsChange(true)
                                        setStoreSkuDetailsUpdatedData([
                                          ...bundleSkus,
                                          {
                                            seller_sku_id: skuDetails.id,
                                            sku_code:
                                              skuDetails.seller_sku_code,
                                            name: skuDetails?.name,
                                            image:
                                              skuDetails?.images?.[0]
                                                ?.default || no_sku_image,
                                            quantity: 1,
                                            ...skuDetails
                                          }
                                        ])
                                        handleShowAddItemsDropdown(false)
                                        inputRef.current.value = ''
                                        return
                                      }

                                      dispatch(
                                        setBundleSkus([
                                          ...bundleSkus,
                                          {
                                            seller_sku_id: skuDetails.id,
                                            sku_code:
                                              skuDetails.seller_sku_code,
                                            name: skuDetails?.name,
                                            image:
                                              skuDetails?.images?.[0]
                                                ?.default || no_sku_image,
                                            quantity: 1,
                                            ...skuDetails
                                          }
                                        ])
                                      )
                                      handleShowAddItemsDropdown(false)
                                      inputRef.current.value = ''
                                    }}
                                    key={id}
                                  >
                                    <SkuDropdownItem itemDetails={skuDetails} />
                                  </div>
                                )
                              })}
                        </PerfectScrollBar>
                      </DropdownMenu>
                    ) : (
                      <DropdownMenu
                        className={classNames('width-400',{
                          'no-result-found-wrapper': editSkuClassName
                        })}
                      >
                        <DropdownItem className="w-100 pe-none">
                          <div className="text-center">
                            {t('No record found !')}
                          </div>
                        </DropdownItem>
                      </DropdownMenu>
                    )}
                  </>
                )}
              </Dropdown>
            </td>
            <td>
              <div className="flex-center-center">
                <div className="sku-quantity bundle-create-quantity-button">
                  <QuantityButton
                    quantity={' '}
                    disabled={
                      bundleCreationLoading ||
                      updateBundleSkusRes.loading ||
                      imageGettingUpload
                    }
                  />
                </div>
              </div>
            </td>
            <td>
              <div className="d-flex flex-column justify-content-start align-items-end mrp-wrapper-empty">
                <div className="mrp"></div>
              </div>
            </td>
            <td>
              <div className="d-flex flex-column justify-content-start align-items-end mrp-wrapper-empty">
                <div className="mrp"></div>
              </div>
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  )
}
